import {combineNodesAndUploads} from 'interface/stacks/uploads/utils/filelist';
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useOutletContext} from 'react-router';
import {selectors as filesSelectors} from 'store/slices/files';
import {load} from 'utils/fast/storage';

import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';

export interface FilesData {
  filesIds: string[];
  files: Record<string, FilesItem>;
}

interface UseFetchShareFilesOptions {
  share: Share;
  folderKey: string;
}

const useFetchShareFiles = ({
  share,
  folderKey,
}: UseFetchShareFilesOptions): FilesData => {
  const dispatch = useDispatch();
  const [, uploader] = useOutletContext<[unknown, UploaderState]>();
  const {id: shareId} = share;

  useEffect(() => {
    if (share) {
      load(dispatch, folderKey, shareId, 'share');
    }
  }, [shareId, folderKey]);

  const allFiles = useSelector(filesSelectors.getItems);
  const folderElementsIds = useSelector(
    filesSelectors.getFolder('share', shareId, folderKey),
  );

  const [view, items] = useMemo(
    () =>
      combineNodesAndUploads(
        folderElementsIds,
        allFiles,
        uploader.uploads,
        share.id,
        folderKey,
      ),
    [folderElementsIds, allFiles, uploader.uploads, share.id, folderKey],
  );

  return {
    filesIds: view,
    files: items,
  };
};

export default useFetchShareFiles;
