import {Divider} from 'interface/common/Divider';
import {StyleSheet, View} from 'react-native';

interface SectionProps {
  children: React.ReactNode;
}

export default function Section({children}: SectionProps) {
  return (
    <>
      <View style={styles.root}>{children}</View>
      <Divider />
    </>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 16,
  },
});
