import AppLogo from 'interface/common/AppLogo';
import React from 'react';
import {StyleSheet, View} from 'react-native';

export default function LogoHeader() {
  return (
    <View style={styles.root}>
      <AppLogo />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    height: 66,
    paddingLeft: 10,
    display: 'flex',
    justifyContent: 'center',
  },
});
