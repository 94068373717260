import theme from 'config/theme';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {SettingsType} from './base/consts';
import {Authentication} from './components/Authentication';
import {EmailDomains} from './components/EmailDomains';
import {AccountSettings} from './components/account-settings';
import {Billing} from './components/billing';
import {Members} from './components/members';
import {Organization} from './components/organization';
import {Profile} from './components/profile';

export const PUBLIC_SETTINGS_SECTIONS = [
  SettingsType.profile,
  SettingsType['account-settings'],
];

const COMPONENT_MAP = {
  [SettingsType.organization]: <Organization />,
  [SettingsType.members]: <Members />,
  [SettingsType.authentication]: <Authentication />,
  [SettingsType.billing]: <Billing />,
  [SettingsType['email-domains']]: <EmailDomains />,
  [SettingsType.profile]: <Profile />,
  [SettingsType['account-settings']]: <AccountSettings />,
};

export interface SettingsContentType {
  type: SettingsType;
}

export default function SettingsContent({type}: SettingsContentType) {
  const isMinimal = screen.width < 600;
  const classes = {
    content: [styles.content, isMinimal && styles.minimal],
  };
  const isAdminOrOwner = useSelector(app.selectors.getUserIsAdminOrOwner);

  if (!isAdminOrOwner && !PUBLIC_SETTINGS_SECTIONS.includes(type)) {
    return null;
  }

  return (
    <View style={styles.root}>
      <View style={classes.content}>{COMPONENT_MAP[type]}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    backgroundColor: theme.colors.neutral.$0,
    borderRadius: 6,
    marginTop: 2,
  },
  content: {
    flex: 1,
    width: '100%',
  },
  minimal: {
    marginHorizontal: 0,
  },
});
