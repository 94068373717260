import {UploadStatus} from 'fast-sdk';
import {
  FilePreviewState,
  StorageItemType,
} from 'fast-sdk/src/api/storage/consts';
import {FilesItemState} from 'store/slices/files/types';

import type {Upload} from 'fast-sdk';
import type {FilesItem, FilesView} from 'store/slices/files/types';

/**
 * Converts an upload to a file to be displayed in a folder
 */
export function toFile(upload: Upload): FilesItem {
  return {
    id: upload.metadata.uuid,
    hash: upload.metadata.hash,
    name: upload.metadata.name,
    type: StorageItemType.File,
    size: upload.metadata.size,
    created: upload.created,
    modified: upload.modified,
    mimetype: upload.metadata.type,
    workspaceId: upload.instanceId,
    parent: upload.folderId,
    hierarchy: [],
    comments: [],
    version: '',
    dmca: false,
    locked: false,
    restricted: false,
    virus: {status: '', reason: ''},
    state: FilesItemState.isUploading,
    upload: {
      bytes: upload.bytes,
      member: upload.memberId,
      status: upload.status,
    },
    previews: {
      thumbnail: {
        state: FilePreviewState.IN_PROGRESS,
      },
      image: {
        state: FilePreviewState.IN_PROGRESS,
      },
    },
    file_attributes: {
      exif_metadata: {
        MIMEType: '',
        FileType: '',
        Title: '',
        Author: '',
        Subject: '',
        Template: '',
        Keywords: '',
        CreateDate: '',
        LastModifiedBy: '',
        ImageHeight: 0,
        ImageWidth: 0,
        Rotation: 0,
      },
    },
    origin: {
      type: 'upload',
      creator: upload.memberId,
      upload_session_id: upload.sessionId,
    },
  };
}

/**
 * Converts relevant uploads to a folder view list
 */
export function toView(
  uploads: readonly Upload[],
  localItems: Record<string, FilesItem>,
  instanceId: string,
  folderId: string,
): FilesView {
  // Index session ids in local items
  const sessionIds = Object.entries(localItems).reduce<Record<string, string>>(
    (acc, [id, item]) => {
      if (item.origin?.upload_session_id)
        acc[item.origin.upload_session_id] = id;
      return acc;
    },
    {},
  );
  // Filter uploads that are not in the local items
  return uploads
    .filter(i => i.instanceId === instanceId && i.folderId === folderId)
    .filter(i => !sessionIds[i.sessionId])
    .map(i => i.metadata.uuid);
}

/**
 * Combines workspace nodes with uploads
 *
 * @returns a tuple of the combined view and items
 */
export function combineNodesAndUploads(
  view: FilesView,
  items: Record<string, FilesItem>,
  uploads: readonly Upload[],
  instanceId: string,
  folderId: string,
): [FilesView, Record<string, FilesItem>] {
  const viewItems = Array.isArray(view) ? view : [];
  const _view = [...viewItems, ...toView(uploads, items, instanceId, folderId)];
  const _items = {
    ...items,
    ...uploads.reduce((acc, upload) => {
      acc[upload.metadata.uuid] = toFile(upload);
      return acc;
    }, {}),
  };
  return [_view, _items];
}
