import {api} from 'fast-sdk';
import {isJWTExpired} from 'fast-sdk/src/utils';
import store from 'store';
import user from 'store/slices/user';
import {compareDatesInUTC, getDatesDifference} from 'utils/common/dates';
import {MIN_TIME_BETWEEN_CACHE_UPDATES} from './config';

export const getActivityWebsocketAuthToken = async (
  entityId: string,
  isExpired: boolean,
): Promise<string> => {
  const storedToken = store.getState().user.websocketAuthTokens?.[entityId];
  if (storedToken && !isJWTExpired(storedToken) && !isExpired) {
    return storedToken;
  }

  const {auth_token} = await api.auth.websocketAuth(entityId);
  store.dispatch(
    user.actions.setWebsocketAuthToken({entityId, authToken: auth_token}),
  );
  return auth_token;
};

export const getMultiplayerWebsocketAuthToken = async (): Promise<string> => {
  console.log('TODO: Implement multiplayer websocket auth');
  throw new Error(
    'Not implemented WebSocketCategory.Multiplayer websocket auth',
  );
};

export const pollEntityActivity = async (
  entityId: string,
  pollingWaitTime: number,
  lastActivity: string,
) => {
  const response = await api.activity.poll({
    entityId: entityId,
    wait: pollingWaitTime,
    lastactivity: lastActivity,
  });

  return response;
};

export const shouldUpdateCache = (
  lastServerUpdate: string,
  lastClientUpdate: string,
) => {
  if (lastServerUpdate && lastClientUpdate) {
    if (compareDatesInUTC(lastServerUpdate, lastClientUpdate) > 0) {
      const msDifference = getDatesDifference(
        lastServerUpdate,
        lastClientUpdate,
      );
      return msDifference > MIN_TIME_BETWEEN_CACHE_UPDATES;
    }
    return false;
  }
  return !!lastServerUpdate;
};
