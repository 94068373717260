import {api} from 'fast-sdk';
import {ApiErrors} from 'fast-sdk/src/requests/errors';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as shared from 'store/slices/shared';
import {
  type Share,
  type ShareMember,
  ShareViewStatus,
} from 'store/slices/shared/types';
import * as user from 'store/slices/user';
import {getMembersFromUsers} from 'utils/common/members';

interface UseFetchShareViewStatusProps {
  share: Share | undefined;
  sharedCustomName: string;
}

export default function useFetchShareViewStatus({
  share,
  sharedCustomName,
}: UseFetchShareViewStatusProps) {
  const dispatch = useDispatch();
  const userDetails = useSelector(user.selectors.getUserDetails);

  const [viewStatus, setViewStatus] = useState<ShareViewStatus>(
    share ? ShareViewStatus.Viewer : ShareViewStatus.Loading,
  );

  const determineViewStatus = (
    members: ShareMember[],
    userId: string,
  ): ShareViewStatus => {
    const shareOwners = members.filter(
      member => member.permissions === 'owner',
    );
    return shareOwners.some(owner => owner.id === userId)
      ? ShareViewStatus.Owner
      : ShareViewStatus.Editor;
  };

  const updateStatus = (newStatus: ShareViewStatus) => {
    setViewStatus(newStatus);
    dispatch(
      shared.default.actions.setShareViewStatus({
        share,
        status: newStatus,
      }),
    );
  };

  const updateMembers = (newMembers: ShareMember[]) => {
    dispatch(
      shared.default.actions.setShareMembers({
        share,
        members: newMembers,
      }),
    );
  };

  const fetchShareMembers = async (customName: string): Promise<void> => {
    try {
      const {result, users, error} =
        await api.share.getAllShareMembers(customName);

      if (result && users) {
        const newMembers = getMembersFromUsers(users as ShareMember[]);
        updateMembers(newMembers);
        updateStatus(determineViewStatus(newMembers, userDetails.id));
      } else {
        updateStatus(
          error.code === ApiErrors.ProfileNotFound || !share
            ? ShareViewStatus.Closed
            : ShareViewStatus.Viewer,
        );
      }
    } catch (error) {
      updateStatus(ShareViewStatus.Viewer);
    }
  };

  useEffect(() => {
    if (sharedCustomName && !share?.members?.length) {
      fetchShareMembers(sharedCustomName);
    }
  }, [sharedCustomName]);

  return {shareViewStatus: viewStatus};
}
