import {useFlag} from '@unleash/proxy-client-react';
import {useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {useDispatch} from 'react-redux';
import {useOutletContext} from 'react-router';
import * as files from 'store/slices/files';
import type {Share} from 'store/slices/shared/types';
import Comments from './components/Comments';
import useFetchShareFiles from './hooks/useFetchShareFiles';
import type {ShareFilesNavigation} from './hooks/useShareFilesNavigation';
import ShareFiles from './layout/ShareFiles';
import {ShareFooter} from './layout/ShareFooter';
import ShareHeaderInfo from './layout/ShareHeaderInfo';

export function ShareRecepientView() {
  const dispatch = useDispatch();

  const [share, , shareFilesNavigation] =
    useOutletContext<[Share, unknown, ShareFilesNavigation]>();

  const {folderKey} = shareFilesNavigation;

  const filesData = useFetchShareFiles({share, folderKey});

  const showComments = useFlag('share-comments') && share.comments.enabled;

  useEffect(() => {
    dispatch(files.default.actions.focusParent({id: share.id}));
    dispatch(files.default.actions.focus({id: ''}));
  }, [share.id]);

  return (
    <View style={styles.root}>
      <ShareHeaderInfo share={share} />
      <ShareFiles
        share={share}
        filesData={filesData}
        shareFilesNavigation={shareFilesNavigation}
      />
      {showComments && <Comments share={share} />}
      <ShareFooter
        shareFilesNavigation={shareFilesNavigation}
        filesData={filesData}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 12,
  },
});
