import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {
  type GestureResponderEvent,
  type MouseEvent,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';

interface SectionHeaderProps {
  title: string;
  onTitlePress?: () => void;
  onAddPress?: (e: MouseEvent | GestureResponderEvent) => void;
  ariaLabel?: string;
  addButtonRef?: React.RefObject<any>;
}

export function SectionHeader({
  title,
  onTitlePress,
  onAddPress,
  ariaLabel,
  addButtonRef,
}: SectionHeaderProps) {
  const titleText = (
    <Typography variant="medium" size="sm" color={theme.colors.neutral.$2Base}>
      {title}
    </Typography>
  );

  return (
    <View style={styles.sectionHeader}>
      {onTitlePress ? (
        <Pressable onPress={onTitlePress}>{titleText}</Pressable>
      ) : (
        titleText
      )}
      <Button
        ref={addButtonRef}
        aria-label={ariaLabel}
        variant="text"
        onPress={onAddPress}
        endEnhancer={
          <Icon
            name="lucide:plus"
            color={theme.colors.neutral.$black}
            size={14}
          />
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  sectionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 4,
    paddingLeft: 16,
    paddingRight: 4,
  },
});
