import type {EventProfile} from 'fast-sdk/src/api/events/consts';
import type {ProfileState} from './types';

export const getProfileKey = (profile: EventProfile): string => {
  return Object.entries(profile)
    .filter(([_, value]) => value)
    .map(([key, value]) => `${key}:${value}`)
    .join('|');
};

export const profileInitialState: ProfileState = {
  events: [],
  loading: false,
  error: null,
  hasMore: true,
};
