import type {FontBase} from './base';

class Fonts implements FontBase {
  monospace = 'monospace';
  light =
    '"SF Semilight", "Segoe System UI Semilight", "Segoe UI Semilight", sans-serif';
  regular =
    '"SF Regular", "Segoe System UI Regular", "Segoe UI Regular", sans-serif';
  semibold =
    '"SF Semibold", "Segoe System UI Semibold", "Segoe UI Semibold", sans-serif';
  bold = '"SF Bold", "Segoe System UI Bold", "Segoe UI Bold", sans-serif';
}

export default new Fonts();
