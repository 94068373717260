import theme from 'config/theme';
import {NAME_SPACES} from 'fast-sdk/src/api/common';
import {getBaseUrl} from 'fast-sdk/src/requests/utils';
import Typography from 'interface/base/Typography';
import {useEffect} from 'react';
import {StyleSheet} from 'react-native';
import {useDispatch} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import {useGenericSSE} from '../useGenericSSE';

interface Props {
  workspaceId: string;
  chatId: string;
  messageId: string;
}

export function AiChatResponseListeningItem({
  workspaceId,
  chatId,
  messageId,
}: Props) {
  const dispatch = useDispatch();
  const {events, isConnected, error, hasCompleted} = useGenericSSE(
    getBaseUrl(
      `${NAME_SPACES.WORKSPACE}/${workspaceId}/ai/chat/${chatId}/message/${messageId}/read`,
    ),
  );
  const response = `${events.join(' ')}`;

  useEffect(() => {
    const saveResponse = (response: string) => {
      dispatch(
        aichat.default.actions.addChatResponse({
          chatId: chatId,
          messageId: messageId,
          response,
        }),
      );
    };

    if (hasCompleted && !error) {
      saveResponse(response);
    }
  }, [hasCompleted]);

  return (
    <Typography size="md" color={theme.colors.neutral.$700}>
      {response}
    </Typography>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: 8,
  },
  buttons: {
    flexDirection: 'row',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  spacer: {
    flex: 1,
  },
  iconContainer: {
    padding: 4,
  },
  popupContent: {
    width: 100,
    height: 100,
  },
});
