import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/common/Button';
import {FormError} from 'interface/common/FormError';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import app from 'store/slices/app';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';
import {RouteLayout} from '../_layout/RouteLayout';
import {PasswordInput} from '../components/PasswordInput';
import {PasswordResetCompleted} from '../components/PasswordResetCompleted';
import PasswordResetFailed from '../components/PasswordResetFailed';
import usePasswordReset, {PageStatus} from '../hooks/usePasswordReset';

export const ResetPassword = () => {
  const dispatch = useDispatch();
  const {pageStatus, errorMessage, handleResetPassword, loading} =
    usePasswordReset();

  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>();

  useEffect(() => {
    dispatch(
      app.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.Public,
        pageName: 'Reset Password',
      }),
    );
  }, []);

  return pageStatus === PageStatus.ResetPassword ? (
    <RouteLayout
      title={t`Reset Password`}
      subTitle={t`Choose a new password for your account.`}>
      {errorMessage ? <FormError errorMessage={errorMessage} /> : null}
      <PasswordInput
        password={newPassword}
        setPassword={setNewPassword}
        label={t`New Password`}
        setPasswordIsValid={isValid => setIsPasswordValid(isValid)}
        withValidation
        customRootStyle={{marginBottom: '1.5rem'}}
      />
      <PasswordInput
        password={confirmPassword}
        setPassword={setConfirmPassword}
        label={t`Confirm Password`}
        customRootStyle={{marginBottom: '3rem'}}
        errorMessage={
          newPassword !== confirmPassword && newPassword && confirmPassword
            ? t`The confirm password should be the same as the new password`
            : null
        }
      />
      <Button
        label={t`Continue`}
        type="Primary"
        onPress={() => handleResetPassword(newPassword, confirmPassword)}
        loading={loading}
        disabled={
          !newPassword ||
          !confirmPassword ||
          !isPasswordValid ||
          loading ||
          newPassword !== confirmPassword
        }
        customLoadingColor={theme.colors.neutral.$white}
        customRootStyle={{height: 48}}
      />
    </RouteLayout>
  ) : pageStatus === PageStatus.Failed ? (
    <PasswordResetFailed errorMessage={errorMessage} />
  ) : (
    <PasswordResetCompleted password={newPassword} />
  );
};
