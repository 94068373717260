import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import type {BillingUsageMember} from 'fast-sdk/src/api/billing/consts';
import type {BillingUsageMember as Member} from 'fast-sdk/src/api/billing/consts';
import Typography from 'interface/base/Typography';
import {Loading} from 'interface/common/Loading';
import SettingsTable, {
  type Column,
  type SortColumnMap,
} from 'interface/stacks/settings/base/SettingsTable';
import {useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import WorkspaceMemberRow from '../WorkspaceMemberRow';

export type Props = {
  members: Array<BillingUsageMember>;
  loading: boolean;
};

const columns: Array<Column> = [
  {
    title: 'Member',
    width: '60%',
    isSortable: true,
    sort: (direction: 'asc' | 'desc') => (a, b) => {
      const aName = `${a.first_name} ${a.last_name}`;
      const bName = `${b.first_name} ${b.last_name}`;

      if (direction === 'asc') {
        return aName.localeCompare(bName);
      }

      return bName.localeCompare(aName);
    },
  },
  {title: 'Workspaces', width: '25%'},
  {title: 'Date Joined', width: '15%'},
];

export function WorkspaceUsage({members, loading}: Props) {
  const renderRows = useCallback(
    (sortedColumns: SortColumnMap, shouldSort: boolean) => {
      const sortedMembers = shouldSort
        ? members.sort((a: Member, b: Member) => {
            for (const column in sortedColumns) {
              const sortInfo = sortedColumns[column];
              const sortFn = sortInfo.sort(sortInfo.direction);
              const result = sortFn(a, b);
              if (result !== 0) {
                return result;
              }
            }
            return 0;
          })
        : members;

      return sortedMembers.map(member => (
        <WorkspaceMemberRow key={member.id} member={member} columns={columns} />
      ));
    },
    [members],
  );

  return (
    <View>
      <Typography variant="medium" size="md" color={theme.colors.neutral.$700}>
        <Trans>Workspace Members</Trans>
      </Typography>
      <Typography variant="regular" size="sm" color={theme.colors.neutral.$500}>
        <Trans>
          Below is a list of all members across all of your workspaces,
          including those who are not members of your org.
        </Trans>
      </Typography>
      <View style={styles.content}>
        {loading ? (
          <Loading />
        ) : members.length ? (
          <SettingsTable columns={columns} renderRows={renderRows} />
        ) : (
          <Typography
            variant="medium"
            size="base"
            color={theme.colors.neutral.$700}>
            No workspace members to show
          </Typography>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    marginTop: 16,
  },
});
