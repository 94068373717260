export function parseDate(date: Date): Date;
export function parseDate(date: string): Date;
export function parseDate(date: null): null;
export function parseDate(date: Date | null): Date | null;

// dates from API are in format "2018-07-07 20:50:56 UTC"
// change it to "2018-07-07T20:50:56Z" so safari browser could parse it
export function parseDate(date: Date | string | null) {
  if (date === null) return null;

  if (typeof date === 'string') {
    const [datePart, timePart] = date.split(' ');
    return new Date(`${datePart}T${timePart}Z`);
  }

  return date;
}

export const parseUpdatedAndCreated = (
  obj: {updated: Date; created: Date} | null,
) => {
  if (obj) {
    obj.updated = parseDate(obj.updated);
    obj.created = parseDate(obj.created);
  }
};

export const randomString = (length: number) => {
  const randomCharacters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = ' ';
  const charactersLength = randomCharacters.length;
  for (let i = 0; i < length; i++) {
    result += randomCharacters.charAt(
      Math.floor(Math.random() * charactersLength),
    );
  }
  return result;
};

export const atob =
  typeof window !== 'undefined'
    ? window.atob
    : (str: string) => Buffer.from(str, 'base64').toString();

export const isJWTExpired = (authToken: string): boolean => {
  try {
    const now = Date.now();
    const payloadEncoded = authToken.split('.').at(1) ?? '';
    const {expires} = JSON.parse(atob(payloadEncoded));
    return !expires || now >= expires * 1000;
  } catch (error) {
    console.error(
      `[Validation Error] - Parsing auth token: ${(error as Error)?.message}`,
      new Error().stack,
    );
    return true;
  }
};

export const formatNumber = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
