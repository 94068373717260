import {theme} from 'config/themes';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import RecentFilters from '../base/RecentFilters';
import RecentHeader from '../base/RecentHeader';
import RecentList from '../base/RecentList';
import {type Filters, initialFilter} from '../consts';
import {useRecentFiles} from '../hooks/useRecentFiles';

const RouteRecents = () => {
  const [filters, setFilters] = useState<Filters>(initialFilter);
  const recentFiles = useRecentFiles();

  const updateFilter = (key: string, value?: any | null) => {
    if (key === 'clear') {
      setFilters(initialFilter);
      return;
    }

    setFilters(prev => {
      if (key === 'people' || key === 'event' || key === 'type') {
        if (value === null) {
          return {...prev, [key]: []};
        }
        const selected = prev[key] as Array<string>;
        const currentValue = value as string;
        const isSelected = selected.includes(currentValue);

        if (isSelected) {
          return {
            ...prev,
            [key]: selected.filter(item => item !== currentValue),
          };
        }

        return {...prev, [key]: [...selected, currentValue]};
      }

      if (key === 'date') {
        return {...prev, [key]: value};
      }

      return prev;
    });
  };

  const isMinimal = screen.width < 600;
  const classes = {
    inner: [styles.inner, isMinimal && styles.minimal],
  };
  return (
    <View style={styles.root}>
      <View style={classes.inner}>
        <View style={styles.content}>
          <RecentHeader />
          <RecentFilters filters={filters} updateFilter={updateFilter} />
          <RecentList files={recentFiles} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: theme.files.fill,
  },
  inner: {
    flex: 1,
  },
  minimal: {
    marginHorizontal: 0,
  },
  content: {
    flex: 1,
  },
  main: {
    flex: 1,
  },
});

export default RouteRecents;
