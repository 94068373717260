import {useCallback} from 'react';

const useLegalNavigation = () => {
  const navigateToTerms = useCallback(() => {
    window.open('https://fast.io/terms/', '_blank');
  }, []);

  const navigateToPrivacy = useCallback(() => {
    window.open('https://fast.io/privacy/', '_blank');
  }, []);

  const navigateToContactUs = useCallback(() => {
    window.open('https://fast.io/contact', '_blank');
  }, []);

  const navigateToHelp = useCallback(() => {
    window.open('https://help.fast.io/', '_blank');
  }, []);

  return {
    navigateToTerms,
    navigateToPrivacy,
    navigateToContactUs,
    navigateToHelp,
  };
};

export default useLegalNavigation;
