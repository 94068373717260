import {api} from 'fast-sdk';
import {useCallback, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import store from 'store';
import * as sharedSlice from 'store/slices/shared';
import type {Share} from 'store/slices/shared/types';

export default function useArchiveShares() {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const archiveShares = useCallback(async (shares: Share[]) => {
    setLoading(true);
    try {
      const results = await Promise.all(
        shares.map(share => api.share.archiveShare(share.custom_name)),
      );
      const haveAtLeastOneSuccess = results.some(({result}) => result);
      if (haveAtLeastOneSuccess) {
        toast.show(
          shares.length === 1
            ? `You've successfully archived the "${shares[0].title}" share.`
            : `You've successfully archived (${shares.length}) shares.`,
          {
            type: 'neutral',
          },
        );
        results.forEach((result, index) => {
          if (result.result) {
            dispatch(sharedSlice.default.actions.archiveShare(shares[index]));
          }
        });
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const archiveSharesByFileId = async (fileIds: string[]) => {
    const shares = Object.values(store.getState().shared.shares);
    const sharesToArchive = shares.filter(share =>
      fileIds.includes(share.share_link?.id),
    );
    await archiveShares(sharesToArchive);
  };

  return {archiveShares, archiveSharesByFileId, loading};
}
