import theme from 'config/theme';
import {useHover} from 'interface/common/hooks/useHover';
import {
  Pressable,
  type StyleProp,
  StyleSheet,
  type ViewStyle,
} from 'react-native';

interface Props {
  children: React.ReactNode;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

export function HoveredView({children, onPress, style}: Props) {
  const {isHover, onHoverIn, onHoverOut} = useHover();

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      style={[style, isHover && styles.hovered]}>
      {children}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  hovered: {
    backgroundColor: theme.colors.neutral.$13,
  },
});
