import {resetSlice} from 'store/utils';
import {initialState} from '.';
import type {Actions, Store} from './types';

import type {ChatMessage} from 'fast-sdk/src/api/workspace/aichat/consts';
import {buildChat, buildChatMessage} from './helpers';

export function purge(state: Store) {
  return resetSlice(state, initialState);
}

export function setChats(state: Store, action: Actions['setChats']) {
  const {workspaceId, chats} = action.payload;

  chats.forEach(chat => {
    const existingMessages = state.chats[chat.chat_id]?.messages || [];
    state.chats[chat.chat_id] = buildChat(chat, workspaceId, existingMessages);
  });
}

export function setChatMessages(
  state: Store,
  action: Actions['setChatMessages'],
) {
  const {chatId, messages} = action.payload;
  state.chats[chatId].messages = messages.map(message =>
    buildChatMessage(message),
  );
}

export function addChatMessage(
  state: Store,
  action: Actions['addChatMessage'],
) {
  const {chatId, messageId, message, currentUserId} = action.payload;
  const newMessage: ChatMessage = {
    chat_id: chatId,
    message_id: messageId,
    creator: {
      id: currentUserId,
      type: 'user',
    },
    message: {
      text: message,
    },
    state: 'in_progress',
    created: new Date().toISOString(),
    updated: new Date().toISOString(),
    personality: '',
  };
  state.chats[chatId].messages.push(buildChatMessage(newMessage));
}

export function addChatResponse(
  state: Store,
  action: Actions['addChatResponse'],
) {
  const {chatId, messageId, response} = action.payload;
  const chat = state.chats[chatId];
  const message = chat.messages.find(m => m.id === messageId);
  if (message) {
    message.state = 'completed';
    message.response = {
      text: response,
    };
  }
}

export function setChatDetails(
  state: Store,
  action: Actions['setChatDetails'],
) {
  // TODO: Store chat details
}

export function setCurrentChat(
  state: Store,
  action: Actions['setCurrentChat'],
) {
  const {chatId} = action.payload;
  state.currentChat = chatId;
}
