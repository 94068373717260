export const primary = {
  brand: '#0070F0',
  dark: '#0e2866',
  medium: '#0045a6',
  light: '#e0eeff',
  shade: '#5d75af',
};

export const secondary = {
  green: '#66cc33',
  tan: '#feebc4',
  tanner: '#e2c18e',
  yellow: '#ffbf07',
  orange: '#ff8637',
  redOrange: '#ef5939',
  brightRed: '#e23b2a',
  darkRed: '#a72c21',
};

export const neutral = {
  black: '#000000',
  darker: '#1e232f',
  darkest: '#13171f',
  dark: '#282f3d',
  mid: '#888d9c',
  darkgrey: '#7f838d',
  light: '#b2b7c4',
  lighter: '#e8e9ec',
  lightest: '#f4f4f5',
  whitish: '#fafafa',
  white: '#ffffff',
  transparent: 'rgba(0, 0, 0, 0)',
};

export const files = {
  text: '#000000',
  code: '#000000',
  document: '#0070F0',
  image: '#66cc33',
  pdf: '#e23b2a',
  video: '#9900ff',
  audio: '#9900ff',
  spreadsheet: '#00a651',
  archive: '#ac8000',
  presentation: '#ef5939',
};

export const _primary = {
  blue: '#006CFA',
  errorBackground: '#FFE3DB',
  errorText: '#722A17',
  errorIcon: '#FE714D',
  successBackground: '#17CF79',
};

export const mainLightColors = {
  text: '#222835',
  backgroundColor: '#FFFFFF',
  buttonBackground: '#F4F4F5',
  buttonBorderColor: '#EFF0F0',
  tooltipBackground: '#222835',
  tooltipText: '#FFFFFF',
};

export const mainDarkColors = {
  backgroundColor: '#191E29',
  text: '#FFFFFF',
  buttonBackground: '#222835',
  buttonBorderColor: '#2E3441',
};
