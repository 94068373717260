/**
 * ```ts
 * enum SystemStatus {
 *  operational = 'operational'
 *  degradedPerformance = 'degraded_performance'
 *  partialOutage = 'partial_outage'
 *  majorOutage = 'major_outage'
 *  underMaintenance = 'under_maintenance';
 * }
 * ```
 */
export enum SystemStatus {
  operational = 'operational',
  degradedPerformance = 'degraded_performance',
  partialOutage = 'partial_outage',
  majorOutage = 'major_outage',
  underMaintenance = 'under_maintenance',
}

export type Subsystem = {
  status: SystemStatus;
  updated_at: string;
};

export type SystemStatusResponse = {
  updated: string;
  statuses: {
    API: Subsystem;
    downloads: Subsystem;
    uploads: Subsystem;
    billing: Subsystem;
    storage: Subsystem;
    encoding: Subsystem;
    assets: Subsystem;
    cache: Subsystem;
    database: Subsystem;
  };
};
