import {Pressable, StyleSheet, View} from 'react-native';
import {useNavigate} from 'react-router';

import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import {AppMemberAvatar} from 'interface/stacks/app/AppMemberAvatar';
import {FolderBreadcrumbs} from 'interface/stacks/workspace/storage/FolderBreadcrumbs';

import type {MultiplayerMember} from 'interface/multiplayer/types';
import type {GestureResponderEvent} from 'react-native';
import {ColumnManager} from './ColumnManager';

export interface StorageHeaderProps {
  folderId?: string;
  workspaceId?: string;
  workspaceName?: string;
  members?: MultiplayerMember[];
  showBackButton?: boolean;
  onFolderPress?: (e: GestureResponderEvent) => void;
}

/**
 * Header
 *
 * Main header for the app. Includes breadcrumbs, list of active team members, and panel toggles
 */
export function StorageHeader(props: StorageHeaderProps) {
  const navigate = useNavigate();

  return (
    <View style={styles.root}>
      <View style={styles.container}>
        {props.showBackButton && (
          <View style={styles.backButton}>
            <Pressable
              style={styles.back}
              onPress={() => navigate('storage/root')}>
              <Icon
                name="lucide:arrow-left"
                color={theme.colors.brand.$4Base}
                size={20}
              />
            </Pressable>
          </View>
        )}
        <FolderBreadcrumbs
          folderId={props.folderId}
          workspaceId={props.workspaceId}
          workspaceName={props.workspaceName}
          members={props.members}
          onFolderPress={props.onFolderPress}
        />
        <View style={styles.multiplayer}>
          {props.members && props.folderId && (
            <View style={styles.multiplayerAvatars}>
              {props.members.map(member => (
                <AppMemberAvatar
                  key={member.clientId}
                  member={member}
                  borderKind={'light'}
                />
              ))}
            </View>
          )}
        </View>
        <ColumnManager />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    padding: StyleSheet.hairlineWidth,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingStart: 8,
    paddingEnd: 12,
    borderRadius: 6,
    backgroundColor: theme.colors.neutral.$white,
  },
  backButton: {
    flexDirection: 'row',
    gap: 15,
  },
  back: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 36,
    height: 36,
  },
  multiplayer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 15,
    flex: 1,
  },
  multiplayerAvatars: {
    flexDirection: 'row-reverse',
    alignItems: 'center',
    paddingRight: 5,
  },
});
