import {useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import {NEW_CHAT} from './consts';

import {useWorkspaceMembers} from '../../hooks/useWorkspaceMembers';
import {AiChat} from './pages/AiChat';
import {AiChatList} from './pages/AiChatList';

interface Props {
  workspaceId: string;
  chatId?: string;
  messageId?: string;
}

export function WorkspaceAiChat({workspaceId, chatId, messageId}: Props) {
  const openChat = useSelector(aichat.selectors.getCurrentChat(workspaceId));
  const {members, isLoading: membersLoading} = useWorkspaceMembers(workspaceId);

  const dispatch = useDispatch();

  const handleNewChatPress = () => {
    handleChatPress(NEW_CHAT);
  };

  const handleChatPress = (chatId: string) => {
    dispatch(aichat.default.actions.setCurrentChat({chatId}));
  };

  useEffect(() => {
    if (chatId) {
      handleChatPress(chatId);
    }
  }, [chatId]);

  return (
    <View style={styles.container}>
      {!openChat && (
        <AiChatList
          workspaceId={workspaceId}
          onNewChatPress={handleNewChatPress}
          onChatPress={chat => handleChatPress(chat.id)}
          workspaceMembers={members}
        />
      )}
      {openChat && (
        <AiChat
          workspaceId={workspaceId}
          chatId={openChat}
          messageId={messageId}
          onBackPress={() => handleChatPress(null)}
          onNewChatPress={handleNewChatPress}
          workspaceMembers={members}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
});
