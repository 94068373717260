import {Loading} from 'interface/common/Loading';
import {NoResultsAvailable} from 'interface/common/NoResultsAvailable';
import {FileList} from 'interface/stacks/workspace/storage/FileList';
import {FileListItemLayout} from 'interface/stacks/workspace/storage/FileListItem';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {
  getIsListLoading,
  getWorkspaceShares,
} from 'store/slices/shared/selectors';
import SharedToolbar from './SharedToolbar';

interface SharedFoldersListProps {
  workspaceAlt: string;
  showArchived: boolean;
}

export function SharedFoldersList({
  workspaceAlt,
  showArchived,
}: SharedFoldersListProps) {
  const isSharesLoading = useSelector(getIsListLoading);
  const shares = useSelector(getWorkspaceShares(workspaceAlt, showArchived));

  const noShares = Object.keys(shares).length === 0;
  const showLoader = isSharesLoading && noShares;

  return (
    <View style={styles.sharedItemsContainer}>
      <SharedToolbar
        workspaceAlt={workspaceAlt}
        showArchived={showArchived}
        shares={Object.values(shares)}
      />
      {showLoader ? (
        <Loading centered />
      ) : noShares ? (
        <NoResultsAvailable
          message={
            showArchived
              ? 'No archived shares.'
              : 'No shares available for this workspace'
          }
        />
      ) : (
        <FileList
          view={Object.keys(shares)}
          items={shares}
          root={['root']}
          options={{
            disableDetails: true,
            disableSelect: true,
            disableDrag: true,
            disableActions: showArchived,
          }}
          shareds
          archivedShares={showArchived}
          layout={showArchived ? FileListItemLayout.ListNormal : undefined}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  sharedItemsContainer: {
    flex: 1,
  },
});
