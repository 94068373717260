import {useParams} from 'extensions/navigation';
import {AppLoading} from 'interface/stacks/app/AppLoading';
import ContentViewer from 'interface/stacks/content-viewer/ContentViewer';
import {ShareNotAvailableCard} from 'interface/stacks/share/layout/ShareNotAvailableCard';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import contentViewer from 'store/slices/content-viewer';
import useGetQuickShare from '../hooks/useGetQuickShare';

const QuickShareViewer = () => {
  const {quickShareId} = useParams();
  const dispatch = useDispatch();

  const {file, isLoading} = useGetQuickShare(quickShareId);

  useEffect(() => {
    dispatch(contentViewer.actions.setNeighbors({prev: null, next: null}));
  }, []);

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <ContentViewer
      file={file}
      emptyState={<ShareNotAvailableCard isQuickShare />}
    />
  );
};

export default QuickShareViewer;
