import theme from 'config/theme';
import {InfoPanelDetailsTab} from 'interface/stacks/workspace/layout/WorkspaceInfoPanel/components/InfoPanelDetailsTab';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import Tabs, {Tab} from '../components/side-bar/Tabs';
import {HEIGHT_TOP_TOOLBAR} from './TopToolbar';

type Props = {
  onClose: () => void;
  file: FilesItem;
  parent: FilesItem;
};

const Sidebar = ({onClose, file, parent}: Props) => {
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.FILE_INFO);

  const onChangeTab = (tab: Tab) => setSelectedTab(tab);

  // Hidding comments for now
  const showComments = !file.quickShare && false;

  return (
    <View style={styles.root}>
      <Tabs
        onClose={onClose}
        onChangeTab={onChangeTab}
        selected={selectedTab}
        showComments={showComments}
      />
      {selectedTab === Tab.FILE_INFO && <InfoPanelDetailsTab item={file} />}
      {selectedTab === Tab.COMMENTS && <View />}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: 350,
    backgroundColor: theme.colors.neutral.$1,
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.neutral.$black,
    borderTopWidth: 1,
    borderTopColor: theme.colors.neutral.$black,
  },
  bottom: {
    height: `calc(100vh - ${HEIGHT_TOP_TOOLBAR}px)`,
    flexBasis: 0,
  },
});

export default Sidebar;
