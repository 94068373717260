import {useNavigate} from 'extensions/navigation';
import type {Share} from 'store/slices/shared/types';
import useGenerateShareUrl from './useGenerateShareUrl';

const useNavigateToShare = () => {
  const navigate = useNavigate();
  const {generateShareUrl} = useGenerateShareUrl();

  const navigateToShare = (share: Share) => {
    const shareUrl = generateShareUrl(share);
    navigate(shareUrl, {replace: true, state: {showWorkspaceLayout: true}});
  };

  return {
    navigateToShare,
  };
};

export default useNavigateToShare;
