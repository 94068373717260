import activity from 'store/slices/activity';
import app from 'store/slices/app';
import errors from 'store/slices/errors';
import events from 'store/slices/events';
import files from 'store/slices/files';
import onboarding from 'store/slices/onboarding';
import quickShare from 'store/slices/quick-share';
import settings from 'store/slices/settings';
import shared from 'store/slices/shared';
import user from 'store/slices/user';
import users from 'store/slices/users';
import workspace from 'store/slices/workspace';
import contentViewer from './content-viewer';
import organization from './organization';

import aichat from './aichat';

export default {
  app,
  activity,
  files,
  user,
  users,
  workspace,
  settings,
  shared,
  quickShare,
  onboarding,
  errors,
  contentViewer,
  events,
  organization,
  aichat,
};
