import {dateSplit} from 'interface/stacks/workspace/shared/base/consts';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export const dateFormatter = (
  currentDate: Date,
  targetDate: Date,
  timePast: number,
  getFullDate?: boolean,
) => {
  const {
    seconds: currentSecconds,
    minutes: currentMinutes,
    hour: currentHours,
  } = dateSplit(currentDate);
  const {seconds, minutes, hour, day, year, monthName} = dateSplit(targetDate);

  if (getFullDate) {
    return `${monthName} ${day}, ${year} at ${hour % 12}:${minutes < 10 ? `${minutes}0` : minutes} ${hour < 12 ? 'AM' : 'PM'}`;
  }

  if (timePast > 24 * HOUR) {
    return `${monthName} ${day}`;
  }
  if (timePast > 1 * HOUR) {
    return `${currentHours - hour} hours ago`;
  }
  if (timePast > 1 * MINUTE) {
    return `${currentMinutes - minutes} minutes ago`;
  }
  if (timePast > 1 * SECOND) {
    return `${currentSecconds - seconds} seconds ago`;
  }
  return 'just now';
};

export const VARIANT = {
  dark: 'dark',
  light: 'light',
} as const;

export type Variant = keyof typeof VARIANT;
