import theme from 'config/theme';
import type {ShareLink} from 'fast-sdk/src/api/share/consts';
import {HoveredView} from 'interface/base/HoveredView';
import Typography from 'interface/base/Typography';
import useOpenLinkInNewTab from 'interface/common/hooks/useOpenLinkInNewTab';
import {StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';

interface Props {
  share: Share;
}

export default function ShareLinks({share}: Props) {
  const {openExternalLink} = useOpenLinkInNewTab();

  const links = [share.link_1, share.link_2, share.link_3].filter(Boolean);

  const openShareLink = (link: ShareLink) => {
    openExternalLink(link.url);
  };

  return (
    <View style={styles.root}>
      {links.map((link, index) => (
        <HoveredView
          key={`share-link-${index}`}
          onPress={() => openShareLink(link)}
          style={styles.link}>
          <Typography size="xs" color={theme.colors.neutral.$2Base}>
            {link.title}
          </Typography>
        </HoveredView>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    gap: 8,
  },
  link: {
    borderRadius: 6,
    backgroundColor: theme.colors.neutral.$50,
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
});
