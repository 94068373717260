import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {
  ROUTES,
  WORKSPACE_SHARED,
  WORKSPACE_SHARED_ARCHIVED,
} from 'constants/routes';
import CircleButton from 'interface/common/CircleButton';
import {WorkspaceButton} from 'interface/stacks/workspace/base/WorkspaceButton';
import {FolderLayoutSwitchButton} from 'interface/stacks/workspace/storage/FolderLayoutSwitchButton';
import {StyleSheet, Text, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as shared from 'store/slices/shared';
import type {Share} from 'store/slices/shared/types';
import useDeleteShares from '../../hooks/useDeleteShares';

interface SharedToolbarProps {
  workspaceAlt: string;
  showArchived: boolean;
  shares: Share[];
}

export default function SharedToolbar({
  workspaceAlt,
  shares,
  showArchived,
}: SharedToolbarProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sort = useSelector(shared.selectors.getSort);

  const {deleteShares} = useDeleteShares();

  const handleOrderSortChange = () => {
    const newOrder = isOrderSortAsc ? 'desc' : 'asc';
    dispatch(
      shared.default.actions.sort({
        order: newOrder,
        category: 'date',
      }),
    );
  };

  const baseUrl = `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspaceAlt}`;

  const handleShowArchived = () => {
    navigate(`${baseUrl}/${WORKSPACE_SHARED_ARCHIVED}`, {
      replace: true,
    });
  };

  const handleGoBack = () => {
    navigate(`${baseUrl}/${WORKSPACE_SHARED}`, {
      replace: true,
    });
  };

  const handleDeleteAllArchived = () => {
    deleteShares(shares);
  };

  const orderSort = sort?.order ?? 'asc';
  const isOrderSortAsc = orderSort === 'asc';

  return (
    <View style={styles.root}>
      <View style={styles.leftContainer}>
        {showArchived && (
          <CircleButton
            type="Primary"
            iconName="arrow-left"
            showBorder={false}
            onPress={handleGoBack}
          />
        )}
        <Text style={styles.title}>
          <Trans>{showArchived ? 'Archived Shares' : 'Shares'}</Trans>
        </Text>
      </View>
      <View style={styles.actions}>
        {showArchived ? (
          <>
            {shares.length > 0 && (
              <WorkspaceButton
                label={'Delete All'}
                onPress={handleDeleteAllArchived}
                disabled={shares.length === 0}
                icon={
                  <MCIcon
                    name={'archive-outline'}
                    size={20}
                    color={theme.colors.danger.$4Base}
                  />
                }
              />
            )}
          </>
        ) : (
          <>
            <WorkspaceButton
              label={isOrderSortAsc ? 'Oldest' : 'Newest'}
              onPress={handleOrderSortChange}
              icon={
                <Icon
                  name={'filter-list'}
                  size={20}
                  color={theme.colors.neutral.$5}
                  style={{
                    transform: [{rotate: isOrderSortAsc ? '180deg' : '0deg'}],
                  }}
                />
              }
            />
            <WorkspaceButton
              label={'Archived'}
              onPress={handleShowArchived}
              icon={
                <MCIcon
                  name={'archive-outline'}
                  size={20}
                  color={theme.colors.neutral.$5}
                />
              }
            />
            <FolderLayoutSwitchButton />
          </>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 10,
  },
  title: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 36,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
  },
  dropdowns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 16,
  },
  button: {
    height: 36,
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
});
