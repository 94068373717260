import {resetSlice} from 'store/utils';
import {initialState} from '.';
import type {Actions, Store} from './types';

export function addUser(state: Store, action: Actions['AddUser']) {
  const {user} = action.payload;
  return {
    ...state,
    items: {...state.items, [user.id]: user},
  };
}

export function purge(state: Store) {
  return resetSlice(state, initialState);
}
