import {api} from 'fast-sdk';
import {useDispatch} from 'react-redux';
import * as settings from 'store/slices/settings';
import {blobToBase64} from 'utils/common/image';

export const useGetOrganizationLogo = () => {
  const dispatch = useDispatch();

  const setLogo = (logo: string) => {
    if (logo) {
      dispatch(settings.default.actions.setOrganizationLogo({logo}));
    }
  };

  const fetchOrganizationLogo = async (subdomain: string) => {
    const logo = await api.organization.getAsset(subdomain, 'logo');
    blobToBase64(logo, setLogo);
  };

  return {fetchOrganizationLogo};
};
