import {t} from '@lingui/macro';
import theme from 'config/theme';
import {api} from 'fast-sdk';
import {Button} from 'interface/common/Button';
import {Loading} from 'interface/common/Loading';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {ProfileMemberList} from 'interface/stacks/app/ProfileMemberList';
import {useCallback, useEffect, useState} from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';

import type {
  WorkspaceInvitation,
  WorkspaceListDetail,
  WorkspaceMember,
} from 'fast-sdk/src/api/workspace/consts';
import AddEmailTextarea from 'interface/base/AddEmailTextarea';
import {ToggleWithLabel} from 'interface/base/ToggleWithLabel';
import usePermissionsPicker from 'interface/common/hooks/usePermissionsPicker';

interface WorkspaceSettingsMembersProps {
  workspace: WorkspaceListDetail;
  selfId: string;
  close: () => void;
}

export function WorkspaceSettingsMembers(props: WorkspaceSettingsMembersProps) {
  const toast = useToast();
  const {subdomain} = useSubDomain();

  const [loadingMembers, setLoadingMembers] = useState(true);
  const [sendingInvites, setSendingInvites] = useState(false);
  const [hasSuggestions, setHasSuggestions] = useState(false);
  const {permission, PickerComponent} = usePermissionsPicker(
    'member',
    sendingInvites,
  );
  const [emails, setEmails] = useState<Array<string>>([]);
  const [members, setMembers] = useState<Array<WorkspaceMember> | null>(null);
  const [invitations, setInvitations] =
    useState<Array<WorkspaceInvitation> | null>(null);
  const [inviteToOrg, setInviteToOrg] = useState(false);

  const hasLoading = loadingMembers && !members && !invitations;
  const hasMemberList =
    (members && members.length > 0) || (invitations && invitations.length > 0);

  const fetchMembers = useCallback(async () => {
    setLoadingMembers(true);
    const [members, invitations] = await Promise.all([
      api.workspace.getWorkspaceMembers(props.workspace.folder_name),
      api.workspace.getWorkspaceInvitations(props.workspace.folder_name),
    ]);
    if (members.result) setMembers(members.users);
    if (invitations.result) setInvitations(invitations.invitations);
    setLoadingMembers(false);
  }, [props.workspace.folder_name]);

  const inviteMembers = async () => {
    setSendingInvites(true);
    if (emails) {
      const sucEmails = [];
      const errEmails = [];
      const message = `You've been invited to the ${props.workspace.name} workspace`;
      for (const email of emails) {
        const {result} = await api.workspace.addMember(
          props.workspace.folder_name,
          email,
          permission,
          message,
        );
        if (inviteToOrg) {
          api.organization.addMember(
            props.workspace.org_domain,
            email,
            permission,
            message,
          );
        }
        result ? sucEmails.push(email) : errEmails.push(email);
      }
      if (sucEmails.length === emails.length)
        toast.show(
          t`Existing org members added to workspace, email invites sent to new members`,
          {type: 'success'},
        );
      else
        toast.show(t`Unable to send emails to: ${errEmails.join(', ')}`, {
          type: 'danger',
        });
    }
    setSendingInvites(false);
    fetchMembers();
    setEmails([]);
  };

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  return (
    <View style={[hasLoading && {marginBottom: 24}]}>
      <AddEmailTextarea
        emails={emails}
        setEmails={setEmails}
        customRootStyle={{paddingBottom: 4}}
      />
      <ToggleWithLabel
        label="Invite to org"
        onChange={setInviteToOrg}
        checked={inviteToOrg}
        showHover={false}
      />
      <View style={styles.buttonsContainer}>
        {hasLoading && (
          <View style={styles.status}>
            <Loading size="small" />
            <Text style={styles.message}>{t`Loading Members...`}</Text>
          </View>
        )}
        <View style={{flex: 1}} />
        {PickerComponent}
        <Button
          label={t`Invite`}
          type="Primary"
          fontStyle="Normal, Small"
          onPress={inviteMembers}
          customRootStyle={styles.rootButton}
          loading={sendingInvites}
        />
      </View>
      {hasMemberList && (
        <ScrollView style={styles.members}>
          <ProfileMemberList
            members={members}
            invites={invitations}
            selfId={props.selfId}
            profileId={props.workspace.folder_name}
            subdomain={subdomain}
            reload={fetchMembers}
            type="workspace"
          />
        </ScrollView>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    minHeight: 36,
    gap: 10,
  },
  rootButton: {
    height: 36,
  },
  textarea: {
    marginBottom: 10,
  },
  members: {
    marginTop: 24,
    paddingBottom: 24,
    minHeight: 260,
    maxHeight: '33vh',
    flex: 1,
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    gap: 10,
  },
  message: {
    color: theme.colors.neutral.$2Base,
    fontSize: 12,
  },
});
