import type React from 'react';
import {View} from 'react-native';
import {QuickShareActions} from './QuickShareActions';
import {RegularActions} from './RegularActions';
import {styles} from './styles';
import type {ActionButtonProps} from './types';

const ActionButtons: React.FC<ActionButtonProps> = props => {
  const isQuickShare = Boolean(props.file.quickShare);

  return (
    <View style={styles.root}>
      {isQuickShare ? (
        <QuickShareActions {...props} />
      ) : (
        <RegularActions {...props} />
      )}
    </View>
  );
};

export default ActionButtons;
