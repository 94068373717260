import theme from 'config/theme';
import {format} from 'date-fns';
import type {ShareType} from 'fast-sdk/src/api/share/consts';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ShareTypeColor, ShareTypeIcon} from 'utils/fast/shares';

interface Props {
  expirationDate: Date;
  shareType: ShareType;
}

export function ShareExpiration({expirationDate, shareType}: Props) {
  return (
    <View style={[styles.root, {backgroundColor: ShareTypeColor[shareType]}]}>
      <Icon
        name={ShareTypeIcon[shareType]}
        color={theme.colors.neutral.$2Base}
      />
      <Typography size="xs" color={theme.colors.neutral.$2Base}>
        Expires {`${format(expirationDate, 'MM/dd/yy')}`}
      </Typography>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 100,
  },
});
