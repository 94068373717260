import analytics from 'extensions/analytics';
import {api} from 'fast-sdk';
import type {InvitationDetails} from 'fast-sdk/src/api/share/consts';
import {authDb} from 'store';
import type {Member} from 'store/slices/settings/types';
import type {Organization} from 'store/slices/user/types';

export default {
  setAuthToken: (token: string) => authDb.setItem('session_token', token),
  getAuthToken: () => authDb.getItem('session_token'),
  setUserEmail: (email: string) => authDb.setItem('User_Email', email),
  getUserEmail: () => authDb.getItem('User_Email'),
  setResetPasswordEmail: (email: string) =>
    authDb.setItem('Reset_Password_Email', email),
  getResetPasswordEmail: () => authDb.getItem('Reset_Password_Email'),
  clearDB: () => authDb.clearAll(),
};

export const organizationFormatter = async (
  org: Organization,
): Promise<OrganizationSummary> => {
  return {
    id: org.id,
    accent_color: org.accent_color,
    closed: org.closed,
    domain: org.domain,
    locked: org.locked,
    logo: org.logo,
    name: org.name,
    suspended: org.suspended,
  };
};

export const getOrganizationMembersDetails = async (
  orgId: string,
): Promise<Array<Member>> => {
  const result: Array<Member> = [];
  const response = await api.organization.getOrganizationMembers(orgId);

  if (response.result) {
    for (const member of response.users) {
      result.push({
        authentication: '',
        dateJoined: null,
        email: member.email_address,
        firstName: member.first_name,
        lastName: member.last_name,
        profilePic: member.profile_pic,
        id: member.id,
        permissions: member.permissions,
      });
    }
  }

  return result;
};

export const getOrganizations = async (
  orgsId: Array<string>,
): Promise<Array<OrganizationSummary>> => {
  const result: Array<OrganizationSummary> = [];
  for (const orgId of orgsId) {
    try {
      const org = await api.organization.getOrganizationDetails(orgId);
      if (org.result) {
        const orgDetails = await organizationFormatter(org.org);
        result.push(orgDetails);
      }
    } catch (err) {
      analytics.notify(err);
    }
  }
  return result;
};

export type OrganizationSummary = {
  id: string;
  name: string;
  logo?: string;
  accent_color?: {
    color: string;
    opacity: number;
  };
  locked: boolean;
  suspended: boolean;
  closed: boolean;
  domain: string;
};

export type OrganizationSummaryPending = OrganizationSummary & {
  invitedBy: {
    email: string;
  };
};

export type PendingInvitationDetails = InvitationDetails & {
  hasClickedOnJoined?: boolean;
  invitationToken: string;
};
