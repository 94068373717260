import theme from 'config/theme';
import React, {useEffect, useRef, useState} from 'react';
import {Animated, type LayoutChangeEvent, StyleSheet, View} from 'react-native';

interface GradientProgressBarProps {
  duration?: number;
}

const DEFAULT_DURATION = 4000;

const GradientProgressBar = ({
  duration = DEFAULT_DURATION,
}: GradientProgressBarProps) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const progressAnim = useRef(new Animated.Value(0)).current;
  const linearGradientColors = ['#0469FF', '#CB37FF', '#FF4C57'];

  const onLayout = (event: LayoutChangeEvent) => {
    const {width} = event.nativeEvent.layout;
    setContainerWidth(width);
  };

  useEffect(() => {
    if (containerWidth > 0) {
      Animated.timing(progressAnim, {
        toValue: containerWidth,
        duration: duration,
        useNativeDriver: false,
      }).start();
    }
  }, [containerWidth]);

  return (
    <View style={styles.container} onLayout={onLayout}>
      <View style={styles.progressBackground}>
        <Animated.View
          style={[
            styles.progressBar,
            {
              width: progressAnim,
              // @ts-expect-error
              backgroundImage: `linear-gradient(to right, ${linearGradientColors.join(', ')})`,
            },
          ]}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  progressBackground: {
    height: 6,
    backgroundColor: theme.colors.neutral.$11,
    borderRadius: 4,
    overflow: 'hidden',
  },
  progressBar: {
    height: 6,
    borderRadius: 4,
  },
});

export default GradientProgressBar;
