import React from 'react';
import {StyleSheet, View} from 'react-native';
import OrganizationHeaderSkeleton from './OrganizationHeaderSkeleton';
import SharesTableSkeleton from './ShareTableRowsSkeleton';
import WorkspacesCardsSkeleton from './WorkspacesCardsSkeleton';

const ORGANIZATION_SKELETON_ROWS = 2;

const PageSkeleton = ({isWorkspaces = true}: {isWorkspaces?: boolean}) => {
  return (
    <View style={styles.root}>
      {[...Array(ORGANIZATION_SKELETON_ROWS)].map((_, index) => (
        <View key={`org-section-${index}`} style={styles.container}>
          <OrganizationHeaderSkeleton />
          {isWorkspaces ? <WorkspacesCardsSkeleton /> : <SharesTableSkeleton />}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    gap: 48,
  },
  container: {
    flex: 1,
  },
});

export default PageSkeleton;
