import {api} from 'fast-sdk';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import * as user from 'store/slices/user';

export default function useFetchOrgs() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const fetchOrganizations = async () => {
    try {
      const {result, orgs} =
        await api.organization.getListOfAccessibleOrganizations();
      if (result) {
        dispatch(user.default.actions.setOrganizationsList({orgsList: orgs}));
      }
    } finally {
      setLoading(false);
    }
  };

  return {fetchOrganizations, loading};
}
