import theme from 'config/theme';
import {StyleSheet, View} from 'react-native';
import useContentViewerMode from '../hooks/useContentViewerMode';

export const HEIGHT_CONTROL_BAR = 52;
export const HEIGHT_CONTROL_BAR_THUMBNAIL = 44;

type Props = {
  isThumbnail?: boolean;
  left?: React.ReactNode;
  middle?: React.ReactNode;
  right?: React.ReactNode;
  top?: React.ReactNode;
};

const ControlBar = ({left, middle, right, top}: Props) => {
  const {isThumbnail} = useContentViewerMode();
  const renderLeft = Boolean(left);
  const renderMiddle = !isThumbnail && Boolean(middle);
  const renderRight = Boolean(right);
  const renderTop = Boolean(top);

  return (
    <View style={[styles.root, isThumbnail && styles.rootThumbnail]}>
      {renderTop && <View>{top}</View>}
      <View style={[styles.controls, isThumbnail && styles.controlsThumbnail]}>
        {renderLeft && <View style={styles.left}>{left}</View>}
        {renderMiddle && <View style={styles.middle}>{middle}</View>}
        {renderRight && <View style={styles.right}>{right}</View>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
  },
  rootThumbnail: {
    position: 'absolute',
    height: 'unset',
    bottom: 0,
  },
  controls: {
    height: HEIGHT_CONTROL_BAR,
    backgroundColor: theme.colors.neutral.$1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
  },
  controlsThumbnail: {
    height: HEIGHT_CONTROL_BAR_THUMBNAIL,
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
  },
  middle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
  },
});

export default ControlBar;
