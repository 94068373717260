import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {useFetchChatList} from 'interface/stacks/workspace/hooks/useFetchChatList';
import {useEffect} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import * as workspaceStore from 'store/slices/workspace';
import {AiChatListHeader} from '../components/AiChatListHeader';

import {AiChatItem} from '../components/AiChatItem';

import {groupChatsByDay} from '../helpers';

import type {EntityChat} from 'store/slices/aichat/types';
import type {Member} from 'store/slices/settings/types';

interface Props {
  workspaceId: string;
  onChatPress: (chat: EntityChat) => void;
  onNewChatPress: () => void;
  workspaceMembers: Member[];
}

const GROUP_BY_DAY = {
  today: 'Today',
  yesterday: 'Yesterday',
  older: 'Older',
};

export function AiChatList({
  workspaceId,
  onChatPress,
  onNewChatPress,
  workspaceMembers,
}: Props) {
  const dispatch = useDispatch();

  const workspace = useSelector(
    workspaceStore.selectors.getWorkspaceById(workspaceId),
  );
  const chats = useSelector(aichat.selectors.getChats(workspaceId));
  const workspaceFolderName = workspace?.folder_name;
  const {isLoading: chatsLoading, fetchChatList} = useFetchChatList();

  const fetchChats = async () => {
    const chats = await fetchChatList(workspaceFolderName);
    dispatch(aichat.default.actions.setChats({workspaceId, chats}));
  };

  useEffect(() => {
    fetchChats();
  }, [workspaceId]);

  const groupedChats = groupChatsByDay(chats);

  return (
    <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      <AiChatListHeader onNewChatPress={onNewChatPress} />

      {chatsLoading ? (
        <Typography>Loading...</Typography>
      ) : (
        <View style={styles.chatsContainer}>
          {groupedChats &&
            Object.entries(groupedChats).map(([key, value]) => (
              <>
                {value.length > 0 && (
                  <Typography
                    overrides={styles.groupTitle}
                    size="sm"
                    color={theme.colors.neutral.$500}>
                    {GROUP_BY_DAY[key]}
                  </Typography>
                )}
                <View style={styles.chatList}>
                  {value.map(chat => (
                    <AiChatItem
                      key={chat.id}
                      chat={chat}
                      workspaceMembers={workspaceMembers}
                      onPress={() => onChatPress(chat)}
                    />
                  ))}
                </View>
              </>
            ))}
        </View>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    // padding: 16,
  },
  groupTitle: {
    marginVertical: 16,
  },
  chatsContainer: {
    paddingHorizontal: 16,
  },
  chatList: {
    gap: 8,
  },
});
