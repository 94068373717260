import theme from 'config/theme';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import type {Status} from './hooks/useSystemStatus';

interface Props {
  status: Status;
  width?: number;
  height?: number;
}

export default function StatusIndicator({status, width, height}: Props) {
  const COLOR = {
    operational: styles.green,
    degraded: styles.yellow,
    down: styles.red,
  };

  const size = {width: width || 8, height: height || 8};

  return <View style={[styles.root, COLOR[status], size]} />;
}

const styles = StyleSheet.create({
  root: {
    borderRadius: 99,
  },
  green: {
    backgroundColor: theme.colors.success.$4Base,
  },
  yellow: {
    backgroundColor: theme.colors.caution.$4Base,
  },
  red: {
    backgroundColor: theme.colors.danger.$4Base,
  },
});
