import theme from 'config/theme';
import {Icon as LucideIcon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {useState} from 'react';
import {Pressable, StyleSheet, type ViewStyle} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

interface Props extends React.ComponentProps<typeof Pressable> {
  icon?: React.ReactNode;
  iconName?: string;
  onPress: () => void;
  label: string;
  customStyle?: ViewStyle;
  showDivider?: boolean;
}

export function DropdownButton(props: Props) {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <Pressable
      style={[
        styles.actionButton,
        props.customStyle,
        {
          backgroundColor: isHover
            ? theme.colors.neutral.$13
            : theme.colors.neutral.$white,
        },
        props.showDivider && styles.divider,
      ]}
      onPress={props.onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      aria-label={props.label}>
      {props.icon ? (
        props.icon
      ) : props.iconName?.includes('lucide') ? (
        <LucideIcon
          name={props.iconName}
          color={
            isHover ? theme.colors.neutral.$2Base : theme.colors.neutral.$6
          }
          size={20}
        />
      ) : (
        <Icon
          name={props.iconName}
          color={
            isHover ? theme.colors.neutral.$2Base : theme.colors.neutral.$6
          }
          size={20}
        />
      )}
      <Typography size="sm" color={theme.colors.neutral.$2Base}>
        {props.label}
      </Typography>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  actionButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 30,
    gap: 8,
  },
  divider: {
    borderBottomColor: theme.colors.neutral.$11,
    borderBottomWidth: 1,
  },
});
