import {api} from 'fast-sdk';
import {useCallback, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import * as sharedSlice from 'store/slices/shared';
import type {Share} from 'store/slices/shared/types';

export default function useUnarchiveShare() {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const unarchiveShare = useCallback(async (share: Share) => {
    setLoading(true);
    try {
      const {result} = await api.share.unarchiveShare(share.custom_name);
      if (result) {
        toast.show(`"${share.title}" restored.`, {
          type: 'neutral',
        });
        dispatch(sharedSlice.default.actions.unarchiveShare(share));
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return {unarchiveShare, loading};
}
