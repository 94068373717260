import {IconButton} from 'interface/common/IconButton';
import useFileMenu from 'interface/stacks/content-viewer/hooks/useFileMenu';
import type React from 'react';
import ActionIcon from './ActionIcon';
import {styles} from './styles';
import type {ActionButtonProps} from './types';

export const RegularActions: React.FC<ActionButtonProps> = ({
  toggleSidebar,
  file,
}) => {
  const {menu, ref} = useFileMenu(file);

  return (
    <>
      <IconButton
        buttonStyle={styles.iconButton}
        iconStyle={styles.icon}
        buttonHoverStyle={styles.iconButtonHover}
        onPress={toggleSidebar}
        icon={<ActionIcon name="information-outline" />}
      />
      <IconButton
        reference={ref}
        buttonStyle={styles.iconButton}
        iconStyle={styles.icon}
        buttonHoverStyle={styles.iconButtonHover}
        onPress={menu}
        icon={<ActionIcon name="dots-horizontal" />}
      />
      {/* <IconButton
        buttonStyle={styles.shareButton}
        iconStyle={styles.icon}
        buttonHoverStyle={styles.shareButtonHover}
        textStyle={styles.shareButtonText}
        text="Share"
        onPress={() => {}}
        icon={<ActionIcon name="share" />}
      /> */}
    </>
  );
};
