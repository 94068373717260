import {api} from 'fast-sdk';
import {useDispatch} from 'react-redux';
import * as user from 'store/slices/user';
import {blobToBase64} from 'utils/common/image';

export const useGetUserProfilePhoto = () => {
  const dispatch = useDispatch();

  const setProfilePic = (profilePic: string) => {
    if (profilePic) {
      dispatch(user.default.actions.setUserProfilePic({img: profilePic}));
    }
  };

  const fetchUserProfilePhoto = async (userId: string) => {
    const profilePic = await api.user.getProfilePhoto(userId);
    blobToBase64(profilePic, setProfilePic);
  };

  return {fetchUserProfilePhoto};
};
