import {useVideoQualityOptions} from '@vidstack/react';
import CommentButton from 'interface/stacks/content-viewer/components/control-bar/CommentButton';
import FullscreenButton from 'interface/stacks/content-viewer/components/control-bar/FullscreenButton';
import QualityButton from 'interface/stacks/content-viewer/components/control-bar/QualityButton';
import useContentViewerMode from 'interface/stacks/content-viewer/hooks/useContentViewerMode';
import useVideo from 'interface/stacks/content-viewer/hooks/useVideo';
import VideoTimeDisplay from '../VideoTimeDisplay';

const ControlsRight = () => {
  const {isThumbnail} = useContentViewerMode();
  const {video, handler} = useVideo();
  const options = useVideoQualityOptions({auto: true, sort: 'descending'});
  const currentQualityHeight = options.selectedQuality?.height;
  const qualityLabel =
    options.selectedValue !== 'auto' && currentQualityHeight
      ? `${currentQualityHeight}p`
      : `Auto${currentQualityHeight ? ` (${currentQualityHeight}p)` : ''}`;

  const toggleFullscreen = () => handler.toggleFullscreen();

  return (
    <>
      {!isThumbnail && (
        <QualityButton
          qualities={options}
          quality={options.selectedValue}
          qualityLabel={qualityLabel}
        />
      )}
      {!isThumbnail && <CommentButton />}
      {isThumbnail && <VideoTimeDisplay />}
      <FullscreenButton
        fullscreen={video.fullscreen}
        toggleFullscreen={toggleFullscreen}
      />
    </>
  );
};

export default ControlsRight;
