import {isAfter, isBefore} from 'date-fns';
import {useState} from 'react';

export enum DateRangeBoundary {
  START = 'start',
  END = 'end',
}

const getTomorrowDate = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

const START_DATE = new Date();
const END_DATE = getTomorrowDate();

const useDateRange = (startDate?: Date, endDate?: Date) => {
  const [start, setStart] = useState(startDate ?? START_DATE);
  const [end, setEnd] = useState(endDate ?? END_DATE);
  const [boundary, setBoundary] = useState<DateRangeBoundary | null>(null);

  const reset = () => {
    setStart(START_DATE);
    setEnd(END_DATE);
    setBoundary(null);
  };

  const handleChangeStart = (date: Date) => {
    setStart(date);
    if (boundary === DateRangeBoundary.START) {
      if (isAfter(date, end)) {
        setBoundary(DateRangeBoundary.END);
        setStart(end);
        setEnd(date);
      }
    }
  };

  const handleChangeEnd = (date: Date) => {
    setEnd(date);
    if (boundary === DateRangeBoundary.END) {
      if (isBefore(date, start)) {
        setBoundary(DateRangeBoundary.START);
        setEnd(start);
        setStart(date);
      }
    }
  };

  return {
    start,
    end,
    boundary,
    handleChangeStart,
    handleChangeEnd,
    setBoundary,
    reset,
  };
};

export default useDateRange;
