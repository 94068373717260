import {TOAST_AUTO_HIDE_DELAY_MS} from 'constants/errors';
import {useState} from 'react';
import {Toast} from 'react-native-toast-notifications';

interface UseRetryProps {
  initialRetries: number;
  retryFn: () => Promise<{result: boolean}>;
  toastId: string;
  initialMessage: string;
}

export const useToastRetry = ({
  initialRetries,
  retryFn,
  toastId,
  initialMessage,
}: UseRetryProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(initialMessage);
  const [remainingRetries, setRemainingRetries] = useState(initialRetries);

  const handleRetry = async () => {
    setIsLoading(true);
    try {
      const {result} = await retryFn();
      if (result) {
        Toast.update(toastId, 'The request was processed successfully', {
          duration: TOAST_AUTO_HIDE_DELAY_MS,
          type: 'success',
        });
      } else {
        throw new Error('Retry unsuccessful');
      }
    } catch (error) {
      setCurrentMessage('There is still an issue with this request');
      const newRetries = remainingRetries - 1;
      setRemainingRetries(newRetries);
      if (newRetries === 0) {
        setTimeout(() => Toast.hide(toastId), TOAST_AUTO_HIDE_DELAY_MS);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, currentMessage, remainingRetries, handleRetry};
};
