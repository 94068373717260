import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {Navigate, useNavigate, useParams} from 'extensions/navigation';
import {useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {Login} from './Login';
import {Header} from './layout/Header';

export function Access() {
  const {type, id} = useParams();
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const navigate = useNavigate();

  const renderResult = (type: string) => {
    switch (type) {
      case 'organization':
        return <Login type="Organization" />;
      case 'workspace':
        return <Login type="Workspace" />;
      case 'file':
        return <Login type="File" />;
      case 'share':
        return <Login type="Share" />;
      default:
        return <Navigate to={`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`} />;
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/request-access/${type}/${id}`);
    }
  }, [isLoggedIn]);

  return (
    <View
      style={[
        styles.root,
        {
          backgroundColor: theme.colors.neutral.$white,
        },
      ]}>
      <Header />
      {renderResult(type)}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    height: '100%',
  },
});
