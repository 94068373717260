import {PageContent} from 'interface/stacks/settings/base/PageContent';
import {PageSubTitle} from 'interface/stacks/settings/base/PageSubTitle';
import {StyleSheet, View} from 'react-native';

interface SectionItemProps {
  title: string;
  content: string;
  sectionElement: JSX.Element;
}

export function SectionItem(props: SectionItemProps) {
  return (
    <View style={styles.content}>
      <PageSubTitle text={props.title} />
      <PageContent text={props.content} />
      {props.sectionElement}
    </View>
  );
}

const styles = StyleSheet.create({
  content: {
    maxWidth: 600,
    paddingLeft: 50,
    marginBottom: 35,
  },
});
