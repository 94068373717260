import {t} from '@lingui/macro';
import theme from 'config/theme';
import {useMenu} from 'extensions/viewport/useMenu';
import React, {createRef, useCallback} from 'react';
import {
  type GestureResponderEvent,
  Pressable,
  StyleSheet,
  type View,
} from 'react-native';
import IconCommunity from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialIcons';

interface MemberMenuProps {
  isInvite: boolean;
  isMe: boolean;
  onAction: (command: string) => void;
}

export const MemberMenu = ({isInvite, isMe, onAction}: MemberMenuProps) => {
  const getMenuItems = () => {
    if (isInvite) {
      return [
        {
          command: 'resend',
          text: t`Resend Invite`,
          icon: <Icon name="email" size={20} color={theme.colors.neutral.$6} />,
        },
        {
          command: 'remove',
          text: t`Remove from Org`,
          icon: (
            <IconCommunity
              name="account-remove"
              size={20}
              color={theme.colors.neutral.$6}
            />
          ),
        },
      ];
    }

    if (isMe) {
      return [
        {
          command: 'edit',
          text: t`Edit Profile`,
          icon: (
            <IconCommunity
              name="account-circle-outline"
              size={20}
              color={theme.colors.neutral.$6}
            />
          ),
        },
        {
          command: 'leave',
          text: t`Leave Org`,
          icon: (
            <Icon
              name="exit-to-app"
              size={20}
              color={theme.colors.neutral.$6}
            />
          ),
        },
      ];
    }

    return [
      {
        command: 'remove',
        text: t`Remove from Org`,
        icon: (
          <IconCommunity
            name="account-remove"
            size={20}
            color={theme.colors.neutral.$6}
          />
        ),
      },
    ];
  };

  const memberMenu = useMenu(() => getMenuItems(), onAction, false);

  const menuAnchorRef = createRef<View>();

  const menu = useCallback(
    (e: GestureResponderEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (memberMenu) {
        if (e?.nativeEvent) {
          memberMenu.setPosition({
            x: e.nativeEvent.pageX,
            y: e.nativeEvent.pageY,
          });
        }
        memberMenu.setRef(menuAnchorRef);
      }
      memberMenu.open();
    },
    [memberMenu, menuAnchorRef],
  );

  return (
    <Pressable ref={menuAnchorRef} style={styles.menu} onPress={menu}>
      <Icon name="more-horiz" size={20} />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  menu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10%',
  },
});
