import {api} from 'fast-sdk';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as contentViewer from 'store/slices/content-viewer';

export default function useUploadedBy(userId: string) {
  const uploadedBy = useSelector(contentViewer.selectors.getUploadedBy);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userId) {
      return;
    }

    async function fetchUploadedBy() {
      const response = await api.user.getUserDetailsById(userId);
      if (response.result) {
        dispatch(
          contentViewer.default.actions.setUploadedBy({user: response.user}),
        );
      }
    }

    fetchUploadedBy();
  }, [userId]);

  return {uploadedBy};
}
