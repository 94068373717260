import {t} from '@lingui/macro';
import theme from 'config/theme';
import {useNavigate} from 'extensions/navigation';
import {Button} from 'interface/common/Button';
import Auth from 'interface/stacks/auth/consts';
import {StyleSheet, Text, View} from 'react-native';
import {useDispatch} from 'react-redux';
import user from 'store/slices/user';
import {OrganizationLayout} from '../_layout/OrganizationLayout';
import {UserBadge} from '../components/UserBadge';

interface Props {
  subDomain: string;
  organizationName: string;
  userEmail: string;
}

export function WrongEmail(props: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const textColor = theme.colors.neutral.$2Base;

  const createNewOrganization = () => {};
  const signout = () => {
    dispatch(user.actions.deleteUserDetails());
    Auth.setAuthToken('');
    navigate(
      `/signin/subdomain?subDomain=${props.subDomain}&name=${props.organizationName}`,
    );
  };

  return (
    <OrganizationLayout
      name=""
      subDomain={props.subDomain}
      customSubTitleStyle={{marginBottom: '2rem'}}
      customSubTitle={
        <View style={styles.passwordSubTitleContainer}>
          <Text style={[styles.passwordSubTitleText, {color: textColor}]}>
            Is this the right email?
          </Text>
          <UserBadge
            id={props.userEmail}
            email={props.userEmail}
            imageSrc={null}
            initials={props.userEmail[0].toUpperCase()}
            customStyle={{marginBottom: '0.5rem'}}
          />
        </View>
      }
      removeTitle
      removeBottomLink>
      <View>
        <Text style={[styles.content, {color: textColor}]}>
          This organization requires an
          <Text style={[styles.contentSubDomain, {color: textColor}]}>
            {' '}
            @{props.subDomain}{' '}
          </Text>
          email address to join. Contact the {props.organizationName}{' '}
          administrator for an assistance.
        </Text>
        <View style={styles.bottons}>
          <Button
            type="Primary"
            label={t`Log in with different email`}
            onPress={signout}
            customRootStyle={styles.button}
            customTextStyle={styles.buttonText}
          />
          <Button
            type="Secondary"
            label={t`Create new organization`}
            onPress={createNewOrganization}
            customRootStyle={styles.button}
            customTextStyle={styles.buttonText}
          />
        </View>
      </View>
    </OrganizationLayout>
  );
}

const styles = StyleSheet.create({
  passwordSubTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 7,
  },
  passwordSubTitleText: {
    fontSize: 26,
    lineHeight: 40,
    fontWeight: '700',
    color: theme.colors.neutral.$2Base,
  },
  content: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
    marginBottom: '2rem',
    textAlign: 'center',
  },
  contentSubDomain: {
    fontWeight: '700',
  },
  bottons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 14,
  },
  button: {
    height: 48,
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
});
