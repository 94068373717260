import {Linking} from 'react-native';

export function getStage() {
  // @ts-ignore
  return import.meta.env.VITE_BUILD_STAGE || 'local';
}

export function getVersion(excludeBranch?: boolean) {
  const branch = !excludeBranch ? ` (${getStage()})` : '';
  // @ts-ignore
  return `${import.meta.env.VITE_BUILD_DATE || ''}-${import.meta.env.VITE_BUILD_HASH?.slice(0, 7) || '0'}${branch}`;
}

export const getEnvironmentDomain = async () => {
  let domain: string;
  if (window) {
    const origin = window.location.origin.split('://');
    domain = origin[origin.length - 1];
  } else {
    const url = await Linking.getInitialURL();
    const origin = new URL(url).origin.split('://');
    domain = origin[origin.length - 1];
  }
  return domain.replace('www.', '').replace('app.', '');
};

export const getEnvironmentProtocol = async () => {
  let domain: string;
  if (window) {
    const origin = window.location.origin.split('://');
    domain = origin[0];
  } else {
    const url = await Linking.getInitialURL();
    const origin = new URL(url).origin.split('://');
    domain = origin[0];
  }
  return domain;
};
