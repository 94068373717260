import {api} from 'fast-sdk';
import {useEffect, useMemo} from 'react';
import YPartyKitProvider from 'y-partykit/provider';
import {Doc} from 'yjs';

import type {
  MultiplayerConnection,
  MultiplayerMember,
  MultiplayerProfile,
} from '../types';

export function useMultiplayerConnection(
  profile: MultiplayerProfile,
): MultiplayerConnection {
  const provider = useMemo(
    () =>
      profile?.id
        ? new YPartyKitProvider(
            __DEV__ || location.host.endsWith('fastdev1.com')
              ? 'realtime.fastdev1.com'
              : 'realtime.fast.io',
            profile.id,
            new Doc(),
            {
              connect: false,
              disableBc: false,
              maxBackoffTime: 8000,
              prefix: `/party/${profile.id}`,
              params: async () => ({
                token: (await api.auth.realtimeAuth(profile.id)).auth_token,
              }),
            },
          )
        : null,
    [profile.id],
  );

  useEffect(() => {
    provider?.connect();
    return () => provider?.disconnect();
  }, [provider]);

  return useMemo(
    () => ({
      profile,
      provider,
      setState: (state: Partial<MultiplayerMember>) => {
        provider?.awareness.setLocalState({
          ...provider?.awareness.getLocalState(),
          ...state,
        });
      },
    }),
    [profile, provider],
  );
}
