import {PopupMenu, type PopupMenuItem} from 'extensions/viewport/PopupMenu';
import {usePopup} from 'extensions/viewport/usePopup';
import {useCallback} from 'react';

export function useMenu(
  items: () => PopupMenuItem[],
  onSelect: (command: string) => void,
  multiMode?: boolean,
) {
  const popup = usePopup();

  const {close, visible, reference, setPosition, setRef} = popup;

  const open = useCallback(() => {
    popup.open(
      <PopupMenu
        multiMode={multiMode}
        menuItems={items().filter(e => !!e)}
        onSelectItem={onSelect}
        close={close}
      />,
      {closeOnClickOutside: true},
    );
  }, [items]);

  return {open, close, visible, reference, setPosition, setRef};
}
