import {api} from 'fast-sdk';
import {useState} from 'react';

export const useCreateNewChatMessage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const createChatMessage = async (
    workspaceName: string,
    chatId: string,
    message: string,
  ) => {
    setIsLoading(true);
    try {
      const response = await api.workspaceAichat.createChatMessage(
        workspaceName,
        chatId,
        message,
      );
      if (response.result) {
        return response.message_id;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, createChatMessage};
};
