import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/common/Button';
import type {PropsWithChildren} from 'react';
import {StyleSheet, Text, View} from 'react-native';

interface Props {
  title: string;
  subtitle: string;
  next: () => void;
  skip?: () => void;
  disabled?: boolean;
  loading?: boolean;
  loadingColor?: string;
  loadingSkip?: boolean;
}

export default function Layout(props: PropsWithChildren<Props>) {
  const {skip} = props;
  const showSkip = skip !== undefined;
  return (
    <View style={styles.root}>
      <View style={styles.top}>
        <Text style={styles.title}>{props.title}</Text>
        <Text style={styles.subtitle}>{props.subtitle}</Text>
        {props.children}
      </View>
      <View style={styles.bottom}>
        <Button
          type="Primary"
          fontStyle="Normal, Large"
          label={t`Continue`}
          disabled={props.disabled}
          loading={props.loading}
          customLoadingColor={props.loadingColor}
          customRootStyle={styles.button}
          onPress={props.next}
        />
        {showSkip && (
          <Button
            type="Secondary"
            fontStyle="Normal, Large"
            label={t`Skip`}
            customRootStyle={styles.button}
            onPress={skip}
            loading={props.loadingSkip}
            customLoadingColor={theme.colors.neutral.$6}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 26,
    lineHeight: 40,
    fontWeight: '700',
    marginBottom: 7,
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: '400',
    color: theme.colors.neutral.$2Base,
  },
  top: {
    display: 'flex',
    padding: 20,
    zIndex: 1,
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 20,
    padding: 20,
  },
  button: {
    width: 'auto',
    flexGrow: 1,
    height: 48,
    borderRadius: 5,
  },
});
