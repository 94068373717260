import {api} from 'fast-sdk';
import type {BillingStatus} from 'fast-sdk/src/api/billing/consts';
import {useEffect, useState} from 'react';

interface Options {
  disable?: boolean;
  generatePaymentIntent?: boolean;
}

const defaultOptions: Options = {
  disable: false,
  generatePaymentIntent: false,
} as const;

export default function useBillingDetails(
  domain: string,
  options = defaultOptions,
) {
  const [loading, setLoading] = useState(true);
  const [billingStatus, setBillingStatus] = useState<BillingStatus | null>(
    null,
  );

  async function getBillingDetails(domain: string) {
    return api.billing.getSubscriptionDetails(domain);
  }

  function shouldGenerateNewIntent(
    billingStatus: BillingStatus,
    options: Options,
  ): boolean {
    if (!options.generatePaymentIntent) return false;

    const setupIntent = billingStatus?.setup_intent;
    return (
      !setupIntent || (Array.isArray(setupIntent) && setupIntent.length === 0)
    );
  }

  async function handlePaymentIntentGeneration(
    domain: string,
    billingStatus: BillingStatus,
  ) {
    const plan = billingStatus?.current_plan.name;
    const response = await api.billing.createOrUpdateSubscription(domain, plan);

    return response.result ? response : null;
  }

  useEffect(() => {
    if (!domain || options.disable) {
      return;
    }

    let isMounted = true;

    const fetchBillingDetails = async () => {
      try {
        const response = await getBillingDetails(domain);

        if (!response.result || !isMounted) return;

        if (shouldGenerateNewIntent(response.billing_status, options)) {
          const updatedResponse = await handlePaymentIntentGeneration(
            domain,
            response.billing_status,
          );
          if (updatedResponse && isMounted) {
            setBillingStatus(updatedResponse.billing_status);
          }
        } else {
          setBillingStatus(response.billing_status);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    setLoading(true);
    fetchBillingDetails();

    return () => {
      isMounted = false;
    };
  }, [domain, options.disable, options.generatePaymentIntent]);

  return {billingStatus, loading} as const;
}

// Helper functions
