import theme from 'config/theme';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import FileThumbnail from 'interface/stacks/workspace/storage/thumbnail/FileThumbnail';
import {useEffect} from 'react';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import Pagination from '../../components/Pagination';
import useFileUrl from '../../hooks/useFileUrl';
import type {ViewerProps as Props} from '../../types';
import {HEIGHT_TOP_TOOLBAR} from '../TopToolbar';

export function TextViewer({file}: Props) {
  const [text, setText] = useState('');
  const {loading, url} = useFileUrl(file);

  useEffect(() => {
    async function fetchText() {
      const response = await fetch(url);
      const text = await response.text();
      setText(text);
    }

    if (url) {
      fetchText();
    }
  }, [url]);

  return (
    <>
      <View style={styles.root}>
        {loading ? (
          <FileThumbnail
            item={file}
            size={FileThumbnailSizes.Preview}
            options={{previewResizeMode: 'center'}}
          />
        ) : (
          <pre style={styles.text}>{text}</pre>
        )}
      </View>

      <Pagination />
    </>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    maxHeight: `calc(100vh - ${HEIGHT_TOP_TOOLBAR}px)`,
    overflow: 'scroll',
    padding: 15,
  },
  text: {
    color: theme.colors.neutral.$white,
    whiteSpace: 'break-spaces',
    margin: 0,
  },
});
