import {useState} from 'react';

export function useFocus(initialState?: boolean) {
  const [focused, setIsFocus] = useState(initialState);

  const onFocus = () => setIsFocus(true);
  const onBlur = () => setIsFocus(false);

  return {focused, onFocus, onBlur};
}
