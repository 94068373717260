import {StyleSheet, Text, type TextStyle} from 'react-native';

interface Props {
  text: string;
  rootStyle?: TextStyle;
}

export function PageContent(props: Props) {
  return <Text style={[styles.root, props.rootStyle]}>{props.text}</Text>;
}

const styles = StyleSheet.create({
  root: {
    fontFamily: '',
    fontSize: 14,
    lineHeight: 22,
    fontWeight: '400',
    marginBottom: '1.5rem',
  },
});
