import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

interface NoResultsAvailableProps {
  message?: string;
}

export const NoResultsAvailable = ({
  message = 'No results available',
}: NoResultsAvailableProps) => {
  return (
    <View style={styles.root}>
      <Text style={styles.text}>
        <Trans>{message}</Trans>
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: theme.colors.neutral.$15,
    width: '100%',
    paddingVertical: 12,
    paddingHorizontal: 20,
    alignItems: 'center',
    zIndex: -1,
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
  },
});
