import type {Actions, Store} from './types';

export function setSubDomain(
  state: Store,
  action: Actions['organization']['general']['setSubDomain'],
) {
  const {subDomain} = action.payload;
  state.organization.general.subDomain = subDomain;
}

export function setTimeZone(
  state: Store,
  action: Actions['organization']['general']['setTimeZone'],
) {
  const {timeZone} = action.payload;
  state.organization.general.timeZone = timeZone;
}

export function setOrganizationName(
  state: Store,
  action: Actions['organization']['branding']['setName'],
) {
  const {name} = action.payload;
  state.organization.branding.name = name;
}
export function setOrganizationLogo(
  state: Store,
  action: Actions['organization']['branding']['setLogo'],
) {
  const {logo} = action.payload;
  state.organization.branding.logo = logo;
}
export function setOrganizationDescription(
  state: Store,
  action: Actions['organization']['branding']['setDescription'],
) {
  const {description} = action.payload;
  state.organization.branding.description = description;
}
export function setOrganizationSocialLinks(
  state: Store,
  action: Actions['organization']['branding']['setSocialLinks'],
) {
  const {links} = action.payload;
  state.organization.branding.socialLinks = links;
}

export function addMember(state: Store, action: Actions['members']['Add']) {
  const {member} = action.payload;
  state.members.push(member);
}

export function removeMember(
  state: Store,
  action: Actions['members']['remove'],
) {
  const {email} = action.payload;
  const otherMembers = state.members.filter(member => member.email !== email);
  state.members = otherMembers;
}

export function addPlanOwner(
  state: Store,
  action: Actions['billing']['plan']['addOwner'],
) {
  const {email} = action.payload;
  state.billing.owner.email = email;
}
export function useSeats(
  state: Store,
  action: Actions['billing']['plan']['useSeats'],
) {
  const {seats} = action.payload;
  state.billing.seats.used = seats;
}
export function changeSeatsCapacity(
  state: Store,
  action: Actions['billing']['plan']['changeSeatsCapacity'],
) {
  const {seats} = action.payload;
  state.billing.seats.capacity = seats;
}
export function changePlan(
  state: Store,
  action: Actions['billing']['plan']['changePlan'],
) {
  const {plan} = action.payload;
  state.billing.plan = plan;
}

export function changeBilledDuration(
  state: Store,
  action: Actions['billing']['plan']['changeBilledDuration'],
) {
  const {duration} = action.payload;
  state.billing.plan.billedDuration = duration;
}
