import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import type {ShareLink} from 'fast-sdk/src/api/share/consts';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';
import type {ShareState} from '../../../shared/dialogs/SharedCustomizeDialog/hooks/useShareActions';
import {EditLinkModal} from '../dialogs/EditLinkModal';
import Section from './Section';
import SectionTitle from './SectionTitle';

interface Props {
  shareState: ShareState;
  updateShare: (updatedShare: Partial<Share>) => void;
}

export default function ShareLinks({shareState, updateShare}: Props) {
  const modal = useModal();

  const {links} = shareState;

  const updateShareLinks = async (newLinks: ShareLink[]) => {
    const request = {
      link_1: newLinks[0] ? {...newLinks[0], id: undefined} : undefined,
      link_2: newLinks[1] ? {...newLinks[1], id: undefined} : undefined,
      link_3: newLinks[2] ? {...newLinks[2], id: undefined} : undefined,
    };
    await updateShare(request);
  };

  const getNewLinks = (link: ShareLink) => {
    const newLinks = [...links];
    const index = newLinks.findIndex(l => l.id === link.id);
    if (index === -1) {
      newLinks.push(link);
    } else {
      newLinks[index] = link;
    }
    return newLinks;
  };

  const saveLink = useCallback(
    async (link: ShareLink) => {
      if (link) {
        const newLinks = getNewLinks(link);
        await updateShareLinks(newLinks);
        modal.close();
      }
    },
    [links],
  );

  const removeAndCancelLink = useCallback(
    async (link: ShareLink) => {
      const newLinks = links.filter(l => l.id !== link.id);
      await updateShareLinks(newLinks);
      modal.close();
    },
    [links],
  );

  const handleAddLink = () => {
    modal.open(
      <EditLinkModal
        saveLink={saveLink}
        removeAndCancelLink={removeAndCancelLink}
        done={modal.close}
      />,
    );
  };

  const handleEditLink = (link: ShareLink) => {
    modal.open(
      <EditLinkModal
        link={link}
        saveLink={saveLink}
        removeAndCancelLink={removeAndCancelLink}
        done={modal.close}
      />,
    );
  };

  return (
    <Section>
      <SectionTitle>Links</SectionTitle>
      <View style={styles.linksContainer}>
        {links.map(link => (
          <View style={styles.linkItem}>
            <View style={styles.linkLabelContainer}>
              <Icon
                name="lucide:link-2"
                size={18}
                color={theme.colors.neutral.$2Base}
              />
              <View style={styles.linkContent}>
                <Typography size="sm" color={theme.colors.neutral.$2Base}>
                  {link.title}
                </Typography>
                <Typography size="sm" color={theme.colors.neutral.$500}>
                  {link.url}
                </Typography>
              </View>
            </View>
            <Button
              onPress={() => handleEditLink(link)}
              variant="secondary"
              size="sm">
              <Typography size="xs" color={theme.colors.neutral.$700}>
                Edit
              </Typography>
            </Button>
          </View>
        ))}
      </View>
      {links.length < 3 && (
        <Button
          onPress={handleAddLink}
          variant="secondary"
          startEnhancer={
            <Icon name="lucide:plus" color={theme.colors.neutral.$700} />
          }>
          <Typography size="xs" color={theme.colors.neutral.$700}>
            Add Link
          </Typography>
        </Button>
      )}
    </Section>
  );
}

const styles = StyleSheet.create({
  linkItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 10,
    width: '100%',
  },
  linkLabelContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 8,
  },
  linkContent: {
    flexDirection: 'column',
    gap: 6,
  },
  linksContainer: {
    gap: 16,
    marginBottom: 16,
  },
});
