import {WORKSPACE_ROOT} from 'constants/routes';
import {
  FilePreviewState,
  type StorageNamespace,
} from 'fast-sdk/src/api/storage/consts';
import {ActivityTypes} from 'fast-sdk/src/websockets/types';
import {useGetFileDetails} from 'interface/stacks/workspace/hooks/useGetFileDetails';
import {useDispatch} from 'react-redux';
import store from 'store';
import * as activity from 'store/slices/activity';
import {
  type ActivityTypeWithTimestamp,
  EntityType,
} from 'store/slices/activity/types';
import * as files from 'store/slices/files';
import {getCurrentUTCDateTime} from 'utils/common/dates';
import {shouldUpdateCache} from '../utils';
import useItemChangeDetector from './useItemChangeDetector';

interface UseSyncStorageDataOptions {
  namespace: StorageNamespace;
  profileId: string;
  profileName: string;
  folderKey: string;
  activities: ActivityTypeWithTimestamp;
}

const useSyncStorageData = ({
  namespace,
  profileId,
  profileName,
  folderKey,
  activities,
}: UseSyncStorageDataOptions) => {
  const dispatch = useDispatch();

  const {fetchFileDetails} = useGetFileDetails();

  const updateEntityLastClientUpdate = (
    profileId: string,
    activityType: string,
    fileId: string,
    folderKey: string,
  ) => {
    const lastClientUpdate = getCurrentUTCDateTime();
    const entityType =
      namespace === 'workspace'
        ? EntityType.Workspace
        : namespace === 'share'
          ? EntityType.Share
          : undefined;
    if (entityType) {
      dispatch(
        activity.default.actions.setEntitiesLastClientUpdate({
          entities: [profileId],
          activities: [`${activityType}:${fileId}:${folderKey}`],
          entityType,
          lastClientUpdate,
        }),
      );
    }
  };

  const onFileChange = ({id: fileId}) => {
    if (profileId) {
      updateEntityLastClientUpdate(
        profileId,
        ActivityTypes.STORAGE,
        fileId,
        folderKey,
      );
    }
  };

  const onStorageActivityChange = ({id: storageActivityKey}) => {
    const fileId = storageActivityKey.split(':')[1];
    if (fileId === WORKSPACE_ROOT) return;
    const storageActivity = activities[storageActivityKey];
    if (storageActivity) {
      const {lastServerUpdate, lastClientUpdate} = storageActivity;
      if (shouldUpdateCache(lastServerUpdate, lastClientUpdate)) {
        fetchFileDetails(fileId, profileName, namespace);
      }
    }
  };

  const onPreviewActivityChange = ({id: previewActivityKey}) => {
    const fileId = previewActivityKey.split(':')[1];
    if (fileId === WORKSPACE_ROOT) return;
    const previewActivity = activities[previewActivityKey];
    if (previewActivity) {
      const {lastServerUpdate, lastClientUpdate} = previewActivity;
      const file = store.getState().files.items[fileId];
      if (
        shouldUpdateCache(lastServerUpdate, lastClientUpdate) &&
        file?.previews?.image?.state === FilePreviewState.IN_PROGRESS
      ) {
        fetchFileDetails(fileId, profileName, namespace);
      }
    }
  };

  const storageActivitySelector =
    namespace === 'share'
      ? activity.selectors.getShareActivitiesByPrefix
      : namespace === 'workspace'
        ? activity.selectors.getWorkspaceActivityByPrefix
        : undefined;

  const previewActivitySelector =
    namespace === 'share'
      ? activity.selectors.getShareActivitiesForPreview
      : namespace === 'workspace'
        ? activity.selectors.getWorkspaceActivityForPreview
        : undefined;

  useItemChangeDetector(
    storageActivitySelector(profileId, `${ActivityTypes.STORAGE}:`, folderKey),
    onStorageActivityChange,
  );

  useItemChangeDetector(
    files.selectors.getFolderAsList(namespace, profileName, folderKey),
    onFileChange,
  );

  useItemChangeDetector(
    previewActivitySelector(profileId, `${ActivityTypes.PREVIEW}:`),
    onPreviewActivityChange,
  );
};

export default useSyncStorageData;
