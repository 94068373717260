import type {PayloadAction} from '@reduxjs/toolkit';
import type {QuickShareItem} from 'fast-sdk/src/api/storage/consts';

export type Store = {
  list: Array<QuickShare>;
  workspace: {[key: string]: Array<string>};
};

export enum ActivityType {
  Created = 'Created',
}

export interface QuickShare extends QuickShareItem {
  workspaceFolderName: string;
  duration: number;
}

export type Actions = {
  SetQuickShares: PayloadAction<{
    quickshares: Array<QuickShareItem>;
    workspaceFolderName: string;
  }>;
  CreateQuickShare: PayloadAction<{
    quickshare: QuickShareItem;
    workspaceFolderName?: string;
  }>;
  RemoveQuickShare: PayloadAction<{
    quickshareId: string;
    workspaceFolderName: string;
  }>;
};
