import theme from 'config/theme';
import React from 'react';
import Icon from 'react-native-vector-icons/MaterialIcons';
import ActionButton from '../ActionButton';

type Props = {
  onPress: () => void;
};

const ZoomOutButton = ({onPress}: Props) => {
  return (
    <ActionButton
      icon={
        <Icon name="remove" size={20} color={theme.colors.neutral.$white} />
      }
      tooltipText="Zoom Out"
      onPress={onPress}
    />
  );
};

export default ZoomOutButton;
