import theme from 'config/theme';
import {StyleSheet, Text, View} from 'react-native';
import {Circle, Svg} from 'react-native-svg';

interface Props {
  progress: number;
  text: string;
}

const radius = 41;

export function CircularProgressBar(props: Props) {
  const strokeDashArray = radius * 3.14 * 2;
  const strokePct = ((100 - props.progress) * strokeDashArray) / 100;

  return (
    <View style={styles.root}>
      <Svg style={styles.svg}>
        <Circle
          cx={radius}
          cy={radius}
          r={radius}
          stroke={theme.colors.neutral.$10}
          strokeWidth={4}
          fill="transparent"
          strokeDasharray={strokeDashArray}
          strokeLinecap={'round'}
          strokeDashoffset={0}
        />
        <Circle
          cx={radius}
          cy={radius}
          r={radius}
          stroke={theme.colors.brand.$4Base}
          strokeWidth={4}
          fill="transparent"
          strokeDasharray={strokeDashArray}
          strokeLinecap={'round'}
          strokeDashoffset={strokePct}
        />
      </Svg>
      <View style={styles.timeContainer}>
        <Text style={styles.time}>{props.text}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    height: radius * 2,
    width: radius * 2,
    marginRight: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  svg: {
    width: radius * 2,
    height: radius * 2,
    overflow: 'visible',
    transform: [{rotate: '-90deg'}, {scaleY: -1}],
  },
  timeContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  time: {},
});
