import {useEffect, useState} from 'react';

const UPDATE_CHECK_INTERVAL = 60000;

const buildMetaPath = '/build-meta.json';

const useShowUpdateNotice = () => {
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);

  useEffect(() => {
    if (__DEV__) return;

    const currentBuildTime = Date.now();

    const checkForUpdates = async () => {
      try {
        const response = await fetch(buildMetaPath);
        if (!response.ok) return;

        const manifest = await response.json();

        // Compare the build time with the current time
        const manifestBuildTime = new Date(manifest.buildTime).getTime();

        if (manifestBuildTime > currentBuildTime) {
          setShowUpdateAlert(true);
        }
      } catch (error) {
        console.error('Error checking for updates:', error);
      }
    };

    const intervalId = setInterval(checkForUpdates, UPDATE_CHECK_INTERVAL);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  return {showUpdateAlert};
};

export default useShowUpdateNotice;
