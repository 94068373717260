import theme from 'config/theme';
import {useEffect, useRef} from 'react';
import {
  Animated,
  Pressable,
  type StyleProp,
  StyleSheet,
  View,
  type ViewStyle,
} from 'react-native';

type Props = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  clickable?: boolean;
};

export function Toggle({checked, onChange, clickable = true}: Props) {
  const animatedValue = useRef(new Animated.Value(checked ? 1 : 0)).current;

  useEffect(() => {
    Animated.spring(animatedValue, {
      toValue: checked ? 1 : 0,
      useNativeDriver: true,
      bounciness: 4,
    }).start();
  }, [checked, animatedValue]);

  const rootStyle = checked ? styles.rootOn : styles.rootOff;
  const translateX = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 16],
  });

  const wrapper = (children: React.ReactNode, style?: StyleProp<ViewStyle>) =>
    clickable ? (
      <Pressable onPress={() => onChange(!checked)} style={style}>
        {children}
      </Pressable>
    ) : (
      <View style={style}>{children}</View>
    );

  return wrapper(
    <Animated.View style={[styles.track, {transform: [{translateX}]}]} />,
    [styles.root, rootStyle],
  );
}

const styles = StyleSheet.create({
  root: {
    width: 32,
    height: 16,
    borderRadius: 999,
    padding: 2,
  },
  track: {
    width: 12,
    height: 12,
    borderRadius: 999,
    backgroundColor: theme.colors.neutral.$white,
  },
  rootOff: {
    backgroundColor: theme.colors.neutral.$150,
  },
  rootOn: {
    backgroundColor: theme.colors.brand.$4,
  },
  off: {},
  on: {
    transform: [{translateX: 16}],
  },
});
