import DMImage from '_dummy/access/DM.png';
import FileImage from 'assets/images/request-access/file.svg';
import LockImage from 'assets/images/request-access/lock.svg';
import ShareImage from 'assets/images/request-access/share.svg';
import WorkspaceImage from 'assets/images/request-access/workspace.svg';
import theme from 'config/theme';
import type {ReactNode} from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';
import {getContrast} from 'utils/common/color';

export type Type = 'File' | 'Organization' | 'Workspace' | 'Share';

interface Props {
  image?: {
    src: string;
    alt: string;
  };
  children: ReactNode;
  type: Type;
}

export function Layout(props: Props) {
  const emptyPhotoBackgroundColor = theme.colors.neutral.$4;

  const renderImage = (type: Type) => {
    switch (type) {
      case 'File':
        return {
          src: FileImage,
          alt: 'Access To this file',
        };
      case 'Workspace':
        return {
          src: WorkspaceImage,
          alt: 'Access To this Workspace',
        };
      case 'Share':
        return {
          src: ShareImage,
          alt: 'Access To share',
        };
      case 'Organization':
        // The code should looks like the below line but for
        // presentation the dummy Image and alt is shown instead of the
        // organization image and alt
        // if (props.image && props.image.src) return props.image;
        if (props.image) {
          return {
            src: DMImage,
            alt: 'Dunder Mifflin Organization',
          };
        }
        return 'DM';
    }
  };

  return (
    <View style={styles.root}>
      <View style={styles.imageContainer}>
        {typeof renderImage(props.type) === 'string' ? (
          <Text
            style={[
              styles.initials,
              {
                backgroundColor: emptyPhotoBackgroundColor,
                color: getContrast(emptyPhotoBackgroundColor),
              },
            ]}>
            {renderImage(props.type)}
          </Text>
        ) : (
          <Image
            // This should not be a typescript error beacause the error
            // is handled in the above lines of code
            // @ts-ignore
            source={{uri: renderImage(props.type).src}}
            // @ts-ignore
            alt={renderImage(props.type).alt}
            style={[
              styles.image,
              {
                borderRadius: props.type === 'Organization' ? 30 : 0,
                width:
                  props.type === 'Organization' || props.type === 'Workspace'
                    ? 64
                    : 50,
              },
            ]}
          />
        )}
        <Image source={{uri: LockImage}} alt="Locked" style={styles.lockIcon} />
      </View>
      <View style={styles.childContainer}>{props.children}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: '1.5rem',
    paddingTop: 66,
    paddingBottom: '1.5rem',
    maxWidth: 500,
  },
  imageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  lockIcon: {
    position: 'absolute',
    width: 30,
    height: 30,
    borderRadius: 30,
    bottom: -10,
    left: -10,
    zIndex: 1,
  },
  image: {
    height: 64,
  },
  childContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  initials: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    borderRadius: 50,
    padding: 19,
  },
});
