import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useDispatch} from 'react-redux';
import errors from 'store/slices/errors';
import {Button} from '../Button';
import Modal from '../Modal';

interface ConfirmResetProps {
  goBack: () => void;
  confirm: () => void;
  showCloseButton: boolean;
}

export const ConfirmReset = (props: ConfirmResetProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  return (
    <Modal>
      {props.showCloseButton && (
        <Button
          lIcon={
            <Icon name="close" size={20} color={theme.colors.neutral.$6} />
          }
          onPress={() => dispatch(errors.actions.hideError())}
          type="Text"
          customRootStyle={styles.closeContainer}
        />
      )}
      <Text style={styles.title}>
        <Trans>Confirm reset</Trans>
      </Text>
      <Text style={styles.message}>
        <Trans>
          In order to reset Fastio, we’ll need to sign you out of your account.
          Do you want to continue?
        </Trans>
      </Text>
      <View style={styles.buttonContainer}>
        <Button
          type="Secondary"
          label={t`Go Back`}
          ariaLabel={t`Go Back`}
          onPress={props.goBack}
          customRootStyle={{height: 36}}
        />
        <Button
          type="Primary"
          label={t`Yes, sign out and reset`}
          ariaLabel={t`Yes, sign out and reset`}
          onPress={() => {
            setLoading(true);
            props.confirm();
          }}
          customRootStyle={{height: 36}}
          loading={loading}
          disabled={loading}
        />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 21,
    padding: 24,
    backgroundColor: theme.colors.neutral.$white,
    width: 500,
    borderRadius: 7,
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 6px 12px 0px rgba(0, 0, 0, 0.07)',
  },
  closeContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
    margin: 8,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  title: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
  message: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: 10,
  },
});
