import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import {MemberAvatarGroup} from 'interface/stacks/auth/components/MemberAvatarGroup';
import {ShareModal} from 'interface/stacks/share/components/ShareModal';
import {StyleSheet, View} from 'react-native';
import {type Share, ShareViewStatus} from 'store/slices/shared/types';
import Section from './Section';
import SectionTitle from './SectionTitle';

interface Props {
  share: Share;
}

export default function AddPeople({share}: Props) {
  const modal = useModal();

  const handleAddPeople = () => {
    modal.open(
      <ShareModal
        workspaceFolderName={share.parent_workspace_folder}
        shareId={share.id}
        done={modal.close}
      />,
    );
  };
  return (
    <Section>
      <SectionTitle>Add People</SectionTitle>
      <View style={styles.content}>
        <View>
          <MemberAvatarGroup
            members={share.members}
            showCount={false}
            loading={share.shareViewStatus === ShareViewStatus.Viewer}
          />
        </View>
        <Button
          onPress={handleAddPeople}
          variant="secondary"
          startEnhancer={
            <Icon name="lucide:user-plus" color={theme.colors.neutral.$black} />
          }>
          <Trans>Add</Trans>
        </Button>
      </View>
    </Section>
  );
}

const styles = StyleSheet.create({
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
