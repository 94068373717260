import {api} from 'fast-sdk';
import {useDispatch} from 'react-redux';
import * as user from 'store/slices/user';

export const useGetOrganizationDetails = () => {
  const dispatch = useDispatch();

  const fetchOrganizationDetails = async (subdomain: string) => {
    const {result, org} =
      await api.organization.getOrganizationDetails(subdomain);
    if (result) {
      dispatch(user.default.actions.setSelectedOrganization({org}));
      dispatch(user.default.actions.updateOrganization({org}));
    }
  };

  return {fetchOrganizationDetails};
};
