import NotFound from 'assets/svgs/recents/not-found.svg';
import theme from 'config/theme';
import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

const RecentEmpty = () => {
  return (
    <View style={styles.root}>
      <Image
        source={{uri: NotFound}}
        style={styles.image}
        alt="Not found image"
      />
      <Text style={styles.title}>No results were found</Text>
      <Text style={styles.subtitle}>
        Try adjusting or clearing filters to see recent files.
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: theme.colors.brand.$9,
    padding: 24,
    paddingTop: 10,
    borderRadius: 14,
    maxWidth: 450,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  image: {
    width: 96,
    height: 96,
  },
  title: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
  subtitle: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
});

export default RecentEmpty;
