import {Picker} from '@react-native-picker/picker';
import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import type React from 'react';
import {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';

const ICON_GAP = 8;

export enum PickerSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export type DropdownOption = {
  title: string;
  value: string;
};

interface CustomPickerProps {
  label?: string;
  icon?: string;
  options: Array<DropdownOption>;
  setSelected: (inp: string) => void;
  selected: string;
  enabled?: boolean;
  fullWidth?: boolean;
  size?: PickerSize;
}

export const CustomPicker: React.FC<CustomPickerProps> = ({
  label,
  icon,
  options,
  setSelected,
  selected,
  enabled,
  fullWidth,
  size = PickerSize.MEDIUM,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(selected);

  const handleOptionChange = (value: string) => {
    setSelectedOption(value);
    setSelected(value);
  };

  useEffect(() => {
    if (selected !== selectedOption) {
      setSelectedOption(selected);
    }
  }, [selected]);

  const hasLabelOrIcon = Boolean(label || icon);

  return (
    <View style={[styles.root, fullWidth && styles.fullWidth]}>
      {hasLabelOrIcon && (
        <View style={styles.labelContainer}>
          {icon && (
            <Icon name={icon} size={18} color={theme.colors.neutral.$2Base} />
          )}
          <Typography size="sm" color={theme.colors.neutral.$2Base}>
            {label}
          </Typography>
        </View>
      )}
      <Picker
        selectedValue={selectedOption ?? ''}
        onValueChange={newValue => handleOptionChange(newValue)}
        style={[styles.picker, fullWidth && styles.fullWidth, styles[size]]}
        enabled={enabled}>
        {options.map(option => (
          <Picker.Item
            key={option.value}
            label={option.title}
            value={option.value}
          />
        ))}
      </Picker>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: ICON_GAP,
  },
  label: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  icon: {
    position: 'absolute',
    left: 15,
    top: 10,
  },
  picker: {
    paddingHorizontal: 8,
    paddingVertical: 8,
    gap: 6,
    borderRadius: 6,
    borderWidth: 0,
    borderRightWidth: 8,
    borderRightColor: 'transparent',
    color: theme.colors.neutral.$2Base,
    borderColor: 'transparent',
    backgroundColor: theme.colors.neutral.$75,
  },
  fullWidth: {
    width: '100%',
  },
  [PickerSize.SMALL]: {
    paddingHorizontal: 4,
    paddingVertical: 3,
  },
  [PickerSize.MEDIUM]: {
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
});
