import {orgLogoService} from 'extensions/images';
import {Avatar} from 'interface/common/Avatar';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as settings from 'store/slices/settings';
import * as user from 'store/slices/user';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';
import {type OrgLogoProps, OrgLogoShapeKind, OrgLogoSize} from './types';

const DEFAULT_INITIALS = 'O R G';

export default function OrgLogo({
  org,
  size = OrgLogoSize.Medium,
  shapeKind = OrgLogoShapeKind.RoundedSquare,
  showBorder = false,
}: OrgLogoProps) {
  const {id, domain} = org ?? {};

  const currentOrg = useSelector(user.selectors.getSelectedOrganization);
  const currentOrgLogo = useSelector(settings.selectors.getOrganizationLogo);

  const [logoFromCache, setLogoFromCache] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const isCurrentOrg = currentOrg?.id === id;

  const loadPhoto = useCallback(async () => {
    if (isCurrentOrg || isLoading || logoFromCache) {
      return;
    }

    setIsLoading(true);

    try {
      const photoContent = await orgLogoService.getContent(id);
      setLogoFromCache(photoContent);
    } catch (err) {
      setLogoFromCache('');
    } finally {
      setIsLoading(false);
    }
  }, [id, domain, isCurrentOrg, isLoading, logoFromCache]);

  useEffect(() => {
    loadPhoto();
  }, [loadPhoto]);

  useEffect(() => {
    setLogoFromCache('');
    setIsLoading(false);
  }, [id, domain]);

  const initials = org ? createInitials(org.name) : DEFAULT_INITIALS;

  const logo = isCurrentOrg ? currentOrgLogo : logoFromCache;

  return (
    <Avatar
      key={id}
      color={getHashColor(id)}
      initials={initials}
      photo={logo}
      size={size}
      shapeKind={shapeKind}
      hideBorder={!showBorder}
    />
  );
}
