import theme from 'config/theme';
import {TextInput} from 'interface/common/TextInput';
import {StyleSheet, Text, View, useWindowDimensions} from 'react-native';

interface Props {
  subDomainName: string;
  setEmail: (email: string) => void;
  email: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}

export function SubDomainAuthInput(props: Props) {
  const dimension = useWindowDimensions();

  return (
    <View
      style={[
        styles.inputContainer,
        {
          width: dimension.width > 790 ? 380 : '100%',
        },
      ]}>
      <TextInput
        label={props.label}
        customRootStyle={styles.inputRoot}
        customStyle={styles.input}
        placeHolder={props.placeholder ? props.placeholder : 'your.email'}
        setInputValue={props.setEmail}
        value={props.email}
        customProps={{placeholderTextColor: theme.colors.neutral.$6}}
        disabled={props.disabled}
      />
      <Text style={styles.subDomainName} numberOfLines={1}>
        @{props.subDomainName}.com
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    marginBottom: '1.5rem',
    height: 48,
  },
  input: {
    width: '100%',
    height: '100%',
    padding: 10,
    backgroundColor: theme.colors.neutral.$13,
    marginBottom: 0,
  },
  inputRoot: {
    height: '100%',
    width: '54%',
    backgroundColor: theme.colors.neutral.$13,
    borderTopLeftRadius: 5,
  },
  subDomainName: {
    width: '46%',
    height: '100%',
    paddingHorizontal: '1rem',
    borderWidth: 1,
    borderColor: theme.colors.neutral.$10,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
    color: theme.colors.neutral.$5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
