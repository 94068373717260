import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {ScrollView, StyleSheet, Text} from 'react-native';
import {EmailInfo} from './EmailInfo';

export function AccountSettings() {
  return (
    <ScrollView contentContainerStyle={styles.root}>
      <Text style={styles.header}>
        <Trans>Account Settings</Trans>
      </Text>
      <EmailInfo />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    paddingVertical: 45,
    paddingHorizontal: 50,
  },
  header: {
    fontSize: 21,
    fontWeight: '600',
    lineHeight: 34,
    color: theme.colors.neutral.$2Base,
    marginBottom: '2rem',
  },
});
