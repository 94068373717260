import React, {useCallback} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import useFileUrl from '../../hooks/useFileUrl';
import useFullscreen from '../../hooks/useFullscreen';
import useSpreadSheet from '../../hooks/useSpreadSheet';
import useZoom from '../../hooks/useZoom';
import '@glideapps/glide-data-grid/dist/index.css';
import {
  DataEditor,
  type GridCell,
  GridCellKind,
  type Item,
} from '@glideapps/glide-data-grid';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import FileThumbnail from 'interface/stacks/workspace/storage/thumbnail/FileThumbnail';
import Pagination from '../../components/Pagination';
import type {ViewerProps as Props} from '../../types';
import ControlBar, {HEIGHT_CONTROL_BAR} from '../ControlBar';
import {HEIGHT_TOP_TOOLBAR} from '../TopToolbar';
import ControlsRight from '../spread-sheets/controls/ControlsRight';
import SpreadSheetsPages from './SpreadSheetsPages';

const SpreadSheetsViewer = ({file}: Props) => {
  const {url, loading: fileLoading} = useFileUrl(file);
  const {activeSheet, columns, loading, rows, sheetNames, handleSheetChange} =
    useSpreadSheet(url);
  const {ref, isFullscreen, toggleFullscreen} = useFullscreen();
  const {zoom, handleZoomIn, handleZoomOut, handleLastZoom} = useZoom();

  const getCellContent = useCallback(
    (cell: Item): GridCell => {
      const [col, row] = cell;
      const dataRow = rows[row];
      const indexes = columns.map(column => column.id);
      const d = dataRow[indexes[col]];

      const getKind = (value: any) => {
        if (typeof value === 'string') {
          return GridCellKind.Text;
        }

        if (typeof value === 'number') {
          return GridCellKind.Number;
        }

        if (typeof value === 'boolean') {
          return GridCellKind.Boolean;
        }

        return GridCellKind.Text;
      };

      return {
        kind: getKind(d),
        allowOverlay: false,
        displayData: d?.toString() || '',
        data: d,
      };
    },
    [columns, rows],
  );

  return (
    <View style={styles.root}>
      <ScrollView style={styles.scrollContainer}>
        <View
          ref={ref}
          style={[
            styles.container,
            {
              // @ts-ignore
              zoom,
            },
          ]}>
          {fileLoading || loading ? (
            <View style={styles.previewContainer}>
              <FileThumbnail item={file} size={FileThumbnailSizes.Preview} />
            </View>
          ) : (
            <DataEditor
              getCellContent={getCellContent}
              columns={columns}
              maxColumnAutoWidth={500}
              maxColumnWidth={500}
              rows={rows.length}
            />
          )}
        </View>
      </ScrollView>
      <ControlBar
        left={
          <SpreadSheetsPages
            sheets={sheetNames}
            activeSheet={activeSheet}
            handleChangeSheet={handleSheetChange}
          />
        }
        right={
          <ControlsRight
            zoom={zoom}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
            handleLastZoom={handleLastZoom}
            isFullscreen={isFullscreen}
            toggleFullscreen={toggleFullscreen}
          />
        }
      />
      <Pagination />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#16161b',
  },
  scrollContainer: {
    width: '100%',
    // Total viewport height - header height - control bar height
    minHeight: `calc(100vh - ${HEIGHT_TOP_TOOLBAR}px - ${HEIGHT_CONTROL_BAR}px)`,
  },
  container: {
    width: '100%',
    height: `calc(100vh - ${HEIGHT_TOP_TOOLBAR}px - ${HEIGHT_CONTROL_BAR}px)`,
  },
  previewContainer: {
    height: '100%',
  },
  bottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default SpreadSheetsViewer;
