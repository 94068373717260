import {t} from '@lingui/macro';
import {colors, vectors} from 'config/styles';
import {theme} from 'config/themes';
import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import {IconButton} from 'interface/common/IconButton';
import {useCallback, useEffect, useState} from 'react';
import {ActivityIndicator, StyleSheet, Text, View} from 'react-native';
import {Path, Svg} from 'react-native-svg';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import {selectors} from 'store/slices/files';
import {copy, move, restore, transfer} from 'utils/fast/storage';
import {FileList} from './FileList';
import {FileListItemLayout} from './FileListItem';
import {FolderPickerHeader} from './FolderPickerHeader';
import * as filesEffects from './hooks/files';
import {useModalCreateFolder} from './hooks/useModalCreateFolder';

import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import type {FilesItem} from 'store/slices/files/types';

interface FolderPickerProps {
  instanceId: string;
  instanceNs: StorageNamespace;
  instanceName: string;
}

export function FolderPicker(props: FolderPickerProps) {
  const toast = useToast();
  const items = useSelector(selectors.getItems);
  const pick = useSelector(selectors.getPick);
  const dest = useSelector(selectors.getItem(pick?.destination));
  const view = useSelector(
    selectors.getFolder(props.instanceNs, props.instanceId, pick?.destination),
  );

  const [shareTarget, setShareTarget] = useState<[string, string] | null>(null);

  const action = pick ? pick.action : 'copy';
  const destId = dest?.id ?? 'root';
  const destName = dest?.name ?? 'root';
  const actionName = action.slice(0, 1).toUpperCase() + action.slice(1);
  const sizeActual = view ? view.length : 0;
  const isLoading = !view;
  const isEmpty = sizeActual === 0 && !isLoading;

  const dispatch = useDispatch();
  const close = useCallback(filesEffects.pick(dispatch, null), []);
  const doPick = useCallback(
    (item: FilesItem) => {
      if (item.type === StorageItemType.Link) {
        return setShareTarget([item.id, item.target_id]);
      }
      if (item.type === StorageItemType.Folder) {
        setShareTarget(null);
        return filesEffects.pickSubmit(
          pick,
          dispatch,
          props.instanceId,
          props.instanceNs,
        )(item);
      }
      return null;
    },
    [pick, dispatch, props.instanceId, props.instanceNs],
  );

  const createFolder = useModalCreateFolder(
    destId,
    props.instanceId,
    props.instanceNs,
  );

  const submit = useCallback(async () => {
    if (!pick) return;

    let completes = 0;
    let actionPast = '';

    switch (action) {
      case 'copy': {
        actionPast = 'Copied';
        completes = (
          await copy(
            dispatch,
            pick.ids,
            destId,
            props.instanceId,
            props.instanceNs,
          )
        ).completes.length;
        break;
      }
      case 'move': {
        actionPast = 'Moved';
        completes = (
          await move(
            dispatch,
            pick.ids,
            destId,
            props.instanceId,
            props.instanceNs,
          )
        ).completes.length;
        break;
      }
      case 'restore': {
        actionPast = 'Restored';
        completes = (
          await restore(
            dispatch,
            pick.ids,
            destId,
            props.instanceId,
            props.instanceNs,
          )
        ).completes.length;
        break;
      }
      case 'transfer': {
        actionPast = 'Added';
        completes = (
          await transfer(
            pick.ids,
            shareTarget[1],
            'root',
            props.instanceId,
            props.instanceNs,
          )
        ).completes.length;
        break;
      }
      default:
        action satisfies never;
    }
    if (completes === pick.ids.length) {
      const topic = `${completes} ${completes > 1 ? 'items' : 'item'}`;
      if (action === 'restore') {
        toast.show(`${actionPast} ${topic}`);
      } else {
        toast.show(`${actionPast} ${topic} to ${destName}`);
      }
    } else {
      const failures = pick.ids.length - completes;
      const topic = `${failures} ${failures > 1 ? 'items' : 'item'}`;
      toast.show(t`Failed to ${action} ${topic}`, {type: 'danger'});
    }
    filesEffects.pickClear(dispatch);
  }, [pick, items]);

  // Temp: auto submit restore action since specifying a destination
  // folder is not supported yet
  useEffect(() => {
    if (pick?.action === 'restore') {
      submit();
    }
  }, [pick]);

  return pick && pick.action !== 'restore' ? (
    <View style={styles.root}>
      <View style={styles.inner}>
        <View style={styles.content}>
          <FolderPickerHeader
            id={destId}
            rootName={props.instanceName}
            handleBack={() => setShareTarget(null)}
          />
          {!isEmpty && !isLoading && (
            <View style={styles.list}>
              <FileList
                view={view}
                items={items}
                layout={FileListItemLayout.ListNormal}
                onOpen={doPick}
                options={{
                  hideShares: pick.action !== 'transfer',
                  hideFiles: true,
                  hideGutter: true,
                  disableDrag: true,
                  disableFocus: true,
                  disableSelect: true,
                  disableDetails: true,
                  disableActions: true,
                  disablePullToRefresh: true,
                  externalSelection: shareTarget ? [shareTarget[0]] : undefined,
                }}
              />
            </View>
          )}
          {isEmpty && (
            <View style={styles.empty}>
              <Text style={styles.emptyText}>
                {t`${actionName} ${pick.ids.length > 1 ? 'items' : 'item'} here`}
              </Text>
            </View>
          )}
          {isLoading && (
            <View style={styles.loading}>
              <ActivityIndicator color={colors.primary.brand} size="small" />
            </View>
          )}
        </View>
        <View style={styles.actions}>
          <IconButton
            mode="primary"
            textStyle={styles.buttonTextPrimary}
            disabled={action === 'transfer' && !shareTarget}
            onPress={submit}
            title={t`Start ${action}`}
            text={
              action === 'transfer'
                ? t`ADD TO SHARE`
                : t`${action}`.toUpperCase()
            }
          />
          <View style={styles.spacerH} />
          <IconButton
            mode="cancel"
            onPress={close}
            title={t`Cancel ${action}`}
            text={t`Cancel`.toUpperCase()}
          />
          <View style={styles.spacerFill} />
          {action !== 'transfer' && (
            <IconButton
              onPress={createFolder.open}
              title={t`Create new folder`}
              text={t`NEW FOLDER`}
              buttonStyle={styles.button}
              buttonHoverStyle={styles.buttonHover}
              textStyle={styles.buttonText}
              icon={
                <Svg viewBox="0 0 20 16" width={20} height={16}>
                  <Path
                    d={vectors.icons.folderAdd}
                    fill={colors.neutral.mid}
                    fillOpacity={1}
                  />
                </Svg>
              }
            />
          )}
        </View>
      </View>
    </View>
  ) : null;
}

export const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    backgroundColor: theme.dialog.overlay,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  inner: {
    borderRadius: 3,
    height: 480,
    minWidth: 360,
    maxWidth: 640,
    backgroundColor: theme.transfers.fill,
    shadowOffset: {width: 0, height: 3},
    shadowColor: 'rgba(0,0,0,0.3)',
    shadowRadius: 3,
    elevation: 1,
  },
  actions: {
    height: 64,
    flexDirection: 'row-reverse',
    padding: 10,
    paddingLeft: 6,
    borderTopWidth: 2,
    borderColor: theme.dialog.border,
    backgroundColor: theme.dialog.fill,
  },
  button: {
    paddingHorizontal: 8,
    alignItems: 'center',
    flexDirection: 'row',
    minWidth: 32,
    marginRight: 6,
  },
  buttonHover: {
    backgroundColor: theme.button.cancel.hover,
  },
  buttonText: {
    color: colors.neutral.darkgrey,
  },
  buttonTextPrimary: {
    color: colors.neutral.white,
  },
  content: {
    flex: 1,
    margin: 0,
  },
  list: {
    flex: 1,
    height: '100%',
    marginTop: 8,
    paddingHorizontal: 8,
  },
  empty: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyText: {
    color: colors.neutral.mid,
    fontSize: 14,
  },
  loading: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  spacerH: {
    width: 6,
  },
  spacerFill: {
    flex: 1,
  },
});
