import {WORKSPACE_ROOT, WORKSPACE_TRASH} from 'constants/routes';
import {useState} from 'react';
import type {FilesItem} from 'store/slices/files/types';

export interface ShareFilesNavigation {
  breadcrumbs: FilesItem[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<FilesItem[]>>;
  setTrashView: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFolder: FilesItem | undefined;
  folderKey: string;
}

export function useShareFilesNavigation(): ShareFilesNavigation {
  const [breadcrumbs, setBreadcrumbs] = useState<FilesItem[]>([]);
  const [isTrashView, setTrashView] = useState(false);

  const selectedFolder =
    breadcrumbs.length > 0 ? breadcrumbs.at(-1) : undefined;

  const folderKey = isTrashView
    ? WORKSPACE_TRASH
    : (selectedFolder?.id ?? WORKSPACE_ROOT);

  return {
    breadcrumbs,
    setBreadcrumbs,
    setTrashView,
    selectedFolder,
    folderKey,
  };
}
