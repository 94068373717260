import {ROUTES} from 'constants/routes';
import type {QuickShare} from 'store/slices/quick-share/types';

const useGenerateQuickShareUrl = () => {
  const generateQuickShareUrl = (quickShare: QuickShare) => {
    return `/${ROUTES.LOGGED_OR_NOT_LOGGED.QUICK_SHARE}/${quickShare.id}`;
  };

  return {
    generateQuickShareUrl,
  };
};

export default useGenerateQuickShareUrl;
