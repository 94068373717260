import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import React from 'react';
import {StyleSheet, View} from 'react-native';

const ShareTableHeaders = () => (
  <View style={styles.root}>
    <View style={styles.cell}>
      <Typography
        size="xs"
        color={theme.colors.neutral.$500}
        overrides={{width: 100}}>
        Name
      </Typography>
    </View>
    <View style={styles.cell}>
      <Typography
        size="xs"
        color={theme.colors.neutral.$500}
        overrides={{width: 80}}>
        Type
      </Typography>
    </View>
    <View style={styles.cell}>
      <Typography
        size="xs"
        color={theme.colors.neutral.$500}
        overrides={{width: 100}}>
        Workspace
      </Typography>
    </View>
    <View style={styles.cell}>
      <Typography
        size="xs"
        color={theme.colors.neutral.$500}
        overrides={{width: 100}}>
        Date Created
      </Typography>
    </View>
    <View style={[styles.cell, styles.actionCell]} />
  </View>
);

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    paddingVertical: 14,
    borderTopWidth: 1,
    borderTopColor: theme.colors.neutral.$10,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$10,
  },
  cell: {
    flex: 4,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  actionCell: {
    flex: 1,
  },
});

export default ShareTableHeaders;
