import {useFlag} from '@unleash/proxy-client-react';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useMultiplayerConnection} from './useMultiplayerConnection';
import {useMultiplayerCursors} from './useMultiplayerCursors';
import {useMultiplayerFollow} from './useMultiplayerFollow';
import {useMultiplayerMembers} from './useMultiplayerMembers';
import {useMultiplayerSync} from './useMultiplayerSync';

import * as files from 'store/slices/files';

import type {
  MultiplayerProfile,
  MultiplayerResource,
  MultiplayerState,
} from '../types';

export function useMultiplayer(
  profile: MultiplayerProfile,
  resource: MultiplayerResource,
): MultiplayerState {
  const hasCursors = useFlag('multiplayer-cursors');
  const connection = useMultiplayerConnection(profile);
  const members = useMultiplayerMembers(connection);
  const _cursors = useMultiplayerCursors(members.list, resource.id);
  const followId = useSelector(files.selectors.getFollow);
  const following = members.list?.find(member => member.userId === followId);
  const cursors = useMemo(
    () => (hasCursors ? _cursors : []),
    [hasCursors, _cursors],
  );

  useMultiplayerFollow(connection, following);
  useMultiplayerSync(connection, resource.id, resource.type);

  return useMemo(
    () => ({
      self: members.self,
      members: members.list,
      cursors,
      following,
      setState: connection.setState,
    }),
    [members, cursors, following, connection.setState],
  );
}
