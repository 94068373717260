import Bugsnag from '@bugsnag/js';
import {AnalyticsBrowser} from '@segment/analytics-next';
import {VE_DEBUG_ROUTING_KEY} from 'fast-sdk/src/requests/consts';
import {getStage, getVersion} from 'utils/common/version';

import type {NotifiableError, OnErrorCallback} from '@bugsnag/js';
import type {AnalyticsBase} from 'extensions/analytics/base';
import type {IntercomContextValues} from 'react-use-intercom';
import type {Organization, User} from 'store/slices/user/types';

const isLocal = __DEV__;
const isDev1 = location.host.endsWith('fastdev1.com');
const debugRoutingKey = localStorage.getItem(VE_DEBUG_ROUTING_KEY);

export const segment =
  !isLocal &&
  AnalyticsBrowser.load({
    writeKey: isDev1
      ? 'ievAnqPOH7gHUxihmJGFw6asBNSBhVlm'
      : 'U62iBo7jsHPx8EzVRo6VwO7suVSalz0k',
  });

export const bugsnag =
  !isLocal &&
  Bugsnag.start({
    apiKey: isDev1
      ? '1eb1c22a3ebcabbdaf24a62c612d96ea'
      : 'c2574495ca68f8c71fffd6bb9363cd18',
    appVersion: getVersion(true),
    releaseStage: getStage(),
    maxBreadcrumbs: 50,
    enabledBreadcrumbTypes: [
      'log',
      'user',
      'state',
      'error',
      'manual',
      'process',
      'request',
    ],
  });

class Analytics implements AnalyticsBase {
  identify(
    user: User,
    organization: Organization,
    intercom: IntercomContextValues,
  ) {
    if (isLocal) return;
    const {id, plan} = user;
    const avatar = user.profile_pic;
    const name = `${user.first_name} ${user.last_name}`;
    const email = user.email_address;
    const phone = user?.phone_number
      ? `(${user?.country_code}) ${user?.phone_number}`
      : undefined;

    Bugsnag.setUser(id, email, name);
    Bugsnag.addMetadata('Fastio', {
      debugRoutingKey,
    });

    intercom.update({
      userId: user.id,
      name,
      email,
      phone,
      avatar: {type: 'avatar', imageUrl: avatar},
      createdAt: user?.created?.getTime?.(),
      company: {
        name: organization?.name,
        plan: organization?.plan,
        website: organization?.homepage,
        companyId: organization?.id,
        createdAt: organization?.created?.getTime?.(),
      },
      customAttributes: {
        debug_routing_key: debugRoutingKey,
      },
    });

    segment.identify(
      id,
      {
        plan,
        name,
        email,
        phone,
        avatar,
        lastName: user.last_name,
        firstName: user.first_name,
        createdAt: user?.created,
        company: {
          id: organization?.id,
          name: organization?.name,
          plan: organization?.plan,
        },
      },
      {
        traits: {
          debugRoutingKey,
        },
      },
    );
  }

  notify(error: NotifiableError, onError?: OnErrorCallback) {
    if (isLocal) return;
    Bugsnag.notify(error, onError);
    const name =
      typeof error === 'string'
        ? error
        : typeof error === 'object' && 'name' in error
          ? error.name
          : 'Unknown';
    const message =
      typeof error === 'string'
        ? error
        : typeof error === 'object' && 'message' in error
          ? error.message
          : error.toString();
    const report = {
      name,
      message,
      debugRoutingKey,
    };
    segment.track('Error', report, {
      traits: {
        name,
        message,
        debugRoutingKey,
      },
    });
    globalThis?.Intercom?.('trackEvent', 'Error', report);
  }

  log(value: string, context?: Record<string, string>) {
    if (isLocal) return;
    segment.track(value, context);
    Bugsnag.leaveBreadcrumb(value, context);
    globalThis?.Intercom?.('trackEvent', value, context);
  }

  page(url: string) {
    if (isLocal) return;
    segment.page(url);
    Bugsnag.setContext(url);
    globalThis?.Intercom?.('update');
  }
}

export default new Analytics();
