import {t} from '@lingui/macro';
import type {ShareLink} from 'fast-sdk/src/api/share/consts';
import {Button} from 'interface/common/Button';
import {TextInput} from 'interface/common/TextInput';
import React from 'react';
import {StyleSheet, View} from 'react-native';

interface LinkFormProps {
  currentLink: ShareLink;
  setCurrentLink: (link?: ShareLink) => void;
  saveLink: () => void;
  removeAndCancelLink: () => void;
  isDraft: boolean;
}

export default function LinkForm({
  currentLink,
  setCurrentLink,
  saveLink,
  removeAndCancelLink,
  isDraft,
}: LinkFormProps) {
  return (
    <View style={styles.linkForm}>
      <TextInput
        label={t`Label`}
        value={currentLink.title}
        placeHolder={'Enter a label'}
        setInputValue={title => setCurrentLink({...currentLink, title})}
      />
      <TextInput
        label={t`URL`}
        value={currentLink.url}
        placeHolder={'https://mywebsite.com'}
        setInputValue={url => setCurrentLink({...currentLink, url})}
      />
      <View style={styles.formFooter}>
        <View>
          {!isDraft && (
            <Button
              type="Secondary"
              label={t`Remove`}
              onPress={removeAndCancelLink}
            />
          )}
        </View>
        <View style={styles.rightButtons}>
          <Button
            type="Secondary"
            label={t`Cancel`}
            onPress={() => setCurrentLink(undefined)}
          />
          <Button
            type="Primary"
            label={isDraft ? t`Add Link` : t`Save Link`}
            onPress={saveLink}
            disabled={!currentLink.title || !currentLink.url}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  linkForm: {
    flexDirection: 'column',
    display: 'flex',
  },
  formFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightButtons: {
    flexDirection: 'row',
    gap: 10,
  },
});
