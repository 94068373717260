import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import {useCallback} from 'react';
import {Pressable, StyleSheet, Text, type TextStyle} from 'react-native';
import AdvancedSettingsModal from './AdvancedSettingsModal';

interface Props {
  label: string;
  textStyle: TextStyle;
}

export default function AdvancedSettingsButton({label, textStyle}: Props) {
  const modal = useModal();

  const open = useCallback(() => {
    modal.open(<AdvancedSettingsModal onClose={modal.close} />);
  }, []);

  return (
    <Pressable onPress={open}>
      <Text style={[styles.text, textStyle]}>{label}</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  text: {
    lineHeight: 18,
    fontSize: 11,
    fontWeight: '400',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$6,
  },
});
