import theme from 'config/theme';
import {KeyboardListener} from 'interface/KeyboardListener';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {useState} from 'react';
import {StyleSheet, TextInput, View} from 'react-native';
import type {Member} from 'store/slices/settings/types';
import {AiChatKeyboardLabel} from './AiChatKeyboardLabel';
import {AiChatMembers} from './AiChatMembers';
import {AiChatSelectFilesButton} from './AiChatSelectFilesButton';
interface Props {
  workspaceId: string;
  chatId: string;
  workspaceMembers: Member[];
  onSendMessage: (message: string) => void;
  isEnabled: boolean;
}

export function AiChatInput({
  workspaceId,
  chatId,
  workspaceMembers,
  onSendMessage,
  isEnabled,
}: Props) {
  const [message, setMessage] = useState('');

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitMessage();
    }
  };

  const submitMessage = () => {
    if (message.trim() && isEnabled) {
      onSendMessage(message);
      setMessage('');
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.inputContainer}>
        <View style={styles.inputRow}>
          <KeyboardListener onKeyPress={handleKeyPress} customStyle={{flex: 1}}>
            <TextInput
              placeholder="Type a message..."
              placeholderTextColor={theme.colors.neutral.$400}
              multiline={true}
              scrollEnabled={false}
              numberOfLines={4}
              editable={isEnabled}
              onChangeText={setMessage}
              value={message}
              style={[
                styles.input,
                {
                  // @ts-ignore
                  outline: 'none',
                },
              ]}
            />
          </KeyboardListener>
          <Button
            onPress={submitMessage}
            variant="primary"
            disabled={!isEnabled}
            startEnhancer={
              <Icon name="lucide:arrow-up" color={theme.colors.neutral.$0} />
            }
            overrides={{
              Button: {
                style: {
                  backgroundColor: theme.colors.brand.$4,
                  width: 32,
                  height: 32,
                },
              },
            }}
          />
        </View>
        <AiChatSelectFilesButton workspaceId={workspaceId} />
      </View>

      <View style={styles.footer}>
        <AiChatMembers chatId={chatId} workspaceMembers={workspaceMembers} />
        <View style={styles.help}>
          <Typography size="xs" color={theme.colors.neutral.$400}>
            New line:
          </Typography>
          <AiChatKeyboardLabel label="Shift" icon="⇧" />
          <Typography size="xs" color={theme.colors.neutral.$400}>
            +
          </Typography>
          <AiChatKeyboardLabel label="Enter" icon="↵" />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: theme.colors.neutral.$0,
    flexDirection: 'column',
    borderTopColor: theme.colors.neutral.$75,
    borderTopWidth: 1,
  },
  inputWrapper: {},
  inputContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 16,
    flex: 1,
    overflow: 'visible',
    marginTop: 16,
    marginHorizontal: 16,
    borderRadius: 8,
    backgroundColor: theme.colors.neutral.$75,
  },
  inputRow: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    gap: 12,
  },
  input: {
    width: '100%',
    padding: 0,
    borderWidth: 0.5, // any value is required
    borderColor: 'transparent',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    height: 'fit-content',
    paddingEnd: 16,
  },
  help: {
    width: 'auto',
    flexDirection: 'row',
    gap: 6,
    alignItems: 'center',
  },
});
