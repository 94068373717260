import RichTextEditor from 'interface/common/RichTextEditor';
import {TextInput} from 'interface/common/TextInput';
import type {Share} from 'store/slices/shared/types';
import type {ShareState} from '../../../shared/dialogs/SharedCustomizeDialog/hooks/useShareActions';
import Section from './Section';
import SectionTitle from './SectionTitle';

interface Props {
  share: Share;
  shareState: ShareState;
  updateShare: (updatedShare: Partial<Share>) => void;
}

export default function ShareMainInfo({share, shareState, updateShare}: Props) {
  const {title, description, setTitle, setDescription} = shareState;

  const handleSetTitle = () => {
    if (title !== share.title) {
      updateShare({
        title,
      });
    }
  };

  const handleSetDescription = () => {
    if (description !== share.description) {
      updateShare({
        description,
      });
    }
  };

  return (
    <Section>
      <SectionTitle>Title and Description</SectionTitle>
      <TextInput
        value={title}
        placeHolder={'Enter a title'}
        setInputValue={setTitle}
        setBlur={handleSetTitle}
      />
      <RichTextEditor
        content={description}
        onChangeText={setDescription}
        placeholder="Enter a description"
        minHeight={150}
        onBlur={handleSetDescription}
      />
    </Section>
  );
}
