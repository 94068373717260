import {useParams} from 'extensions/navigation';
import useGetShareDetails from 'interface/stacks/share/hooks/useGetShareDetails';
import {ScrollView, StyleSheet} from 'react-native';
import ShareContent from './components/ShareContent';

export default function SharePanelConfig() {
  const {sharedCustomName} = useParams();

  const {share} = useGetShareDetails({
    sharedCustomName,
  });

  return (
    <ScrollView style={styles.root} showsVerticalScrollIndicator={false}>
      {share && <ShareContent share={share} />}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
