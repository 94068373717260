import {useSelector} from 'react-redux';
import * as workspace from 'store/slices/workspace';
import {WorkspaceCardList} from '../base/WorkspaceCardList';
import {WorkspacesToolbar} from './WorkspacesToolbar';

export function BrowseWorkspaces() {
  const workspaces = useSelector(workspace.selectors.getAllWorkspacesInOrg);

  return (
    <>
      <WorkspacesToolbar showArchived />
      <WorkspaceCardList workspaces={workspaces} />
    </>
  );
}
