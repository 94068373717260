import {t} from '@lingui/macro';
import theme from 'config/theme';
import {useNavigate} from 'extensions/navigation';
import {api} from 'fast-sdk';
import {Button} from 'interface/common/Button';
import Chip from 'interface/common/Chip';
import {useNavigatorRedirection} from 'interface/common/hooks/navigator/useNavigatorRedirection';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {useEffect, useMemo, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import type {Member} from 'store/slices/settings/types';
import * as user from 'store/slices/user';
import type {Organization} from 'store/slices/user/types';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';
import {blobToBase64} from 'utils/common/image';
import {getCurrentPath} from 'utils/common/platform';
import {getOrganizationMembersDetails} from '../consts';
import {InvitedBy} from './InvitedBy';
import {MemberAvatarGroup} from './MemberAvatarGroup';
import RowCard from './RowCard';

type Props = {
  loading?: boolean;
  organization: Organization;
  pending?: boolean;
  invitedByEmail?: string;
  setLoading?: (loading: boolean) => void;
  onJoinButtonPress?: () => void;
  index?: number;
  roundedBottom?: boolean;
  borderBottom?: boolean;
};

export function OrganizationCard({
  loading,
  organization,
  pending,
  invitedByEmail,
  setLoading,
  onJoinButtonPress,
  index,
  roundedBottom,
  borderBottom,
}: Props) {
  const navigate = useNavigate();
  const {subdomain} = useSubDomain();

  const [members, setMembers] = useState<Array<Member>>();
  const [isMembersLoading, setIsMembersLoading] = useState(false);

  const [orgLogo, setOrgLogo] = useState<string>();

  const domainOrigin = useSelector(app.selectors.getEnvironmentDomain);
  const isLoggedin = useSelector(app.selectors.isLoggedIn);
  const userDetails = useSelector(user.selectors.getUserDetails);

  const orgSubdomain = organization?.domain;
  const isOwner = `${organization.parent}` === userDetails.id;

  const {redirectToMainApp} = useNavigatorRedirection();
  // const {billingStatus} = useBillingDetails(orgSubdomain, {
  //   disable: !isOwner,
  // });

  // const dispatch = useDispatch();

  const getMembers = async (domain: string) => {
    setIsMembersLoading(true);
    try {
      const members = await getOrganizationMembersDetails(domain);
      setMembers(members);
    } finally {
      setIsMembersLoading(false);
    }
  };

  const getLogo = async (domain: string) => {
    const logo = await api.organization.getAsset(domain, 'logo');
    blobToBase64(logo, setOrgLogo);
  };

  const handleGoToOrganization = async () => {
    setLoading(true);
    const path = getCurrentPath();
    const nextPage = redirectToMainApp(path, organization.domain, true);
    navigate(`/${nextPage}`);
    setLoading(false);
  };

  const handleJoin = () => {
    if (onJoinButtonPress) onJoinButtonPress();
  };

  useEffect(() => {
    if (orgSubdomain) {
      getLogo(orgSubdomain);
    }
  }, [orgSubdomain]);

  const shouldGetMembers = useMemo(() => {
    return orgSubdomain && isLoggedin && !pending;
  }, [orgSubdomain, isLoggedin, pending]);

  useEffect(() => {
    if (shouldGetMembers) {
      getMembers(orgSubdomain);
    }
  }, [shouldGetMembers]);

  const isRootDisabled = pending || !isLoggedin;

  const showInvitedBy = pending && invitedByEmail;

  return (
    <RowCard
      disabled={isRootDisabled}
      borderProps={{
        borderTop: index === 0,
        borderBottom: roundedBottom || borderBottom,
        roundedTop: index === 0,
        roundedBottom: roundedBottom,
      }}
      onPress={handleGoToOrganization}
      avatarColor={
        organization.accent_color?.color ?? getHashColor(organization.id)
      }
      avatarInitials={createInitials(organization.name)}
      avatarPhoto={orgLogo}
      main={
        <View style={styles.content}>
          <View style={styles.header}>
            <Text style={styles.name} numberOfLines={1}>
              {organization.name}
            </Text>
            <Text style={styles.domain} numberOfLines={1}>
              {`(${organization.domain}.${domainOrigin.replace(`${subdomain}.`, '')})`}
            </Text>
            {index !== undefined && isOwner && (
              <Chip
                label="Owner"
                color={theme.colors.caution.$5}
                textColor={theme.colors.neutral.$2Base}
              />
            )}
          </View>
          <MemberAvatarGroup members={members} loading={isMembersLoading} />
        </View>
      }
      right={
        pending ? (
          <Button
            type="Primary"
            label={t`Join`}
            onPress={handleJoin}
            customRootStyle={styles.joinButton}
            ariaLabel="Join Organization"
            loading={loading}
          />
        ) : (
          <Icon
            name="arrow-forward"
            size={24}
            color={theme.colors.brand.$4Base}
          />
        )
      }
      bottom={showInvitedBy && <InvitedBy invitedByEmail={invitedByEmail} />}
    />
  );
}

const styles = StyleSheet.create({
  content: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 7,
    marginBottom: 7,
  },
  name: {
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
  domain: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$4,
  },
  joinButton: {
    minWidth: 80,
  },
});
