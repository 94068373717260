import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import {useEffect, useState} from 'react';
import {Pressable, StyleSheet, TextInput} from 'react-native';
import {validateEmail} from 'utils/common/validation';

interface AddEmailChipProps {
  email: string;
  showError?: boolean;
  enableEdit?: boolean;
  setIsEditing: (isEditing: boolean) => void;
  deleteString: () => void;
  onEmailChange?: (email: string) => void;
}

export function AddEmailChip(props: AddEmailChipProps) {
  const [localEmail, setLocalEmail] = useState(props.email);
  const [textWidth, setTextWidth] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const isEmailValid = validateEmail(localEmail);
  const showErrorStyle = props.showError && !isEmailValid;

  const handleBlur = () => {
    props.onEmailChange(localEmail);
    setIsEditing(false);
    props.setIsEditing(false);
  };

  const calculateTextWidth = (text: string) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = '13px sans-serif';
      const metrics = context.measureText(text);
      setTextWidth(Math.max(50, metrics.width + 30));
    }
  };

  useEffect(() => {
    calculateTextWidth(localEmail);
  }, [localEmail]);

  return (
    <Pressable
      style={[
        styles.root,
        isEditing && styles.editing,
        showErrorStyle && styles.error,
      ]}
      onPress={() => {
        setIsEditing(true);
        props.setIsEditing(true);
      }}>
      <TextInput
        value={localEmail}
        onChangeText={text => {
          setLocalEmail(text);
          calculateTextWidth(text);
        }}
        onBlur={handleBlur}
        style={[
          styles.email,
          {
            // @ts-ignore
            outline: 'none',
            width: textWidth,
          },
        ]}
      />
      <Pressable style={styles.icon} onPress={props.deleteString}>
        <Icon name="lucide:x" size={18} color={theme.colors.neutral.$5} />
      </Pressable>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.$white,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$9,
    borderRadius: 50,
    padding: 2,
    flexShrink: 0,
    width: 'auto',
    minWidth: 50,
  },
  error: {
    borderColor: theme.colors.danger.$5,
  },
  editing: {
    borderColor: theme.colors.brand.$4Base,
  },
  email: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
    paddingHorizontal: 8,
    paddingVertical: 5,
    minWidth: 20,
  },
  icon: {
    padding: 6,
  },
});
