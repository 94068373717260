import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';
import HTML from 'react-native-render-html';
import type {Share} from 'store/slices/shared/types';
import {ShareExpiration} from './ShareExpiration';
import ShareLinks from './ShareLinks';

interface Props {
  share: Share;
}

export default function ShareHeaderInfo({share}: Props) {
  const expirationDate = share.expires ? new Date(share.expires) : undefined;
  return (
    <View style={styles.infoContainer}>
      <View style={styles.titleContainer}>
        <Typography
          size="2xl"
          color={theme.colors.neutral.$2Base}
          variant="medium">
          {share.title}
        </Typography>
        {expirationDate && (
          <ShareExpiration
            expirationDate={expirationDate}
            shareType={share.share_type}
          />
        )}
      </View>
      <HTML source={{html: share.description}} />
      <ShareLinks share={share} />
    </View>
  );
}

const styles = StyleSheet.create({
  infoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 12,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
