import {ROUTES, WORKSPACE_ROOT} from 'constants/routes';
import {Navigate} from 'react-router';
import type {Organization} from 'store/slices/user/types';
import {isSubdomainValid} from 'utils/common/platform';

type Props = {
  organizationsList: Organization[];
  subdomain: string;
};

const DefaultRedirect = ({organizationsList, subdomain}: Props) => {
  if (organizationsList.length > 0 && isSubdomainValid(subdomain)) {
    return (
      <Navigate
        to={`/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${WORKSPACE_ROOT}`}
        replace
      />
    );
  }

  return <Navigate to={ROUTES.LOGGED_IN_WITHOUT_ORG.HOME} replace />;
};

export default DefaultRedirect;
