import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {useHover} from 'interface/common/hooks/useHover';
import {useState} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useSelector} from 'react-redux';
import {selectors as app} from 'store/slices/app';
import {selectors as user} from 'store/slices/user';
import {AppUserAvatar} from '../AppUserAvatar';
import {AccountDropdown} from './AccountDropdown';

type Props = {
  profilePicture?: string;
  fullname?: string;
  size?: 'mini' | 'full';
  kind?: 'light' | 'dark';
};

export default function AppHeaderAccountDropdown({
  profilePicture,
  fullname,
  size = 'full',
  kind = 'light',
}: Props) {
  const {isHover, onHoverIn, onHoverOut} = useHover();

  const details = useSelector(user.getUserDetails);
  const name = useSelector(app.getName);

  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);

  const onOpenAccountDropdown = () => {
    setIsAccountDropdownOpen(true);
  };

  const closeAccountDropdown = () => {
    setIsAccountDropdownOpen(false);
  };

  const userName = details?.first_name
    ? `${details?.first_name} ${details?.last_name}`
    : name || 'Anonymous';

  const renderTrigger = () => {
    return (
      <Pressable
        onHoverIn={onHoverIn}
        onHoverOut={onHoverOut}
        onPress={onOpenAccountDropdown}
        style={[
          styles.account,
          {
            borderColor:
              kind === 'light'
                ? theme.colors.neutral.$10
                : theme.colors.neutral.$4,
          },
          isHover && {
            backgroundColor:
              kind === 'light'
                ? theme.colors.neutral.$13
                : theme.colors.neutral.$3,
          },
        ]}>
        <AppUserAvatar profilePicture={profilePicture} />
        {size === 'full' && <Text style={styles.text}>{userName}</Text>}
        <View style={styles.icon}>
          <Icon
            name="keyboard-arrow-down"
            size={20}
            color={theme.colors.neutral.$5}
            style={{
              transform: [{rotate: isAccountDropdownOpen ? '180deg' : '0deg'}],
            }}
          />
        </View>
      </Pressable>
    );
  };

  return (
    <Popup
      placement="bottom-end"
      isOpen={isAccountDropdownOpen}
      triggerElement={renderTrigger()}
      close={closeAccountDropdown}>
      <AccountDropdown
        close={closeAccountDropdown}
        profilePicture={profilePicture}
        fullname={fullname}
      />
    </Popup>
  );
}

const styles = StyleSheet.create({
  text: {
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'left',
    color: theme.colors.neutral.$2Base,
  },
  account: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    paddingVertical: 3,
    paddingLeft: 3,
    paddingRight: 15,
    borderRadius: 50,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  icon: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: '100%',
  },
});
