import {
  type ErrorConfiguration,
  ErrorFlowActions,
  type RetryOptions,
} from 'errors/types';
import type {ToastOptions} from 'react-native-toast-notifications';

export const DEFAULT_ERROR_CONFIG: Partial<ErrorConfiguration> = {
  flowAction: ErrorFlowActions.Toast,
  allowReset: true,
};

export const TOAST_AUTO_HIDE_DELAY_MS = 3 * 1000;

export const DEFAULT_ERROR_TOAST_OPTIONS: ToastOptions = {
  type: 'danger',
  duration: TOAST_AUTO_HIDE_DELAY_MS,
};

export const DEFAULT_RETRY_OPTIONS: RetryOptions = {
  backoffFactor: 2,
  automatic: false,
  maxRetries: 5,
  maxRetryInterval: 60 * 1000,
  retryInterval: 1 * 1000,
};

export const DEFAULT_RETRIES_ON_GENERIC_ERROR = 1;

export const DEFAULT_FRIENDLY_USER_ERROR_TITLE =
  'Oops! Something unexpected happened';

export const DEFAULT_FRIENDLY_USER_ERROR_MESSAGE =
  "There's a problem with our platform. Try this request again or contact support.";
