import {createSelector} from '@reduxjs/toolkit';
import type {EventProfile} from 'fast-sdk/src/api/events/consts';
import type {Store} from 'store';
import {getProfileKey, profileInitialState} from './helpers';

const x = (store: Store) => store;

export const getProfileState = (profile: EventProfile) =>
  createSelector(
    x,
    store =>
      store.events.profiles[getProfileKey(profile)] || {...profileInitialState},
  );
