import type {StorageBase} from 'extensions/storage/base';
import {clear, createStore, del, get, set} from 'idb-keyval';

export class Storage implements StorageBase {
  init(id: string) {
    const db = createStore(`db-${id}`, `store-${id}`);
    return {
      getItem: (key: string) => get(key, db),
      setItem: (key: string, value: any) => set(key, value, db),
      removeItem: (key: string) => del(key, db),
      clearAll: () => clear(db),
    };
  }
}

export default new Storage();
