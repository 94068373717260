import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import useFileThumbnailSource from 'interface/stacks/workspace/storage/hooks/useFileThumbnailSource';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as files from 'store/slices/files';
import type {FilesItem} from 'store/slices/files/types';
import {getFileTypeFromMime} from 'utils/fast/files';
import usePagination from './usePagination';

export default function useContentPrefetch() {
  const {prev, next} = usePagination();
  const dispatch = useDispatch();
  const {fetchFileThumbnail} = useFileThumbnailSource();

  const prevThumbnailUrl = useSelector(
    files.selectors.selectThumbnail(
      `${prev?.id}-${FileThumbnailSizes.Preview}`,
    ),
  );
  const nextThumbnailUrl = useSelector(
    files.selectors.selectThumbnail(
      `${next?.id}-${FileThumbnailSizes.Preview}`,
    ),
  );

  const prefetchThumbnail = useCallback(
    async (item: FilesItem) => {
      const fileType = getFileTypeFromMime(item);
      const newThumbnail = await fetchFileThumbnail(
        item,
        FileThumbnailSizes.Preview,
        fileType,
      );
      if (newThumbnail) {
        dispatch(
          files.default.actions.setThumbnail({
            thumbnailKey: `${item.id}-${FileThumbnailSizes.Preview}`,
            source: newThumbnail,
          }),
        );
      }
    },
    [fetchFileThumbnail],
  );

  useEffect(() => {
    if (!prev || prevThumbnailUrl) {
      return;
    }

    prefetchThumbnail(prev);
  }, [prefetchThumbnail, prev, prevThumbnailUrl]);

  useEffect(() => {
    if (!next || nextThumbnailUrl) {
      return;
    }

    prefetchThumbnail(next);
  }, [prefetchThumbnail, next, nextThumbnailUrl]);
}
