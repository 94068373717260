import {t} from '@lingui/macro';
import RichTextEditor from 'interface/common/RichTextEditor';
import {TextInput} from 'interface/common/TextInput';
import {View} from 'react-native';

import type {ShareState} from '../hooks/useShareActions';

interface ShareDetailsProps {
  shareState: ShareState;
}

export function ShareDetails({shareState}: ShareDetailsProps) {
  const {title, description, setTitle, setDescription} = shareState;

  return (
    <View>
      <TextInput
        label={t`Title`}
        value={title}
        placeHolder={'Enter a title'}
        setInputValue={setTitle}
      />
      <RichTextEditor
        label="Description"
        content={description}
        onChangeText={setDescription}
        placeholder="Enter a description"
        minHeight={160}
      />
    </View>
  );
}
