import theme from 'config/theme';
import {AppMemberAvatar} from 'interface/stacks/app/AppMemberAvatar';
import {StyleSheet, View} from 'react-native';
import {type Share, ShareViewStatus} from 'store/slices/shared/types';
import ShareHeaderAccount from './ShareHeaderAccount';
import ShareHeaderActions from './ShareHeaderActions';
import ShareHeaderOrg from './ShareHeaderOrg';

import type {MultiplayerMember} from 'interface/multiplayer/types';

interface Props {
  share?: Share;
  members?: MultiplayerMember[];
}

export default function ShareHeader({share, members}: Props) {
  const canManage =
    share?.shareViewStatus === ShareViewStatus.Owner ||
    share?.shareViewStatus === ShareViewStatus.Editor;

  return (
    <View style={styles.root}>
      {share && (
        <View style={styles.section}>
          <ShareHeaderOrg orgId={share?.parent_org} />
        </View>
      )}
      <View style={styles.section}>
        <View style={styles.buttons}>
          {canManage && <ShareHeaderActions share={share} />}
          {members?.map(member => (
            <AppMemberAvatar
              key={member.clientId}
              member={member}
              borderKind={'light'}
              disableFollow
            />
          ))}
        </View>
        <ShareHeaderAccount />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
    borderColor: theme.colors.neutral.$12,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    paddingHorizontal: 16,
  },
});
