import {t} from '@lingui/macro';
import {Picker} from '@react-native-picker/picker';
import theme from 'config/theme';
import {api} from 'fast-sdk';
import {useCallback, useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';

import type {
  WorkspaceDetails,
  WorkspaceListDetail,
} from 'fast-sdk/src/api/workspace/consts';
import type {Dispatch, SetStateAction} from 'react';

interface WorkspaceSettingsPermissionsProps {
  workspace: WorkspaceListDetail;
  close: () => void;
}

export function WorkspaceSettingsPermissions(
  props: WorkspaceSettingsPermissionsProps,
) {
  const [loading, setLoading] = useState(true);
  const [perm_join, setPermJoin] =
    useState<WorkspaceDetails['perm_join']>(null);
  const [perm_member_manage, setPermMemberManage] =
    useState<WorkspaceDetails['perm_member_manage']>(null);
  const toast = useToast();

  const getWorkspacePermissions = useCallback(async () => {
    setLoading(true);
    const workspace = await api.workspace.getWorkspaceDetails(
      props.workspace.folder_name,
    );
    if (workspace.result) {
      setPermJoin(workspace.workspace.perm_join);
      setPermMemberManage(workspace.workspace.perm_member_manage);
    }
    setLoading(false);
  }, [props.workspace.folder_name]);

  const updateWorkspacePermission = useCallback(
    (
      permissions: keyof WorkspaceDetails,
      update: Dispatch<
        SetStateAction<WorkspaceDetails[keyof WorkspaceDetails]>
      >,
    ) =>
      async (value: WorkspaceDetails[keyof WorkspaceDetails]) => {
        const ws = await api.workspace.updateWorkspace(
          props.workspace.folder_name,
          {
            [permissions]: value,
          },
        );
        if (ws.result) {
          toast.show(t`Workspace permissions updated`, {type: 'info'});
          update(value);
        }
      },
    [props.workspace.folder_name, toast],
  );

  useEffect(() => {
    getWorkspacePermissions();
  }, [getWorkspacePermissions]);

  return (
    <View style={styles.root}>
      <View style={styles.options}>
        <View style={styles.option}>
          <Text style={styles.label}>{t`Joining Workspace`}</Text>
          <Picker
            enabled={!!perm_join}
            selectedValue={perm_join ?? ''}
            onValueChange={updateWorkspacePermission('perm_join', setPermJoin)}
            style={styles.picker}>
            <Picker.Item
              label={loading ? t`Loading Policy...` : t`Join Policy`}
              value=""
              enabled={false}
            />
            <Picker.Item label={t`Only Org Owners`} value={'Only Org Owners'} />
            <Picker.Item label={t`Admin or above`} value={'Admin or above'} />
            <Picker.Item label={t`Member or above`} value={'Member or above'} />
            <Picker.Item
              label={t`No one can join automatically`}
              value={'No one can join automatically'}
            />
          </Picker>
        </View>
        <View style={[styles.option, {borderBottomWidth: 0}]}>
          <Text style={styles.label}>{t`Member Management`}</Text>
          <Picker
            enabled={!!perm_member_manage}
            selectedValue={perm_member_manage ?? ''}
            onValueChange={updateWorkspacePermission(
              'perm_member_manage',
              setPermMemberManage,
            )}
            style={styles.picker}>
            <Picker.Item
              label={loading ? t`Loading Policy...` : t`Member Policy`}
              value=""
              enabled={false}
            />
            <Picker.Item label={t`Admin or above`} value={'Admin or above'} />
            <Picker.Item label={t`Member or above`} value={'Member or above'} />
            <Picker.Item label={t`Guest or above`} value={'Guest or above'} />
          </Picker>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    marginBottom: 14,
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
  },
  option: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: theme.colors.neutral.$10,
    paddingVertical: 12,
    gap: 8,
  },
  label: {
    flex: 1,
    fontSize: 14,
    fontWeight: '600',
    color: theme.colors.neutral.$3,
  },
  picker: {
    display: 'flex',
    paddingHorizontal: 8,
    paddingVertical: 6,
    gap: 8,
    borderRadius: 3,
    borderWidth: 0,
    height: 36,
    borderRightWidth: 8,
    borderRightColor: 'transparent',
    color: theme.colors.neutral.$4,
    boxShadow: `0px 0px 0px 1px ${theme.colors.neutral.$10}`,
    borderColor: 'transparent',
  },
});
