import LogoDark from 'assets/fastio_logo_assets/logo_dark.svg';
import LogoLight from 'assets/fastio_logo_assets/logo_light.svg';
import {Image, Pressable, StyleSheet} from 'react-native';
import {useNavigate} from 'react-router';

interface AppLogoProps {
  kind?: 'light' | 'dark';
}

export default function AppLogo({kind = 'light'}: AppLogoProps) {
  const navigate = useNavigate();
  return (
    <Pressable
      onPress={() => {
        navigate('/');
      }}>
      <Image
        source={kind === 'light' ? LogoLight : LogoDark}
        style={styles.logo}
        alt="Fast.io logo"
      />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  logo: {
    width: 129,
    height: 48,
  },
});
