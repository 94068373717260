import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import type {PropsWithChildren} from 'react';
import {View} from 'react-native';

import {StyleSheet} from 'react-native';

type Props = {
  buttonText: string;
  buttonOnPress: () => void;
};

export function BillingControlItem({
  children,
  buttonText,
  buttonOnPress,
}: PropsWithChildren<Props>) {
  return (
    <View style={styles.root}>
      {children}
      <Button
        overrides={{
          Button: {
            style: styles.button,
          },
        }}
        onPress={buttonOnPress}
        variant="secondary"
        size="md">
        {buttonText}
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.$50,
    paddingHorizontal: 24,
    paddingVertical: 30,
    borderRadius: 16,
    maxWidth: 650,
  },
  button: {
    backgroundColor: theme.colors.neutral.$100,
  },
});
