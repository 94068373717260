import {BilledDurationType} from 'store/slices/settings/types';
import {DISCOUNT} from './consts';

export function validateSubdomain(subdomain: string) {
  if (!/[0-9]/.test(subdomain[0]) && !/[a-z]/.test(subdomain[0])) return false;
  if (!/[!@#$%^&._*()+=,/\\?]/.test(subdomain)) return true;
  return false;
}

export function prepareSubdomain(subdomain: string, domainOrigin: string) {
  let result = '';
  const string = subdomain
    .split(`.${domainOrigin}`)[0]
    .split(' ')
    .join('')
    .toLowerCase();
  for (const char of string) {
    if (validateSubdomain(char) || char === '-') {
      result += char;
    }
  }
  return result;
}

export function calcAmount(price: number, unit: BilledDurationType) {
  if (unit === BilledDurationType.Monthly) {
    return price;
  }
  if (unit === BilledDurationType.Yearly) {
    return (price * 12 * ((100 - DISCOUNT) / 100)) / 12;
  }
}
