import theme from 'config/theme';
import React from 'react';
import {StyleSheet} from 'react-native';
import IconCommunity from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialIcons';
import ActionButton from '../ActionButton';

type Props = {
  isOpen: boolean;
  onPress: () => void;
};

const PagesButton = ({isOpen, onPress}: Props) => {
  return (
    <ActionButton
      icon={
        isOpen ? (
          <IconCommunity
            name="arrow-collapse-left"
            size={20}
            color={theme.colors.neutral.$white}
          />
        ) : (
          <Icon
            name="view-carousel"
            size={20}
            color={theme.colors.neutral.$white}
            style={styles.root}
          />
        )
      }
      tooltipText="Pages"
      onPress={onPress}
    />
  );
};

const styles = StyleSheet.create({
  root: {
    transform: [{rotate: '90deg'}],
  },
});

export default PagesButton;
