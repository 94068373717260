import {VolumeSlider} from '@vidstack/react';
import theme from 'config/theme';
import {StyleSheet} from 'react-native';

const KNOB_SIZE = 12;
const PROGRESS_SIZE = {width: 80, height: 4};

const ExtVolumeSlider = () => {
  return (
    <VolumeSlider.Root className="vds-slider" style={styles.progress}>
      <VolumeSlider.Track
        className="vds-slider-track"
        style={styles.progressEmpty}
      />
      <VolumeSlider.TrackFill
        className="vds-slider-track-fill vds-slider-track"
        style={styles.progressFilled}
      />
      <VolumeSlider.Thumb className="vds-slider-thumb" style={styles.knob} />
    </VolumeSlider.Root>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  volume: {
    width: 100,
  },
  progress: {
    width: PROGRESS_SIZE.width,
    marginTop: -8,
    marginBottom: -8,
  },
  knob: {
    width: KNOB_SIZE,
    height: KNOB_SIZE,
    backgroundColor: theme.colors.neutral.$8,
  },
  progressFilled: {
    height: PROGRESS_SIZE.height,
    borderRadius: 2,
    backgroundColor: theme.colors.neutral.$8,
  },
  progressEmpty: {
    height: PROGRESS_SIZE.height,
    borderRadius: 2,
    backgroundColor: theme.colors.neutral.$3,
  },
});

export default ExtVolumeSlider;
