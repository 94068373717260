import {ErrorFlowActions} from 'errors/types';
import analytics from 'extensions/analytics';
import {useModal} from 'extensions/viewport/useModal';
import {ErrorPage} from 'interface/stacks/app/ErrorPage';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Outlet} from 'react-router';
import {useIntercom} from 'react-use-intercom';

import {ErrorComponent} from './ErrorComponent';
import {useHandleNoActionErrors} from './hooks/useHandleNoActionErrors';
import {useShowToastErrors} from './hooks/useShowToastErrors';

import * as app from 'store/slices/app';
import * as errors from 'store/slices/errors';
import * as user from 'store/slices/user';

export const ErrorWrapper = () => {
  const intercom = useIntercom();
  const showError = useSelector(errors.selectors.getShowError);
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const userDetails = useSelector(user.selectors.getUserDetails);
  const userOrganization = useSelector(user.selectors.getSelectedOrganization);
  const lastError = useSelector(errors.selectors.getLastError);

  const [renderErrorPage, setRenderErrorPage] = useState<boolean>(false);
  const modal = useModal();

  useShowToastErrors();
  useHandleNoActionErrors();

  // Update analytics identities when user details change
  useEffect(() => {
    if (!isLoggedIn) return;
    analytics.identify(userDetails, userOrganization, intercom);
  }, [isLoggedIn, userDetails, intercom.update, intercom.show]);

  // Show error page / dialog if we have an error
  useEffect(() => {
    if (showError) {
      if (
        isLoggedIn ||
        lastError?.errorConfiguration?.flowAction === ErrorFlowActions.Dialog
      ) {
        // Workaround for modal not showing when another modal is open
        setTimeout(() => {
          modal.open(<ErrorComponent showCloseButton />);
        }, 100);
        setRenderErrorPage(false);
      } else {
        setRenderErrorPage(true);
      }
    } else {
      modal.close();
      setRenderErrorPage(false);
    }
  }, [showError]);

  if (renderErrorPage) return <ErrorPage />;

  return <Outlet />;
};
