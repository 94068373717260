import {api} from 'fast-sdk';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useCallback, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import * as workspaceSlice from 'store/slices/workspace';

export default function useLeaveWorkspace() {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const leaveWorkspace = useCallback(async (workspace: WorkspaceListDetail) => {
    setLoading(true);
    try {
      const {result} = await api.workspace.leaveWorkspace(
        workspace.folder_name,
      );
      if (result) {
        toast.show(
          `You've successfully leaved the "${workspace.name}" workspace.`,
          {
            type: 'neutral',
          },
        );
        dispatch(workspaceSlice.default.actions.leaveWorkspace(workspace));
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return {leaveWorkspace, loading};
}
