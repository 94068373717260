import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import Skeleton from 'interface/common/Skeleton';
import type {PropsWithChildren} from 'react';
import {StyleSheet, View} from 'react-native';

interface Props {
  title: string;
  content: string;
  loadingTitle?: boolean;
  loadingContent?: boolean;
}

export function BillingInfoItem({
  children,
  title,
  content,
  loadingTitle,
  loadingContent,
}: PropsWithChildren<Props>) {
  return (
    <View style={styles.root}>
      {loadingTitle ? (
        <Skeleton height={20} width={130} />
      ) : (
        <Typography
          variant="regular"
          size="sm"
          color={theme.colors.neutral.$700}>
          {title}
        </Typography>
      )}
      {loadingContent ? (
        <Skeleton height={30} width={80} />
      ) : (
        <Typography
          variant="semi-bold"
          size="xl"
          color={theme.colors.neutral.$700}>
          {content}
        </Typography>
      )}
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
});
