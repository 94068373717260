import {ActivityTypes} from 'fast-sdk/src/websockets/types';
import {useGetOrganizationDetails} from 'interface/common/hooks/useGetOrganizationDetails';
import {useGetOrganizationLogo} from 'interface/common/hooks/useGetOrganizationLogo';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as activity from 'store/slices/activity';
import {EntityType} from 'store/slices/activity/types';
import * as settings from 'store/slices/settings';
import type {Organization} from 'store/slices/user/types';
import {getCurrentUTCDateTime} from 'utils/common/dates';
import {shouldUpdateCache} from '../utils';

interface UseSyncOrganizationDataOptions {
  organization: Organization;
}

const useSyncOrganizationData = ({
  organization,
}: UseSyncOrganizationDataOptions) => {
  const dispatch = useDispatch();

  const organizationLogo = useSelector(settings.selectors.getOrganizationLogo);

  const organizationActivities =
    useSelector(activity.selectors.getOrganizationActivities(organization?.id))
      ?.activities ?? {};

  const organizationDetailsActivity =
    organizationActivities[ActivityTypes.DETAILS];

  const organizationAssetsActivity =
    organizationActivities[ActivityTypes.ASSETS];

  const {fetchOrganizationDetails} = useGetOrganizationDetails();
  const {fetchOrganizationLogo} = useGetOrganizationLogo();

  const updateEntityLastClientUpdate = useCallback(
    (organizationId: string, activityType: string) => {
      const lastClientUpdate = getCurrentUTCDateTime();
      dispatch(
        activity.default.actions.setEntitiesLastClientUpdate({
          entities: [organizationId],
          activities: [activityType],
          entityType: EntityType.Organization,
          lastClientUpdate,
        }),
      );
    },
    [],
  );

  const onOrganizationChange = useCallback(() => {
    if (organization?.id) {
      updateEntityLastClientUpdate(organization.id, ActivityTypes.DETAILS);
    }
  }, []);

  const onOrganizationLogoChange = useCallback(() => {
    if (organization?.id) {
      updateEntityLastClientUpdate(organization.id, ActivityTypes.ASSETS);
    }
  }, []);

  useEffect(() => {
    onOrganizationLogoChange();
  }, [organizationLogo]);

  useEffect(() => {
    onOrganizationChange();
  }, [organization]);

  useEffect(() => {
    if (organizationDetailsActivity) {
      const {lastServerUpdate, lastClientUpdate} = organizationDetailsActivity;
      if (shouldUpdateCache(lastServerUpdate, lastClientUpdate)) {
        fetchOrganizationDetails(organization.domain);
      }
    }
  }, [organizationDetailsActivity]);

  useEffect(() => {
    if (organizationAssetsActivity) {
      const {lastServerUpdate, lastClientUpdate} = organizationAssetsActivity;
      if (shouldUpdateCache(lastServerUpdate, lastClientUpdate)) {
        fetchOrganizationLogo(organization.id);
      }
    }
  }, [organizationAssetsActivity]);
};

export default useSyncOrganizationData;
