import {Icon as Iconify} from '@iconify/react';

export interface IconProps {
  name: string;
  size?: number;
  color?: string;
  opacity?: number;
}

export function Icon({name, size = 18, color, opacity}: IconProps) {
  return (
    <Iconify
      icon={name}
      width={size}
      height={size}
      color={color}
      style={{opacity: opacity}}
    />
  );
}
