import {Trans, t} from '@lingui/macro';
import images from 'config/images';
import theme from 'config/theme';
import {useNavigate} from 'extensions/navigation';
import {StyleSheet, Text, View, useWindowDimensions} from 'react-native';
import {LoginButton} from '../components/LoginButton';
import {LoginButtons} from '../components/LoginButtons';

interface Props {
  signInOrSignUp: 'SignIn' | 'SignUp';
  customEmailOnPress?: () => void;
}

export function LoginComponent(props: Props) {
  const navigate = useNavigate();
  const isSignUpPage = props.signInOrSignUp === 'SignUp';
  const backgroundColor = theme.colors.neutral.$white;
  const borderBackgroundColor = theme.colors.neutral.$12;
  const dimension = useWindowDimensions();

  return (
    <View style={styles.root}>
      <LoginButtons signUpButtons={isSignUpPage} />
      <>
        <View style={styles.orContainer}>
          <Text style={[styles.or, {backgroundColor: backgroundColor}]}>
            <Trans>or</Trans>
          </Text>
          <View
            style={[
              styles.orBorder,
              {
                backgroundColor: borderBackgroundColor,
                width: dimension.width > 790 ? '100%' : dimension.width - 40,
              },
            ]}
          />
        </View>
        <LoginButton
          signUpButton
          title={isSignUpPage ? t`Sign up with email` : t`Sign in with email`}
          customRootStyle={{marginBottom: 0}}
          onPress={
            props.customEmailOnPress
              ? props.customEmailOnPress
              : () =>
                  isSignUpPage
                    ? navigate('/signup/email')
                    : navigate('/signin/email')
          }
          icon={{
            uri: images.whiteEmailIcon,
          }}
          height={16}
          width={20}
        />
      </>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  orContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: '0.5rem',
    marginVertical: '1.5rem',
  },
  or: {
    position: 'absolute',
    flexWrap: 'nowrap',
    paddingHorizontal: 10,
    zIndex: 1,
    lineHeight: 22,
    fontSize: 14,
    fontWeight: '400',
    color: theme.colors.neutral.$5,
  },
  orBorder: {
    height: 1,
  },
});
