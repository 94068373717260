import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {StyleSheet, Text, View, useWindowDimensions} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

interface Props {
  invitedByEmail: string;
}

export function InvitedBy({invitedByEmail}: Props) {
  const dimension = useWindowDimensions();

  return (
    <View
      style={[
        styles.container,
        {
          flexDirection: dimension.width > 790 ? 'row' : 'column',
          alignItems: dimension.width > 790 ? 'center' : 'flex-start',
        },
      ]}>
      <View style={styles.infoContainer}>
        <Icon name="person-add" size={20} color={theme.colors.neutral.$4} />
        <View style={styles.textContainer}>
          <Text style={styles.invitedByText}>
            <Trans>Invited by</Trans>
          </Text>
          <Text style={styles.invitedByEmail}>{invitedByEmail}</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    gap: 5,
  },
  invitedByEmail: {
    fontSize: 13,
    fontWeight: '600',
    color: theme.colors.neutral.$2Base,
  },
  invitedByText: {
    fontSize: 13,
    fontWeight: '400',
    color: theme.colors.neutral.$4,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 7,
    alignItems: 'center',
  },
  textContainer: {
    flexDirection: 'row',
    gap: 5,
  },
});
