import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {useGetUserProfilePhoto} from './useGetUserProfilePhoto';

export const useProfilePhoto = () => {
  const {fetchUserProfilePhoto} = useGetUserProfilePhoto();

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const userDetails = useSelector(user.selectors.getUserDetails);
  const currentProfilePic = useSelector(user.selectors.getUserProfilePic);

  useEffect(() => {
    if (isLoggedIn && !currentProfilePic) {
      fetchUserProfilePhoto(userDetails.id);
    }
  }, [isLoggedIn]);
};
