import type {ImagesBase} from './base';

import rightArrow from 'assets/images/login/right-arrow.png';

import blackAppleLogo from 'assets/images/login/apple-logo-black.png';
import whiteAppleLogo from 'assets/images/login/apple-logo-white.png';
import blackEmailIcon from 'assets/images/login/black-email.png';
import facebookLogo from 'assets/images/login/facebook-logo.png';
import googleLogo from 'assets/images/login/google-logo.png';
import microsoftLogo from 'assets/images/login/microsoft-logo.png';
import twitterLogo from 'assets/images/login/twitter-logo.png';
import whiteEmailIcon from 'assets/images/login/white-email.png';

import history from 'assets/images/login/history.png';
import bluePlus from 'assets/images/onboarding/blue-plus.png';
import copyIcon from 'assets/images/onboarding/copy-icon.png';
import grayPlus from 'assets/images/onboarding/gray-plus.png';
import leftkArrow from 'assets/images/onboarding/left-arrow.png';
import successIcon from 'assets/images/onboarding/success-icon.png';
import userIcon from 'assets/images/onboarding/user-icon.png';

class Images implements ImagesBase {
  rightArrow = rightArrow;
  whiteAppleLogo = whiteAppleLogo;
  blackAppleLogo = blackAppleLogo;
  googleLogo = googleLogo;
  facebookLogo = facebookLogo;
  microsoftLogo = microsoftLogo;
  twitterLogo = twitterLogo;
  grayPlus = grayPlus;
  bluePlus = bluePlus;
  leftkArrow = leftkArrow;
  userIcon = userIcon;
  successIcon = successIcon;
  copyIcon = copyIcon;
  blackEmailIcon = blackEmailIcon;
  whiteEmailIcon = whiteEmailIcon;
  history = history;
}

export default new Images();
