import theme from 'config/theme';
import {Panel, PanelHandle} from 'interface/base/Panel';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {ColumnManagerInfoKeys} from 'store/slices/app/types';
import * as files from 'store/slices/files';

interface ColumnToggleProps {
  columnKey: ColumnManagerInfoKeys;
  itemsToClose?: number;
  children: React.ReactNode;
}

const COLUMN_ORDER = {
  [ColumnManagerInfoKeys.WorkspaceBrowser]: 1,
  [ColumnManagerInfoKeys.FoldersAndFiles]: 2,
  [ColumnManagerInfoKeys.InfoPanel]: 3,
  [ColumnManagerInfoKeys.ShareConfiguration]: 4,
  [ColumnManagerInfoKeys.AIChat]: 5,
};

const SIZE_DEFAULT = {
  [ColumnManagerInfoKeys.WorkspaceBrowser]: 15,
  [ColumnManagerInfoKeys.FoldersAndFiles]: undefined,
  [ColumnManagerInfoKeys.InfoPanel]: 20,
  [ColumnManagerInfoKeys.ShareConfiguration]: 0,
  [ColumnManagerInfoKeys.AIChat]: 25,
};

const SIZE_DEFAULT_SHARE = {
  [ColumnManagerInfoKeys.WorkspaceBrowser]: 10,
  [ColumnManagerInfoKeys.FoldersAndFiles]: undefined,
  [ColumnManagerInfoKeys.InfoPanel]: 15,
  [ColumnManagerInfoKeys.ShareConfiguration]: 20,
  [ColumnManagerInfoKeys.AIChat]: 25,
};

export function ColumnPanelToggle({columnKey, children}: ColumnToggleProps) {
  const focusedParentId = useSelector(files.selectors.getFocusedParent);
  const columnInfo = useSelector(app.selectors.columnInfo);
  const columnInfoOverride = useSelector(app.selectors.columnInfoOverride);
  const isShareContext = !!focusedParentId?.startsWith('5');

  if (
    !isShareContext &&
    columnKey === ColumnManagerInfoKeys.ShareConfiguration
  ) {
    return <></>;
  }

  const visibility = columnInfoOverride[columnKey] ?? columnInfo[columnKey];
  const columnOrder = COLUMN_ORDER[columnKey];
  const size = isShareContext
    ? SIZE_DEFAULT_SHARE[columnKey]
    : SIZE_DEFAULT[columnKey];

  return (
    !!visibility && (
      <Panel
        id={columnKey}
        order={columnOrder}
        minSize={15}
        defaultSize={size}
        flex>
        <View style={styles.root}>
          <View style={styles.container}>{children}</View>
        </View>
        {columnKey !== ColumnManagerInfoKeys.AIChat && <PanelHandle />}
      </Panel>
    )
  );
}

const styles = StyleSheet.create({
  root: {
    height: '100%',
    width: '100%',
  },
  container: {
    height: '100%',
    borderRadius: 6,
    backgroundColor: theme.colors.neutral.$white,
    margin: StyleSheet.hairlineWidth,
    flexDirection: 'column',
  },
});
