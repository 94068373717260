import theme from 'config/theme';
import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isSameYear,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import {Pressable, StyleSheet, Text, View} from 'react-native';

interface Props {
  showDate: Date;
  startDate?: Date;
  endDate?: Date;
  setDate?: (date: Date) => void;
  isStartDate?: boolean;
  isDateRange?: boolean;
}

const isSameDate = (date1: Date, date2: Date) => {
  return (
    isSameDay(date1, date2) &&
    isSameMonth(date1, date2) &&
    isSameYear(date1, date2)
  );
};

export function MonthDates(props: Props) {
  const today = new Date();

  const getWeekDaysNames = () => {
    const weekStartDate = startOfWeek(today);
    const weekDays = [];
    for (let day = 0; day < 7; day++) {
      weekDays.push(
        <Text style={styles.weekDaysName} key={day}>
          {format(addDays(weekStartDate, day), 'E')[0]}
        </Text>,
      );
    }
    return <View style={styles.weekDaysNamesContainer}>{weekDays}</View>;
  };

  const generateDatesForCurrentWeek = (
    date: Date,
    selectedDate: Date,
    activeDate: Date,
  ) => {
    let currentDate = date;
    const week = [];
    for (let day = 0; day < 7; day++) {
      const cloneDate = currentDate;

      const isToday = isSameDate(currentDate, today);
      const selected = selectedDate && isSameDate(currentDate, selectedDate);
      const outOfMonth = !isSameMonth(currentDate, props.showDate);

      week.push(
        <Pressable
          key={cloneDate.toString()}
          disabled={outOfMonth}
          onPress={() => props.setDate?.(cloneDate)}
          style={[
            styles.dayContainer,
            isToday && !selected && styles.todayContainer,
            selected && styles.selectedDayContainer,
          ]}>
          <Text
            style={[
              styles.day,
              outOfMonth && styles.inactiveDay,
              selected && styles.selectedDay,
              isToday && !selected && styles.today,
            ]}>
            {format(currentDate, 'd')}
          </Text>
        </Pressable>,
      );
      currentDate = addDays(currentDate, 1);
    }
    return week;
  };

  const generateDateRangeForCurrentWeek = (date: Date) => {
    let currentDate = date;

    const week = [];
    for (let day = 0; day < 7; day++) {
      const cloneDate = currentDate;

      const staticDate = props.isStartDate ? props.endDate : props.startDate;
      const dynamicDate = props.isStartDate ? props.startDate : props.endDate;
      const isStartDate = props.isStartDate;

      const isFixed = staticDate && isSameDate(currentDate, staticDate);
      const outOfMonth = !isSameMonth(currentDate, props.showDate);
      const after = props.startDate && isAfter(currentDate, props.startDate);
      const before = props.endDate && isBefore(currentDate, props.endDate);
      const selected = dynamicDate && isSameDate(currentDate, dynamicDate);
      const between = before && after && !isFixed;

      week.push(
        <Pressable
          onPress={() => props.setDate?.(cloneDate)}
          key={cloneDate.toString()}
          disabled={outOfMonth}
          style={[
            styles.dayContainer,
            isFixed &&
              !selected && {backgroundColor: theme.colors.neutral.$white},
            isFixed &&
              (isStartDate ? styles.selectedContainer : styles.todayContainer),
            selected &&
              (isStartDate ? styles.todayContainer : styles.selectedContainer),
            (isFixed || selected || between) && styles.between,
            day === 0 &&
              between && {borderTopLeftRadius: 7, borderBottomLeftRadius: 7},
            day === 6 &&
              between && {borderTopRightRadius: 7, borderBottomRightRadius: 7},
          ]}>
          <Text
            style={[
              styles.day,
              outOfMonth && styles.inactiveDay,
              selected && !isFixed && styles.selectedDay,
              isFixed && styles.today,
            ]}>
            {format(currentDate, 'd')}
          </Text>
        </Pressable>,
      );
      currentDate = addDays(currentDate, 1);
    }
    return week;
  };

  const getDates = () => {
    const startOfTheSelectedMonth = startOfMonth(props.showDate);
    const endOfTheSelectedMonth = endOfMonth(props.showDate);
    const startDate = startOfWeek(startOfTheSelectedMonth);
    const endDate = endOfWeek(endOfTheSelectedMonth);

    let currentDate = startDate;
    const allWeeks = [];

    while (currentDate <= endDate) {
      allWeeks.push(
        props.isDateRange
          ? generateDateRangeForCurrentWeek(currentDate)
          : generateDatesForCurrentWeek(currentDate, props.startDate, today),
      );
      currentDate = addDays(currentDate, 7);
    }

    return allWeeks;
  };

  return (
    <Text style={styles.root}>
      {getWeekDaysNames()}
      <View style={styles.weekContainer}>{getDates()}</View>
    </Text>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  weekDaysName: {
    width: 22,
    height: 20,
    color: theme.colors.neutral.$2Base,
    fontSize: 12,
    fontWeight: '600',
    lineHeight: 20,
    margin: '0.3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 'auto',
  },
  weekDaysNamesContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 24,
  },
  todayContainer: {
    borderRadius: 50,
  },
  selectedDayContainer: {
    backgroundColor: theme.colors.brand.$4Base,
    borderRadius: 50,
  },
  selectedContainer: {
    borderBottomRightRadius: 50,
    borderTopRightRadius: 50,
  },
  dayContainer: {
    marginVertical: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 34,
  },
  between: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 34,
    backgroundColor: theme.colors.brand.$7,
  },
  day: {
    height: 28,
    width: 28,
    paddingVertical: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
  inactiveDay: {
    color: theme.colors.neutral.$5,
  },
  selectedDay: {
    color: theme.colors.neutral.$white,
  },
  today: {
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: theme.colors.brand.$4Base,
    color: theme.colors.neutral.$2Base,
  },
  weekContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingBottom: 8,
  },
});
