import theme from 'config/theme';
import ActionButton, {
  type ActionButtonProps,
} from 'interface/stacks/content-viewer/components/ActionButton';
import React, {forwardRef} from 'react';
import {type StyleProp, StyleSheet, type ViewStyle} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import type {ButtonType} from './Button';

interface Props extends ActionButtonProps {
  iconName: string;
  iconStyle?: StyleProp<ViewStyle>;
  type?: ButtonType;
}

const typeToColor = {
  Primary: theme.colors.brand.$4Base,
  Secondary: theme.colors.neutral.$4,
  Danger: theme.colors.danger.$4Base,
};

const CircleButton = forwardRef<any, Props>(
  ({iconName, iconStyle, type = 'Primary', ...rest}, ref) => {
    return (
      <ActionButton
        showBorder
        kind={'dark'}
        tooltipPos={'bottom'}
        icon={
          <MCIcon
            name={iconName}
            size={20}
            color={typeToColor[type]}
            /* @ts-ignore */
            style={iconStyle}
          />
        }
        buttonStyle={styles.button}
        ref={ref}
        {...rest}
      />
    );
  },
);

const styles = StyleSheet.create({
  button: {
    borderColor: theme.colors.neutral.$10,
    borderRadius: 50,
  },
});

export default CircleButton;
