import {useFlag} from '@unleash/proxy-client-react';
import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import {usePopup} from 'extensions/viewport/usePopup';
import {Transfers} from 'fast-sdk';
import {PanelGroup} from 'interface/base/Panel';
import {AppMemberCursor} from 'interface/stacks/app/AppMemberCursor';
import {WorkspaceAiChat} from 'interface/stacks/workspace/layout/WorkspaceAiChat';
import {useEffect, useMemo, useRef} from 'react';
import {StyleSheet, View, useWindowDimensions} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet, useParams} from 'react-router';
import app from 'store/slices/app';
import {
  ColumnManagerInfoKeys,
  DEFAULT_PAGE_INFO,
  PageType,
} from 'store/slices/app/types';
import {selectors} from 'store/slices/files';
import {load} from 'utils/fast/storage';
import {WorkspaceFrame} from '../base/WorkspaceFrame';
import useColumnManagerHandler from '../hooks/useColumnManagerHandler';
import {SharedCustomizeDialog} from '../shared/dialogs/SharedCustomizeDialog';
import {ColumnPanelToggle} from '../storage/base/ColumnPanelToggle';
import {StorageHeader} from '../storage/base/StorageHeader';
import {useContextMenu} from '../storage/hooks/useContextMenu';
import {useDragDropTarget} from '../storage/hooks/useDragDropTarget';
import {useModalCreateFolder} from '../storage/hooks/useModalCreateFolder';
import {useWorkspaceContext} from '../storage/hooks/useWorkspaceContext';
import {WorkspaceInfoPanel} from './WorkspaceInfoPanel';
import WorkspaceShareConfig from './WorkspaceShareConfig';
import {WorkspaceSideNav} from './WorkspaceSideNav';

export const LayoutWorkspaceContent = () => {
  const {folderKey} = useParams<'folderKey' | 'workspaceId'>();
  const workspaceContext = useWorkspaceContext();
  const {workspace, uploader, multiplayer, hasFixedMenu} = workspaceContext;

  const folderId = folderKey ?? 'root';

  const ref = useRef<View>(null);
  const modal = useModal();
  const popup = usePopup();
  const screen = useWindowDimensions();
  const hasCursors = useFlag('multiplayer-cursors');
  const focusedParentId = useSelector(selectors.getFocusedParent);
  const focusedId = useSelector(selectors.getFocused);
  const localItems = useSelector(selectors.getItems);
  useColumnManagerHandler();

  const isMinimal = screen.width < 600;

  const dispatch = useDispatch();

  const addFilesFromLocal = async () => {
    const files = await Transfers.pickFiles();
    uploader.instance.addFiles(files, folderId, workspace.id);
  };

  const openCreateShareDialog = () => {
    modal.open(
      <SharedCustomizeDialog
        workspaceFolderName={workspace.folder_name}
        folderParentId={folderId}
        close={modal.close}
      />,
    );
  };

  const {isDropping} = useDragDropTarget({
    ref,
    targetNs: 'workspace',
    targetId: folderId,
    targetName: workspace.name,
    instanceNs: 'workspace',
    instanceId: workspace.id,
    uploader,
    options: {
      isUploadOnly: true,
    },
  });

  const modalCreateFolder = useModalCreateFolder(
    folderId,
    workspace.folder_name,
    'workspace',
  );

  const menuFiles = useContextMenu({
    noDividers: true,
    ignoreMultiSelect: true,
    headerText: localItems[folderId]?.name ?? workspace.name,
    uploadHereOnPress: addFilesFromLocal,
    createShareOnPress: openCreateShareDialog,
    createFolderOnPress: modalCreateFolder.open,
  });

  const workspaceInfoPanel = useMemo(() => {
    return (
      <WorkspaceInfoPanel
        focusedParentId={focusedParentId}
        focusedId={focusedId}
        workspaceId={workspace.id}
      />
    );
  }, [focusedParentId, focusedId, workspace.id]);

  const workspaceAiChat = useMemo(() => {
    return <WorkspaceAiChat workspaceId={workspace.id} />;
  }, [workspace.id]);

  // Load folder contents when workspace or folder changes
  useEffect(() => {
    load(dispatch, folderId, workspace.folder_name, 'workspace');
    dispatch(
      app.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.Storage,
        folderId: folderId,
        workspace: workspace.folder_name,
      }),
    );
  }, [folderId, workspace.folder_name]);

  return (
    <View ref={ref} style={styles.root}>
      <View style={[styles.flex, isMinimal && styles.minimal]}>
        <StorageHeader
          folderId={folderId}
          workspaceId={workspace.folder_name}
          workspaceName={workspace.name}
          members={multiplayer?.members}
          onFolderPress={e => {
            e.preventDefault();
            e.stopPropagation();
            if (popup) {
              if ('nativeEvent' in e)
                popup.setPosition({
                  x: e.nativeEvent.pageX,
                  y: e.nativeEvent.pageY,
                });
            }
            menuFiles.open();
          }}
        />
        <View style={styles.content}>
          <PanelGroup direction="horizontal">
            <ColumnPanelToggle
              columnKey={ColumnManagerInfoKeys.WorkspaceBrowser}>
              <WorkspaceSideNav
                workspace={workspace}
                selectedFolderId={folderId}
              />
            </ColumnPanelToggle>
            <ColumnPanelToggle
              columnKey={ColumnManagerInfoKeys.FoldersAndFiles}>
              <Outlet context={workspaceContext} />
            </ColumnPanelToggle>
            <ColumnPanelToggle columnKey={ColumnManagerInfoKeys.InfoPanel}>
              {workspaceInfoPanel}
            </ColumnPanelToggle>
            <ColumnPanelToggle
              columnKey={ColumnManagerInfoKeys.ShareConfiguration}>
              <WorkspaceShareConfig />
            </ColumnPanelToggle>
            <ColumnPanelToggle columnKey={ColumnManagerInfoKeys.AIChat}>
              {workspaceAiChat}
            </ColumnPanelToggle>
          </PanelGroup>
        </View>
      </View>
      {hasCursors &&
        multiplayer?.cursors?.map(cursor => (
          <AppMemberCursor
            key={cursor.id}
            hasMenu={hasFixedMenu}
            localScrollX={multiplayer?.self?.scrollX}
            localScrollY={multiplayer?.self?.scrollY}
            {...cursor}
          />
        ))}
      {isDropping && (
        <WorkspaceFrame mode="upload" color={theme.colors.brand.$4Base} />
      )}
    </View>
  );
};

export const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: theme.colors.neutral.$100,
    padding: 3,
  },
  minimal: {
    marginHorizontal: 0,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
  },
  flex: {
    flex: 1,
  },
});
