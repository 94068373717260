import {StyleSheet, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import RecentEmpty from '../components/RecentEmpty';
import RecentSection from './RecentSection';
type Props = {
  files: {
    today: {[id: string]: FilesItem};
    yesterday: {[id: string]: FilesItem};
    lastMonth: {[id: string]: FilesItem};
  };
};

const RecentList = ({files}: Props) => {
  const {today, yesterday, lastMonth} = files;

  if (
    Object.keys(today).length === 0 &&
    Object.keys(yesterday).length === 0 &&
    Object.keys(lastMonth).length === 0
  ) {
    return <RecentEmpty />;
  }

  return (
    <View style={styles.root}>
      <RecentSection title="Today" items={today} />
      <RecentSection title="Yesterday" items={yesterday} />
      <RecentSection title="Earlier this month" items={lastMonth} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    zIndex: -1,
    // Total viewport height - Header bar height - toolbar height - filters height
    maxHeight: 'calc(100vh - 69px - 66px - 66px)',
  },
});

export default RecentList;
