import {api} from 'fast-sdk';
import {useDispatch} from 'react-redux';
import * as user from 'store/slices/user';

export const useGetUserDetails = () => {
  const dispatch = useDispatch();

  const fetchUserDetails = async () => {
    const {result, user: userDetails} = await api.user.userDetails();
    if (result) {
      dispatch(user.default.actions.setUserDetails(userDetails));
    }
  };

  return {fetchUserDetails};
};
