import {Main} from 'interface/stacks/workspace/inbox/Main';

export function RouteInbox() {
  return (
    <>
      <Main />
    </>
  );
}

export default RouteInbox;
