import {createSelector} from '@reduxjs/toolkit';
import {NEW_CHAT} from 'interface/stacks/workspace/layout/WorkspaceAiChat/consts';
import type {Store} from 'store';

const x = (store: Store) => store;

export const getChats = (workspaceId: string) =>
  createSelector(x, store =>
    Object.entries(store.aichat?.chats ?? {}).filter(
      ([_, val]) => val.workspaceId === workspaceId,
    ),
  );

export const getChat = (chatId: string) =>
  createSelector(x, store => store.aichat?.chats[chatId]);

export const getCurrentChat = (workspaceId: string) =>
  createSelector(x, store => {
    const currentChatId = store.aichat?.currentChat;
    if (currentChatId === NEW_CHAT) {
      return NEW_CHAT;
    }
    const chat = store.aichat?.chats[currentChatId];
    if (chat?.workspaceId === workspaceId) {
      return currentChatId;
    }
    return null;
  });
