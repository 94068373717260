import {api} from 'fast-sdk';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useCallback, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import * as workspaceSlice from 'store/slices/workspace';

export default function useArchiveWorkspace() {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const archiveWorkspace = useCallback(
    async (workspace: WorkspaceListDetail) => {
      setLoading(true);
      try {
        const {result} = await api.workspace.archiveWorkspace(
          workspace.folder_name,
        );
        if (result) {
          toast.show(
            `You've successfully archived the "${workspace.name}" workspace.`,
            {
              type: 'neutral',
            },
          );
          dispatch(workspaceSlice.default.actions.archiveWorkspace(workspace));
        }
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {archiveWorkspace, loading};
}
