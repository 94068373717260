import {t} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {api} from 'fast-sdk';
import {Button} from 'interface/common/Button';
import {FormError} from 'interface/common/FormError';
import {Loading} from 'interface/common/Loading';
import {useProccessPendingInvitation} from 'interface/common/hooks/useProccessPendingInvitation';
import {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {Text} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {RouteLayout} from '../_layout/RouteLayout';
import OTPInput from '../components/OTPInput';
import Auth from '../consts';

export function SignUpVerification() {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>();
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const invitation = useSelector(app.selectors.getCurrentJoinInvitation);

  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {handleInvitationFlow} = useProccessPendingInvitation();

  useEffect(() => {
    async function getEmail() {
      const email = await Auth.getUserEmail();
      setEmail(email);
    }

    getEmail();
  }, []);

  useEffect(() => {
    if (email) {
      sendVerification();
    }
  }, [email]);

  const validateCode = async (code: string) => {
    if (!email) {
      return setErrorMessage(
        'There is a problem with your email please try again',
      );
    }

    if (code) {
      setPageLoading(true);
      const validateResponse = await api.user.validateEmail({
        email_token: code,
        email,
      });

      if (!validateResponse.result) {
        setPageLoading(false);
        return setErrorMessage('Invalid code');
      }

      toast.show('Your email validation was successful', {
        type: 'success',
      });
      const userResponse = await api.user.userDetails();

      if (userResponse.result) {
        dispatch(user.default.actions.setUserDetails({...userResponse.user}));

        if (invitation) {
          return handleInvitationFlow(userResponse.user.email_address);
        }

        return navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}`);
      }

      setPageLoading(false);
    }
  };

  const sendVerification = async () => {
    setErrorMessage('');
    setLoading(true);

    try {
      const {result, error} = await api.user.sendValidationEmail(email);

      if (result) {
        toast.show(`A verification link was sent to ${email}`, {
          type: 'success',
        });
      } else {
        setErrorMessage(error.text);
      }
    } finally {
      setLoading(false);
    }
  };

  const onStartTyping = () => {
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  return (
    <RouteLayout
      title="Check your email for a code"
      subTitle={`A code was sent to ${email}. If you don't see the email, check your spam folder. This code is only valid for 10 minutes.`}
      customContentContainerStyle={{marginBottom: '3rem'}}>
      {loading ? (
        <Loading size="large" customColor={theme.colors.brand.$4Base} />
      ) : (
        <>
          {errorMessage ? <FormError errorMessage={errorMessage} /> : null}
          <View style={styles.fullWidth}>
            <OTPInput
              validate={validateCode}
              disabled={pageLoading}
              onStartTyping={onStartTyping}
            />
            {pageLoading ? (
              <View style={styles.loadingContainer}>
                <Loading size="small" />
                <Text style={styles.loadingText}>Verifying code…</Text>
              </View>
            ) : (
              <View style={styles.bottom}>
                <Button
                  type="Text"
                  label={t`Resend verification email`}
                  onPress={() => sendVerification()}
                  customRootStyle={{paddingVertical: '1rem'}}
                />
                <Button
                  type="Text"
                  label={t`Try a different email`}
                  onPress={() => {
                    dispatch(user.default.actions.deleteUserDetails());
                    Auth.setAuthToken('');
                  }}
                  customRootStyle={{paddingVertical: '1rem'}}
                />
              </View>
            )}
          </View>
        </>
      )}
    </RouteLayout>
  );
}

const styles = StyleSheet.create({
  input: {
    width: '100%',
    height: 48,
    padding: 10,
    marginBottom: '1.5rem',
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
  fullWidth: {
    width: '100%',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    height: 48,
    borderRadius: 5,
    marginBottom: '1.5rem',
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
  errorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    backgroundColor: theme.colors.danger.$8,
    borderWidth: 2,
    borderColor: theme.colors.danger.$4Base,
    borderRadius: 5,
    marginBottom: '1.5rem',
  },
  errorMessage: {
    color: theme.colors.danger.$e2,
    textAlign: 'center',
    width: '100%',
  },
  bottom: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 42,
  },
  loadingContainer: {
    flexDirection: 'row',
    gap: 10,
    height: 24,
    marginTop: 21,
  },
  loadingText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$5,
  },
});
