import fonts from 'config/fonts';
import type {TextStyle} from 'react-native';

export enum sizes {
  xxs = 10,
  xs = 11,
  s = 12,
  m = 14,
  l = 16,
  xl = 20,
  xxl = 32,
}

export const monospace: TextStyle = {
  fontFamily: fonts.monospace,
};

export const light: TextStyle = {
  fontFamily: fonts.light,
  fontWeight: '300',
};

export const regular: TextStyle = {
  fontFamily: fonts.regular,
  fontWeight: '400',
};

export const semibold: TextStyle = {
  fontFamily: fonts.semibold,
  fontWeight: '600',
};

export const bold: TextStyle = {
  fontFamily: fonts.bold,
  fontWeight: '700',
};
