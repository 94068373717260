import {useParams} from 'extensions/navigation';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {ActivityTypes} from 'fast-sdk/src/websockets/types';
import {useGetWorkspaceDetails} from 'interface/stacks/workspace/hooks/useGetWorkspaceDetails';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as activity from 'store/slices/activity';
import {EntityType} from 'store/slices/activity/types';
import {selectors as workspaceSelectors} from 'store/slices/workspace';
import {getCurrentUTCDateTime} from 'utils/common/dates';
import {shouldUpdateCache} from '../utils';
import useItemChangeDetector from './useItemChangeDetector';
import useSyncStorageData from './useSyncStorageData';

interface UseSyncWorkspaceDataOptions {
  workspace: WorkspaceListDetail;
}

const useSyncWorkspaceData = ({workspace}: UseSyncWorkspaceDataOptions) => {
  const {folderKey} = useParams<'folderKey'>();

  const dispatch = useDispatch();

  const workspaceActivities =
    useSelector(activity.selectors.getWorkspaceActivities(workspace?.id))
      ?.activities ?? {};

  const workspaceDetailsActivity = workspaceActivities[ActivityTypes.DETAILS];

  const {fetchWorkspaceDetails} = useGetWorkspaceDetails();

  const updateEntityLastClientUpdate = useCallback(
    (workspaceId: string, activityType: string, fileId?: string) => {
      const lastClientUpdate = getCurrentUTCDateTime();
      dispatch(
        activity.default.actions.setEntitiesLastClientUpdate({
          entities: [workspaceId],
          activities: [fileId ? `${activityType}:${fileId}` : activityType],
          entityType: EntityType.Workspace,
          lastClientUpdate,
        }),
      );
    },
    [],
  );

  const onWorkspaceChange = useCallback(workspace => {
    if (workspace.id) {
      updateEntityLastClientUpdate(workspace.id, ActivityTypes.DETAILS);
    }
  }, []);

  useItemChangeDetector(
    workspaceSelectors.getUserWorkspaces,
    onWorkspaceChange,
  );

  useEffect(() => {
    if (workspaceDetailsActivity) {
      const {lastServerUpdate, lastClientUpdate} = workspaceDetailsActivity;
      if (shouldUpdateCache(lastServerUpdate, lastClientUpdate)) {
        fetchWorkspaceDetails(workspace.id);
      }
    }
  }, [workspaceDetailsActivity]);

  useSyncStorageData({
    namespace: 'workspace',
    profileId: workspace?.id,
    profileName: workspace?.folder_name,
    folderKey,
    activities: workspaceActivities,
  });
};

export default useSyncWorkspaceData;
