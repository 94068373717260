import theme from 'config/theme';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import {Button} from 'interface/common/Button';
import FileThumbnail from 'interface/stacks/workspace/storage/thumbnail/FileThumbnail';
import {StyleSheet, Text, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import {bytesize} from 'utils/common/data';
import useFileMenu from '../hooks/useFileMenu';
import Pagination from './Pagination';
import ActionIcon from './top-toolbar/ActionButtons/ActionIcon';

interface Props {
  file: FilesItem;
}

export const FILES_DESCRIPTIONS = {
  ZIP: 'Compressed archive',
  NONE: 'No preview available',
};

const NotSupported = ({file}: Props) => {
  const {downloadItem} = useFileMenu(file);

  const fileType = file.file_attributes?.exif_metadata?.FileType;

  const description = FILES_DESCRIPTIONS[fileType] ?? FILES_DESCRIPTIONS.NONE;
  const sizeLabel = bytesize(file.size);

  return (
    <View style={styles.root}>
      <View style={styles.downloadContainer}>
        <View style={styles.thumbnail}>
          <FileThumbnail
            item={file}
            size={FileThumbnailSizes.Preview}
            options={{previewResizeMode: 'center'}}
          />
        </View>
        <Text style={styles.text}>{file.name}</Text>
        <Text style={styles.textDescription}>
          {description} {sizeLabel}
        </Text>
        <Button
          type="Primary"
          label="Download"
          onPress={downloadItem}
          lIcon={<ActionIcon name="tray-arrow-down" />}
          customRootStyle={{width: 200}}
        />
      </View>
      <Pagination />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.$black,
  },
  text: {
    fontSize: 18,
    fontWeight: '600',
    color: theme.colors.neutral.$white,
  },
  textDescription: {
    fontSize: 13,
    color: theme.colors.neutral.$6,
    marginTop: 4,
    marginBottom: 30,
  },
  downloadContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 490,
    paddingHorizontal: 30,
    paddingVertical: 45,
    backgroundColor: theme.colors.neutral.$1,
    borderRadius: 16,
  },
  thumbnail: {
    width: 80,
    height: 80,
  },
});

export default NotSupported;
