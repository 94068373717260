import theme from 'config/theme';

export const DEFAULT_PADDING = {top: 50, bottom: 30, left: 50, right: 20};
export const DEFAULT_TOOLTIP_STYLE = {
  fontFamily: 'sans-serif',
  fontSize: 12,
  fill: theme.colors.neutral.$700,
};
export const DEFAULT_BAR_STYLE = {
  data: {
    fill: theme.colors.brand.$4,
    width: 24,
  },
};
export const DEFAULT_TOOLTIP_FLYOUT_STYLE = {
  stroke: 'none',
  fill: theme.colors.neutral.$75,
};
