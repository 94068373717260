import {useModal} from 'extensions/viewport/useModal';
import {usePopup} from 'extensions/viewport/usePopup';
import {Transfers} from 'fast-sdk';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useCallback, useRef} from 'react';
import type {MouseEvent} from 'react-native';
import {SharedCustomizeDialog} from '../../../shared/dialogs/SharedCustomizeDialog';
import {useContextMenu} from '../../../storage/hooks/useContextMenu';
import {useModalCreateFolder} from '../../../storage/hooks/useModalCreateFolder';
import {useWorkspaceContext} from '../../../storage/hooks/useWorkspaceContext';
import type {IFolderItem} from '../types';

interface FolderActionsOptions {
  item: IFolderItem;
  workspace: WorkspaceListDetail;
}

export function useFolderActions({item, workspace}: FolderActionsOptions) {
  const modal = useModal();
  const popup = usePopup();
  const menuRef = useRef<any>();

  const {uploader} = useWorkspaceContext();

  const addFilesFromLocal = async () => {
    const files = await Transfers.pickFiles();
    uploader.instance.addFiles(files, item.id, workspace.id);
  };

  const openCreateShareDialog = () => {
    modal.open(
      <SharedCustomizeDialog
        workspaceFolderName={workspace.folder_name}
        folderParentId={item.id}
        close={modal.close}
      />,
    );
  };

  const modalCreateFolder = useModalCreateFolder(
    item.id,
    workspace.folder_name,
    'workspace',
  );

  const menuFiles = useContextMenu({
    noDividers: true,
    ignoreMultiSelect: true,
    headerText: item.name,
    uploadHereOnPress: addFilesFromLocal,
    createShareOnPress: openCreateShareDialog,
    createFolderOnPress: modalCreateFolder.open,
  });

  const openContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (event?.nativeEvent) {
        popup.setPlacement('bottom-start');
        popup.setPosition({
          x: event.nativeEvent.pageX,
          y: event.nativeEvent.pageY,
        });
        menuFiles.open();
      }
    },
    [popup, menuFiles],
  );

  return {openContextMenu, menuRef};
}
