import {ROUTES} from 'constants/routes';
import {routesGroups} from 'interface/common/hooks/navigator/useNavigatorRedirection';

export const SESSION_VALIDATION_EXEMPT_ROUTES = [
  ...routesGroups.notLoggedInRoutes,
  ROUTES.LOGGED_IN_WITH_SUBDOMAIN.FROM_SUBDOMAIN,
];

export const ROUTES_EXCLUDED_FROM_ORGANIZATION_LOADING = [
  ...routesGroups.loggedInWithOrgRoutes,
  ROUTES.LOGGED_IN_WITHOUT_ORG.HOME,
];

export const ROUTES_EXEMPT_FROM_USER_DETAILS_VALIDATION = [
  ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION,
];

export const LOGOUT_EVENT_KEY = 'app_logout_event';
export const LOGIN_EVENT_KEY = 'app_login_event';
