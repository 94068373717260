import {t} from '@lingui/macro';
import theme from 'config/theme';
import {UploadButton} from 'interface/stacks/uploads/base/UploadButton';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import IconC from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {bytesize} from 'utils/common/data';

import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';

interface UploadsBarProps extends UploaderState {
  showAll: boolean;
  maximized: boolean;
  setShowAll: (showAll: boolean) => void;
  setMaximized: (maximized: boolean) => void;
}

export function UploadsBar(props: UploadsBarProps) {
  return (
    <Pressable
      style={styles.root}
      onPress={() => props.setMaximized(!props.maximized)}>
      <View style={styles.info}>
        <Text style={styles.title}>{t`Uploads`}</Text>
        {props.active && (
          <>
            <Text style={styles.muted}>
              {`${bytesize(props.bytes)} / ${bytesize(props.size)}`}
            </Text>
            {/* <Text style={styles.muted}>
              {props.eta ? new Date(props.eta).toISOString().slice(14, 19) : ''}
            </Text> */}
          </>
        )}
      </View>
      <View style={styles.actions}>
        {/* <UploadButton accessibilityLabel={t`Filter Uploads`}>
          <Icon name="filter-list" size={20} color={theme.colors.neutral.$8} />
        </UploadButton> */}
        <UploadButton
          accessibilityLabel={
            props.maximized ? t`Minimize Uploads` : t`Maximize Uploads`
          }
          onPress={() => props.setMaximized(!props.maximized)}>
          {props.maximized ? (
            <IconC
              name="chevron-down"
              size={20}
              color={theme.colors.neutral.$8}
            />
          ) : (
            <IconC
              name="chevron-up"
              size={20}
              color={theme.colors.neutral.$8}
            />
          )}
        </UploadButton>
        <UploadButton
          accessibilityLabel={t`Close Uploads`}
          onPress={() => props.cancel()}>
          <Icon name="close" size={20} color={theme.colors.neutral.$8} />
        </UploadButton>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 6,
    paddingRight: 2,
    cursor: 'default',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  info: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
  actions: {
    flexDirection: 'row',
    marginRight: 6,
  },
  button: {
    padding: 0,
  },
  title: {
    fontSize: 14,
    lineHeight: 22,
    marginHorizontal: 12,
    fontWeight: '600',
    color: theme.colors.neutral.$14,
  },
  muted: {
    fontSize: 11,
    color: theme.colors.neutral.$8,
  },
});
