import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import React from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';
import ShareTypeCell from './ShareTypeCell';
import ShareWorkspace from './ShareWorkspace';

interface ShareTableRowProps {
  share: Share;
  onPress: () => void;
}

const ShareTableRow = ({share, onPress}: ShareTableRowProps) => (
  <Pressable style={styles.row} onPress={onPress}>
    <View style={styles.cell}>
      <Typography size="sm" color={theme.colors.neutral.$2Base}>
        {share.name}
      </Typography>
    </View>
    <View style={styles.cell}>
      <ShareTypeCell shareType={share.share_type} />
    </View>
    <View style={styles.cell}>
      <ShareWorkspace workspaceId={share.parent_workspace} />
    </View>
    <View style={styles.cell}>
      <Typography size="sm" color={theme.colors.neutral.$2Base}>
        {share.created}
      </Typography>
    </View>
    <View style={[styles.cell, styles.actionCell]}>
      <Button
        overrides={{
          Button: {
            style: styles.buttonMenu,
          },
        }}
        aria-label="Open Share Menu"
        variant="text"
        onPress={() => {}}
        endEnhancer={
          <Icon name={'lucide:ellipsis'} color={theme.colors.neutral.$2Base} />
        }
      />
    </View>
  </Pressable>
);

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$10,
  },
  cell: {
    flex: 4,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  actionCell: {
    flex: 1,
  },
  buttonMenu: {
    width: 40,
    height: 40,
  },
});

export default ShareTableRow;
