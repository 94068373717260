import AppHeaderAccountDropdown from 'interface/stacks/app/layout/AppHeaderAccountDropdown';

type Props = {
  profilePicture?: string;
  fullname?: string;
  kind?: 'light' | 'dark';
};

const UserDropdown = ({profilePicture, fullname, kind}: Props) => {
  return (
    <AppHeaderAccountDropdown
      profilePicture={profilePicture}
      fullname={fullname}
      size="mini"
      kind={kind}
    />
  );
};

export default UserDropdown;
