import theme from 'config/theme';
import type React from 'react';
import {StyleSheet, Text, View} from 'react-native';

type Props = {
  label: string;
  color?: string;
  textColor?: string;
  icon?: React.ReactNode;
};

const Chip = ({
  label,
  color = theme.colors.neutral.$11,
  textColor = theme.colors.neutral.$2Base,
  icon,
}: Props) => {
  return (
    <View style={[styles.root, {backgroundColor: color}]}>
      {icon && icon}
      <Text style={[styles.label, {color: textColor}]}>{label}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingVertical: 1,
    paddingHorizontal: 8,
    borderRadius: 99,
    backgroundColor: theme.colors.neutral.$11,
    width: 'fit-content',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  },
  label: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
    textTransform: 'capitalize',
  },
});

export default Chip;
