import theme from 'config/theme';
import {CommentTextInput} from 'interface/stacks/workspace/storage/CommentTextInput';
import {FileComment} from 'interface/stacks/workspace/storage/FileComment';

import type {ReactNode} from 'react';
import {useState} from 'react';
import {Text} from 'react-native';
import {StyleSheet, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';

interface Props {
  item: FilesItem;
  thumbnail: ReactNode;
}

export function PreviewSidePanelComments(props: Props) {
  const [isReply, setIsReply] = useState<boolean>(false);
  const [comment, setComment] = useState<string>();

  return (
    <View style={styles.root}>
      <View style={styles.commentsHeader}>
        <Text style={styles.commentsNumber}>
          {props.item.comments?.length} Comments
        </Text>
      </View>
      <CommentTextInput
        setInputValue={(inp: string) => setComment(inp)}
        reply={isReply}
        setReplyFalse={() => setIsReply(false)}
      />
      <View style={[styles.comments, {maxHeight: isReply ? '60%' : '70%'}]}>
        {props.item.comments?.map((comment, idx) => (
          <FileComment
            key={comment.content + comment.sender.email + idx}
            comment={comment}
            setReply={(inp: boolean) => setIsReply(inp)}
          />
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
    paddingTop: 20,
  },
  fileIcon: {
    marginRight: 10,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 15,
  },
  headerName: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  commentsHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  commentsNumber: {
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
  comments: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
});
