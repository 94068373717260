import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {format} from 'date-fns';
import {formatNumber} from 'fast-sdk/src/utils';
import {Button} from 'interface/base/Button';
import Typography from 'interface/base/Typography';
import Skeleton from 'interface/common/Skeleton';
import {StyleSheet, View} from 'react-native';
import {BillingInfoItem} from './BillingInfoItem';

interface BillingInfoProps {
  planType: string;
  periodEnd: Date;
  periodStart: Date;
  formattedPricePerUser: string;
  billableUsers: number;
  freeUsers: number;
  freeCredits: number;
  creditsConsumed: number;
  onUserListPress: () => void;
  loading: boolean;
}

export default function BillingInfo({
  periodEnd,
  periodStart,
  planType,
  formattedPricePerUser,
  billableUsers,
  freeUsers,
  freeCredits,
  creditsConsumed,
  onUserListPress,
  loading,
}: BillingInfoProps) {
  const linearGradientColors = ['#F0F6FF', '#EBEBFF'];

  return (
    <View
      style={[
        styles.root,
        {
          // @ts-expect-error
          backgroundImage: `linear-gradient(to right, ${linearGradientColors.join(', ')})`,
        },
      ]}>
      <View style={[styles.billingInfo, styles.billingInfoRightBorder]}>
        <BillingInfoItem
          title={t`${planType} Plan`}
          content={t`${formattedPricePerUser} per user / mo`}
          loadingTitle={loading}
          loadingContent={loading}>
          {loading ? (
            <Skeleton height={18} width={100} />
          ) : (
            <Typography
              variant="medium"
              size="xs"
              color={theme.colors.neutral.$400}>
              <Trans>Minimum of {freeUsers} users</Trans>
            </Typography>
          )}
          {loading ? (
            <Skeleton height={18} width={120} />
          ) : (
            <Typography
              variant="medium"
              size="xs"
              color={theme.colors.neutral.$400}>
              <Trans>Included credits ({formatNumber(freeCredits)})</Trans>
            </Typography>
          )}
        </BillingInfoItem>
      </View>
      <View style={[styles.billingInfo, styles.billingInfoRightBorder]}>
        <BillingInfoItem
          title={t`Total Billable Users`}
          content={`${billableUsers}`}
          loadingContent={loading}>
          <Button
            variant="secondary"
            size="md"
            overrides={{
              Button: {
                style: styles.userListButton,
              },
            }}
            onPress={onUserListPress}>
            <Trans>User List</Trans>
          </Button>
        </BillingInfoItem>
      </View>
      <View style={[styles.billingInfo, styles.billingInfoRightBorder]}>
        <BillingInfoItem
          title={t`Consumed Credits`}
          content={creditsConsumed ? formatNumber(creditsConsumed) : ''}
          loadingContent={loading}>
          {loading ? (
            <>
              <Skeleton height={18} width={120} />
              <Skeleton height={18} width={100} />
            </>
          ) : (
            <Typography
              variant="medium"
              size="xs"
              color={theme.colors.neutral.$400}>
              <Trans>
                Credits consumed for billing period starting{' '}
                {periodStart ? format(periodStart, 'LLLL do, yyyy') : ''}
              </Trans>
            </Typography>
          )}
        </BillingInfoItem>
      </View>
      <View style={styles.billingInfo}>
        <BillingInfoItem
          title={t`Next Billing Date`}
          content={periodEnd ? format(periodEnd, 'LLLL d, yyyy') : ''}
          loadingContent={loading}>
          {/* <Button
            variant="secondary"
            size="md"
            overrides={{
              Button: {
                style: styles.userListButton,
              },
            }}>
            <Trans>Upcoming Invoice</Trans>
          </Button> */}
        </BillingInfoItem>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 12,
    marginBottom: '2.5rem',
  },
  billingInfo: {
    paddingTop: 16,
    paddingBottom: 24,
    paddingHorizontal: 24,
    flexBasis: '25%',
  },
  billingInfoRightBorder: {
    borderRightWidth: 1,
    borderRightColor: theme.colors.neutral.$11,
  },
  userListButton: {
    marginTop: 8,
    alignSelf: 'flex-start',
    backgroundColor: '#0000000D',
  },
});
