import theme from 'config/theme';
import ActivityTimeline from 'interface/base/ActivityTimeline';
import Typography from 'interface/base/Typography';
import {PreviewSidePanelComments} from 'interface/stacks/workspace/layout/WorkspaceInfoPanel/PreviewSidePanelComments';
import {useMemo} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {selectors} from 'store/slices/files';
import PreviewFileThumbnail from '../../storage/thumbnail/PreviewFileThumbnail';
import {InfoPanelDetailsTab} from './components/InfoPanelDetailsTab';
import {InfoPanelHeader} from './components/InfoPanelHeader';
import {InfoPanelTabHeader} from './components/InfoPanelTabHeader';
import {InfoPanelVersionsTabMocked} from './components/InfoPanelVersionsTabMocked';
import {WorkspaceInfoPanelTab} from './types/WorkspaceInfoPanelTab';

export interface WorkspaceInfoPanelProps {
  focusedParentId: string;
  focusedId: string;
  workspaceId: string;
}

export function WorkspaceInfoPanel(props: WorkspaceInfoPanelProps) {
  const {focusedParentId, focusedId, workspaceId} = props;
  const focusedType = useSelector(selectors.getFocusedType);

  const item = useSelector(selectors.getItem(focusedId));
  const isFile = item?.type === 'file';

  const thumbnail = useMemo(
    () => <PreviewFileThumbnail item={item} />,
    [item?.id],
  );

  const activityTimeline = useMemo(
    () => (
      <ActivityTimeline
        workspaceId={workspaceId}
        parentId={focusedParentId}
        selectedId={focusedId}
      />
    ),
    [focusedParentId, focusedId, workspaceId],
  );

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View style={styles.root}>
        {!item && (
          <InfoPanelHeader
            focusedParentId={focusedParentId}
            workspaceId={workspaceId}
          />
        )}
        {item && !isFile && (
          <InfoPanelHeader
            focusedParentId={focusedId}
            workspaceId={workspaceId}
          />
        )}
        {item && isFile && thumbnail}
        <InfoPanelTabHeader />
        {focusedType === WorkspaceInfoPanelTab.FileInfo && (
          <>
            <TabTitle title="Details" />
            {item && <InfoPanelDetailsTab item={item} />}
          </>
        )}
        {focusedType === WorkspaceInfoPanelTab.Activity && (
          <>
            <TabTitle title="Activity" />
            {activityTimeline}
          </>
        )}
        {focusedType === WorkspaceInfoPanelTab.Versions && (
          <>
            <TabTitle title="Version History" />
            <InfoPanelVersionsTabMocked />
          </>
        )}
        {focusedType === WorkspaceInfoPanelTab.Comments && (
          <>
            <TabTitle title="Comments" />
            <PreviewSidePanelComments item={item} thumbnail={thumbnail} />
          </>
        )}
      </View>
    </ScrollView>
  );
}

function TabTitle({title}: {title: string}) {
  return (
    <Typography size="md" color={theme.colors.neutral.$700}>
      {title}
    </Typography>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flexDirection: 'column',
    gap: 16,
    padding: 16,
  },
  name: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  nameContainer: {
    paddingHorizontal: 20,
    paddingBottom: 5,
    paddingTop: 15,
  },
});
