import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useSelector} from 'react-redux';
import {selectors as filesSelectors} from 'store/slices/files';
import type {FilesItem} from 'store/slices/files/types';
import {selectors as sharedSelectors} from 'store/slices/shared';
import type {Share} from 'store/slices/shared/types';
import {selectors as workspaceSelectors} from 'store/slices/workspace';

type InfoPanelItem = {
  type: 'folder' | 'share' | 'workspace' | 'other';
  item: FilesItem | Share | WorkspaceListDetail | undefined;
};

export function useGetNodeType(
  nodeId: string,
  workspaceId: string,
): InfoPanelItem | null {
  const workspace = useSelector(
    workspaceSelectors.getWorkspaceById(workspaceId),
  );
  const folder = useSelector(filesSelectors.getItem(nodeId));
  const linkId = folder?.target_id;
  const share = useSelector(
    linkId
      ? sharedSelectors.getSingleSharedItem(linkId)
      : sharedSelectors.getSingleSharedItem(nodeId),
  );

  const isFolder = folder !== undefined && linkId === undefined;
  const isShare = !isFolder && share !== undefined;
  const isWorkspace = !isFolder && !isShare && workspace !== undefined;

  if (isFolder) return {type: 'folder', item: folder};
  if (isShare) return {type: 'share', item: share};
  if (isWorkspace) return {type: 'workspace', item: workspace};

  return {type: 'other', item: undefined};
}
