import {t} from '@lingui/macro';
import type {InvitationDetails} from 'fast-sdk/src/api/share/consts';
import {Button} from 'interface/common/Button';
import {NavigateTo, useLogout} from 'interface/common/hooks/useLogout';
import {StyleSheet, Text, View} from 'react-native';
import type {User} from 'store/slices/user/types';
import {RouteLayout} from '../_layout/RouteLayout';
import {UserBadge} from './UserBadge';

interface InvitationUserNotMatchProps {
  invitation: InvitationDetails;
  userDetails: User;
  userProfilePic: string;
}

export function InvitationUserNotMatch(props: InvitationUserNotMatchProps) {
  const {invitation, userDetails, userProfilePic} = props;
  const {logout} = useLogout();

  const handleSignIn = async () => {
    logout({navigateTo: NavigateTo.SIGNUP});
  };

  return (
    <RouteLayout
      title={t`Are you signed in with the right email?`}
      subTitle={
        <UserBadge
          id={userDetails.id}
          email={userDetails.email_address}
          imageSrc={userProfilePic}
          customStyle={{marginBottom: '0.5rem'}}
          initials={`${userDetails.first_name[0]}${userDetails.last_name[0]}`}
        />
      }
      customRootMaxWidth={700}
      customContentContainerStyle={styles.titleContainer}>
      <Text style={styles.description}>
        {t`To access this ${invitation.entity_type}, you must be signed in with the same email address that received the invite.`}
      </Text>
      <View style={styles.buttonContainer}>
        <Button
          type="Primary"
          onPress={handleSignIn}
          label={t`Sign in with different email`}
        />
      </View>
    </RouteLayout>
  );
}

const styles = StyleSheet.create({
  titleContainer: {
    marginBottom: '1rem',
  },
  buttonContainer: {
    alignSelf: 'center',
  },
  description: {
    textAlign: 'center',
    marginBottom: 20,
  },
});
