import theme from 'config/theme';
import {ScrollView, StyleSheet} from 'react-native';
import {Document} from 'react-pdf';
import {HEIGHT_CONTROL_BAR} from '../ControlBar';
import PDFPage from './PDFPage';

type Props = {
  file: any;
  onDocumentLoadSuccess: (data: {numPages: number}) => void;
  handleChangePage: (page: number) => void;
  pageNumber: number;
  totalPages: number;
  showPagination: boolean;
};

const PDFPagination = ({
  file,
  onDocumentLoadSuccess,
  handleChangePage,
  pageNumber,
  totalPages,
  showPagination,
}: Props) => {
  return (
    <ScrollView
      style={[styles.pagesContainer, !showPagination && styles.hidden]}>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from({length: totalPages}).map((_, index) => {
          const currentPage = index + 1;
          const isActive = currentPage === pageNumber;
          return (
            <PDFPage
              key={currentPage}
              isActive={isActive}
              handleChangePage={handleChangePage}
              currentPage={currentPage}
            />
          );
        })}
      </Document>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  pagesContainer: {
    flex: 1,
    maxWidth: 180,
    minWidth: 180,
    padding: 15,
    paddingTop: 0,
    backgroundColor: theme.colors.neutral.$1,
    height: `calc(100vh - ${HEIGHT_CONTROL_BAR}px)`,
  },
  hidden: {
    display: 'none',
  },
});

export default PDFPagination;
