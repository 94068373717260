import {t} from '@lingui/macro';
import theme from 'config/theme';
import {DEFAULT_RETRIES_ON_GENERIC_ERROR} from 'constants/errors';
import type {ErrorBoundaryDetails} from 'errors/types';
import {useModal} from 'extensions/viewport/useModal';
import {VE_DEBUG_ROUTING_KEY} from 'fast-sdk/src/requests/consts';
import Typography from 'interface/base/Typography';
import {useEffect, useState} from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as errors from 'store/slices/errors';
import {copyLink} from 'utils/common/interactions';
import {Button} from '../Button';
import {FormError} from '../FormError';
import {ChatbotButton} from './ChatbotButton';
import {ConfirmReset} from './ConfirmReset';
import AppErrorImage from './assets/App_Error.svg';

enum PageStatus {
  ErrorInfo = 'ErrorInfo',
  ConfirmReset = 'ConfirmReset',
}

export interface ErrorComponentProps {
  showConfirmResetModal?: boolean;
  showCloseButton?: boolean;
  errorDetails?: ErrorBoundaryDetails;
}

export const ErrorComponent = ({
  showConfirmResetModal,
  showCloseButton,
  errorDetails,
}: ErrorComponentProps) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const modal = useModal();

  const [loading, setLoading] = useState<boolean>(false);
  const [debugKey, setDebugKey] = useState<string>(null);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [pageStatus, setPageStatus] = useState<PageStatus>(
    PageStatus.ErrorInfo,
  );

  const lastError = useSelector(errors.selectors.getLastError);

  useEffect(() => {
    const debugKey = window.localStorage.getItem(VE_DEBUG_ROUTING_KEY);
    setDebugKey(`Debug Routing: ${debugKey}`);
  }, []);

  const handleChatbotClickCallback = () => {
    dispatch(errors.default.actions.hideError());
  };

  const handleCopy = () => {
    copyLink(`${debugKey}\n\n${cleanErrorStack(errorDetails.error.stack)}`);
    toast.show('Error stack copied to clipboard', {
      type: 'neutral',
    });
  };

  const handleTryAgain = () => {
    if (lastError.retryFn) {
      setLoading(true);
      lastError
        .retryFn()
        .then(res => {
          if (!res || !res.result) {
            setErrorMessage(
              t`There is still an issue with this request, we recommend close this modal and start fresh`,
            );
          }
        })
        .finally(() => {
          setLoading(false);
          dispatch(errors.default.actions.decreaseRemainingRetries());
        });
    } else {
      if (window) {
        window.location.reload();
      }
    }
  };

  const handleConfirmReset = async () => {
    dispatch(app.default.actions.setForceLogout(true));
  };

  const openConfirmModal = () => {
    modal.open(
      <ConfirmReset
        confirm={handleConfirmReset}
        goBack={modal.close}
        showCloseButton
      />,
    );
  };

  const cleanErrorStack = (stack: string) => {
    return stack.replace(new RegExp(window.location.origin, 'g'), '');
  };

  const showRetryButton =
    (lastError?.errorConfiguration?.allowRetry && lastError?.retryFn) ||
    lastError?.errorCode === 'cors' ||
    errorDetails;

  const showResetButton =
    lastError?.errorConfiguration?.allowReset || errorDetails;

  const availableRetries = errorDetails
    ? DEFAULT_RETRIES_ON_GENERIC_ERROR
    : lastError.availableRetries;

  return pageStatus === PageStatus.ErrorInfo ? (
    <View style={styles.root}>
      {showCloseButton && (
        <Button
          lIcon={
            <Icon name="close" size={20} color={theme.colors.neutral.$6} />
          }
          onPress={() => dispatch(errors.default.actions.hideError())}
          type="Text"
          customRootStyle={styles.closeContainer}
        />
      )}
      {errorMessage ? <FormError errorMessage={errorMessage} /> : null}
      <View style={styles.topImageContainer}>
        <Image
          source={{uri: AppErrorImage}}
          alt={t`Error`}
          style={styles.topImage}
        />
      </View>
      <View style={styles.errorInfoContainer}>
        <Typography variant="semi-bold" color={theme.colors.neutral.$2Base}>
          {lastError.errorCode ? lastError.title : errorDetails?.title}
        </Typography>
        <Typography size="md" color={theme.colors.neutral.$2Base}>
          {lastError.errorCode ? lastError.message : errorDetails?.message}
        </Typography>
        {errorDetails && (
          <Typography
            size="md"
            color={theme.colors.neutral.$2Base}
            overrides={styles.stack}
            selectable>
            {`${debugKey}\n\n`}
            {cleanErrorStack(errorDetails.error.stack)}
          </Typography>
        )}
        {errorDetails && (
          <Button
            type="Secondary"
            label="Copy"
            ariaLabel="Copy"
            onPress={handleCopy}
          />
        )}
      </View>
      <View style={styles.buttonsContainer}>
        <View style={styles.leftButtons}>
          {showRetryButton && (
            <Button
              type="Secondary"
              label={t`Try Again${availableRetries > 1 ? ` (${availableRetries})` : ''}`}
              ariaLabel={t`Try Again`}
              onPress={handleTryAgain}
              loading={loading}
              disabled={loading || !availableRetries}
            />
          )}
          {showResetButton && (
            <Button
              type="Secondary"
              label={t`Reset Fastio`}
              ariaLabel={t`Reset Fastio`}
              onPress={
                showConfirmResetModal
                  ? openConfirmModal
                  : () => setPageStatus(PageStatus.ConfirmReset)
              }
            />
          )}
        </View>
        <ChatbotButton
          prefilledMessage={`***${debugKey}\n${errorDetails?.error?.name}: ${errorDetails?.error?.message}***\n\nHello, I have encountered an error.`}
          onClickCallback={handleChatbotClickCallback}
        />
      </View>
    </View>
  ) : (
    <ConfirmReset
      goBack={() => setPageStatus(PageStatus.ErrorInfo)}
      showCloseButton={showCloseButton}
      confirm={handleConfirmReset}
    />
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    position: 'relative',
    backgroundColor: theme.colors.neutral.$white,
    width: 500,
    borderRadius: 7,
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 6px 12px 0px rgba(0, 0, 0, 0.07)',
  },
  topImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  topImage: {
    width: 96,
    height: 96,
  },
  errorInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginBottom: 24,
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
  message: {
    fontSize: 14,
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
  },
  stack: {
    width: '100%',
    maxHeight: '35vh',
    minHeight: '25vh',
    borderRadius: 8,
    borderWidth: 1,
    overflow: 'scroll',
    borderColor: theme.colors.neutral.$10,
    padding: 8,
    whiteSpace: 'pre',
    fontFamily: 'monospace',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: 10,
  },
  closeContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
    margin: 8,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
});
