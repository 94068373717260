import {type AudioMimeType, MediaPlayer, MediaProvider} from '@vidstack/react';
import type {PropsWithChildren} from 'react';
import {StyleSheet, View} from 'react-native';

export type AudioWrapperMimeType = AudioMimeType;

type Props = {
  url: string;
  loading: boolean;
  title: string;
  type: AudioWrapperMimeType;
};

const AudioWrapper = ({
  url,
  loading,
  title,
  children,
  type,
}: PropsWithChildren<Props>) => {
  if (!url && loading) {
    return <View style={styles.root}>{children}</View>;
  }

  return (
    <MediaPlayer
      title={title}
      src={{src: url, type}}
      style={styles.root}
      preload="auto">
      <MediaProvider />
      {children}
    </MediaPlayer>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});

export default AudioWrapper;
