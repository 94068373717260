import {api} from 'fast-sdk';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as users from 'store/slices/users';
import {createInitials} from 'utils/common/data';

// Promise cache outside the hook to be shared across all instances
const fetchPromiseCache: Record<string, Promise<any>> = {};

export function useGetUserDetailsCached(userId: string) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const user = useSelector(users.selectors.getUser(userId));

  const userFirstName = user?.first_name;
  const userLastName = user?.last_name;

  const userName = `${userFirstName} ${userLastName}`;
  const initials = createInitials(userName);

  useEffect(() => {
    let mounted = true;

    const fetchUser = async () => {
      // If user exists in store, don't fetch
      if (user || !userId) return;

      try {
        setIsLoading(true);
        setError(null);

        // If there's already a fetch in progress for this userId, reuse that promise
        if (!fetchPromiseCache[userId]) {
          fetchPromiseCache[userId] = api.user
            .getUserDetailsById(userId)
            .then(response => {
              dispatch(users.default.actions.addUser({user: response.user}));
              return response;
            })
            .finally(() => {
              // Clean up the cache after the promise resolves or rejects
              delete fetchPromiseCache[userId];
            });
        }

        // Wait for the cached promise to resolve
        await fetchPromiseCache[userId];
      } catch (err) {
        if (mounted) {
          setError(
            err instanceof Error ? err : new Error('Failed to fetch user'),
          );
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();

    return () => {
      mounted = false;
    };
  }, [userId, dispatch, user]);

  return {
    user,
    initials,
    userName,
    isLoading,
    error,
  };
}
