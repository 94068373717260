import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {StyleSheet, View} from 'react-native';
import {useFolderTree} from '../../hooks/useFolderTree';
import {FolderItem} from './FolderItem';

interface FolderTreeProps {
  workspace: WorkspaceListDetail;
  selectedFolderId?: string;
  onFolderPress?: (folderId: string) => void;
}

export function FolderTree({
  workspace,
  selectedFolderId,
  onFolderPress,
}: FolderTreeProps) {
  const {rootItem} = useFolderTree({workspace});

  return (
    <View style={styles.root}>
      <FolderItem
        workspace={workspace}
        item={rootItem}
        onPress={onFolderPress}
        selectedFolderId={selectedFolderId}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
});
