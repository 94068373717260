import AdjustHorizontalButton from 'interface/stacks/content-viewer/components/control-bar/AdjustHorizontalButton';
import AdjustVerticalButton from 'interface/stacks/content-viewer/components/control-bar/AdjustVerticalButton';
import CommentButton from 'interface/stacks/content-viewer/components/control-bar/CommentButton';
import FullscreenButton from 'interface/stacks/content-viewer/components/control-bar/FullscreenButton';

type Props = {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
  handleExpandHorizontal: () => void;
  handleExpandVertical: () => void;
};

const ControlsRight = ({
  isFullscreen,
  toggleFullscreen,
  handleExpandHorizontal,
  handleExpandVertical,
}: Props) => {
  return (
    <>
      <CommentButton />
      <AdjustHorizontalButton handleAdjustHorizontal={handleExpandHorizontal} />
      <AdjustVerticalButton handleAdjustVertical={handleExpandVertical} />
      <FullscreenButton
        fullscreen={isFullscreen}
        toggleFullscreen={toggleFullscreen}
      />
    </>
  );
};

export default ControlsRight;
