import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import type {PropsWithChildren} from 'react';
import type {TextProps} from 'react-native';

type Props = PropsWithChildren<{}> & TextProps;

export default function MediaText({children, ...props}: Props) {
  return (
    <Typography
      variant="regular"
      size="sm"
      overrides={props.style}
      color={theme.colors.neutral.$white}>
      {children}
    </Typography>
  );
}
