import {api} from 'fast-sdk';
import {PermJoin, PermManageMembers} from 'fast-sdk/src/api/workspace/consts';
import {prependedWorkspaceName} from 'utils/fast/common';

interface Options {
  subdomain: string;
  orgId: string;
  intelligence: boolean;
  name: string;
}

const DEFAULT_WORKSPACE_PERMISSIONS = {
  perm_join: PermJoin['Member or above'],
  perm_member_manage: PermManageMembers['Member or above'],
};

const useCreateWorkspace = () => {
  const createWorkspace = async ({
    subdomain,
    orgId,
    name,
    intelligence,
  }: Options) => {
    // The "folder_name" property should be unique, so I prepend the organization
    // id at the beginning of the name and set it as the "folder_name"
    const folderName = prependedWorkspaceName(name, orgId);
    return await api.workspace.createWorkspace(subdomain, {
      name,
      folder_name: folderName,
      intelligence,
      ...DEFAULT_WORKSPACE_PERMISSIONS,
    });
  };

  return {createWorkspace};
};

export default useCreateWorkspace;
