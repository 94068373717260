import theme from 'config/theme';
import React from 'react';
import Icon from 'react-native-vector-icons/MaterialIcons';
import ActionButton from '../ActionButton';

type Props = {
  onPress: () => void;
};

const PreviousPageButton = ({onPress}: Props) => {
  return (
    <ActionButton
      icon={
        <Icon
          name="arrow-circle-up"
          size={20}
          color={theme.colors.neutral.$white}
        />
      }
      tooltipText="Previous Page"
      onPress={onPress}
    />
  );
};

export default PreviousPageButton;
