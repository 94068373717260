import theme from 'config/theme';
import {parseISO} from 'date-fns';
import Typography from 'interface/base/Typography';
import {MemberAvatar} from 'interface/common/MemberAvatar';
import {useGetUserDetailsCached} from 'interface/common/hooks/useGetUserDetailsCached';
import {StyleSheet, View} from 'react-native';

import type {EntityChatMessageContent} from 'store/slices/aichat/types';
import {formatRelativeTime} from '../../helpers';

interface Props {
  message: EntityChatMessageContent;
  userId: string;
  createdAt: string;
}

export function AiChatQuestionItem({message, userId, createdAt}: Props) {
  const {user, initials, userName, isLoading} = useGetUserDetailsCached(userId);
  const createdAtDate = parseISO(createdAt);

  return (
    <View style={styles.container}>
      <View style={styles.avatarContainer}>
        {userId && <MemberAvatar initials={initials} memberId={userId} />}
      </View>
      <View style={styles.messageContainer}>
        <View style={styles.header}>
          <Typography
            variant="medium"
            size="md"
            color={theme.colors.neutral.$700}>
            {userName}
          </Typography>
          <Typography size="sm" color={theme.colors.neutral.$500}>
            {formatRelativeTime(createdAtDate)}
          </Typography>
        </View>
        <Typography size="md" color={theme.colors.neutral.$700}>
          {message.text}
        </Typography>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 12,
  },
  avatarContainer: {
    width: 32,
    height: 32,
    marginVertical: 4,
  },
  messageContainer: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 8,
    gap: 8,
    borderRadius: 12,
    backgroundColor: theme.colors.neutral.$75,
  },
  header: {
    flexDirection: 'row',
    gap: 12,
  },
});
