import {colors} from 'config/styles';
import theme from 'config/theme';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  type ViewStyle,
} from 'react-native';
import {CenteredView} from './CenteredView';

type LoadingSize = 'large' | 'small';

interface LoadingProps {
  size?: LoadingSize;
  customStyle?: ViewStyle;
  customColor?: string;
  centered?: boolean;
  text?: string;
}

export const Loading = ({
  size = 'large',
  customStyle,
  customColor = colors.primary.brand,
  centered = false,
  text = undefined,
}: LoadingProps) => {
  const LoadingIndicator = (
    <ActivityIndicator
      testID="loading-indicator"
      color={customColor}
      size={size}
      style={customStyle}
    />
  );

  return centered || text ? (
    <CenteredView style={styles.root}>
      {LoadingIndicator}
      {text && <Text style={styles.text}>{text}</Text>}
    </CenteredView>
  ) : (
    LoadingIndicator
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    gap: 10,
  },
  text: {
    color: theme.colors.neutral.$2Base,
    fontSize: 12,
  },
});
