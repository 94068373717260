import {useEffect, useRef, useState} from 'react';
import type {View} from 'react-native';

const performFullscreen = (
  isFullscreen: boolean,
  ref: React.RefObject<View>,
) => {
  if (isFullscreen) {
    if (window.document.exitFullscreen) {
      window.document.exitFullscreen();
      // @ts-ignore
    } else if (window.document.webkitExitFullscreen) {
      // @ts-ignore
      window.document.webkitExitFullscreen();
      // @ts-ignore
    } else if (window.document.msExitFullscreen) {
      // @ts-ignore
      window.document.msExitFullscreen();
    }
  } else {
    // @ts-ignore
    if (ref.current.requestFullscreen) {
      // @ts-ignore
      ref.current.requestFullscreen();
      // @ts-ignore
    } else if (ref.current.webkitRequestFullscreen) {
      // @ts-ignore
      ref.current.webkitRequestFullscreen();
      // @ts-ignore
    } else if (ref.current.msRequestFullscreen) {
      // @ts-ignore
      ref.current.msRequestFullscreen();
    }
  }
};

const useFullscreen = () => {
  const ref = useRef<View>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const exitFullscreen = () => {
      if (
        // @ts-ignore
        !document.webkitIsFullScreen &&
        // @ts-ignore
        !document.mozFullScreen &&
        // @ts-ignore
        !document.msFullscreenElement
      ) {
        setIsFullscreen(false);
      }
    };

    document.addEventListener('fullscreenchange', exitFullscreen);
    document.addEventListener('webkitfullscreenchange', exitFullscreen);
    document.addEventListener('mozfullscreenchange', exitFullscreen);
    document.addEventListener('MSFullscreenChange', exitFullscreen);

    return () => {
      document.removeEventListener('fullscreenchange', exitFullscreen);
      document.removeEventListener('webkitfullscreenchange', exitFullscreen);
      document.removeEventListener('mozfullscreenchange', exitFullscreen);
      document.removeEventListener('MSFullscreenChange', exitFullscreen);
    };
  }, []);

  const toggleFullscreen = () => {
    if (!ref.current) {
      return;
    }

    setIsFullscreen(prev => {
      performFullscreen(prev, ref);
      return !prev;
    });
  };

  return {ref, isFullscreen, toggleFullscreen};
};

export default useFullscreen;
