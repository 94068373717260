import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import Modal from 'interface/common/Modal';
import {TabList} from 'interface/common/TabList';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {WorkspaceSettingsDetails} from './WorkspaceSettingsDetails';
import {WorkspaceSettingsMembers} from './WorkspaceSettingsMembers';
import {WorkspaceSettingsPermissions} from './WorkspaceSettingsPermissions';

const tabs: ActiveTab[] = ['Details', 'Members', 'Permissions'];

export type ActiveTab = 'Details' | 'Members' | 'Permissions';

interface WorkspaceSettingsProps {
  workspace: WorkspaceListDetail;
  initialTab?: ActiveTab;
  selfId: string;
  close: () => void;
}

export function WorkspaceSettings(props: WorkspaceSettingsProps) {
  const [activeTab, setActiveTab] = useState<ActiveTab>(
    props.initialTab || 'Details',
  );

  return (
    <Modal title={`Workspace Settings - ${props.workspace.name}`}>
      <View style={styles.container}>
        <TabList
          tabs={tabs.map(tab => ({key: tab, label: tab}))}
          activeTab={activeTab}
          setActiveTab={(tab: ActiveTab) => setActiveTab(tab)}
          rootStyle={styles.tabList}
          tabStyle={styles.tabItem}
        />
        {activeTab === 'Details' ? (
          <WorkspaceSettingsDetails
            workspace={props.workspace}
            close={props.close}
          />
        ) : activeTab === 'Members' ? (
          <WorkspaceSettingsMembers
            workspace={props.workspace}
            selfId={props.selfId}
            close={props.close}
          />
        ) : (
          <WorkspaceSettingsPermissions
            workspace={props.workspace}
            close={props.close}
          />
        )}
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    gap: 24,
  },
  tabList: {
    paddingHorizontal: 0,
  },
  tabItem: {
    paddingVertical: 6,
    paddingLeft: 15,
    paddingRight: 15,
    marginRight: 0,
  },
});
