import {t} from '@lingui/macro';
import {useMemo} from 'react';
import {Image, View} from 'react-native';
import {useIntercom} from 'react-use-intercom';
import {Button, type ButtonProps} from '../Button';
import ChatBotImage from './assets/Chatbot.svg';

interface ChatbotButtonProps extends Partial<ButtonProps> {
  iconWidth?: number;
  iconHeight?: number;
  prefilledMessage?: string;
  onClickCallback?: () => void;
}

export const ChatbotButton = ({
  onClickCallback,
  iconWidth = 24,
  iconHeight = 24,
  prefilledMessage,
  ...rest
}: ChatbotButtonProps) => {
  const intercom = useIntercom();
  const chatBotIcon = useMemo(
    () => (
      <View>
        <Image
          source={{
            uri: ChatBotImage,
          }}
          style={{
            width: iconWidth,
            height: iconHeight,
          }}
        />
      </View>
    ),
    [iconWidth, iconHeight],
  );

  return (
    <Button
      {...rest}
      type="Primary"
      lIcon={chatBotIcon}
      onPress={() => {
        intercom.showNewMessage(prefilledMessage);
      }}
      ariaLabel={t`Open Chatbot`}
      label={t`Chat with Support`}
    />
  );
};
