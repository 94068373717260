import theme from 'config/theme';
import {useHover} from 'interface/common/hooks/useHover';
import React, {type PropsWithChildren} from 'react';
import {StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import ActionButton from '../ActionButton';

type Props = {
  volume: number;
  muted: boolean;
  toggleMuted: () => void;
};

const VolumeButton = ({
  children,
  volume,
  muted,
  toggleMuted,
}: PropsWithChildren<Props>) => {
  const {isHover, onHoverIn, onHoverOut} = useHover();

  return (
    <View
      style={styles.root}
      // @ts-ignore
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}>
      <ActionButton
        icon={
          <Icon
            name={volume && !muted ? 'volume-up' : 'volume-off'}
            size={20}
            color={theme.colors.neutral.$white}
          />
        }
        onPress={toggleMuted}
        tooltipText={muted ? 'Unmute' : 'Mute'}
      />
      <View style={styles.volume}>{isHover && children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  volume: {
    width: 100,
  },
});

export default VolumeButton;
