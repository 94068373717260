import theme from 'config/theme';
import React from 'react';
import Icon from 'react-native-vector-icons/MaterialIcons';
import ActionButton from '../ActionButton';

type Props = {
  playing: boolean;
  togglePause: () => void;
};

const PlayButton = ({playing, togglePause}: Props) => {
  const handlePlay = () => togglePause();

  return (
    <ActionButton
      icon={
        <Icon
          name={playing ? 'pause' : 'play-arrow'}
          size={20}
          color={theme.colors.neutral.$white}
        />
      }
      tooltipText={playing ? 'Pause' : 'Play'}
      onPress={handlePlay}
    />
  );
};

export default PlayButton;
