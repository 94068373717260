import {ActivityTypes} from 'fast-sdk/src/websockets/types';
import {useGetUserDetails} from 'interface/common/hooks/useGetUserDetails';
import {useGetUserProfilePhoto} from 'interface/common/hooks/useGetUserProfilePhoto';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as activity from 'store/slices/activity';
import {EntityType} from 'store/slices/activity/types';
import * as userSlice from 'store/slices/user';
import type {User} from 'store/slices/user/types';
import {getCurrentUTCDateTime} from 'utils/common/dates';
import {shouldUpdateCache} from '../utils';

interface UseSyncUserDataOptions {
  user: User;
}

const useSyncUserData = ({user}: UseSyncUserDataOptions) => {
  const dispatch = useDispatch();

  const userLogo = useSelector(userSlice.selectors.getUserProfilePic);

  const userActivities =
    useSelector(activity.selectors.getUserActivities(user?.id))?.activities ??
    {};

  const userDetailsActivity = userActivities[ActivityTypes.DETAILS];
  const userAssetsActivity = userActivities[ActivityTypes.ASSETS];

  const {fetchUserDetails} = useGetUserDetails();
  const {fetchUserProfilePhoto} = useGetUserProfilePhoto();

  const updateEntityLastClientUpdate = useCallback(
    (userId: string, activityType: string) => {
      const lastClientUpdate = getCurrentUTCDateTime();
      dispatch(
        activity.default.actions.setEntitiesLastClientUpdate({
          entities: [userId],
          activities: [activityType],
          entityType: EntityType.User,
          lastClientUpdate,
        }),
      );
    },
    [],
  );

  const onUserChange = useCallback(() => {
    if (user?.id) {
      updateEntityLastClientUpdate(user.id, ActivityTypes.DETAILS);
    }
  }, []);

  const onUserLogoChange = useCallback(() => {
    if (user?.id) {
      updateEntityLastClientUpdate(user.id, ActivityTypes.ASSETS);
    }
  }, []);

  useEffect(() => {
    onUserLogoChange();
  }, [userLogo]);

  useEffect(() => {
    onUserChange();
  }, [user]);

  useEffect(() => {
    if (userDetailsActivity) {
      const {lastServerUpdate, lastClientUpdate} = userDetailsActivity;
      if (shouldUpdateCache(lastServerUpdate, lastClientUpdate)) {
        fetchUserDetails();
      }
    }
  }, [userDetailsActivity]);

  useEffect(() => {
    if (userAssetsActivity) {
      const {lastServerUpdate, lastClientUpdate} = userAssetsActivity;
      if (shouldUpdateCache(lastServerUpdate, lastClientUpdate)) {
        fetchUserProfilePhoto(user.id);
      }
    }
  }, [userAssetsActivity]);
};

export default useSyncUserData;
