import theme from 'config/theme';
import {Pressable, StyleSheet, Text, type ViewStyle} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

interface Props {
  check: boolean;
  checkBoxText?: string;
  setCheck?: (value: boolean) => void;
  customStyle?: ViewStyle;
  disabled?: boolean;
  pointerEvents?: 'none' | 'auto' | 'box-none' | 'box-only';
}

export function CustomCheckbox(props: Props) {
  return (
    <Pressable
      pointerEvents={props.pointerEvents}
      style={[
        styles.root,
        {opacity: props.disabled ? 0.5 : 1},
        props.customStyle,
      ]}
      onPress={() => props.setCheck?.(!props.check)}
      disabled={props.disabled}>
      {props.check ? (
        <Icon name="check-box" size={20} color={theme.colors.brand.$4Base} />
      ) : (
        <Icon
          name="check-box-outline-blank"
          size={20}
          color={theme.colors.neutral.$5}
        />
      )}
      {props.checkBoxText && (
        <Text style={styles.text}>{props.checkBoxText}</Text>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  text: {
    lineHeight: 20,
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '400',
    color: theme.colors.neutral.$2Base,
  },
});
