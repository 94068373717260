export type Error = {
  code: number;
  text: string;
};

export type Redirect = {
  primary: string;
  enabled: boolean;
  rules: Rule[];
};

export type Rule = {
  id: number;
  method: string;
  match: string;
  destination: string;
  priority: number;
  type: string;
};

export const NAME_SPACES = {
  AUTH: '/user/sso/signin',
  ALERTS_EVENTS: '/events',
  ANALYTICS: '/analytics',
  APPS: '/apps',
  ASSETS: {
    all: '/assets',
    single: '/asset',
  },
  BILLING: '/billing',
  CDN_PROVIDER: '/cdn/providers',
  DOMAIN_BINDING: '/domains/bindings',
  FILE_FOLDER: '/server/list',
  LOG: '/log',
  METADATA: '/metadata',
  PLATFORM: '/system',
  POLL: '/activity/poll',
  SERVER: '/server',
  STORAGE: '/storage',
  TEAMS: '/member/list',
  TWO_FACTOR: '/user/2factor',
  UPLOAD: '/upload',
  STATISTICS: '/stats',
  USER: '/user',
  USERS: '/users',
  PAYMENT: 'https://api.stripe.com/v1',
  ORGANIZATION: '/org',
  WORKSPACE: '/workspace',
  WORKSPACES: '/workspaces',
  SHARE: '/share',
  SHARES: '/shares',
  REALTIME: '/realtime',
  WEBSOCKET: '/websocket',
  QUICK_SHARES: '/quickshares',
  QUICK_SHARE: '/quickshare',
  EVENTS: '/events',
};
