import {useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {InboxTimelineEvent} from './base/InboxTimelineEvent';
import {InboxTimelineHeading} from './base/InboxTimelineHeading';

type InboxEvent = {};

const inboxEvents: (string | InboxEvent)[] = ['Today', {}, 'Yesterday', {}];

const stickyHeaderIndices = inboxEvents
  .map((item, index) => {
    if (typeof item === 'string') {
      return index;
    }
    return null;
  })
  .filter(item => item !== null) as number[];

const InboxList = () => {
  return null;
  // return (
  //   <FlashList
  //     data={inboxEvents}
  //     estimatedItemSize={200}
  //     contentContainerStyle={{}}
  //     stickyHeaderIndices={stickyHeaderIndices}
  //     getItemType={item => (typeof item === 'string' ? 'sectionHeader' : 'row')}
  //     renderItem={({item}) => {
  //       // Render header
  //       if (typeof item === 'string') {
  //         return <InboxTimelineHeading label={item} />;
  //         // Render item
  //       }
  //       return <InboxTimelineEvent />;
  //     }}
  //   />
  // );
};

export function Main() {
  return (
    <View style={styles.root}>
      <View style={styles.inbox}>
        <InboxList />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  inbox: {},
});
