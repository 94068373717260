import theme from 'config/theme';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {Icon} from '../Icon';

interface AddEmailErrorCounterProps {
  counter: number;
  onRemoveAll: () => void;
}

export function AddEmailErrorCounter(props: AddEmailErrorCounterProps) {
  if (props.counter === 0) {
    return null;
  }

  const errorText = props.counter === 1 ? 'error' : 'errors';

  return (
    <View style={styles.container}>
      <Icon
        name="lucide:triangle-alert"
        size={14}
        color={theme.colors.danger.$5}
      />
      <Text style={styles.error}>
        {props.counter} {errorText}
      </Text>
      <Pressable onPress={props.onRemoveAll}>
        <Text style={styles.button}>Remove errors</Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 7,
    marginTop: 6,
    alignItems: 'center',
  },
  error: {
    borderColor: theme.colors.danger.$e2,
    fontSize: 12,
  },
  button: {
    color: theme.colors.brand.$4Base,
    fontSize: 12,
  },
});
