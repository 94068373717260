import {CustomCheckbox} from 'interface/common/CustomCheckbox';
import React from 'react';
import {StyleSheet, Text} from 'react-native';
import {type OnChangeFilter, type OnRemoveFilter, getLabel} from '../consts';
import DropdownPill from './dropdown-pill/DropdownPill';
import DropdownPillItem from './dropdown-pill/DropdownPillItem';

const label = {
  added: 'Added',
  renamed: 'Renamed',
  moved: 'Moved',
  metadataUpdated: 'Metadata Updated',
};

const options = [
  {label: label.added, value: 'added'},
  {label: label.renamed, value: 'renamed'},
  {label: label.moved, value: 'moved'},
  {label: label.metadataUpdated, value: 'metadataUpdated'},
];

type Props = {
  selected: Array<string>;
  onChange: OnChangeFilter;
  onRemove: OnRemoveFilter;
};

const EventFilter = ({selected, onChange, onRemove}: Props) => {
  return (
    <DropdownPill
      label={getLabel(selected, 'Event', label)}
      active={Boolean(selected.length)}
      removeFilter={onRemove}>
      {options.map(option => (
        <DropdownPillItem
          key={option.value}
          onPress={() => onChange(option.value)}>
          <CustomCheckbox
            pointerEvents="none"
            customStyle={styles.checkbox}
            check={selected.includes(option.value)}
          />
          <Text numberOfLines={1}>{option.label}</Text>
        </DropdownPillItem>
      ))}
    </DropdownPill>
  );
};

const styles = StyleSheet.create({
  checkbox: {
    paddingHorizontal: 0,
  },
});

export default EventFilter;
