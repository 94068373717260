import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';

export function AiChatPrivateLabel() {
  return (
    <View style={styles.container}>
      <Icon name="lucide:lock" size={16} color={theme.colors.neutral.$500} />
      <Typography size="xs" color={theme.colors.neutral.$700}>
        Private
      </Typography>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 20,
    paddingHorizontal: 12,
    borderRadius: 10,
    backgroundColor: theme.colors.neutral.$100,
    gap: 6,
  },
});
