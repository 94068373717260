import type {Actions, Store} from 'store/slices/quick-share/types';
import {resetSlice} from 'store/utils';
import {initialState} from '.';
import {buildQuickShare} from './helpers';

export function purge(state: Store) {
  return resetSlice(state, initialState);
}

export function setQuickShares(
  state: Store,
  action: Actions['SetQuickShares'],
) {
  const {quickshares, workspaceFolderName} = action.payload;
  if (!workspaceFolderName)
    console.error('No workspace folder name provided for setQuickShares');

  const currentIds = state.workspace[workspaceFolderName] || [];
  state.list = [
    ...state.list.filter(qs => !currentIds.includes(qs.id)),
    ...quickshares.map(qs => buildQuickShare(qs, workspaceFolderName)),
  ];

  state.workspace[workspaceFolderName] = quickshares.map(qs => qs.id);
}

export function addQuickShare(
  state: Store,
  action: Actions['CreateQuickShare'],
) {
  const {quickshare, workspaceFolderName} = action.payload;
  if (!workspaceFolderName)
    console.error('No workspace folder name provided for addQuickShare');

  state.list.push(buildQuickShare(quickshare, workspaceFolderName));

  if (!state.workspace[workspaceFolderName])
    state.workspace[workspaceFolderName] = [];
  state.workspace[workspaceFolderName].push(quickshare.id);
}

export function removeQuickShare(
  state: Store,
  action: Actions['RemoveQuickShare'],
) {
  const {quickshareId, workspaceFolderName} = action.payload;
  state.list = state.list.filter(qs => qs.id !== quickshareId);
  state.workspace[workspaceFolderName] = state.workspace[
    workspaceFolderName
  ].filter(id => id !== quickshareId);
}
