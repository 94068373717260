import type {Member} from 'store/slices/settings/types';
import type {ShareMember} from 'store/slices/shared/types';
import {getHashColor} from './color';

export const getMemberImages = (membersArray: Array<Member>) => {
  const result = [];
  const members = membersArray.slice(0, 5);
  for (const member of members) {
    result.push({
      alt: `${member.firstName} ${member.lastName}`,
      src: member.profilePic,
      color: getHashColor(member.id),
      initials: `${member.firstName[0]}${member.lastName[0]}`,
    });
  }

  return result;
};

export const getMembersFromUsers = (members: ShareMember[]): ShareMember[] => {
  return members.map(member => ({
    ...member,
    id: member.id,
    permissions: member.permissions,
    authentication:
      member['2factor'] === true
        ? 'Two-Factor (2FA)'
        : member['2factor'] === false
          ? 'Without Two-Factor'
          : '',
    dateJoined: member.created ? new Date(member.created) : null,
    email: member.email_address,
    firstName: member.first_name,
    lastName: member.last_name,
    profilePic: member.profile_pic,
  }));
};
