import type {QuickShareItem} from 'fast-sdk/src/api/storage/consts';
import type {QuickShare} from 'store/slices/quick-share/types';

export function buildQuickShare(
  quickshare: QuickShareItem,
  workspaceFolderName: string,
): QuickShare {
  const duration =
    (new Date(quickshare.expires).getTime() -
      new Date(quickshare.created).getTime()) /
    1000;
  return {...quickshare, workspaceFolderName, duration};
}
