import theme from 'config/theme';
import {type MenuOptionItem, useMenu} from 'extensions/viewport/useMenu';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import {createRef, useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import type {GestureResponderEvent, StyleProp, ViewStyle} from 'react-native';

type Props = {
  label: string;
  items: Array<MenuOptionItem>;
  rootStyle?: StyleProp<ViewStyle>;
};

const SettingsTableDropdown = <T,>({label, items, rootStyle}: Props) => {
  const memberMenu = useMenu(
    () => items,
    () => {
      memberMenu.close();
    },
    false,
  );

  const menuAnchorRef = createRef<View>();

  const menu = useCallback(
    (e: GestureResponderEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (memberMenu) {
        if (e?.nativeEvent) {
          memberMenu.setPosition({
            x: e.nativeEvent.pageX,
            y: e.nativeEvent.pageY,
          });
          memberMenu.setRef(menuAnchorRef);
        }
      }
      memberMenu.open();
    },
    [memberMenu, menuAnchorRef],
  );

  return (
    <View ref={menuAnchorRef} style={[styles.root, rootStyle]}>
      <Button
        overrides={{
          Button: {
            style: styles.button,
          },
        }}
        variant="text"
        size="md"
        onPress={menu}
        endEnhancer={
          <Icon name="lucide:chevron-down" color={theme.colors.neutral.$700} />
        }>
        {label}
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    maxWidth: '100%',
  },
  button: {
    justifyContent: 'flex-start',
  },
});

export default SettingsTableDropdown;
