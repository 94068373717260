import CommentButton from 'interface/stacks/content-viewer/components/control-bar/CommentButton';
import FullscreenButton from 'interface/stacks/content-viewer/components/control-bar/FullscreenButton';
import TimeDisplay from 'interface/stacks/content-viewer/components/control-bar/TimeDisplay';
import ZoomInButton from 'interface/stacks/content-viewer/components/control-bar/ZoomInButton';
import ZoomOutButton from 'interface/stacks/content-viewer/components/control-bar/ZoomOutButton';
import ZoomTextButton from 'interface/stacks/content-viewer/components/control-bar/ZoomTextButton';

export enum Zoom {
  FIFTY = 0.5,
  ONE = 1,
  ONE_AND_HALF = 1.5,
  TWO = 2,
}

type Props = {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
  zoom: Zoom;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  handleLastZoom: () => void;
};

const ControlsRight = ({
  isFullscreen,
  toggleFullscreen,
  zoom,
  handleZoomIn,
  handleZoomOut,
  handleLastZoom,
}: Props) => {
  return (
    <>
      <TimeDisplay>
        <ZoomOutButton onPress={handleZoomOut} />
        <ZoomTextButton zoom={zoom} handleLastZoom={handleLastZoom} />
        <ZoomInButton onPress={handleZoomIn} />
      </TimeDisplay>
      <CommentButton />
      <FullscreenButton
        fullscreen={isFullscreen}
        toggleFullscreen={toggleFullscreen}
      />
    </>
  );
};

export default ControlsRight;
