import {api} from 'fast-sdk';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useCallback, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import * as workspaceSlice from 'store/slices/workspace';

export default function useUnarchiveWorkspace() {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const unarchiveWorkspace = useCallback(
    async (workspace: WorkspaceListDetail) => {
      setLoading(true);
      try {
        const {result} = await api.workspace.unarchiveWorkspace(
          workspace.folder_name,
        );
        if (result) {
          toast.show(`"${workspace.name}" workspace restored.`, {
            type: 'neutral',
          });
          dispatch(
            workspaceSlice.default.actions.unarchiveWorkspace(workspace),
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {unarchiveWorkspace, loading};
}
