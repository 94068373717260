import {WORKSPACE_SHARED} from 'constants/routes';
import {useEffect} from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import app from 'store/slices/app';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';
import files, {selectors} from 'store/slices/files';
import {SharedFoldersList} from './components/main/SharedFoldersList';

interface WorkspaceSharedListProps {
  showArchived?: boolean;
}

export function WorkspaceSharedList({showArchived}: WorkspaceSharedListProps) {
  const {workspaceId} = useParams<'workspaceId'>();
  const focusedId = useSelector(selectors.getFocused);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      app.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.Storage,
        folderId: WORKSPACE_SHARED,
        workspace: workspaceId,
      }),
    );
  }, [workspaceId]);

  useEffect(() => {
    dispatch(files.actions.focusParent({id: ''}));
    dispatch(files.actions.focus({id: ''}));
  }, []);

  return (
    <ScrollView
      contentContainerStyle={styles.scrollViewContainer}
      showsVerticalScrollIndicator={false}>
      <SharedFoldersList
        workspaceAlt={workspaceId}
        showArchived={showArchived}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  scrollViewContainer: {
    flex: 1,
    display: 'flex',
  },
  content: {
    flex: 1,
    flexDirection: 'row',
  },
});
