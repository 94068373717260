import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';

export type OrgLogoProps = {
  org: OrganizationDetails;
  size?: OrgLogoSize;
  shapeKind?: OrgLogoShapeKind;
  showBorder?: boolean;
};

export enum OrgLogoSize {
  ExtraSmall = -1,
  Small = 1,
  Medium = 2,
  Large = 3,
  ExtraLarge = 4,
}

export enum OrgLogoShapeKind {
  RoundedSquare = 'rounded-square',
  Circle = 'circle',
}
