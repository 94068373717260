import {Trans, t} from '@lingui/macro';
import SuccessIcon from 'assets/svgs/icons/large_check.svg';
import theme from 'config/theme';
import {Button} from 'interface/common/Button';
import {Image, StyleSheet, Text, View} from 'react-native';

interface Props {
  done: () => void;
}

export function CancelSuccess(props: Props) {
  return (
    <View style={styles.root}>
      <Image source={{uri: SuccessIcon}} style={styles.successImage} />
      <Text selectable style={styles.header}>
        <Trans>Plan successfully canceled</Trans>
      </Text>
      <Text selectable style={styles.content}>
        <Trans>
          Your plan has been successfully canceled. A confirmation email has
          been sent to your email address.
        </Trans>
      </Text>
      <View style={styles.buttonContainer}>
        <Button
          type="Primary"
          label={t`Done`}
          onPress={props.done}
          customRootStyle={styles.buttonRoot}
          customTextStyle={styles.buttonText}
          ariaLabel={t`Done`}
          fontStyle="Normal, Large"
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
    borderRadius: 14,
    backgroundColor: theme.colors.neutral.$white,
    shadowColor: theme.colors.neutral.$black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3.05,
    elevation: 4,
    maxWidth: 650,
  },
  successImage: {
    width: 84,
    height: 84,
    marginBottom: '1rem',
  },
  header: {
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 48,
    color: theme.colors.neutral.$2Base,
    marginBottom: '1.5rem',
    textAlign: 'center',
    width: '100%',
  },
  content: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
    marginBottom: '3rem',
    textAlign: 'center',
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonRoot: {
    paddingHorizontal: '1rem',
    paddingVertical: 12,
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
});
