import React, {useEffect, useRef} from 'react';
import {View} from 'react-native';
import {Page} from 'react-pdf';

type Props = {
  page: number;
  zoom: number;
  observer: IntersectionObserver | null;
  currentPage: number;
  isHover: boolean;
};

const ObservedPage = ({page, zoom, observer, currentPage, isHover}: Props) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!observer || !ref.current) {
      return;
    }

    observer.observe(ref.current);
  }, [observer]);

  useEffect(() => {
    if (page === currentPage && !isHover) {
      ref.current?.scrollIntoViewIfNeeded({
        block: 'end',
      });
    }
  }, [currentPage, isHover]);

  return (
    <View ref={ref} id={page.toString()}>
      <Page
        key={page}
        className="react-pdf-page-custom"
        pageNumber={page}
        scale={zoom}
      />
    </View>
  );
};

export default ObservedPage;
