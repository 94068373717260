import {api} from 'fast-sdk';
import {BaseCachingService} from './BaseCachingService';

const CACHE_DB_KEY = 'user-photos-v1';
const CACHE_PREFIX = 'photo-';

const fetchPromiseCache: Record<string, Promise<Blob>> = {};

export default class UserProfilePhotoService extends BaseCachingService {
  constructor(config: {cacheDuration?: number} = {}) {
    super({
      cacheDuration: config.cacheDuration,
      cacheDbKey: CACHE_DB_KEY,
      cachePrefix: CACHE_PREFIX,
    });
  }

  async getPhoto(userId: string): Promise<Blob> {
    if (!fetchPromiseCache[userId]) {
      const promise = api.user.getProfilePhoto(userId);
      fetchPromiseCache[userId] = promise;
      const response = await promise;
      delete fetchPromiseCache[userId];
      return response;
    }

    return await fetchPromiseCache[userId];
  }

  async getContent(userId: string): Promise<string> {
    try {
      const cachedContent = await this.getCachedContent(userId);
      if (cachedContent !== undefined) {
        return cachedContent;
      }

      const photoContent = await this.getPhoto(userId);

      if (photoContent instanceof Blob) {
        return this.handleBlobContent(userId, photoContent);
      }

      await this.saveBase64ToCache(userId, '');
      return '';
    } catch (error) {
      return '';
    }
  }
}
