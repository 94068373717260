import {Panel} from 'interface/base/Panel';
import {StyleSheet, View} from 'react-native';

interface SectionProps {
  id: string;
  children: React.ReactNode;
}

export function Section({id, children}: SectionProps) {
  return (
    <Panel id={id} minSize={20} defaultSize={50}>
      <View style={styles.root}>
        <View style={styles.panelContent}>{children}</View>
      </View>
    </Panel>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    height: '100%',
    position: 'relative',
  },
  panelContent: {
    flex: 1,
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});
