import {Outlet} from 'extensions/navigation';
import type React from 'react';
import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {EntityType} from 'store/slices/activity/types';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import useEntityCommunication from '../hooks/useEntityCommunication';
import useSyncUserData from '../hooks/useSyncUserData';

const UserActivityProvider = () => {
  const {initializeActivityForEntity} = useEntityCommunication();

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const currentUser = useSelector(user.selectors.getUserDetails);

  const currentUserId = useMemo(() => currentUser.id, [currentUser]);

  useEffect(() => {
    if (isLoggedIn && currentUserId) {
      return initializeActivityForEntity({
        entityId: currentUserId,
        entityType: EntityType.User,
      });
    }
  }, [isLoggedIn, currentUserId]);

  useSyncUserData({user: currentUser});

  return <Outlet />;
};

export default UserActivityProvider;
