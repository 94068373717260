import type {Member, OrganizationDetails} from '../organization/consts';
import type {StorageItem} from '../storage/consts';
import type {WorkspaceDetails} from '../workspace/consts';

export interface ShareListItem extends StorageItem {
  id: string;
  title?: string;
  description?: string;
  display_type: DefaultLayout;
  parent_org: string;
  parent_workspace: string;
  created: string;
  user_status: string;
  download_enabled: boolean;
  recieve_enabled: boolean;
  comments_enabled?: boolean;
  comments: CommentsSettings;
  filesystem: FileSystemSettings;
  share_level: ShareLevel;
  updated: string;
  platform: number;
  storage: number;
  suspended: boolean;
  share_type: ShareType;
  closed: boolean;
  archived: boolean;
  link_1?: ShareLink;
  link_2?: ShareLink;
  link_3?: ShareLink;
  share_link?: {
    id: string;
    graft_point: string;
  };
  parent_workspace_folder: string;
  intelligence?: boolean;
}

export enum ShareType {
  Exchange = 'exchange',
  Send = 'send',
  Receive = 'receive',
}

export enum ShareLevel {
  Owner = 'owner',
  Guest = 'guest',
  Excluded = 'excluded',
}

export interface FileSystemSettings {
  file_creation: boolean;
  file_modification: string;
  file_download: string;
  file_view: string;
  folder_creation: boolean;
  folder_modification: string;
}

export interface CommentsSettings {
  enabled: boolean;
  owner_comments_visible: boolean;
  guest_comments_visible: boolean;
  personal_replies_visible: boolean;
  owner_replies_visible: boolean;
}

export interface ShareItem extends ShareListItem {
  custom_name?: string;
  expires?: string;
  view_enabled?: string;
  receive_enabled?: boolean;
  workspace_style?: boolean;
  access_options?: URLAccessType;
  recipient_visibility?: RecipientVisibility;
  post_download_message?: string;
  post_download_link_1?: string;
  post_download_link_2?: string;
  post_download_link_3?: string;
  parent: string;
  updated: string;
  locked: boolean;
}

export enum DefaultLayout {
  List = 'List',
  Thumbnail = 'Thumbnail',
  Gallery = 'Gallery',
  Blog = 'Blog',
}

export type ShareLink = {
  id?: number;
  title: string;
  url: string;
  textcolor?: string;
};

export interface ShareListResponse {
  result: boolean;
  shares: Array<ShareListItem>;
}

export interface ShareMembersListResponse {
  result: boolean;
  results: number;
  users: Array<ShareMemberListItem>;
}

export interface ShareMemberListItem extends Member {
  id: string;
  permissions: ShareMemberPermission;
}

export type ShareMemberPermission =
  | 'owner'
  | 'admin'
  | 'member'
  | 'guest'
  | 'view'
  | 'any';

export interface CreateShareResponse {
  share: {
    id: string;
    custom_name: string;
  };
}

export enum URLAccessType {
  MEMBERS_ONLY = 'Only members of the Share or Workspace',
  ORG_MEMBERS = 'Members of the Share, Workspace or Org',
  REGISTERED_USERS = 'Anyone with a registered account',
  PUBLIC = 'Anyone with the link',
}

export enum RecipientVisibility {
  'Activity is visible' = 'Activity is visible',
  'Activity is anonymized' = 'Activity is anonymized',
}

export interface ShareDetailsResponse {
  result: boolean;
  share: ShareItem;
}

export interface PublicShareDetailsResponse extends ShareDetailsResponse {
  org: OrganizationDetails;
  users: Member[];
}

export type ShareMembersListInvitationsResponse = {
  result: boolean;
  invitations: ShareMemberListInvitationItem[];
};

export type ShareMemberListInvitationItem = {
  id: string;
  inviter: string;
  invitee_email: string;
  invitee_uid: null;
  entity: {
    id: string;
    id_alt: string;
  };
  state: string;
  created: Date;
  expires: null;
};

export enum InvitationStatuses {
  Pending = 'pending',
}

export enum InvitationTypes {
  Shared = 'share',
  Organization = 'org',
  Workspace = 'workspace',
}

export enum InvitationActions {
  Accept = 'accept',
  Decline = 'decline',
}

export type InvitationType =
  (typeof InvitationTypes)[keyof typeof InvitationTypes];

export interface InvitationDetailsResponse {
  invitation: InvitationDetails;
}

export interface InvitationDetails {
  id: string;
  inviter: string;
  invitee_email: string;
  invitee_uid: any;
  entity_type: InvitationType;
  org?: OrganizationDetails;
  members?: Member[];
  workspace?: WorkspaceDetails;
  state: InvitationStatuses;
  share: ShareItem;
  created: string;
  expires: any;
}

export interface AllSharesResponse {
  shares: ShareItem[];
}

export interface GetAvailableProfilesResponse {
  has_orgs: boolean;
  has_workspaces: boolean;
  has_shares: boolean;
}
