import theme from 'config/theme';
import React, {useEffect, useRef} from 'react';
import {
  Animated,
  type StyleProp,
  StyleSheet,
  View,
  type ViewStyle,
} from 'react-native';

interface SkeletonProps {
  rows?: number;
  height?: number;
  width?: number | string;
  animation?: boolean;
  style?: StyleProp<ViewStyle>;
}

export default function Skeleton({
  rows = 1,
  height = 100,
  width = '100%',
  animation = true,
  style,
}: SkeletonProps) {
  const animatedValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (animation) {
      Animated.loop(
        Animated.sequence([
          Animated.timing(animatedValue, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true,
          }),
          Animated.timing(animatedValue, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true,
          }),
        ]),
      ).start();
    }
  }, [animation]);

  const opacity = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [0.3, 0.7],
  });

  const renderRows = () => {
    return [...Array(rows)].map((_, index) => (
      <Animated.View
        key={index}
        style={[
          styles.skeletonRow,
          {
            opacity,
            width,
            height,
            marginBottom: index === rows - 1 ? 0 : 8,
          },
        ]}
      />
    ));
  };

  return <View style={[{width}, style]}>{renderRows()}</View>;
}

const styles = StyleSheet.create({
  skeletonRow: {
    backgroundColor: theme.colors.neutral.$8,
    borderRadius: 4,
  },
});
