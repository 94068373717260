import React, {type ReactNode} from 'react';
import {StyleSheet, View, type ViewStyle} from 'react-native';

interface CenteredViewProps {
  children: ReactNode;
  style?: ViewStyle | Array<ViewStyle>;
}

export const CenteredView = ({children, style}: CenteredViewProps) => {
  return <View style={[styles.container, style]}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});
