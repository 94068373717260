import {i18n} from '@lingui/core';
import {I18nProvider} from '@lingui/react';
import {FlagProvider} from '@unleash/proxy-client-react';
import {INTERCOM_APP_ID} from 'constants/intercom';
import {FLAG_CONFIG} from 'constants/unleash';
import {handleApiError} from 'errors';
import device from 'extensions/device';
import {loadLocale} from 'extensions/i18n';
import {BootSplash} from 'extensions/viewport/BootSplash';
import {BottomSheetModalProvider} from 'extensions/viewport/BottomSheetProvider';
import {GestureProvider} from 'extensions/viewport/GestureProvider';
import {CustomToastProvider as ToastProvider} from 'extensions/viewport/ToastProvider';
import {useCleanUpConnections} from 'extensions/websockets';
import {Requests} from 'fast-sdk';
import Auth from 'interface/stacks/auth/consts';
import {useEffect, useState} from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {IntercomProvider} from 'react-use-intercom';

export interface ProviderProps {
  children: JSX.Element;
}

export function Provider({children}: ProviderProps) {
  const [initialized, setInitialized] = useState(false);

  useCleanUpConnections();

  useEffect(() => {
    const initializeApp = async () => {
      await initializeSdkApi();
      loadLocale(device.getLocale(true));
      setInitialized(true);
      BootSplash.hide({fade: true});
    };

    initializeApp();
  }, []);

  const initializeSdkApi = async () => {
    if (__DEV__) {
      Requests.setApiLogEnabled(true);
    }
    Requests.setErrorInterceptor(handleApiError);
    const authToken = await Auth.getAuthToken();
    Requests.setAuthToken(authToken);
  };

  if (!initialized) {
    return null;
  }

  return (
    <FlagProvider config={FLAG_CONFIG}>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={__DEV__}>
        <I18nProvider i18n={i18n}>
          <ToastProvider>
            <SafeAreaProvider>
              <GestureProvider style={{flex: 1}}>
                <BottomSheetModalProvider>{children}</BottomSheetModalProvider>
              </GestureProvider>
            </SafeAreaProvider>
          </ToastProvider>
        </I18nProvider>
      </IntercomProvider>
    </FlagProvider>
  );
}
