import {createSelector} from '@reduxjs/toolkit';
import type {Store} from 'store';
import {AppUserGroup, ORG_ADMIN_PERMISSIONS} from 'store/slices/app/types';
import {initialState} from '.';

const x = (store: Store) => store;

export const isLoaded = createSelector(x, store => store.app.loaded);
export const isActive = createSelector(x, store => store.app.active);
export const isLoggedIn = createSelector(
  x,
  store =>
    !!(/*store.user.user  && */ (store.user.token && store.user.user.id)),
); // TIP: Comment out store.user.user.id to use legacy api
export const isPremium = createSelector(
  x,
  store => store.app.user && store.app.user.group >= AppUserGroup.Pro,
);

export const getUser = createSelector(x, store => store.app.user);
export const getName = createSelector(x, store => store.app.user?.name);
export const getEkey = createSelector(x, store => store.app.user?.ekey);
export const getEmail = createSelector(x, store => store.app.user?.email);
export const getLimits = createSelector(x, store => store.app.user?.limit);
export const getAvatar = createSelector(x, store => store.app.user?.avatar);
export const getRevision = createSelector(x, store => store.app.revision);
export const getHeader = createSelector(x, store => store.app.user?.header);
export const getCurrentPage = createSelector(x, store => store.app.currentPage);

export const getStopRedirect = createSelector(
  x,
  store => store.app.stopRedirect,
);

export const getEnvironmentDomain = createSelector(
  x,
  store => store.app.domainOrigin,
);

export const getCurrentJoinInvitation = createSelector(
  x,
  store => store.app.currentJoinInvitation,
);

export const getForceLogout = createSelector(x, store => store.app.forceLogout);

export const getRedirectToRenewSubscription = createSelector(x, store => ({
  redirect: store.app.redirectToRenewSubscription,
  subdomain: store.app.renewSubscriptionSubdomain,
}));

export const getUserPermission = createSelector(
  x,
  store => store.app.userPermission,
);

export const getUserIsAdminOrOwner = createSelector(x, store =>
  ORG_ADMIN_PERMISSIONS.includes(store.app.userPermission),
);

export const columnInfo = createSelector(
  x,
  store => store.app.columnInfo ?? initialState.columnInfo,
);

export const columnInfoOverride = createSelector(
  x,
  store => store.app.columnInfoOverride ?? initialState.columnInfoOverride,
);

export const resendInvitationLimit = createSelector(
  x,
  store => store.app.resendInvitationLimit,
);

export const canResendInvitation = (key: string, limit: number, time: number) =>
  createSelector(x, store => {
    const resendInvitationLimit = store.app.resendInvitationLimit;
    const entry = resendInvitationLimit[key];
    const canResend =
      !entry ||
      entry?.times < limit ||
      new Date().getTime() - new Date(entry.sent).getTime() > 1000 * 60 * time;
    return canResend;
  });
