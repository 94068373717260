import theme from 'config/theme';
import {useOrgLogo} from 'interface/base/OrgLogo';
import AppLogo from 'interface/common/AppLogo';
import {Loading} from 'interface/common/Loading';
import {StyleSheet, Text, View} from 'react-native';

interface Props {
  kind?: 'light' | 'dark';
  orgId: string;
}

const ShareHeaderOrg: React.FC<Props> = ({orgId, kind = 'light'}) => {
  const {org, loading, logo} = useOrgLogo({orgId});

  const textColor =
    kind === 'light'
      ? theme.colors.neutral.$2Base
      : theme.colors.neutral.$white;

  const renderOrganizationContent = () => {
    if (!org) {
      return <AppLogo kind={kind} />;
    }

    return (
      <>
        {logo}
        <Text style={[styles.orgName, {color: textColor}]}>{org.name}</Text>
      </>
    );
  };

  return (
    <View style={styles.root}>
      {loading ? <Loading /> : renderOrganizationContent()}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
  },
  orgName: {
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 24,
    paddingHorizontal: 8,
  },
});

export default ShareHeaderOrg;
