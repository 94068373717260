import {StyleSheet, Text, type TextStyle} from 'react-native';

interface Props {
  text: string;
  customClass?: TextStyle;
}

export function PageTitle(props: Props) {
  return <Text style={[styles.root, props.customClass]}>{props.text}</Text>;
}

const styles = StyleSheet.create({
  root: {
    fontSize: 21,
    lineHeight: 34,
    fontWeight: '600',
    marginBottom: '2rem',
  },
});
