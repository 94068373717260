import * as RRP from 'react-resizable-panels';

export function Panel(props: RRP.PanelProps & {flex?: boolean}) {
  return (
    <RRP.Panel {...props} style={{display: props.flex ? 'flex' : 'block'}} />
  );
}

export function PanelGroup(props: RRP.PanelGroupProps) {
  return <RRP.PanelGroup {...props} />;
}

export function PanelHandle(props: RRP.PanelResizeHandleProps) {
  return <RRP.PanelResizeHandle {...props} />;
}
