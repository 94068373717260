import theme from 'config/theme';
import {parseISO} from 'date-fns';
import {Popup} from 'extensions/viewport/Popup';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {Pressable, StyleSheet, View} from 'react-native';
import type {EntityChat} from 'store/slices/aichat/types';
import type {Member} from 'store/slices/settings/types';
import {AiChatMembers} from './AiChatMembers';
import {MessagesLabel} from './AiChatMessagesLabel';
import {AiChatPrivateLabel} from './AiChatPrivateLabel';
import {TimeLabel} from './AiChatTimeLabel';

interface Props {
  chat: EntityChat;
  workspaceMembers: Member[];
  onPress: () => void;
}
export function AiChatItem({chat, workspaceMembers, onPress}: Props) {
  const date = parseISO(chat.createdAt);
  const messageCount = chat.messageCount;
  // const isPrivate = chat.privacy.visibility === 'private';
  const isPrivate = false;
  const isActive = false;
  return (
    <Pressable
      style={[styles.container, isActive && styles.backgroundActive]}
      onPress={onPress}>
      <View style={styles.titleRow}>
        <Typography
          size="sm"
          variant="medium"
          color={theme.colors.neutral.$700}>
          {chat.name}
        </Typography>
        <Popup
          triggerElement={
            <Icon
              name="lucide:more-horizontal"
              color={theme.colors.neutral.$700}
            />
          }>
          <View style={styles.popupContent} />
        </Popup>
      </View>
      <View style={styles.detailsRow}>
        <AiChatMembers chatId={chat.id} workspaceMembers={workspaceMembers} />
        <TimeLabel date={date} />
        <MessagesLabel messagesCount={messageCount} />
        <View style={styles.spacer} />
        {isPrivate && <AiChatPrivateLabel />}
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: 12,
    borderRadius: 8,
    backgroundColor: theme.colors.neutral.$50,
  },
  backgroundActive: {
    backgroundColor: theme.colors.brand.$75,
  },
  titleRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  detailsRow: {
    marginTop: 12,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  popupContent: {
    padding: 12,
    borderRadius: 8,
    gap: 16,
    backgroundColor: theme.colors.neutral.$50,
  },
  spacer: {
    flex: 1,
  },
});
