import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import type WebSocketConnection from 'fast-sdk/src/websockets/WebSocketConnection';
import {
  type ActivityResponse,
  type WSResponse,
  WebSocketCategory,
  WebSocketResponseTypes,
} from 'fast-sdk/src/websockets/types';
import store from 'store';
import * as activity from 'store/slices/activity';
import {
  type ActivityTypeWithTimestamp,
  type EntityActivities,
  type EntityType,
  entityTypeToStoreKey,
} from 'store/slices/activity/types';
import WebSocketConnectionManager from './WebSocketConnectionManager';
import {
  ACTIVITY_MESSAGE_DELAY,
  DEFAULT_POLLING_WAIT_TIME,
  MAX_POLLING_DELAY,
  POLLING_INTERVAL_DELAY,
  WEBSOCKET_CATEGORIES_CONFIG,
} from './config';
import {CommunicationMode, type EntityActivityHandlerOptions} from './types';
import {pollEntityActivity} from './utils';

class EntityActivityHandler<T extends ActivityResponse> {
  private wsManager: WebSocketConnectionManager;
  private currentMode: CommunicationMode = CommunicationMode.WebSocket;
  private webSocketCategory: WebSocketCategory;
  private ws: WebSocketConnection<T> | null = null;
  private pollingWaitTime: number;
  private pollingTimeoutId: NodeJS.Timeout | null = null;
  private entityId: string;
  private socketKey: string;
  private entityType: EntityType;
  private isPolling = false;
  private pollingErrorCount = 0;

  public constructor(options: EntityActivityHandlerOptions) {
    this.wsManager = WebSocketConnectionManager.getInstance();
    this.webSocketCategory = WebSocketCategory.Activity;
    this.pollingWaitTime = options.pollingWaitTime || DEFAULT_POLLING_WAIT_TIME;
    this.entityId = options.entityId;
    this.entityType = options.entityType;
    this.socketKey = `${this.webSocketCategory}-${this.entityId}`;
  }

  public startCommunication(): void {
    this.startWebSocket();
  }

  public endCommunication(): void {
    this.wsManager.closeConnection(this.socketKey);
    this.stopPolling();
  }

  private startWebSocket(): void {
    if (this.ws) {
      this.ws.connect();
    } else {
      const webSocketCategory = WebSocketCategory.Activity;
      const {connector, fetchAuth} =
        WEBSOCKET_CATEGORIES_CONFIG[webSocketCategory];

      try {
        this.ws = this.wsManager.getOrCreateWebSocketConnection(
          this.socketKey,
          connector,
          {
            webSocketCategory,
            onMessage: message =>
              this.handleMessage(message as WSResponse<ActivityResponse>),
            onClose: (_, manualClose) => !manualClose && this.switchToPoll(),
            onReady: () => this.switchToWebSocket(),
            fetchAuth: (isExpired: boolean) =>
              fetchAuth(this.entityId, isExpired),
          },
        );
      } catch (error) {
        handleAppError({
          appError: AppErrors.EntityWebSocketConnectionError,
          exception: error,
        });
        this.switchToPoll();
      }
    }
  }

  private switchToPoll(): void {
    if (this.currentMode === CommunicationMode.Polling) return;

    this.currentMode = CommunicationMode.Polling;
    this.startPolling();
  }

  private switchToWebSocket(): void {
    if (this.currentMode === CommunicationMode.WebSocket) return;

    this.currentMode = CommunicationMode.WebSocket;
    this.stopPolling();
  }

  private startPolling(): void {
    if (!this.isPolling) {
      this.isPolling = true;
      this.poll();
    }
  }

  private calculatePollingDelay(baseInterval: number): number {
    if (baseInterval <= 0) {
      throw new Error('Base interval must be positive');
    }

    if (this.pollingErrorCount <= 0) {
      return baseInterval;
    }

    // Calculate exponential backoff
    const backoffMultiplier = 2 ** this.pollingErrorCount;
    const delayWithBackoff = baseInterval * backoffMultiplier;

    return Math.min(delayWithBackoff, MAX_POLLING_DELAY);
  }

  private doNextPoll(wasSuccess: boolean): void {
    if (!wasSuccess) {
      this.pollingErrorCount++;
    } else {
      this.pollingErrorCount = 0;
    }
    const delay = this.calculatePollingDelay(POLLING_INTERVAL_DELAY);
    this.pollingTimeoutId = setTimeout(() => this.poll(), delay);
  }

  private async poll(): Promise<void> {
    if (!this.isPolling) return;

    let wasSuccess = false;
    try {
      const targetEntity = entityTypeToStoreKey[this.entityType];
      if (targetEntity) {
        const {lastActivity} = (store.getState().activity[targetEntity][
          this.entityId
        ] ?? {}) as EntityActivities;

        const response = await pollEntityActivity(
          this.entityId,
          this.pollingWaitTime,
          lastActivity,
        );

        if (response.result) {
          this.handleMessage({
            ...response,
            time: response.lastactivity,
            response: WebSocketResponseTypes.ACTIVITY_CURRENT,
          });
          wasSuccess = true;
        } else {
          wasSuccess = false;
        }
      }
    } catch (error) {
      handleAppError({
        appError: AppErrors.PollingError,
        exception: error,
      });
      wasSuccess = false;
    } finally {
      this.doNextPoll(wasSuccess);
    }
  }

  private stopPolling(): void {
    this.isPolling = false;
    if (this.pollingTimeoutId) {
      clearTimeout(this.pollingTimeoutId);
      this.pollingTimeoutId = null;
    }
  }

  private handleMessage(wsResponse: WSResponse<ActivityResponse>): void {
    setTimeout(() => {
      let activitiesWithTimestamp: ActivityTypeWithTimestamp = {};
      if (wsResponse.response === WebSocketResponseTypes.ACTIVITY) {
        const {activity: activitiesTypes} = wsResponse;
        const timestamp = wsResponse.time;
        activitiesWithTimestamp = activitiesTypes.reduce(
          (acc, activityType) => {
            acc[activityType] = {
              lastServerUpdate: timestamp,
            };
            return acc;
          },
          {},
        );
      } else if (
        wsResponse.response === WebSocketResponseTypes.ACTIVITY_CURRENT
      ) {
        const {activity: activities} = wsResponse;
        activitiesWithTimestamp = Object.entries(activities).reduce(
          (acc, [activityType, timestamp]) => {
            acc[activityType] = {
              lastServerUpdate: timestamp,
            };
            return acc;
          },
          {},
        );
      }
      if (Object.keys(activitiesWithTimestamp).length > 0) {
        store.dispatch(
          activity.default.actions.setEntityActivities({
            entityId: this.entityId,
            entityType: this.entityType,
            lastActivity: wsResponse.time,
            activities: activitiesWithTimestamp,
          }),
        );
      }
    }, ACTIVITY_MESSAGE_DELAY);
  }
}

export default EntityActivityHandler;
