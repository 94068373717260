import {AppState} from 'react-native';

export function activate(store: any) {
  return {
    initActivate: (state: any) => {
      state.dispatch(store.app.actions.activate({active: true}));
      AppState.addEventListener('change', status => {
        state.dispatch(
          store.app.actions.activate({
            active: status === 'active' || status === 'background',
          }),
        );
      });
    },
  };
}
