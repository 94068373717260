import theme from 'config/theme';
import {format} from 'date-fns';
import {InvitationStatuses} from 'fast-sdk/src/api/share/consts';
import {Loading} from 'interface/common/Loading';
import PermissionDropdown from 'interface/stacks/settings/components/PermissionDropdown';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import type {Invite, Member} from 'store/slices/settings/types';
import {useMemberActions} from '../../hooks/useMemberActions';
import {
  type Permission,
  useMemberPermissions,
} from '../../hooks/useMemberPermissions';
import {MemberInfo} from './MemberInfo';
import {MemberMenu} from './MemberMenu';

interface MemberTableRowProps {
  memberOrInvite: Member | Invite;
  color: string;
  currentMember: Member;
  onUpdate: () => void;
}

export const MemberTableRow = ({
  memberOrInvite,
  color,
  currentMember,
  onUpdate,
}: MemberTableRowProps) => {
  const isInvite =
    'state' in memberOrInvite &&
    memberOrInvite.state === InvitationStatuses.Pending;

  const initialPermission = (
    'permissions' in memberOrInvite ? memberOrInvite.permissions : 'view'
  ) as Permission;

  const {permission, isLoading, updatePermission} = useMemberPermissions(
    initialPermission,
    memberOrInvite.email,
  );

  const {handleAction} = useMemberActions(
    memberOrInvite.email,
    isInvite,
    memberOrInvite.id,
    permission,
    onUpdate,
  );

  const isLoggedOwner = currentMember.permissions === 'owner';
  const isLoggedAdmin = currentMember.permissions === 'admin';
  const isOwner = permission === 'owner';
  const isMe = memberOrInvite.id === currentMember.id;

  const dateJoined =
    'created' in memberOrInvite
      ? memberOrInvite.created
      : memberOrInvite.dateJoined;

  const inviter = 'inviter' in memberOrInvite ? memberOrInvite.inviter : '--';

  return (
    <View style={styles.row}>
      <MemberInfo
        isInvite={isInvite}
        firstName={
          'firstName' in memberOrInvite ? memberOrInvite.firstName : ''
        }
        lastName={'lastName' in memberOrInvite ? memberOrInvite.lastName : ''}
        email={memberOrInvite.email}
        profilePic={
          'profilePic' in memberOrInvite ? memberOrInvite.profilePic : ''
        }
        color={color}
        state={'state' in memberOrInvite ? memberOrInvite.state : undefined}
        id={memberOrInvite.id}
        isMe={isMe}
      />
      <View style={styles.permissions}>
        {isLoading ? (
          <Loading size="small" customStyle={styles.loading} />
        ) : !isInvite ? (
          <PermissionDropdown
            member={memberOrInvite}
            permission={permission}
            onChangePermission={updatePermission}
            loggedMember={currentMember}
          />
        ) : null}
      </View>
      <Text style={styles.addedBy}>{inviter}</Text>
      <Text style={styles.date}>
        {dateJoined ? format(new Date(dateJoined), 'MMM dd, yyy') : '--'}
      </Text>
      {(isLoggedOwner || isLoggedAdmin) && !isOwner && (
        <MemberMenu isInvite={isInvite} isMe={isMe} onAction={handleAction} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$12,
    zIndex: 1,
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '35%',
  },
  name: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
  },
  avatar: {
    marginRight: 9,
  },
  email: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    width: '25%',
  },
  permissions: {
    width: '15%',
    display: 'flex',
    flexDirection: 'row',
  },
  loading: {
    marginLeft: 24,
  },
  auth: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    width: '15%',
  },
  addedBy: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    width: '25%',
  },
  date: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    width: '15%',
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10%',
  },
  subtitle: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
    color: theme.colors.neutral.$2Base,
  },
  external: {
    marginLeft: 9,
  },
});
