import theme from 'config/theme';
import React from 'react';
import {StyleSheet} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import ActionButton from '../ActionButton';

type Props = {
  handleAdjustHorizontal: () => void;
};

const AdjustHorizontalButton = ({handleAdjustHorizontal}: Props) => {
  return (
    <ActionButton
      icon={
        <Icon
          name="expand"
          size={20}
          color={theme.colors.neutral.$white}
          style={styles.icon}
        />
      }
      onPress={handleAdjustHorizontal}
      tooltipText="Expand Horizontally"
    />
  );
};

const styles = StyleSheet.create({
  icon: {
    transform: [{rotate: '90deg'}],
  },
});

export default AdjustHorizontalButton;
