import {useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import {ProfileMemberListInvite} from './ProfileMemberListInvite';
import {ProfileMemberListUser} from './ProfileMemberListUser';

import {NoResultsAvailable} from 'interface/common/NoResultsAvailable';
import type {
  ProfileInvite,
  ProfileInvitesList,
  ProfileMember,
  ProfileMembersList,
} from './profile.types';

export interface ProfileMemberListProps {
  type: 'workspace' | 'share';
  profileId?: string;
  selfId?: string;
  members?: ProfileMembersList;
  invites?: ProfileInvitesList;
  subdomain?: string;
  customName?: string;
  reload?: () => void;
  emptyMessage?: string;
  showDotsMenu?: boolean;
}

export function ProfileMemberList(props: ProfileMemberListProps) {
  const self = props.members?.find(
    (member: ProfileMember) => member.id === props.selfId,
  );
  const members = useMemo(() => props.members?.reverse(), [props.members]);
  const invites = useMemo(() => props.invites?.reverse(), [props.invites]);

  const isEmpty = !invites?.length && !members?.length;

  return (
    <View style={styles.root}>
      {invites?.map((invite: ProfileInvite, index: number) => (
        <ProfileMemberListInvite
          key={invite.id}
          type={props.type}
          profileId={props.profileId}
          invite={invite}
          self={self}
          isLast={index === 0}
          reload={props.reload}
          subdomain={props.subdomain}
          showDotsMenu={props.showDotsMenu}
        />
      ))}
      {members?.map((member: ProfileMember, index: number) => (
        <ProfileMemberListUser
          key={member.id}
          type={props.type}
          profileId={props.profileId}
          user={member}
          self={self}
          isLast={index === 0 && !invites?.length}
          reload={props.reload}
          subdomain={props.subdomain}
          showDotsMenu={props.showDotsMenu}
        />
      ))}
      {isEmpty && props.emptyMessage && (
        <NoResultsAvailable message={props.emptyMessage} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
});
