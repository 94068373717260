import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {Loading} from 'interface/common/Loading';
import {StyleSheet, Text, View} from 'react-native';

interface UpgradeDowngradeLoadingProps {
  callBack: () => void;
}

export function CancelLoading(props: UpgradeDowngradeLoadingProps) {
  return (
    <View style={styles.root}>
      <Loading
        size="large"
        customColor={theme.colors.success.$4Base}
        customStyle={{width: 64, height: 64, marginBottom: '1rem'}}
      />
      <Text selectable style={styles.text}>
        <Trans>Processing, please wait…</Trans>
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 650,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 14,
    shadowColor: theme.colors.neutral.$black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3.05,
    elevation: 4,
  },
  text: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
  },
});
