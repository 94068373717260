import commonEmails from 'email-providers/common.json';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import {api} from 'fast-sdk';
import {MemberManagePerm} from 'fast-sdk/src/api/organization/consts';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import type {BillingPlan} from '../lib/types';
import useSubscriptionStatus from './useSubscriptionStatus';

type Props = {
  plan: BillingPlan;
};

export default function useCreateOrg({plan}: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  const [intentClientSecret, setIntentClientSecret] = useState<string>('');
  const [stripeKey, setStripeKey] = useState<string>('');

  const clientSecret = useSelector(onboarding.selectors.getIntentClientSecret);
  const orgName = useSelector(onboarding.selectors.getOrganizationName);
  const subdomain = useSelector(onboarding.selectors.getSubdomain);
  const userDetails = useSelector(user.selectors.getUserDetails);
  const createdOrg = useSelector(onboarding.selectors.getCreatedOrganizationId);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {isSubscribed} = useSubscriptionStatus();

  useEffect(() => {
    async function createOrg() {
      const emailProvider = userDetails.email_address.split('@')[1];
      const authDomain = commonEmails.includes(emailProvider)
        ? null
        : emailProvider;

      try {
        const createOrgResponse = await api.organization.createOrganization({
          name: orgName,
          domain: subdomain,
          billing_plan: plan.name,
          billing_email: userDetails.email_address,
          perm_auth_domains: authDomain,
          perm_join: MemberManagePerm['Member or above'],
        });

        if (!createOrgResponse.result) {
          handleAppError({
            appError: AppErrors.CreateOrgError,
            dialogOptions: {
              message: 'Error creating organization',
              title: 'proceeding to checkout',
            },
          });
          setLoading(false);
          return navigate(-1);
        }

        const {id} = createOrgResponse.org;

        const createSubResponse = await api.billing.createOrUpdateSubscription(
          id,
          plan.name,
        );

        if (!createSubResponse.result) {
          handleAppError({
            appError: AppErrors.CreateOrUpdateSubError,
            dialogOptions: {
              message: 'Error creating subscription',
              title: 'proceeding to checkout',
            },
          });
          setLoading(false);
          return navigate(-1);
        }

        const {
          billing_status: {
            public_key,
            setup_intent: {client_secret} = {},
          },
        } = createSubResponse;

        if (!public_key || !client_secret) {
          handleAppError({
            appError: AppErrors.CreateOrUpdateSubError,
            dialogOptions: {
              message: 'Error getting publick key or intent',
              title: 'proceeding to checkout',
            },
          });
          setLoading(false);
          return navigate(-1);
        }

        setIntentClientSecret(client_secret);
        setStripeKey(public_key);

        dispatch(onboarding.default.actions.setCreatedOrganizationId({id}));
        dispatch(
          onboarding.default.actions.setIntentClientSecret({
            intentClientSecret: client_secret,
          }),
        );
        dispatch(
          onboarding.default.actions.setPublicKey({
            key: public_key,
          }),
        );
      } catch (error) {
        navigate(-1);
      } finally {
        setLoading(false);
      }
    }

    if (plan && !createdOrg && !isSubscribed) {
      createOrg();
      return;
    }

    setLoading(false);
  }, [plan, clientSecret, createdOrg, isSubscribed]);

  return {loading, clientSecret: intentClientSecret || clientSecret, stripeKey};
}
