import {createSelector} from '@reduxjs/toolkit';
import type {Store} from 'store';

const x = (store: Store) => store;

export const getSubDomain = createSelector(
  x,
  store => store.settings.organization.general.subDomain,
);
export const getTimeZone = createSelector(
  x,
  store => store.settings.organization.general.timeZone,
);
export const getOrganizationName = createSelector(
  x,
  store => store.settings.organization.branding.name,
);
export const getOrganizationLogo = createSelector(
  x,
  store => store.settings.organization.branding.logo,
);
export const getOrganizationDescription = createSelector(
  x,
  store => store.settings.organization.branding.description,
);
export const getOrganizationSocialLinks = createSelector(
  x,
  store => store.settings.organization.branding.socialLinks,
);
export const getMembers = createSelector(x, store => store.settings.members);
export const getPlanInfo = createSelector(
  x,
  store => store.settings.billing.plan,
);
export const getSeatsInfo = createSelector(
  x,
  store => store.settings.billing.seats,
);
export const getOwnerInfo = createSelector(
  x,
  store => store.settings.billing.owner,
);
export const getBilledDuration = createSelector(
  x,
  store => store.settings.billing.plan.billedDuration,
);
