import theme from 'config/theme';
import React from 'react';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import ActionButton from '../ActionButton';

type Props = {
  fullscreen: boolean;
  toggleFullscreen: () => void;
};

const FullscreenButton = ({fullscreen, toggleFullscreen}: Props) => {
  return (
    <ActionButton
      icon={
        <Icon
          name={fullscreen ? 'fullscreen-exit' : 'fullscreen'}
          size={20}
          color={theme.colors.neutral.$white}
        />
      }
      onPress={toggleFullscreen}
      tooltipText={fullscreen ? 'Exit full screen' : 'Full screen'}
    />
  );
};

export default FullscreenButton;
