import {t} from '@lingui/macro';
import {Button} from 'interface/common/Button';
import {TextInput} from 'interface/common/TextInput';
import {Layout} from 'interface/stacks/auth/Layout';
import {useEffect, useState} from 'react';
import {StyleSheet, View, useWindowDimensions} from 'react-native';
import {validateEmail} from 'utils/common/validation';

interface Props {
  setEmail: (email: string) => void;
}

export function Email(props: Props) {
  const [email, setEmail] = useState<string>();
  const [enableButton, setEnableButton] = useState<boolean>(false);

  useEffect(() => {
    if (email) {
      if (validateEmail(email)) setEnableButton(true);
      else setEnableButton(false);
    } else setEnableButton(false);
  }, [email]);

  const submitEmail = (email: string) => props.setEmail(email);

  const dimension = useWindowDimensions();

  return (
    <View
      style={[
        styles.root,
        {width: dimension.width > 790 ? 380 : dimension.width - 40},
      ]}>
      <Layout
        title="Sign in to Fast.io"
        subTitle="Enter your email address to continue."
        linkDescription="Need an account?"
        linkText="Sign Up"
        linkTo="/signup"
        buttonsMarginTop={21}>
        <form
          onSubmit={e => {
            e.preventDefault();
            if (validateEmail(email)) submitEmail(email);
          }}
          style={{width: '100%'}}>
          <TextInput
            placeHolder="Enter your email address"
            setInputValue={setEmail}
            customStyle={styles.input}
            value={email}
          />
          <Button
            label={t`Continue`}
            onPress={() => submitEmail(email)}
            disabled={!enableButton}
            type={'Primary'}
            customRootStyle={{width: '100%'}}
            customTextStyle={styles.buttonText}
          />
        </form>
      </Layout>
    </View>
  );
}
const styles = StyleSheet.create({
  root: {
    margin: 'auto',
    marginTop: 0,
  },
  input: {
    width: '100%',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    marginBottom: '1.5rem',
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
});
