import Skeleton from 'interface/common/Skeleton';
import {StyleSheet, View} from 'react-native';

const SHARE_LIST_SKELETON_ROWS = 3;

export function ShareListSkeleton() {
  return (
    <>
      {[...Array(SHARE_LIST_SKELETON_ROWS)].map((_, index) => (
        <View key={index} style={styles.shareItem}>
          <Skeleton height={21} width={21} />
          <Skeleton height={18} width={200} />
        </View>
      ))}
    </>
  );
}

const styles = StyleSheet.create({
  shareItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    paddingHorizontal: 8,
    gap: 6,
  },
});
