import theme from 'config/theme';
import type {ShareType} from 'fast-sdk/src/api/share/consts';
import {Icon} from 'interface/base/Icon';
import {StyleSheet, View} from 'react-native';
import {ShareTypeColor, ShareTypeIcon} from 'utils/fast/shares';

interface Props {
  shareType: ShareType;
  size?: number;
}

export function ShareTypeBadge({shareType, size = 18}: Props) {
  const backgroundColor = ShareTypeColor[shareType];
  const icon = ShareTypeIcon[shareType];

  return (
    <View style={[styles.shareItemIcon, {backgroundColor}]}>
      <Icon name={icon} size={size} color={theme.colors.neutral.$2Base} />
    </View>
  );
}

const styles = StyleSheet.create({
  shareItemIcon: {
    padding: 3,
    borderRadius: 4,
  },
});
