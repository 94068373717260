import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {NavigateTo, useLogout} from './useLogout';

export const useCheckForceLogout = () => {
  const dispatch = useDispatch();
  const {logout} = useLogout();

  const forceLogout = useSelector(app.selectors.getForceLogout);
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  useEffect(() => {
    if (forceLogout) {
      try {
        logout({
          navigateTo: isLoggedIn ? NavigateTo.LOGOUT : NavigateTo.SIGNIN,
        });
      } finally {
        dispatch(app.default.actions.setForceLogout(false));
      }
    }
  }, [forceLogout, isLoggedIn]);
};
