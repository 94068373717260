import {t} from '@lingui/macro';
import {useModal} from 'extensions/viewport/useModal';
import {Prompt} from 'interface/common/Prompt';
import {useCallback} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {createFolder} from 'utils/fast/storage';

import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {ModalSize} from 'interface/common/Modal';

export function useModalCreateFolder(
  folderId: string,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  const modal = useModal();
  const toast = useToast();

  const submit = useCallback(
    async (value: string) => {
      if (value) {
        const res = await createFolder(value, folderId, instanceId, instanceNs);
        if (res.result && res?.node?.name) {
          toast.show(t`Created the folder “${res?.node?.name}”`);
        } else {
          toast.show(res?.error?.text ?? t`Failed to create folder`, {
            type: 'danger',
          });
        }
      }
      modal.close();
    },
    [folderId, instanceId, instanceNs],
  );

  const open = useCallback(() => {
    modal.open(
      <Prompt
        size={ModalSize.SMALL}
        onClose={modal.close}
        title={t`Create Folder`}
        text={t`Give this folder a name`}
        options={{
          type: 'plain-text',
          placeholder: t`Folder name`,
        }}
        buttons={[
          {
            variant: 'secondary',
            text: t`Cancel`.toUpperCase(),
            onPress: modal.close,
          },
          {
            variant: 'primary',
            text: t`Ok`.toUpperCase(),
            onPress: submit,
          },
        ]}
      />,
    );
  }, [submit]);

  return {open, close: modal.close};
}
