export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

type SetCookieOptions = {
  name: string;
  value: string;
  age?: number;
  domain: string;
  protocol?: string;
  expires?: string;
  path?: string;
};

export function setCookie({
  name,
  value,
  age = 3600,
  domain,
  protocol,
  expires,
  path = '/',
}: SetCookieOptions) {
  const cookie = new Cookie(name, value);

  cookie
    .setPath(path)
    .setDomain(`.${domain}`)
    .setMaxAge(age)
    .setExpires(expires)
    .setSecure(protocol === 'https')
    .apply();
}

class Cookie {
  private cookie = '';

  constructor(key: string, value: string) {
    this.cookie = `${key}=${value};`;
  }

  setPath(path: string) {
    this.cookie += ` path=${path};`;
    return this;
  }

  setDomain(domain: string) {
    this.cookie += ` domain=${domain};`;
    return this;
  }

  setMaxAge(maxAge: number) {
    this.cookie += ` Max-Age=${maxAge};`;
    return this;
  }

  setSecure(secure = false) {
    if (secure) {
      this.cookie += ' Secure;';
    }
    return this;
  }

  setSameSite(sameSite: 'None' | 'Lax' | 'Strict') {
    this.cookie += ` SameSite=${sameSite};`;
    return this;
  }

  setExpires(expires?: string) {
    if (expires) {
      this.cookie += ` expires=${expires};`;
    }
    return this;
  }

  apply() {
    if (this.cookie) {
      document.cookie = this.cookie;
    }
  }
}
