import Logo from 'assets/fastio_logo_assets/mark_color_48px.svg';
import theme from 'config/theme';
import {Image, StyleSheet, View} from 'react-native';

export default function LoadingImage() {
  return (
    <View style={styles.iconWrapper}>
      <Image source={{uri: Logo}} style={styles.logo} />
    </View>
  );
}

const styles = StyleSheet.create({
  iconWrapper: {
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 8,
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, .04), 0 3px 6px 0 rgba(0, 0, 0, .07)',
  },
  logo: {
    width: 48,
    height: 48,
  },
});
