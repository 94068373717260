import React, {useCallback} from 'react';
import {StyleSheet, View} from 'react-native';
import FeaturesSection from '../components/FeaturesSection';
import LinkForm from '../components/LinkForm';
import LinksSection from '../components/LinksSection';
import type {ShareState} from '../hooks/useShareActions';

interface ShareDesignProps {
  shareState: ShareState;
}

export function ShareDesign({shareState}: ShareDesignProps) {
  const {
    links,
    updateLink,
    removeLink,
    allowComments,
    setAllowComments,
    displayType,
    setDisplayType,
    currentFormLink,
    setCurrentFormLink,
    intelligence,
    setIntelligence,
  } = shareState;

  const addLink = useCallback(() => {
    const nextId =
      (links
        .map(link => link.id)
        .sort()
        .pop() ?? 0) + 1;
    const newLink = {id: nextId, title: 'Our website', url: ''};
    setCurrentFormLink(newLink);
  }, [links]);

  const saveLink = useCallback(() => {
    if (currentFormLink) {
      updateLink(currentFormLink);
      setCurrentFormLink(undefined);
    }
  }, [currentFormLink, updateLink]);

  const removeAndCancelLink = useCallback(() => {
    if (currentFormLink) {
      removeLink(currentFormLink);
      setCurrentFormLink(undefined);
    }
  }, [currentFormLink, removeLink]);

  const onInfoPress = useCallback(() => {
    console.log('shared info');
  }, []);

  const isDraft = !links.find(link => link.id === currentFormLink?.id);

  const linksToDisplay = links.some(link => link.id === currentFormLink?.id)
    ? links.map(link =>
        link.id === currentFormLink?.id ? currentFormLink : link,
      )
    : [...links, currentFormLink].filter(Boolean);

  return (
    <View style={styles.container}>
      <LinksSection
        links={linksToDisplay}
        currentLink={currentFormLink}
        setCurrentLink={setCurrentFormLink}
        addLink={addLink}
        onInfoPress={onInfoPress}
      />
      {currentFormLink ? (
        <LinkForm
          currentLink={currentFormLink}
          setCurrentLink={setCurrentFormLink}
          saveLink={saveLink}
          removeAndCancelLink={removeAndCancelLink}
          isDraft={isDraft}
        />
      ) : (
        <FeaturesSection
          allowComments={allowComments}
          setAllowComments={setAllowComments}
          displayType={displayType}
          setDisplayType={setDisplayType}
          intelligence={intelligence}
          setIntelligence={setIntelligence}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 21,
  },
});
