import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';
import {useMemo} from 'react';
import {OrganizationLogo} from '../components/OrganizationLogo';

export function useGetAuthHeaderLogo(organization: OrganizationDetails) {
  const headerLogo = useMemo(() => {
    if (organization) {
      return <OrganizationLogo organization={organization} />;
    }
  }, [organization]);

  return {headerLogo};
}
