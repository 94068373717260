import CommentButton from 'interface/stacks/content-viewer/components/control-bar/CommentButton';
import FullscreenButton from 'interface/stacks/content-viewer/components/control-bar/FullscreenButton';
import useAudio from 'interface/stacks/content-viewer/hooks/useAudio';
import useContentViewerMode from 'interface/stacks/content-viewer/hooks/useContentViewerMode';
import AudioTimeDisplay from '../AudioTimeDisplay';

const ControlsRight = () => {
  const {isThumbnail} = useContentViewerMode();
  const {audio, handler} = useAudio();

  const toggleFullscreen = () => handler.toggleFullscreen();

  return (
    <>
      {!isThumbnail && <CommentButton />}
      {isThumbnail && <AudioTimeDisplay />}
      <FullscreenButton
        fullscreen={audio.fullscreen}
        toggleFullscreen={toggleFullscreen}
      />
    </>
  );
};

export default ControlsRight;
