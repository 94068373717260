import React from 'react';
import {Pressable} from 'react-native';
import MediaText from './MediaText';

type Props = {
  zoom: number;
  handleLastZoom: () => void;
};

export default function ZoomTextButton({zoom, handleLastZoom}: Props) {
  return (
    <Pressable onPress={handleLastZoom}>
      <MediaText>{(zoom * 100).toFixed(1)}%</MediaText>
    </Pressable>
  );
}
