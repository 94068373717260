import {t} from '@lingui/macro';
import {Loading} from 'interface/common/Loading';
import {NoResultsAvailable} from 'interface/common/NoResultsAvailable';
import {FileList} from 'interface/stacks/workspace/storage/FileList';
import {useMemo} from 'react';
import {StyleSheet, View} from 'react-native';

import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';
import type {FilesData} from '../../hooks/useFetchShareFiles';

interface Props {
  share: Share;
  filesData: FilesData;
  handleFileOpen: (file: FilesItem) => void;
  canManageFiles: boolean;
  isTrashView: boolean;
}

export function FileItems({
  share,
  filesData,
  handleFileOpen,
  canManageFiles,
  isTrashView,
}: Props) {
  const options = useMemo(() => {
    const baseOptions = {
      hideGutter: true,
      disableFocus: true,
      disableSelect: true,
      disableActions: true,
    };

    const manageFilesOptions = canManageFiles
      ? {}
      : {
          disableDrag: true,
          disableDetails: true,
          disablePullToRefresh: true,
        };
    return {...baseOptions, ...manageFilesOptions};
  }, [canManageFiles]);

  return (
    <View style={styles.root}>
      <FileList
        shareView
        share={share}
        root={['root']}
        view={filesData?.filesIds}
        items={filesData?.files}
        layout={share.currentLayout}
        onOpen={handleFileOpen}
        options={options}
        renderEmpty={() => (
          <NoResultsAvailable
            message={
              isTrashView
                ? t`Share trash is empty.`
                : t`No folders or files added yet.`
            }
          />
        )}
        renderLoading={() => (
          <View style={styles.loading}>
            <Loading />
          </View>
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
  },
  loading: {
    flex: 1,
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
