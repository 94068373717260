import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Avatar} from 'interface/common/Avatar';
import {Button} from 'interface/common/Button';
import {TextInput} from 'interface/common/TextInput';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {getHashColor} from 'utils/common/color';

export interface FileIconProps {
  setInputValue: (inp: string) => void;
  setReplyFalse: (inp: boolean) => void;
  reply?: boolean;
}

export function CommentTextInput(props: FileIconProps) {
  const [comment, setComment] = useState<string>();

  const userDetails = useSelector(user.selectors.getUserDetails);
  const initials =
    userDetails.first_name && userDetails.last_name
      ? `${userDetails.first_name[0]}${userDetails.last_name[0]}`
      : '';

  const underline = () => {};
  const openEmojis = () => {};
  const sendReply = () => {};

  return props.reply ? (
    <View style={styles.root}>
      <View style={styles.replyContainer}>
        <Avatar
          initials={initials}
          color={userDetails.color || getHashColor(userDetails.id)}
          photo={userDetails.profile_pic}
        />
        <TextInput
          placeHolder="Add a reply..."
          value={comment}
          setInputValue={setComment}
          customStyle={styles.commentInput}
        />
      </View>
      <View style={styles.actions}>
        <View style={styles.textRelated}>
          <Button
            type="Text"
            lIcon={
              <Icon
                name="format-underline"
                size={20}
                color={theme.colors.neutral.$4}
              />
            }
            onPress={underline}
          />
          <Button
            type="Text"
            onPress={openEmojis}
            lIcon={
              <Icon
                name="insert-emoticon"
                size={20}
                color={theme.colors.neutral.$4}
              />
            }
          />
        </View>
        <View style={styles.buttons}>
          <Button
            type="Text"
            label={t`Cancel`}
            onPress={() => props.setReplyFalse(false)}
            customTextStyle={styles.textButton}
          />
          <Button type="Primary" label={t`Reply`} onPress={sendReply} />
        </View>
      </View>
    </View>
  ) : (
    <form
      onSubmit={e => {
        props.setInputValue(comment);
        e.preventDefault();
      }}
      style={{width: '100%'}}>
      <View style={styles.commentContainer}>
        <Avatar
          initials={initials}
          color={userDetails.color || getHashColor(userDetails.id)}
          photo={userDetails.profile_pic}
        />
        <TextInput
          placeHolder="Add a comment..."
          value={comment}
          setInputValue={setComment}
          customStyle={styles.commentInput}
        />
      </View>
    </form>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    minHeight: 160,
    borderWidth: 3,
    borderColor: theme.colors.brand.$4Base,
    borderRadius: 15,
    paddingHorizontal: 15,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  commentContainer: {
    width: '100%',
    borderWidth: 1,
    borderColor: theme.colors.neutral.$10,
    borderRadius: 15,
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    height: 60,
    marginBottom: 15,
  },
  replyContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    height: 60,
    marginBottom: 15,
  },
  commentInput: {
    borderWidth: 0,
    borderBottomWidth: 0,
    backgroundColor: 'none',
    height: '100%',
    marginBottom: 0,
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 15,
  },
  textRelated: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textButton: {
    color: theme.colors.neutral.$4,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
