import type {ViewStyle} from 'react-native';
import {
  SIZE as TYPOGRAPHY_SIZE,
  VARIANT as TYPOGRAPHY_VARIANT,
} from '../Typography';
import type {ButtonStateStyles, TextProps, TextStateStyles} from './types';

export const SIZE = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
} as const;

export const VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
  text: 'text',
} as const;

type Size = keyof typeof SIZE;
type Variant = keyof typeof VARIANT;

export const SIZES: Record<Size, ViewStyle> = {
  sm: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    minHeight: 24,
  },
  md: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    minHeight: 32,
  },
  lg: {
    paddingHorizontal: 26,
    paddingVertical: 13,
    minHeight: 40,
  },
};

// TODO: replace hardcoded colors with unistyles or new theme file
export const VARIANTS: Record<Variant, ButtonStateStyles> = {
  primary: {
    default: {
      light: {
        backgroundColor: '#2338F6', // Brand_1/500
      },
      dark: {
        backgroundColor: '#fff', // Neutral/0
      },
    },
    hover: {
      light: {
        backgroundColor: '#0A10D0', // Brand_1/600
      },
      dark: {
        backgroundColor: '#fff', // Neutral/0
      },
    },
    focus: {
      light: {
        backgroundColor: '#2338F6', // Brand_1/500
      },
      dark: {
        backgroundColor: '#fff', // Neutral/0
      },
    },
    disabled: {
      light: {},
      dark: {},
    },
  },
  secondary: {
    default: {
      light: {
        backgroundColor: '#F4F4F5', // Neutral/75
      },
      dark: {
        backgroundColor: '#222835', // Neutral/700
      },
    },
    hover: {
      light: {
        backgroundColor: '#E9EAEC', // Neutral/100
      },
      dark: {
        backgroundColor: '#FFFFFF33', // Neutral/0 + 20% transparency
      },
    },
    focus: {
      light: {
        backgroundColor: '#fff', // Neutral/0
      },
      dark: {
        backgroundColor: '#222835', // Neutral/700
      },
    },
    disabled: {
      light: {},
      dark: {},
    },
  },
  text: {
    default: {
      light: {
        backgroundColor: 'transparent',
      },
      dark: {
        backgroundColor: 'transparent',
      },
    },
    hover: {
      light: {
        backgroundColor: 'transparent',
      },
      dark: {
        backgroundColor: 'transparent',
      },
    },
    focus: {
      light: {
        backgroundColor: 'transparent',
      },
      dark: {
        backgroundColor: 'transparent',
      },
    },
    disabled: {
      light: {
        backgroundColor: 'transparent',
      },
      dark: {
        backgroundColor: 'transparent',
      },
    },
  },
};

export const TEXT_SIZES: Record<Size, TextProps> = {
  sm: {
    variant: TYPOGRAPHY_VARIANT.regular,
    size: TYPOGRAPHY_SIZE.xs,
  },
  md: {
    variant: TYPOGRAPHY_VARIANT.regular,
    size: TYPOGRAPHY_SIZE.xs,
  },
  lg: {
    variant: TYPOGRAPHY_VARIANT.regular,
    size: TYPOGRAPHY_SIZE.sm,
  },
};

export const TEXT_VARIANTS: Record<Variant, TextStateStyles> = {
  primary: {
    default: {
      light: {
        color: '#fff', // Neutral/0
      },
      dark: {
        color: '#222835', // Neutral/700
      },
    },
    hover: {
      light: {
        color: '#fff', // Neutral/0
      },
      dark: {
        color: '#0A10D0', // Brand_1/600
      },
    },
    focus: {
      light: {
        color: '#fff', // Neutral/0
      },
      dark: {
        color: '#222835', // Neutral/700
      },
    },
    disabled: {
      light: {},
      dark: {},
    },
  },
  secondary: {
    default: {
      light: {
        color: '#222835', // Neutral/700
      },
      dark: {
        color: '#fff', // Neutral/0
      },
    },
    hover: {
      light: {
        color: '#222835', // Neutral/700
      },
      dark: {
        color: '#fff', // Neutral/0
      },
    },
    focus: {
      light: {
        color: '#222835', // Neutral/700
      },
      dark: {
        color: '#fff', // Neutral/0
      },
    },
    disabled: {
      light: {},
      dark: {},
    },
  },
  text: {
    default: {
      light: {
        color: '#222835', // Neutral/700
      },
      dark: {
        color: '#fff', // Neutral/0
      },
    },
    hover: {
      light: {
        color: '#0A10D0', // Brand_1/600
      },
      dark: {
        color: '#78A2FC', // Brand_1/300
      },
    },
    focus: {
      light: {
        color: '#222835', // Neutral/700
      },
      dark: {
        color: '#fff', // Neutral/0
      },
    },
    disabled: {
      light: {},
      dark: {},
    },
  },
};
