import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {Icon} from 'interface/base/Icon';
import {MonthDates} from 'interface/stacks/share/layout/calendar/MonthDates';
import {MonthSelect} from 'interface/stacks/share/layout/calendar/MonthSelect';
import type React from 'react';
import {useCallback, useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import Typography from '../Typography';

interface DatetimePickerProps {
  label?: string;
  icon?: string;
  date: Date | undefined;
  setDate: (date: Date) => void;
  emptyDateText?: string;
  renderDateText?: (date: Date) => string;
}

export default function DatetimePicker({
  label,
  icon = 'lucide:clock',
  date,
  setDate,
  renderDateText,
}: DatetimePickerProps) {
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);
  const [showDate, setShowDate] = useState(date ?? new Date());

  const openCalendar = useCallback(() => {
    setIsCalendarOpened(true);
  }, []);

  const handleDateSelect = (selectedDate: Date) => {
    setDate(selectedDate);
    setShowDate(selectedDate);
    setIsCalendarOpened(false);
  };

  const triggerButton = (
    <Pressable style={[styles.picker]} onPress={openCalendar}>
      <Typography size="xs" color={theme.colors.neutral.$2Base}>
        Set
      </Typography>
      <Icon
        name="lucide:chevron-down"
        size={14}
        color={theme.colors.neutral.$500}
      />
    </Pressable>
  );

  return (
    <View style={styles.root}>
      <View style={styles.labelContainer}>
        {icon && (
          <Icon name={icon} size={18} color={theme.colors.neutral.$2Base} />
        )}
        <View style={styles.content}>
          <Typography size="sm" color={theme.colors.neutral.$2Base}>
            {label}
          </Typography>
          <Typography size="sm" color={theme.colors.neutral.$500}>
            {renderDateText ? renderDateText(date) : date}
          </Typography>
        </View>
      </View>
      <Popup
        triggerElement={triggerButton}
        placement="bottom-end"
        autoClose
        close={() => setIsCalendarOpened(false)}
        isOpen={isCalendarOpened}
        onOpenChange={openCalendar}>
        <View style={styles.calendar}>
          <MonthSelect showDate={showDate} setShowDate={setShowDate} />
          <MonthDates
            showDate={showDate}
            startDate={date}
            setDate={handleDateSelect}
            isDateRange={false}
          />
        </View>
      </Popup>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 10,
    width: '100%',
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 8,
  },
  picker: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    gap: 6,
    borderRadius: 6,
    height: 24,
    borderColor: 'transparent',
    backgroundColor: theme.colors.neutral.$75,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 80,
  },
  content: {
    flexDirection: 'column',
    gap: 6,
  },
  calendar: {
    width: 294,
  },
});
