import {StyleSheet} from 'react-native';
import {AiChatQuestionItem} from './AiChatQuestionItem';
import {AiChatResponseItem} from './AiChatResponseItem';

import type {EntityChatMessage} from 'store/slices/aichat/types';

interface Props {
  message: EntityChatMessage;
}

export function AiChatMessageItem({message}: Props) {
  const {content, response} = message;
  return (
    <>
      {content && (
        <AiChatQuestionItem
          message={content}
          userId={message.creator.id.toString()}
          createdAt={message.createdAt}
        />
      )}
      {response && <AiChatResponseItem message={message} />}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
});
