import {ROUTES} from 'constants/routes';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';
import {getCurrentPath} from 'utils/common/platform';
import {useJoinInvitationHandler} from './useJoinInvitationHandler';

export const useProccessPendingInvitation = () => {
  const navigate = useNavigate();

  const {handleInvitationAcceptance, isLoading: isPendingInvitationProcessing} =
    useJoinInvitationHandler(true);
  const currentInvitation = useSelector(app.selectors.getCurrentJoinInvitation);

  const handleInvitationFlow = async (email: string) => {
    const invitation = checkJoinInvitations(email);

    if (!invitation) {
      return;
    }

    if (invitation.hasClickedOnJoined) {
      await handleInvitationAcceptance({invitation, showAppLoader: true});
    } else {
      navigate(
        `/${ROUTES.LOGGED_OR_NOT_LOGGED.JOIN}/?token=${invitation.invitationToken}`,
      );
    }
  };

  const getPendingInvitation = (emailAddress: string) => {
    if (currentInvitation) {
      const {invitee_email} = currentInvitation;
      const emailInvitationMatches =
        emailAddress.toLowerCase() === invitee_email.toLowerCase();
      if (emailInvitationMatches) return currentInvitation;
    }
  };

  const checkJoinInvitations = (email: string) => {
    const currentPath = getCurrentPath();
    const shouldCheckPendingInvitation =
      currentPath !== ROUTES.LOGGED_OR_NOT_LOGGED.JOIN && email;
    if (shouldCheckPendingInvitation) {
      return getPendingInvitation(email);
    }
  };

  return {handleInvitationFlow, isPendingInvitationProcessing};
};
