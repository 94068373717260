import {createSelector} from '@reduxjs/toolkit';
import type {Store} from 'store';

const x = (store: Store) => store;

export const getLastError = createSelector(x, store => store.errors.lastError);

export const getShowError = createSelector(x, store => store.errors.showError);

export const getToastErrors = createSelector(
  x,
  store => store.errors.toastErrors,
);

export const getNoActionErrors = createSelector(
  x,
  store => store.errors.noActionErrors,
);
