import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import type {Store} from 'store';

interface Item {
  id: string | number;
}

type ChangeType = 'changed' | 'added' | 'removed';

type OnItemChangeCallback<T extends Item> = (
  item: T,
  changeType: ChangeType,
) => void;

function useItemChangeDetector<T extends Item>(
  selector: (state: Store) => T[],
  onItemChange: OnItemChangeCallback<T>,
): void {
  const items = useSelector(selector);
  const prevItemsRef = useRef<T[]>([]);

  useEffect(() => {
    if (prevItemsRef.current) {
      const prevItems = prevItemsRef.current;

      // Check for changed or added items
      items.forEach(item => {
        const prevItem = prevItems.find(prev => prev.id === item.id);
        if (!prevItem || JSON.stringify(prevItem) !== JSON.stringify(item)) {
          onItemChange(item, prevItem ? 'changed' : 'added');
        }
      });
    }

    prevItemsRef.current = items;
  }, [items, onItemChange]);
}

export default useItemChangeDetector;
