import {useModal} from 'extensions/viewport/useModal';
import {usePopup} from 'extensions/viewport/usePopup';
import * as effects from 'interface/stacks/workspace/storage/hooks/files';
import {useContextMenu} from 'interface/stacks/workspace/storage/hooks/useContextMenu';
import {useModalRename} from 'interface/stacks/workspace/storage/hooks/useModalRename';
import {useModalTrash} from 'interface/stacks/workspace/storage/hooks/useModalTrash';
import {MainDialog} from 'interface/stacks/workspace/storage/quick-share/MainDialog';
import {createRef, useCallback} from 'react';
import type {GestureResponderEvent} from 'react-native';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import type {FilesItem} from 'store/slices/files/types';
import {download} from 'utils/fast/storage';
import useContentViewerParams from './useContentViewerParams';

const useFileMenu = (file: FilesItem) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modal = useModal();
  const popup = usePopup();

  const {fileId, instanceId} = useContentViewerParams();

  const moveTo = useCallback(
    effects.pick(dispatch, {
      action: 'move',
      destination: 'root',
      ids: [fileId],
    }),
    [fileId],
  );

  const copyTo = useCallback(
    effects.pick(dispatch, {
      action: 'copy',
      destination: 'root',
      ids: [fileId],
    }),
    [fileId],
  );

  const renameModal = useModalRename(
    file ? file.id : '',
    file ? file.name : '',
    file ? file.type : '',
    instanceId,
    'workspace',
  );

  const trashModal = useModalTrash([fileId], instanceId, 'workspace');

  const downloadItem = useCallback(() => {
    const id = file.quickShare ? file.quickShare.id : instanceId;
    const instanceNs = file.quickShare ? 'quickshare' : 'workspace';
    download(fileId, id, instanceNs);
  }, [fileId, instanceId]);

  const menuFiles = useContextMenu({
    headerText: undefined,
    addToExistingShareOnPress: () => console.log('addToExistingShareOnPress'),
    createShareOnPress: () => {
      navigate(`/workspace/${instanceId}/share`, {
        state: {selection: [fileId]},
      });
    },
    commentsOnPress: () => {},
    copyToOnPress: copyTo,
    downloadOnPress: downloadItem,
    moveToOnPress: moveTo,
    moveToTrashOnPress: () => trashModal.open(),
    quickShareOnPress: () => {
      modal.open(<MainDialog file={file} />);
    },
    renameOnPress: () => renameModal.open(),
  });
  const menuAnchorRef = createRef<any>();
  const menu = useCallback(
    (e: MouseEvent | GestureResponderEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (popup) {
        // @ts-ignore
        if (e?.nativeEvent) {
          popup.setPosition({
            // @ts-ignore
            x: e.nativeEvent.pageX,
            // @ts-ignore
            y: e.nativeEvent.pageY,
          });
        }
        popup.setRef(menuAnchorRef);
      }
      menuFiles.open();
    },
    [menuFiles],
  );

  return {menu, ref: menuFiles.reference, downloadItem};
};

export default useFileMenu;
