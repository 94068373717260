import {clearImagesCache} from 'extensions/images';
import {Requests} from 'fast-sdk';
import {resetAppStore} from 'store';
import Auth from '../../interface/stacks/auth/consts';

const IGNORE_COOKIES = ['ve_br_key'];

const clearAllCaches = async () => {
  const cacheNames = await caches.keys();
  await Promise.all(cacheNames.map(name => caches.delete(name)));
};

const clearAllCookies = () => {
  document.cookie.split(';').forEach(cookie => {
    const [name] = cookie.split('=').map(c => c.trim());
    if (!IGNORE_COOKIES.includes(name)) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  });
};

export const clearCache = async (stayOnPage = false) => {
  if (window) {
    await resetAppStore();
    clearAllCookies();
    await clearAllCaches();
    localStorage.clear();
    sessionStorage.clear();
    await Auth.clearDB();
    Requests.setAuthToken(null);
    clearImagesCache();
    if (!stayOnPage) {
      window.location.reload();
    }
  }
};
