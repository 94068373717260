import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {useParams} from 'react-router';

type Params = {
  instanceNs: StorageNamespace;
  instanceId: string;
  fileId: string;
};

export default function useContentViewerParams() {
  const {fileId, instanceId, instanceNs} = useParams<Params>();

  return {
    fileId,
    instanceId,
    instanceNs,
  };
}
