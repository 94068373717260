import {monthNumberToName} from 'interface/stacks/settings/base/consts';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export const FILTER_INFO = {
  filter: {
    title: 'Filter by:',
    options: [
      {title: 'All Projects', value: 'All Projects'},
      {title: 'Shared Projects', value: 'Shared Projects'},
      {title: 'Your Projects', value: 'Your Projects'},
    ],
    selectedValue: {title: 'All Projects', value: 'All Projects'},
  },
  sort: {
    title: 'Sort by:',
    options: [
      {title: 'Date', value: 'Date'},
      {title: 'Size', value: 'Size'},
      {title: 'Name', value: 'Name'},
    ],
    selectedValue: {title: 'Date', value: 'Date'},
  },
};

export const dateSplit = (date: Date) => {
  return {
    year: date.getFullYear(),
    month: date.getMonth(),
    day: date.getDate(),
    hour: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
    monthName: monthNumberToName(date.getMonth() + 1),
  };
};

export const dateFormatter = (
  targetDate: Date,
  forwardDayThreshold: number,
  getFullDate?: boolean,
) => {
  const currentDate = new Date();
  const timePast = currentDate.getTime() - targetDate.getTime();

  if (timePast > 0) {
    return datePast(currentDate, targetDate, timePast, getFullDate);
  }
  return dateForward(
    currentDate,
    targetDate,
    timePast,
    forwardDayThreshold,
    getFullDate,
  );
};

const datePast = (
  currentDate: Date,
  targetDate: Date,
  timePast: number,
  getFullDate?: boolean,
) => {
  const {
    seconds: currentSecconds,
    minutes: currentMinutes,
    hour: currentHours,
  } = dateSplit(currentDate);
  const {seconds, minutes, hour, day, month, year, monthName} =
    dateSplit(targetDate);

  if (getFullDate) {
    return `${monthName} ${day}, ${year} at ${hour % 12}:${minutes < 10 ? `${minutes}0` : minutes} ${hour < 12 ? 'AM' : 'PM'}`;
  }

  if (timePast > 24 * HOUR) {
    return `${monthName} ${day}, ${year} at ${hour % 12}:${minutes < 10 ? `${minutes}0` : minutes} ${hour < 12 ? 'AM' : 'PM'}`;
  }
  if (timePast > 1 * HOUR) {
    return `${currentHours - hour} hours ago`;
  }
  if (timePast > 1 * MINUTE) {
    return `${currentMinutes - minutes} minutes ago`;
  }
  if (timePast > 1 * SECOND) {
    return `${currentSecconds - seconds} seconds ago`;
  }
  return 'now';
};

const dateForward = (
  currentDate: Date,
  targetDate: Date,
  timePast: number,
  dayThreshold: number,
  getFullDate?: boolean,
) => {
  const {day: currentDays} = dateSplit(currentDate);
  const {day, month, year, minutes, hour, seconds, monthName} =
    dateSplit(targetDate);

  if (getFullDate) {
    return `${monthName} ${day}, ${year} at ${hour % 12}:${minutes < 10 ? `${minutes}0` : minutes} ${hour < 12 ? 'AM' : 'PM'}`;
  }

  if (Math.abs(timePast) > 50 * DAY) {
    return `${monthName} ${day}, ${year} at ${hour % 12}:${minutes < 10 ? `${minutes}0` : minutes} ${hour < 12 ? 'AM' : 'PM'}`;
  }
  if (Math.abs(timePast) > 1 * DAY) {
    if (day - currentDays < dayThreshold) {
      return {
        text: `${day - currentDays} days`,
        type: 'danger',
      };
    }
  } else {
    return 'today';
  }
};
