import {WORKSPACE_TRASH} from 'constants/routes';
import {api} from 'fast-sdk';
import {
  FilePreviewState,
  FileThumbnailSizes,
  PreviewType,
} from 'fast-sdk/src/api/storage/consts';
import {useCallback, useState} from 'react';
import type {FilesItem} from 'store/slices/files/types';
import {blobToBase64} from 'utils/common/image';
import {FileType} from 'utils/fast/files';

export const FILES_PREVIEW_SUPPORT = [
  FileType.Image,
  FileType.Spreadsheet,
  FileType.PDF,
  FileType.Video,
];

export default function useFileThumbnailSource() {
  const [loading, setLoading] = useState<boolean>(false);
  const fetchImageThumbnail = useCallback(
    async (item: FilesItem, size: FileThumbnailSizes): Promise<string> => {
      if (item.previews.image.state !== FilePreviewState.READY) {
        return '';
      }
      const isPreview = size === FileThumbnailSizes.Preview;
      const previewType = isPreview ? PreviewType.IMAGE : PreviewType.THUMBNAIL;
      const thumbnailSize = isPreview ? undefined : size;
      const shareId = item.shareId;
      const quickShareId = item.quickShare?.id;
      const namespace = shareId
        ? 'share'
        : quickShareId
          ? 'quickshare'
          : 'workspace';
      const instanceId = quickShareId ?? shareId ?? item.workspaceId;
      const storage = api.storage.init(namespace, instanceId);
      const preview = storage.preview(item.id);
      const imageThumbnail = await preview.readPrimary(
        previewType,
        thumbnailSize,
      );

      return await new Promise(resolve =>
        blobToBase64(imageThumbnail, resolve),
      );
    },
    [],
  );

  const fetchFileThumbnail = useCallback(
    async (
      item: FilesItem,
      size: FileThumbnailSizes,
      fileType: FileType,
    ): Promise<string> => {
      setLoading(true);
      try {
        if (item.parent === WORKSPACE_TRASH) {
          return '';
        }
        if (FILES_PREVIEW_SUPPORT.includes(fileType)) {
          return await fetchImageThumbnail(item, size);
        }
        //Here we can add more methods to fech thumbnail depending on file type
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {fetchFileThumbnail, loading};
}
