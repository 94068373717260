import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as shared from 'store/slices/shared';
import useFetchShareDetails from './useFetchShareDetails';

interface UseGetShareDetailsOptions {
  workspaceFolderName?: string;
  shareId?: string;
  sharedCustomName?: string;
  fetchDisabled?: boolean;
}

const useGetShareDetails = ({
  workspaceFolderName,
  shareId,
  sharedCustomName,
  fetchDisabled = false,
}: UseGetShareDetailsOptions) => {
  const {fetchShareDetails, fetchShareDetailsByCustomName} =
    useFetchShareDetails();

  const [isShareLoaded, setIsShareLoaded] = useState(false);

  const sharedByCustomName = useSelector(
    shared.selectors.getSharedByCustomName(sharedCustomName),
  );

  const resolvedDetails = {
    workspaceFolderName:
      workspaceFolderName ?? sharedByCustomName?.workspaceFolderName,
    shareId: shareId ?? sharedByCustomName?.shareId,
  };

  const share = useSelector(
    shared.selectors.getSingleSharedItem(resolvedDetails.shareId),
  );

  useEffect(() => {
    const shouldFetchById =
      resolvedDetails.shareId && resolvedDetails.workspaceFolderName && !share;

    if (shouldFetchById && !fetchDisabled) {
      fetchShareDetails(
        resolvedDetails.workspaceFolderName,
        resolvedDetails.shareId,
      ).finally(() => setIsShareLoaded(true));
    }
  }, [resolvedDetails.shareId, resolvedDetails.workspaceFolderName, share]);

  useEffect(() => {
    const shouldFetchByCustomName =
      sharedCustomName && !share && !sharedByCustomName;

    if (shouldFetchByCustomName && !fetchDisabled) {
      fetchShareDetailsByCustomName(sharedCustomName);
    }
  }, [sharedCustomName, share, sharedByCustomName]);

  useEffect(() => {
    if (share && !isShareLoaded) {
      setIsShareLoaded(true);
    }
  }, [share, isShareLoaded]);

  return {
    share,
    isShareLoaded,
  };
};

export default useGetShareDetails;
