import {t} from '@lingui/macro';
import theme from 'config/theme';
import type {ShareLink} from 'fast-sdk/src/api/share/consts';
import {Button} from 'interface/common/Button';
import {IconButton} from 'interface/common/IconButton';
import {ModalSection} from 'interface/common/Modal';
import React from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

const MAX_LINKS = 3;

interface LinksSectionProps {
  links: ShareLink[];
  currentLink?: ShareLink;
  setCurrentLink: (link?: ShareLink) => void;
  addLink: () => void;
  onInfoPress: () => void;
}

export default function LinksSection({
  links,
  currentLink,
  setCurrentLink,
  addLink,
  onInfoPress,
}: LinksSectionProps) {
  const showAddLink = links.length < MAX_LINKS && !currentLink;

  return (
    <View style={styles.section}>
      <ModalSection
        title={t`External Links`}
        iconName="information-outline"
        onIconPress={onInfoPress}
      />
      <View style={styles.links}>
        {links.map(link => (
          <Pressable key={link.id} onPress={() => setCurrentLink(link)}>
            <Text
              style={[
                styles.link,
                currentLink?.id === link.id && styles.selectedLink,
              ]}>
              {link.title || ' '}
            </Text>
          </Pressable>
        ))}
        {showAddLink &&
          (links.length === 0 ? (
            <Button
              type="Primary"
              label={t`Add link`}
              onPress={addLink}
              lIcon={
                <Icon
                  name="add"
                  size={20}
                  color={theme.colors.neutral.$white}
                />
              }
            />
          ) : (
            <IconButton
              buttonStyle={styles.addLinkButton}
              onPress={addLink}
              icon={
                <Icon name="add" size={20} color={theme.colors.neutral.$5} />
              }
            />
          ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  section: {
    flexDirection: 'column',
  },
  links: {
    flexDirection: 'row',
    gap: 5,
  },
  link: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$10,
    backgroundColor: theme.colors.neutral.$white,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  selectedLink: {
    borderColor: theme.colors.brand.$5,
  },
  addLinkButton: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$10,
    backgroundColor: theme.colors.neutral.$white,
    minWidth: 40,
  },
  infoIcon: {
    color: theme.colors.neutral.$5,
  },
  addIcon: {
    color: theme.colors.neutral.$white,
  },
  addLinkIcon: {
    color: theme.colors.neutral.$5,
  },
});
