import {slices} from 'store';
import {load} from 'utils/fast/storage';

import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import type {Dispatch} from 'redux';
import type {FilesItem, FilesPick} from 'store/slices/files/types';

// Picker

export function pick(dispatch: Dispatch, context: FilesPick) {
  return (e?: any) => {
    e?.stopPropagation?.();
    dispatch(slices.files.actions.pick(context));
  };
}

export function pickClear(dispatch: Dispatch) {
  dispatch(slices.files.actions.pick(null));
}

export function pickSubmit(
  pick: FilesPick,
  dispatch: Dispatch,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  return (item?: FilesItem) => {
    if (item) {
      load(dispatch, item.id, instanceId, instanceNs);
    }
    if (pick) {
      const {ids, action} = pick;
      dispatch(
        slices.files.actions.pick({
          ids,
          action,
          destination: item.id,
        }),
      );
    } else {
      dispatch(slices.files.actions.pick(null));
    }
  };
}
