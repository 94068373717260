import theme from 'config/theme';
import {Svg} from 'react-native-svg';
import {VictoryBar, VictoryTooltip} from 'victory';
import {VictoryAxis, VictoryChart} from 'victory';
import {
  DEFAULT_BAR_STYLE,
  DEFAULT_PADDING,
  DEFAULT_TOOLTIP_FLYOUT_STYLE,
  DEFAULT_TOOLTIP_STYLE,
} from './consts';
import type {BarChartOverrides} from './types';

type Props = {
  width: number;
  height: number;
  overrides?: BarChartOverrides;
  data: any[];
};

export function BarChart({width, height, data, overrides}: Props) {
  const tickLabelStyle = {
    fontFamily: 'sans-serif',
    fontSize: 12,
    fill: theme.colors.neutral.$400,
  };

  const dependentAxisStyle = {
    tickLabels: tickLabelStyle,
    axis: {
      stroke: 'transparent',
    },
  };

  const crossAxisStyle = {
    tickLabels: tickLabelStyle,
    axis: {
      stroke: theme.colors.neutral.$100,
      marginBottom: 50,
    },
    axisLabel: {
      margin: 30,
    },
  };

  return (
    <Svg
      viewBox={`0 0 ${width} ${height}`}
      preserveAspectRatio="none"
      width="100%">
      <VictoryChart
        padding={DEFAULT_PADDING}
        standalone={false}
        width={width}
        height={height}
        domainPadding={{x: 30}}
        {...overrides?.Chart?.props}>
        <VictoryAxis
          dependentAxis
          style={overrides?.DependantAxis?.style ?? dependentAxisStyle}
        />
        <VictoryAxis
          crossAxis
          style={overrides?.CrossAxis?.style ?? crossAxisStyle}
        />
        <VictoryBar
          labelComponent={
            <VictoryTooltip
              cornerRadius={5}
              pointerLength={6}
              flyoutHeight={30}
              flyoutPadding={10}
              flyoutStyle={DEFAULT_TOOLTIP_FLYOUT_STYLE}
              constrainToVisibleArea={true}
              renderInPortal={true}
              style={overrides?.Tooltip?.style ?? DEFAULT_TOOLTIP_STYLE}
              {...overrides?.Tooltip?.props}
            />
          }
          style={overrides?.Bar?.style ?? DEFAULT_BAR_STYLE}
          cornerRadius={{topLeft: 3, topRight: 3}}
          {...overrides?.Bar?.props}
          data={data}
        />
      </VictoryChart>
    </Svg>
  );
}
