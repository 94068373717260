import {useMemo, useState} from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {
  TransformComponent,
  TransformWrapper,
  getMatrixTransformStyles,
} from 'react-zoom-pan-pinch';

import theme from 'config/theme';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import FullScreenButton from 'interface/stacks/workspace/storage/thumbnail/PreviewFullScreenButton';
import {selectors} from 'store/slices/files';
import {getFileTypeFromMime, typeToIcon} from 'utils/fast/files';

import Pagination from '../../components/Pagination';
import useFileUrl from '../../hooks/useFileUrl';
import useFullscreen from '../../hooks/useFullscreen';
import {ZOOM_VALUES_ARRAY} from '../../hooks/useZoom';
import {getFileExifMetadata} from '../../utils';
import {HEIGHT_CONTROL_BAR} from '../ControlBar';
import ImageContents from './ImageContents';
import ImageControls from './ImageControls';

import type {ViewerProps} from '../../types';
import type {ImageControlsProps} from './ImageControls';

const ImageViewer = ({file, isThumbnail}: ViewerProps) => {
  const fullscreen = useFullscreen();
  const {url, loading} = useFileUrl(file);
  const [area, setArea] = useState([0, 0]);
  const [fit, setFit] = useState<'vert' | 'horiz' | null>(null);

  const thumb = useSelector(
    selectors.selectThumbnail(`${file.id}-${FileThumbnailSizes.Preview}`),
  );

  const icon = useMemo(() => {
    const type = getFileTypeFromMime(file);
    return {uri: typeToIcon(type)};
  }, [file.mimetype]);

  const size = useMemo(() => {
    const meta = getFileExifMetadata(file);
    setFit(null);
    return [meta?.ImageWidth ?? 0, meta?.ImageHeight ?? 0];
  }, [file]);

  const metrics: ImageControlsProps['metrics'] = useMemo(() => {
    const [w1, h1] = area;
    const [w2, h2] = size;
    return {
      frame: [w1, h1],
      image: [w2, h2],
      scale:
        w2 && h2 && w1 && h1
          ? fit
            ? fit === 'horiz'
              ? w1 / w2
              : h1 / h2
            : Math.min(1, w1 / w2, h1 / h2)
          : 0,
    };
  }, [area, size, fit]);

  return (
    <View style={[styles.root, isThumbnail && {width: '100%'}]}>
      <View ref={fullscreen.ref} style={styles.main}>
        <TransformWrapper
          key={`${file.id}-${metrics.scale}`}
          disabled={loading}
          minScale={ZOOM_VALUES_ARRAY[0]}
          maxScale={ZOOM_VALUES_ARRAY[ZOOM_VALUES_ARRAY.length - 1]}
          initialScale={metrics.scale}
          initialPositionX={
            (metrics.frame[0] - metrics.image[0] * metrics.scale) / 2
          }
          initialPositionY={
            (metrics.frame[1] - metrics.image[1] * metrics.scale) / 2
          }
          customTransform={getMatrixTransformStyles}
          doubleClick={{mode: 'reset'}}>
          <View
            style={[
              StyleSheet.absoluteFill,
              !isThumbnail && {bottom: HEIGHT_CONTROL_BAR},
            ]}
            onLayout={({
              nativeEvent: {
                layout: {width, height},
              },
            }) => setArea([width, height])}>
            <TransformComponent wrapperStyle={{height: '100%', width: '100%'}}>
              <ImageContents
                url={url}
                thumb={thumb}
                width={size[0]}
                height={size[1]}
              />
            </TransformComponent>
            {isThumbnail && !fullscreen.isFullscreen && (
              <View>
                <FullScreenButton
                  handleOnFullScreen={fullscreen.toggleFullscreen}
                />
              </View>
            )}
          </View>
          {thumb ? (
            <ImageControls
              metrics={metrics}
              setContentFit={setFit}
              toggleFullscreen={fullscreen.toggleFullscreen}
              isFullscreen={fullscreen.isFullscreen}
              isThumbnail={isThumbnail}
            />
          ) : (
            <View style={styles.loading}>
              <Image style={styles.icon} source={icon} resizeMode="center" />
            </View>
          )}
        </TransformWrapper>
      </View>
      {!isThumbnail && <Pagination />}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#000',
  },
  main: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  loading: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 84,
    height: 74,
    tintColor: theme.colors.neutral.$6,
  },
});

export default ImageViewer;
