import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import type {Store} from './types';

export const initialState: Store = {
  profiles: {},
};

const sliceName = 'events';

export default createSlice({name: sliceName, reducers, initialState});
export {selectors};
