import {type Stripe, loadStripe} from '@stripe/stripe-js';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';

type Props = {
  key: string;
  loading: boolean;
};

export default function useLoadStripe({key, loading: loadingKey}: Props) {
  const [stripe, setStripe] = useState<Stripe>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const publicKey = useSelector(onboarding.selectors.getPublicKey);

  useEffect(() => {
    const stripeKey = key || publicKey;

    if (!loadingKey && !stripeKey) {
      handleAppError({
        appError: AppErrors.CreateOrgError,
        dialogOptions: {
          message: 'Public key not found',
          title: 'proceeding to checkout',
        },
      });
      return setLoading(false);
    }

    if (loadingKey && !stripeKey) {
      return;
    }

    async function fetchStripe() {
      const stripe = await loadStripe(stripeKey);
      setStripe(stripe);
      setLoading(false);
    }

    fetchStripe();
  }, [key, loadingKey, publicKey]);

  return {stripe, loading};
}
