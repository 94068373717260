import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import {Toggle} from 'interface/base/Toggle';
import Typography from 'interface/base/Typography';
import {useState} from 'react';
import {
  Pressable,
  StyleSheet,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';

type Props = {
  label: string;
  onChange: (inp: boolean) => void;
  checked?: boolean;
  icon?: string;
  customRootStyle?: ViewStyle;
  customTextStyle?: TextStyle;
};

export function CustomSwitch(props: Props) {
  const [checked, setChecked] = useState<boolean>(props.checked);

  const handleToogle = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    props.onChange(newChecked);
  };

  return (
    <View style={{...styles.root, ...props.customRootStyle}}>
      <View style={styles.labelContainer}>
        {props.icon && (
          <Icon
            name={props.icon}
            size={18}
            color={theme.colors.neutral.$2Base}
          />
        )}
        {props.label && (
          <Typography
            size="sm"
            overrides={props.customTextStyle}
            color={theme.colors.neutral.$2Base}>
            {props.label}
          </Typography>
        )}
      </View>
      <Pressable onPress={handleToogle}>
        <Toggle checked={checked} onChange={handleToogle} />
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  labelContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 6,
  },
  switch: {
    width: 28,
    height: 16,
  },
});
