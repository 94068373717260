import {ROUTES} from 'constants/routes';
import {routesGroups} from 'interface/common/hooks/navigator/useNavigatorRedirection';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {getCurrentPath} from 'utils/common/platform';

type LoadingKind = 'spinner' | 'progress' | null;

export default function useAppLoaderKind() {
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const [kind, setKind] = useState<LoadingKind>(null);

  useEffect(() => {
    const path = getCurrentPath();
    const showProgress =
      (isLoggedIn && !routesGroups.notLoggedInRoutes.includes(path)) ||
      path === ROUTES.LOGGED_IN_WITH_SUBDOMAIN.FROM_SUBDOMAIN;

    setKind(showProgress ? 'progress' : 'spinner');
  }, [isLoggedIn]);

  return kind;
}
