import {Linking} from 'react-native';

export default function useOpenLinkInNewTab() {
  const openAppLink = (url: string) => {
    Linking.openURL(window.location.origin + url);
  };

  const openExternalLink = (url: string) => {
    Linking.openURL(url);
  };

  return {openAppLink, openExternalLink};
}
