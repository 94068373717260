import ColorHash from 'color-hash';

export function getContrast(hexcolor: string) {
  if (!hexcolor) return 'black';
  hexcolor = hexcolor.replace('#', '');
  const r = Number.parseInt(hexcolor.substr(0, 2), 16);
  const g = Number.parseInt(hexcolor.substr(2, 2), 16);
  const b = Number.parseInt(hexcolor.substr(4, 2), 16);
  const y = (r * 299 + g * 587 + b * 114) / 1000;
  return y >= 128 ? 'black' : 'white';
}

export function luminance(hexcolor: string, luminosity: number) {
  hexcolor = String(hexcolor).replace(/[^0-9a-f]/gi, '');
  if (hexcolor.length < 6) {
    hexcolor =
      hexcolor[0] +
      hexcolor[0] +
      hexcolor[1] +
      hexcolor[1] +
      hexcolor[2] +
      hexcolor[2];
  }
  luminosity = luminosity || 0;
  let rgb = '#';
  let c: any;
  let i: number;
  for (i = 0; i < 3; i++) {
    c = Number.parseInt(hexcolor.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * luminosity), 255)).toString(16);
    rgb += `00${c}`.substr(c.length);
  }
  return rgb;
}

export function getHashColor(value: string): string {
  const hash = new ColorHash().hex(value);
  return hash.toString();
}

export function hexToRGBA(hex: string, opacity: number) {
  const code = hex.replace('#', '');

  const r = Number.parseInt(code.substring(0, 2), 16);
  const g = Number.parseInt(code.substring(2, 4), 16);
  const b = Number.parseInt(code.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
