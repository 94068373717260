import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';

interface Props {
  label: string;
  icon: string;
}

export function AiChatKeyboardLabel({label, icon}: Props) {
  return (
    <View style={styles.container}>
      <Typography size="xs" color={theme.colors.neutral.$500}>
        {icon}
      </Typography>
      <Typography size="xs" color={theme.colors.neutral.$500}>
        {label}
      </Typography>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 6,
    paddingVertical: 2,
    alignItems: 'center',
    borderRadius: 6,
    borderColor: theme.colors.neutral.$border,
    borderWidth: 1,
    gap: 4,
  },
});
