import {createSelector} from '@reduxjs/toolkit';
import type {Store} from 'store';

const x = (store: Store) => store.contentViewer;

export const getNeighbors = createSelector(x, store => ({
  prev: store.prev,
  next: store.next,
}));

export const getFiles = createSelector(x, store => store.files);

export const getUrl = (id: string) =>
  createSelector(x, store => store.urls[id]);

export const getIsThumbnail = createSelector(x, store => store.isThumbnail);

export const getUploadedBy = createSelector(x, store => store.uploadedBy);
