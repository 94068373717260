import {FilesItemState} from 'store/slices/files/types';

import type {
  QuickShareItem,
  StorageItem,
  StorageNamespace,
} from 'fast-sdk/src/api/storage/consts';
import type {FilesItem, Store} from 'store/slices/files/types';

import {buildQuickShare} from '../quick-share/helpers';

export function ensureRootExists(
  state: Store,
  instanceId: string,
  instanceNs: StorageNamespace,
) {
  if (!state[instanceNs]?.[instanceId]) state[instanceNs][instanceId] = {};
}

export function buildFileDetails(
  file: StorageItem,
  instanceId?: string,
  instanceNs?: StorageNamespace,
  quickShareItem?: QuickShareItem,
): FilesItem {
  const isShare = instanceNs === 'share';
  return {
    ...file,
    state: FilesItemState.isNormal,
    comments: [],
    hierarchy: [],
    workspaceId: isShare ? undefined : instanceId,
    shareId: isShare ? instanceId : undefined,
    quickShare: quickShareItem
      ? buildQuickShare(quickShareItem, undefined)
      : undefined,
  };
}

export function removeFileFromFolder(
  fileId: string,
  folderId: string,
  instanceList: {[key: string]: string[]},
): void {
  const parentList = instanceList[folderId];
  if (!parentList) return;
  const fileIndex = parentList.indexOf(fileId);
  if (fileIndex !== -1) parentList.splice(fileIndex, 1);
}

export function removeFileFromAllFolders(
  fileId: string,
  instanceList: {[key: string]: string[]},
): void {
  Object.keys(instanceList).forEach(folderId => {
    removeFileFromFolder(fileId, folderId, instanceList);
  });
}

export function addFileToFolder(
  fileId: string,
  folderId: string,
  instanceList: {[key: string]: string[]},
): void {
  if (!instanceList[folderId]) instanceList[folderId] = [];
  if (!instanceList[folderId].includes(fileId))
    instanceList[folderId].push(fileId);
}

export function handleFileDeletion(
  fileInfo: StorageItem,
  instanceList: {[key: string]: string[]},
): void {
  if (!fileInfo.deleted) return;
  if (fileInfo.deleted_from)
    removeFileFromFolder(fileInfo.id, fileInfo.deleted_from, instanceList);
  // Fallback: search and remove from all folders
  else removeFileFromAllFolders(fileInfo.id, instanceList);
}

export function handleParentChange(
  fileInfo: StorageItem,
  originalParent: string | undefined,
  instanceList: {[key: string]: string[]},
): void {
  if (originalParent === fileInfo.parent) return;
  if (originalParent)
    removeFileFromFolder(fileInfo.id, originalParent, instanceList);
  addFileToFolder(fileInfo.id, fileInfo.parent, instanceList);
}
