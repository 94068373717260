import {ROUTES, WORKSPACE_SHARED} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import useNavigateToShare from 'interface/stacks/share/hooks/useNavigateToShare';
import {StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';
import {ShareListSkeleton} from '../skeletons/ShareListSkeleton';
import {ShareAllItems} from './ShareAllItems';
import {ShareItem} from './ShareItem';

interface ShareListProps {
  workspace: WorkspaceListDetail;
  shares: Share[];
  isShareListLoading: boolean;
}

export function ShareList({
  workspace,
  shares,
  isShareListLoading,
}: ShareListProps) {
  const navigate = useNavigate();
  const {navigateToShare} = useNavigateToShare();

  const onShareItemPress = (share: Share) => {
    navigateToShare(share);
  };

  const onShareAllItemsPress = () => {
    navigate(
      `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspace.folder_name}/${WORKSPACE_SHARED}`,
    );
  };

  return (
    <View style={styles.flex1}>
      <ShareAllItems onPress={onShareAllItemsPress} />
      {isShareListLoading ? (
        <ShareListSkeleton />
      ) : (
        shares.map(item => (
          <ShareItem
            key={`item-${item.id}`}
            item={item}
            onPress={onShareItemPress}
          />
        ))
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  flex1: {
    flex: 1,
  },
});
