import {api} from 'fast-sdk';
import {useDispatch} from 'react-redux';
import quickShare from 'store/slices/quick-share';

export const useGetQuickShares = () => {
  const dispatch = useDispatch();

  const fetchAndUpdateQuickShares = async (workspaceFolderName: string) => {
    const storage = api.storage.init('workspace', workspaceFolderName);
    const {result, quickshares} = await storage.getQuickShares();
    if (result) {
      dispatch(
        quickShare.actions.setQuickShares({quickshares, workspaceFolderName}),
      );
    }
  };

  return {
    fetchAndUpdateQuickShares,
  };
};
