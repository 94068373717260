import {useEffect, useState} from 'react';
import type {MultiplayerCursor, MultiplayerMember} from '../types';

export function useMultiplayerCursors(
  members: MultiplayerMember[],
  itemId: string,
) {
  const [cursors, setCursors] = useState<MultiplayerCursor[]>([]);

  useEffect(() => {
    const update = () => {
      const _cursors: MultiplayerCursor[] = [];
      members.forEach((state: MultiplayerMember, clientId) => {
        // Ignore cursors from other files/folders
        if (itemId && itemId !== state.currentItemId) return;
        // Add cursor to list
        _cursors.push({
          id: clientId,
          name: state.userNameFirst.trim(),
          color: state.userColor,
          x: state.cursorX,
          y: state.cursorY,
          offscreen: state.cursorOffscreen,
          busy: false,
        });
      });
      setCursors(_cursors);
    };
    update();
    return () => {
      setCursors([]);
    };
  }, [members, itemId]);

  return cursors;
}
