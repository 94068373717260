import MediaText from 'interface/stacks/content-viewer/components/control-bar/MediaText';
import NextPageButton from 'interface/stacks/content-viewer/components/control-bar/NextPageButton';
import PagesButton from 'interface/stacks/content-viewer/components/control-bar/PagesButton';
import PreviousPageButton from 'interface/stacks/content-viewer/components/control-bar/PreviousPageButton';
import {StyleSheet, View} from 'react-native';

type Props = {
  isPaginationOpen: boolean;
  page: number;
  totalPages: number;
  setPageNumber: (value: number | ((prevState: number) => number)) => void;
  togglePagination: () => void;
};

const ControlsLeft = ({
  isPaginationOpen,
  page,
  totalPages,
  setPageNumber,
  togglePagination,
}: Props) => {
  const handlePreviousPage = () =>
    setPageNumber(prev => (prev === 1 ? totalPages : prev - 1));
  const handleNextPage = () =>
    setPageNumber(prev => (prev === totalPages ? 1 : prev + 1));
  return (
    <View style={styles.root}>
      <PagesButton isOpen={isPaginationOpen} onPress={togglePagination} />
      <PreviousPageButton onPress={handlePreviousPage} />
      <NextPageButton onPress={handleNextPage} />
      <MediaText
        style={styles.text}>{`${page} / ${totalPages ?? 1}`}</MediaText>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    paddingHorizontal: 8,
    paddingVertical: 6,
  },
});

export default ControlsLeft;
