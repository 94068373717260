import Skeleton from 'interface/common/Skeleton';
import {StyleSheet, View} from 'react-native';

export function ActivityTimelineGroupItemSkeleton() {
  const items = Array.from(
    {length: Math.floor(Math.random() * 3) + 1},
    (_, index) => <ActivityTimelineItemSkeleton key={index} />,
  );
  return (
    <View style={styles.groupContainer}>
      <Skeleton width={120} height={30} />
      {items}
    </View>
  );
}

function ActivityTimelineItemSkeleton() {
  return (
    <View style={styles.container}>
      <Skeleton width={30} height={30} style={styles.avatar} />
      <View style={styles.contentRight}>
        <Skeleton width={100} height={20} />
        <Skeleton height={60} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  groupContainer: {
    flexDirection: 'column',
    marginBottom: 15,
    gap: 15,
  },
  container: {
    flexDirection: 'row',
    gap: 10,
  },
  contentRight: {
    flexDirection: 'column',
    flex: 1,
    gap: 6,
  },
  avatar: {
    paddingVertical: 4,
  },
});
