import theme from 'config/theme';
import analytics from 'extensions/analytics';
import {useModal} from 'extensions/viewport/useModal';
import {api} from 'fast-sdk';
import Modal, {ModalSize} from 'interface/common/Modal';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import type {FilesItem} from 'store/slices/files/types';
import * as quickShareSlice from 'store/slices/quick-share';
import {ActivityType} from 'store/slices/quick-share/types';
import {getMembersFromUsers} from 'utils/common/members';
import {GB} from 'utils/fast/files';
import {Activity} from './Activity';
import {Expiration, ExpirationStatus} from './Expiration';

interface Props {
  file: FilesItem;
}

const MAX_QUICK_SHARE_SIZE = 5 * GB;

export function MainDialog({file}: Props) {
  const modal = useModal();
  const dispatch = useDispatch();
  const toast = useToast();

  const [status, setStatus] = useState<ExpirationStatus>(
    file.size > MAX_QUICK_SHARE_SIZE
      ? ExpirationStatus.CreateError
      : ExpirationStatus.Start,
  );
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);

  const quickShare = useSelector(
    quickShareSlice.selectors.getQuickShareByFileId(file.id),
  );

  const storage = api.storage.init('workspace', file.workspaceId);

  const createQuickShare = async (time: number) => {
    analytics.log('Created quick share');
    try {
      setStatus(ExpirationStatus.CreateLoading);
      const {result, quickshare} = await storage.createQuickShare(
        file.id,
        time,
      );
      if (result) {
        dispatch(
          quickShareSlice.default.actions.addQuickShare({
            quickshare,
            workspaceFolderName: file.workspaceId,
          }),
        );
      }
    } finally {
      setStatus(ExpirationStatus.Start);
    }
  };

  const removeQuickShare = async () => {
    try {
      setIsRemoveLoading(true);
      const {result} = await storage.deleteQuickShare(quickShare.node.id);
      if (result) {
        dispatch(
          quickShareSlice.default.actions.removeQuickShare({
            quickshareId: quickShare.id,
            workspaceFolderName: file.workspaceId,
          }),
        );
        handleClose();
        toast.show('QuickShare link removed');
      }
    } finally {
      setIsRemoveLoading(false);
    }
  };

  const handleClose = () => {
    modal.close();
  };

  const showActivity =
    Boolean(quickShare) && status === ExpirationStatus.Running;

  return (
    <Modal
      title={
        status === ExpirationStatus.Remove
          ? 'Remove QuickShare link?'
          : status === ExpirationStatus.CreateError
            ? 'Unable to create QuickShare'
            : `"${file.name}" QuickShare`
      }
      close={handleClose}
      size={ModalSize.SMALL}>
      <Expiration
        quickShare={quickShare}
        createQuickShare={createQuickShare}
        removeQuickShare={removeQuickShare}
        status={status}
        setStatus={setStatus}
        close={handleClose}
        isRemoveLoading={isRemoveLoading}
      />
      {showActivity && (
        <View style={styles.activity}>
          <Activity
            activityTime={new Date(quickShare.created)}
            // @ts-expect-error
            member={getMembersFromUsers([quickShare.creator_uid]).at(0)}
            key={quickShare.id}
            activityType={ActivityType.Created}
          />
        </View>
      )}
    </Modal>
  );
}

const styles = StyleSheet.create({
  activity: {
    flexDirection: 'column',
    borderRadius: 7,
    backgroundColor: theme.colors.neutral.$14,
    marginTop: 20,
  },
});
