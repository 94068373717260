import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {ColumnManagerInfoKeys as ColumnKeys} from 'store/slices/app/types';

const closingOrder: ColumnKeys[] = [
  ColumnKeys.InfoPanel,
  ColumnKeys.WorkspaceBrowser,
  ColumnKeys.AIChat,
  ColumnKeys.ShareConfiguration,
];

const calculateItemsToClose = (windowWidth: number) => {
  if (windowWidth < 600) {
    return 4;
  }
  if (windowWidth < 900) {
    return 3;
  }
  if (windowWidth < 1200) {
    return 2;
  }
  if (windowWidth < 1500) {
    return 1;
  }
  return 0;
};

export default function useColumnManagerHandler() {
  const dispatch = useDispatch();
  const columnInfo = useSelector(app.selectors.columnInfo);
  const columnInfoOverride = useSelector(app.selectors.columnInfoOverride);

  const setColumnInfo = (key: ColumnKeys, value: boolean) => {
    const currentValue = columnInfo[key];
    if (currentValue === value) return;
    dispatch(app.default.actions.setColumnInfo({[key]: value}));
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const columnsToClose = calculateItemsToClose(width);
      const alreadyHiddenColumns: ColumnKeys[] = Object.entries(
        columnInfoOverride,
      )
        .filter(([_, value]) => value === false)
        .map(([key]) => key as ColumnKeys);

      const pendingColumnsToHide = columnsToClose - alreadyHiddenColumns.length;

      let count = 0;
      closingOrder.forEach((key, index) => {
        if (count < pendingColumnsToHide && columnInfoOverride[key] === null) {
          setColumnInfo(key, false);
          count++;
        } else {
          setColumnInfo(key, true);
        }
      });

      setColumnInfo(
        ColumnKeys.FoldersAndFiles,
        columnInfoOverride[ColumnKeys.FoldersAndFiles] ?? true,
      );
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [columnInfo, columnInfoOverride]);
}
