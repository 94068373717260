import {useCreateNewChatMessage} from 'interface/stacks/workspace/hooks/useCreateNewChatMessage';
import {useFetchChatDetail} from 'interface/stacks/workspace/hooks/useFetchChatDetail';
import {useFetchChatMessages} from 'interface/stacks/workspace/hooks/useFetchChatMessages';
import {useEffect} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {EntityChat} from 'store/slices/aichat/types';
import type {Member} from 'store/slices/settings/types';
import * as user from 'store/slices/user';
import {AiChatHeader} from '../components/AiChatHeader';
import {AiChatInput} from '../components/AiChatInput';
import {AiChatMessageItem} from '../components/messages/AiChatMessageItem';
import {NEW_CHAT} from '../consts';

interface Props {
  workspaceId: string;
  chatId: string;
  messageId: string;
  onBackPress: () => void;
  onNewChatPress: () => void;
  workspaceMembers: Member[];
}

export function AiChat({
  workspaceId,
  chatId,
  messageId,
  workspaceMembers,
  onBackPress,
  onNewChatPress,
}: Props) {
  const dispatch = useDispatch();
  const {isLoading: detailsLoading, fetchChatDetails} = useFetchChatDetail();
  const {isLoading: messagesLoading, fetchChatMessages} =
    useFetchChatMessages();
  const {isLoading: createMessageLoading, createChatMessage} =
    useCreateNewChatMessage();

  const currentUser = useSelector(user.selectors.getUserDetails);

  const chat: EntityChat = useSelector(aichat.selectors.getChat(chatId));
  const messages = chat?.messages ?? [];

  const fetchDetails = async () => {
    const details = await fetchChatDetails(workspaceId, chatId);
    dispatch(aichat.default.actions.setChatDetails({chatId, chat: details}));
  };
  const fetchMessages = async () => {
    const messages = await fetchChatMessages(workspaceId, chatId);
    dispatch(aichat.default.actions.setChatMessages({chatId, messages}));
  };
  const createMessage = async (message: string) => {
    const messageId = await createChatMessage(workspaceId, chatId, message);
    dispatch(
      aichat.default.actions.addChatMessage({
        chatId,
        messageId,
        message,
        currentUserId: currentUser.id,
      }),
    );
  };

  useEffect(() => {
    if (chatId === NEW_CHAT) {
      return;
    }
    fetchDetails();
    fetchMessages();
  }, [workspaceId, chatId]);

  const isEnabled = messages[messages.length - 1]?.response?.text !== undefined;

  return (
    <View style={styles.container}>
      <AiChatHeader
        title={chat?.name ?? 'New chat'}
        onBackPress={onBackPress}
        onNewChatPress={onNewChatPress}
      />
      <ScrollView
        style={styles.messagesContainer}
        showsVerticalScrollIndicator={false}>
        <View style={styles.messages}>
          {messages.map(message => (
            <AiChatMessageItem key={message.id} message={message} />
          ))}
        </View>
      </ScrollView>
      <AiChatInput
        workspaceId={workspaceId}
        chatId={chatId}
        workspaceMembers={workspaceMembers}
        onSendMessage={createMessage}
        isEnabled={isEnabled}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  messagesContainer: {
    flex: 1,
    padding: 16,
  },
  messages: {
    flex: 1,
    gap: 30,
  },
});
