import {t} from '@lingui/macro';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import useCreateNewShareMenu from 'interface/stacks/workspace/hooks/useCreateNewShareMenu';
import {useWorkspaceShares} from 'interface/stacks/workspace/hooks/useWorkspaceShares';
import type {MouseEvent} from 'react-native';
import {Section} from '../components/Section';
import {SectionBody} from '../components/SectionBody';
import {SectionHeader} from '../components/SectionHeader';
import {ShareList} from '../components/share/ShareList';

interface SharesSectionProps {
  workspace: WorkspaceListDetail;
}

export function SharesSection({workspace}: SharesSectionProps) {
  const {shares, isShareListLoading} = useWorkspaceShares(workspace);

  const {openNewShareMenu, triggerRef} = useCreateNewShareMenu(workspace);

  const onAddSharePress = (e: MouseEvent) => {
    openNewShareMenu(e);
  };

  return (
    <Section id="shares-section">
      <SectionHeader
        title={t`Shares`}
        onAddPress={onAddSharePress}
        ariaLabel="Add Share"
        addButtonRef={triggerRef}
      />
      <SectionBody>
        <ShareList
          workspace={workspace}
          shares={Object.values(shares)}
          isShareListLoading={isShareListLoading}
        />
      </SectionBody>
    </Section>
  );
}
