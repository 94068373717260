import {useModal} from 'extensions/viewport/useModal';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import * as quickShareSlice from 'store/slices/quick-share';
import type {QuickShare} from 'store/slices/quick-share/types';
import {CircularProgressBar} from './CircularProgressBar';
import {InlineProgress} from './InlineProgress.tsx';
import {durationToProgress} from './utils';

interface Props {
  quickShare: QuickShare;
  kind?: 'inline' | 'circular';
}

export function Timer({quickShare, kind = 'circular'}: Props) {
  const modal = useModal();
  const dispatch = useDispatch();

  const format = durationToProgress(quickShare.duration, quickShare.created);
  const [progress, setProgress] = useState<number>(
    (format.remainingTime * 100) / quickShare.duration,
  );

  useEffect(() => {
    if (progress <= 0) {
      dispatch(
        quickShareSlice.default.actions.removeQuickShare({
          quickshareId: quickShare.id,
          workspaceFolderName: quickShare.workspaceFolderName,
        }),
      );
      modal.close();
    }
  }, [progress]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(progress - 100 / quickShare.duration);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  });

  const text = `${format.hour ? `${format.hour}:` : ''}${format.minutes ? `${format.minutes}:` : ''}${format.seconds}`;

  return kind === 'circular' ? (
    <CircularProgressBar progress={progress} text={text} />
  ) : (
    <InlineProgress text={text} />
  );
}
