import type WebSocketConnection from 'fast-sdk/src/websockets/WebSocketConnection';
import type {
  WebSocketConnectionOptions,
  WebSocketConnectorFn,
  WebSocketResponseType,
} from 'fast-sdk/src/websockets/types';

class WebSocketConnectionManager {
  private static instance: WebSocketConnectionManager | null = null;
  private connections: {[key: string]: WebSocketConnection<any>} = {};

  private constructor() {}

  public static getInstance(): WebSocketConnectionManager {
    if (!WebSocketConnectionManager.instance) {
      WebSocketConnectionManager.instance = new WebSocketConnectionManager();
    }
    return WebSocketConnectionManager.instance;
  }

  public getOrCreateWebSocketConnection<T extends WebSocketResponseType>(
    socketKey: string,
    connector: WebSocketConnectorFn<T>,
    connectorOptions: WebSocketConnectionOptions<T>,
  ) {
    if (!this.connections[socketKey]) {
      this.connections[socketKey] = connector(connectorOptions);
    }
    return this.connections[socketKey];
  }

  public getConnection(socketKey: string) {
    return this.connections[socketKey] || null;
  }

  public closeConnection(socketKey: string): void {
    if (this.connections[socketKey]) {
      this.connections[socketKey].close();
      delete this.connections[socketKey];
    }
  }

  public sendMessage(socketKey: string, message: any): void {
    const connection = this.getConnection(socketKey);
    if (connection?.isOpen) {
      connection.send(message);
    } else {
      console.warn(`Cannot send message. ${socketKey} connection is not open.`);
    }
  }

  public closeAllConnections(): void {
    Object.keys(this.connections).forEach(this.closeConnection.bind(this));
  }
}

export default WebSocketConnectionManager;
