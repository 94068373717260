import {ROUTES} from 'constants/routes';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as workspace from 'store/slices/workspace';
import {WorkspaceCardList} from '../base/WorkspaceCardList';
import {WorkspacesToolbar} from './WorkspacesToolbar';

export function ArchivedWorkspaces() {
  const navigate = useNavigate();

  const workspaces = useSelector(
    workspace.selectors.getArchivedWorkspacesInOrg,
  );

  const goBack = () => {
    navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`, {
      replace: true,
    });
  };

  return (
    <>
      <WorkspacesToolbar title="Archived Workspaces" goBack={goBack} />
      <WorkspaceCardList workspaces={workspaces} />
    </>
  );
}
