import theme from 'config/theme';
import {StyleSheet, Text, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';

interface Props {
  text: string;
}

export function InlineProgress({text}: Props) {
  return (
    <View style={styles.root}>
      <View style={styles.iconContainer}>
        <MCIcon
          name="clock-outline"
          size={20}
          color={theme.colors.neutral.$white}
        />
      </View>
      <Text style={styles.text}>Expires in {text}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    color: theme.colors.neutral.$white,
    width: 120,
  },
});
