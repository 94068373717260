import {api} from 'fast-sdk';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as shared from 'store/slices/shared';
import * as $workspace from 'store/slices/workspace';

export const useWorkspaceShares = (workspace: WorkspaceListDetail) => {
  const dispatch = useDispatch();

  const workspaces = useSelector($workspace.selectors.getAllJoinedWorkspaces);

  const shares = useSelector(
    shared.selectors.getWorkspaceShares(workspace?.folder_name),
  );
  const sharesLoaded = useSelector(shared.selectors.getSharesLoaded);
  const isShareListLoading = useSelector(shared.selectors.getIsListLoading);

  const fetchAndUpdateShares = async () => {
    dispatch(shared.default.actions.setIsListLoading(true));
    try {
      const {result, shares} = await api.workspace.getWorkspaceSharesList(
        workspace?.folder_name,
      );
      if (result) {
        dispatch(
          shared.default.actions.setShares({
            shares: shares.map(share => ({
              ...share,
              parent_workspace: workspace.id,
              parent_org: workspace.org_domain,
            })),
            workspaces: [...workspaces, workspace],
          }),
        );
        dispatch(shared.default.actions.setSharesLoaded({loaded: true}));
      }
    } finally {
      dispatch(shared.default.actions.setIsListLoading(false));
    }
  };

  useEffect(() => {
    if (workspace && !sharesLoaded) {
      fetchAndUpdateShares();
    }
  }, [workspace]);

  return {shares, isShareListLoading};
};
