import {ROUTES} from 'constants/routes';
import {SubscriptionCheckout} from 'interface/stacks/subscription/components/SubscriptionCheckout';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as onboarding from 'store/slices/onboarding';
import useBillingPlan from '../hooks/useBillingPlan';
import useCreateOrg from '../hooks/useCreateOrg';
import useLoadStripe from '../hooks/useLoadStripe';
import {OnboardSteps} from '../lib/consts';

export function Payment() {
  const {loading: loadingBilling, tax, plan} = useBillingPlan();
  const {
    loading: loadingOrgCreation,
    clientSecret,
    stripeKey,
  } = useCreateOrg({plan});
  const {stripe, loading: loadingStripe} = useLoadStripe({
    key: stripeKey,
    loading: loadingOrgCreation || !stripeKey,
  });

  const subdomain = useSelector(onboarding.selectors.getSubdomain);
  const createdOrg = useSelector(onboarding.selectors.getCreatedOrganizationId);
  const intent = useSelector(onboarding.selectors.getIntentClientSecret);

  const secret = clientSecret || intent;

  const loading =
    loadingBilling || loadingOrgCreation || loadingStripe || !clientSecret;

  const navigate = useNavigate();

  const onSubscriptionSuccess = () => {
    navigate(
      `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${OnboardSteps.Success}`,
    );
  };

  return (
    <View style={styles.checkoutContainer}>
      <SubscriptionCheckout
        title="Start your free trial"
        content={`Add your payment details to activate your free ${plan?.pricing?.free_days}-day trial. You can cancel at any time.`}
        loading={loading}
        secret={secret}
        orgId={createdOrg}
        orgDomain={subdomain}
        stripe={stripe}
        plan={plan}
        tax={tax}
        onSubscriptionSuccess={onSubscriptionSuccess}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  checkoutContainer: {
    width: '100%',
    alignItems: 'center',
    marginHorizontal: 'auto',
  },
});
