import {t} from '@lingui/macro';
import {WORKSPACE_TRASH} from 'constants/routes';
import {useModal} from 'extensions/viewport/useModal';
import {usePopup} from 'extensions/viewport/usePopup';
import {Transfers} from 'fast-sdk';
import {Loading} from 'interface/common/Loading';
import {Mouse} from 'interface/common/Mouse';
import {NoResultsAvailable} from 'interface/common/NoResultsAvailable';
import {combineNodesAndUploads} from 'interface/stacks/uploads/utils/filelist';
import {createRef, memo, useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import files, {selectors} from 'store/slices/files';
import {SharedCustomizeDialog} from '../shared/dialogs/SharedCustomizeDialog';
import {FileList} from './FileList';
import {FolderHeader} from './FolderHeader';
import {useContextMenu} from './hooks/useContextMenu';
import {useModalCreateFolder} from './hooks/useModalCreateFolder';
import {useWorkspaceContext} from './hooks/useWorkspaceContext';

export interface FolderContentProps {
  id: string;
}

export const FolderContents = memo((props: FolderContentProps) => {
  const modal = useModal();
  const popup = usePopup();

  const {workspace, uploader, multiplayer} = useWorkspaceContext();

  const localItems = useSelector(selectors.getItems);
  const localView = useSelector(
    selectors.getFolder('workspace', workspace.folder_name, props.id),
  );

  const [view, items] = useMemo(
    () =>
      combineNodesAndUploads(
        localView,
        localItems,
        uploader.uploads,
        workspace.id,
        props.id,
      ),
    [localView, localItems, uploader.uploads, workspace.id, props.id],
  );

  const isTrash = props.id === WORKSPACE_TRASH;

  const dispatch = useDispatch();

  const clearSelection = () => {
    dispatch(files.actions.select({ids: []}));
  };

  const addFilesFromLocal = async () => {
    const files = await Transfers.pickFiles();
    uploader.instance.addFiles(files, props.id, workspace.id);
  };

  const openCreateShareDialog = () => {
    modal.open(
      <SharedCustomizeDialog
        workspaceFolderName={workspace.folder_name}
        folderParentId={props.id}
        close={modal.close}
      />,
    );
  };

  const modalCreateFolder = useModalCreateFolder(
    props.id,
    workspace.folder_name,
    'workspace',
  );

  const menuRef = createRef<any>();
  const menuFiles = useContextMenu({
    noDividers: true,
    ignoreMultiSelect: true,
    headerText: localItems[props.id]?.name ?? workspace.name,
    uploadHereOnPress: addFilesFromLocal,
    createShareOnPress: openCreateShareDialog,
    createFolderOnPress: modalCreateFolder.open,
  });

  return (
    <Mouse
      onContextMenu={e => {
        e?.preventDefault();
        e?.stopPropagation();
        if (popup) {
          if ('nativeEvent' in e)
            popup.setPosition({
              x: e.nativeEvent.pageX,
              y: e.nativeEvent.pageY,
            });
          popup.setRef(menuRef);
        }
        menuFiles.open();
      }}>
      <View onClick={clearSelection} style={styles.content}>
        <FolderHeader id={props.id} />
        <View style={styles.lists}>
          <View style={styles.list}>
            <FileList
              view={view}
              items={items}
              multiplayer={multiplayer}
              root={['root', workspace.name]}
              options={{
                disableOpen: isTrash,
                disableDrag: isTrash,
                disableActions: isTrash,
              }}
              renderEmpty={() => (
                <NoResultsAvailable
                  message={
                    isTrash
                      ? t`Workspace trash is empty.`
                      : t`No folders or files added yet.`
                  }
                />
              )}
              renderLoading={() => (
                <View style={styles.loading}>
                  <Loading />
                </View>
              )}
            />
          </View>
        </View>
      </View>
    </Mouse>
  );
});

export const styles = StyleSheet.create({
  content: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
  },
  lists: {
    flex: 1,
    flexDirection: 'row',
  },
  list: {
    flex: 1,
    margin: 0,
    flexDirection: 'row',
  },
  loading: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
