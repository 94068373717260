import theme from 'config/theme';
import {useState} from 'react';
import {StyleSheet} from 'react-native';
import VideoTimeDisplay, {TimeFormats} from '../VideoTimeDisplay';

const ControlsMiddle = () => {
  const [timeFormat, setTimeFormat] = useState(TimeFormats.STANDARD);

  const onChangeFormat = (format: TimeFormats) => setTimeFormat(format);

  return (
    <VideoTimeDisplay timeFormat={timeFormat} onChangeFormat={onChangeFormat} />
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    color: theme.colors.neutral.$white,
  },
});

export default ControlsMiddle;
