import React from 'react';
import {type StyleProp, StyleSheet, View, type ViewStyle} from 'react-native';
import {getHashColor} from 'utils/common/color';

interface WorkspaceBadgeProps {
  id: string;
  size?: number;
  style?: StyleProp<ViewStyle>;
}

export function WorkspaceBadge({id, size = 16, style}: WorkspaceBadgeProps) {
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.workspaceBagde,
          {backgroundColor: getHashColor(id), width: size, height: size},
          style,
        ]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  workspaceBagde: {
    width: 16,
    height: 16,
    borderRadius: 3,
  },
  container: {
    padding: 2,
  },
});
