import theme from 'config/theme';
import {Link} from 'extensions/navigation/components/Link.native';
import {type ReactElement, useState} from 'react';
import {StyleSheet, Text, type TextStyle, type ViewStyle} from 'react-native';

interface CustomLinkProps {
  children: ReactElement | Array<ReactElement> | string;
  to?: string;
  customContainerStyle?: ViewStyle;
  customTextStyle?: TextStyle;
  onPress?: () => void;
}

export function CustomLink(props: CustomLinkProps) {
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <Link
      role="link"
      to={props.to}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      onPress={props.onPress}
      style={props.customContainerStyle}>
      <Text
        style={[
          styles.simpleLink,
          hovered && styles.simpleLinkHovered,
          props.customTextStyle,
        ]}>
        {props.children}
      </Text>
    </Link>
  );
}

const styles = StyleSheet.create({
  simpleLink: {
    color: theme.colors.brand.$4Base,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  simpleLinkHovered: {
    color: theme.colors.brand.$3,
  },
});
