import {
  DEFAULT_FRIENDLY_USER_ERROR_MESSAGE,
  DEFAULT_FRIENDLY_USER_ERROR_TITLE,
} from 'constants/errors';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import type {ErrorBoundaryDetails} from 'errors/types';
import {ErrorPage} from 'interface/stacks/app/ErrorPage';
import React from 'react';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorDetails?: ErrorBoundaryDetails;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(originalError: Error, originalErrorInfo: React.ErrorInfo) {
    this.setState({
      errorDetails: {
        error: originalError,
        errorInfo: originalErrorInfo,
        title: DEFAULT_FRIENDLY_USER_ERROR_TITLE,
        message: DEFAULT_FRIENDLY_USER_ERROR_MESSAGE,
      },
    });
    try {
      handleAppError({
        appError: AppErrors.GenericBoundaryError,
        exception: originalError,
        stack: originalError.stack ?? 'No stack trace available',
      });
    } catch (unexpectedError) {
      console.error(
        'Unexpected error while handling error on ErrorBoundary',
        originalError,
        originalErrorInfo,
        unexpectedError,
      );
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage
          isUnhandledError={true}
          errorDetails={this.state.errorDetails}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
