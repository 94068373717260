import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import type {Store} from './types';

export const initialState: Store = {
  organizationName: '',
  subdomain: '',
  subscribed: false,
  coWorkerEmails: [],
  startOnboardingEmail: '',
  intentClientSecret: '',
  publicKey: '',
  finished: false,
  createdOrganizationId: '',
  planName: '',
  user: {
    firstName: '',
    lastName: '',
    imgFile: null,
    imgSrc: '',
    imgType: '',
    imgName: '',
  },
  error: false,
};

export default createSlice({name: 'onboarding', reducers, initialState});
export {selectors};
