import {addSeconds, differenceInSeconds} from 'date-fns';

export const durationToProgress = (duration: number, created: string) => {
  // Parse the UTC date string
  const createdAt = new Date(`${created}Z`); // Ensure UTC by adding Z
  const now = new Date();

  // Calculate expiration time and remaining seconds
  const expiredIn = addSeconds(createdAt, duration);
  const remainingTime = differenceInSeconds(expiredIn, now);

  // Ensure we don't have negative values
  const positiveTime = Math.max(remainingTime, 0);

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(positiveTime / 3600);
  const minutes = Math.floor((positiveTime % 3600) / 60);
  const seconds = positiveTime % 60;

  // Helper function to pad numbers
  const padNumber = (num: number): string => {
    return num < 10 ? `0${num}` : String(num);
  };

  return {
    hour: padNumber(hours),
    minutes: padNumber(minutes),
    seconds: padNumber(seconds),
    remainingTime: positiveTime,
  };
};
