import {createSelector} from '@reduxjs/toolkit';

import type {Store} from 'store';
import type {User} from 'store/slices/user/types';

const x = (store: Store) => store;

export const getUser = (id: string) =>
  createSelector(x, (store): User | undefined => {
    return store.users.items[id];
  });
