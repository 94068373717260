import theme from 'config/theme';
import {format, isToday, isYesterday} from 'date-fns';
import {StyleSheet, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import {bytesize} from 'utils/common/data';
import {parseServerDate} from 'utils/common/dates';
import {InfoPanelDetailItem} from './InfoPanelDetailItem';

interface Props {
  item: FilesItem;
}

const renderDate = (date: Date) => {
  if (isToday(date)) {
    return format(date, "'Today at' h:mm a");
  }

  if (isYesterday(date)) {
    return format(date, "'Yesterday at' h:mm a");
  }

  return format(date, "MM/dd/yy 'at' h:mm a");
};

export function InfoPanelDetailsTab({item}: Props) {
  const created = parseServerDate(item.created);
  const formattedCreated = `Created: ${renderDate(created)}`;
  return (
    <View style={styles.root}>
      <InfoPanelDetailItem title={'Date Created'} value={formattedCreated} />
      <InfoPanelDetailItem title={'Size'} value={bytesize(item.size, 2)} />
      <InfoPanelDetailItem title={'Type'} value={item.type} />
      <InfoPanelDetailItem title={'Keywords'} value={'?'} />
      <InfoPanelDetailItem title={'Notes'} value={'?'} />
      <InfoPanelDetailItem title={'Seen by'} value={'?'} />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flexDirection: 'column',
    padding: 8,
    gap: 8,
    backgroundColor: theme.colors.neutral.$75,
    borderRadius: 6,
  },
  spacing: {
    flex: 1,
  },
});
