import theme from 'config/theme';
import {useModal} from 'extensions/viewport/useModal';
import {Button} from 'interface/common/Button';
import {useCallback} from 'react';
import {StyleSheet, type TextStyle} from 'react-native';
import StatusIndicator from './StatusIndicator';
import StatusListModal from './StatusListModal';
import useSystemStatus from './hooks/useSystemStatus';

interface Props {
  textStyle: TextStyle;
}

export default function StatusButton({textStyle}: Props) {
  const {overallStatus, statuses, loading, label} = useSystemStatus();
  const modal = useModal();

  const open = useCallback(() => {
    modal.open(
      <StatusListModal
        label={label}
        overallStatus={overallStatus}
        statuses={statuses}
        onClose={modal.close}
      />,
    );
  }, [statuses]);

  return (
    <Button
      type="Text_Secondary"
      label="Status:"
      customRootStyle={styles.button}
      customTextStyle={[styles.buttonText, textStyle]}
      customRightIconStyle={styles.buttonIcon}
      rIcon={
        !loading && overallStatus ? (
          <StatusIndicator status={overallStatus} />
        ) : null
      }
      onPress={open}
    />
  );
}

const styles = StyleSheet.create({
  button: {
    height: 18,
    paddingHorizontal: 5,
    gap: 5,
  },
  buttonText: {
    lineHeight: 18,
    fontSize: 11,
    fontWeight: '400',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$6,
  },
  buttonIcon: {
    maxWidth: 8,
    maxHeight: 8,
  },
});
