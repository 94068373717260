import Skeleton from 'interface/common/Skeleton';
import {StyleSheet, View} from 'react-native';

const OrganizationHeaderSkeleton = () => (
  <View style={styles.header}>
    <View style={styles.headerLeft}>
      <Skeleton width={40} height={40} />
      <View style={styles.headerInfo}>
        <Skeleton width={150} height={24} />
        <View style={styles.headerStats}>
          <Skeleton width={80} height={16} />
          <Skeleton width={80} height={16} />
        </View>
      </View>
    </View>
    <Skeleton width={120} height={36} />
  </View>
);

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  headerInfo: {
    gap: 4,
  },
  headerStats: {
    flexDirection: 'row',
    gap: 8,
  },
});

export default OrganizationHeaderSkeleton;
