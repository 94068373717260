import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import {BilledDurationType, PlanType, type Store} from './types';

const initialState: Store = {
  organization: {
    general: {
      subDomain: '',
      timeZone: '',
    },
    branding: {
      name: '',
      logo: '',
      description: '',
      socialLinks: [],
    },
  },
  members: [],
  billing: {
    plan: {
      createdDate: new Date(),
      duration: 0,
      type: PlanType.Team,
      billedDuration: BilledDurationType.Monthly,
    },
    seats: {
      capacity: 0,
      used: 0,
    },
    owner: {
      email: '',
    },
  },
};

export default createSlice({name: 'settings', reducers, initialState});
export {selectors};
