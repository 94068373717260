import theme from 'config/theme';
import {ErrorFlowActions} from 'errors/types';
import {StyleSheet, Text, View} from 'react-native';
import type {ErrorItem} from 'store/slices/errors/types';
import {Button} from '../Button';
import {useToastRetry} from './hooks/useToastRetry';

const LOADER_SIZE = 8;

interface ErrorToastProps {
  errorItem: ErrorItem;
  toastId: string;
}

export const ErrorToast: React.FC<ErrorToastProps> = ({errorItem, toastId}) => {
  const {
    message,
    errorConfiguration: {flowAction, allowRetry},
    availableRetries,
    retryFn,
  } = errorItem;

  const {isLoading, currentMessage, remainingRetries, handleRetry} =
    useToastRetry({
      initialRetries: availableRetries,
      retryFn,
      toastId,
      initialMessage: message,
    });

  const showRetryButton =
    flowAction === ErrorFlowActions.Toast && allowRetry && remainingRetries > 0;

  return (
    <View style={styles.root}>
      <Text style={styles.message}>{currentMessage}</Text>
      {showRetryButton && (
        <Button
          onPress={handleRetry}
          label={`Retry (${remainingRetries})`}
          type="Secondary_thin"
          customRootStyle={styles.retry}
          customLoadingStyle={styles.loader}
          loading={isLoading}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    alignItems: 'center',
  },
  message: {
    color: theme.colors.neutral.$white,
  },
  retry: {
    height: 'auto',
  },
  loader: {
    width: LOADER_SIZE,
    height: LOADER_SIZE,
  },
});
