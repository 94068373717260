import theme from 'config/theme';
import {StyleSheet, View, type ViewStyle} from 'react-native';

type Props = {
  color?: string;
  orientation?: 'horizontal' | 'vertical';
  style?: ViewStyle;
};

export function Divider({
  color = theme.colors.neutral.$11,
  orientation = 'horizontal',
  style,
}: Props) {
  return (
    <View
      style={[
        {
          backgroundColor: color,
          width: orientation === 'vertical' ? StyleSheet.hairlineWidth : '100%',
          height:
            orientation === 'vertical' ? '100%' : StyleSheet.hairlineWidth,
          alignSelf: 'stretch',
        },
        style,
      ]}
    />
  );
}
