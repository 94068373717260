import {Time} from '@vidstack/react';
import theme from 'config/theme';
import MediaText from 'interface/stacks/content-viewer/components/control-bar/MediaText';
import TimeDisplay from 'interface/stacks/content-viewer/components/control-bar/TimeDisplay';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import ActionButton from '../../components/ActionButton';
import MediaPopup, {
  type OnChangeParams,
} from '../../components/control-bar/MediaPopup';
import useClickOutside from '../../hooks/useClickOutside';
import useContentViewerMode from '../../hooks/useContentViewerMode';
import useVideo from '../../hooks/useVideo';

export enum TimeFormats {
  STANDARD = 'standard',
  TIMECODE = 'timecode',
  FRAMES = 'frames',
}

const OPTIONS = [
  {
    label: 'Stardard',
    value: TimeFormats.STANDARD,
  },
  {
    label: 'Timecode',
    value: TimeFormats.TIMECODE,
  },
  {
    label: 'Frames',
    value: TimeFormats.FRAMES,
  },
];

const FPS = 30;

type Props = {
  timeFormat?: string;
  onChangeFormat?: (format: TimeFormats) => void;
};

const VideoTimeDisplay = ({timeFormat, onChangeFormat}: Props) => {
  const {isThumbnail} = useContentViewerMode();
  const {video} = useVideo();
  const [showPopup, setShowPopup] = useState(false);
  const {targetRef, sourceRef} = useClickOutside(showPopup, () =>
    setShowPopup(false),
  );

  const togglePopup = () => setShowPopup(prev => !prev);

  const handleChangeFormat = ({value: format}: OnChangeParams) => {
    onChangeFormat(format as TimeFormats);
    setShowPopup(false);
  };

  const currentFrames = Math.floor(video.currentTime * FPS) % FPS;
  const totalFrames = Math.floor(video.duration * FPS) % FPS;

  if (isThumbnail) {
    return (
      <View style={styles.root}>
        <TimeDisplay>
          <Time
            className="vds-time"
            padMinutes
            padHours
            type="current"
            style={styles.text}
          />
          <MediaText>/</MediaText>
          <Time
            className="vds-time"
            padMinutes
            padHours
            type="duration"
            style={styles.text}
          />
        </TimeDisplay>
      </View>
    );
  }

  return (
    <View>
      <ActionButton
        text={
          <TimeDisplay>
            {timeFormat === TimeFormats.STANDARD ? (
              <>
                <Time
                  className="vds-time"
                  showHours
                  padMinutes
                  padHours
                  type="current"
                  style={styles.text}
                />
                <MediaText>/</MediaText>
                <Time
                  className="vds-time"
                  showHours
                  padMinutes
                  padHours
                  type="duration"
                  style={styles.text}
                />
              </>
            ) : timeFormat === TimeFormats.FRAMES ? (
              <>
                <MediaText>{`${currentFrames}`.padStart(2, '0')}</MediaText>
                <MediaText>/</MediaText>
                <MediaText>{`${totalFrames}`.padStart(2, '0')}</MediaText>
              </>
            ) : (
              <View style={styles.timecode}>
                <Time
                  showHours
                  padMinutes
                  padHours
                  type="current"
                  style={styles.text}
                />
                <MediaText>{`:${currentFrames}`.padStart(2, '0')}</MediaText>
                <MediaText style={styles.separator}>/</MediaText>
                <Time
                  showHours
                  padMinutes
                  padHours
                  type="duration"
                  style={styles.text}
                />
                <MediaText>{`:${totalFrames}`.padStart(2, '0')}</MediaText>
              </View>
            )}
          </TimeDisplay>
        }
        ref={sourceRef}
        onPress={togglePopup}
        textStyle={styles.text}
        tooltipText="Time Format"
        hideTooltip={showPopup}
      />
      {showPopup && (
        <MediaPopup
          ref={targetRef}
          options={OPTIONS}
          onChange={handleChangeFormat}
          currentValue={timeFormat}
          headerText="Time Format"
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    marginRight: 12,
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    color: theme.colors.neutral.$white,
  },
  timecode: {
    flexDirection: 'row',
  },
  separator: {
    paddingHorizontal: 4,
  },
});

export default VideoTimeDisplay;
