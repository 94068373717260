import {t} from '@lingui/macro';
import {api} from 'fast-sdk';
import type {ShareMemberListInvitationItem} from 'fast-sdk/src/api/share/consts';
import {CustomTextInput} from 'interface/common/CustomTextInput';
import {Loading} from 'interface/common/Loading';
import {ModalSection} from 'interface/common/Modal';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {ProfileMemberList} from 'interface/stacks/app/ProfileMemberList';
import {useCallback, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';

interface ShareSettingsMembersProps {
  share: Share;
  toogleCurrentView: () => void;
  canAddRecipients?: boolean;
}

export default function ShareMembers({
  share,
  toogleCurrentView,
  canAddRecipients,
}: ShareSettingsMembersProps) {
  const {subdomain} = useSubDomain();

  const [invitations, setInvitations] =
    useState<Array<ShareMemberListInvitationItem> | null>(null);
  const [loadingInvitations, setLoadingInvitations] = useState(true);

  const onInfoPress = () => {
    console.log('info');
  };

  const fetchInvitations = useCallback(async () => {
    setLoadingInvitations(true);
    try {
      const {result, invitations} =
        await api.share.getAllShareMembersInvitation(share?.id);
      if (result) setInvitations(invitations);
    } finally {
      setLoadingInvitations(false);
    }
  }, [share?.id]);

  useEffect(() => {
    if (share?.id) {
      fetchInvitations();
    }
  }, [share?.id]);

  const members = [...(share?.members ?? [])];

  const hasMembersOrInvites = members?.length > 0 || invitations?.length > 0;

  return (
    <View style={styles.root}>
      {canAddRecipients && (
        <View>
          <ModalSection title={t`Send invitations to`} />
          <CustomTextInput
            value={'Enter member names or email addresses...'}
            setValue={() => {}}
            ariaLabel={'Enter member names or email addresses...'}
            onFocus={toogleCurrentView}
            readOnly
          />
        </View>
      )}
      <View style={styles.membersContainer}>
        <ModalSection
          title={t`Members`}
          iconName="information-outline"
          onIconPress={onInfoPress}
        />
        {loadingInvitations ? (
          <Loading size="small" text={t`Loading Recipients...`} centered />
        ) : (
          <View
            style={[
              styles.membersList,
              hasMembersOrInvites && {minHeight: 120},
            ]}>
            <ProfileMemberList
              members={members}
              invites={invitations}
              profileId={share?.id}
              subdomain={subdomain}
              reload={fetchInvitations}
              type="share"
              emptyMessage={t`No recipients found`}
              showDotsMenu
            />
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    gap: 21,
  },
  membersContainer: {
    flexDirection: 'column',
  },
  membersList: {
    maxHeight: '33vh',
    flex: 1,
  },
});
