import theme from 'config/theme';
import {format, isToday, isYesterday} from 'date-fns';
import {Avatar} from 'interface/common/Avatar';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import type {User} from 'store/slices/user/types';
import {getHashColor} from 'utils/common/color';

type Tag = {
  id: string;
  label: string;
};

type Props = {
  date: Date;
  user: User;
  action: string;
  last: boolean;
};

const renderDate = (date: Date) => {
  if (isToday(date)) {
    return format(date, "'Today,' h:mm a");
  }

  if (isYesterday(date)) {
    return format(date, "'Yesterday,' h:mm a");
  }

  return format(date, 'MMM. d, yyyy, h:mm a');
};

const PreviewSidePanelVersionChange = ({date, user, action, last}: Props) => {
  return (
    <View style={styles.root}>
      <View style={styles.left}>
        <View style={styles.line} />
        <View style={styles.circle} />
        {!last && <View style={styles.line} />}
      </View>
      <View style={styles.right}>
        <Text style={styles.date}>{renderDate(date)}</Text>
        <View style={styles.user}>
          <Avatar
            color={getHashColor(user.id)}
            initials={`${user.first_name[0]}${user.last_name[0]}`}
            photo={user.profile_pic}
          />
          <Text style={styles.fullname}>
            {user.first_name} {user.last_name} {action}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    height: 75,
    flexDirection: 'row',
    gap: 10,
  },
  left: {
    width: 30,
    alignItems: 'center',
  },
  line: {
    width: 2,
    height: 32.5,
    backgroundColor: theme.colors.neutral.$10,
  },
  lineLast: {},
  circle: {
    width: 10,
    height: 10,
    borderWidth: 2,
    borderColor: theme.colors.neutral.$10,
    borderStyle: 'solid',
    borderRadius: 50,
  },
  right: {
    justifyContent: 'center',
    gap: 5,
  },
  date: {
    fontSize: 13,
    fontWeight: '600',
    lineHeight: 20,
  },
  user: {
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  },
  fullname: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
  },
});

export default PreviewSidePanelVersionChange;
