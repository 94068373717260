import {useState} from 'react';

export function useMouseDown(initialState?: boolean) {
  const [mouseDown, setMouseDown] = useState(initialState);

  const onMouseDown = () => setMouseDown(true);
  const onMouseUp = () => setMouseDown(false);

  return {mouseDown, onMouseDown, onMouseUp};
}
