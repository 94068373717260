import theme from 'config/theme';
import {useEffect, useState} from 'react';
import {Text} from 'react-native';
import {StyleSheet, View} from 'react-native';
import {validatePassword} from 'utils/common/validation';

interface Props {
  password: string;
  setPasswordIsValid: (inp: boolean) => void;
}

export function PasswordInputValidation(props: Props) {
  const [passwordRequirements, setPasswordRequirements] = useState<
    Array<string>
  >([]);
  const textColor = theme.colors.neutral.$2Base;

  const [validationStatus, setValidationStatus] = useState<
    'Weak' | 'Strong. Excellent!'
  >('Weak');

  const getPasswordRequirements = (password: string) => {
    const res = [];
    let status: 'Weak' | 'Strong. Excellent!' = 'Weak';
    if (!password) {
      return {
        status,
        requirements: res,
      };
    }
    const result = validatePassword(password);
    if (result.specialCharacter) res.push('a symbol');
    if (result.upperCase) res.push('an uppercase letter');
    if (result.lowerCase) res.push('a lowercase letter');
    if (result.digit) res.push('a number');
    if (result['8OrMore']) res.push('8 characters minimum');

    if (res.length === 0) status = 'Strong. Excellent!';
    else status = 'Weak';

    return {
      status,
      requirements: res,
    };
  };

  useEffect(() => {
    const res = getPasswordRequirements(props.password);
    setValidationStatus(res.status);
    setPasswordRequirements(res.requirements);
  }, [props.password]);

  useEffect(() => {
    if (validationStatus === 'Strong. Excellent!') {
      props.setPasswordIsValid(true);
    } else props.setPasswordIsValid(false);
  }, [validationStatus]);

  if (!props.password) return null;

  return (
    <View style={styles.validation}>
      <View style={styles.progressBarContainer}>
        <View
          style={[
            styles.progressBar,
            {backgroundColor: theme.colors.neutral.$12},
          ]}>
          <View
            style={[
              styles.progressBar,
              {
                width: `${
                  passwordRequirements.length === 0
                    ? 100
                    : (5 - passwordRequirements.length) * 20
                }%`,
                backgroundColor:
                  passwordRequirements.length === 0
                    ? theme.colors.brand.$4Base
                    : theme.colors.caution.$4Base,
              },
            ]}
          />
        </View>
        <View style={styles.progressBarDescription}>
          <Text style={[styles.passwordRequirementStatus, {color: textColor}]}>
            {`${
              passwordRequirements?.length ? 5 - passwordRequirements.length : 5
            }/5 requirements`}
          </Text>
          <Text
            style={[styles.passwordStatus, {color: theme.colors.neutral.$4}]}>
            {validationStatus}
          </Text>
        </View>
      </View>
      {passwordRequirements?.length ? (
        <View>
          <Text
            style={[
              styles.requirement,
              {color: textColor, fontWeight: '700', marginBottom: '0.5rem'},
            ]}>
            Your password must contain:
          </Text>
          {passwordRequirements?.length
            ? passwordRequirements.map(requirement => (
                <Text
                  key={requirement}
                  style={[styles.requirement, {color: textColor}]}>
                  {`\u2022 ${requirement}`}
                </Text>
              ))
            : null}
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  validation: {
    width: '100%',
  },
  progressBarContainer: {
    marginTop: '1rem',
  },
  progressBar: {
    height: 6,
    width: '100%',
    marginBottom: 4,
    borderRadius: 5,
  },
  progressBarDescription: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  passwordStatus: {
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
  },
  passwordRequirementStatus: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
  },
  requirement: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
});
