import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Tabs, {createTabItem} from 'interface/base/Tabs';
import {OnboardSteps} from 'interface/stacks/onboard/lib/consts';
import {StyleSheet, View} from 'react-native';
import {useDispatch} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import {AppTabs} from '../../types';

interface Props {
  activeTab: AppTabs;
  setActiveTab: (value: AppTabs) => void;
}

export default function Header({activeTab, setActiveTab}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCreateNewOrganization = () => {
    dispatch(onboarding.default.actions.restartOnboarding());
    navigate(
      `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${OnboardSteps.Organization}`,
    );
  };

  const tabs = [
    createTabItem(
      AppTabs.Workspaces,
      'Workspaces',
      'lucide:app-window-mac',
      activeTab,
    ),
    createTabItem(AppTabs.Shares, 'Shares', 'lucide:package-2', activeTab),
  ];

  return (
    <View style={styles.root}>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={value => setActiveTab(value as AppTabs)}
      />
      <Button
        onPress={handleCreateNewOrganization}
        variant="secondary"
        startEnhancer={
          <Icon name="lucide:plus" color={theme.colors.neutral.$black} />
        }>
        <Trans>New Org</Trans>
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
  },
});
