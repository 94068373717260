import {type IStorage, Storage} from 'extensions/storage';
import {blobToBase64} from 'utils/common/image';

interface CacheConfig {
  cacheDuration?: number;
  cacheDbKey: string;
  cachePrefix: string;
}

export abstract class BaseCachingService {
  protected cacheDuration: number;
  protected storage: IStorage;
  private cachePrefix: string;
  private static DEFAULT_CACHE_DURATION = 5 * 60 * 1000;

  constructor(config: CacheConfig) {
    this.cacheDuration =
      config?.cacheDuration || BaseCachingService.DEFAULT_CACHE_DURATION;
    this.storage = new Storage().init(config.cacheDbKey);
    this.cachePrefix = config.cachePrefix;
  }

  protected getCacheKey(id: string): string {
    return `${this.cachePrefix}${id}`;
  }

  protected async saveBase64ToCache(
    id: string,
    base64: string | undefined,
  ): Promise<void> {
    const cacheData = {
      timestamp: Date.now(),
      data: base64,
    };

    await this.storage.setItem(this.getCacheKey(id), JSON.stringify(cacheData));
  }

  protected async getCachedContent(id: string): Promise<string | undefined> {
    try {
      const cacheData = await this.storage.getItem(this.getCacheKey(id));
      if (!cacheData) return undefined;

      const {timestamp, data} = JSON.parse(cacheData);
      const age = Date.now() - timestamp;

      if (age > this.cacheDuration) {
        await this.clearCache(id);
        return undefined;
      }

      return data ?? '';
    } catch (error) {
      return undefined;
    }
  }

  protected async handleBlobContent(id: string, blob: Blob): Promise<string> {
    return new Promise(resolve =>
      blobToBase64(blob, content => {
        this.saveBase64ToCache(id, content);
        resolve(content);
      }),
    );
  }

  abstract getContent(id: string): Promise<string>;

  async updateContent(id: string, content: string): Promise<void> {
    try {
      await this.saveBase64ToCache(id, content);
    } catch (error) {
      console.error('Error in updateContent:', error);
    }
  }

  async clearCache(id: string): Promise<void> {
    try {
      const cacheKey = this.getCacheKey(id);
      const cacheData = await this.storage.getItem(cacheKey);

      if (cacheData) {
        await this.storage.removeItem(cacheKey);
      }
    } catch (error) {
      console.error('Error clearing cache:', error);
      throw error;
    }
  }

  async clearAllCache(): Promise<void> {
    try {
      await this.storage.clearAll();
    } catch (error) {
      console.error('Error clearing all cache:', error);
      throw error;
    }
  }
}
