import {t} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {Requests, api} from 'fast-sdk';
import {Button} from 'interface/common/Button';
import {FormError} from 'interface/common/FormError';
import {useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import user, {defaultUser} from 'store/slices/user';
import {RouteLayout} from '../_layout/RouteLayout';
import Auth from '../consts';

interface PasswordResetCompletedProps {
  password: string;
}

export const PasswordResetCompleted = (props: PasswordResetCompletedProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loginLoading, setLoginLoading] = useState<boolean>(false);

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const continueToDashboard = async () => {
    setErrorMessage('');
    const userEmail = await Auth.getResetPasswordEmail();
    if (!userEmail) {
      // The user email should be stored in the indexedDB in the forgot password page
      // if this toast is showing the email is not stored correctly in the forgot password
      // page, so we need to redirect the user to the "forgot password" page again
      toast.show(
        t`The user email is not embedded correctly please try the process again`,
        {type: 'danger'},
      );
      navigate(ROUTES.NOT_LOGGED_IN.FORGOT_PASSWORD);
      return;
    }
    setLoginLoading(true);
    let token = '';
    let details = defaultUser;
    api.auth
      .signIn({email: userEmail, password: props.password})
      .then(res => {
        if (res?.result) {
          token = res.auth_token;
          Requests.setAuthToken(res.auth_token);
          Auth.setAuthToken(res.auth_token);
          return api.user.userDetails();
        }
        throw Error(res.error?.text);
      })
      .then(res => {
        if (res?.result) {
          details = res.user;
          return api.organization.getListOfAccessibleOrganizations();
        }
        setErrorMessage(res.error?.text);
      })
      .then(({result, orgs: orgsList, error}) => {
        if (result) {
          dispatch(user.actions.setUserToken(token));
          dispatch(user.actions.setUserDetails(details));
          dispatch(user.actions.setOrganizationsList({orgsList}));
          navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`);
        } else {
          throw Error(error?.text);
        }
      })
      .catch(err => {
        setErrorMessage(err);
      })
      .finally(() => {
        setLoginLoading(false);
      });
  };

  return (
    <RouteLayout
      title={t`Password reset complete`}
      subTitle=""
      customContentContainerStyle={{marginBottom: '2rem'}}>
      {errorMessage ? <FormError errorMessage={errorMessage} /> : null}
      <Button
        type="Primary"
        label={t`Continue`}
        ariaLabel={t`Continue`}
        customRootStyle={{height: 48}}
        onPress={continueToDashboard}
        loading={loginLoading}
        disabled={loginLoading}
        customLoadingColor={theme.colors.neutral.$white}
      />
    </RouteLayout>
  );
};
