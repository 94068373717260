import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';
import {formatRelativeTime} from '../helpers';

interface Props {
  date: Date;
}

export function TimeLabel({date}: Props) {
  return (
    <View style={styles.container}>
      <Icon name="lucide:clock" size={16} color={theme.colors.neutral.$300} />
      <Typography size="xs" color={theme.colors.neutral.$500}>
        {formatRelativeTime(date)}
      </Typography>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 3,
    gap: 4,
  },
});
