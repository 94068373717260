import {t} from '@lingui/macro';
import theme from 'config/theme';
import {WORKSPACE_SHARED} from 'constants/routes';
import {HoveredView} from 'interface/base/HoveredView';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';

interface ShareAllItemsProps {
  onPress: () => void;
}

export function ShareAllItems({onPress}: ShareAllItemsProps) {
  const isSelected = location.pathname.endsWith(`/${WORKSPACE_SHARED}`);

  return (
    <HoveredView
      onPress={onPress}
      style={[styles.shareItem, isSelected && styles.selected]}>
      <View style={styles.shareItemIcon}>
        <Icon
          name={'lucide:layout-list'}
          size={18}
          color={theme.colors.neutral.$2Base}
        />
      </View>
      <Typography
        variant="regular"
        size="sm"
        color={theme.colors.neutral.$2Base}>
        {t`All Shares`}
      </Typography>
    </HoveredView>
  );
}

const styles = StyleSheet.create({
  shareItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    gap: 6,
  },
  shareItemIcon: {
    padding: 3,
    borderRadius: 4,
  },
  selected: {
    backgroundColor: theme.colors.neutral.$100,
  },
});
