import {t} from '@lingui/macro';
import images from 'config/images';
import analytics from 'extensions/analytics';
import device from 'extensions/device';
import {LoginButton} from 'interface/stacks/auth/components/LoginButton';
import {useCallback, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch, useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import type {SSOMethod} from 'store/slices/user/types';
import {
  getPreviouslySignedInMethod,
  getPreviouslySignedInResult,
  loginGoogle,
  loginMicrosoft,
} from 'utils/fast/auth';

const width = 550;
const height = 570;
const left = screen.width / 2 - width / 2;
const top = screen.height / 2 - height / 2;
const loginWindowProps = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`;

const prevSignedInMapper = (
  method: SSOMethod,
  previouslyMethod: SSOMethod | '',
  previouslyAccepted: boolean,
) => {
  if (previouslyMethod && method === previouslyMethod) {
    if (previouslyAccepted) {
      return {
        accepted: true,
        error: false,
      };
    }
    return {
      accepted: false,
      error: true,
    };
  }
  return {
    accepted: false,
    error: false,
  };
};

interface LoginButtonsProps {
  signUpButtons: boolean;
}

export function LoginButtons(props: LoginButtonsProps) {
  const [prevSignInAccepted, setPrevSignInAccepted] = useState<boolean>();
  const [prevSignInMethod, setPrevSignInMethod] = useState<SSOMethod | ''>();
  const [openedWindow, setOpenedWindow] = useState<Window>();
  const userToken = useSelector(user.selectors.getToken);

  const dispatch = useDispatch();
  const toast = useToast();

  const openNewWindow = (source: string) => {
    const newWindow = window.open(source, '_blank', loginWindowProps);
    setOpenedWindow(newWindow);
  };

  useEffect(() => {
    const getPreviousData = async () => {
      const preMethod = await getPreviouslySignedInMethod();
      const preResult = await getPreviouslySignedInResult();
      setPrevSignInMethod(preMethod as SSOMethod | '');
      setPrevSignInAccepted(preResult ? preResult : false);
    };
    getPreviousData();
  }, []);

  useEffect(() => {
    if (userToken && openedWindow) {
      openedWindow.close();
      setOpenedWindow(undefined);
    }
  }, [userToken]);

  const openGoogleLogin = async () => {
    device.dismissKeyboard();
    try {
      await loginGoogle(dispatch).then(res => {
        if (!res) {
          toast.show(t`There is a problem with connecting to the server`, {
            type: 'danger',
          });
        } else {
          openNewWindow(res);
        }
      });
    } catch (error) {
      analytics.notify(error);
      toast.show(error.message, {type: 'danger'});
    }
  };

  const openMicrosoftLogin = async () => {
    device.dismissKeyboard();
    try {
      await loginMicrosoft(dispatch).then(res => {
        if (!res) {
          toast.show(t`There is a problem with connecting to the server`, {
            type: 'danger',
          });
        } else {
          openNewWindow(res);
        }
      });
    } catch (error) {
      analytics.notify(error);
      toast.show(error.message, {type: 'danger'});
    }
  };

  const render = useCallback(() => {
    return (
      <View style={styles.root}>
        {/* <LoginButton
          key="Apple"
          title={t`Continue with Apple`}
          onPress={() => {}}
          icon={{uri: images.blackAppleLogo}}
          signUpButton={props.signUpButtons}
          previouslySignedIn={
            prevSignedInMapper('Apple', prevSignInMethod, prevSignInAccepted)
              .accepted
          }
          previouslyError={
            prevSignedInMapper('Apple', prevSignInMethod, prevSignInAccepted)
              .error
          }
          width={20}
        /> */}
        <LoginButton
          key="Google"
          title={t`Continue with Google`}
          onPress={openGoogleLogin}
          icon={{uri: images.googleLogo}}
          signUpButton={props.signUpButtons}
          previouslySignedIn={
            prevSignedInMapper('Google', prevSignInMethod, prevSignInAccepted)
              .accepted
          }
          previouslyError={
            prevSignedInMapper('Google', prevSignInMethod, prevSignInAccepted)
              .error
          }
        />
        <LoginButton
          key="Microsoft"
          title={t`Continue with Microsoft`}
          onPress={openMicrosoftLogin}
          icon={{uri: images.microsoftLogo}}
          signUpButton={props.signUpButtons}
          previouslySignedIn={
            prevSignedInMapper(
              'Microsoft',
              prevSignInMethod,
              prevSignInAccepted,
            ).accepted
          }
          previouslyError={
            prevSignedInMapper(
              'Microsoft',
              prevSignInMethod,
              prevSignInAccepted,
            ).error
          }
          withoutMarginBottom
        />
      </View>
    );
  }, []);

  return render();
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
  },
});
