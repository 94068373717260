import theme from 'config/theme';
import {addMonths, format, isPast, subMonths} from 'date-fns';
import {Button} from 'interface/common/Button';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

interface Props {
  showDate: Date;
  setShowDate: (activeDate: Date) => void;
}

export function MonthSelect(props: Props) {
  const prevMonth = () => props.setShowDate(subMonths(props.showDate, 1));
  const nextMonth = () => props.setShowDate(addMonths(props.showDate, 1));

  return (
    <View style={styles.root}>
      <Button
        type="Text"
        onPress={prevMonth}
        lIcon={
          <Icon
            name="chevron-left"
            size={22}
            color={theme.colors.neutral.$5}
            style={{opacity: isPast(props.showDate) ? 0.5 : 1}}
          />
        }
        disabled={isPast(props.showDate)}
        ariaLabel="Previous Month"
      />
      <Text style={styles.monthName}>
        {format(props.showDate, 'MMMM yyyy')}
      </Text>
      <Button
        type="Text"
        onPress={nextMonth}
        lIcon={
          <Icon
            name="chevron-right"
            size={22}
            color={theme.colors.neutral.$5}
            style={{opacity: 1}}
          />
        }
        ariaLabel="Next Month"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  monthName: {
    fontSize: 13,
    fontWeight: '600',
    lineHeight: 20,
    color: theme.colors.neutral.$5,
  },
});
