import {t} from '@lingui/macro';
import theme from 'config/theme';
import analytics from 'extensions/analytics';
import {api} from 'fast-sdk';
import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';
import {Button} from 'interface/common/Button';
import {CustomTextInput} from 'interface/common/CustomTextInput';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {useState} from 'react';
import {Image, Pressable, StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {type SocialLink, SocialLinkType} from 'store/slices/settings/types';
import {socialLinkTypeToLogo} from '../../base/consts';

interface LinkItemProps {
  link: SocialLink;
  getOrgDetails: () => void;
}

export const LinkItem = (props: LinkItemProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {subdomain} = useSubDomain();
  const toast = useToast();

  const getProvider = (
    linkType: SocialLinkType,
  ): Partial<keyof OrganizationDetails> => {
    switch (linkType) {
      case SocialLinkType.Facebook:
        return 'facebook';
      case SocialLinkType.Instagram:
        return 'instagram';
      case SocialLinkType.Other:
        return 'homepage';
      case SocialLinkType.Twitter:
        return 'twitter';
      case SocialLinkType.Youtube:
        return 'youtube';
    }
  };

  const deleteLink = () => {
    setLoading(true);
    const provider = getProvider(props.link.type);
    api.organization
      .updateOrganization(subdomain, {[provider]: null})
      .then(res => {
        if (res?.result) {
          props.getOrgDetails();
        }
      })
      .catch(err => {
        toast.show(err.message, {type: 'danger'});
        analytics.notify(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <View style={styles.root}>
      <Pressable>
        <Icon
          name="drag"
          size={24}
          style={{marginRight: 4}}
          color={theme.colors.neutral.$5}
        />
      </Pressable>
      <View style={styles.container}>
        <Button
          type="Secondary"
          lIcon={
            <Image
              style={styles.socialLinkImage}
              source={{uri: socialLinkTypeToLogo(props.link.type)}}
              resizeMode="contain"
            />
          }
          onPress={() => {}}
          customRootStyle={{height: 36, width: 36}}
        />
        <CustomTextInput
          value={props.link.link}
          setValue={() => {}}
          ariaLabel={t`${props.link.type} Link`}
          disabled
          customRootStyle={{width: '93%'}}
        />
      </View>
      <Button
        type="Secondary"
        lIcon={
          <Icon
            name="delete-outline"
            size={20}
            color={theme.colors.neutral.$5}
          />
        }
        onPress={deleteLink}
        customRootStyle={{height: 36, width: 36}}
        loading={loading}
        customLoadingColor={theme.colors.neutral.$2Base}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 10,
    flex: 5,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 4,
  },
  socialLinkImage: {
    width: 20,
    height: 20,
  },
});
