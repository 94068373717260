import theme from 'config/theme';
import {StyleSheet} from 'react-native';
import {Circle, Svg} from 'react-native-svg';

type Props = {
  progress: number;
};

export function ProgressCircle(props: Props) {
  return (
    <Svg style={styles.root}>
      <Circle
        cx="9"
        cy="9"
        r="9"
        stroke={theme.colors.brand.$5}
        strokeOpacity={1}
        strokeWidth={4}
        fill="rgba(255,255,255,0.2)"
        strokeDasharray={`${(props.progress * 100) / 1.7} 100`}
      />
      <Circle cx="9" cy="9" r="7" fill={theme.colors.neutral.$1} />
    </Svg>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 18,
    height: 18,
    borderRadius: 50,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: [{rotate: '-90deg'}],
  },
});
