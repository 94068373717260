import theme from 'config/theme';
import type {
  BillingMeterDataPoint,
  BillingMeterUsage,
} from 'fast-sdk/src/api/billing/consts';
import {formatNumber} from 'fast-sdk/src/utils';
import Tabs, {createTabItem} from 'interface/base/Tabs';
import Typography from 'interface/base/Typography';
import {BarChart} from 'interface/base/charts/BarChart';
import {Loading} from 'interface/common/Loading';
import {useMemo, useState} from 'react';
import {StyleSheet, View} from 'react-native';

export type Props = {
  meters: BillingMeterUsage;
  loading: boolean;
};

const DEFAULT_WIDTH = 1256;
const DEFAULT_HEIGHT = 268;

enum TrailingDaysTabs {
  Week = 'week',
  Fortnight = 'fortnight',
  Month = 'month',
}

const DAYS_OPTIONS = {
  [TrailingDaysTabs.Week]: 7,
  [TrailingDaysTabs.Fortnight]: 14,
  [TrailingDaysTabs.Month]: 30,
};

const getChartData = (
  dataPoints: Array<BillingMeterDataPoint>,
  trailingDays: number,
) => {
  const data = dataPoints.map(dataPoint => ({
    x: new Date(dataPoint.start_time).getDate().toString(),
    y: dataPoint.value,
    label: formatNumber(dataPoint.value),
  }));

  return trailingDays === 30 ? data : data.slice(-trailingDays);
};

const formatDate = (date: Date) => {
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
  }).format(date);
};

const getDateRange = (dataPoints: Array<BillingMeterDataPoint>) => {
  const startDate = new Date(dataPoints[0].start_time);
  const endDate = new Date(dataPoints[dataPoints.length - 1].start_time);
  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

export function CreditUsage({meters, loading}: Props) {
  const [trailingDays, setTrailingDays] = useState(TrailingDaysTabs.Month);
  const [chartWidth] = useState(DEFAULT_WIDTH);
  const {data_points} = meters;

  const data = useMemo(() => {
    return getChartData(data_points, DAYS_OPTIONS[trailingDays]);
  }, [data_points, trailingDays]);

  const tabs = Object.entries(DAYS_OPTIONS).map(([key, value]) =>
    createTabItem(key, `${value} Days`, '', trailingDays),
  );

  const handleChangeTab = (value: string) => {
    setTrailingDays(value as TrailingDaysTabs);
  };

  return (
    <View>
      {loading ? (
        <Loading />
      ) : (
        <View style={styles.container}>
          <Typography
            variant="regular"
            size="md"
            color={theme.colors.neutral.$700}>
            Credit Usage: {getDateRange(data_points)}
          </Typography>
          <BarChart width={chartWidth} height={DEFAULT_HEIGHT} data={data} />
          <Tabs
            rootStyle={styles.tabs}
            tabStyle={styles.tab}
            tabs={tabs}
            activeTab={trailingDays}
            setActiveTab={handleChangeTab}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 2,
    borderColor: theme.colors.neutral.$50,
    borderRadius: 16,
    paddingHorizontal: 24,
    paddingTop: 16,
    paddingBottom: 24,
    gap: 16,
  },
  tabs: {
    height: 36,
  },
  tab: {
    height: 32,
    minWidth: 98,
  },
});
