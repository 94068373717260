import {StyleSheet, Text, View} from 'react-native';
import ClearButton from '../components/ClearButton';
import DateFilter from '../components/DateFilter';
import EventFilter from '../components/EventFilter';
import PeopleFilter from '../components/PeopleFilter';
import TypeFilter from '../components/TypeFilter';
import type {Filters} from '../consts';

type Props = {
  filters: Filters;
  updateFilter: (key: string, value?: any) => void;
};

const isFilterApplied = (filters: Filters) => {
  return Object.values(filters).some(value =>
    Array.isArray(value) ? value.length > 0 : Boolean(value),
  );
};

const RecentFilters = ({filters, updateFilter}: Props) => {
  return (
    <View style={styles.root}>
      <PeopleFilter
        selected={filters.people}
        onChange={value => updateFilter('people', value)}
        onRemove={() => updateFilter('people', null)}
      />
      <TypeFilter
        selected={filters.type}
        onChange={value => updateFilter('type', value)}
        onRemove={() => updateFilter('type', null)}
      />
      <DateFilter
        selected={filters.date}
        onChange={value => updateFilter('date', value)}
        onRemove={() => updateFilter('date', null)}
      />
      <EventFilter
        selected={filters.event}
        onChange={value => updateFilter('event', value)}
        onRemove={() => updateFilter('event', null)}
      />
      {isFilterApplied(filters) && (
        <ClearButton onPress={() => updateFilter('clear')} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingVertical: 10,
    gap: 8,
  },
});

export default RecentFilters;
