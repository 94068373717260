import {StyleSheet, Text, type TextStyle} from 'react-native';

interface Props {
  text: string;
  customStyle?: TextStyle;
}

export function PageSubTitle(props: Props) {
  return <Text style={[styles.root, props.customStyle]}>{props.text}</Text>;
}

const styles = StyleSheet.create({
  root: {
    fontFamily: '',
    fontSize: 16.5,
    lineHeight: 26,
    fontWeight: '600',
    marginBottom: '0.5rem',
  },
});
