import {ErrorFlowActions} from 'errors/types';
import {randomString} from 'fast-sdk/src/utils';
import type {Actions, Store} from 'store/slices/errors/types';

export function addError(state: Store, action: Actions['AddError']): void {
  const newError = {...action.payload, id: randomString(20)};
  const {flowAction} = newError.errorConfiguration;

  const isToastError = flowAction === ErrorFlowActions.Toast;

  if (isToastError) {
    state.toastErrors.push(newError);
  } else if (flowAction === ErrorFlowActions.NoAction) {
    state.noActionErrors.push(newError);
  } else {
    state.lastError = newError;
    state.showError = true;
  }
}

export function decreaseRemainingRetries(state: Store) {
  if (state.lastError.availableRetries > 0) {
    state.lastError.availableRetries -= 1;
  }
}

export function hideError(state: Store) {
  state.showError = false;
}
