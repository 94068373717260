import {getOrganizationMembersDetails} from 'interface/stacks/auth/consts';
import {useEffect, useState} from 'react';
import type {Member} from 'store/slices/settings/types';
import type {Organization} from 'store/slices/user/types';

export function useOrgMembers(organization: Organization) {
  const [members, setMembers] = useState<Array<Member>>();
  const [loading, setLoading] = useState(false);

  const getMembers = async (domain: string) => {
    setLoading(true);
    try {
      const members = await getOrganizationMembersDetails(domain);
      setMembers(members);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMembers(organization.domain);
  }, []);

  return {members, loading};
}
