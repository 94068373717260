import type {PayloadAction} from '@reduxjs/toolkit';
import type {EventActivity, EventProfile} from 'fast-sdk/src/api/events/consts';
import {getProfileKey, profileInitialState} from './helpers';
import type {Store} from './types';

export const setProfileLoading = (
  state: Store,
  action: PayloadAction<{profile: EventProfile; loading: boolean}>,
) => {
  const key = getProfileKey(action.payload.profile);
  if (!state.profiles[key]) {
    state.profiles[key] = {...profileInitialState};
  }
  state.profiles[key].loading = action.payload.loading;
};

export const addProfileEvents = (
  state: Store,
  action: PayloadAction<{profile: EventProfile; events: EventActivity[]}>,
) => {
  const key = getProfileKey(action.payload.profile);
  if (!state.profiles[key]) {
    state.profiles[key] = {...profileInitialState};
  }
  state.profiles[key].events = [
    ...state.profiles[key].events,
    ...action.payload.events,
  ];
};

export const setProfileError = (
  state: Store,
  action: PayloadAction<{profile: EventProfile; error: string | null}>,
) => {
  const key = getProfileKey(action.payload.profile);
  if (!state.profiles[key]) {
    state.profiles[key] = {...profileInitialState};
  }
  state.profiles[key].error = action.payload.error;
};

export const setProfileHasMore = (
  state: Store,
  action: PayloadAction<{profile: EventProfile; hasMore: boolean}>,
) => {
  const key = getProfileKey(action.payload.profile);
  if (!state.profiles[key]) {
    state.profiles[key] = {...profileInitialState};
  }
  state.profiles[key].hasMore = action.payload.hasMore;
};

export const resetProfile = (state, action: PayloadAction<EventProfile>) => {
  const key = getProfileKey(action.payload);
  state.profiles[key] = {...profileInitialState};
};
