import theme from 'config/theme';
import {StyleSheet, Text, View, type ViewStyle} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

interface FormErrorProps {
  errorMessage: string;
  customRootStyle?: ViewStyle;
}

export function FormError(props: FormErrorProps) {
  return (
    <View style={[styles.root, props.customRootStyle]}>
      <Icon name="alert" size={20} color={theme.colors.danger.$4Base} />
      <Text style={styles.errorMessage}>{props.errorMessage}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '1rem',
    columnGap: 14,
    backgroundColor: theme.colors.danger.$8,
    borderRadius: 5,
    marginBottom: '1.5rem',
  },
  errorMessage: {
    color: theme.colors.danger.$e1,
    textAlign: 'left',
    width: '100%',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 20,
  },
});
