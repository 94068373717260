export const formatStringForUrl = (str: string) => {
  return (
    str
      .trim()
      .toLowerCase()
      // Replace special characters with hyphen
      .replace(/[^a-z0-9\s-]/g, '-')
      // Replace spaces with hyphen
      .replace(/\s+/g, '-')
      // Replace multiple consecutive hyphens with a single hyphen
      .replace(/-+/g, '-')
      // Remove leading and trailing hyphens
      .replace(/^-+|-+$/g, '')
  );
};

export const prependedWorkspaceName = (
  workspaceName: string,
  orgId: string,
) => {
  return formatStringForUrl(`${orgId}-${workspaceName}`);
};
