import Skeleton from 'interface/common/Skeleton';
import {StyleSheet, View} from 'react-native';
import Card from '../content/common/Card';

const WORKSPACE_CARD_SKELETON_ROWS = 4;

export const WorkspaceCardSkeleton = () => (
  <Card style={styles.card}>
    <View style={styles.cardLeftContent}>
      <Skeleton width={40} height={40} />
      <Skeleton width={150} height={20} />
    </View>
    <Skeleton width={24} height={24} />
  </Card>
);

const WorkspacesCardsSkeleton = () => (
  <View style={styles.grid}>
    {[...Array(WORKSPACE_CARD_SKELETON_ROWS)].map((_, index) => (
      <WorkspaceCardSkeleton key={`workspace-card-${index}`} />
    ))}
  </View>
);

const styles = StyleSheet.create({
  card: {
    justifyContent: 'space-between',
    elevation: 2,
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 1},
    shadowOpacity: 0.2,
    shadowRadius: 2,
  },
  cardLeftContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 24,
    gap: 16,
  },
});

export default WorkspacesCardsSkeleton;
