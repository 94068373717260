import {createSelector} from '@reduxjs/toolkit';
import type {Store} from 'store';

const x = (store: Store) => store;

export const getUsersActivity = createSelector(
  x,
  store => store.activity.users,
);

export const getWorkspacesActivity = createSelector(
  x,
  store => store.activity.workspaces,
);

export const getOrganizationsActivity = createSelector(
  x,
  store => store.activity.organizations,
);

export const getSharesActivity = createSelector(
  x,
  store => store.activity.shares,
);

export const getUserActivities = (userId: string) =>
  createSelector(x, store => store.activity.users[userId]);

export const getWorkspaceActivities = (workspaceId: string) =>
  createSelector(x, store => store.activity.workspaces[workspaceId]);

export const getOrganizationActivities = (organizationId: string) =>
  createSelector(x, store => store.activity.organizations[organizationId]);

export const getShareActivities = (shareId: string) =>
  createSelector(x, store => store.activity.shares[shareId]);

export const getWorkspaceActivityByPrefix = (
  workspaceId: string,
  activityKeyPrefix: string,
  folderKey: string,
) =>
  createSelector(x, store =>
    Object.entries(store.activity.workspaces[workspaceId]?.activities ?? {})
      .filter(
        ([key]) =>
          key.startsWith(activityKeyPrefix) &&
          key.endsWith(folderKey) &&
          key.split(':').length === 3,
      )
      .map(([key, {lastServerUpdate}]) => ({id: key, lastServerUpdate})),
  );

export const getShareActivitiesByPrefix = (
  shareId: string,
  activityKeyPrefix: string,
  folderKey: string,
) =>
  createSelector(x, store =>
    Object.entries(store.activity.shares[shareId]?.activities ?? {})
      .filter(
        ([key]) =>
          key.startsWith(activityKeyPrefix) &&
          key.endsWith(folderKey) &&
          key.split(':').length === 3,
      )
      .map(([key, {lastServerUpdate}]) => ({id: key, lastServerUpdate})),
  );

export const getWorkspaceActivityForPreview = (
  workspaceId: string,
  activityKeyPrefix: string,
) =>
  createSelector(x, store =>
    Object.entries(store.activity.workspaces[workspaceId]?.activities ?? {})
      .filter(
        ([key]) =>
          key.startsWith(activityKeyPrefix) && key.split(':').length === 2,
      )
      .map(([key, {lastServerUpdate}]) => ({id: key, lastServerUpdate})),
  );

export const getShareActivitiesForPreview = (
  shareId: string,
  activityKeyPrefix: string,
) =>
  createSelector(x, store =>
    Object.entries(store.activity.shares[shareId]?.activities ?? {})
      .filter(
        ([key]) =>
          key.startsWith(activityKeyPrefix) && key.split(':').length === 2,
      )
      .map(([key, {lastServerUpdate}]) => ({id: key, lastServerUpdate})),
  );
