import {colors} from 'config/styles';
import theme from 'config/theme';
import type {ReactElement} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {ToastProvider} from 'react-native-toast-notifications';

export const CustomToastProvider = ({children}: {children: ReactElement}) => {
  return (
    <ToastProvider
      normalColor={colors.neutral.dark}
      dangerColor={colors.secondary.darkRed}
      renderType={{
        neutral: toast => (
          <View style={styles.toastContainer}>
            <Text style={styles.toastMessage}>{toast?.message ?? ''}</Text>
          </View>
        ),
      }}>
      {children}
    </ToastProvider>
  );
};

const styles = StyleSheet.create({
  toastContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.brand.$4Base,
    paddingVertical: 16,
    paddingHorizontal: 24,
    marginTop: 16,
    borderRadius: 6,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3.05,
    elevation: 4,
  },
  toastMessage: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$white,
  },
});
