import {Trans} from '@lingui/macro';
import {StyleSheet, Text, View, useWindowDimensions} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import theme from 'config/theme';
import type {SSOMethod} from 'store/slices/user/types';

interface LoginPageErrorProps {
  errorOption: SSOMethod;
}

export function LoginPageError(props: LoginPageErrorProps) {
  const screen = useWindowDimensions();
  const isSmallScreen = screen.width < 790;
  const isVerySmallScreen = screen.width < 450;
  const classes = {
    errorContainer: [
      styles.errorContainer,
      {
        width: isSmallScreen ? '100%' : 420,
      },
    ],
  };

  return (
    <View
      style={[
        classes.errorContainer,
        {justifyContent: isVerySmallScreen ? 'space-between' : 'center'},
      ]}>
      <Icon name="alert" size={20} color={theme.colors.danger.$4Base} />
      <View style={styles.errorContentContainer}>
        <Text style={styles.errorTitle}>
          <Trans>Authentication failed...</Trans>
        </Text>
        <Text style={styles.errorText}>
          <Trans>
            There was an issue logging in with the selected method (
            {props.errorOption}). Please try again.
          </Trans>
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  errorContainer: {
    maxWidth: '100%',
    paddingVertical: 10,
    paddingHorizontal: 14,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: theme.colors.danger.$8,
    marginBottom: '1.5rem',
    gap: 14,
  },
  errorIconContainer: {
    height: '100%',
  },
  errorContentContainer: {
    maxWidth: 318,
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  errorTitle: {
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '700',
    color: theme.colors.danger.$e1,
  },
  errorText: {
    fontSize: 13,
    lineHeight: 20,
    fontWeight: '400',
    color: theme.colors.danger.$e1,
  },
});
