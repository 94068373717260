import {useFlag} from '@unleash/proxy-client-react';
import theme from 'config/theme';
import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import {useHover} from 'interface/common/hooks/useHover';
import ActionButton from 'interface/stacks/content-viewer/components/ActionButton';
import {type GestureResponderEvent, StyleSheet, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialIcons';
import type {FilesItem} from 'store/slices/files/types';
import {hexToRGBA} from 'utils/common/color';

type Props = {
  show: boolean;
  rename: () => void;
  details: () => void;
  download: () => void;
  openQuickShare: (e: GestureResponderEvent) => void;
  openActivity: () => void;
  previewShare: () => void;
  customizeShare: () => void;
  openShare: () => void;
  tooltipPos?: 'top' | 'bottom';
  file: FilesItem;
};

const FileListItemActions = ({
  show,
  rename,
  details,
  download,
  openQuickShare,
  openActivity,
  previewShare,
  customizeShare,
  openShare,
  file,
  tooltipPos = 'top',
}: Props) => {
  const {isHover, onHoverIn, onHoverOut} = useHover();

  const enableDetailActivity = useFlag('detail-activity');

  return (
    <View
      style={[styles.root, {opacity: show || isHover ? 1 : 0}]}
      // @ts-ignore
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}>
      <ActionButton
        onPress={details}
        hoverStyle={styles.hover}
        tooltipText="Details"
        tooltipPos={tooltipPos}
        kind="dark"
        icon={
          <MCIcon
            name="information-outline"
            size={20}
            color={theme.colors.neutral.$4}
          />
        }
      />
      {file.type === StorageItemType.Link ? (
        <>
          <ActionButton
            onPress={previewShare}
            hoverStyle={styles.hover}
            tooltipText="Preview Share"
            tooltipPos={tooltipPos}
            kind="dark"
            icon={
              <MCIcon
                name="open-in-new"
                size={20}
                color={theme.colors.neutral.$4}
              />
            }
          />
          <ActionButton
            onPress={customizeShare}
            hoverStyle={styles.hover}
            tooltipText="Customize"
            tooltipPos={tooltipPos}
            kind="dark"
            icon={
              <MCIcon
                name="tune-variant"
                size={20}
                color={theme.colors.neutral.$4}
              />
            }
          />
          <ActionButton
            onPress={openShare}
            hoverStyle={styles.hover}
            tooltipText="Share"
            tooltipPos={tooltipPos}
            kind="dark"
            icon={
              <MCIcon name="share" size={20} color={theme.colors.neutral.$4} />
            }
          />
        </>
      ) : (
        <>
          <ActionButton
            onPress={download}
            hoverStyle={styles.hover}
            tooltipText="Download"
            tooltipPos={tooltipPos}
            kind="dark"
            icon={
              <Icon name="save-alt" size={20} color={theme.colors.neutral.$4} />
            }
          />
          {file.type === StorageItemType.Folder && (
            <ActionButton
              onPress={rename}
              hoverStyle={styles.hover}
              tooltipText="Rename"
              tooltipPos={tooltipPos}
              kind="dark"
              icon={
                <MCIcon
                  name="form-textbox"
                  size={20}
                  color={theme.colors.neutral.$4}
                />
              }
            />
          )}
          {file.type === StorageItemType.File && (
            <ActionButton
              onPress={openQuickShare}
              hoverStyle={styles.hover}
              tooltipText="Quick Share"
              tooltipPos={tooltipPos}
              kind="dark"
              icon={
                <MCIcon
                  name="account-arrow-right"
                  size={20}
                  color={theme.colors.neutral.$4}
                />
              }
            />
          )}
          {enableDetailActivity && (
            <ActionButton
              onPress={openActivity}
              hoverStyle={styles.hover}
              tooltipText="Activity"
              tooltipPos={tooltipPos}
              kind="dark"
              icon={
                <MCIcon
                  name="timeline-text"
                  size={20}
                  color={theme.colors.neutral.$4}
                />
              }
            />
          )}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'visible',
    width: 150,
  },
  icon: {
    cursor: 'pointer',
    padding: 8,
  },
  hover: {
    backgroundColor: hexToRGBA(theme.colors.neutral.$black, 0.08),
  },
  button: {
    minWidth: 36,
    minHeight: 36,
    maxHeight: 36,
  },
});

export default FileListItemActions;
