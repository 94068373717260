import {t} from '@lingui/macro';
import {Picker} from '@react-native-picker/picker';
import theme from 'config/theme';
import type {MemberPermission} from 'fast-sdk/src/api/workspace/consts';
import {useMemo, useState} from 'react';
import {StyleSheet} from 'react-native';

type Access = Extract<MemberPermission, 'admin' | 'member' | 'guest'>;

const usePermissionsPicker = (
  initialPermission: Access = 'member',
  disabled = false,
) => {
  const [permission, setPermission] = useState<Access>(initialPermission);

  const PickerComponent = useMemo(
    () => (
      <Picker
        selectedValue={permission}
        onValueChange={setPermission}
        style={styles.picker}
        enabled={!disabled}>
        <Picker.Item label={t`Member`} value="member" />
        <Picker.Item label={t`Guest`} value="guest" />
        <Picker.Item label={t`Admin`} value="admin" />
      </Picker>
    ),
    [permission, disabled],
  );

  return {permission, PickerComponent};
};

const styles = StyleSheet.create({
  picker: {
    display: 'flex',
    paddingHorizontal: 8,
    paddingVertical: 6,
    gap: 8,
    borderRadius: 3,
    borderWidth: 0,
    height: 36,
    borderRightWidth: 8,
    borderRightColor: 'transparent',
    color: theme.colors.neutral.$4,
    boxShadow: `0px 0px 0px 1px ${theme.colors.neutral.$10}`,
    borderColor: 'transparent',
  },
});
export default usePermissionsPicker;
