import {t} from '@lingui/macro';
import theme from 'config/theme';
import type {ShareLink} from 'fast-sdk/src/api/share/consts';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Modal from 'interface/common/Modal';
import {TextInput} from 'interface/common/TextInput';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';

interface Props {
  link?: ShareLink | undefined;
  saveLink: (link: ShareLink) => Promise<void>;
  removeAndCancelLink: (link: ShareLink) => void;
  done: () => void;
}

export function EditLinkModal({
  link,
  done,
  saveLink,
  removeAndCancelLink,
}: Props) {
  const [title, setTitle] = useState(link?.title);
  const [url, setUrl] = useState(link?.url);

  const [saveLoading, setSaveLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const handleSave = async () => {
    setSaveLoading(true);
    await saveLink({...link, title, url});
    setSaveLoading(false);
  };

  const handleRemove = async () => {
    setRemoveLoading(true);
    await removeAndCancelLink(link);
    setRemoveLoading(false);
  };

  return (
    <Modal title={`${link ? t`Edit Link` : t`Add Link`}`} close={done}>
      <TextInput
        label={t`Label`}
        value={title}
        placeHolder={'Enter a label'}
        setInputValue={title => setTitle(title)}
      />
      <TextInput
        label={t`URL`}
        value={url}
        placeHolder={'https://mywebsite.com'}
        setInputValue={url => setUrl(url)}
      />
      <View style={styles.formFooter}>
        <View>
          {link && (
            <Button
              variant="secondary"
              loading={removeLoading}
              startEnhancer={
                <Icon name="lucide:trash" color={theme.colors.neutral.$black} />
              }
              onPress={handleRemove}>
              {t`Remove`}
            </Button>
          )}
        </View>
        <View style={styles.rightButtons}>
          <Button variant="secondary" onPress={done}>
            {t`Cancel`}
          </Button>
          <Button
            variant="primary"
            onPress={handleSave}
            loading={saveLoading}
            disabled={!title || !url}>
            {t`Done`}
          </Button>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  linkForm: {
    flexDirection: 'column',
    display: 'flex',
  },
  formFooter: {
    flexDirection: 'row',
    paddingTop: 16,
    justifyContent: 'space-between',
  },
  rightButtons: {
    flexDirection: 'row',
    gap: 10,
  },
});
