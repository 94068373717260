import theme from 'config/theme';
import {WORKSPACE_ROOT} from 'constants/routes';
import {useNavigate, useParams} from 'extensions/navigation';
import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import Typography from 'interface/base/Typography';
import useWorkspaceMenuSettings from 'interface/stacks/app/hooks/useWorkspaceMenuSettings';
import {Fragment, useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {selectors} from 'store/slices/files';
import * as user from 'store/slices/user';
import {FolderBreadcrumbsItem} from './FolderBreadcrumbsItem';
import {useWorkspaceContext} from './hooks/useWorkspaceContext';

import type {MultiplayerMember} from 'interface/multiplayer/types';
import type {GestureResponderEvent} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';

export interface FolderBreadcrumbsProps {
  workspaceName: string;
  workspaceId: string;
  folderId: string;
  members?: MultiplayerMember[];
  onFolderPress?: (e: GestureResponderEvent) => void;
}

export function FolderBreadcrumbs(props: FolderBreadcrumbsProps) {
  const {folderKey} = useParams<'folderKey'>();

  const {workspace} = useWorkspaceContext();
  const currentUser = useSelector(user.selectors.getUserDetails);
  const {handleOpenMenu} = useWorkspaceMenuSettings(workspace, currentUser.id);
  const {workspaceId} = useParams<'workspaceId'>();
  const navigate = useNavigate();
  const folder = useSelector(selectors.getItem(props.folderId));
  const items = useSelector(selectors.getItems);
  const root = useMemo(
    () => [props.workspaceId, props.workspaceName],
    [props.workspaceId, props.workspaceName],
  );

  const buildHierarchy = (fileItem: FilesItem): FilesItem[] => {
    return fileItem
      ? [...buildHierarchy(items[fileItem.parent]), fileItem]
      : [];
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const handleMenuPress = (event: GestureResponderEvent) => {
    props.onFolderPress?.(event);
  };

  const handleWorkspacePress = (event: GestureResponderEvent) => {
    event.preventDefault();
    event.stopPropagation();
    handleOpenMenu(event);
  };

  const baseUrl = `/workspace/${workspaceId}`;
  const hierarchy = useMemo(
    () => [root, ...buildHierarchy(folder)],
    [root, folder?.id],
  );

  return (
    <View style={styles.root}>
      <View style={styles.breadcrumbs}>
        {hierarchy.map((item, i, paths) => {
          const first = i === 0;
          const last = i === paths.length - 1;
          const isFolder = 'id' in item;
          const folderId = isFolder ? item.id : item[0];
          const folderName = isFolder ? item.name : item[1];
          const isShared = isFolder
            ? item.type === StorageItemType.Link
            : false;
          const link = first
            ? `${baseUrl}/storage/${WORKSPACE_ROOT}`
            : `${baseUrl}/storage/${folderId}`;
          return (
            <Fragment key={i}>
              <FolderBreadcrumbsItem
                id={folderId}
                name={folderName}
                root={first ? workspace?.id : undefined}
                state={last ? 'Current' : 'Default'}
                workspaceId={workspace.id}
                members={props.members}
                isShared={isShared}
                isLast={last}
                onPressRoot={handleWorkspacePress}
                onPress={e =>
                  last && folderKey
                    ? first
                      ? handleWorkspacePress(e)
                      : handleMenuPress(e)
                    : handleNavigation(link)
                }
              />
              {!last && (
                <Typography size="md" variant="medium">
                  /
                </Typography>
              )}
            </Fragment>
          );
        })}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.$white,
  },
  breadcrumbs: {
    gap: 4,
    marginVertical: 6,
    marginHorizontal: 6,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
