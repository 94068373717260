import {ROUTES, WORKSPACE_ROOT} from 'constants/routes';
import {handleApiError} from 'errors';
import {useSearchParams} from 'extensions/navigation/hooks/useSearchParams';
import {Requests, api} from 'fast-sdk';
import {ApiErrors} from 'fast-sdk/src/requests/errors';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import {AppLoading} from '../app/AppLoading';
import Auth from '../auth/consts';
import {useLayoutWorkspaces} from '../workspace/hooks/useLayoutWorkspaces';
import useSubdomainAuth from './useSubdomainAuth';

export function FromSubdomain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {subdomain} = useSubDomain();
  const {getToken, getUserId, loaded} = useSubdomainAuth();
  const userIdFromSubdomain = getUserId();

  const {fetchAndUpdateWorkspaces} = useLayoutWorkspaces();

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const userDetails = useSelector(user.selectors.getUserDetails);

  const redirectToApp = () => {
    const workspaceRoot = `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${WORKSPACE_ROOT}`;
    const redirectTo = searchParams.get('redirectTo') ?? workspaceRoot;
    navigate(redirectTo, {
      replace: true,
    });
  };

  const getUserInfo = async (token: string) => {
    Requests.setAuthToken(token);
    try {
      const {result, org} =
        await api.organization.getOrganizationDetails(subdomain);

      if (result) {
        const [userResponse, orgListResponse] = await Promise.all([
          api.user.userDetails(),
          api.organization.getListOfAccessibleOrganizations(),
          fetchAndUpdateWorkspaces(),
        ]);
        const {result: userResult, user: userDetails} = userResponse;
        const {result: orgListResult, orgs} = orgListResponse;

        if (userResult && orgListResult) {
          dispatch(user.default.actions.setUserProfilePic({img: ''}));
          dispatch(user.default.actions.setUserDetails(userDetails));
          await Auth.setAuthToken(token);

          dispatch(user.default.actions.setSelectedOrganization({org}));
          dispatch(
            user.default.actions.setOrganizationsList({
              orgsList: orgs,
            }),
          );
          return;
        }
      }
      navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
    } catch (err) {
      handleApiError({
        code: ApiErrors.CorsError,
        text: 'There is a CORS error, please click on the try again button',
      });
    }
  };

  async function loadUserSession(token: string) {
    try {
      await getUserInfo(token);
      redirectToApp();
    } catch (err) {
      navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
    }
  }

  useEffect(() => {
    if (loaded) {
      if (isLoggedIn) {
        if (userIdFromSubdomain === userDetails.id) {
          redirectToApp();
          return;
        }
      }
      const token = getToken();
      if (token) {
        dispatch(user.default.actions.setUserToken(token));
        loadUserSession(token);
      } else {
        navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`);
      }
    }
  }, [loaded]);

  const showLoading = !isLoggedIn || userIdFromSubdomain !== userDetails.id;

  return showLoading ? <AppLoading /> : null;
}
