import {useDispatch, useSelector} from 'react-redux';
import contentViewer, {selectors} from 'store/slices/content-viewer';

const useContentViewerMode = () => {
  const dispatch = useDispatch();
  const isThumbnail = useSelector(selectors.getIsThumbnail);

  const setIsThumbnail = (isThumbnail: boolean) => {
    dispatch(contentViewer.actions.setIsThumbnail({isThumbnail}));
  };

  return {isThumbnail, setIsThumbnail};
};

export default useContentViewerMode;
