import {t} from '@lingui/macro';
import theme from 'config/theme';
import {DefaultLayout} from 'fast-sdk/src/api/share/consts';
import {CustomPicker} from 'interface/common/CustomPicker';
import {CustomSwitch} from 'interface/common/CustomSwitch';
import {ModalSection} from 'interface/common/Modal';
import React from 'react';
import {StyleSheet, View} from 'react-native';

interface FeaturesSectionProps {
  allowComments: boolean;
  setAllowComments: (allow: boolean) => void;
  displayType: DefaultLayout;
  setDisplayType: (type: DefaultLayout) => void;
  intelligence: boolean;
  setIntelligence: (intelligence: boolean) => void;
}

export default function FeaturesSection({
  allowComments,
  setAllowComments,
  displayType,
  setDisplayType,
  intelligence,
  setIntelligence,
}: FeaturesSectionProps) {
  return (
    <View style={styles.column}>
      <ModalSection title={'Features'} showDivider />
      <View style={styles.column}>
        <View style={styles.feature}>
          <CustomSwitch
            label="Intelligence"
            onChange={setIntelligence}
            checked={intelligence}
          />
        </View>
        <View style={styles.feature}>
          <CustomSwitch
            label="Allow comments"
            onChange={setAllowComments}
            checked={allowComments}
          />
        </View>
        <View style={styles.feature}>
          <CustomPicker
            label={t`Default file layout`}
            options={Object.values(DefaultLayout).map(value => ({
              title: value,
              value,
            }))}
            setSelected={setDisplayType}
            selected={displayType}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  column: {
    flexDirection: 'column',
  },
  feature: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$12,
    height: 46,
    justifyContent: 'center',
  },
});
