import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {api} from 'fast-sdk';
import {CustomTextInput} from 'interface/common/CustomTextInput';
import {FormError} from 'interface/common/FormError';
import useDebounce from 'interface/common/hooks/useDebounce';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as onboarding from 'store/slices/onboarding';
import NewLayout from './Layout';
import {prepareSubdomain, validateSubdomain} from './lib/utils';

interface Props {
  next: () => void;
}

export default function Subdomain(props: Props) {
  const toast = useToast();
  const dispatch = useDispatch();
  const {subdomain: currentSubdomain} = useSubDomain();

  const orgName = useSelector(onboarding.selectors.getOrganizationName);
  const storeSubdomain = useSelector(onboarding.selectors.getSubdomain);
  const environmentDomain = useSelector(app.selectors.getEnvironmentDomain);
  const createdOrganizationId = useSelector(
    onboarding.selectors.getCreatedOrganizationId,
  );

  const [subdomain, setSubDomain] = useState<string>(() =>
    storeSubdomain
      ? storeSubdomain
      : prepareSubdomain(orgName, environmentDomain),
  );
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [focused, setFocused] = useState<boolean>(false);

  // check if the user created an organizaiton with the current subdomain or not
  const checkUserOrganizations = async (callback?: () => void) => {
    try {
      const {result, org} =
        await api.organization.getOrganizationPublicDetails(subdomain);
      if (result && createdOrganizationId) {
        if (createdOrganizationId === org.id) {
          if (callback) callback();
          else {
            setErrorMessage('');
            toast.show(
              t`This subdomain is created by you before; you can continue with this subdomain`,
              {type: 'success'},
            );
          }
        } else {
          // @ts-ignore
          setErrorMessage(orgRes.error.text);
        }
      } else {
        setErrorMessage(t`The supplied name is already in use.`);
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const availablity = async () => {
    try {
      const {result} = await api.organization.checkDomainName(subdomain);
      if (result) {
        setErrorMessage('');
        toast.show(t`This subdomain is available`, {type: 'success'});
      } else {
        await checkUserOrganizations();
      }
    } catch (err) {
      setErrorMessage(t`The supplied name is already in use.`);
    }
  };
  const debouncedAvailablity = useDebounce(availablity, 700);

  const next = async () => {
    setErrorMessage('');
    if (validateSubdomain(subdomain)) {
      try {
        setButtonLoading(true);
        try {
          const {result} = await api.organization.checkDomainName(subdomain);
          if (result) {
            dispatch(
              onboarding.default.actions.setSubdomain({
                subdomain,
              }),
            );
            props.next();
          } else {
            await checkUserOrganizations(props.next);
          }
        } catch (err) {
          setErrorMessage(t`The supplied name is already in use.`);
        }
      } finally {
        setButtonLoading(false);
      }
    } else {
      toast.show(t`The entered subdomain is invalid`, {type: 'danger'});
    }
  };

  useEffect(() => {
    if (orgName) {
      const subdomain = prepareSubdomain(orgName, environmentDomain);
      setSubDomain(subdomain);
    }
  }, [orgName]);

  useEffect(() => {
    if (subdomain) {
      debouncedAvailablity();
    }
  }, [subdomain]);

  return (
    <NewLayout
      title="Set your subdomain"
      subtitle="All files you share will come to this domain name so your recipients will always know they’re from your organization."
      next={next}
      disabled={!subdomain || buttonLoading}
      loading={buttonLoading}
      loadingColor={theme.colors.neutral.$white}>
      <View style={styles.root}>
        {errorMessage ? <FormError errorMessage={errorMessage} /> : null}
        <View style={styles.contentContainer}>
          <View style={styles.subdomainContainer}>
            <CustomTextInput
              size="Large"
              ariaLabel="Subdomain"
              value={subdomain}
              setValue={(value: string) =>
                setSubDomain(prepareSubdomain(value, environmentDomain))
              }
              customInputStyle={styles.input}
              customRootStyle={{
                flexGrow: 3,
                flexShrink: 1,
                borderTopRightRadius: 0,
              }}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              placeholder="dundermifflin"
              disabled={buttonLoading}
            />
            <Text style={styles.text}>
              {`${environmentDomain.replace(currentSubdomain, '')}`}
            </Text>
          </View>
          {focused ? (
            <View
              style={styles.infoMessageContainer}
              aria-live="polite"
              aria-hidden={!focused}>
              <Icon
                name="alert"
                size={20}
                color={theme.colors.caution.$35Hover}
                aria-label="Info"
              />
              <Text style={styles.infoMessage}>
                <Trans>
                  Your organization URL can only contain lowercase letters,
                  numbers, and dashes (and must start with a letter or number).
                </Trans>
              </Text>
            </View>
          ) : null}
        </View>
      </View>
    </NewLayout>
  );
}

const styles = StyleSheet.create({
  root: {
    marginTop: 42,
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    height: 48,
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    textAlign: 'center',
    color: theme.colors.neutral.$5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: theme.colors.neutral.$white,
    borderWidth: 2,
    borderColor: theme.colors.neutral.$10,
  },
  button: {
    width: 'auto',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    paddingVertical: 11,
    paddingHorizontal: 14,
    borderWidth: 2,
    borderRadius: 5,
    borderColor: theme.colors.neutral.$9,
    backgroundColor: theme.colors.neutral.$white,
    marginLeft: 10,
  },
  suffix: {
    width: 'auto',
    flexShrink: 1,
    flexGrow: 1,
    height: 48,
    paddingVertical: 0,
    paddingHorizontal: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 0,
    borderColor: theme.colors.neutral.$10,
    backgroundColor: theme.colors.neutral.$white,
  },
  subdomainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
  input: {
    width: '100%',
    height: 48,
    padding: 15,
    marginBottom: 6,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.$13,
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 0,
  },
  infoMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    columnGap: 7,
  },
  infoMessage: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
});
