import {ROUTES} from 'constants/routes';
import {Navigate, Outlet} from 'extensions/navigation';
import {AppLoading} from 'interface/stacks/app/AppLoading';
import useSubdomainAuth from 'interface/stacks/subdomain/useSubdomainAuth';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import {useCheckOrgSubscription} from './hooks/useCheckOrgSubscription';

export default function ProtectedRoute() {
  const storeToken = useSelector(user.selectors.getToken);
  const {getToken, loaded} = useSubdomainAuth();
  const userDetails = useSelector(user.selectors.getUserDetails);

  useCheckOrgSubscription();

  if (storeToken && userDetails.id) {
    return <Outlet />;
  }

  const cookieToken = getToken();

  if (!loaded) {
    return <AppLoading />;
  }

  if (loaded && cookieToken && userDetails.id) {
    return <Outlet />;
  }

  return <Navigate to={ROUTES.NOT_LOGGED_IN.SIGNIN} replace />;
}
