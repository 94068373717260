import {ROUTES} from 'constants/routes';
import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import contentViewer, {
  selectors as contentViewerSelectors,
} from 'store/slices/content-viewer';
import useContentViewerParams from './useContentViewerParams';

const getNextUrl = (
  instanceNs: StorageNamespace,
  instanceId: string,
  fileId: string,
) =>
  `/${instanceNs}/${instanceId}/${ROUTES.LOGGED_IN_WITH_ORG.PREVIEW}/${fileId}`;

const usePagination = () => {
  const {fileId, instanceId, instanceNs} = useContentViewerParams();
  const {prev, next} = useSelector(contentViewerSelectors.getNeighbors);
  const files = useSelector(contentViewerSelectors.getFiles);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const index = files.findIndex(sibling => sibling.id === fileId);
    const prev = index > 0 ? files[index - 1] : null;
    const next = index < files.length - 1 ? files[index + 1] : null;

    dispatch(contentViewer.actions.setNeighbors({prev, next}));
  }, [fileId, files]);

  const handlePrev = () => {
    if (!prev) {
      return;
    }

    navigate(getNextUrl(instanceNs, instanceId, prev.id), {replace: true});
  };

  const handleNext = () => {
    if (!next) {
      return;
    }

    navigate(getNextUrl(instanceNs, instanceId, next.id), {replace: true});
  };

  return {prev, next, handlePrev, handleNext};
};

export default usePagination;
