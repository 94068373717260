import theme from 'config/theme';
import Skeleton from 'interface/common/Skeleton';
import {StyleSheet, View} from 'react-native';

const SHARES_TABLE_SKELETON_ROWS = 3;

const ShareTableRowSkeleton = () => (
  <View style={styles.row}>
    <View style={styles.cell}>
      <Skeleton width={150} height={20} />
    </View>
    <View style={styles.cell}>
      <Skeleton width={150} height={24} />
    </View>
    <View style={styles.cell}>
      <Skeleton width={150} height={20} />
    </View>
    <View style={styles.cell}>
      <Skeleton width={150} height={20} />
    </View>
    <View style={[styles.cell, styles.actionCell]}>
      <Skeleton width={24} height={24} />
    </View>
  </View>
);

const ShareTableRowsSkeleton = () => (
  <>
    {[...Array(SHARES_TABLE_SKELETON_ROWS)].map((_, index) => (
      <ShareTableRowSkeleton key={`org-shares-${index}`} />
    ))}
  </>
);

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    paddingVertical: 4,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$10,
  },
  cell: {
    flex: 4,
    justifyContent: 'center',
  },
  actionCell: {
    flex: 1,
  },
});

export default ShareTableRowsSkeleton;
