import theme from 'config/theme';
import {STORAGE_ROOT} from 'constants/routes';
import {useModal} from 'extensions/viewport/useModal';
import {usePopup} from 'extensions/viewport/usePopup';
import {ShareType} from 'fast-sdk/src/api/share/consts';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {ShareTypeBadge} from 'interface/base/ShareTypeBadge';
import Typography from 'interface/base/Typography';
import {DropdownButton} from 'interface/common/DropdownButton';
import {useCallback, useRef} from 'react';
import {
  type GestureResponderEvent,
  type MouseEvent,
  StyleSheet,
  View,
} from 'react-native';
import {ShareTypeLabel} from 'utils/fast/shares';
import {SharedCustomizeDialog} from '../shared/dialogs/SharedCustomizeDialog';

export default function useCreateNewShareMenu(workspace: WorkspaceListDetail) {
  const modal = useModal();
  const popup = usePopup();

  const triggerRef = useRef<any>(null);

  const handleMenuCommand = useCallback(
    (shareType: ShareType) => {
      switch (shareType) {
        case ShareType.Exchange:
        case ShareType.Receive:
        case ShareType.Send:
          modal.open(
            <SharedCustomizeDialog
              workspaceFolderName={workspace.folder_name}
              close={modal.close}
              folderParentId={STORAGE_ROOT}
              shareType={shareType}
            />,
          );
          break;
      }
      popup.close();
    },
    [workspace],
  );

  const renderMenu = useCallback(() => {
    return (
      <View style={styles.root}>
        <Typography
          size="sm"
          variant="regular"
          color={theme.colors.neutral.$500}>
          Create New Share
        </Typography>
        <View style={styles.menuItems}>
          {[ShareType.Exchange, ShareType.Receive, ShareType.Send].map(
            shareType => (
              <DropdownButton
                icon={<ShareTypeBadge shareType={shareType} />}
                label={ShareTypeLabel[shareType]}
                onPress={() => handleMenuCommand(shareType)}
                key={shareType}
                customStyle={styles.menuItem}
              />
            ),
          )}
        </View>
      </View>
    );
  }, []);

  const openNewShareMenu = useCallback(
    (e: MouseEvent | GestureResponderEvent) => {
      e?.preventDefault();
      e?.stopPropagation();
      popup.setPlacement('bottom-start');
      popup.setPosition(undefined);
      if (triggerRef) {
        popup.setRef(triggerRef);
      } else {
        if (e?.nativeEvent) {
          popup.setPosition({
            x: e.nativeEvent.pageX,
            y: e.nativeEvent.pageY,
          });
        }
      }
      popup.open(renderMenu(), {
        closeOnClickOutside: true,
      });
    },
    [popup, triggerRef],
  );

  return {openNewShareMenu, triggerRef};
}

const styles = StyleSheet.create({
  root: {
    padding: 24,
    borderRadius: 16,
    gap: 16,
    backgroundColor: theme.colors.neutral.$white,
    flexDirection: 'column',
    width: 280,
    boxShadow:
      '0px 6px 12px 0px rgba(0, 0, 0, 0.07), 0px 0px 0px 1px rgba(0, 0, 0, 0.04)',
  },
  menuItems: {
    flexDirection: 'column',
  },
  menuItem: {
    paddingVertical: 2,
    paddingHorizontal: 8,
  },
});
