import theme from 'config/theme';
import React from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';

type Props = {
  sheets: Array<string>;
  activeSheet: string;
  handleChangeSheet: (sheet: string) => void;
};

const SpreadSheetsPages = ({activeSheet, sheets, handleChangeSheet}: Props) => {
  return (
    <View style={styles.root}>
      {sheets.map(name => (
        <Pressable
          key={name}
          style={[
            styles.container,
            activeSheet === name && styles.activeBackground,
          ]}
          onPress={() => handleChangeSheet(name)}>
          <Text style={styles.text}>{name}</Text>
        </Pressable>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    flexGrow: 1,
  },
  container: {
    paddingHorizontal: 15,
    paddingVertical: 16,
  },
  activeBackground: {
    backgroundColor: theme.colors.brand.$4Base,
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$white,
  },
});

export default SpreadSheetsPages;
