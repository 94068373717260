import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {
  type WorkspaceListDetail,
  WorkspaceUserStatus,
} from 'fast-sdk/src/api/workspace/consts';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {WorkspaceBadge} from 'interface/common/WorkspaceBadge';
import useWorkspaceMenuSettings from 'interface/stacks/app/hooks/useWorkspaceMenuSettings';
import {useWorkspaceJoinMember} from 'interface/stacks/workspace/hooks/useWorkspaceJoinMember';
import {useRef, useState} from 'react';
import {type GestureResponderEvent, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import Card from '../common/Card';

interface WorkspaceCardProps {
  workspace: WorkspaceListDetail;
  onWorkspacePress: (workspace: WorkspaceListDetail) => void;
}

const WorkspaceCard = ({workspace, onWorkspacePress}: WorkspaceCardProps) => {
  const [isJoinLoading, setIsJoinLoading] = useState(false);

  const workspaceMenuRef = useRef<any>(null);

  const currentUser = useSelector(user.selectors.getUserDetails);

  const {handleOpenMenu} = useWorkspaceMenuSettings(workspace, currentUser.id);
  const {onJoinButtonPress} = useWorkspaceJoinMember({navigateOnJoin: false});

  const handleMenuPress = (event: GestureResponderEvent) => {
    event.preventDefault();
    handleOpenMenu(event, workspaceMenuRef);
  };

  const handleJoinWorkspacePress = async () => {
    try {
      setIsJoinLoading(true);
      await onJoinButtonPress(workspace);
      onWorkspacePress(workspace);
    } finally {
      setIsJoinLoading(false);
    }
  };

  const handleWorkspacePress = () => {
    onWorkspacePress(workspace);
  };

  const isUserJoinedWorkspace =
    workspace.user_status === WorkspaceUserStatus.Joined;

  return (
    <Card
      style={styles.card}
      onPress={isUserJoinedWorkspace ? handleWorkspacePress : undefined}>
      <View style={styles.leftContent}>
        <WorkspaceBadge id={workspace.id} size={40} style={{borderRadius: 8}} />
        <Typography variant={'medium'} color={theme.colors.neutral.$2Base}>
          {workspace.name}
        </Typography>
      </View>
      {isUserJoinedWorkspace ? (
        <Button
          ref={workspaceMenuRef}
          overrides={{
            Button: {
              style: {
                width: 40,
                height: 40,
              },
            },
          }}
          aria-label="Open Workspace Menu"
          variant="text"
          onPress={handleMenuPress}
          endEnhancer={
            <Icon
              name={'lucide:ellipsis'}
              color={theme.colors.neutral.$2Base}
            />
          }
        />
      ) : (
        <Button
          aria-label="Join Workspace"
          variant="text"
          loading={isJoinLoading}
          overrides={{
            Button: {
              style: {
                borderRadius: 8,
                backgroundColor: theme.colors.neutral.$100,
              },
            },
          }}
          onPress={handleJoinWorkspacePress}>
          <Trans>Join</Trans>
        </Button>
      )}
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    justifyContent: 'space-between',
  },
  leftContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
});

export default WorkspaceCard;
