import theme from 'config/theme';
import React from 'react';
import Icon from 'react-native-vector-icons/MaterialIcons';
import ActionButton from '../ActionButton';

type Props = {
  handleAdjustVertical: () => void;
};

const AdjustVerticalButton = ({handleAdjustVertical}: Props) => {
  return (
    <ActionButton
      icon={
        <Icon name="expand" size={20} color={theme.colors.neutral.$white} />
      }
      onPress={handleAdjustVertical}
      tooltipText="Expand Vertically"
    />
  );
};

export default AdjustVerticalButton;
