import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import type {FilesItem, FilesSort} from 'store/slices/files/types';

export function sortItems(
  aID: string,
  bID: string,
  items: {[id: string]: FilesItem},
  sort?: FilesSort,
) {
  const aItem = items[aID];
  const bItem = items[bID];

  // Always sort by type first (link first, then folder, then file)
  if (
    aItem.type === StorageItemType.Link &&
    bItem.type !== StorageItemType.Link
  )
    return -1;
  if (
    aItem.type !== StorageItemType.Link &&
    bItem.type === StorageItemType.Link
  )
    return 1;
  if (
    aItem.type === StorageItemType.Folder &&
    bItem.type !== StorageItemType.Folder
  )
    return -1;
  if (
    aItem.type !== StorageItemType.Folder &&
    bItem.type === StorageItemType.Folder
  )
    return 1;

  // If same type, then apply the sort order
  let a: FilesItem;
  let b: FilesItem;
  if (
    sort?.category === 'size' &&
    (aItem.type !== StorageItemType.File || bItem.type !== StorageItemType.File)
  ) {
    // For size sorting, folders and links always sort alphabetically ascending
    a = items[aID];
    b = items[bID];
  } else {
    // For name and date sorting, apply asc/desc to all types
    a = items[sort?.order === 'asc' ? aID : bID];
    b = items[sort?.order === 'asc' ? bID : aID];
  }

  // Sort by category
  switch (sort?.category) {
    // TODO: implement download sorting
    case 'downloads':
    case 'name':
      return a.name.localeCompare(b.name);
    case 'size':
      // For folders and links, always sort by name ascending
      if (a.type !== StorageItemType.File || b.type !== StorageItemType.File) {
        return a.name.localeCompare(b.name);
      }
      return a.size - b.size;
    case 'date':
      return new Date(a.modified).getTime() - new Date(b.modified).getTime();
    default:
      sort?.category satisfies never;
  }
}
