import theme from 'config/theme';
import {useNavigate} from 'extensions/navigation';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {Pressable} from 'react-native';
import {StyleSheet} from 'react-native';

interface Props {
  title: string;
  link: string;
  iconName: string;
  sectionName: string;
  active?: boolean;
  cominutyIcon?: boolean;
}

/**
 * Settings side menu link button component
 */

export function SettingsGeneralMenuButtonItem(props: Props) {
  const navigate = useNavigate();
  return (
    <Pressable
      onPress={() => navigate(props.link, {replace: true})}
      style={[styles.root, props.active && styles.active]}>
      <Icon name={props.iconName} size={17} color={'#000'} />
      <Typography variant="regular" size="sm" color={theme.colors.neutral.$700}>
        {props.title}
      </Typography>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 14,
    padding: 9,
    paddingLeft: 25,
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$white,
  },
  active: {
    backgroundColor: theme.colors.neutral.$100,
    borderRadius: 6,
  },
});
