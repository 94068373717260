import {createSelector} from '@reduxjs/toolkit';
import type {Store} from 'store';

const x = (store: Store) => store;

export const getOrganizationsList = createSelector(
  x,
  store => store.user.organizationsList,
);
export const getSelectedOrganization = createSelector(
  x,
  store => store.user.selectedOrganization,
);
export const getUserFirstName = createSelector(
  x,
  store => store.user.user.first_name,
);
export const getUserLastName = createSelector(
  x,
  store => store.user.user.last_name,
);
export const getUserEmail = createSelector(
  x,
  store => store.user.user.email_address,
);
export const getUserProfilePic = createSelector(
  x,
  store => store.user.profilePic,
);
export const getToken = createSelector(x, store => store.user.token);
export const getUserDetails = createSelector(x, store => store.user.user);
// TODO we need to get all the organizations that the user has completed onboarded
// We need to change the organizationsList to have completed org info
export const getOnboardedOrganizations = createSelector(
  x,
  store => store.user.organizationsList,
);

export const getAvailableProfiles = createSelector(x, store => ({
  hasOrgs: store.user.hasOrgs,
  hasWorkspaces: store.user.hasWorkspaces,
  hasShares: store.user.hasShares,
}));
