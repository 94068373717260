import theme from 'config/theme';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import useClickOutside from '../../hooks/useClickOutside';
import ActionButton from '../ActionButton';
import MediaPopup, {type OnChangeParams} from './MediaPopup';

const OPTIONS = [
  {
    label: '0.25',
    value: 0.25,
  },
  {
    label: '0.5',
    value: 0.5,
  },
  {
    label: '0.75',
    value: 0.75,
  },
  {
    label: 'Normal',
    value: 1,
  },
  {
    label: '1.25',
    value: 1.25,
  },
  {
    label: '1.5',
    value: 1.5,
  },
  {
    label: '1.75',
    value: 1.75,
  },
];

type Props = {
  playbackRate: number;
  onChangeRate: (speed: number) => void;
};

const PlaybackSpeedButton = ({playbackRate, onChangeRate}: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const {targetRef, sourceRef} = useClickOutside(showPopup, () =>
    setShowPopup(false),
  );

  const togglePopup = () => setShowPopup(prev => !prev);

  const handleChangeSpeed = ({value}: OnChangeParams) => {
    onChangeRate(value as number);
    setShowPopup(false);
  };

  return (
    <View style={styles.root}>
      <ActionButton
        ref={sourceRef}
        text={`${playbackRate}x`}
        onPress={togglePopup}
        textStyle={styles.text}
        tooltipText="Playback speed"
        hideTooltip={showPopup}
      />
      {showPopup && (
        <MediaPopup
          ref={targetRef}
          options={OPTIONS}
          onChange={handleChangeSpeed}
          currentValue={playbackRate}
          headerText="Playback Speed"
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    position: 'relative',
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 24,
    color: theme.colors.neutral.$white,
  },
});

export default PlaybackSpeedButton;
