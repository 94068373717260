import {api} from 'fast-sdk';
import {useEffect, useState} from 'react';
import {buildFileDetails} from 'store/slices/files/helpers';
import type {FilesItem} from 'store/slices/files/types';

interface QuickShareState {
  file: FilesItem | null;
  isLoading: boolean;
  error: boolean;
}

const initialState: QuickShareState = {
  file: null,
  isLoading: true,
  error: false,
};

export default function useGetQuickShare(quickShareId: string | undefined) {
  const [state, setState] = useState<QuickShareState>(initialState);

  useEffect(() => {
    const fetchQuickShare = async () => {
      if (!quickShareId) {
        setState(prevState => ({...prevState, isLoading: false}));
        return;
      }

      try {
        const {result, quickshare} =
          await api.workspace.getQuickShare(quickShareId);

        setState(prevState => ({
          ...prevState,
          file: result
            ? buildFileDetails(
                quickshare.node,
                undefined,
                undefined,
                quickshare,
              )
            : null,
          error: !result,
          isLoading: false,
        }));
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          error: true,
          isLoading: false,
        }));
      }
    };

    setState(initialState);
    fetchQuickShare();
  }, [quickShareId]);

  return state;
}
