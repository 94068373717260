import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {StyleSheet} from 'react-native';

interface Props {
  workspaceId: string;
}

export function AiChatSelectFilesButton({workspaceId}: Props) {
  return (
    <Button
      variant="secondary"
      onPress={() => {}}
      overrides={{
        Button: {
          style: {
            backgroundColor: theme.colors.neutral.$100,
            borderRadius: 6,
            paddingHorizontal: 8,
            paddingVertical: 2,
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      }}
      startEnhancer={
        <Icon
          name="lucide:filter"
          size={18}
          color={theme.colors.neutral.$700}
        />
      }>
      <Typography size="xs" color={theme.colors.neutral.$700}>
        Select Files
      </Typography>
    </Button>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
