import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {
  ROUTES,
  WORKSPACE_ROOT,
  WORKSPACE_STORAGE,
  WORKSPACE_TRASH,
} from 'constants/routes';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import {StyleSheet, View} from 'react-native';

import {useFlag} from '@unleash/proxy-client-react';
import {useNavigate, useParams} from 'extensions/navigation';
import {useDispatch, useSelector} from 'react-redux';
import {useAddNewNode} from './hooks/useAddNewNode';
import {useModalPurge} from './hooks/useModalPurge';
import {useWorkspaceContext} from './hooks/useWorkspaceContext';

import {FolderLayoutSwitchButton} from './FolderLayoutSwitchButton';

import {slices} from 'store';
import {selectors} from 'store/slices/files';

export interface FolderHeaderProps {
  id: string;
}

export function FolderHeader(props: FolderHeaderProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileSort = useSelector(selectors.getSort);
  const parentFolder = useSelector(selectors.getParent(props.id));
  const showRecent = useFlag('recent-files');
  const {folderKey} = useParams<'folderKey'>();
  const {workspace, uploader} = useWorkspaceContext();
  const {addNewNodeButtonRef, openAddNewNodePopup} = useAddNewNode({
    instanceId: workspace.id,
    instanceAlt: workspace.folder_name,
    instanceNs: 'workspace',
    folderKey,
    uploader,
  });

  const isTrash = props.id === WORKSPACE_TRASH;
  const showBack = folderKey !== WORKSPACE_ROOT;
  const purgeTrashModal = useModalPurge(
    null,
    workspace.folder_name,
    'workspace',
  );

  const handleBack = () => {
    if (isTrash) {
      navigate(-1);
    } else {
      navigate(
        `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspace.folder_name}/storage/${parentFolder}`,
      );
    }
  };

  return (
    <View style={styles.root}>
      <View style={styles.group}>
        {showBack && (
          <Button
            onPress={handleBack}
            variant="secondary"
            startEnhancer={
              <Icon
                name="lucide:arrow-left"
                color={theme.colors.neutral.$black}
              />
            }
            overrides={{
              Button: {
                style: {
                  width: 32,
                  height: 32,
                  marginRight: 8,
                },
              },
            }}
          />
        )}
        <Button
          ref={addNewNodeButtonRef}
          onPress={openAddNewNodePopup}
          variant="secondary"
          startEnhancer={
            <Icon name="lucide:plus" color={theme.colors.neutral.$black} />
          }>
          <Trans>New</Trans>
        </Button>
      </View>
      <View style={styles.group}>
        {isTrash ? (
          <Button
            aria-label="Empty Trash"
            variant="text"
            onPress={() => purgeTrashModal.open()}
            endEnhancer={
              <Icon name="lucide:trash" color={theme.colors.danger.$4Base} />
            }
          />
        ) : (
          <>
            <Button
              aria-label="Sort"
              variant="text"
              onPress={() =>
                dispatch(
                  slices.files.actions.sort({
                    category: 'name',
                    order: fileSort.order === 'asc' ? 'desc' : 'asc',
                  }),
                )
              }
              endEnhancer={
                <Icon
                  name={
                    fileSort.order === 'asc'
                      ? 'lucide:arrow-down-wide-narrow'
                      : 'lucide:arrow-up-wide-narrow'
                  }
                  color={theme.colors.neutral.$black}
                />
              }
            />
            {showRecent && (
              <Button
                aria-label="Recent"
                variant="text"
                onPress={() => {
                  navigate(
                    `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspace.folder_name}/${ROUTES.LOGGED_IN_WITH_ORG.RECENTS}`,
                  );
                }}
                endEnhancer={
                  <Icon
                    name="lucide:clock"
                    color={theme.colors.neutral.$black}
                  />
                }
              />
            )}
            <Button
              aria-label="Trash"
              variant="text"
              onPress={() => {
                navigate(
                  `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspace.folder_name}/${WORKSPACE_STORAGE}/${WORKSPACE_TRASH}`,
                );
              }}
              endEnhancer={
                <Icon name="lucide:trash" color={theme.colors.neutral.$black} />
              }
            />
            {!isTrash && <FolderLayoutSwitchButton />}
          </>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderColor: theme.colors.neutral.$12,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
  },
});
