import type {
  WSResponse,
  WebSocketResponseType,
} from 'fast-sdk/src/websockets/types';
import type {EntityType} from 'store/slices/activity/types';

export enum CommunicationMode {
  WebSocket = 'websocket',
  Polling = 'polling',
}

export type PollFn<T extends WebSocketResponseType> = () => Promise<
  WSResponse<T>
>;

export interface EntityActivityHandlerOptions {
  entityId: string;
  entityType: EntityType;
  pollingWaitTime?: number;
}
