import {t} from '@lingui/macro';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import type {Organization} from 'store/slices/user/types';
import AddItemCard from './common/AddItemCard';
import OrganizationSection from './organization/OrganizationSection';

const PageContent = ({
  organizations,
  isWorkspaces,
  isSharesLoading,
}: {
  organizations: Organization[];
  isWorkspaces: boolean;
  isSharesLoading: boolean;
}) => {
  return (
    <View style={styles.root}>
      {organizations.map(organization => (
        <OrganizationSection
          key={`org-section-${organization.id}`}
          organization={organization}
          isWorkspaces={isWorkspaces}
          isSharesLoading={isSharesLoading}
        />
      ))}
      {organizations.length === 0 && (
        <AddItemCard
          onPress={() => {}}
          label={t`You aren't a member of any Workspaces. Create an Org to get started.`}
          ariaLabel="Add New Organization"
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    gap: 48,
  },
});

export default PageContent;
