import theme from 'config/theme';
import {
  type LegacyRef,
  type ReactElement,
  type RefObject,
  useCallback,
  useState,
} from 'react';
import {
  type NativeSyntheticEvent,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  type TextInputProps,
  type TextInputSubmitEditingEventData,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import {type SelectionMode, useTextSelection} from './hooks/useTextSelection';

type InputSize = 'Small' | 'Medium' | 'Large';

interface CustomTextInputProps {
  reference?: RefObject<null | unknown>;
  placeholder?: string;
  label?: string;
  size?: InputSize;
  value: string;
  setValue: (inp: string) => void;
  ariaLabel: string;
  isInValid?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  customRootStyle?: ViewStyle | Array<ViewStyle>;
  customInputStyle?: ViewStyle | Array<ViewStyle>;
  customLabelStyle?: TextStyle | Array<TextStyle>;
  customProps?: TextInputProps;
  rightIcon?: ReactElement;
  iconOnClick?: () => void;
  rightIconTitle?: string;
  errorMessage?: string;
  infoMessage?: string;
  enterKeyPressed?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  selectInput?: SelectionMode;
}

export function CustomTextInput(props: CustomTextInputProps) {
  const {selection, disableSelection} = useTextSelection(
    props?.selectInput,
    props?.customProps?.defaultValue,
  );
  const [focused, setFocused] = useState<boolean>(false);

  const sizeStyle = (size: InputSize) => {
    switch (size) {
      case 'Small':
        return styles.smallInput;
      case 'Medium':
        return styles.mediumInput;
      case 'Large':
        return styles.largeInput;
      default:
        return styles.smallInput;
    }
  };

  const borderBottomStyle = useCallback(() => {
    if (props.disabled) return styles.disabled;
    if (props.readOnly) return styles.regular;
    if (props.isInValid) {
      if (focused) return styles.focused;
      return styles.error;
    }
    if (props.value) {
      if (focused) return styles.focused;
      return styles.regular;
    }
    if (focused) return styles.focused;
    return styles.regular;
  }, [focused, props.disabled, props.isInValid, props.readOnly, props.value]);

  const renderLabel = () => {
    if (window) {
      // The style of the React Native is not compatible with the React so
      // I tried to fix this issue with removing the style from the typescript
      // checks
      // @ts-ignore
      return (
        <label
          style={{...styles.label, ...props.customLabelStyle}}
          htmlFor={props.ariaLabel + props.label}>
          {props.label}
        </label>
      );
    }
    return (
      <Text
        style={[styles.label, props.customLabelStyle]}
        aria-label={props.label}
        nativeID={props.ariaLabel + props.label}>
        {props.label}
      </Text>
    );
  };

  return (
    <View style={[styles.container, props.customRootStyle]}>
      {props.label ? renderLabel() : null}
      <View
        style={{
          width: '100%',
          position: 'relative',
          marginBottom: props.infoMessage || props.errorMessage ? 6 : 0,
        }}>
        <TextInput
          ref={props.reference as LegacyRef<TextInput>}
          id={props.ariaLabel + props.label}
          aria-label={props.ariaLabel}
          value={props.value}
          onChangeText={text => props.setValue(text)}
          placeholder={props.placeholder}
          aria-disabled={props.disabled}
          onFocus={() => {
            setFocused(true);
            if (props.onFocus) props.onFocus();
          }}
          onBlur={() => {
            setFocused(false);
            if (props.onBlur) props.onBlur();
          }}
          style={[
            sizeStyle(props.size),
            borderBottomStyle(),
            props.customInputStyle,
            props.readOnly && styles.readOnly,
          ]}
          multiline={false}
          placeholderTextColor={theme.colors.neutral.$6}
          onSubmitEditing={
            props.enterKeyPressed
              ? (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) =>
                  props.enterKeyPressed()
              : undefined
          }
          selection={selection}
          onChange={disableSelection}
          onSelectionChange={disableSelection}
          {...props.customProps}
        />
        {props.rightIcon ? (
          <Pressable
            role="button"
            aria-label={props.rightIconTitle}
            style={styles.rightIcon}
            onPress={props.iconOnClick}>
            {props.rightIcon}
          </Pressable>
        ) : null}
      </View>
      {props.infoMessage && focused ? (
        <View
          style={styles.infoMessageContainer}
          aria-live="polite"
          aria-hidden={!props.infoMessage || !focused}>
          <Icon
            name="alert"
            size={20}
            color={theme.colors.caution.$35Hover}
            aria-label="Info"
          />
          <Text style={styles.infoMessage}>{props.infoMessage}</Text>
        </View>
      ) : null}
      {props.isInValid && props.errorMessage && !focused ? (
        <View
          style={styles.errorMessageContainer}
          aria-live="polite"
          aria-hidden={!props.isInValid}>
          <Icon
            name="alert"
            size={20}
            color={theme.colors.danger.$4Base}
            aria-label="Error"
          />
          <Text numberOfLines={1} style={styles.errorMessage}>
            {props.errorMessage}
          </Text>
        </View>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  smallInput: {
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    outlineColor: 'transparent',
    width: '100%',
    color: theme.colors.neutral.$2Base,
    paddingHorizontal: 8,
    paddingVertical: 6,
    backgroundColor: theme.colors.neutral.$13,
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  mediumInput: {
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    outlineColor: 'transparent',
    width: '100%',
    color: theme.colors.neutral.$2Base,
    paddingHorizontal: 8,
    paddingVertical: 8,
    backgroundColor: theme.colors.neutral.$13,
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 1.57,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  largeInput: {
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    outlineColor: 'transparent',
    width: '100%',
    color: theme.colors.neutral.$2Base,
    paddingHorizontal: 10,
    paddingVertical: 10,
    backgroundColor: theme.colors.neutral.$13,
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  regular: {
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    boxShadow: `0px -1px ${theme.colors.neutral.$10} inset`,
  },
  focused: {
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    boxShadow: `0px -2px ${theme.colors.brand.$4Base} inset`,
    outlineStyle: 'none',
  },
  disabled: {
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    boxShadow: '0',
    cursor: 'not-allowed',
  },
  error: {
    // This attribute is just for web version and because of that we need
    // to ignore it in the typscript with the following command
    // @ts-ignore
    boxShadow: `0px -1px ${theme.colors.danger.$4Base} inset`,
  },
  // errorFocused: {
  //   // This attribute is just for web version and because of that we need
  //   // to ignore it in the typscript with the following command
  //   // @ts-ignore
  //   boxShadow: `0px -2px ${theme.colors.danger.$e2} inset`,
  // },
  label: {
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 1.66,
    color: theme.colors.neutral.$2Base,
    marginBottom: 6,
  },
  rightIcon: {
    position: 'absolute',
    height: '100%',
    right: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorMessage: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.danger.$e2,
  },
  errorMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: 7,
  },
  infoMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    columnGap: 7,
  },
  infoMessage: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
  readOnly: {
    color: theme.colors.neutral.$6,
  },
});
