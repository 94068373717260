import theme from 'config/theme';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import TimeProgress from 'interface/stacks/content-viewer/components/TimeProgress';
import FileThumbnail from 'interface/stacks/workspace/storage/thumbnail/FileThumbnail';
import {StyleSheet, View} from 'react-native';
import Pagination from '../../components/Pagination';
import useContentViewerMode from '../../hooks/useContentViewerMode';
import useFileUrl from '../../hooks/useFileUrl';
import type {ViewerProps} from '../../types';
import ContentComments from '../ContentComments';
import ControlBar from '../ControlBar';
import AudioWrapper, {type AudioWrapperMimeType} from './AudioWrapper';
import Waveform from './Waveform';
import ControlsLeft from './controls/ControlsLeft';
import ControlsMiddle from './controls/ControlsMiddle';
import ControlsRight from './controls/ControlsRight';

const AudioViewer = ({file}: ViewerProps) => {
  const {isThumbnail} = useContentViewerMode();
  const {url, loading} = useFileUrl(file);

  return (
    <AudioWrapper
      title={file?.name}
      url={url}
      loading={loading}
      type={file?.mimetype as AudioWrapperMimeType}>
      <View style={styles.root}>
        <View style={styles.content}>
          {loading ? (
            <FileThumbnail item={file} size={FileThumbnailSizes.Preview} />
          ) : (
            <Waveform url={url} />
          )}
        </View>
        <View style={styles.bottom}>
          {url && (
            <ControlBar
              top={
                <>
                  <TimeProgress />
                  {!isThumbnail && <ContentComments />}
                </>
              }
              left={<ControlsLeft />}
              middle={<ControlsMiddle />}
              right={<ControlsRight />}
            />
          )}
        </View>
        {!isThumbnail && <Pagination />}
      </View>
    </AudioWrapper>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
  },
  player: {
    flex: 1,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.colors.neutral.$black,
  },
  bottom: {
    maxHeight: 94,
  },
  bottomLoadingContainer: {
    paddingVertical: 32,
    backgroundColor: theme.colors.neutral.$1,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral.$black,
  },
});

export default AudioViewer;
