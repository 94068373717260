import {ActivityTypes} from 'fast-sdk/src/websockets/types';
import {resetSlice} from 'store/utils';
import {formatFileId} from 'utils/fast/files';
import {initialState} from '.';
import {
  type Actions,
  type ActivityTypeWithTimestamp,
  type Store,
  entityTypeToStoreKey,
} from './types';

export function purge(state: Store) {
  return resetSlice(state, initialState);
}

/**
 * Add activity types here that don't need
 * to store the activity for each entity.
 *
 * Uploads are ignored as we process all
 * active upload sessions on the base activity.
 */
const IGNORE_ACTIVITY_PREFIXES: ActivityTypes[] = [ActivityTypes.UPLOADS];

const addUTCIfNeeded = (dateStr: string) => {
  if (dateStr && !dateStr.includes('UTC')) {
    return `${dateStr} UTC`;
  }
  return dateStr;
};

const processActivityType = (activityType: string) => {
  if (
    (activityType.startsWith(`${ActivityTypes.STORAGE}:`) ||
      activityType.startsWith(`${ActivityTypes.PREVIEW}:`)) &&
    !activityType.includes('-')
  ) {
    const [activityPrefix, fileId, folderKey] = activityType.split(':');
    const formattedFileId = formatFileId(fileId);
    const formattedFolderKey = folderKey ? `:${formatFileId(folderKey)}` : '';
    return `${activityPrefix}:${formattedFileId}${formattedFolderKey}`;
  }
  return activityType;
};

const skipActivityPrefix = (activityPrefix: string) => {
  return IGNORE_ACTIVITY_PREFIXES.some(prefix =>
    activityPrefix.startsWith(`${prefix}:`),
  );
};

const updateActivities = (
  currentActivities: ActivityTypeWithTimestamp,
  newActivities: ActivityTypeWithTimestamp,
): ActivityTypeWithTimestamp => {
  currentActivities = currentActivities || {};
  return Object.entries(newActivities).reduce(
    (acc, [activityTypeKey, activity]) => {
      // Transform activity type to match the format in the local redux store
      const activityType = processActivityType(activityTypeKey);
      // Skip activity if it starts with an ignored prefix
      if (skipActivityPrefix(activityType)) return acc;
      // Add activity to the accumulator
      acc[activityType] = {
        lastServerUpdate: addUTCIfNeeded(
          activity.lastServerUpdate ||
            currentActivities[activityType]?.lastServerUpdate,
        ),
        lastClientUpdate:
          activity.lastClientUpdate ||
          currentActivities[activityType]?.lastClientUpdate ||
          null,
      };
      return acc;
    },
    {...currentActivities},
  );
};

export const setEntityActivities = (
  state: Store,
  action: Actions['setEntityActivities'],
) => {
  const {entityId, entityType, activities, lastActivity} = action.payload;
  const targetEntity = entityTypeToStoreKey[entityType];

  if (targetEntity) {
    const currentEntityState = state[targetEntity][entityId] || {};
    const updatedActivities = updateActivities(
      currentEntityState.activities,
      activities,
    );

    state[targetEntity][entityId] = {
      lastActivity: lastActivity || currentEntityState.lastActivity,
      activities: updatedActivities,
    };
  }
};

export const setEntitiesLastClientUpdate = (
  state: Store,
  action: Actions['setEntitiesLastClientUpdate'],
) => {
  const {entities, entityType, activities, lastClientUpdate} = action.payload;
  const targetEntity = entityTypeToStoreKey[entityType];

  if (targetEntity) {
    entities.forEach(entityId => {
      if (!state[targetEntity][entityId]) {
        state[targetEntity][entityId] = {activities: {}};
      }
      activities.forEach(activityType => {
        if (!state[targetEntity][entityId].activities[activityType]) {
          state[targetEntity][entityId].activities[activityType] = {};
        }
        state[targetEntity][entityId].activities[
          activityType
        ].lastClientUpdate = lastClientUpdate;
      });
    });
  }
};

export const removeEntityActivity = (
  state: Store,
  action: Actions['removeEntityActivity'],
) => {
  const {entityId, entityType, activityType} = action.payload;

  const targetEntity = entityTypeToStoreKey[entityType];
  if (targetEntity && state[targetEntity][entityId]) {
    const updatedEntityActivities = {
      ...state[targetEntity][entityId],
    };
    delete updatedEntityActivities[activityType];

    state[targetEntity] = {
      ...state[targetEntity],
      [entityId]: updatedEntityActivities,
    };
  }
};

export const removeEntity = (state: Store, action: Actions['removeEntity']) => {
  const {entityId, entityType} = action.payload;

  const targetEntity = entityTypeToStoreKey[entityType];
  if (targetEntity) {
    const updatedEntityActivities = {...state[targetEntity]};
    delete updatedEntityActivities[entityId];

    state[targetEntity] = updatedEntityActivities;
  }
};
