import {addDays, addMonths, endOfDay, startOfDay} from 'date-fns';

export enum DateRangeType {
  TODAY = 'today',
  LAST_7_DAYS = 'last7Days',
  LAST_30_DAYS = 'last30Days',
  CUSTOM = 'custom',
}

export type DateRange = {
  type: DateRangeType;
  start: Date;
  end: Date;
};

export type Filters = {
  people: Array<string>;
  type: Array<string>;
  date: DateRange | null;
  event: Array<string>;
};

export const initialFilter: Filters = {
  people: [],
  type: [],
  date: null,
  event: [],
};

export type OnChangeFilter<T = string> = (value: T | null) => void;
export type OnRemoveFilter = () => void;

export const getLabel = (
  selected: Array<string>,
  defaultLabel: string,
  labelKeys: Record<string, string>,
) => {
  if (selected.length === 0) {
    return defaultLabel;
  }

  if (selected.length === 1) {
    return labelKeys[selected[0]];
  }

  return `${defaultLabel} (${selected.length})`;
};

const getToday = () => {
  const now = new Date();
  const start = startOfDay(now);
  const end = endOfDay(now);
  return {start, end};
};

const getLastWeek = () => {
  const end = new Date();
  const lastWeek = addDays(new Date(), -7);
  const start = startOfDay(lastWeek);
  return {start, end};
};

const getLastMonth = () => {
  const end = new Date();
  const lastMonth = addMonths(new Date(), -1);
  const start = startOfDay(lastMonth);
  return {start, end};
};

const getDefaultCustom = () => {
  const now = new Date();
  const start = startOfDay(now);
  const end = endOfDay(addDays(now, 1));
  return {start, end};
};

export const DATE_VALUES: Record<DateRangeType, DateRange> = {
  [DateRangeType.TODAY]: {type: DateRangeType.TODAY, ...getToday()},
  [DateRangeType.LAST_7_DAYS]: {
    type: DateRangeType.LAST_7_DAYS,
    ...getLastWeek(),
  },
  [DateRangeType.LAST_30_DAYS]: {
    type: DateRangeType.LAST_30_DAYS,
    ...getLastMonth(),
  },
  [DateRangeType.CUSTOM]: {type: DateRangeType.CUSTOM, ...getDefaultCustom()},
};
