import theme from 'config/theme';
import type React from 'react';
import {type PropsWithChildren, forwardRef} from 'react';
import {StyleSheet, View, type ViewProps} from 'react-native';

type Props = {
  //
} & ViewProps;

const DropdownPillContent = forwardRef(
  ({children}: PropsWithChildren<Props>, ref: React.ForwardedRef<View>) => {
    return (
      <View style={styles.root} ref={ref}>
        {children}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    zIndex: 3,
    position: 'absolute',
    top: 38,
    left: 0,
    paddingVertical: 7,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 7,
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 6px 12px 0px rgba(0, 0, 0, 0.07)',
  },
});

export default DropdownPillContent;
