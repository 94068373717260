import {api} from 'fast-sdk';
import {useState} from 'react';

export const useFetchChatDetail = () => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchChatDetails = async (workspaceName: string, chatId: string) => {
    setIsLoading(true);
    try {
      const response = await api.workspaceAichat.getChatDetail(
        workspaceName,
        chatId,
      );
      if (response.result) {
        const chat = response;
        return chat;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, fetchChatDetails};
};
