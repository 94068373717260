import {t} from '@lingui/macro';
import theme from 'config/theme';
import {CustomTextInput} from 'interface/common/CustomTextInput';
import {PasswordInputValidation} from 'interface/stacks/auth/components/PasswordInputValidation';
import {useState} from 'react';
import {
  StyleSheet,
  type TextInputProps,
  View,
  type ViewStyle,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

interface Props {
  password: string;
  setPassword: (password: string) => void;
  setPasswordIsValid?: (inp: boolean) => void;
  withValidation?: boolean;
  customRootStyle?: ViewStyle;
  customInputRootStyle?: ViewStyle;
  customInputStyle?: ViewStyle;
  disabled?: boolean;
  label?: string;
  ariaLabel?: string;
  customProps?: TextInputProps;
  errorMessage?: string;
}

export function PasswordInput(props: Props) {
  const [hidePassword, setHidePassword] = useState<boolean>(true);

  return (
    <View style={[styles.root, props.customRootStyle]}>
      <View style={styles.inputContainer}>
        <CustomTextInput
          size="Large"
          customProps={{secureTextEntry: hidePassword, ...props.customProps}}
          value={props.password}
          setValue={(password: string) => props.setPassword(password)}
          placeholder="Enter your password"
          customInputStyle={[styles.input, props.customInputStyle]}
          label={props.label ? props.label : null}
          customRootStyle={[props.customInputRootStyle, {width: '100%'}]}
          disabled={props.disabled}
          ariaLabel={props.ariaLabel ? props.ariaLabel : t`Password`}
          rightIcon={
            <Icon
              name={hidePassword ? 'eye-outline' : 'eye-off-outline'}
              size={24}
              color={theme.colors.neutral.$6}
            />
          }
          iconOnClick={() => setHidePassword(!hidePassword)}
          rightIconTitle="Show Password"
          errorMessage={props.errorMessage}
          isInValid={!!props.errorMessage}
        />
      </View>
      {props.withValidation && (
        <View
          style={{width: '100%'}}
          aria-live="polite"
          aria-hidden={!props.withValidation}>
          <PasswordInputValidation
            password={props.password}
            setPasswordIsValid={props.setPasswordIsValid}
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  iconContainer: {
    position: 'relative',
    right: 50,
    backgroundColor: 'transparent',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    width: '100%',
    marginBottom: 0,
    marginTop: 0,
    padding: 10,
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
  inputContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
});
