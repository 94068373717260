import {ROUTES} from 'constants/routes';
import type {Share} from 'store/slices/shared/types';
import {formatStringForUrl} from 'utils/fast/common';

const useGenerateShareUrl = () => {
  const generateShareUrl = (share: Share, internal = true) => {
    const prefix = internal
      ? `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${share.parent_workspace_folder}`
      : '';
    const suffix = internal ? '' : `/${formatStringForUrl(share.title)}`;
    return `${prefix}/${ROUTES.LOGGED_IN_WITH_ORG.SHARED}/${share.custom_name}${suffix}`;
  };

  return {
    generateShareUrl,
  };
};

export default useGenerateShareUrl;
