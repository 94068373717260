import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {BilledDurationType} from 'store/slices/settings/types';

import {DISCOUNT} from '../lib/consts';

import type {ViewStyle} from 'react-native';

export interface ChangeUnitProps {
  active: BilledDurationType;
  customRootStyle?: ViewStyle;
  setActive: (inp: BilledDurationType) => void;
}

export function ChangeUnit(props: ChangeUnitProps) {
  return (
    <View style={[props.customRootStyle, styles.root]}>
      <Pressable
        onPress={() => props.setActive(BilledDurationType.Monthly)}
        style={[
          styles.button,
          {
            backgroundColor:
              props.active === BilledDurationType.Monthly
                ? theme.colors.success.$3
                : 'transparent',
          },
        ]}>
        <Text
          style={[
            styles.buttonText,
            {
              color:
                props.active === BilledDurationType.Monthly
                  ? theme.colors.neutral.$white
                  : theme.colors.neutral.$2Base,
            },
          ]}>
          <Trans>Monthly</Trans>
        </Text>
      </Pressable>
      <Pressable
        onPress={() => props.setActive(BilledDurationType.Yearly)}
        style={[
          styles.button,
          {
            backgroundColor:
              props.active === BilledDurationType.Yearly
                ? theme.colors.success.$3
                : 'transparent',
          },
        ]}>
        <Text
          style={[
            styles.buttonText,
            {
              color:
                props.active === BilledDurationType.Yearly
                  ? theme.colors.neutral.$white
                  : theme.colors.neutral.$2Base,
            },
          ]}>
          <Trans>Yearly (save {`${DISCOUNT}%`})</Trans>
        </Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    backgroundColor: theme.colors.neutral.$11,
    borderRadius: 50,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 5,
    borderRadius: 50,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: '600',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
});
