import theme from 'config/theme';
import React from 'react';
import {StyleSheet, View} from 'react-native';

export const HEIGHT_CONTENT_COMMENTS = 36;

const ContentComments = () => {
  return <View style={styles.root} />;
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: HEIGHT_CONTENT_COMMENTS,
    backgroundColor: theme.colors.neutral.$1,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$black,
    zIndex: -1,
  },
});

export default ContentComments;
