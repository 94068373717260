import type {PayloadAction} from '@reduxjs/toolkit';
import type {InvitationStatuses} from 'fast-sdk/src/api/share/consts';

export type Store = {
  organization: {
    general: {
      subDomain: string;
      timeZone: string;
    };
    branding: {
      name: string;
      logo: string;
      description: string;
      socialLinks: Array<SocialLink>;
    };
  };
  members: Array<Member>;
  billing: {
    plan: Plan;
    seats: {
      capacity: number;
      used: number;
    };
    owner: {
      email: string;
    };
  };
};

export enum PlanType {
  Startup = 'Startup',
  Team = 'Team',
  Business = 'Business',
  Enterprise = 'Enterprise',
}

export enum BilledDurationType {
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export type SocialLink = {
  type: SocialLinkType;
  link: string;
};

export type Plan = {
  type: PlanType;
  duration: number;
  createdDate: Date;
  billedDuration: BilledDurationType;
};

export enum SocialLinkType {
  Instagram = 0,
  Facebook = 1,
  Youtube = 2,
  Twitter = 3,
  Other = 4,
}

export type Member = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  authentication: string;
  dateJoined: Date;
  permissions?: string;
  profilePic?: string;
};

export type Invite = {
  id: string;
  email: string;
  inviter: string;
  state: InvitationStatuses;
  created: string;
};

export type Actions = {
  organization: {
    general: {
      setSubDomain: PayloadAction<{subDomain: string}>;
      setTimeZone: PayloadAction<{timeZone: string}>;
    };
    branding: {
      setName: PayloadAction<{name: string}>;
      setLogo: PayloadAction<{logo: string}>;
      setDescription: PayloadAction<{description: string}>;
      setSocialLinks: PayloadAction<{links: Array<SocialLink>}>;
    };
  };
  members: {
    Add: PayloadAction<{member: Member}>;
    remove: PayloadAction<{email: string}>;
  };
  billing: {
    plan: {
      addOwner: PayloadAction<{email: string}>;
      useSeats: PayloadAction<{seats: number}>;
      changeSeatsCapacity: PayloadAction<{seats: number}>;
      changePlan: PayloadAction<{plan: Plan}>;
      changeBilledDuration: PayloadAction<{duration: BilledDurationType}>;
    };
  };
};
