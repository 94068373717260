import {useEffect, useState} from 'react';

import type {MultiplayerConnection, MultiplayerMember} from '../types';

export function useMultiplayerMembers(connection: MultiplayerConnection) {
  const {provider} = connection;
  const [self, setSelf] = useState<MultiplayerMember | null>(null);
  const [list, setList] = useState<MultiplayerMember[]>([]);

  useEffect(() => {
    if (!provider) return;

    const update = () => {
      const selfMember =
        provider.awareness.getLocalState() as MultiplayerMember;
      setSelf(selfMember);
      const membersMap = Array.from(provider.awareness.getStates())
        .map(([clientId, state]) => ({
          ...(state as MultiplayerMember),
          clientId,
        }))
        .filter(member => member.userId !== selfMember.userId)
        .reduce(
          (acc: Map<string, MultiplayerMember>, member: MultiplayerMember) => {
            const existing = acc.get(member.userId);
            if (!existing || member.lastUpdate > existing.lastUpdate) {
              acc.set(member.userId, member);
            }
            return acc;
          },
          new Map<string, MultiplayerMember>(),
        );
      setList(Array.from(membersMap.values()));
    };
    update();
    provider.awareness.on('change', update);
    return () => {
      setList([]);
      provider.awareness.off('change', update);
    };
  }, [provider]);

  return {self, list};
}
