import {ROUTES} from 'constants/routes';
import {useNavigate, useParams} from 'extensions/navigation';
import useFetchOrgs from 'interface/common/hooks/useFetchOrgs';
import {useShares} from 'interface/common/hooks/useShares';
import {useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import app from 'store/slices/app';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';
import * as user from 'store/slices/user';
import {useLayoutWorkspaces} from '../workspace/hooks/useLayoutWorkspaces';
import PageContent from './components/content';
import PageHeader from './components/header';
import PageSkeleton from './components/skeleton';
import {AppTabs} from './types';

export default function ProfileMembership() {
  const {activeTab} = useParams<{activeTab: AppTabs}>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {fetchAndUpdateShares, loading: isSharesLoading} = useShares();
  const {fetchOrganizations, loading: isFetchingOrgs} = useFetchOrgs();
  const {fetchAndUpdateWorkspaces} = useLayoutWorkspaces();

  const organizations = useSelector(user.selectors.getOrganizationsList);

  const setActiveTab = (tab: AppTabs) => {
    if (tab !== activeTab)
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}/${tab}`);
  };

  const setCurrentPage = (tabPageName: string) => {
    dispatch(
      app.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.Public,
        pageName: `Home - ${tabPageName}`,
      }),
    );
  };

  useEffect(() => {
    fetchOrganizations();
    fetchAndUpdateShares();
    fetchAndUpdateWorkspaces();
  }, []);

  useEffect(() => {
    const tabPageName =
      activeTab === AppTabs.Workspaces ? 'Workspaces' : 'Shares';
    setCurrentPage(tabPageName);
  }, [activeTab]);

  const isWorkspaces = activeTab === AppTabs.Workspaces;
  const loading = isFetchingOrgs && organizations.length === 0;

  return (
    <View style={styles.root}>
      <PageHeader activeTab={activeTab} setActiveTab={setActiveTab} />
      {loading ? (
        <PageSkeleton isWorkspaces={isWorkspaces} />
      ) : (
        <PageContent
          organizations={organizations}
          isWorkspaces={isWorkspaces}
          isSharesLoading={isSharesLoading}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 24,
    gap: 48,
  },
});
