import type {EventsBase, KeyAction, KeyEvent, KeyHandler} from './base';

class Events implements EventsBase {
  addKeyListener(action: KeyAction, subscribe: KeyHandler) {
    const type = action === 'up' ? 'keyup' : 'keydown';
    const handler = (e: KeyboardEvent) => {
      const {key, code} = e;
      const event: KeyEvent = {key, code, flags: {}, original: e};
      if (e.altKey) event.flags.altKey = true;
      if (e.ctrlKey) event.flags.ctrlKey = true;
      if (e.metaKey) event.flags.metaKey = true;
      if (e.shiftKey) event.flags.shiftKey = true;
      if (e.getModifierState) {
        if (e.getModifierState('NumLock')) {
          event.flags.modifierNumLock = true;
        }
        if (e.getModifierState('CapsLock')) {
          event.flags.modifierCapsLock = true;
        }
        if (e.getModifierState('ScrollLock')) {
          event.flags.modifierScrollLock = true;
        }
      }
      subscribe(event);
    };
    document.addEventListener(type, handler);
    return {
      unsubscribe: () => document.removeEventListener(type, handler),
    };
  }

  simulateKey(action: KeyAction, event: KeyEvent) {
    const type = action === 'up' ? 'keyup' : 'keydown';
    document.dispatchEvent(
      new KeyboardEvent(type, {
        key: event.key,
        code: event.code,
        ...event.flags,
      }),
    );
  }
}

export default new Events();
