import {t} from '@lingui/macro';
import {useNavigate} from 'extensions/navigation';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useModalCreateFolder} from 'interface/stacks/workspace/storage/hooks/useModalCreateFolder';
import type {MouseEvent} from 'react-native';
import {Section} from '../components/Section';
import {SectionBody} from '../components/SectionBody';
import {SectionHeader} from '../components/SectionHeader';
import {FolderTree} from '../components/folder/FolderTree';

interface FolderTreeSectionProps {
  workspace: WorkspaceListDetail;
  selectedFolderId?: string;
}

export function FolderTreeSection({
  workspace,
  selectedFolderId,
}: FolderTreeSectionProps) {
  const navigate = useNavigate();

  const modalCreateFolder = useModalCreateFolder(
    selectedFolderId,
    workspace.folder_name,
    'workspace',
  );

  const onAddFolderPress = (e: MouseEvent) => {
    modalCreateFolder.open();
  };

  const handleFolderPress = (folderId: string) => {
    const baseUrl = `/workspace/${workspace.folder_name}`;
    const folderLink = `${baseUrl}/storage/${folderId}`;
    navigate(folderLink);
  };

  return (
    <Section id="folder-section">
      <SectionHeader
        title={t`Storage`}
        onAddPress={onAddFolderPress}
        ariaLabel="Add Folder"
      />
      <SectionBody>
        <FolderTree
          workspace={workspace}
          selectedFolderId={selectedFolderId}
          onFolderPress={handleFolderPress}
        />
      </SectionBody>
    </Section>
  );
}
