import type {ActivityResponse} from 'fast-sdk/src/websockets/types';
import type {EntityType} from 'store/slices/activity/types';
import EntityActivityHandler from './EntityActivityHandler';
import type {EntityActivityHandlerOptions} from './types';

class EntityCommunicationManager {
  private static instance: EntityCommunicationManager | null = null;
  private communicationHandlers: {
    [communicationKey: string]: EntityActivityHandler<ActivityResponse>;
  } = {};

  private constructor() {}

  public static getInstance(): EntityCommunicationManager {
    if (!EntityCommunicationManager.instance) {
      EntityCommunicationManager.instance = new EntityCommunicationManager();
    }
    return EntityCommunicationManager.instance;
  }

  public initializeActivityHandler(options: EntityActivityHandlerOptions) {
    const communicationKey = `${options.entityType}-${options.entityId}`;
    if (!this.communicationHandlers[communicationKey]) {
      this.communicationHandlers[communicationKey] = new EntityActivityHandler(
        options,
      );
      this.communicationHandlers[communicationKey].startCommunication();
    }
    return this.communicationHandlers[communicationKey];
  }

  public endActivityHandler(communicationKey: string): void {
    if (this.communicationHandlers[communicationKey]) {
      this.communicationHandlers[communicationKey].endCommunication();
      delete this.communicationHandlers[communicationKey];
    }
  }

  public endActivityHandlersForUnlistedEntities(
    entityType: EntityType,
    entitiesIds: string[],
  ) {
    const communicationsToEnd = Object.keys(this.communicationHandlers).filter(
      communicationKey => {
        return (
          communicationKey.startsWith(entityType) &&
          !entitiesIds.find(entityId => communicationKey.endsWith(entityId))
        );
      },
    );

    communicationsToEnd.forEach(this.endActivityHandler.bind(this));
  }

  public endAllActivityHandlers() {
    Object.keys(this.communicationHandlers).forEach(
      this.endActivityHandler.bind(this),
    );
  }
}

export default EntityCommunicationManager;
