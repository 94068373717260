export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function number(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export function shuffle(array: unknown[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function getRandomColor() {
  const colors = [
    '#0DC5FF',
    '#10EB9C',
    '#6524FF',
    '#FFC804',
    '#F96B6F',
    '#517EF2',
    '#FFA14A',
    '#FF0DBB',
    '#D32A7B',
    '#F4E028',
    '#C4EE4E',
  ];
  const randomNumber = Math.floor(Math.random() * colors.length);
  return colors[randomNumber];
}

export function getRandomId(digits: number) {
  const res = [];
  for (let i = 0; i < digits; i++) {
    res.push(Math.floor(Math.random() * 10));
  }
  return res.join('');
}
