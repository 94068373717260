import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import useBillingDetails from 'interface/common/hooks/useBillingDetails';
import useOrgDetails from 'interface/common/hooks/useOrgDetails';
import useLoadStripe from 'interface/stacks/onboard/hooks/useLoadStripe';
import {SubscriptionCheckout} from 'interface/stacks/subscription/components/SubscriptionCheckout';
import {StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {getOrigin} from 'utils/common/platform';

export function RenewSubscription() {
  const {details, loading: loadingOrgDetails} = useOrgDetails();
  const {billingStatus, loading: loadingBillingDetails} = useBillingDetails(
    details?.domain,
    {disable: loadingOrgDetails, generatePaymentIntent: true},
  );
  const {stripe, loading: loadingStripe} = useLoadStripe({
    key: billingStatus?.public_key,
    loading: !billingStatus?.public_key,
  });

  const orgId = details?.id;
  const subdomain = details?.domain;

  const secret = billingStatus?.setup_intent?.client_secret;

  const loading = loadingBillingDetails || loadingOrgDetails || loadingStripe;
  const plan = billingStatus?.current_plan;
  const tax = plan && Math.ceil(plan.pricing.price_base * 0.08);

  const renderAlert = () => {
    return (
      <View style={styles.warningBox}>
        <Icon
          name="warning"
          size={24}
          style={styles.warningIcon}
          color={theme.colors.neutral.$2Base}
        />
        <Typography
          variant="regular"
          size="md"
          color={theme.colors.neutral.$2Base}>
          Your organization does not currently have an active subscription. You
          will need to purchase a plan before continuing.
        </Typography>
      </View>
    );
  };

  const onSubscriptionSuccess = async () => {
    const origin = await getOrigin();
    window.location.href = `${origin}/to-subdomain?orgDomain=${subdomain}`;
  };

  return (
    <SubscriptionCheckout
      alert={renderAlert()}
      title="Purchase Plan"
      content="Review your plan summary below, then add your payment details to continue with your purchase."
      loading={loading}
      secret={secret}
      orgId={orgId}
      orgDomain={subdomain}
      stripe={stripe}
      plan={plan}
      tax={tax}
      onSubscriptionSuccess={onSubscriptionSuccess}
    />
  );
}

const styles = StyleSheet.create({
  warningBox: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    backgroundColor: theme.colors.caution.$4Base,
    borderRadius: 4,
    marginBottom: '2rem',
  },
  warningIcon: {},
});
