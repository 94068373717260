import theme from 'config/theme';
import {Outlet, useNavigate} from 'extensions/navigation';
import {Button} from 'interface/common/Button';
import {AppUserAvatar} from 'interface/stacks/app/AppUserAvatar';
import {useState} from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useSelector} from 'react-redux';

import * as app from 'store/slices/app';
import * as user from 'store/slices/user';

export enum UpgradeDowngradeState {
  SelectPlan = 'SelectPlan',
  AddCard = 'AddCard',
  Loading = 'Loading',
  Success = 'Success',
  Downgrade = 'Downgrade',
}

const calcBackButton = (status: UpgradeDowngradeState) => {
  switch (status) {
    case UpgradeDowngradeState.AddCard:
      return UpgradeDowngradeState.SelectPlan;
    case UpgradeDowngradeState.Downgrade:
      return UpgradeDowngradeState.SelectPlan;
    default:
      return -1;
  }
};

export function UpgradeDowngradeLayout() {
  const [status, setStatus] = useState<UpgradeDowngradeState>(
    UpgradeDowngradeState.SelectPlan,
  );

  const name = useSelector(app.selectors.getName);
  const details = useSelector(user.selectors.getUserDetails);
  const navigate = useNavigate();

  const userName = details?.first_name
    ? `${details?.first_name} ${details?.last_name}`
    : name || 'Anonymous';

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <Button
          type="Text"
          customRootStyle={{
            backgroundColor: theme.colors.neutral.$white,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          lIcon={
            <Icon
              name="arrow-back"
              size={30}
              color={theme.colors.brand.$4Base}
              style={styles.back}
            />
          }
          ariaLabel="Go Back"
          onPress={() => {
            const destination = calcBackButton(status);
            if (destination === -1) {
              navigate(-1);
            } else {
              setStatus(destination);
            }
          }}
          disabled={status === UpgradeDowngradeState.Loading}
        />
        <View
          style={[
            styles.account,
            {opacity: status === UpgradeDowngradeState.Loading ? 0.5 : 1},
          ]}>
          <AppUserAvatar />
          <Text style={styles.text}>{userName}</Text>
          <View style={styles.icon}>
            <Icon
              name="keyboard-arrow-down"
              color={theme.colors.neutral.$5}
              size={20}
            />
          </View>
        </View>
      </View>
      <ScrollView contentContainerStyle={styles.outletWrapper}>
        <Outlet context={[status, setStatus]} />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.neutral.$15,
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$10,
    backgroundColor: theme.colors.neutral.$white,
  },
  backIcon: {
    paddingVertical: 12,
    paddingHorizontal: '1rem',
  },
  account: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    paddingVertical: 3,
    paddingLeft: 3,
    paddingRight: 15,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 50,
    borderColor: '#e4e5e7',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  text: {
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'left',
    color: theme.colors.neutral.$2Base,
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: '100%',
  },
  outletWrapper: {
    width: '100%',
    height: '100%',
    padding: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  back: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
