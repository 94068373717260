import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/common/Button';
import {StyleSheet, Text, View} from 'react-native';
import type {BilledDurationType} from 'store/slices/settings/types';

import type {PlanMetaData} from 'interface/stacks/onboard/lib/types';

interface DowngradeProps {
  plan: PlanMetaData;
  duration: BilledDurationType;
  handleSubmit: () => void;
  goBack: () => void;
}

export function Downgrade(props: DowngradeProps) {
  return (
    <View style={styles.root}>
      <Text selectable style={styles.headerText}>
        Downgrade to {props.plan.name}?
      </Text>
      <Text selectable style={styles.contentText}>
        Downgrading your plan will reduce the maximum number of users and
        workspaces, and file sizes will be more limited. Do you still want to
        downgrade?
      </Text>
      <View style={styles.buttonsContainer}>
        <Button
          type="Secondary"
          label={t`No, take me back`}
          onPress={props.goBack}
        />
        <Button
          type="Primary"
          label={t`Downgrade to ${props.plan.name}`}
          onPress={props.handleSubmit}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 650,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 14,
    shadowColor: theme.colors.neutral.$black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3.05,
    elevation: 4,
  },
  headerText: {
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 48,
    color: theme.colors.neutral.$2Base,
    marginBottom: '1.5rem',
  },
  contentText: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
    marginBottom: '3rem',
    textAlign: 'center',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
  },
});
