import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {StyleSheet} from 'react-native';

export default function SectionTitle({children}: {children: React.ReactNode}) {
  return (
    <Typography
      color={theme.colors.neutral.$2Base}
      size="md"
      overrides={styles.sectionTitle}>
      <Trans>{children}</Trans>
    </Typography>
  );
}

const styles = StyleSheet.create({
  sectionTitle: {
    marginBottom: 12,
  },
});
