import {useDispatch, useSelector} from 'react-redux';
import * as shared from 'store/slices/shared';
import type {Share} from 'store/slices/shared/types';
import * as user from 'store/slices/user';

interface Props {
  share: Share;
}

const useShareComments = ({share}: Props) => {
  const dispatch = useDispatch();

  const thisUser = useSelector(user.selectors.getUserDetails);

  const defaultCreatedBy = {
    id: thisUser.id,
    authentication: '',
    dateJoined: thisUser.created,
    email: thisUser.email_address,
    firstName: thisUser.first_name,
    lastName: thisUser.last_name,
  };

  const addComment = (commentText: string) => {
    dispatch(
      shared.default.actions.addCommentToShare({
        commentText,
        sender: defaultCreatedBy,
        share,
      }),
    );
  };

  const addReply = (replyText: string, commentId: string) => {
    dispatch(
      shared.default.actions.addReplyForShare({
        commentId,
        share,
        replyText,
        sender: defaultCreatedBy,
      }),
    );
  };

  return {
    addComment,
    addReply,
  };
};

export default useShareComments;
