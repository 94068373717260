import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {Icon} from 'interface/base/Icon';
import {ToggleWithLabel} from 'interface/base/ToggleWithLabel';
import Typography from 'interface/base/Typography';
import {useHover} from 'interface/common/hooks/useHover';
import {useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import {ColumnManagerInfoKeys as ColumnKeys} from 'store/slices/app/types';
import * as files from 'store/slices/files';
export function ColumnManager() {
  const {isHover, onHoverIn, onHoverOut} = useHover();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popup
      isOpen={isOpen}
      showBorder={false}
      close={() => setIsOpen(false)}
      triggerElement={
        <Pressable
          onHoverIn={onHoverIn}
          onHoverOut={onHoverOut}
          onPress={() => setIsOpen(true)}>
          <ColumnManagerButton isHover={isHover} />
        </Pressable>
      }
      placement="bottom-start"
      customContainerStyle={styles.popupContainer}
      customPopupStyle={{
        backgroundColor: 'transparent',
        paddingBottom: 8,
        paddingTop: 8,
        paddingHorizontal: 8,
      }}>
      <ColumnManagerModal />
    </Popup>
  );
}

function ColumnManagerButton({isHover}: {isHover: boolean}) {
  return (
    <View style={[styles.button, isHover && styles.hovered]}>
      <Icon name="lucide:columns-3" color={theme.colors.brand.$4} size={20} />
      <Icon
        name="lucide:chevron-down"
        color={theme.colors.neutral.$2Base}
        size={20}
        opacity={0.5}
      />
    </View>
  );
}

function ColumnManagerModal() {
  const columnInfo = useSelector(app.selectors.columnInfo);
  const columnInfoOverride = useSelector(app.selectors.columnInfoOverride);

  const focusedParentId = useSelector(files.selectors.getFocusedParent);

  const getInfo = (key: ColumnKeys) =>
    columnInfoOverride[key] ?? columnInfo[key];

  const isFoldersAndFilesChecked = getInfo(ColumnKeys.FoldersAndFiles);
  const isInfoPanelChecked = getInfo(ColumnKeys.InfoPanel);
  const isShareConfigurationChecked = getInfo(ColumnKeys.ShareConfiguration);
  const isAIChatChecked = getInfo(ColumnKeys.AIChat);
  const isWorkspaceBrowserChecked = getInfo(ColumnKeys.WorkspaceBrowser);

  const isShareContext = !!focusedParentId?.startsWith('5');

  const dispatch = useDispatch();

  const setColumnManagerInfo = (key: ColumnKeys, value: boolean) => {
    dispatch(app.default.actions.setColumnInfoOverride({[key]: value}));
  };

  return (
    <>
      <Typography
        variant="regular"
        size="sm"
        color={theme.colors.neutral.$500}
        overrides={{marginBottom: 16}}>
        Toggle Columns:
      </Typography>
      <ToggleWithLabel
        label="Workspace Browser"
        checked={isWorkspaceBrowserChecked}
        onChange={() =>
          setColumnManagerInfo(
            ColumnKeys.WorkspaceBrowser,
            !isWorkspaceBrowserChecked,
          )
        }
      />
      <ToggleWithLabel
        label="Folders and Files"
        checked={isFoldersAndFilesChecked}
        onChange={() =>
          setColumnManagerInfo(
            ColumnKeys.FoldersAndFiles,
            !isFoldersAndFilesChecked,
          )
        }
      />
      <ToggleWithLabel
        label="Info Panel"
        checked={isInfoPanelChecked}
        onChange={() =>
          setColumnManagerInfo(ColumnKeys.InfoPanel, !isInfoPanelChecked)
        }
      />
      {isShareContext && (
        <ToggleWithLabel
          label="Share Configuration"
          checked={isShareConfigurationChecked}
          onChange={() =>
            setColumnManagerInfo(
              ColumnKeys.ShareConfiguration,
              !isShareConfigurationChecked,
            )
          }
        />
      )}
      <ToggleWithLabel
        label="AI Chat"
        checked={isAIChatChecked}
        onChange={() =>
          setColumnManagerInfo(ColumnKeys.AIChat, !isAIChatChecked)
        }
      />
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 6,
    padding: 6,
    gap: 3,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hovered: {
    backgroundColor: theme.colors.neutral.$13,
  },
  popupContainer: {
    borderRadius: 16,
    padding: 24,
    width: 280,
    shadowColor: 'rgb(28, 27, 34)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.08,
    shadowRadius: 8,
    backgroundColor: theme.colors.neutral.$0,
    flexDirection: 'column',
  },
});
