import {fonts} from 'config/styles';
import {theme} from 'config/themes';
import {
  type GestureResponderEvent,
  type NativeMouseEvent,
  type NativeSyntheticEvent,
  Pressable,
  StyleSheet,
  Text,
  type TextStyle,
  View,
  type ViewStyle,
  useColorScheme,
} from 'react-native';
import {isAndroid} from 'utils/common/platform';

import type {ReactNode} from 'react';

export interface IconButtonProps {
  text?: string | React.JSX.Element;
  mode?: 'primary' | 'secondary' | 'cancel' | 'destructive' | 'danger';
  icon?: ReactNode;
  title?: string;
  disabled?: boolean;
  onPress?: (e: GestureResponderEvent) => void;
  iconStyle?: ViewStyle | ViewStyle[];
  textStyle?: TextStyle | TextStyle[];
  textHoverStyle?: TextStyle | TextStyle[];
  buttonStyle?: ViewStyle | ViewStyle[];
  buttonHoverStyle?: ViewStyle | ViewStyle[];
  reference?: any;
  onHoverIn?: (inp: NativeSyntheticEvent<NativeMouseEvent>) => void;
  onHoverOut?: (inp: NativeSyntheticEvent<NativeMouseEvent>) => void;
  onMouseEnter?: (e: NativeSyntheticEvent<NativeMouseEvent>) => void;
  onMouseLeave?: (e: NativeSyntheticEvent<NativeMouseEvent>) => void;
}

export function IconButton(props: IconButtonProps) {
  const scheme = useColorScheme();
  return (
    <Pressable
      role="button"
      ref={props.reference}
      onPress={props.onPress ?? undefined}
      disabled={props.disabled}
      android_ripple={{
        borderless: !props.mode && !props.text,
        color:
          scheme === 'dark'
            ? 'rgba(255, 255, 255, 0.25)'
            : 'rgba(0, 0, 0, 0.25)',
      }}
      /* @ts-ignore */
      style={({pressed, hovered, focused}) => {
        const isHighlighted: boolean =
          !isAndroid() && (pressed || hovered || focused);
        const isDestructive = props.mode === 'destructive';
        const isPrimary = props.mode === 'primary';
        const isCancel = props.mode === 'cancel';
        const isSecondary = props.mode === 'secondary';
        const isDanger = props.mode === 'danger';

        return [
          styles.button,
          isHighlighted && props.buttonHoverStyle,
          props.buttonStyle,
          !props.mode &&
            !props.buttonHoverStyle &&
            isHighlighted &&
            styles.buttonSecondaryHover,
          isPrimary && styles.buttonPrimary,
          isPrimary && isHighlighted && styles.buttonPrimaryHover,
          isDestructive && styles.buttonDestructive,
          isDestructive && isHighlighted && styles.buttonDestructiveHover,
          isCancel && styles.buttonCancel,
          isCancel && isHighlighted && styles.buttonCancelHover,
          isSecondary && styles.buttonSecondary,
          isSecondary && isHighlighted && styles.buttonSecondaryHover,
          isDanger && styles.buttonDanger,
          isDanger && isHighlighted && styles.buttonDangerHover,
          props.disabled && styles.disabled,
        ];
      }}
      onHoverIn={props.onHoverIn}
      onHoverOut={props.onHoverOut}
      /* @ts-ignore */
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}>
      {/** @ts-ignore */}
      {({pressed, hovered, focused}) => {
        const isHighlighted: boolean =
          !isAndroid() && (pressed || hovered || focused);
        const isDestructive = props.mode === 'destructive';
        const isPrimary = props.mode === 'primary';
        const isCancel = props.mode === 'cancel';
        const isSecondary = props.mode === 'secondary';
        const isDanger = props.mode === 'danger';
        const hasIcon = !!props.icon;
        const hasText = !!props.text;
        return (
          <View style={[styles.inner, hasIcon && hasText && styles.innerPad]}>
            {hasIcon && (
              <View style={[styles.icon, props.iconStyle]}>{props.icon}</View>
            )}
            {hasText && (
              <Text
                style={[
                  styles.text,
                  isPrimary && styles.textPrimary,
                  isDestructive && styles.textDestructive,
                  isCancel && styles.textCancel,
                  isSecondary && styles.textSecondary,
                  isDanger && styles.textDanger,
                  isHighlighted && props.textHoverStyle,
                  props.textStyle,
                ]}>
                {props.text}
              </Text>
            )}
          </View>
        );
      }}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 1,
  },
  disabled: {
    opacity: 0.5,
  },
  icon: {
    width: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inner: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerPad: {
    paddingLeft: 8,
  },
  button: {
    padding: 1,
    minWidth: 90,
    minHeight: 40,
    maxHeight: 40,
    borderWidth: 2,
    borderRadius: 3,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'transparent',
  },
  buttonPrimary: {
    borderColor: theme.button.primary.border,
    backgroundColor: theme.button.primary.fill,
  },
  buttonPrimaryHover: {
    backgroundColor: theme.button.primary.hover,
  },
  buttonCancel: {
    borderColor: theme.button.cancel.border,
    backgroundColor: theme.button.cancel.fill,
  },
  buttonCancelHover: {
    backgroundColor: theme.button.cancel.hover,
  },
  buttonDestructive: {
    borderColor: theme.button.destructive.border,
    backgroundColor: theme.button.destructive.fill,
  },
  buttonDanger: {
    borderColor: theme.button.danger.border,
    backgroundColor: theme.button.danger.fill,
  },
  buttonDestructiveHover: {
    backgroundColor: theme.button.destructive.hover,
  },

  buttonDangerHover: {
    backgroundColor: theme.button.danger.hover,
  },
  buttonSecondary: {
    borderColor: theme.button.secondary.border,
    backgroundColor: theme.button.secondary.fill,
  },
  buttonSecondaryHover: {
    backgroundColor: theme.button.secondary.hover,
  },
  text: {
    // ...fonts.regular,
    fontSize: fonts.sizes.s,
    padding: 10,
  },
  textPrimary: {
    color: theme.button.primary.text,
  },
  textCancel: {
    color: theme.button.cancel.text,
  },
  textDestructive: {
    color: theme.button.destructive.text,
  },
  textSecondary: {
    color: theme.button.secondary.text,
  },
  textDanger: {
    color: theme.button.danger.text,
  },
});
