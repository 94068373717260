import React, {type PropsWithChildren} from 'react';
import {StyleSheet, View} from 'react-native';

const TimeDisplay = ({children}: PropsWithChildren<{}>) => {
  return <View style={styles.root}>{children}</View>;
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
});

export default TimeDisplay;
