import {View} from 'react-native';
import {Outlet} from 'react-router';

import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import AppUserBar from 'interface/stacks/app/AppUserBar';
import {StyleSheet} from 'react-native';
import {SettingsMenu} from '../base/SettingsMenu';

export function LayoutSettings() {
  return (
    <View style={styles.root}>
      <AppUserBar />
      <View style={styles.right}>
        <View style={styles.header}>
          <Typography
            variant="regular"
            size="md"
            color={theme.colors.neutral.$700}>
            Settings
          </Typography>
        </View>
        <View style={styles.content}>
          <SettingsMenu />
          <Outlet />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    height: '100%',
    backgroundColor: theme.colors.neutral.$100,
    padding: 3,
    flexDirection: 'row',
  },
  header: {
    backgroundColor: theme.colors.neutral.$0,
    width: '100%',
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
  },
  content: {
    flexDirection: 'row',
    flexGrow: 1,
    maxHeight: 'calc(100% - 42px)',
  },
  right: {
    flex: 1,
    flexGrow: 1,
  },
});
