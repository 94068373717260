import {t} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {Button} from 'interface/common/Button';
import AppHeaderAccountDropdown from 'interface/stacks/app/layout/AppHeaderAccountDropdown';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';

export default function ShareHeaderAccount() {
  const navigate = useNavigate();

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  return (
    <View style={styles.accountContainer}>
      {isLoggedIn ? (
        <AppHeaderAccountDropdown />
      ) : (
        <Button
          type="Primary"
          label={t`Sign In`}
          onPress={() => navigate(`/${ROUTES.NOT_LOGGED_IN.SIGNIN}`)}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  accountContainer: {
    paddingLeft: 16,
    borderLeftWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.neutral.$12,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});
