import theme from 'config/theme';
import {useEffect, useRef} from 'react';
import {StyleSheet, View} from 'react-native';
import WaveSurfer from 'wavesurfer.js';
import useAudio from '../../hooks/useAudio';
import useContentViewerMode from '../../hooks/useContentViewerMode';

type Props = {
  url: string;
};

const Waveform = ({url}: Props) => {
  const {isThumbnail} = useContentViewerMode();
  const {
    audio: {playing, currentTime},
  } = useAudio();

  const containerRef = useRef();
  const waveSurferRef = useRef<WaveSurfer | null>(null);

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      barWidth: 1.5,
      barRadius: 5,
      cursorWidth: 0,
      height: isThumbnail ? 200 : 430,
      waveColor: '#4d4d4d',
      progressColor: '#dadada',
      interact: false,
    });
    waveSurfer.load(url);
    waveSurfer.on('ready', () => {
      waveSurferRef.current = waveSurfer;
      waveSurfer.setMuted(true);
    });
    return () => {
      if (waveSurfer) {
        waveSurfer.destroy();
      }
    };
  }, [url, isThumbnail]);

  useEffect(() => {
    if (!waveSurferRef.current) {
      return;
    }

    const time = waveSurferRef.current.getCurrentTime();
    if (time !== currentTime) {
      waveSurferRef.current.setTime(currentTime);
    }
  }, [currentTime]);

  useEffect(() => {
    if (!waveSurferRef.current) {
      return;
    }

    if (playing) {
      waveSurferRef.current.play();
    } else {
      waveSurferRef.current.pause();
    }
  }, [playing]);

  return (
    <View style={[styles.root, isThumbnail && {paddingHorizontal: 0}]}>
      <View ref={containerRef} style={styles.container} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: 100,
    height: '100%',
    backgroundColor: theme.colors.neutral.$black,
    justifyContent: 'center',
  },
  container: {
    width: '100%',
  },
});

export default Waveform;
