import {TimeSlider} from '@vidstack/react';
import theme from 'config/theme';
import React from 'react';
import {StyleSheet, View} from 'react-native';

export const HEIGHT_TIME_PROGRESS = 6;

const TimeProgress = () => {
  return (
    <View style={styles.root}>
      <TimeSlider.Root
        className="vds-time-slider vds-slider"
        style={styles.progressRoot}>
        <TimeSlider.Track
          className="vds-slider-track"
          style={styles.progressEmpty}
        />
        <TimeSlider.TrackFill
          className="vds-slider-track-fill vds-slider-track"
          style={styles.progressFilled}
        />
        <TimeSlider.Progress
          className="vds-slider-progress vds-slider-track"
          style={styles.progress}
        />
        <TimeSlider.Thumb className="vds-slider-thumb" style={styles.thumb} />
      </TimeSlider.Root>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    height: HEIGHT_TIME_PROGRESS,
  },
  progressRoot: {
    height: HEIGHT_TIME_PROGRESS,
    borderRadius: 0,
    borderWidth: 0,
    margin: 0,
  },
  progress: {
    height: HEIGHT_TIME_PROGRESS,
    borderRadius: 0,
    borderWidth: 0,
  },
  progressEmpty: {
    height: HEIGHT_TIME_PROGRESS,
    backgroundColor: theme.colors.neutral.$4,
    borderRadius: 0,
    borderWidth: 0,
  },
  progressFilled: {
    height: HEIGHT_TIME_PROGRESS,
    backgroundColor: theme.colors.brand.$4Base,
    borderRadius: 0,
    borderWidth: 0,
  },
  thumb: {
    width: 14,
    height: 14,
    backgroundColor: theme.colors.brand.$4Base,
    borderWidth: 0,
    zIndex: 2,
  },
});

export default TimeProgress;
