import {useSelector} from 'react-redux';
import * as shared from 'store/slices/shared';
import * as workspace from 'store/slices/workspace';

export default function useHasExternalWorkspacesShares() {
  const externalWorkspaces = useSelector(
    workspace.selectors.getExternalWorkspaces,
  );
  const externalShares = useSelector(
    shared.selectors.getWorkspaceShares('externals'),
  );
  const hasExternalWorkspaces = externalWorkspaces.length > 0;
  const hasExternalShares = Object.keys(externalShares).length > 0;
  const hasExternalWorkspacesOrShares =
    hasExternalWorkspaces || hasExternalShares;

  return {
    hasExternalWorkspacesOrShares,
    hasExternalWorkspaces,
    hasExternalShares,
  };
}
