import {useMediaRemote, useMediaStore} from '@vidstack/react';

const useVideo = () => {
  const video = useMediaStore();
  const handler = useMediaRemote();

  return {
    video,
    handler,
  };
};

export default useVideo;
