import * as colors from 'config/styles/colors';
import theme from 'config/theme';
import type {ThemeBase} from 'config/themes/base';
import {luminance} from 'utils/common/color';

class LightTheme implements ThemeBase {
  app = {
    logo: '#0070f0',
    header: colors.neutral.white,
    menu: colors.neutral.darker,
    content: colors.neutral.white,
    separator: '#ccc',
    selection: luminance(colors.primary.light, -0.2),
  };

  button = {
    primary: {
      fill: colors.primary.brand,
      text: colors.neutral.white,
      border: colors.primary.brand,
      hover: luminance(colors.primary.brand, -0.2),
    },
    destructive: {
      fill: colors.secondary.darkRed,
      text: colors.neutral.white,
      border: colors.secondary.darkRed,
      hover: luminance(colors.secondary.darkRed, -0.2),
    },
    cancel: {
      fill: colors.neutral.white,
      text: colors.neutral.mid,
      border: colors.neutral.lighter,
      hover: colors.neutral.lightest,
    },
    secondary: {
      fill: theme.colors.neutral.$white,
      text: theme.colors.neutral.$4,
      border: theme.colors.neutral.$10,
      hover: theme.colors.neutral.$13,
    },
    danger: {
      fill: theme.colors.danger.$4Base,
      text: theme.colors.neutral.$2Base,
      border: theme.colors.danger.$5,
      hover: theme.colors.danger.$35Hover,
    },
  };

  dialog = {
    fill: colors.neutral.white,
    hover: colors.neutral.lightest,
    border: colors.neutral.lighter,
    overlay: 'rgba(0,0,0,0.6)',
    title: colors.neutral.darker,
    text: colors.neutral.dark,
    input: {
      fill: colors.neutral.lightest,
      text: colors.neutral.dark,
      placeholder: colors.neutral.mid,
    },
  };

  menu = {
    fill: colors.neutral.white,
    border: colors.neutral.lighter,
    hover: colors.neutral.lightest,
    text: colors.neutral.dark,
    legal: '#bbb',
    header: {
      fill: colors.neutral.lightest,
      text: colors.neutral.dark,
      border: colors.neutral.lighter,
      hover: colors.neutral.lightest,
      multi: colors.secondary.tan,
      multiBorder: '#e2c18e',
      multiText: '#665e4e',
    },
  };

  login = {
    fill: colors.neutral.white,
    header: colors.neutral.mid,
    signup: colors.neutral.lighter,
    signupText: colors.neutral.dark,
  };

  files = {
    fill: colors.neutral.white,
    icon: colors.neutral.light,
    navigator: {
      border: colors.neutral.lighter,
      fill: colors.neutral.lightest,
      text: colors.neutral.dark,
    },
    breadcrumbs: {
      border: colors.neutral.lighter,
      fill: colors.neutral.lightest,
      text: colors.neutral.dark,
      share: colors.neutral.white,
      shareBorder: colors.neutral.lighter,
    },
    listItem: {
      fill: colors.neutral.white,
      title: colors.neutral.dark,
      border: colors.neutral.lighter,
      focused: colors.neutral.whitish,
      dragged: colors.neutral.white,
      dropped: colors.primary.light,
      selected: colors.primary.light,
      selectedIcon: colors.primary.dark,
      selectedText: colors.neutral.mid,
      selectedCheck: colors.primary.dark,
      selectedFocused: luminance(colors.primary.light, 0.4),
    },
  };

  transfers = {
    fill: colors.neutral.white,
    icon: colors.neutral.lighter,
    header: colors.neutral.lightest,
    headerText: colors.neutral.dark,
    headerTitle: colors.neutral.darker,
    headerBorder: colors.neutral.lighter,
    diagnosticText: colors.primary.brand,
    progress: {
      bar: '#d6d6d6',
      hashed: colors.neutral.light,
      uploaded: colors.primary.brand,
    },
  };
}

export default new LightTheme();
