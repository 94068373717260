import {Outlet, useParams} from 'extensions/navigation';
import type React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {EntityType} from 'store/slices/activity/types';
import * as app from 'store/slices/app';
import {selectors as workspaceSelectors} from 'store/slices/workspace';
import useEntityCommunication from '../hooks/useEntityCommunication';
import useSyncWorkspaceData from '../hooks/useSyncWorkspaceData';

const WorkspaceActivityProvider = () => {
  const {workspaceId} = useParams();

  const [workspaceAlt, setWorkspaceAlt] = useState(workspaceId);

  const isLoggedIn = useSelector(app.selectors.isLoggedIn);
  const workspaces = useSelector(workspaceSelectors.getAllJoinedWorkspaces);
  const {initializeActivityForEntity} = useEntityCommunication();

  const currentWorkspace = useMemo(
    () => workspaces.find(ws => ws.folder_name === workspaceAlt),
    [workspaces, workspaceAlt],
  );
  const currentWorkspaceId = currentWorkspace?.id;

  useEffect(() => {
    if (isLoggedIn && currentWorkspaceId) {
      return initializeActivityForEntity({
        entityId: currentWorkspaceId,
        entityType: EntityType.Workspace,
      });
    }
  }, [isLoggedIn, currentWorkspaceId]);

  useEffect(() => {
    setWorkspaceAlt(workspaceId);
  }, [workspaceId]);

  useSyncWorkspaceData({workspace: currentWorkspace});

  return <Outlet />;
};

export default WorkspaceActivityProvider;
