import theme from 'config/theme';
import {useMemo} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Path, Svg} from 'react-native-svg';
import {getContrast} from 'utils/common/color';

import type {MultiplayerMember} from 'interface/multiplayer/types';

export interface AppMemberCursorProps {
  name: string;
  color: string;
  hasMenu: boolean;
  offscreen: boolean;
  localScrollX: number;
  localScrollY: number;
  busy: boolean;
  x: number;
  y: number;
}

/**
 * Member Cursor
 *
 * Multiplayer cursor, displays member name and includes a state to indicate busy/in-menu
 */
export function AppMemberCursor(props: AppMemberCursorProps) {
  const {hasMenu, localScrollX, localScrollY} = props;
  const position = useMemo(() => {
    const scrollX = localScrollX ?? 0;
    const scrollY = localScrollY ?? 0;
    return {
      x: props.x - scrollX,
      y: props.y - scrollY,
    };
  }, [hasMenu, props.x, props.y, localScrollX, localScrollY]);

  const contrast = getContrast(props.color);
  const translateX = position.x > 0 ? position.x + 35 : 0;
  const translateY = position.y > 0 ? position.y + 35 : 0;
  const classes = {
    root: [
      styles.root,
      {opacity: props.offscreen ? 0 : 1},
      {transform: [{translateX}, {translateY}]},
    ],
    cursor: [styles.cursor, {opacity: props.offscreen ? 0 : 1}],
    nameplate: [
      styles.nameplate,
      {backgroundColor: props.color, opacity: props.offscreen ? 0.2 : 1},
    ],
    name: [styles.name, {color: contrast === 'black' ? '#000' : '#fff'}],
  };

  return (
    <View style={classes.root} pointerEvents="none">
      <View style={classes.cursor}>
        <Svg width="16.65876579284668" height="16.658767700195312">
          <Path
            d="M 6 0 L 0 12 L 6 10 L 12 12 L 6 0 Z"
            fill={props.color}
            stroke={
              contrast === 'black' ? 'rgba(0,0,0,.3)' : 'rgba(255,255,255,.3)'
            }
            strokeWidth={1}
          />
        </Svg>
      </View>
      {props.busy && (
        <View style={styles.busy}>
          <Text selectable={false}>{'…'}</Text>
        </View>
      )}
      <View style={classes.nameplate}>
        <Text selectable={false} style={classes.name}>
          {props.name}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    zIndex: 1000,
    top: -35,
    left: -35,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition:
      'transform 0.5s cubic-bezier(.17,.93,.38,1), opacity 0.5s ease-out 1s',
    gap: 10,
  },
  cursor: {
    transform: [{rotate: '-34deg'}],
  },
  busy: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 2,
    paddingHorizontal: 8,
    backgroundColor: theme.colors.neutral.$10,
    borderRadius: 3,
    borderColor: '#0dc5ff',
    borderWidth: 2,
    borderStyle: 'solid',
  },
  nameplate: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 1,
    paddingHorizontal: 6,
    backgroundColor: '#0dc5ff',
    borderRadius: 3,
    borderColor: '#0000001a',
    borderWidth: 1,
    borderStyle: 'solid',
  },
  name: {
    height: 20,
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'center',
    color: '#000000',
  },
});
