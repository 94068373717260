import materialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import materialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import 'react-image-crop/dist/ReactCrop.css';
import {VE_DEBUG_ROUTING_KEY} from 'fast-sdk/src/requests/consts';
import {randomString} from 'fast-sdk/src/utils';
import App from 'interface';
import {AppRegistry} from 'react-native';
import config from 'react-native-ultimate-config';

injectIcons();

const fstBrKey = randomString(32);
if (typeof window !== 'undefined') {
  window.document.cookie = `ve_br_key=${fstBrKey}`;
  const debugKey = window.localStorage.getItem(VE_DEBUG_ROUTING_KEY);
  if (!debugKey) {
    window.localStorage.setItem(VE_DEBUG_ROUTING_KEY, randomString(10).trim());
  }
}

AppRegistry.registerComponent(config.APP_NAME, () => App);
AppRegistry.runApplication(config.APP_NAME, {
  rootTag: document.getElementById('root'),
});

function injectIcons() {
  const style = document.createElement('style');
  style.type = 'text/css';
  // @ts-ignore
  if (style.styleSheet) {
    // @ts-ignore
    style.styleSheet.cssText = font1;
    // @ts-ignore
    style.styleSheet.cssText = font2;
  } else {
    style.appendChild(
      document.createTextNode(
        `@font-face {src: url(${materialIcons}); font-family: MaterialIcons}`,
      ),
    );
    style.appendChild(
      document.createTextNode(
        `@font-face {src: url(${materialCommunityIcons}); font-family: MaterialCommunityIcons}`,
      ),
    );
  }
  document.head.appendChild(style);
}
