import {t} from '@lingui/macro';
import {useModal} from 'extensions/viewport/useModal';
import {Prompt} from 'interface/common/Prompt';
import {useCallback} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {rename} from 'utils/fast/storage';

import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';

export function useModalRename(
  id: string,
  name: string,
  type: string,
  workspaceId: string,
  namespace: StorageNamespace,
) {
  const modal = useModal();
  const toast = useToast();
  const submit = useCallback(
    async (value: string) => {
      if (value) {
        const {result} = await rename(id, value, workspaceId, namespace);
        if (result) {
          toast.show(t`Renamed “${name}” to “${value}”`);
        } else {
          toast.show(t`Failed to rename ${name}`, {type: 'danger'});
        }
      }
      modal.close();
    },
    [id, name, workspaceId, namespace],
  );

  const open = useCallback(() => {
    const isFolder = type === 'folder';
    modal.open(
      <Prompt
        onClose={modal.close}
        title={t`Rename ${isFolder ? 'Folder' : 'File'}`}
        text={t`Give “${name}” a new name`}
        options={{
          type: 'plain-text',
          placeholder: name,
          defaultValue: name,
          selectInput: isFolder ? 'all' : 'filename',
        }}
        buttons={[
          {
            variant: 'secondary',
            text: t`Cancel`.toUpperCase(),
            onPress: modal.close,
          },
          {
            variant: 'primary',
            text: t`Ok`.toUpperCase(),
            onPress: submit,
          },
        ]}
      />,
    );
  }, [id, name]);

  return {open, close: modal.close};
}
