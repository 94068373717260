import {api} from 'fast-sdk';
import {useState} from 'react';

export const useFetchChatMessages = () => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchChatMessages = async (workspaceName: string, chatId: string) => {
    setIsLoading(true);
    try {
      const response = await api.workspaceAichat.getChatMessages(
        workspaceName,
        chatId,
      );
      if (response.result) {
        const messages = response.messages;
        return messages;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, fetchChatMessages};
};
