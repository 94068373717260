import {useEffect, useState} from 'react';

export default function useLoadingTimeout(timeout: number) {
  const [isLoadingTooLong, setIsLoadingTooLong] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoadingTooLong(true);
    }, timeout);

    return () => clearTimeout(timeoutId);
  }, [timeout]);

  return isLoadingTooLong;
}
