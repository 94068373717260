import {t} from '@lingui/macro';
import theme from 'config/theme';
import analytics from 'extensions/analytics';
import {api} from 'fast-sdk';
import {Button} from 'interface/common/Button';
import {CustomTextInput} from 'interface/common/CustomTextInput';
import {FormError} from 'interface/common/FormError';
import {useEffect, useState} from 'react';
import {useToast} from 'react-native-toast-notifications';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import app from 'store/slices/app';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';
import {validateEmail} from 'utils/common/validation';
import {RouteLayout} from '../_layout/RouteLayout';
import {CheckEmail} from '../components/CheckEmail';
import Auth from '../consts';

enum PageStatus {
  ForgotPassword = 'ForgotPassword',
  CheckEmail = 'CheckEmail',
}

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const [pageStatus, setPageStatus] = useState<PageStatus>(
    PageStatus.ForgotPassword,
  );
  const [email, setEmail] = useState<string>('');
  const [sendEmailLoading, setSendEmailLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    dispatch(
      app.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.Public,
        pageName: 'Forgot Password',
      }),
    );
  }, []);

  const sendEmail = () => {
    if (!email) {
      setErrorMessage(t`Please add your email`);
      return;
    }
    if (!validateEmail(email)) {
      setErrorMessage(t`Please add a valid email`);
      return;
    }
    setErrorMessage('');
    setSendEmailLoading(true);
    api.user
      .lostPassword({email})
      .then(res => {
        if (res?.result) {
          Auth.setResetPasswordEmail(email);
          setPageStatus(PageStatus.CheckEmail);
          toast.show(t`Password reset link sent to your email`, {
            type: 'success',
          });
        } else {
          setErrorMessage(res.error?.text);
        }
      })
      .catch(err => {
        analytics.notify(err);
        setErrorMessage(err.message);
      })
      .finally(() => setSendEmailLoading(false));
  };

  return pageStatus === PageStatus.ForgotPassword ? (
    <RouteLayout
      title={t`Reset your password`}
      subTitle={t`Enter the email address you used to create your account and we’ll send a password reset link.`}
      subContent={
        <Button
          label={t`Back to sign in`}
          type="Text"
          onPress={() => navigate('/signin')}
          customRootStyle={{height: 38}}
        />
      }
      removeSubContentBottomBorder>
      {errorMessage ? <FormError errorMessage={errorMessage} /> : null}
      <CustomTextInput
        value={email}
        setValue={setEmail}
        placeholder={t`Enter your email address`}
        ariaLabel="Your Email Address"
        customInputStyle={{height: 48}}
        customRootStyle={{marginBottom: '1.5rem'}}
      />
      <Button
        type="Primary"
        label={t`Send password reset link`}
        onPress={sendEmail}
        loading={sendEmailLoading}
        disabled={sendEmailLoading}
        customRootStyle={{height: 48}}
        customLoadingColor={theme.colors.neutral.$white}
      />
    </RouteLayout>
  ) : (
    <CheckEmail
      email={email}
      resendEmail={sendEmail}
      tryDifferentEmail={() => setPageStatus(PageStatus.ForgotPassword)}
      sendEmailLoading={sendEmailLoading}
    />
  );
};
