import {
  differenceInSeconds,
  formatDistanceToNow,
  isToday,
  isYesterday,
  parseISO,
} from 'date-fns';

import type {EntityChat} from 'store/slices/aichat/types';

interface ChatGroups {
  today: EntityChat[];
  yesterday: EntityChat[];
  older: EntityChat[];
}

export function formatRelativeTime(date) {
  const seconds = differenceInSeconds(new Date(), date);

  // If less than 60 seconds, return "now"
  if (seconds < 60) {
    return 'now';
  }

  // Use formatDistanceToNow for other cases
  const distance = formatDistanceToNow(date, {
    addSuffix: true, // adds "ago" at the end
    includeSeconds: false,
  });

  return distance;
}

export function groupChatsByDay(chats: [string, EntityChat][]): ChatGroups {
  const groups = chats.reduce(
    (groups, [_, chat]) => {
      const chatDate = parseISO(chat.createdAt);
      if (isToday(chatDate)) {
        groups.today.push(chat);
      } else if (isYesterday(chatDate)) {
        groups.yesterday.push(chat);
      } else {
        groups.older.push(chat);
      }
      return groups;
    },
    {
      today: [] as EntityChat[],
      yesterday: [] as EntityChat[],
      older: [] as EntityChat[],
    },
  );

  const sortByDate = (a: EntityChat, b: EntityChat) =>
    parseISO(b.createdAt).getTime() - parseISO(a.createdAt).getTime();

  return {
    today: groups.today.sort(sortByDate),
    yesterday: groups.yesterday.sort(sortByDate),
    older: groups.older.sort(sortByDate),
  };
}
