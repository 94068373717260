import {api} from 'fast-sdk';
import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';
import {Avatar, type AvatarSize, type ShapeKind} from 'interface/common/Avatar';
import React, {useEffect, useState} from 'react';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';
import {blobToBase64} from 'utils/common/image';

interface OrganizationLogoProps {
  organization: OrganizationDetails;
  size?: AvatarSize;
  shapeKind?: ShapeKind;
}

export const OrganizationLogo = ({
  organization,
  size = 4,
  shapeKind = 'circle',
}: OrganizationLogoProps) => {
  const [orgLogo, setOrgLogo] = useState<string>(organization.logo);

  const getLogo = async (domain: string) => {
    const logo = await api.organization.getAsset(domain, 'logo');
    blobToBase64(logo, setOrgLogo);
  };

  useEffect(() => {
    if (organization.domain && !orgLogo) {
      getLogo(organization.domain);
    }
  }, []);

  const color =
    organization.accent_color?.color ?? getHashColor(organization.id);

  return (
    <Avatar
      color={color}
      initials={createInitials(organization.name)}
      photo={orgLogo}
      size={size}
      hideBorder
      shapeKind={shapeKind}
    />
  );
};
