import {Time} from '@vidstack/react';
import theme from 'config/theme';
import MediaText from 'interface/stacks/content-viewer/components/control-bar/MediaText';
import TimeDisplay from 'interface/stacks/content-viewer/components/control-bar/TimeDisplay';
import {StyleSheet, View} from 'react-native';

const AudioTimeDisplay = () => {
  return (
    <View style={styles.root}>
      <TimeDisplay>
        <Time
          className="vds-time"
          type="current"
          style={styles.text}
          padMinutes
        />
        <MediaText>/</MediaText>
        <Time
          className="vds-time"
          type="duration"
          style={styles.text}
          padMinutes
        />
      </TimeDisplay>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 12,
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    color: theme.colors.neutral.$white,
  },
});

export default AudioTimeDisplay;
