import {useMediaRemote, useMediaStore} from '@vidstack/react';

const useAudio = () => {
  const audio = useMediaStore();
  const handler = useMediaRemote();

  return {
    audio,
    handler,
  };
};

export default useAudio;
