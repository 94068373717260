import {ScrollView, StyleSheet} from 'react-native';

interface Props {
  children: React.ReactNode;
}

export function SectionBody({children}: Props) {
  return (
    <ScrollView
      style={styles.root}
      contentContainerStyle={styles.scrollContent}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      horizontal
      nestedScrollEnabled>
      <ScrollView nestedScrollEnabled showsVerticalScrollIndicator={false}>
        {children}
      </ScrollView>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingHorizontal: 16,
  },
  scrollContent: {
    flexGrow: 1,
    minWidth: '100%',
  },
});
