import {ROUTES} from 'constants/routes';
import {handleAppError} from 'errors';
import {AppErrors} from 'errors/appErrors';
import {api} from 'fast-sdk';
import {useLogout} from 'interface/common/hooks/useLogout';
import useOrgDetails from 'interface/common/hooks/useOrgDetails';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {SettingsType} from 'interface/stacks/settings/base/consts';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import store from 'store';
import * as app from 'store/slices/app';
import * as user from 'store/slices/user';
import type {Permission} from './useMemberPermissions';

const RESEND_LIMIT = 3;
const RESEND_TIME = 10;

export const useMemberActions = (
  email: string,
  isInvite: boolean,
  id: string,
  permission: Permission,
  onUpdate: () => void,
) => {
  const {logout} = useLogout();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {subdomain} = useSubDomain();
  const {details} = useOrgDetails();
  const userDetails = useSelector(user.selectors.getUserDetails);

  const handleAction = async (command: string) => {
    switch (command) {
      case 'resend': {
        const key = `${userDetails.id}-${subdomain}-${id}-resend`;
        const canResend = app.selectors.canResendInvitation(
          key,
          RESEND_LIMIT,
          RESEND_TIME,
        )(store.getState());

        if (!canResend) {
          return handleAppError({
            appError: AppErrors.RetryRequestLimitError,
            text: 'Please wait before resending.',
          });
        }

        dispatch(
          app.default.actions.setResendInvitationLimitEntry({
            key,
            times: RESEND_LIMIT,
            sent: canResend ? new Date() : undefined,
          }),
        );
        const res = await api.organization.addMember(
          subdomain,
          email,
          permission,
          `You've been invited to the ${details.name} organization`,
        );
        if (res.result) {
          onUpdate();
        }
        break;
      }
      case 'remove': {
        const endpoint = isInvite
          ? api.organization.deleteOrganizationInvitation
          : api.organization.deleteOrganizationMember;
        const res = await endpoint(subdomain, isInvite ? id : email);
        if (res.result) {
          onUpdate();
        }
        break;
      }
      case 'edit':
        navigate(
          `/${ROUTES.LOGGED_IN_WITH_ORG.SETTINGS}/${SettingsType.profile}`,
        );
        break;
      case 'leave': {
        const res = await api.organization.leaveOrganization(subdomain);
        if (res.result) {
          logout();
        }
        break;
      }
    }
  };

  return {handleAction};
};
