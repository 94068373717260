import CommentButton from 'interface/stacks/content-viewer/components/control-bar/CommentButton';
import FullscreenButton from 'interface/stacks/content-viewer/components/control-bar/FullscreenButton';

type Props = {
  isFullscreen: boolean;
  toggleFullscreen: () => void;
};

const ControlsRight = ({isFullscreen, toggleFullscreen}: Props) => {
  return (
    <>
      <CommentButton />
      <FullscreenButton
        fullscreen={isFullscreen}
        toggleFullscreen={toggleFullscreen}
      />
    </>
  );
};

export default ControlsRight;
