import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import {useCallback, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DataProvider, LayoutProvider} from 'recyclerlistview/web';

import events from 'extensions/events';
import {useNavigate, useOutletContext, useParams} from 'extensions/navigation';
import {usePopup} from 'extensions/viewport/usePopup';
import useDebounce from 'interface/common/hooks/useDebounce';
import {isTouch} from 'utils/common/platform';

import {FileListItemLayout} from '../FileListItem';
import {FILE_CELL_HEIGHT} from '../FileListItemCell';
import {useWorkspaceContext} from '../hooks/useWorkspaceContext';
import {MEDIUM_THUMBNAIL_SIZE} from '../thumbnail/FileThumbnail';
import {sortItems} from '../utils/sort';

import {slices} from 'store';
import * as app from 'store/slices/app';
import {selectors} from 'store/slices/files';
import * as shared from 'store/slices/shared';

import type {ShareFilesNavigation} from 'interface/stacks/share/hooks/useShareFilesNavigation';
import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import type {MouseEvent} from 'react';
import type {LayoutChangeEvent, PointerEvent} from 'react-native';
import type {ScrollEvent} from 'recyclerlistview/dist/reactnative/core/scrollcomponent/BaseScrollView';
import type {RecyclerListView} from 'recyclerlistview/web';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';
import type {FileListProps} from '../FileList';

const GUTTER_SIZE = 16;
const CELL_PADDING = 16;
const RESIZE_DEBOUNCE_TIMEOUT_MS = 300;

export function useFileList(props: FileListProps) {
  const {view, items, options, multiplayer} = props;
  const {members, following} = multiplayer ?? {};
  const {folderKey, workspaceId} = useParams<'folderKey' | 'workspaceId'>();
  const contextWorkspace = useWorkspaceContext();
  const contextShare =
    useOutletContext<[Share, UploaderState, ShareFilesNavigation]>();

  const refResize = useRef(undefined);
  const refList = useRef<RecyclerListView<any, any>>();
  const popup = usePopup();
  const layout = useSelector(selectors.getLayout);
  const dragging = useSelector(selectors.getDrag);
  const _selection = useSelector(selectors.getSelection);
  const selection = options?.externalSelection ?? _selection;
  const isPremium = useSelector(app.selectors.isPremium);
  const isLoading = useSelector(
    selectors.isLoadingFolder(
      contextWorkspace?.workspace ? 'workspace' : 'share',
      contextWorkspace?.workspace?.folder_name ?? contextShare?.[0]?.id,
      contextWorkspace?.workspace ? folderKey : contextShare?.[2]?.folderKey,
    ),
  );

  const sort = useSelector(
    props.shareView
      ? shared.selectors.getShareSort(props.share?.id)
      : props.shareds
        ? shared.selectors.getSort
        : selectors.getSort,
  );

  const [isRangeSelect, setRangeSelect] = useState(false);
  const [isMultiSelect, setMultiSelect] = useState(false);
  const [focusedI, setFocusedI] = useState(-1);
  const [focused, setFocused] = useState('');
  const [offsetX, setOffsetX] = useState(0);
  const [listWidth, setListWidth] = useState(0);
  const [listHeight, setListHeight] = useState(0);

  const viewtype = props.layout || layout;
  const targetFolderId = folderKey ?? contextShare?.[2]?.folderKey;
  const workspaceTarget = options?.overrideWorkspaceId || workspaceId;

  const hasGridView = viewtype >= FileListItemLayout.GridSimple;
  const hasGutter = !options?.hideGutter;
  const hasOpen = !options?.disableOpen;
  const hasDrag = !options?.disableDrag;
  const hasFocus = !options?.disableFocus;
  const hasSelect = !options?.disableSelect;
  const hasExternalSelection = !!options?.externalSelection;

  const itemList = useMemo(
    () =>
      view
        // Filter null ids
        .filter(e => !!e)
        // Filtering types
        .filter(e => {
          const item = items[e];
          if (options?.hideFolders && item.type === StorageItemType.Folder)
            return false;
          if (options?.hideFiles && item.type === StorageItemType.File)
            return false;
          if (options?.hideShares && item.type === StorageItemType.Link)
            return false;
          return !!item;
        })
        // Sorting
        .sort((aID, bID) => sortItems(aID, bID, items, sort)),
    [view, sort, items],
  );

  const extended = useMemo(
    () => ({items, members, focused, selection, dragging}),
    [items, members, focused, selection, dragging],
  );

  const provider = useRef(
    new DataProvider(
      (a, b) => a !== b,
      i => itemList[i] || i.toString(),
    ),
  );

  const listData = useMemo(
    () => provider.current.cloneWithRows(itemList),
    [itemList],
  );

  const listGutter = useMemo(() => (hasGutter ? GUTTER_SIZE : 0), []);

  const listLayout = useMemo(
    () =>
      new LayoutProvider(
        () => viewtype,
        (type, rect, _index) => {
          const widthFull = listWidth - listGutter * 2;
          switch (type) {
            case FileListItemLayout.ListNormal:
              rect.width = widthFull;
              rect.height = isTouch() ? 60 : 43;
              return;
            case FileListItemLayout.GridSimple: {
              const cellSize = MEDIUM_THUMBNAIL_SIZE + CELL_PADDING;
              const columns = Math.floor(widthFull / cellSize);
              rect.width = widthFull / columns;
              rect.height = FILE_CELL_HEIGHT + CELL_PADDING;
              return;
            }
          }
        },
      ),
    [view, viewtype, listWidth],
  );

  const isEmpty = listData.getSize() === 0;

  // Actions

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const open = useCallback(
    async (item: FilesItem) => {
      if (props.onOpen) {
        props.onOpen(item);
        return;
      }

      const {id, type} = item;
      const baseUrl = `/workspace/${workspaceTarget}`;
      if (type === StorageItemType.Folder) {
        navigate(`${baseUrl}/storage/${id}`);
      } else if (type === StorageItemType.File) {
        navigate(`${baseUrl}/preview/${id}`);
      }
    },
    [workspaceTarget],
  );

  const focus = useCallback(
    (item?: FilesItem, openDetails?: boolean) => {
      if (!isRangeSelect) {
        setFocused(item ? item.id : '');
        if (openDetails) {
          dispatch(slices.files.actions.focus({id: item ? item.id : ''}));
        }
      }
    },
    [isRangeSelect],
  );

  const select = useCallback(
    (item: FilesItem) => {
      if (isRangeSelect) {
        dispatch(
          slices.files.actions.select({ids: [item.id], focused, itemList}),
        );
      } else {
        focus(item, true);
        dispatch(
          slices.files.actions.select({
            ids: [item.id],
            noToggle: !isMultiSelect,
          }),
        );
      }
    },
    [isRangeSelect, isMultiSelect, focused, itemList],
  );

  const resize = useDebounce(
    (e: LayoutChangeEvent) => {
      setListWidth(e.nativeEvent.layout.width);
      setListHeight(e.nativeEvent.layout.height);
      setOffsetX(e.nativeEvent.layout.x);
      refResize.current = true;
    },
    refResize.current ? RESIZE_DEBOUNCE_TIMEOUT_MS : 0,
  );

  const syncMouse = useCallback(
    (e: PointerEvent) => {
      const scroll = refList?.current?.getCurrentScrollOffset();
      const cursorX = e.nativeEvent.clientX - offsetX;
      const cursorY = e.nativeEvent.clientY + scroll;
      const isOffscreen = listWidth - cursorX < 100;
      multiplayer?.setState(
        isOffscreen
          ? {cursorOffscreen: true}
          : {cursorOffscreen: false, cursorX, cursorY, lastUpdate: Date.now()},
      );
    },
    [multiplayer?.setState, offsetX, listWidth],
  );

  const syncMouseIdle = useCallback(() => {
    multiplayer?.setState({cursorOffscreen: true});
  }, [multiplayer?.setState]);

  const syncScrollPos = useCallback(
    (e: ScrollEvent) => {
      const {x, y} = e.nativeEvent?.contentOffset;
      multiplayer?.setState({scrollX: x, scrollY: y});
    },
    [multiplayer?.setState],
  );

  const noop = useCallback(() => {}, []);

  const noopCancel = useCallback((e: DragEvent | MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  // Scroll to following cursor
  useLayoutEffect(() => {
    if (following) {
      const {scrollX, scrollY} = following;
      refList?.current?.scrollToOffset(scrollX, scrollY, false);
    }
  }, [members, following]);

  // Scroll list to top when folder changes
  useLayoutEffect(() => {
    setTimeout(() => {
      refList?.current?.scrollToTop(false);
    }, 0);
  }, [targetFolderId]);

  // Handle hotkeys
  useLayoutEffect(() => {
    const keyDown = events.addKeyListener('down', e => {
      // Range select (shift)
      if (e.flags.shiftKey) {
        setRangeSelect(true);
        return true;
      }
      // Multi select (ctrl)
      if (e.flags.ctrlKey || e.flags.metaKey) {
        setMultiSelect(true);
        return true;
      }
      switch (e.code) {
        // Navigate up list
        case 'ArrowUp':
          if (!popup.visible) {
            setFocusedI(focusedI - 1);
            // filelist.current.scrollToIndex(focusedI - 1);
          }
          break;
        // Navigate down list
        case 'ArrowDown':
          if (!popup.visible) {
            setFocusedI(focusedI + 1);
            // filelist.current.scrollToIndex(focusedI + 1);
          }
          break;
        // Navigate to top of list
        case 'ArrowLeft':
          if (!popup.visible) {
            refList?.current?.scrollToTop();
          }
          break;
        // Navigate to bottom of list
        case 'ArrowRight':
          if (!popup.visible) {
            refList?.current?.scrollToEnd();
          }
          break;
        // Select all
        case 'KeyA':
          if (e.flags.ctrlKey || e.flags.metaKey) {
            dispatch(
              slices.files.actions.select({ids: itemList, noToggle: true}),
            );
          }
          break;
        // Clear selection
        case 'Escape':
          dispatch(slices.files.actions.select({ids: []}));
          break;
      }
      return true;
    });

    const keyUp = events.addKeyListener('up', e => {
      // Shift select
      if (e.key === 'Shift') setRangeSelect(false);
      // Multi select
      if (e.key === 'Ctrl' || e.key === 'Meta') setMultiSelect(false);
      return true;
    });

    return () => {
      keyDown.unsubscribe();
      keyUp.unsubscribe();
    };
  }, [itemList, popup, focusedI]);

  return {
    items,
    itemList,
    listData,
    listWidth,
    listHeight,
    listLayout,
    listGutter,
    extended,
    refResize,
    refList,
    workspaceTarget,
    targetFolderId,
    isEmpty,
    isLoading,
    isPremium,
    hasGutter,
    hasOpen,
    hasSelect,
    hasExternalSelection,
    hasFocus,
    hasDrag,
    hasGridView,
    open,
    focus,
    select,
    resize,
    syncMouse,
    syncMouseIdle,
    syncScrollPos,
    noopCancel,
    noop,
  };
}
