// import theme from 'config/theme';
// import React, {useState} from 'react';
// import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
// import ActionButton from '../ActionButton';

const CommentButton = () => {
  // Hidding comments button for now
  return null;
  // const [showComments, setshowComments] = useState(true);

  // return (
  //   <ActionButton
  //     icon={
  //       showComments ? (
  //         <Icon
  //           name="comment-outline"
  //           size={20}
  //           color={theme.colors.neutral.$white}
  //         />
  //       ) : (
  //         <Icon
  //           name="comment-off-outline"
  //           size={20}
  //           color={theme.colors.neutral.$white}
  //         />
  //       )
  //     }
  //     tooltipText={showComments ? 'Hide Comments' : 'Show Comments'}
  //     onPress={() => setshowComments(prev => !prev)}
  //   />
  // );
};

export default CommentButton;
