import theme from 'config/theme';
import {StyleSheet, View} from 'react-native';
import ShareHeader from './ShareHeader';
import {ShareNotAvailableCard} from './ShareNotAvailableCard';

export function ShareNotAvailable() {
  return (
    <View style={[styles.root]}>
      <ShareHeader />
      <ShareNotAvailableCard />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors.neutral.$13,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  quickShareRoot: {
    backgroundColor: theme.colors.neutral.$black,
  },
});
