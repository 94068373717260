import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import type {ShareSort, Store} from './types';

export const defaultSort: ShareSort = {order: 'desc', category: 'date'};

export const initialState: Store = {
  isListLoading: false,
  workspaces: {externals: []},
  sort: defaultSort,
  sharedsByCustomName: {},
  shares: {},
  sharesLoaded: false,
};
const sliceName = 'shared';

const sharedSliceTransform = {
  in: (state, key) => {
    if (key === sliceName) {
      return {
        ...state,
        sharesLoaded: initialState.sharesLoaded,
      };
    }
    return state;
  },
  out: state => state,
};

export default createSlice({name: sliceName, reducers, initialState});
export {selectors, sharedSliceTransform};
