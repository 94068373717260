import theme from 'config/theme';
import {IconButton} from 'interface/common/IconButton';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {hexToRGBA} from 'utils/common/color';
import usePagination from '../hooks/usePagination';

const Pagination = () => {
  const {prev, next, handlePrev, handleNext} = usePagination();

  return (
    <>
      <View style={[styles.container, styles.previous]}>
        {prev && (
          <IconButton
            buttonStyle={styles.button}
            iconStyle={styles.icon}
            buttonHoverStyle={styles.hover}
            icon={
              <Icon
                name="chevron-left"
                size={28}
                color={theme.colors.neutral.$white}
              />
            }
            onPress={handlePrev}
          />
        )}
      </View>
      <View style={[styles.container, styles.next]}>
        {next && (
          <IconButton
            buttonStyle={styles.button}
            iconStyle={styles.icon}
            buttonHoverStyle={styles.hover}
            icon={
              <Icon
                name="chevron-right"
                size={28}
                color={theme.colors.neutral.$white}
              />
            }
            onPress={handleNext}
          />
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 'calc(50vh - 20px)',
    zIndex: 5,
  },
  previous: {
    left: 15,
  },
  next: {
    right: 15,
  },
  button: {
    borderRadius: 50,
    minWidth: 40,
    minHeight: 40,
    maxHeight: 40,
    backgroundColor: theme.colors.neutral.$black,
  },
  hover: {
    backgroundColor: hexToRGBA(theme.colors.neutral.$white, 0.1),
  },
  icon: {
    width: 28,
    height: 28,
  },
});

export default Pagination;
