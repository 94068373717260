import {t} from '@lingui/macro';
import {Button} from 'interface/common/Button';
import {ChatbotButton} from 'interface/common/error/ChatbotButton';
import {StyleSheet, View} from 'react-native';
import {RouteLayout} from '../_layout/RouteLayout';

type Props = {
  onInvalidNavigate: () => void;
};

export function InvalidToken({onInvalidNavigate}: Props) {
  return (
    <RouteLayout
      title={t`This invite link is not valid`}
      subTitle={t`The link you clicked is not valid or expired. Try contacting the person who sent the link for a new one.`}>
      <View style={styles.buttonsContainer}>
        <Button
          type="Secondary_thin"
          onPress={onInvalidNavigate}
          label={t`Go to Fast.io`}
        />
        <ChatbotButton />
      </View>
    </RouteLayout>
  );
}

const styles = StyleSheet.create({
  titleContainer: {
    marginBottom: '1rem',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  description: {
    textAlign: 'center',
    marginBottom: 20,
  },
});
