import theme from 'config/theme';
import {forwardRef, useMemo} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

type Value = number | string;

const CONTENT_PADDING_VERTICAL = 7;
const POPUP_ITEM_HEIGHT = 36;
const POPUP_HEADER_HEIGHT = 35;

export type Option = {
  label: string;
  value: Value;
  payload?: Record<string, any>;
};

export type OnChangeParams = {
  value: Value;
  index?: number;
  payload?: Record<string, any>;
};

type Props = {
  options: Option[];
  onChange: (params: OnChangeParams) => void;
  currentValue: Value;
  headerText: string;
  isRight?: boolean;
};

const MediaPopup = forwardRef(
  (
    {options, onChange, currentValue, headerText, isRight}: Props,
    ref: React.ForwardedRef<View>,
  ) => {
    const height = useMemo(
      () =>
        options.length * POPUP_ITEM_HEIGHT +
        CONTENT_PADDING_VERTICAL * 2 +
        POPUP_HEADER_HEIGHT,
      [options.length],
    );

    return (
      <View
        style={[styles.popup, {top: -height}, isRight ? {right: 0} : {left: 0}]}
        ref={ref}>
        <View style={styles.popupHeader}>
          <Text style={styles.popupHeaderText}>{headerText}</Text>
        </View>
        <View style={styles.popupContent}>
          {options.map(({label, value, payload}, index) => (
            <Pressable
              key={value}
              style={styles.popupItem}
              onPress={() => onChange({value, index, payload})}>
              <View style={styles.popupCheckContainer}>
                {currentValue === value && (
                  <Icon
                    name="check"
                    size={20}
                    color={theme.colors.neutral.$5}
                  />
                )}
              </View>
              <Text style={styles.popupItemText}>{label}</Text>
            </Pressable>
          ))}
        </View>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  popup: {
    width: 155,
    position: 'absolute',
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 7,
  },
  popupHeader: {
    paddingVertical: 7,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$11,
    alignItems: 'center',
  },
  popupHeaderText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$5,
  },
  popupContent: {
    paddingVertical: CONTENT_PADDING_VERTICAL,
  },
  popupItem: {
    height: POPUP_ITEM_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    gap: 8,
    paddingHorizontal: 30,
  },
  popupItemText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
  popupCheckContainer: {
    width: 20,
    height: 20,
  },
});

export default MediaPopup;
