import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Avatar} from 'interface/common/Avatar';
import {Button} from 'interface/common/Button';
import {dateFormatter} from 'interface/stacks/workspace/storage/base/consts';
import {StyleSheet, Text, View} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Icon from 'react-native-vector-icons/MaterialIcons';
import type {FilesComment} from 'store/slices/files/types';
import {getHashColor} from 'utils/common/color';

interface Props {
  comment: FilesComment;
  setReply?: (inp: boolean) => void;
  isReply?: boolean;
}

export function FileComment(props: Props) {
  const initials = `${props.comment.sender.firstName[0]}${props.comment.sender.lastName[0]}`;
  const name = `${props.comment.sender.firstName} ${props.comment.sender.lastName}`;
  const color = getHashColor(props.comment.sender.id);

  const reply = () => {
    props.setReply(true);
  };
  const openEmojis = () => {};

  const currentDate = new Date();
  const timePast =
    currentDate.getTime() - new Date(props.comment.createdAt).getTime();

  return (
    <View style={styles.root}>
      <View style={styles.comments}>
        <Avatar
          color={color}
          initials={initials}
          photo={props.comment.sender.profilePic}
        />
        <View style={styles.content}>
          <View style={styles.header}>
            <Text style={styles.name}>{name}</Text>
            <Text style={styles.date}>
              {dateFormatter(
                currentDate,
                new Date(props.comment.createdAt),
                timePast,
                false,
              )}
            </Text>
          </View>
          <Text style={styles.comment}>{props.comment.content}</Text>
          <View style={styles.buttons}>
            <Button
              type="Text"
              label={t`Reply`}
              onPress={reply}
              customTextStyle={{
                color: theme.colors.neutral.$4,
                marginLeft: 6,
              }}
              customRootStyle={{paddingHorizontal: 8}}
              lIcon={
                <MCIcon
                  name="arrow-left-top"
                  size={20}
                  color={theme.colors.neutral.$4}
                />
              }
            />
            <Button
              type="Text"
              onPress={openEmojis}
              customRootStyle={{paddingHorizontal: 8}}
              lIcon={
                <Icon
                  name="insert-emoticon"
                  size={20}
                  color={theme.colors.neutral.$4}
                />
              }
            />
          </View>
        </View>
        {!props.isReply && props.comment.reply.length !== 0 ? (
          <View
            style={[
              styles.leftBar,
              {
                height:
                  props.comment.reply.length === 1
                    ? '88%'
                    : `${props.comment.reply.length * 94}%`,
              },
            ]}
          />
        ) : null}
      </View>
      {props.comment.reply.map(comment => (
        <View
          style={styles.replies}
          key={comment.content + comment.sender.email}>
          <FileComment
            comment={comment}
            setReply={props.setReply}
            isReply={true}
          />
          <View style={styles.curveBar} />
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {},
  comments: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    gap: 10,
    position: 'relative',
  },
  content: {
    width: '90%',
    gap: 5,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  name: {
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
    marginRight: 10,
  },
  date: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
  },
  comment: {
    width: '93%',
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  replies: {
    paddingLeft: 30,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    position: 'relative',
  },
  leftBar: {
    position: 'absolute',
    top: 40,
    left: 10,
    width: 2,
    backgroundColor: theme.colors.neutral.$10,
  },
  curveBar: {
    position: 'absolute',
    width: 16,
    height: 16,
    left: 10,
    top: -1,
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderBottomColor: theme.colors.neutral.$10,
    borderLeftColor: theme.colors.neutral.$10,
    borderBottomLeftRadius: 12,
  },
});
