import theme from 'config/theme';
import {URLAccessType} from 'fast-sdk/src/api/share/consts';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {CustomPicker} from 'interface/common/CustomPicker';
import useGenerateShareUrl from 'interface/stacks/share/hooks/useGenerateShareUrl';
import {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import type {Share} from 'store/slices/shared/types';
import {copyLink} from 'utils/common/interactions';
import Section from './Section';
import SectionTitle from './SectionTitle';

interface Props {
  share: Share;
  updateShare: (updatedShare: Partial<Share>) => void;
}

const ACCESS_TYPE_LABELS = {
  [URLAccessType.MEMBERS_ONLY]: 'Anyone in this Workspace can view',
  [URLAccessType.ORG_MEMBERS]: 'Anyone in this Org can view',
  [URLAccessType.REGISTERED_USERS]: 'Anyone with the link',
};

const ACCESS_TYPE_DESCRIPTIONS = {
  [URLAccessType.MEMBERS_ONLY]:
    'Workspace members can access this share. People not part of this workspace can join by invitation.',
  [URLAccessType.ORG_MEMBERS]:
    'Organization members can access this share. People not part of this org can join by invitation.',
  [URLAccessType.REGISTERED_USERS]:
    'Anyone with a link to this Share can access it. They will also be able to invite other people to this Share.',
};

const ACCESS_TYPE_OPTIONS = Object.entries(ACCESS_TYPE_LABELS).map(
  ([key, value]) => ({
    value: key,
    title: value,
  }),
);

export default function Permissions({share, updateShare}: Props) {
  const toast = useToast();

  const {generateShareUrl} = useGenerateShareUrl();

  const [accessType, setAccessType] = useState<URLAccessType>(
    share?.access_options,
  );

  const shareUrl = `${location.origin}${generateShareUrl(share, false)}`;

  const copyShareLink = () => {
    copyLink(shareUrl);
    toast.show('The share URL was copied to clipboard', {type: 'success'});
  };

  const saveAccessType = (newAccessType: URLAccessType) => {
    setAccessType(newAccessType);
    updateShare({access_options: newAccessType});
  };

  useEffect(() => {
    setAccessType(share?.access_options);
  }, [share?.access_options]);

  return (
    <Section>
      <SectionTitle>Permissions</SectionTitle>
      <CustomPicker
        options={ACCESS_TYPE_OPTIONS}
        setSelected={(value: string) => saveAccessType(value as URLAccessType)}
        selected={accessType}
        fullWidth
      />
      <View style={styles.shareUrlContainer}>
        <View style={styles.shareUrl}>
          <Typography
            size="sm"
            color={theme.colors.neutral.$2Base}
            overrides={styles.urlText}>
            {shareUrl}
          </Typography>
          <View>
            <Icon name="lucide:link-2" color={theme.colors.neutral.$400} />
          </View>
        </View>
        <Button onPress={copyShareLink}>Copy</Button>
      </View>
      <Typography color={theme.colors.neutral.$2Base} size="sm">
        {ACCESS_TYPE_DESCRIPTIONS[accessType]}
      </Typography>
    </Section>
  );
}

const styles = StyleSheet.create({
  shareUrlContainer: {
    flexDirection: 'row',
    gap: 8,
    marginTop: 8,
    marginBottom: 16,
  },
  shareUrl: {
    flex: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 4,
    borderRadius: 6,
    backgroundColor: theme.colors.neutral.$75,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$100,
    padding: 6,
  },
  urlText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});
