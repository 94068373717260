import type {FileExifMetadata} from 'fast-sdk/src/api/storage/consts';
import type {FilesItem} from 'store/slices/files/types';

export const getFileExifMetadata = (
  file: FilesItem,
): Partial<FileExifMetadata> | undefined => {
  if (
    !file.file_attributes ||
    Array.isArray(file.file_attributes) ||
    !file.file_attributes.exif_metadata ||
    Array.isArray(file.file_attributes)
  ) {
    return undefined;
  }

  return file.file_attributes.exif_metadata;
};
