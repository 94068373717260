import {theme} from 'config/themes';
import {ModalContext} from 'extensions/viewport/ModalContext';
import {useMemo, useState} from 'react';
import {Modal, StyleSheet, View, useWindowDimensions} from 'react-native';

import type {ModalOptions} from 'extensions/viewport/ModalContext';

export const ModalProvider = ({children}) => {
  const screen = useWindowDimensions();
  const [ariaLabel, setAriaLabel] = useState<string>();
  const [content, setContent] = useState<JSX.Element>();
  const [options, setOptions] = useState<ModalOptions>({});
  const [visible, setVisible] = useState<boolean>(false);
  const classes = {
    root: [
      !options.frameless ? styles.root : styles.noframe,
      options.frameless ? styles.noframe : styles.root,
    ],
    content: [
      options.frameless ? styles.noframeContent : styles.content,
      screen.width < 500 && styles.contentSmall,
    ],
  };

  const context = useMemo(
    () => ({
      visible: false,
      open: (content: JSX.Element, options?: ModalOptions) => {
        setOptions(options ?? {});
        setContent(content);
        setVisible(true);
      },
      close: () => {
        setVisible(false);
        setOptions({});
      },
      setAriaLabel: (ariaLabel: string) => {
        setAriaLabel(ariaLabel);
      },
    }),
    [],
  );

  return (
    <ModalContext.Provider value={context}>
      {children}
      <Modal
        role="dialog"
        transparent
        visible={visible}
        style={options.style}
        aria-label={ariaLabel}
        onDismiss={options.forceChoice ? context.close : undefined}>
        <View style={classes.root} onPointerDown={context.close}>
          <View
            style={classes.content}
            onPointerDown={e => e.stopPropagation()}>
            {content}
          </View>
        </View>
      </Modal>
    </ModalContext.Provider>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: theme.dialog.overlay,
  },
  content: {
    minWidth: 360,
    minHeight: 225,
    justifyContent: 'center',
  },
  contentSmall: {
    minWidth: 300,
  },
  noframe: {
    flex: 1,
  },
  noframeContent: {
    flex: 0,
  },
});
