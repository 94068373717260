import {ROUTES} from 'constants/routes';
import {Navigate, Outlet} from 'extensions/navigation';
import {AppLoading} from 'interface/stacks/app/AppLoading';
import Auth from 'interface/stacks/auth/consts';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';

export default function VerificationRoute() {
  const [loading, setLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const storeToken = useSelector(user.selectors.getToken);

  useEffect(() => {
    async function getEmail() {
      const email = await Auth.getUserEmail();
      setEmail(email);
      setLoading(false);
    }

    getEmail();
  }, []);

  if (loading) {
    return <AppLoading />;
  }

  if (storeToken && email) {
    return <Outlet />;
  }

  return <Navigate to={ROUTES.NOT_LOGGED_IN.SIGNIN} replace />;
}
