import {useMemo} from 'react';
import {getCurrentSubdomain} from 'utils/common/platform';

export function useSubDomain() {
  const subdomain = useMemo(() => {
    return getCurrentSubdomain();
  }, []);

  return {
    subdomain,
  };
}
