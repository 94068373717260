import theme from 'config/theme';
import {api} from 'fast-sdk';
import type {Member} from 'fast-sdk/src/api/organization/consts';
import {MemberAvatar} from 'interface/common/MemberAvatar';
import {useEffect, useState} from 'react';
import type {GestureResponderEvent} from 'react-native';
import {Pressable, StyleSheet, Text, View} from 'react-native';

export interface ProfileMemberItemUserProps {
  id: string;
  onLoad?: (member: Member) => void;
  onPress?: (event: GestureResponderEvent) => void;
}

export function ProfileMemberItemUser(props: ProfileMemberItemUserProps) {
  const [member, setMember] = useState<Member>(null);

  const setDetails = async () => {
    const {result, user} = await api.organization.getOrganizationMemberDetails(
      props.id,
    );
    if (result) {
      setMember(user);
      props.onLoad?.(user);
    }
  };

  useEffect(() => {
    setDetails();
  }, []);

  return member ? (
    <Pressable
      // @ts-ignore
      style={({hovered}) => [
        styles.root,
        props.onPress && styles.pressable,
        props.onPress &&
          hovered && {backgroundColor: hovered ? '#F4F4F5' : 'transparent'},
      ]}
      onPress={props.onPress}>
      <View style={styles.avatar}>
        <MemberAvatar
          memberId={member.id}
          initials={`${member.first_name[0]}${member.last_name[0]}`}
        />
      </View>
      <View style={styles.info}>
        <Text style={styles.name}>
          {member.first_name} {member.last_name}
        </Text>
        <Text style={styles.email}>{member.email_address}</Text>
      </View>
    </Pressable>
  ) : null;
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pressable: {
    padding: 10,
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 9,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  name: {
    fontSize: 13,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 16,
    color: theme.colors.neutral.$2Base,
  },
  email: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 16,
    color: theme.colors.neutral.$2Base,
  },
});
