import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {MemberAvatar} from 'interface/common/MemberAvatar';
import {Pressable, StyleSheet, View} from 'react-native';
import type {Member} from 'store/slices/settings/types';

interface Props {
  onClose: () => void;
  members: Member[];
  activeMembers: Member[];
  ownerId: string;
}

export default function AiChatMembersModal({
  members,
  activeMembers,
  ownerId,
  onClose,
}: Props) {
  const inactiveMembers = members.filter(
    member => !activeMembers.includes(member),
  );

  return (
    <View style={styles.root}>
      <View style={styles.iconContainer}>
        <Pressable onPress={onClose}>
          <Icon name="lucide:x" size={24} color={theme.colors.neutral.$700} />
        </Pressable>
      </View>
      <Typography
        variant="medium"
        size="base"
        color={theme.colors.neutral.$700}>
        Members
      </Typography>
      {activeMembers.length > 0 && (
        <MemberSection
          title="Active Members"
          members={activeMembers}
          ownerId={ownerId}
          isActive={true}
        />
      )}
      {inactiveMembers.length > 0 && (
        <MemberSection
          title="Inactive Members"
          members={inactiveMembers}
          ownerId={ownerId}
        />
      )}
    </View>
  );
}

function MemberSection({
  title,
  members,
  ownerId,
  isActive,
}: {
  title: string;
  members: Member[];
  ownerId: string;
  isActive?: boolean;
}) {
  return (
    <View style={styles.memberSection}>
      <Typography
        variant="regular"
        size="sm"
        color={theme.colors.neutral.$500}
        overrides={styles.memberSectionTitle}>
        {title}
      </Typography>
      <View style={styles.membersContainer}>
        {members.map(member => (
          <MemberRow
            member={member}
            isOwner={member.id === ownerId}
            isActive={isActive}
          />
        ))}
      </View>
    </View>
  );
}

function MemberRow({
  member,
  isOwner,
  isActive,
}: {
  member: Member;
  isOwner: boolean;
  isActive: boolean;
}) {
  const name = `${member.firstName} ${member.lastName}`;
  const initials = name
    .split(' ')
    .map(name => name[0])
    .join('');
  return (
    <View style={styles.memberRow}>
      <MemberAvatar memberId={member.id} initials={initials} />
      <View style={styles.memberInfo}>
        <View style={styles.memberNameContainer}>
          <Typography
            variant="medium"
            size="sm"
            color={theme.colors.neutral.$700}>
            {name}
          </Typography>
          {isActive && <View style={styles.activeDot} />}
        </View>
        <Typography
          variant="regular"
          size="xs"
          color={theme.colors.neutral.$700}>
          {member.email}
        </Typography>
      </View>
      {isOwner && (
        <Typography
          variant="regular"
          size="xs"
          color={theme.colors.neutral.$700}
          overrides={styles.memberRole}>
          Owner
        </Typography>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 600,
    maxHeight: 800,
    padding: 24,
    gap: 24,
    backgroundColor: theme.colors.neutral.$0,
    borderRadius: 16,
  },
  iconContainer: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  membersContainer: {
    flexDirection: 'column',
    borderTopWidth: 1,
    borderTopColor: theme.colors.neutral.$75,
  },
  memberSectionTitle: {
    marginBottom: 17,
  },
  memberRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 48,
    gap: 12,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$75,
  },
  memberInfo: {
    flexDirection: 'column',
    flex: 1,
  },
  memberNameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
  memberSection: {
    flexDirection: 'column',
  },
  activeDot: {
    width: 6,
    height: 6,
    borderRadius: 50,
    backgroundColor: theme.colors.brand2.$2,
  },
  memberRole: {
    borderRadius: 50,
    paddingHorizontal: 12,
    paddingVertical: 3,
    backgroundColor: theme.colors.neutral.$75,
  },
});
