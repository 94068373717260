import RipleyLogo from 'assets/svgs/aichat/ripley.svg';
import theme from 'config/theme';
import {Popup} from 'extensions/viewport/Popup';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {useWorkspaceContext} from 'interface/stacks/workspace/storage/hooks/useWorkspaceContext';
import {Image, Pressable, StyleSheet, View} from 'react-native';
import type {EntityChatMessage} from 'store/slices/aichat/types';
import {AiChatResponseListeningItem} from './AiChatResponseListeningItem';

interface Props {
  message: EntityChatMessage;
}

export function AiChatResponseItem({message}: Props) {
  const {workspace} = useWorkspaceContext();
  const {id: workspaceId} = workspace;

  const handleCopyPress = () => {
    // TODO: Implement copy to clipboard
  };
  const responseText = message.response.text;

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Image
          source={{uri: RipleyLogo}}
          style={{width: 24, height: 24}}
          alt="Create Organization Image"
        />
        <Typography
          variant="medium"
          size="md"
          color={theme.colors.neutral.$700}>
          Ripley
        </Typography>
        <View style={styles.spacer} />
        <View style={styles.buttons}>
          <Pressable onPress={handleCopyPress} style={styles.iconContainer}>
            <Icon name="lucide:copy" color={theme.colors.neutral.$700} />
          </Pressable>
          <Popup
            triggerElement={
              <View style={styles.iconContainer}>
                <Icon
                  name="lucide:more-horizontal"
                  color={theme.colors.neutral.$700}
                />
              </View>
            }>
            <View style={styles.popupContent} />
          </Popup>
        </View>
      </View>
      {!responseText && (
        <AiChatResponseListeningItem
          workspaceId={workspaceId}
          chatId={message.chatId}
          messageId={message.id}
        />
      )}
      {responseText && (
        <Typography size="md" color={theme.colors.neutral.$700}>
          {responseText}
        </Typography>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    gap: 8,
  },
  buttons: {
    flexDirection: 'row',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
  },
  spacer: {
    flex: 1,
  },
  iconContainer: {
    padding: 4,
  },
  popupContent: {
    width: 100,
    height: 100,
  },
});
