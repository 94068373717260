import theme from 'config/theme';
import type {AvatarSize} from 'interface/common/Avatar';
import {MemberAvatar} from 'interface/common/MemberAvatar';
import Skeleton from 'interface/common/Skeleton';
import {
  StyleSheet,
  Text,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';
import type {Member} from 'store/slices/settings/types';

interface Props {
  members: Array<Member> | undefined;
  loading?: boolean;
  text?: string;
  rootStyle?: ViewStyle | ViewStyle[];
  textStyle?: TextStyle | TextStyle[];
  showCount?: boolean;
  size?: AvatarSize;
}

export function MemberAvatarGroup({
  members,
  loading,
  rootStyle,
  textStyle,
  text,
  showCount = true,
  size,
}: Props) {
  const membersToShow = members?.slice(0, 5);

  return (
    <View style={[styles.root, rootStyle]}>
      {loading ? (
        <Skeleton width={80} height={16} />
      ) : (
        Boolean(members?.length) && (
          <>
            {membersToShow.map((member, index) => (
              <MemberAvatar
                key={member.id}
                memberId={member.id}
                initials={`${member.firstName[0]}${member.lastName[0]}`}
                isStacked={index > 0}
                borderKind="light"
                size={size}
              />
            ))}
            {showCount && (
              <Text style={[styles.memberCount, textStyle]}>
                {text || `${members.length} members`}
              </Text>
            )}
          </>
        )
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  memberCount: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    marginLeft: 6,
    color: theme.colors.neutral.$2Base,
  },
});
