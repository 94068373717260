import theme from 'config/theme';
import {StyleSheet} from 'react-native';
import {hexToRGBA} from 'utils/common/color';
import {BUTTON_CONSTANTS} from './constants';

export const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
  icon: {
    width: BUTTON_CONSTANTS.ICON_SIZE,
    height: BUTTON_CONSTANTS.ICON_SIZE,
  },
  iconButton: {
    minWidth: BUTTON_CONSTANTS.BUTTON_MIN_SIZE,
    minHeight: BUTTON_CONSTANTS.BUTTON_MIN_SIZE,
    borderColor: hexToRGBA(
      theme.colors.neutral.$white,
      BUTTON_CONSTANTS.BORDER_OPACITY,
    ),
    borderWidth: 1,
  },
  iconButtonHover: {
    backgroundColor: hexToRGBA(
      theme.colors.neutral.$white,
      BUTTON_CONSTANTS.BORDER_OPACITY,
    ),
  },
  shareButton: {
    minWidth: BUTTON_CONSTANTS.BUTTON_MIN_SIZE,
    minHeight: BUTTON_CONSTANTS.BUTTON_MIN_SIZE,
    backgroundColor: theme.colors.brand.$4Base,
  },
  shareButtonHover: {
    backgroundColor: theme.colors.brand.$35Hover,
  },
  shareButtonText: {
    color: theme.colors.neutral.$white,
  },
});
