import {STORAGE_ROOT} from 'constants/routes';
import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {selectors as filesSelectors} from 'store/slices/files';

interface FolderTreeOptions {
  workspace: WorkspaceListDetail;
}

export function useFolderTree({workspace}: FolderTreeOptions) {
  const itemValues = Object.values(useSelector(filesSelectors.getItems));
  const rootFolderItems = useSelector(
    filesSelectors.getFolder('workspace', workspace?.folder_name, STORAGE_ROOT),
  );

  const buildHierarchy = useMemo(() => {
    const build = (parentId: string | undefined) => {
      const children = itemValues.filter(
        item =>
          item.parent === parentId && item.type === StorageItemType.Folder,
      );

      return children
        .filter(
          item =>
            item.parent !== STORAGE_ROOT || rootFolderItems?.includes(item.id),
        )
        .map(item => ({
          id: item.id,
          name: item.name,
          children: build(item.id),
          isExpanded: false,
        }));
    };

    return build(STORAGE_ROOT);
  }, [itemValues]);

  const rootItem = useMemo(
    () => ({
      id: STORAGE_ROOT,
      name: workspace?.name ?? 'Workspace',
      children: buildHierarchy,
      isExpanded: true,
    }),
    [buildHierarchy],
  );

  return {rootItem};
}
