import type {PercentCrop} from 'react-image-crop';

export const getCroppedImg = (
  img: string,
  crop: PercentCrop,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = img;

    image.onload = () => {
      const canvas = document.createElement('canvas');

      const aspectRatio = image.naturalWidth / image.naturalHeight;

      let differWidth: number;
      let differHeight: number;

      if (aspectRatio <= 1) {
        const freeSpace = (image.naturalHeight - image.naturalWidth) / 2;
        differHeight = 0;
        differWidth = Math.abs(freeSpace / image.naturalHeight);
      } else {
        const freeSpace = (image.naturalWidth - image.naturalHeight) / 2;
        differWidth = 0;
        differHeight = Math.abs(freeSpace / image.naturalWidth);
      }

      const ctx = canvas.getContext('2d');
      const canvasWidth = Math.floor(
        (crop.width / 100 + 2 * differWidth) * image.width,
      );
      const canvasHeight = Math.floor(
        (crop.height / 100 + 2 * differHeight) * image.height,
      );
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        Math.floor((crop.x / 100 - differWidth) * image.naturalWidth),
        Math.floor((crop.y / 100 - differHeight) * image.naturalHeight),
        canvasWidth,
        canvasHeight,
        0,
        0,
        canvasWidth,
        canvasHeight,
      );

      const base64Image = canvas.toDataURL('image/jpeg');
      resolve(base64Image);
    };
    image.onerror = () => {
      reject(img);
    };
  });
};

export const blobToBase64 = (
  blob: Blob,
  callback: (base64: string) => void,
) => {
  if (!blob || !blob.type) return;
  const reader = new FileReader();
  reader.onload = () => {
    const dataUrl = reader.result;
    const base64 = (dataUrl as string).split(',')[1];
    callback(`data:image/jpeg;base64,${base64}`);
  };
  reader.readAsDataURL(blob);
};
