import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import analytics from 'extensions/analytics';
import {api} from 'fast-sdk';
import {Button} from 'interface/common/Button';
import {CustomTextInput} from 'interface/common/CustomTextInput';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useSelector} from 'react-redux';
import * as settings from 'store/slices/settings';
import {SocialLinkType} from 'store/slices/settings/types';
import {linkProvider, providerMapper} from 'utils/common/validation';
import {LinkItem} from './LinkItem';

interface OrganizationLinksModalProps {
  close: () => void;
  getOrgDetails: () => void;
}

export const OrganizationLinksModal = (props: OrganizationLinksModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');

  const links = useSelector(settings.selectors.getOrganizationSocialLinks);

  const {subdomain} = useSubDomain();
  const toast = useToast();

  const getRequest = (provider: SocialLinkType, link: string) => {
    switch (provider) {
      case SocialLinkType.Facebook:
        return api.organization.updateOrganization(subdomain, {facebook: link});
      case SocialLinkType.Instagram:
        return api.organization.updateOrganization(subdomain, {
          instagram: link,
        });
      case SocialLinkType.Twitter:
        return api.organization.updateOrganization(subdomain, {twitter: link});
      case SocialLinkType.Youtube:
        return api.organization.updateOrganization(subdomain, {youtube: link});
      default:
        return api.organization.updateOrganization(subdomain, {homepage: link});
    }
  };

  const addLink = (link: string) => {
    setLoading(true);
    const provider = linkProvider(link);
    getRequest(provider, link)
      .then(res => {
        if (res?.result) {
          props.getOrgDetails();
        }
      })
      .catch(err => {
        toast.show(err.message, {type: 'danger'});
        analytics.notify(err);
      })
      .finally(() => {
        setLink('');
        setLoading(false);
      });
  };

  return (
    <View style={styles.root}>
      <Button
        type="Text"
        lIcon={<Icon name="close" size={18} color={theme.colors.neutral.$6} />}
        onPress={props.close}
        customRootStyle={styles.closeButton}
      />
      <View style={{padding: 24}}>
        <Text style={styles.header}>
          <Trans>Add Links</Trans>
        </Text>
        <View style={styles.inputContainer}>
          <CustomTextInput
            value={link}
            setValue={setLink}
            ariaLabel={t`Add New Link`}
            placeholder={t`Enter URL`}
            customInputStyle={{minWidth: 445, height: 36}}
            disabled={loading}
          />
          <Button
            label={t`Add`}
            type="Primary"
            disabled={!link || loading}
            onPress={() => addLink(link)}
            customRootStyle={{height: 36}}
            loading={loading}
            customLoadingColor={theme.colors.neutral.$white}
          />
        </View>
      </View>
      {links?.length ? (
        <View style={styles.linksContainer}>
          {links.map(link => (
            <LinkItem
              key={link.type + link.link}
              link={link}
              getOrgDetails={props.getOrgDetails}
            />
          ))}
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    maxWidth: 550,
    borderRadius: 7,
    backgroundColor: theme.colors.neutral.$white,
  },
  closeButton: {
    position: 'absolute',
    right: 6,
    top: 6,
    zIndex: 1,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  header: {
    width: '100%',
    textAlign: 'left',
    fontSize: 16.5,
    fontWeight: '600',
    lineHeight: 26,
    marginBottom: '1.5rem',
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 10,
  },
  linksContainer: {
    padding: 24,
    borderTopWidth: 1,
    borderTopColor: theme.colors.neutral.$12,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    rowGap: 10,
  },
});
