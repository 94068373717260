import {api} from 'fast-sdk';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as organization from 'store/slices/organization';

export default function useBillingMembers() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const {subdomain} = useSubDomain();
  const members = useSelector(organization.selectors.getMembers(subdomain));

  useEffect(() => {
    if (members) {
      return;
    }

    setLoading(true);

    async function fetchMembers() {
      const response = await api.billing.getBillingUsageMembers(subdomain);

      if (response.result) {
        const members = response.billable_members
          ? Object.values(response.billable_members)
          : [];

        dispatch(
          organization.default.actions.setMembersMap({
            orgId: subdomain,
            members,
          }),
        );
      }

      setLoading(false);
    }

    if (subdomain) {
      fetchMembers();
    }
  }, [subdomain, members]);

  return {
    members,
    loading,
  };
}
