import {t} from '@lingui/macro';
import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {Button} from 'interface/common/Button';
import type React from 'react';
import {useState} from 'react';
import {Pressable, StyleSheet, View} from 'react-native';

type SortDirection = 'asc' | 'desc';

export type Column = {
  title: string;
  width: string;
  isSortable?: boolean;
  sort?: (direction: SortDirection) => (a: any, b: any) => number;
};

type SortInfo = {
  direction: SortDirection;
  sort: (direction: SortDirection) => (a: any, b: any) => number;
};

export type SortColumnMap = Record<string, SortInfo>;

type Props = {
  columns: Array<Column>;
  renderRows: (
    sortedColumns: SortColumnMap,
    shouldSort: boolean,
  ) => React.ReactNode;
  onShowMore?: () => void;
};

const SettingsTable = ({columns, renderRows, onShowMore}: Props) => {
  const [sortedColumns, setSortedColumns] = useState<SortColumnMap>(
    columns.reduce((acc, column) => {
      if (column.isSortable) {
        acc[column.title] = {
          direction: 'asc',
          sort: column.sort,
        };
      }
      return acc;
    }, {}),
  );

  const handleSort = (title: string) => {
    setSortedColumns(prev => {
      const newDirection = prev[title].direction === 'asc' ? 'desc' : 'asc';
      return {
        ...prev,
        [title]: {
          ...prev[title],
          direction: newDirection,
        },
      };
    });
  };

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        {columns.map((column: Column) => (
          <View
            key={column.title}
            style={[styles.headerColumn, {width: column.width}]}>
            <Typography
              variant="regular"
              size="xs"
              color={theme.colors.neutral.$500}>
              {column.title}
            </Typography>
            {column.isSortable && (
              <Pressable onPress={() => handleSort(column.title)}>
                <Icon
                  name={`lucide:chevron-${sortedColumns[column.title].direction === 'asc' ? 'down' : 'up'}`}
                  color={theme.colors.neutral.$300}
                />
              </Pressable>
            )}
          </View>
        ))}
      </View>
      <View style={styles.body}>
        {renderRows(sortedColumns, Object.keys(sortedColumns).length > 0)}
      </View>
      {onShowMore && (
        <View style={styles.showMoreContainer}>
          <Button type="Text" label={t`Show More`} onPress={onShowMore} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  header: {
    height: 48,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$75,
    borderTopWidth: 1,
    borderTopColor: theme.colors.neutral.$75,
    paddingHorizontal: 8,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  headerColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    paddingHorizontal: 12,
    borderRightWidth: 1,
    borderRightColor: theme.colors.neutral.$75,
  },
  sortButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {},
  showMoreContainer: {
    width: 123,
  },
});

export default SettingsTable;
