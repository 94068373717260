import theme from 'config/theme';
import type {ViewStyle} from 'react-native';

type ContextMenuButton = {
  command: string;
  title: string;
  iconName: string;
  iconColor?: string;
  onPress?: () => void;
  additionalIconStyle?: ViewStyle;
};

export const CONTEXT_MENU_BUTTONS: {
  TOP: {
    createShare: ContextMenuButton;
    createFolder: ContextMenuButton;
    addToExistingShare: ContextMenuButton;
    quickShare: ContextMenuButton;
    download: ContextMenuButton;
    view: ContextMenuButton;
    comments: ContextMenuButton;
    previewShare: ContextMenuButton;
    openShareDialog: ContextMenuButton;
    customizeShare: ContextMenuButton;
    unarchiveShare: ContextMenuButton;
    deleteShare: ContextMenuButton;
  };
  MIDDLE: {
    uploadHere: ContextMenuButton;
    moveTo: ContextMenuButton;
    copyTo: ContextMenuButton;
    rename: ContextMenuButton;
  };
  BOTTOM: {
    moveToTrash: ContextMenuButton;
    purgeFromTrash: ContextMenuButton;
    restoreFromTrash: ContextMenuButton;
    archiveShare: ContextMenuButton;
  };
} = {
  TOP: {
    createShare: {
      command: 'create share',
      title: 'Create Share',
      iconName: 'auto-fix',
    },
    createFolder: {
      command: 'create folder',
      title: 'Add New Folder',
      iconName: 'folder-plus',
    },
    addToExistingShare: {
      command: 'add to existing share',
      title: 'Add to Existing Share',
      iconName: 'plus',
    },
    quickShare: {
      command: 'quick share',
      title: 'Quick Share',
      iconName: 'reply',
      // additionalIconStyle: { transform: [{ rotateX: "90deg" }] },
    },
    download: {
      command: 'download',
      title: 'Download',
      iconName: 'download',
    },
    view: {command: 'view', title: 'View', iconName: 'eye'},
    comments: {
      command: 'comments',
      title: 'Comments',
      iconName: 'mode-comment',
    },
    previewShare: {
      command: 'preview share',
      title: 'Preview Share',
      iconName: 'open-in-new',
    },
    openShareDialog: {
      command: 'open share dialog',
      title: 'Share',
      iconName: 'share',
    },
    customizeShare: {
      command: 'customize share',
      title: 'Customize Share',
      iconName: 'tune-variant',
    },
    unarchiveShare: {
      command: 'unarchive share',
      title: 'Restore',
      iconName: 'refresh',
    },
    deleteShare: {
      command: 'delete share',
      title: 'Delete Permanently',
      iconName: 'trash-can-outline',
      iconColor: theme.colors.danger.$4Base,
    },
  },
  MIDDLE: {
    uploadHere: {
      command: 'upload here',
      title: 'Upload Here',
      iconName: 'upload',
    },
    moveTo: {command: 'move to', title: 'Move to…', iconName: 'directions'},
    copyTo: {command: 'copy to', title: 'Copy to…', iconName: 'content-copy'},
    rename: {command: 'rename', title: 'Rename', iconName: 'form-textbox'},
  },
  BOTTOM: {
    moveToTrash: {
      command: 'move to trash',
      title: 'Move to Trash',
      iconName: 'delete',
    },
    purgeFromTrash: {
      command: 'purge from trash',
      title: 'Delete Permanently',
      iconName: 'delete-outline',
      iconColor: theme.colors.danger.$4Base,
    },
    restoreFromTrash: {
      command: 'restore from trash',
      title: 'Restore',
      iconName: 'refresh',
    },
    archiveShare: {
      command: 'archive share',
      title: 'Archive',
      iconName: 'archive-outline',
    },
  },
};
