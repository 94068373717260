import analytics from 'extensions/analytics';
import type {ShareLink, ShareType} from 'fast-sdk/src/api/share/consts';
import {DefaultLayout} from 'fast-sdk/src/api/share/consts';
import useGetShareDetails from 'interface/stacks/share/hooks/useGetShareDetails';
import {useCallback, useEffect, useMemo, useState} from 'react';
import useCreateShare from '../../../hooks/useCreateShare';
import useUpdateShare from '../../../hooks/useUpdateShare';

export enum UpdateMode {
  Create = 'Create',
  Edit = 'Edit',
}

export interface ShareState {
  title: string | undefined;
  setTitle: (title: string) => void;
  description: string | undefined;
  setDescription: (description: string) => void;
  links: ShareLink[];
  allowComments: boolean;
  setAllowComments: (allowComments: boolean) => void;
  currentFormLink: ShareLink | undefined;
  setCurrentFormLink: (link: ShareLink) => void;
  updateLink: (link: ShareLink) => void;
  removeLink: (link: ShareLink) => void;
  displayType: DefaultLayout;
  setDisplayType: (displayType: DefaultLayout) => void;
  expirationDate: Date | undefined;
  setExpirationDate: (expirationDate: Date | undefined) => void;
  intelligence: boolean;
  setIntelligence: (intelligence: boolean) => void;
}

interface ShareActionsOptions {
  workspaceFolderName: string;
  shareId: string | undefined;
  folderParentId: string | undefined;
  updateMode: UpdateMode;
  done?: () => void;
  items?: string[];
  shareType?: ShareType;
}

export default function useShareActions({
  workspaceFolderName,
  shareId,
  folderParentId,
  updateMode,
  done,
  items,
  shareType,
}: ShareActionsOptions) {
  const {share} = useGetShareDetails({workspaceFolderName, shareId});

  const {createShare, isLoading: isCreateShareLoading} = useCreateShare();
  const {updateShare, isLoading: isUpdateShareLoading} = useUpdateShare({
    share,
  });

  const [title, setTitle] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [links, setLinks] = useState<ShareLink[]>([]);
  const [allowComments, setAllowComments] = useState(true);
  const [displayType, setDisplayType] = useState(DefaultLayout.List);
  const [expirationDate, setExpirationDate] = useState<Date | undefined>(
    undefined,
  );
  const [intelligence, setIntelligence] = useState(true);
  const [currentFormLink, setCurrentFormLink] = useState<ShareLink | undefined>(
    undefined,
  );
  const [isContentLoading, setIsContentLoading] = useState(
    updateMode === UpdateMode.Edit,
  );

  const buildShareRequest = () => ({
    title,
    description,
    comments_enabled: allowComments,
    display_type: displayType,
    link_1: links[0] ? {...links[0], id: undefined} : undefined,
    link_2: links[1] ? {...links[1], id: undefined} : undefined,
    link_3: links[2] ? {...links[2], id: undefined} : undefined,
  });

  const handleCreateShare = async () => {
    analytics.log('Created share');
    await createShare(
      workspaceFolderName,
      folderParentId,
      {...buildShareRequest(), share_type: shareType, intelligence},
      items,
    );
    done?.();
  };

  const handleUpdateShare = async () => {
    await updateShare(buildShareRequest());
    done?.();
  };

  const updateLink = useCallback(
    (link: ShareLink) => {
      setLinks(prevLinks => {
        const newLinks = [...prevLinks];
        const index = newLinks.findIndex(l => l.id === link.id);
        if (index === -1) {
          newLinks.push(link);
        } else {
          newLinks[index] = link;
        }
        return newLinks;
      });
    },
    [links],
  );

  const removeLink = useCallback(
    (link: ShareLink) => {
      setLinks(prevLinks => prevLinks.filter(l => l.id !== link.id));
    },
    [links],
  );

  useEffect(() => {
    if (share) {
      setTitle(share.title);
      setDescription(share.description);
      setLinks(
        [
          share.link_1 && {...share.link_1, id: 1},
          share.link_2 && {...share.link_2, id: 2},
          share.link_3 && {...share.link_3, id: 3},
        ].filter(Boolean),
      );
      setAllowComments(share.comments.enabled);
      setDisplayType(share.display_type);
      setExpirationDate(share?.expires ? new Date(share.expires) : undefined);
      setIsContentLoading(false);
    }
  }, [share]);

  const hasChanges = useMemo(() => {
    if (!share || updateMode === UpdateMode.Create) return true;

    // Compare current state with original share data
    const currentLinks = [
      links[0] ? {...links[0], id: undefined} : undefined,
      links[1] ? {...links[1], id: undefined} : undefined,
      links[2] ? {...links[2], id: undefined} : undefined,
    ];

    return (
      title !== share.title ||
      description !== share.description ||
      allowComments !== share.comments.enabled ||
      displayType !== share.display_type ||
      JSON.stringify(currentLinks) !==
        JSON.stringify([share.link_1, share.link_2, share.link_3])
    );
  }, [
    share,
    title,
    description,
    allowComments,
    displayType,
    links,
    updateMode,
  ]);

  const isFormInvalid =
    !title ||
    title.length < 3 ||
    (updateMode === UpdateMode.Edit && !hasChanges);

  const shareState = {
    title,
    setTitle,
    description,
    setDescription,
    links,
    allowComments,
    setAllowComments,
    displayType,
    setDisplayType,
    currentFormLink,
    setCurrentFormLink,
    updateLink,
    removeLink,
    expirationDate,
    setExpirationDate,
    intelligence,
    setIntelligence,
  } as ShareState;

  return {
    shareState,
    isSaveLoading: isCreateShareLoading || isUpdateShareLoading,
    handleCreateShare,
    handleUpdateShare,
    isFormInvalid,
    isContentLoading,
  };
}
