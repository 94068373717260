import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {Toggle} from 'interface/base/Toggle';
import Typography from 'interface/base/Typography';
import {Button} from 'interface/common/Button';
import Modal from 'interface/common/Modal';
import {TextInput} from 'interface/common/TextInput';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useToast} from 'react-native-toast-notifications';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';
import type {Organization} from 'store/slices/user/types';
import useCreateWorkspace from '../hooks/useCreateWorkspace';
import {useGetWorkspaceDetails} from '../hooks/useGetWorkspaceDetails';

interface WorkspaceCreateProps {
  close: () => void;
  organization?: Organization;
  setIsReloading?: (loading: boolean) => void;
}

export function WorkspaceCreate(props: WorkspaceCreateProps) {
  const toast = useToast();

  const [workspaceName, setWorkspaceName] = useState<string>();
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [intelligence, setIntelligence] = useState<boolean>(true);

  const currentSelectedOrganization = useSelector(
    user.selectors.getSelectedOrganization,
  );
  const orgToCreateWorkspace =
    props.organization || currentSelectedOrganization;

  const {fetchWorkspaceDetails} = useGetWorkspaceDetails();
  const {createWorkspace} = useCreateWorkspace();

  const reloadWorkspaces = async (workspaceId: string) => {
    if (props.setIsReloading) {
      try {
        props.setIsReloading(true);
        await fetchWorkspaceDetails(workspaceId);
      } finally {
        props.setIsReloading(false);
      }
    } else {
      fetchWorkspaceDetails(workspaceId);
    }
  };

  const createNewWorkspace = async () => {
    try {
      setCreateLoading(true);
      const {result, workspace: workspaceCreated} = await createWorkspace({
        subdomain: orgToCreateWorkspace.domain,
        orgId: orgToCreateWorkspace.id,
        name: workspaceName.trim(),
        intelligence,
      });
      if (result) {
        toast.show(`The "${workspaceName.trim()}" workspace is created`, {
          type: 'success',
        });
        reloadWorkspaces(workspaceCreated.id);
      }
    } finally {
      setCreateLoading(false);
      props.close();
    }
  };

  return (
    <Modal title={t`Create a Workspace`} close={props.close}>
      <TextInput
        label={t`Workspace name`}
        placeHolder={t`Enter workspace name`}
        value={workspaceName}
        setInputValue={setWorkspaceName}
        customStyle={{marginBottom: 24}}
      />
      <View style={styles.toggleContainer}>
        <Toggle checked={intelligence} onChange={setIntelligence} />
        <Typography size="md" color={theme.colors.neutral.$2Base}>
          <Trans>Intelligence</Trans>
        </Typography>
      </View>
      <View style={styles.buttonsContainer}>
        <Button
          label={t`Cancel`}
          onPress={props.close}
          type="Secondary"
          customRootStyle={styles.rootButton}
        />
        <Button
          label={t`Create`}
          loading={createLoading}
          onPress={createNewWorkspace}
          type="Primary"
          customRootStyle={styles.rootButton}
          customLoadingColor={theme.colors.neutral.$white}
          disabled={!workspaceName}
        />
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 450,
    paddingBottom: 24,
    backgroundColor: '#fff',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
  },
  rootButton: {
    width: 61,
    height: 36,
  },
  toggleContainer: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
});
