import {useEffect} from 'react';
import {Outlet} from 'react-router';
import {GO_SUBDOMAIN} from 'utils/fast/constants';
import useHost from './hooks/useHost';
import {useSubDomain} from './hooks/useSubDomain';

export default function GoRoute() {
  const {subdomain} = useSubDomain();
  const {domain, loaded, mainDomain, protocol} = useHost();

  useEffect(() => {
    if (loaded && subdomain !== GO_SUBDOMAIN) {
      if (!subdomain) {
        window.location.href = `${protocol}://${GO_SUBDOMAIN}.${domain}${window.location.pathname}`;
        return;
      }

      const url = `${protocol}://${domain}${window.location.pathname}`;
      window.location.href = url.replace(subdomain, GO_SUBDOMAIN);
    }
  }, [loaded]);

  return <Outlet />;
}
