import {Trans, t} from '@lingui/macro';
import {PaymentElement} from '@stripe/react-stripe-js';
import theme from 'config/theme';
import {Button} from 'interface/common/Button';
import {Loading} from 'interface/common/Loading';
import {DISCOUNT} from 'interface/stacks/onboard/lib/consts';
import {useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {BilledDurationType} from 'store/slices/settings/types';

import {PlanDetails} from './PlanDetails';

import type {PlanMetaData} from 'interface/stacks/onboard/lib/types';

interface AddCardProps {
  plan: PlanMetaData;
  selectedUnit: BilledDurationType;
  handleSubmit: () => void;
}

export function AddCard(props: AddCardProps) {
  const [loading, setLoading] = useState<boolean>(true);
  return (
    <View style={styles.root}>
      <Text selectable style={styles.title}>
        <Trans>Upgrade to {props.plan.name}</Trans>
      </Text>
      <Text selectable style={styles.content}>
        <Trans>
          Review your plan summary below, then add your payment details to
          continue with your upgrade.
        </Trans>
      </Text>
      <PlanDetails
        plan={props.plan}
        unit={props.selectedUnit}
        totalAmount={props.plan.price}
        totalPlanCost={props.plan.price}
        annualDiscount={(props.plan.price / 100) * DISCOUNT}
        tax={(props.plan.price / 100) * 9}
      />
      <View style={styles.inputs}>
        {loading && <Loading />}
        <form style={{display: loading ? 'none' : 'flex'}}>
          <View style={{marginVertical: '1rem'}}>
            <PaymentElement
              onReady={() => setLoading(false)}
              options={{
                layout: {
                  type: 'tabs',
                  defaultCollapsed: false,
                },
              }}
            />
          </View>
          {!loading && (
            <Button
              type="Primary"
              label={t`Upgrade to ${props.plan.name}, ${props.selectedUnit === BilledDurationType.Yearly ? 'Annual' : 'Monthly'} - pay $???`}
              customRootStyle={styles.button}
              customTextStyle={styles.buttonText}
              onPress={event => {
                event.preventDefault();
                props.handleSubmit();
              }}
            />
          )}
        </form>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 50,
    borderRadius: 14,
    backgroundColor: theme.colors.neutral.$white,
    shadowColor: theme.colors.neutral.$black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3.05,
    elevation: 4,
    maxWidth: 650,
  },
  title: {
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 48,
    marginBottom: '1.5rem',
    textAlign: 'center',
    color: theme.colors.neutral.$2Base,
  },
  content: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    marginBottom: '2.5rem',
    textAlign: 'center',
  },
  inputs: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 12,
  },
  rowInputs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    marginTop: 26,
    height: 48,
    borderRadius: 5,
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '600',
    lineHeight: 24,
  },
});
