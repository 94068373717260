import {t} from '@lingui/macro';
import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {Button} from 'interface/common/Button';
import {FormError} from 'interface/common/FormError';
import {useNavigate} from 'react-router';
import {RouteLayout} from '../_layout/RouteLayout';

type Props = {
  errorMessage: string;
};

const PasswordResetFailed = ({errorMessage}: Props) => {
  const navigate = useNavigate();

  return (
    <RouteLayout
      title={t`Reset your password`}
      subTitle="Choose a new password for your account."
      customContentContainerStyle={{marginBottom: '2rem'}}>
      <FormError errorMessage={errorMessage} />
      <Button
        type="Primary"
        label={t`Reset Again`}
        ariaLabel={t`Reset Again`}
        customRootStyle={{height: 48}}
        onPress={() => navigate(`/${ROUTES.NOT_LOGGED_IN.FORGOT_PASSWORD}`)}
        customLoadingColor={theme.colors.neutral.$white}
      />
    </RouteLayout>
  );
};

export default PasswordResetFailed;
