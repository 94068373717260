import theme from 'config/theme';
import {ShareType} from 'fast-sdk/src/api/share/consts';

export const ShareTypeIcon = {
  [ShareType.Exchange]: 'lucide:repeat-2',
  [ShareType.Send]: 'lucide:square-arrow-right',
  [ShareType.Receive]: 'lucide:square-arrow-left',
};

export const ShareTypeColor = {
  [ShareType.Exchange]: theme.colors.shares.$exchange,
  [ShareType.Send]: theme.colors.shares.$send,
  [ShareType.Receive]: theme.colors.shares.$receive,
};

export const ShareTypeLabel = {
  [ShareType.Exchange]: 'Exchange',
  [ShareType.Send]: 'Send',
  [ShareType.Receive]: 'Receive',
};
