import type {Placement} from '@floating-ui/react-dom';
import {createContext} from 'react';
import type {ViewStyle} from 'react-native';

export type PopupType = undefined | 'hoverable';
export type PopupContextType = {
  reference: any;
  visible: boolean;
  open(content: JSX.Element, options?: PopupOptions): void;
  close(): void;
  setRef: (ref: React.RefObject<any>) => void;
  setPlacement: (inp: Placement) => void;
  setPosition?: (cor: {x: number; y: number} | undefined) => void;
  setType?: (type: PopupType) => void;
  onHoverIn?: (inp?: any) => void;
  onHoverOut?: (inp?: any) => void;
  setManualPosition?: (cor: {x: number; y: number}) => void;
};

export const PopupContext = createContext<PopupContextType>(null);

export interface PopupOptions {
  style?: ViewStyle;
  anchorPosition?: {x: number; y: number};
  closeOnClickOutside?: boolean;
  showArrow?: boolean;
}
