import type {
  Chat,
  ChatCreator,
  ChatMessage,
} from 'fast-sdk/src/api/workspace/aichat/consts';
import type {
  EntityChat,
  EntityChatCreator,
  EntityChatMessage,
  EntityChatMessageContent,
  EntityChatMessageResponse,
} from './types';

export function buildChat(
  chat: Chat,
  workspaceId: string,
  existingMessages: EntityChatMessage[],
): EntityChat {
  return {
    id: chat.chat_id,
    creator: buildChatMember(chat.creator),
    workspaceId: workspaceId,
    name: chat.name,
    messageCount: chat.message_count,
    status: chat.status,
    createdAt: chat.created_at,
    updatedAt: chat.updated_at,
    members: chat.unique_creators.map(buildChatMember),
    messages: existingMessages,
  };
}
export function buildChatMessage(message: ChatMessage): EntityChatMessage {
  return {
    id: message.message_id,
    chatId: message.chat_id,
    creator: buildChatMember(message.creator),
    state: message.state,
    content: buildChatMessageContent(message),
    response: buildChatMessageResponse(message),
    createdAt: message.created,
    updatedAt: message.updated,
  };
}

function buildChatMessageContent(
  message: ChatMessage,
): EntityChatMessageContent {
  return {
    text: message.message.text,
  };
}

function buildChatMessageResponse(
  message: ChatMessage,
): EntityChatMessageResponse | undefined {
  return {
    text: message.response?.text,
  };
}

function buildChatMember(member: ChatCreator): EntityChatCreator {
  return {
    id: member.id,
    type: member.type,
  };
}
