import theme from 'config/theme';
import {Avatar} from 'interface/common/Avatar';
import {StyleSheet, Text, View, type ViewStyle} from 'react-native';
import {getHashColor} from 'utils/common/color';

interface Props {
  id: string;
  email: string;
  imageSrc: string;
  initials?: string;
  customStyle?: ViewStyle;
}

export function UserBadge(props: Props) {
  return (
    <View style={[props.customStyle, styles.root]}>
      <Avatar
        color={getHashColor(props.id)}
        initials={props.initials}
        photo={props.imageSrc}
      />
      <Text style={styles.email}>{props.email}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 50,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$9,
    padding: 3,
  },
  email: {
    fontSize: 13,
    fontWeight: '400',
    paddingHorizontal: 10,
    paddingVertical: 5,
    color: theme.colors.neutral.$2Base,
  },
});
