import {useSearchParams} from 'extensions/navigation/hooks/useSearchParams';
import useHost from 'interface/common/hooks/useHost';
import {getCookie, setCookie} from 'utils/common/cookies';

const TOKEN_KEY = {
  param: 'token',
  cookie: 'auth_token',
};

const USER_ID_KEY = {
  param: 'user_id',
  cookie: 'user_id',
};

const isLocalhost = (url: string) => url.includes('localhost');

const useSubdomainAuth = () => {
  const [searchParams] = useSearchParams();
  const {mainDomain, protocol, loaded} = useHost();

  const sendToken = (token: string, userId: string, url: string) => {
    const isLocal = isLocalhost(mainDomain);

    if (!isLocal) {
      setCookie({
        name: TOKEN_KEY.cookie,
        value: token,
        domain: mainDomain.replace(/:\d+$/, ''),
        protocol,
      });
      setCookie({
        name: USER_ID_KEY.cookie,
        value: userId,
        domain: mainDomain.replace(/:\d+$/, ''),
        protocol,
      });
    }

    const divider = url.includes('?') ? '&' : '?';
    window.location.replace(
      isLocal
        ? `${url}${divider}${TOKEN_KEY.param}=${token}&${USER_ID_KEY.param}=${userId}`
        : url,
    );
  };

  const getToken = () => {
    if (isLocalhost(mainDomain)) {
      return searchParams.get(TOKEN_KEY.param);
    }

    return getCookie(TOKEN_KEY.cookie);
  };

  const getUserId = () => {
    if (isLocalhost(mainDomain)) {
      return searchParams.get(USER_ID_KEY.param);
    }

    return getCookie(USER_ID_KEY.cookie);
  };

  return {sendToken, getUserId, getToken, loaded};
};

export default useSubdomainAuth;
