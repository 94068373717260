import theme from 'config/theme';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';

interface Props {
  messagesCount: number;
}

export function MessagesLabel({messagesCount}: Props) {
  return (
    <View style={styles.container}>
      <Icon
        name="lucide:message-square-text"
        size={16}
        color={theme.colors.neutral.$300}
      />
      <Typography size="xs" color={theme.colors.neutral.$500}>
        {messagesCount}
      </Typography>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 3,
    gap: 4,
  },
});
