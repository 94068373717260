import {api} from 'fast-sdk';
import type {
  EventProfile,
  EventSearchParams,
} from 'fast-sdk/src/api/events/consts';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import * as eventsSlice from 'store/slices/events';

export const useFetchEvents = () => {
  const dispatch = useDispatch();

  const fetchEvents = useCallback(
    async (
      profile: EventProfile,
      params: EventSearchParams,
    ): Promise<boolean> => {
      dispatch(
        eventsSlice.default.actions.setProfileLoading({profile, loading: true}),
      );

      try {
        const {result, events} = await api.events.search(params);

        if (result) {
          dispatch(
            eventsSlice.default.actions.addProfileEvents({
              profile,
              events,
            }),
          );
          dispatch(
            eventsSlice.default.actions.setProfileHasMore({
              profile,
              hasMore: events.length === params.limit,
            }),
          );
          dispatch(
            eventsSlice.default.actions.setProfileError({profile, error: null}),
          );
          return true;
        }

        return false;
      } catch (err) {
        dispatch(
          eventsSlice.default.actions.setProfileError({
            profile,
            error: err instanceof Error ? err.message : 'An error occurred',
          }),
        );
        return false;
      } finally {
        dispatch(
          eventsSlice.default.actions.setProfileLoading({
            profile,
            loading: false,
          }),
        );
      }
    },
    [],
  );

  return {fetchEvents};
};
