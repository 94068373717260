import {useRef, useState} from 'react';

export const ZOOM_VALUES = {
  '12.5%': 0.125,
  '25%': 0.25,
  '50%': 0.5,
  '100%': 1,
  '200%': 2,
  '300%': 3,
  '400%': 4,
};

export const ZOOM_VALUES_ARRAY = Object.values(ZOOM_VALUES).sort(
  (a, b) => a - b,
);

export const getClosestZoom = (zoom: number, dir: 'in' | 'out') => {
  const val = ZOOM_VALUES_ARRAY;
  const idx = val.findIndex(l => l >= zoom);
  const len = val.length - 1;
  const asc = dir === 'in';
  const cur = zoom === val[idx];
  return val[Math.min(Math.max(idx + (asc ? (cur ? 1 : 0) : -1), 0), len)];
};

export type Zoom = (typeof ZOOM_VALUES)[keyof typeof ZOOM_VALUES];

export default function useZoom() {
  const [zoom, setZoom] = useState(ZOOM_VALUES['100%']);
  const lastZoom = useRef(ZOOM_VALUES['100%']);

  const handleZoomIn = () =>
    setZoom(currentZoom => {
      lastZoom.current = currentZoom;
      return getClosestZoom(currentZoom, 'in');
    });

  const handleZoomOut = () =>
    setZoom(currentZoom => {
      lastZoom.current = currentZoom;
      return getClosestZoom(currentZoom, 'out');
    });

  const handleLastZoom = () => setZoom(lastZoom.current);
  const handleZoom = (zoom: Zoom) => setZoom(zoom);

  return {zoom, handleZoomIn, handleZoomOut, handleZoom, handleLastZoom};
}
