import {t} from '@lingui/macro';
import theme from 'config/theme';
import type {InvitationDetails} from 'fast-sdk/src/api/share/consts';
import React, {useMemo} from 'react';
import {StyleSheet, Text} from 'react-native';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import type {User} from 'store/slices/user/types';
import {RouteLayout} from '../_layout/RouteLayout';
import type {OrganizationSummary} from '../consts';
import {OrganizationCard} from './OrganizationCard';
import {UserBadge} from './UserBadge';

interface OrganizationInviteProps {
  loading: boolean;
  userDetails: User;
  userProfilePic: string;
  organization: OrganizationSummary;
  invitation: InvitationDetails;
  onJoinButtonPress: () => void;
}

export const OrganizationInvite = ({
  loading,
  userDetails,
  userProfilePic,
  organization,
  invitation,
  onJoinButtonPress,
}: OrganizationInviteProps) => {
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  const title = useMemo(
    () => (
      <Text>
        {t`You've been invited to join`}{' '}
        <Text style={styles.subDomainName}>{organization.name}</Text>
      </Text>
    ),
    [organization.name],
  );

  const subTitle = useMemo(() => {
    if (isLoggedIn) {
      return (
        <UserBadge
          id={userDetails.id}
          email={userDetails.email_address}
          imageSrc={userProfilePic}
          customStyle={{marginBottom: '0.5rem'}}
          initials={`${userDetails.first_name[0]}${userDetails.last_name[0]}`}
        />
      );
    }
    return t`To continue, create a Fastio account or sign in to your existing one.`;
  }, []);

  return (
    <RouteLayout title={title} subTitle={subTitle} customRootMaxWidth={700}>
      <OrganizationCard
        loading={loading}
        organization={organization}
        invitedByEmail={invitation.inviter}
        pending={true}
        onJoinButtonPress={onJoinButtonPress}
      />
    </RouteLayout>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 26,
    fontWeight: '700',
    lineHeight: 40,
    color: theme.colors.neutral.$2Base,
    marginBottom: 42,
    textAlign: 'center',
  },
  subDomainName: {
    color: theme.colors.brand.$3,
  },
});
