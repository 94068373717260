import analytics from 'extensions/analytics';

export function reporter(_store: any) {
  return {
    pluginReporter: (_store: any) => (next: any) => (action: any) => {
      if (action.type === '@@router/LOCATION_CHANGE') {
        analytics.page(action.payload.pathname);
      }
      try {
        return next(action);
      } catch (error) {
        analytics.notify(error);
      }
    },
  };
}
