import theme from 'config/theme';
import {IconButton} from 'interface/common/IconButton';
import {useHover} from 'interface/common/hooks/useHover';
import {forwardRef} from 'react';
import {
  type GestureResponderEvent,
  StyleSheet,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';
import {hexToRGBA} from 'utils/common/color';
import ActionTooltip from './ActionTooltip';

export type ActionButtonProps = {
  icon?: React.ReactNode;
  text?: string | React.JSX.Element;
  textStyle?: TextStyle | TextStyle[];
  showBorder?: boolean;
  isActive?: boolean;
  onPress: (e?: GestureResponderEvent) => void;
  tooltipText?: string;
  tooltipPos?: 'top' | 'bottom';
  hideTooltip?: boolean;
  hoverStyle?: ViewStyle | ViewStyle[];
  buttonStyle?: ViewStyle | ViewStyle[];
  kind?: 'dark' | 'light';
  disabled?: boolean;
};

const ActionButton = forwardRef(
  (
    {
      icon,
      showBorder = false,
      text,
      textStyle,
      onPress,
      isActive,
      tooltipText,
      tooltipPos = 'top',
      hideTooltip,
      hoverStyle,
      kind,
      buttonStyle,
      disabled,
    }: ActionButtonProps,
    ref,
  ) => {
    const {isHover, onHoverIn, onHoverOut} = useHover();

    const classes = {
      button: {
        ...styles.button,
        borderWidth: showBorder ? 1 : 0,
        ...(isActive && styles.active),
      },
    };

    return (
      <View>
        <IconButton
          reference={ref}
          buttonStyle={[
            classes.button,
            ...(Array.isArray(buttonStyle) ? buttonStyle : [buttonStyle]),
          ]}
          iconStyle={styles.icon}
          buttonHoverStyle={hoverStyle ?? styles.hover}
          textStyle={textStyle}
          text={text}
          onPress={onPress}
          icon={icon}
          onHoverIn={onHoverIn}
          onHoverOut={onHoverOut}
          disabled={disabled}
        />
        {isHover && tooltipText && !hideTooltip && (
          <ActionTooltip kind={kind} text={tooltipText} position={tooltipPos} />
        )}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
  icon: {
    width: 20,
    height: 20,
  },
  button: {
    minWidth: 36,
    minHeight: 36,
    maxHeight: 36,
    borderColor: hexToRGBA(theme.colors.neutral.$white, 0.1),
  },
  hover: {
    backgroundColor: hexToRGBA(theme.colors.neutral.$white, 0.1),
  },
  active: {
    backgroundColor: hexToRGBA(theme.colors.neutral.$white, 0.1),
  },
});

export default ActionButton;
