import {useEffect, useState} from 'react';
import {
  getEnvironmentDomain,
  getEnvironmentProtocol,
} from 'utils/common/version';

const useHost = () => {
  const [domain, setDomain] = useState('');
  const [mainDomain, setMainDomain] = useState('');
  const [protocol, setProtocol] = useState('');
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getHost = async () => {
      const environmentDomain = await getEnvironmentDomain();
      const environmentProtocol = await getEnvironmentProtocol();
      setDomain(environmentDomain);
      setProtocol(environmentProtocol);
      const domains = environmentDomain.split('.');

      if (environmentDomain.includes('localhost') || domains.length === 1) {
        const [main] = domains.slice(-1);
        return setMainDomain(main);
      }
      const [top, second] = domains.reverse();
      setMainDomain([second, top].join('.'));
    };

    getHost().finally(() => setLoaded(true));
  }, []);

  return {domain, protocol, mainDomain, loaded};
};

export default useHost;
