import {createSelector} from '@reduxjs/toolkit';
import type {Store} from 'store';

const x = (store: Store) => store.onboarding;

export const getOrganizationName = createSelector(
  x,
  store => store.organizationName,
);
export const getSubdomain = createSelector(x, store => store.subdomain);
export const getSubscribed = createSelector(x, store => store.subscribed);
export const getCoWorkerEmails = createSelector(
  x,
  store => store.coWorkerEmails,
);
export const getStartOnboardingEmail = createSelector(
  x,
  store => store.startOnboardingEmail,
);
export const getIsOnbooardingFinished = createSelector(
  x,
  store => store.finished,
);
export const getIntentClientSecret = createSelector(
  x,
  store => store.intentClientSecret,
);
export const getCreatedOrganizationId = createSelector(
  x,
  store => store.createdOrganizationId,
);
export const getPlanName = createSelector(x, store => store.planName);
export const getUserData = createSelector(x, store => store.user);
export const getError = createSelector(x, store => store.error);
export const getPublicKey = createSelector(x, store => store.publicKey);
