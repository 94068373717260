import {useCallback, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useControls, useTransformComponent} from 'react-zoom-pan-pinch';
import {getClosestZoom} from '../../hooks/useZoom';
import ControlBar from '../ControlBar';
import ControlsMiddle from './controls/ControlsMiddle';
import ControlsRight from './controls/ControlsRight';

export type ImageControlsProps = {
  setContentFit: (fit: 'horiz' | 'vert' | null) => void;
  toggleFullscreen: () => void;
  isFullscreen: boolean;
  isThumbnail: boolean;
  metrics: {
    frame: [number, number];
    image: [number, number];
    scale: number;
  };
};

const ImageControls = (props: ImageControlsProps) => {
  const {metrics, isFullscreen, isThumbnail, ...actions} = props;
  const {frame, image, scale} = metrics;
  const [prevZoom, setPrevZoom] = useState<number | null>(null);
  const {setContentFit, toggleFullscreen} = actions;
  const {setTransform} = useControls();
  const transform = useCallback(
    (scale: number, time: number): [number, number, number, number] => [
      (frame[0] - image[0] * scale) / 2,
      (frame[1] - image[1] * scale) / 2,
      scale,
      time,
    ],
    [frame, image],
  );

  const zoom = useTransformComponent(({state}) => state.scale);

  const zoomIn = useCallback(
    () => setTransform(...transform(getClosestZoom(zoom, 'in'), 150)),
    [transform, zoom],
  );

  const zoomOut = useCallback(
    () => setTransform(...transform(getClosestZoom(zoom, 'out'), 150)),
    [transform, zoom],
  );

  // Reset zoom when scale changes
  useEffect(() => setTransform(...transform(scale, 0)), [scale]);

  return !isFullscreen && !isThumbnail ? (
    <View style={styles.root}>
      <ControlBar
        left={<View />}
        middle={
          <ControlsMiddle
            zoom={zoom}
            handleZoomIn={zoomIn}
            handleZoomOut={zoomOut}
            handleLastZoom={() => {
              setContentFit(null);
              // Go back to previous zoom if we had one
              if (prevZoom) {
                setTransform(...transform(prevZoom, 0));
                setPrevZoom(null);
                // Go to base scale
              } else {
                setPrevZoom(zoom);
                setTransform(...transform(scale, 0));
              }
            }}
          />
        }
        right={
          <ControlsRight
            handleExpandHorizontal={() => setContentFit('horiz')}
            handleExpandVertical={() => setContentFit('vert')}
            toggleFullscreen={toggleFullscreen}
            isFullscreen={isFullscreen}
          />
        }
      />
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export default ImageControls;
