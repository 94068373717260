import {useElements, useStripe} from '@stripe/react-stripe-js';
import type {SetupIntentResult} from '@stripe/stripe-js';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import app from 'store/slices/app';

const usePayment = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const handlePayment = async (): Promise<SetupIntentResult> => {
    if (!stripe || !elements) return;

    setLoading(true);
    dispatch(app.actions.stopRedirect());
    const stripeRequest = stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    let response = await stripeRequest;

    if (response.error && response.error.setup_intent?.status !== 'succeeded') {
      response = await stripeRequest;
    }

    setLoading(false);
    return response;
  };

  return {loading, handlePayment};
};

export default usePayment;
