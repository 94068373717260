import {useRef} from 'react';
import type {EntityType} from 'store/slices/activity/types';
import EntityCommunicationManager from '../EntityActivityManager';

const useEntityCommunication = () => {
  const entityCommunicationManager = useRef(
    EntityCommunicationManager.getInstance(),
  );

  const endActivityForUnlistedEntities = (
    entityType: EntityType,
    listedEntityIds: string[],
  ) => {
    entityCommunicationManager.current.endActivityHandlersForUnlistedEntities(
      entityType,
      listedEntityIds,
    );
  };

  const initializeActivityForEntity = ({
    entityType,
    entityId,
  }: {entityType: EntityType; entityId: string}) => {
    entityCommunicationManager.current.initializeActivityHandler({
      entityId,
      entityType,
    });

    endActivityForUnlistedEntities(entityType, [entityId]);

    return () => {
      endActivityForUnlistedEntities(entityType, []);
    };
  };

  return {initializeActivityForEntity};
};

export default useEntityCommunication;
