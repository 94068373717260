import theme from 'config/theme';
import React from 'react';
import {Pressable, StyleSheet, Text} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useHover} from './hooks/useHover';

type Props = {
  date: Date;
  onPress: () => void;
  isActive: boolean;
};

const getFormattedDate = (date: Date) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date);
};

const DatePicker = ({date, onPress, isActive}: Props) => {
  const {isHover, onHoverIn, onHoverOut} = useHover();

  return (
    <Pressable
      style={[styles.root, (isHover || isActive) && styles.rootHover]}
      onPress={onPress}
      // @ts-ignore
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}>
      <Text style={[styles.text, isHover && styles.textHover]}>
        {getFormattedDate(date)}
      </Text>
      <Icon
        name="chevron-right"
        size={20}
        color={isHover ? theme.colors.neutral.$2Base : theme.colors.neutral.$5}
      />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  root: {
    cursor: 'pointer',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: theme.colors.neutral.$9,
    paddingVertical: 6,
    paddingHorizontal: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.neutral.$white,
    maxWidth: 160,
  },
  rootHover: {
    backgroundColor: theme.colors.neutral.$13,
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$4,
  },
  textHover: {
    color: theme.colors.neutral.$2Base,
  },
});

export default DatePicker;
