import {useState} from 'react';

export function useHover(initialState?: boolean) {
  const [isHover, setIsHover] = useState(initialState);

  const onHoverIn = () => setIsHover(true);
  const onHoverOut = () => setIsHover(false);

  return {isHover, onHoverIn, onHoverOut};
}
