import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';

interface Props {
  title: string;
  value: string;
}

export function InfoPanelDetailItem({title, value}: Props) {
  return (
    <View style={styles.root}>
      <Typography size="xs" color={theme.colors.neutral.$500}>
        {title}
      </Typography>
      <View style={styles.spacing} />
      <Typography size="xs" color={theme.colors.neutral.$700}>
        {value}
      </Typography>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: 8,
    gap: 8,
    flexWrap: 'wrap',
    backgroundColor: theme.colors.neutral.$0,
    borderRadius: 6,
  },
  spacing: {
    flex: 1,
  },
});
