import {api} from 'fast-sdk';
import {BaseCachingService} from './BaseCachingService';

const CACHE_DB_KEY = 'org-logo-v1';
const CACHE_PREFIX = 'logo-';

const fetchPromiseCache: Record<string, Promise<any>> = {};

export default class OrgLogoService extends BaseCachingService {
  constructor(config: {cacheDuration?: number} = {}) {
    super({
      cacheDuration: config.cacheDuration,
      cacheDbKey: CACHE_DB_KEY,
      cachePrefix: CACHE_PREFIX,
    });
  }

  async getLogo(orgId: string) {
    if (!fetchPromiseCache[orgId]) {
      const promise = api.organization.getAsset(orgId, 'logo');
      fetchPromiseCache[orgId] = promise;
      const response = await promise;
      delete fetchPromiseCache[orgId];
      return response;
    }

    return await fetchPromiseCache[orgId];
  }

  async getContent(orgId: string): Promise<string> {
    try {
      const cachedContent = await this.getCachedContent(orgId);
      if (cachedContent !== undefined) {
        return cachedContent;
      }

      const logoContent = await this.getLogo(orgId);

      if (logoContent instanceof Blob) {
        return this.handleBlobContent(orgId, logoContent);
      }

      await this.saveBase64ToCache(orgId, '');
      return '';
    } catch (error) {
      return '';
    }
  }
}
