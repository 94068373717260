import {ROUTES} from 'constants/routes';
import useHost from '../useHost';

export const routesGroups = {
  loggedInRoutes: Object.values(ROUTES.LOGGED_IN),
  loggedInWithOrgRoutes: Object.values(ROUTES.LOGGED_IN_WITH_ORG),
  loggedInWithoutOrgRoutes: Object.values(ROUTES.LOGGED_IN_WITHOUT_ORG),
  loggedInWithSubdomainRoutes: Object.values(ROUTES.LOGGED_IN_WITH_SUBDOMAIN),
  notLoggedInRoutes: Object.values(ROUTES.NOT_LOGGED_IN),
  loggedOrNotLoggedRoutes: Object.values(ROUTES.LOGGED_OR_NOT_LOGGED),
};

// This hook manages the complex navigation logic for the application
// It determines where users should be redirected based on their authentication state and current location
export const useNavigatorRedirection = () => {
  const {
    loggedInRoutes,
    loggedInWithOrgRoutes,
    loggedInWithoutOrgRoutes,
    loggedInWithSubdomainRoutes,
    notLoggedInRoutes,
  } = routesGroups;

  const {domain, protocol, loaded} = useHost();

  // Unauthenticated users should be redirected to sign in
  const redirectToSignin = path => {
    if (
      !path ||
      loggedInRoutes.includes(path) ||
      loggedInWithOrgRoutes.includes(path) ||
      loggedInWithoutOrgRoutes.includes(path)
    ) {
      return ROUTES.NOT_LOGGED_IN.SIGNIN;
    }
  };

  // Users with unverified emails should be redirected to email verification
  const redirectToEmailVerification = path => {
    const allowedPaths = [
      ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION,
      ROUTES.LOGGED_IN_WITHOUT_ORG.VERIFY_EMAIL,
      'account',
    ];
    if (!allowedPaths.includes(path)) {
      return ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION;
    }
  };

  // Authenticated users with a subdomain should be redirected to the main app
  const redirectToMainApp = (path, subdomain, withRedirections) => {
    if (
      !path ||
      notLoggedInRoutes.includes(path) ||
      loggedInWithoutOrgRoutes.includes(path)
    ) {
      return withRedirections
        ? `${ROUTES.LOGGED_IN_WITHOUT_ORG.TO_SUBDOMAIN}?orgDomain=${subdomain}`
        : `${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/root`;
    }
  };

  // Authenticated users without an organization should be redirected to organization selection
  const redirectToSelectOrganization = path => {
    const includedPaths = [
      ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING,
      ROUTES.LOGGED_IN_WITHOUT_ORG.VERIFY_EMAIL,
      ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION,
    ];
    const excludedPaths = [
      ...loggedInWithoutOrgRoutes,
      ROUTES.NOT_LOGGED_IN.LOGOUT,
      ROUTES.LOGGED_OR_NOT_LOGGED.JOIN,
    ];

    // Check if the current path is not a path allowed to be access
    //without org also adding check for logout route
    if (!path || !excludedPaths.includes(path) || includedPaths.includes(path))
      return ROUTES.LOGGED_IN_WITHOUT_ORG.HOME;
  };

  // New users should be redirected to onboarding
  const redirectToOnboarding = (path: string) => {
    const isNotOnboardingPath =
      path !== ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING;
    const isNotLoggedInOrEmailVerification =
      notLoggedInRoutes.includes(path) ||
      path === ROUTES.LOGGED_IN_WITHOUT_ORG.EMAIL_VERIFICATION;

    if (isNotOnboardingPath && isNotLoggedInOrEmailVerification) {
      return ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING;
    }
  };

  const redirectToGoSubdomain = (path: string) => {
    if (domain && loaded) {
      const [subdomain] = domain.split('.');

      if (
        subdomain !== 'go' &&
        (path.includes('signin') ||
          path.includes('signup') ||
          path.includes('logout'))
      ) {
        const url = `${protocol}://${domain}/${path}`;
        window.location.href = url.replace(subdomain, 'go');
      }
    }
  };

  return {
    redirectToSignin,
    redirectToEmailVerification,
    redirectToMainApp,
    redirectToSelectOrganization,
    redirectToOnboarding,
    redirectToGoSubdomain,
    routesGroups,
  };
};
