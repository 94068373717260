import theme from 'config/theme';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import MediaComponent from 'interface/stacks/content-viewer/components/MediaComponent';
import {useMemo} from 'react';
import {StyleSheet, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';
import {FileType, getFileTypeFromMime} from 'utils/fast/files';
import FileThumbnail from './FileThumbnail';

interface PreviewFileThumbnailProps {
  item: FilesItem;
}

export const THUMBNAIL_HEIGHT = 262;

export default function PreviewFileThumbnail({
  item,
}: PreviewFileThumbnailProps) {
  const fileCategory = useMemo(() => getFileTypeFromMime(item), [item]);

  const renderPreviewFileThumbnail = useMemo(() => {
    switch (fileCategory) {
      case FileType.Image:
      case FileType.Video:
      case FileType.Audio:
        return <MediaComponent file={item} isThumbnail />;
      /*
          Here we can add all preview thumbnails components depending 
          on the file type like, audio, video, pdf, etc.
      */
      default:
        return <FileThumbnail item={item} size={FileThumbnailSizes.Preview} />;
    }
  }, [item]);

  return <View style={styles.container}>{renderPreviewFileThumbnail}</View>;
}

export const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100%',
    height: THUMBNAIL_HEIGHT,
    backgroundColor: theme.colors.neutral.$15,
  },
});
