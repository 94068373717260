import {t} from '@lingui/macro';
import analytics from 'extensions/analytics';
import {useModal} from 'extensions/viewport/useModal';
import {api} from 'fast-sdk';
import {Button} from 'interface/common/Button';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {PageTitle} from 'interface/stacks/settings/base/PageTitle';
import {useCallback, useEffect, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import * as user from 'store/slices/user';

import {InviteMembersModal} from './InviteMembersModal';
import {MembersTable} from './MembersTable';

import type {Invite, Member} from 'store/slices/settings/types';

export function Members() {
  const {subdomain} = useSubDomain();
  const organization = useSelector(user.selectors.getSelectedOrganization);

  const [members, setMembers] = useState<Array<Member>>();
  const [invites, setInvites] = useState<Array<Invite>>();
  const [loading, setLoading] = useState<boolean>(false);

  const {open} = useModal();

  const getMembers = useCallback(async () => {
    setLoading(true);
    try {
      const orgMembers: Array<Member & {id: string}> = [];
      const response = await api.organization.getOrganizationMembers(subdomain);
      if (response.result) {
        for (const member of response.users) {
          const authStatus =
            member['2factor'] === true
              ? 'Two-Factor (2FA)'
              : member['2factor'] === false
                ? 'Without Two-Factor'
                : '';
          orgMembers.push({
            permissions: member.permissions,
            authentication: authStatus,
            dateJoined: member.created ? new Date(member.created) : null,
            email: member.email_address,
            firstName: member.first_name,
            lastName: member.last_name,
            profilePic: member.profile_pic,
            id: member.id,
          });
        }
      }

      setMembers(orgMembers);
    } catch (err) {
      analytics.notify(err);
    }
    setLoading(false);
  }, [subdomain]);

  const getInvites = useCallback(async () => {
    try {
      const res: Array<Invite & {id: string}> = [];
      const result =
        await api.organization.getOrganizationInvitations(subdomain);
      if (result.result) {
        for (const member of result.invitations) {
          res.push({
            id: member.id,
            email: member.invitee_email,
            inviter: member.inviter,
            state: member.state,
            created: member.created,
          });
        }
      }
      setInvites(res);
    } catch (err) {
      analytics.notify(err);
    }
  }, [subdomain]);

  const onUpdate = useCallback(() => {
    Promise.all([getMembers(), getInvites()]);
  }, [getMembers, getInvites]);

  const openInviteModal = useCallback(
    () =>
      open(
        <InviteMembersModal
          organization={organization}
          onSubmit={() => setTimeout(() => getInvites(), 3000)}
        />,
      ),
    [open, organization.name, getInvites],
  );

  useEffect(() => {
    if (subdomain) {
      getMembers();
      getInvites();
    }
  }, [subdomain, getMembers, getInvites]);

  return (
    <ScrollView contentContainerStyle={styles.root}>
      <View style={styles.titleContainer}>
        <PageTitle text={t`Members`} customClass={{marginBottom: 0}} />
        <Button
          type="Primary"
          label={t`Invite People`}
          onPress={openInviteModal}
          customRootStyle={{height: 36}}
        />
      </View>
      <MembersTable
        members={members}
        invites={invites}
        loading={loading}
        onUpdate={onUpdate}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  root: {
    paddingVertical: 45,
    paddingHorizontal: 50,
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 28,
  },
});
