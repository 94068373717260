import theme from 'config/theme';
import {FileThumbnailSizes} from 'fast-sdk/src/api/storage/consts';
import {Mouse} from 'interface/common/Mouse';
import {useFileListItem} from 'interface/stacks/workspace/storage/hooks/useFileListItem';
import {memo, useCallback, useMemo, useRef} from 'react';
import isEqual from 'react-fast-compare';
import {Pressable, StyleSheet, View, useColorScheme} from 'react-native';
import {Path, Svg} from 'react-native-svg';
import {isTouch} from 'utils/common/platform';
import {FileListItemCell} from './FileListItemCell';
import {FileListItemRow} from './FileListItemRow';
import FileThumbnail from './thumbnail/FileThumbnail';

import type {ShareItem} from 'fast-sdk/src/api/share/consts';
import type {MultiplayerMember} from 'interface/multiplayer/types';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';

export interface FileListItemProps {
  item: FilesItem;
  layout: FileListItemLayout;
  workspaceId: string;
  members: MultiplayerMember[];
  focused?: boolean;
  selected?: boolean;
  dragging?: boolean;
  isPremium?: boolean;
  linkParent?: boolean;
  multiSelect?: boolean;
  disableDetails?: boolean;
  disableActions?: boolean;
  isPrevSelected?: boolean;
  isNextSelected?: boolean;
  tooltipPos?: 'top' | 'bottom';
  recents?: boolean;
  shareds?: boolean;
  share?: Share;
  shareView?: boolean;
  archivedShares?: boolean;
  onOpen(item: FilesItem, share?: ShareItem): void;
  onFocus(item: FilesItem, openDetails?: boolean): void;
  onSelect(item: FilesItem): void;
}

export enum FileListItemLayout {
  ListNormal = 1,
  GridSimple = 2,
}

export const FileListItem = memo(
  (props: FileListItemProps) => {
    const ref = useRef<View>(null);
    const item = useFileListItem(ref, props);
    const scheme = useColorScheme();
    const classes = {
      root: [
        styles.root,
        item.isRow && styles.row,
        item.isCell && styles.cell,
        item.isUpload && styles.uploading,
        // props.focused && styles.focused,
        props.selected && !props.dragging && styles.selected,
        props.dragging && styles.dragging,
      ],
      outline: [
        styles.outline,
        item.isCell && styles.outlineCell,
        item.isRow &&
          props.isPrevSelected && {
            borderTopWidth: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
        item.isRow &&
          props.isNextSelected && {
            borderBottomWidth: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        {
          borderColor: item.isDropping
            ? theme.colors.brand.$4Base
            : props.selected || item.membersSelected?.length > 1
              ? theme.colors.neutral.$2Base
              : item.membersSelected?.pop()?.userColor,
        },
      ],
    };

    const renderThumbnail = useMemo(
      () => (
        <FileThumbnail
          item={item.fileNode}
          uploading={item.isUpload}
          options={{
            backgroundColor: item.isCell
              ? theme.colors.neutral.$15
              : props.selected && !props.dragging
                ? theme.colors.caution.$8
                : theme.colors.neutral.$white,
          }}
          size={
            item.isCell ? FileThumbnailSizes.Medium : FileThumbnailSizes.Small
          }
        />
      ),
      [
        item.fileNode?.id,
        props.selected,
        props.dragging,
        item.isCell,
        item.isUpload,
      ],
    );

    const renderMembers = useCallback(
      () => (
        <View style={styles.members}>
          {props.members
            ?.filter(m => m.currentItemId === item.fileNode?.id)
            ?.map(member => (
              <View
                key={member.clientId}
                style={[styles.dot, {backgroundColor: member.userColor}]}
              />
            ))}
        </View>
      ),
      [props.members],
    );

    return (
      <Pressable
        ref={ref}
        style={classes.root}
        android_ripple={{
          color:
            scheme === 'dark'
              ? 'rgba(255, 255, 255, 0.10)'
              : 'rgba(0, 0, 0, 0.10)',
        }}>
        <Mouse onDoubleClick={item.open} onContextMenu={item.menu}>
          {item.isRow ? (
            <FileListItemRow
              {...props}
              thumbnail={renderThumbnail}
              renderMembers={renderMembers}
              onSelectThumbnail={item.select}
              disableDetails={props.disableDetails}
              disableActions={props.disableActions}
              onTitlePress={!isTouch() ? item.open : undefined}
              onDropDownMenu={item.menu}
              menuAnchor={item.menuFiles.reference}
              focus={item.focus}
              rename={() => item.renameModal.open()}
              onPress={
                props.multiSelect || !isTouch() ? item.select : item.open
              }
              onLongPress={item.longPress}
              recents={props.recents}
              shareds={props.shareds}
              share={item.shareTarget}
              downloadItem={item.downloadItem}
              previewShare={item.previewShare}
              customizeShare={item.customizeShare}
              openShare={item.openShare}
              fileLoading={item.fileLoading}
            />
          ) : (
            <FileListItemCell
              {...props}
              thumbnail={renderThumbnail}
              focus={item.focus}
              onPress={
                props.multiSelect || !isTouch() ? item.select : item.open
              }
              onDropDownMenu={item.menu}
              menuAnchor={item.menuFiles.reference}
              fileLoading={item.fileLoading}
            />
          )}
        </Mouse>
        {item.hasOutline && (
          <>
            <View
              style={[styles.checks, item.isCell && styles.checksCell]}
              pointerEvents="none">
              {props.members
                ?.filter(m => m.selection?.includes(item.fileNode?.id))
                ?.map(member => (
                  <View
                    key={member.clientId}
                    style={[
                      styles.checkBox,
                      {backgroundColor: member.userColor},
                    ]}>
                    <View style={styles.checkIcon}>
                      <Svg viewBox="0 0 16 16" width="16" height="16">
                        <Path
                          fill="#ffffff"
                          d="M 6.210000038146973 15.539999961853027 L 0 9.329999923706055 L 2.8299999237060547 6.5 L 6.210000038146973 9.890000343322754 L 16.09000015258789 0 L 18.919998168945312 2.8299999237060547 L 6.210000038146973 15.539999961853027 Z"
                        />
                      </Svg>
                    </View>
                  </View>
                ))}
            </View>
            <View pointerEvents="none" style={classes.outline} />
          </>
        )}
      </Pressable>
    );
  },
  (prev, next) => isEqual(prev, next),
);

const styles = StyleSheet.create({
  // Global
  root: {
    flex: 1,
    position: 'relative',
  },
  outline: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderWidth: 2,
    borderRadius: 3,
    overflow: 'visible',
  },
  outlineCell: {
    borderWidth: 2,
    borderRadius: 8,
  },
  icons: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    minWidth: 42,
    height: 42,
    flexDirection: 'row',
    alignItems: 'center',
  },
  large: {
    width: 84,
    height: 74,
  },
  // Layouts
  row: {
    flexDirection: 'row',
  },
  cell: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    margin: 3,
  },
  // States
  focused: {
    backgroundColor: theme.colors.neutral.$15,
  },
  selected: {
    backgroundColor: theme.colors.caution.$8,
  },
  dragging: {
    opacity: 0.6,
  },
  uploading: {
    /* @ts-ignore Web Only */
    pointerEvents: 'none',
  },
  // Awareness
  members: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
  },
  dot: {
    display: 'flex',
    flexDirection: 'column',
    width: 7,
    height: 7,
    borderRadius: 99999,
  },
  checks: {
    position: 'absolute',
    flexDirection: 'row',
    top: 2,
    left: 2,
  },
  checksCell: {
    left: 12,
    top: 14,
    borderRadius: 3,
    overflow: 'hidden',
  },
  checkBox: {
    width: 16,
    height: 16,
    backgroundColor: theme.colors.neutral.$black,
  },
  checkIcon: {
    width: 16,
    height: 16,
    padding: 4,
    alignItems: 'center',
    marginLeft: -1,
  },
});
