import {useParams} from 'extensions/navigation';
import {Layout as AuthLayout} from 'interface/stacks/auth/Layout';
import {useEffect, useState} from 'react';
import {LoginComponent} from '../auth/layout/LoginComponent';
import {Email} from './Email';
import {Password} from './Password';
import {Layout, type Type} from './layout/Layout';

type Status = 'Email' | 'Password' | undefined;

interface Props {
  type: Type;
}

export function Login(props: Props) {
  // This is the id of the file, or share, or workspace, or the organization
  // that the user wants to access
  const {id} = useParams<'id'>();
  const [status, setStatus] = useState<Status>();
  const [email, setEmail] = useState<string>();

  const LoginOptions = () => {
    return (
      <AuthLayout
        title="Sign in to access"
        subTitle={`Sign in to access to this ${props.type.toLowerCase()}. Select an option to sign in, or sign up for an account.`}
        linkDescription="Need an account?"
        linkText="Sign Up"
        linkTo="/signup"
        buttonsMarginTop={21}>
        <LoginComponent
          signInOrSignUp="SignIn"
          customEmailOnPress={() => setStatus('Email')}
        />
      </AuthLayout>
    );
  };

  useEffect(() => {
    if (email) {
      setStatus('Password');
    }
  }, [email]);

  const renderResult = (status: Status) => {
    switch (status) {
      case 'Email':
        return <Email setEmail={setEmail} />;
      case 'Password':
        return (
          <Password
            email={email}
            profilePic=""
            useDifferentAccount={() => setStatus(undefined)}
            type={props.type}
            id={id}
          />
        );

      default:
        return LoginOptions();
    }
  };

  return <Layout type={props.type}>{renderResult(status)}</Layout>;
}
