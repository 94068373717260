import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';

interface Props {
  title: string;
  onBackPress: () => void;
  onNewChatPress: () => void;
}

export function AiChatHeader({title, onBackPress, onNewChatPress}: Props) {
  const handleCopyLinkPress = () => {
    // TODO: Implement copy to clipboard
  };

  return (
    <View style={styles.container}>
      <Button
        onPress={onBackPress}
        variant="secondary"
        startEnhancer={
          <Icon name="lucide:arrow-left" color={theme.colors.neutral.$700} />
        }
      />
      <Typography
        size="md"
        variant="medium"
        color={theme.colors.neutral.$700}
        overrides={styles.title}>
        {title}
      </Typography>
      <View style={styles.spacer} />
      <Button
        onPress={onNewChatPress}
        variant="text"
        startEnhancer={
          <Icon name="lucide:plus" color={theme.colors.neutral.$700} />
        }
      />
      <Button
        onPress={handleCopyLinkPress}
        variant="text"
        startEnhancer={
          <Icon name="lucide:link-2" color={theme.colors.neutral.$700} />
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    padding: 16,
    borderBottomColor: theme.colors.neutral.$75,
  },
  title: {
    flex: 1,
    marginHorizontal: 8,
  },
  spacer: {
    flex: 1,
  },
});
