import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {WORKSPACE_TRASH} from 'constants/routes';
import CircleButton from 'interface/common/CircleButton';
import {FileListItemLayout} from 'interface/stacks/workspace/storage/FileListItem';
import {FolderLayoutSwitchButton} from 'interface/stacks/workspace/storage/FolderLayoutSwitchButton';
import {useAddNewNode} from 'interface/stacks/workspace/storage/hooks/useAddNewNode';
import {StyleSheet, View} from 'react-native';
import {useDispatch} from 'react-redux';
import {useOutletContext} from 'react-router';
import shared from 'store/slices/shared';

import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';
import type {FilesData} from '../../hooks/useFetchShareFiles';

interface FilesHeaderProps {
  share: Share;
  filesData: FilesData;
  selectedFolder: FilesItem;
  folderKey: string;
  onGoBack: () => void;
  onTrashOpen: () => void;
  onTrashEmpty: () => void;
  canManageFiles: boolean;
}

interface HeaderTitleProps {
  selectedFolder: FilesItem | null;
  isTrashView: boolean;
  onGoBack?: () => void;
}

interface HeaderActionsProps {
  share: Share;
  filesData: FilesData;
  addNewNodeButtonRef: React.RefObject<any>;
  openAddNewNodePopup: () => void;
  onFilterChange: () => void;
  onLayoutChange: () => void;
  onTrashOpen: () => void;
  onTrashEmpty: () => void;
  canManageFiles: boolean;
  isTrashView: boolean;
}

const HeaderTitle = ({
  selectedFolder,
  isTrashView,
  onGoBack,
}: HeaderTitleProps) => {
  return (
    <View style={styles.headerLeft}>
      {(selectedFolder || isTrashView) && (
        <Button
          onPress={onGoBack}
          variant="secondary"
          startEnhancer={
            <Icon
              name="lucide:arrow-left"
              color={theme.colors.neutral.$black}
            />
          }
        />
      )}
      <Typography color={theme.colors.neutral.$2Base} variant="semi-bold">
        <Trans>
          {isTrashView
            ? 'Trash'
            : selectedFolder
              ? selectedFolder.name
              : 'Files'}
        </Trans>
      </Typography>
    </View>
  );
};

const HeaderActions = ({
  share,
  addNewNodeButtonRef,
  openAddNewNodePopup,
  onFilterChange,
  onLayoutChange,
  onTrashOpen,
  onTrashEmpty,
  canManageFiles,
  isTrashView,
}: HeaderActionsProps) => {
  const layout = share.currentLayout ?? FileListItemLayout.ListNormal;
  const isOrderSortAsc = share.sort?.order === 'asc';

  return (
    <View style={styles.headerOptions}>
      {canManageFiles && !isTrashView && (
        <Button
          ref={addNewNodeButtonRef}
          onPress={openAddNewNodePopup}
          variant="secondary"
          startEnhancer={
            <Icon name="lucide:plus" color={theme.colors.neutral.$black} />
          }>
          <Trans>New</Trans>
        </Button>
      )}
      {canManageFiles && isTrashView && (
        <CircleButton
          ref={addNewNodeButtonRef}
          type="Danger"
          iconName="delete"
          onPress={onTrashEmpty}
          text="Empty Trash"
        />
      )}
      {!isTrashView && (
        <Button
          aria-label="Sort"
          variant="text"
          onPress={onFilterChange}
          endEnhancer={
            <Icon
              name={
                isOrderSortAsc
                  ? 'lucide:arrow-down-wide-narrow'
                  : 'lucide:arrow-up-wide-narrow'
              }
              color={theme.colors.neutral.$black}
            />
          }
        />
      )}
      {!isTrashView && (
        <FolderLayoutSwitchButton
          layout={layout}
          onLayoutChange={onLayoutChange}
        />
      )}
    </View>
  );
};

export function FilesHeader({
  share,
  filesData,
  selectedFolder,
  folderKey,
  onGoBack,
  onTrashOpen,
  onTrashEmpty,
  canManageFiles,
}: FilesHeaderProps) {
  const dispatch = useDispatch();
  const [, uploader] = useOutletContext<[unknown, UploaderState]>();

  const {addNewNodeButtonRef, openAddNewNodePopup} = useAddNewNode({
    instanceId: share.id,
    instanceAlt: share.custom_name,
    instanceNs: 'share',
    folderKey,
    uploader,
  });

  const handleFilterChange = () => {
    const newOrder = share.sort?.order === 'asc' ? 'desc' : 'asc';
    dispatch(
      shared.actions.setShareSort({
        share,
        sort: {
          order: newOrder,
          category: 'date',
        },
      }),
    );
  };

  const handleLayoutChange = () => {
    const newLayout =
      share.currentLayout === FileListItemLayout.ListNormal
        ? FileListItemLayout.GridSimple
        : FileListItemLayout.ListNormal;
    dispatch(shared.actions.setShareLayout({share, layout: newLayout}));
  };

  const isGridLayoutWithContent =
    share.currentLayout === FileListItemLayout.GridSimple &&
    Boolean(filesData.filesIds.at(0));

  const isTrashView = folderKey === WORKSPACE_TRASH;

  return (
    <View
      style={[styles.root, isGridLayoutWithContent && styles.gridWithContent]}>
      <HeaderTitle
        selectedFolder={selectedFolder}
        isTrashView={isTrashView}
        onGoBack={onGoBack}
      />
      <HeaderActions
        share={share}
        filesData={filesData}
        addNewNodeButtonRef={addNewNodeButtonRef}
        openAddNewNodePopup={openAddNewNodePopup}
        onFilterChange={handleFilterChange}
        onLayoutChange={handleLayoutChange}
        onTrashOpen={onTrashOpen}
        onTrashEmpty={onTrashEmpty}
        canManageFiles={canManageFiles}
        isTrashView={isTrashView}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
    borderColor: theme.colors.neutral.$12,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  gridWithContent: {
    marginBottom: 20,
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  headerOptions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
