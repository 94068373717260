import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/common/Button';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {BilledDurationType, type PlanType} from 'store/slices/settings/types';
import {CallBackType} from '..';

interface Props {
  plan: PlanType;
  duration: BilledDurationType;
  callBackType: CallBackType;
  callBack: () => void;
}

export function Success(props: Props) {
  const renderText = (
    planType: PlanType,
    callBackType: CallBackType,
    billedDuration: BilledDurationType,
  ) => {
    switch (callBackType) {
      case CallBackType.Upgrade:
        return {
          header: `Upgraded to ${planType}`,
          content: `Your plan has been upgraded to ${planType} with ${
            billedDuration === BilledDurationType.Yearly ? 'annual' : 'monthly'
          } recurring billing. A receipt has been sent to your email address.`,
        };
      case CallBackType.Downgrade:
        return {
          header: `Plan changed to ${planType}`,
          content: `Your plan has been downgraded to ${planType} with ${
            billedDuration === BilledDurationType.Yearly ? 'annual' : 'monthly'
          } recurring billing. A receipt has been sent to your email address.`,
        };
    }
  };

  return (
    <View style={styles.root}>
      <Icon
        name="check-circle-outline"
        size={84}
        color={theme.colors.success.$4Base}
        style={styles.icon}
      />
      <Text selectable style={styles.header}>
        <Trans>
          {renderText(props.plan, props.callBackType, props.duration).header}
        </Trans>
      </Text>
      <Text selectable style={styles.content}>
        <Trans>
          {renderText(props.plan, props.callBackType, props.duration).content}
        </Trans>
      </Text>
      <View style={styles.buttonContainer}>
        <Button
          type="Primary"
          label={t`Return to settings`}
          onPress={props.callBack}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
    borderRadius: 14,
    backgroundColor: theme.colors.neutral.$white,
    shadowColor: theme.colors.neutral.$black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3.05,
    elevation: 4,
    maxWidth: 650,
  },
  icon: {
    width: 84,
    height: 84,
    marginBottom: '1rem',
  },
  header: {
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 48,
    color: theme.colors.neutral.$2Base,
    marginBottom: '1.5rem',
    textAlign: 'center',
    width: '100%',
  },
  content: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
    marginBottom: '3rem',
    textAlign: 'center',
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});
