import {useDispatch} from 'react-redux';
import files from 'store/slices/files';
import {getFileDetails} from 'utils/fast/storage';

import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';

export const useGetFileDetails = () => {
  const dispatch = useDispatch();

  const fetchFileDetails = async (
    fileId: string,
    instanceId: string,
    instanceNs: StorageNamespace,
  ) => {
    const {result, node} = await getFileDetails(fileId, instanceId, instanceNs);
    if (result) {
      dispatch(
        files.actions.updateFile({
          file: node,
          instanceId,
          instanceNs,
        }),
      );
    }
  };

  return {
    fetchFileDetails,
  };
};
