import {Trans, t} from '@lingui/macro';
import theme from 'config/theme';
import {Button} from 'interface/common/Button';
import {PLANS_INFO} from 'interface/stacks/onboard/lib/consts';
import {ChangeUnit} from 'interface/stacks/onboard/payment/ChangeUnit';
import {Plan} from 'interface/stacks/onboard/payment/Plan';
import {useState} from 'react';
import {StyleSheet, Text, View, useWindowDimensions} from 'react-native';
import {useSelector} from 'react-redux';
import * as settings from 'store/slices/settings';
import {BilledDurationType, PlanType} from 'store/slices/settings/types';

import {calcDowngradeOrUpgrade} from '../lib/utils';

import useLegalNavigation from 'interface/stacks/app/hooks/useLegalNavigation';
import type {PlanMetaData} from 'interface/stacks/onboard/lib/types';
import type {SelectedPlan} from '..';

interface Props {
  goToAddCard: (newPlan: SelectedPlan) => void;
  goToDowngrade: (newPlan: SelectedPlan) => void;
}

export function SelectPlan(props: Props) {
  const screen = useWindowDimensions();
  const planInfo = useSelector(settings.selectors.getPlanInfo);
  const [unit, setUnit] = useState<BilledDurationType>(
    planInfo.billedDuration
      ? planInfo.billedDuration
      : BilledDurationType.Monthly,
  );
  const {navigateToTerms, navigateToPrivacy} = useLegalNavigation();

  const setSelectedPlan = (plan: PlanMetaData) => {
    const result = calcDowngradeOrUpgrade(
      plan.name,
      planInfo.type,
      unit,
      planInfo.billedDuration,
    );
    if (result === 'Downgrade' || result === 'Switch to Monthly') {
      props.goToDowngrade({
        type: plan.name,
        duration: unit,
      });
    }
    if (result === 'Upgrade' || result === 'Switch to Annual') {
      props.goToAddCard({
        type: plan.name,
        duration: unit,
      });
    }
  };

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <Text selectable style={styles.headerText}>
          <Trans>Select a plan that best fits your team.</Trans>
        </Text>
        <View style={styles.changeUnitContainer}>
          <ChangeUnit active={unit} setActive={setUnit} />
        </View>
      </View>
      <View
        style={[
          styles.plansContainer,
          {flexDirection: screen.width > 1100 ? 'row' : 'column'},
        ]}>
        {Object.entries(PLANS_INFO).map(([key, plan]) => (
          <Plan
            key={key}
            plan={plan}
            submitPlan={(plan: PlanMetaData) => setSelectedPlan(plan)}
            unit={unit}
            buttonText={
              plan.name === PlanType.Enterprise
                ? calcDowngradeOrUpgrade(
                    PlanType.Enterprise,
                    planInfo.type,
                    unit,
                    planInfo.billedDuration,
                  ) === 'Current Plan'
                  ? 'Current Plan'
                  : 'Contact Us'
                : calcDowngradeOrUpgrade(
                    plan.name,
                    planInfo.type,
                    unit,
                    planInfo.billedDuration,
                  )
            }
            buttonType={
              plan.name === PlanType.Enterprise
                ? 'Secondary'
                : calcDowngradeOrUpgrade(
                      PlanType.Startup,
                      planInfo.type,
                      unit,
                      planInfo.billedDuration,
                    ) === 'Current Plan'
                  ? 'Text'
                  : 'Primary'
            }
            buttonCustomStyle={
              calcDowngradeOrUpgrade(
                plan.name,
                planInfo.type,
                unit,
                planInfo.billedDuration,
              ) === 'Current Plan'
                ? styles.currentPlanButton
                : undefined
            }
            buttonCustomTextStyle={
              plan.name === PlanType.Enterprise
                ? {color: theme.colors.neutral.$4}
                : calcDowngradeOrUpgrade(
                      plan.name,
                      planInfo.type,
                      unit,
                      planInfo.billedDuration,
                    ) === 'Current Plan'
                  ? styles.currentPlanButtonText
                  : undefined
            }
            customWidth={280}
            customHeight={920}
            customPadding="2rem"
            selectedPlan={plan.name === planInfo.type}
          />
        ))}
      </View>
      <View style={styles.bottomContainer}>
        <Text
          selectable
          style={[styles.bottomText, {color: theme.colors.neutral.$2Base}]}>
          <Trans>
            You can upgrade, downgrade, or cancel your subscription anytime.
          </Trans>
        </Text>
        <View style={styles.bottomLinksContainer}>
          <Button
            type="Text"
            label={t`Terms`}
            onPress={navigateToTerms}
            customTextStyle={styles.buttonText}
          />
          <Button
            type="Text"
            label={t`Privacy Policy`}
            onPress={navigateToPrivacy}
            customTextStyle={styles.buttonText}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  headerText: {
    width: '100%',
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 48,
    textAlign: 'center',
    color: theme.colors.neutral.$2Base,
    marginBottom: '1rem',
  },
  changeUnitContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  plansContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 20,
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 7,
    marginVertical: 50,
  },
  bottomText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
  },
  bottomLinksContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  currentPlanButton: {
    backgroundColor: theme.colors.neutral.$13,
  },
  currentPlanButtonText: {
    color: theme.colors.neutral.$2Base,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
  },
});
