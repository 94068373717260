import type React from 'react';
import {useEffect, useRef} from 'react';
import {type StyleProp, View, type ViewStyle} from 'react-native';
import {findNodeHandle} from 'react-native-web';

interface KeyboardListenerProps {
  children: React.ReactNode;
  onKeyPress: (event: KeyboardEvent) => void;
  customStyle?: StyleProp<ViewStyle>;
}

export const KeyboardListener = ({
  children,
  customStyle,
  onKeyPress,
}: KeyboardListenerProps) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = event => {
      if (onKeyPress) {
        onKeyPress(event);
      }
    };

    const containerNode = findNodeHandle(containerRef.current);

    if (containerNode) {
      containerNode.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (containerNode) {
        containerNode.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [onKeyPress]);

  return (
    <View ref={containerRef} style={customStyle} focusable={true}>
      {children}
    </View>
  );
};
