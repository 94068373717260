import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as settings from 'store/slices/settings';
import {isSubdomainValid} from 'utils/common/platform';
import {useGetOrganizationLogo} from './useGetOrganizationLogo';
import {useSubDomain} from './useSubDomain';

export const useOrganizationLogo = () => {
  const {subdomain} = useSubDomain();

  const {fetchOrganizationLogo} = useGetOrganizationLogo();

  const currentLogo = useSelector(settings.selectors.getOrganizationLogo);

  useEffect(() => {
    if (isSubdomainValid(subdomain) && !currentLogo) {
      fetchOrganizationLogo(subdomain);
    }
  }, [subdomain]);
};
