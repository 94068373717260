import {useFlag} from '@unleash/proxy-client-react';
import {ROUTES, WORKSPACE_TRASH} from 'constants/routes';
import {useNavigate} from 'extensions/navigation';
import {StorageItemType} from 'fast-sdk/src/api/storage/consts';
import {useModalPurge} from 'interface/stacks/workspace/storage/hooks/useModalPurge';
import {StyleSheet, View} from 'react-native';
import {ShareViewStatus} from 'store/slices/shared/types';
import type {FilesData} from '../../hooks/useFetchShareFiles';
import {FileItems} from './FileItems';
import {FilesHeader} from './FilesHeader';

import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import app from 'store/slices/app';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';
import type {FilesItem} from 'store/slices/files/types';
import type {Share} from 'store/slices/shared/types';
import type {ShareFilesNavigation} from '../../hooks/useShareFilesNavigation';

interface Props {
  share: Share;
  shareFilesNavigation: ShareFilesNavigation;
  filesData: FilesData;
}

export default function ShareFiles({
  share,
  shareFilesNavigation,
  filesData,
}: Props) {
  const navigate = useNavigate();

  const {breadcrumbs, setBreadcrumbs, setTrashView, selectedFolder, folderKey} =
    shareFilesNavigation;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      app.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.ShareExternal,
        shareName: share.name,
        shareId: share.id,
        folderId: selectedFolder?.id,
        workspace: share.parent_workspace_folder,
      }),
    );
  }, [share.parent_workspace_folder, selectedFolder?.name]);

  const purgeTrashModal = useModalPurge(null, share.id, 'share');
  const isTrashView = folderKey === WORKSPACE_TRASH;

  const handleFileOpen = (file: FilesItem) => {
    const fileId = file.id;
    if (file.type === StorageItemType.Folder) {
      setBreadcrumbs(prev => [...prev, file]);
    } else if (file.type === StorageItemType.File) {
      navigate(
        `/share/${share.id}/${ROUTES.LOGGED_IN_WITH_ORG.PREVIEW}/${fileId}`,
      );
    }
  };

  const handleTrashOpen = () => {
    setTrashView(true);
  };

  const handleTrashEmpty = () => {
    purgeTrashModal.open();
  };

  const handleGoBack = () => {
    if (isTrashView) {
      setTrashView(false);
    } else {
      setBreadcrumbs(breadcrumbs.slice(0, -1));
    }
  };

  const canManageFiles =
    (share.filesystem.file_creation && useFlag('share-external-uploads')) ||
    share.shareViewStatus === ShareViewStatus.Owner ||
    share.shareViewStatus === ShareViewStatus.Editor;

  return (
    <View style={styles.filesContainer}>
      <FilesHeader
        share={share}
        filesData={filesData}
        selectedFolder={selectedFolder}
        folderKey={folderKey}
        onGoBack={handleGoBack}
        onTrashOpen={handleTrashOpen}
        onTrashEmpty={handleTrashEmpty}
        canManageFiles={canManageFiles}
      />
      <FileItems
        share={share}
        filesData={filesData}
        handleFileOpen={handleFileOpen}
        canManageFiles={canManageFiles}
        isTrashView={isTrashView}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  filesContainer: {
    flex: 1,
    width: '100%',
    flexDirection: 'column',
  },
});
