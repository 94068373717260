import {t} from '@lingui/macro';
import {useSearchParams} from 'extensions/navigation/hooks/useSearchParams';
import {api} from 'fast-sdk';
import {useState} from 'react';
import {useToast} from 'react-native-toast-notifications';

export enum PageStatus {
  ResetPassword = 'ResetPassword',
  Completed = 'Completed',
  Failed = 'Failed',
}

export default function usePasswordReset() {
  const [searchparams] = useSearchParams();
  const code = searchparams.get('code');

  const [loading, setLoading] = useState<boolean>(false);
  const [pageStatus, setPageStatus] = useState<PageStatus>(
    PageStatus.ResetPassword,
  );
  const [errorMessage, setErrorMessage] = useState<string>('');

  const toast = useToast();

  const handleResetPassword = async (
    password: string,
    confirmPassword: string,
  ) => {
    if (password !== confirmPassword) {
      toast.show(t`The confirm password is not the same as the new password`, {
        type: 'danger',
      });
      return;
    }
    if (!code) {
      toast.show(
        t`The link that you've reached is not valid please try opening the emailed link again`,
        {type: 'danger'},
      );
      return;
    }

    setLoading(true);
    const response = await api.user.setPassword({
      code,
      password1: password,
      password2: confirmPassword,
    });

    if (!response.result) {
      setErrorMessage(response.error?.text);
      setLoading(false);
      return setPageStatus(PageStatus.Failed);
    }

    setPageStatus(PageStatus.Completed);
    setLoading(false);
  };

  return {
    loading,
    pageStatus,
    errorMessage,
    handleResetPassword,
  };
}
