import theme from 'config/theme';
import {useParams} from 'extensions/navigation';
import {usePopup} from 'extensions/viewport/usePopup';
import {HoveredView} from 'interface/base/HoveredView';
import {ShareTypeBadge} from 'interface/base/ShareTypeBadge';
import Typography from 'interface/base/Typography';
import {useDragDropTarget} from 'interface/stacks/workspace/storage/hooks/useDragDropTarget';
import {useFileListItem} from 'interface/stacks/workspace/storage/hooks/useFileListItem';
import {useWorkspaceContext} from 'interface/stacks/workspace/storage/hooks/useWorkspaceContext';
import {type MouseEvent, useCallback, useRef} from 'react';
import {StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';

interface ShareItemProps {
  onPress: (item: Share) => void;
  item: Share;
}

export function ShareItem({onPress, item}: ShareItemProps) {
  const {sharedCustomName} = useParams<'sharedCustomName'>();
  const popup = usePopup();
  const menuRef = useRef<any>();
  const {uploader} = useWorkspaceContext();
  const {isDropping} = useDragDropTarget({
    ref: menuRef,
    targetNs: 'share',
    targetId: item.id,
    targetName: item.name,
    instanceNs: 'workspace',
    instanceId: item.parent_workspace,
    uploader,
  });

  const fileListItem = useFileListItem(undefined, {
    //@ts-expect-error
    item,
    shareds: true,
    workspaceId: item.parent_workspace_folder,
  });

  const handleRightClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (event?.nativeEvent) {
        popup.setPlacement('bottom-start');
        popup.setPosition({
          x: event.nativeEvent.pageX,
          y: event.nativeEvent.pageY,
        });
        fileListItem.menu(event, menuRef);
      }
    },
    [menuRef],
  );

  const isSelected = item.custom_name === sharedCustomName;

  return (
    <HoveredView onPress={() => !isSelected && onPress(item)}>
      <View
        //@ts-expect-error
        onContextMenu={handleRightClick}
        ref={menuRef}
        style={[styles.shareItem, isSelected && styles.selectedItem]}>
        <ShareTypeBadge shareType={item.share_type} />
        <Typography
          variant="regular"
          size="sm"
          color={theme.colors.neutral.$2Base}>
          {item.name}
        </Typography>
      </View>
      {isDropping && <View pointerEvents="none" style={styles.drop} />}
    </HoveredView>
  );
}

const styles = StyleSheet.create({
  shareItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 4,
    gap: 6,
  },
  selectedItem: {
    backgroundColor: theme.colors.neutral.$100,
  },
  hovered: {
    backgroundColor: theme.colors.neutral.$13,
  },
  drop: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderWidth: 2,
    borderRadius: 6,
    borderColor: theme.colors.brand.$4Base,
    overflow: 'visible',
  },
});
