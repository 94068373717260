import theme from 'config/theme';
import {Pressable, StyleSheet} from 'react-native';

import type {PressableProps, PressableStateCallbackType} from 'react-native';

export function UploadButton(props: PressableProps) {
  return (
    <Pressable
      style={({hovered}: PressableStateCallbackType) => [
        styles.root,
        hovered && styles.hovered,
      ]}
      {...props}
    />
  );
}

const styles = StyleSheet.create({
  root: {
    width: 32,
    height: 32,
    borderRadius: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  hovered: {
    backgroundColor: theme.colors.neutral.$25,
  },
});
