import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import {Avatar} from 'interface/common/Avatar';
import useHost from 'interface/common/hooks/useHost';
import {useHover} from 'interface/common/hooks/useHover';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {StyleSheet} from 'react-native';
import type {Organization} from 'store/slices/user/types';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';

const commonOverrides = {
  Button: {
    style: {
      width: 32,
      paddingVertical: 0,
      backgroundColor: theme.colors.neutral.$black,
    },
  },
};

interface HeaderOrgButtonProps {
  organization: Organization;
  orgLogo: string;
}

export function HeaderOrgButton({organization, orgLogo}: HeaderOrgButtonProps) {
  const {subdomain} = useSubDomain();
  const {domain, protocol} = useHost();

  const {isHover, onHoverIn, onHoverOut} = useHover();

  const onOrgPress = () => {
    const url = `${protocol}://${domain}/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`;
    window.location.href = url.replace(subdomain, 'go');
  };

  const orgInitials = createInitials(organization?.name);

  const orgColor =
    organization?.accent_color?.color || getHashColor(organization?.id);

  return (
    <Button
      overrides={commonOverrides}
      aria-label="Organization"
      variant="text"
      onPress={onOrgPress}
      onHoverInFn={onHoverIn}
      onHoverOutFn={onHoverOut}
      endEnhancer={
        isHover ? (
          <Icon
            name="lucide:arrow-left"
            color={theme.colors.neutral.$white}
            size={22}
          />
        ) : (
          <Avatar
            color={orgColor}
            initials={orgInitials}
            photo={orgLogo}
            hideBorder
            size={1}
            shapeKind="rounded-square"
            imageStyle={styles.avatar}
            innerStyle={styles.avatar}
          />
        )
      }
    />
  );
}

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 64,
    height: '100%',
    paddingBottom: 16,
    paddingHorizontal: 16,
    paddingTop: 8,
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 6,
  },
  top: {
    gap: 24,
  },
  bottom: {
    gap: 24,
  },
});
