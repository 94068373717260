import theme from 'config/theme';
import React, {memo, useEffect, useRef} from 'react';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {Page} from 'react-pdf';

type Props = {
  isActive: boolean;
  handleChangePage: (page: number) => void;
  currentPage: number;
};

const PDFPage = memo(
  ({isActive, handleChangePage, currentPage}: Props) => {
    const ref = useRef(null);

    useEffect(() => {
      if (ref.current && isActive) {
        ref.current.scrollIntoViewIfNeeded(false);
      }
    }, [ref.current, isActive]);

    return (
      <Pressable
        ref={ref}
        style={[styles.pageContainer, isActive && styles.pageContainerActive]}
        onPress={() => handleChangePage(currentPage)}>
        <View pointerEvents="none">
          <Page pageNumber={currentPage} width={150} />
          <Text
            style={[
              styles.pageNumber,
              isActive ? styles.pageNumberActive : styles.pageNumberInactive,
            ]}>
            {currentPage}
          </Text>
        </View>
      </Pressable>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.isActive === nextProps.isActive;
  },
);

const styles = StyleSheet.create({
  pageContainer: {
    marginTop: 15,
    borderRadius: 6,
    overflow: 'hidden',
  },
  pageContainerActive: {
    borderWidth: 1,
    borderColor: '#000',
    outlineWidth: 6,
    outlineColor: theme.colors.brand.$4Base,
    outlineStyle: 'solid',
  },
  pageWrapper: {
    position: 'relative',
  },
  pageNumber: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 24,
    width: 24,
    height: 24,
    color: theme.colors.neutral.$white,
    borderRadius: 50,
    textAlign: 'center',
  },
  pageNumberActive: {
    backgroundColor: theme.colors.brand.$4Base,
  },
  pageNumberInactive: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
});

export default PDFPage;
