import {ROUTES, STORAGE_ROOT, WORKSPACE_STORAGE} from 'constants/routes';
import {useModal} from 'extensions/viewport/useModal';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {useNavigatorRedirection} from 'interface/common/hooks/navigator/useNavigatorRedirection';
import {useOrgMembers} from 'interface/common/hooks/useOrgMembers';
import {SettingsType} from 'interface/stacks/settings/base/consts';
import useGenerateShareUrl from 'interface/stacks/share/hooks/useGenerateShareUrl';
import {WorkspaceCreate} from 'interface/stacks/workspace/dialogs/WorkspaceCreate';
import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as shared from 'store/slices/shared';
import type {Share} from 'store/slices/shared/types';
import type {Organization} from 'store/slices/user/types';
import * as workspace from 'store/slices/workspace';
import {getCurrentPath} from 'utils/common/platform';
import SharesTable from '../shares/SharesTable';
import WorkspaceCards from '../workspaces/WorkspaceCards';
import OrganizationHeader from './OrganizationHeader';

interface OrganizationSectionProps {
  organization: Organization;
  isSharesLoading: boolean;
  isWorkspaces?: boolean;
}

const OrganizationSection = ({
  organization,
  isSharesLoading,
  isWorkspaces = true,
}: OrganizationSectionProps) => {
  const modal = useModal();
  const navigate = useNavigate();

  const [isReloadingWorkspaces, setIsReloadingWorkspaces] = useState(false);

  const {redirectToMainApp} = useNavigatorRedirection();
  const {generateShareUrl} = useGenerateShareUrl();

  const {members, loading: isMembersLoading} = useOrgMembers(organization);

  const workspaces = useSelector(
    workspace.selectors.getOrganizationWorkspaces(organization.domain),
  );

  const shares = useSelector(
    shared.selectors.getOrganizationShares(organization?.id),
  );

  const redirectToSubdomain = (redirectTo: string) => {
    const path = getCurrentPath();
    const baseSubdomainPath = redirectToMainApp(
      path,
      organization.domain,
      true,
    );
    navigate(`/${baseSubdomainPath}&redirectTo=${redirectTo}`);
  };

  const onNewWorkspacePress = () => {
    modal.open(
      <WorkspaceCreate
        organization={organization}
        close={modal.close}
        setIsReloading={setIsReloadingWorkspaces}
      />,
    );
  };

  const onSettingsPress = () => {
    redirectToSubdomain(
      `/${ROUTES.LOGGED_IN_WITH_ORG.SETTINGS}/${SettingsType.organization}`,
    );
  };

  const onViewArchivedWorkspacesPress = () => {
    redirectToSubdomain(`/${ROUTES.LOGGED_IN_WITH_ORG.ARCHIVED_WORKSPACES}`);
  };

  const onWorkspacePress = (workspace: WorkspaceListDetail) => {
    redirectToSubdomain(
      `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${workspace.folder_name}/${WORKSPACE_STORAGE}/${STORAGE_ROOT}`,
    );
  };

  const onSharePress = (share: Share) => {
    redirectToSubdomain(generateShareUrl(share));
  };

  return (
    <View style={styles.root}>
      <OrganizationHeader
        organization={organization}
        workspacesCount={workspaces.length}
        sharesCount={shares.length}
        isWorkspaces={isWorkspaces}
        members={members}
        isMembersLoading={isMembersLoading}
        onNewWorkspacePress={onNewWorkspacePress}
        onSettingsPress={onSettingsPress}
        onViewArchivedWorkspacesPress={onViewArchivedWorkspacesPress}
      />
      {isWorkspaces ? (
        <WorkspaceCards
          organization={organization}
          workspaces={workspaces}
          members={members}
          isMembersLoading={isMembersLoading}
          onNewWorkspacePress={onNewWorkspacePress}
          onWorkspacePress={onWorkspacePress}
          isReloadingWorkspaces={isReloadingWorkspaces}
        />
      ) : (
        <SharesTable
          shares={shares}
          isSharesLoading={isSharesLoading}
          onSharePress={onSharePress}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {},
});

export default OrganizationSection;
