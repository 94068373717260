import theme from 'config/theme';
import {Avatar} from 'interface/common/Avatar';
import {StyleSheet, Text, View} from 'react-native';
import {getHashColor} from 'utils/common/color';

export interface ProfileMemberItemInviteProps {
  id: string;
  email: string;
}

export function ProfileMemberItemInvite(props: ProfileMemberItemInviteProps) {
  return (
    <View style={styles.root}>
      <View style={styles.avatar}>
        <Avatar
          color={getHashColor(props.id)}
          initials={props.email[0].toUpperCase()}
          photo={null}
          emptyFallback
        />
      </View>
      <View style={styles.info}>
        <Text style={styles.email}>{props.email}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 9,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  email: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 16,
    color: theme.colors.neutral.$2Base,
  },
});
