import type {GridColumn} from '@glideapps/glide-data-grid';
import {useEffect, useMemo, useState} from 'react';
import {read, utils} from 'xlsx';

type Row = Record<string, Array<any>>;
type Column = Record<string, Array<GridColumn>>;

const EMPTY_KEY = '__EMPTY';

const renderColumnTitle = (key: string) => {
  if (key.startsWith(EMPTY_KEY)) {
    return key.replace(EMPTY_KEY, '0').split('_')?.[1] || '0';
  }

  return key;
};

const useSpreadSheet = (url: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [activeSheet, setActiveSheet] = useState<string | null>(null);
  const [rowsBySheet, setRowsBySheet] = useState<Row | null>(null);
  const [columnsBySheet, setColumnsBySheet] = useState<Column | null>(null);

  useEffect(() => {
    async function getSpreadSheets() {
      setLoading(true);
      const file = await fetch(url);
      const arrayBuffer = await file.arrayBuffer();
      const workBook = read(arrayBuffer);

      setActiveSheet(workBook.SheetNames[0]);

      const rows: Row = {};
      const columns: Column = {};
      const sheets = Object.values(workBook.Sheets);
      for (let i = 0; i < Object.values(workBook.Sheets).length; i++) {
        const name = workBook.SheetNames[i];
        const sheet = sheets[i];
        const sheetJson: any[] = utils.sheet_to_json<any>(sheet, {
          defval: '',
          blankrows: true,
          header: 'A',
        });

        const firstRow = sheetJson[0];

        if (!firstRow) {
          break;
        }

        rows[name] = sheetJson;

        const dataColumns = Object.keys(firstRow);
        const sheetColumns: Array<GridColumn> = dataColumns.map(key => ({
          title: renderColumnTitle(key),
          id: key,
          width: 250,
        }));
        columns[name] = sheetColumns;
      }

      setRowsBySheet(rows);
      setColumnsBySheet(columns);
      setLoading(false);
    }

    if (url) {
      getSpreadSheets();
    }
  }, [url]);

  const rows = useMemo(
    () => (activeSheet ? rowsBySheet?.[activeSheet] : []),
    [activeSheet, rowsBySheet],
  );
  const columns = useMemo(
    () => (activeSheet ? columnsBySheet?.[activeSheet] : []),
    [activeSheet, columnsBySheet],
  );

  const sheetNames = useMemo(
    () => (rowsBySheet ? Object.keys(rowsBySheet) : []),
    [rowsBySheet],
  );

  const handleSheetChange = (name: string) => setActiveSheet(name);

  return {activeSheet, rows, columns, loading, sheetNames, handleSheetChange};
};

export default useSpreadSheet;
