import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {Pressable, Text} from 'react-native';
import {StyleSheet} from 'react-native';

interface Props {
  label: string;
  value: number;
  setValue: (inp: number) => void;
}

export function ExpirationItem(props: Props) {
  return (
    <Pressable style={styles.root} onPress={() => props.setValue(props.value)}>
      <Text style={styles.text}>
        <Trans>{props.label}</Trans>
      </Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  root: {
    width: 82,
    height: 82,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: theme.colors.neutral.$10,
    borderRadius: 150,
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$2Base,
  },
});
