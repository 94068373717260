import {api} from 'fast-sdk';
import {useState} from 'react';

export const useFetchChatList = () => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchChatList = async (workspaceName: string) => {
    setIsLoading(true);
    try {
      const response = await api.workspaceAichat.getChatList(workspaceName);
      if (response.result) {
        const chats = response.chats;
        // TODO: Remove when API fixes the data
        const fixedChats = chats.map(chat => ({
          ...chat,
          created_at: chat.created_at || new Date().toISOString(),
        }));
        return fixedChats;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, fetchChatList};
};
