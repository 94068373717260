import {Trans, t} from '@lingui/macro';
import {vectors} from 'config/styles';
import theme from 'config/theme';
import {Pressable, StyleSheet, Text, View} from 'react-native';
import {Path, Svg} from 'react-native-svg';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Button} from './Button';
import {IconButton} from './IconButton';

export enum ModalSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  AUTO = 'auto',
}

interface ModalProps {
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  close?: () => void;
  size?: ModalSize;
}

interface FooterActionsProps {
  actionButtonLabel: string;
  isFormInvalid: boolean;
  isActionLoading: boolean;
  onSave: () => void;
  onCancel: () => void;
}

interface ModalSectionProps {
  title: string;
  iconName?: string;
  onIconPress?: () => void;
  showDivider?: boolean;
}

export function FooterActions({
  actionButtonLabel,
  isFormInvalid,
  isActionLoading,
  onSave,
  onCancel,
}: FooterActionsProps) {
  return (
    <View style={styles.buttonsContainer}>
      <Button type="Secondary" label={t`Cancel`} onPress={onCancel} />
      <Button
        type="Primary"
        label={actionButtonLabel}
        disabled={isFormInvalid}
        onPress={onSave}
        loading={isActionLoading}
        customLoadingColor="white"
      />
    </View>
  );
}

export function ModalSection({
  title,
  iconName,
  onIconPress,
  showDivider = false,
}: ModalSectionProps) {
  return (
    <View style={[styles.sectionHeader, showDivider && styles.sectionDivider]}>
      <Text style={styles.sectionTitle}>{title}</Text>
      {iconName && onIconPress && (
        <IconButton
          buttonStyle={styles.sectionIcon}
          icon={
            <MCIcon name={iconName} size={20} color={theme.colors.neutral.$5} />
          }
          onPress={onIconPress}
        />
      )}
    </View>
  );
}

export default function Modal({
  title,
  children,
  footer,
  close,
  size = ModalSize.MEDIUM,
}: ModalProps) {
  return (
    <View style={styles.root}>
      <View style={[styles.innerContainer, styles[size]]}>
        {close && (
          <View style={styles.iconContainer}>
            <Pressable style={styles.icon} onPress={close}>
              <Svg viewBox="0 0 14 14" width={12} height={12}>
                <Path d={vectors.icons.close} fill={theme.colors.neutral.$5} />
              </Svg>
            </Pressable>
          </View>
        )}
        <Text style={styles.title}>
          <Trans>{title}</Trans>
        </Text>
        <View style={styles.body}>{children}</View>
        {footer}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  innerContainer: {
    borderRadius: 7,
    backgroundColor: 'white',
    padding: 24,
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 6px 12px 0px rgba(0, 0, 0, 0.07)',
  },
  iconContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  icon: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    marginTop: 16,
  },
  title: {
    lineHeight: 26,
    fontSize: 16.5,
    fontWeight: '600',
  },
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
    zIndex: -1,
    marginTop: 24,
  },
  sectionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    paddingBottom: 14,
  },
  sectionTitle: {
    lineHeight: 22,
    fontSize: 14,
    fontWeight: '600',
    color: theme.colors.neutral.$2Base,
  },
  sectionIcon: {
    minWidth: 20,
    minHeight: 20,
  },
  sectionDivider: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$12,
  },
  [ModalSize.SMALL]: {
    width: 420,
  },
  [ModalSize.MEDIUM]: {
    width: 550,
  },
  [ModalSize.LARGE]: {
    width: 800,
  },
  [ModalSize.AUTO]: {
    width: 'auto',
  },
});
