export const ORGANIZATION_MOCK = {
  id: '3927297806733761231',
  domain: 'mediafire',
  accent_color: null,
  closed: false,
  description: null,
  facebook: null,
  homepage: null,
  instagram: null,
  locked: false,
  logo: null,
  name: 'MediaFire',
  parent: '2777297806141331231',
  suspended: false,
  twitter: null,
  youtube: null,
  created: '2024-10-24 14:37:57',
  updated: '2024-10-24 14:37:57',
};

export const MEMBERS_MOCK = [
  {
    id: '2767297873993301231',
    '2factor': false,
    closed: false,
    country_code: 'US',
    created: '2024-10-24 16:30:13',
    email_address: 'gianfranco.montero@fast.io',
    first_name: 'gianfranco',
    last_name: 'galvez',
    locked: false,
    notify: 'Notify me in app',
    permissions: 'owner',
    phone_country: null,
    phone_number: null,
    profile_pic: null,
    suspended: false,
    tos_agree: true,
    updated: '2024-10-24 16:30:13',
    valid_email: true,
    valid_phone: false,
  },
];
