import {Trans} from '@lingui/macro';
import {useFlag} from '@unleash/proxy-client-react';
import theme from 'config/theme';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import * as app from 'store/slices/app';
import {SettingsGeneralMenuButtonItem as Item} from './SettingsGeneralMenuButtonItem';
import {
  ACCOUNT_SETTINGS_MENU_LINKS,
  ORGANIZATION_SETTINGS_MENU_LINKS,
  SettingsType,
} from './consts';

export function SettingsMenu() {
  const {settingsSection} = useParams<'settingsSection'>();

  const hasOrgSettingsAuth = useFlag('org-settings-authentication');
  const hasOrgSettingsEmailDomains = useFlag('org-settings-email-domains');
  const hasOrgSettingsManageMembers = useFlag('org-settings-manage-members');

  const isAdminOrOwner = useSelector(app.selectors.getUserIsAdminOrOwner);

  const checkFlags = (settings: SettingsType) => {
    switch (settings) {
      case SettingsType.authentication:
        return hasOrgSettingsAuth;
      case SettingsType['email-domains']:
        return hasOrgSettingsEmailDomains;
      case SettingsType.members:
        return hasOrgSettingsManageMembers;
      default:
        return true;
    }
  };

  return (
    <View style={styles.root}>
      <View>
        <View style={styles.buttonsContainer}>
          <View style={[styles.section, styles.borderBottom]}>
            <View style={styles.titleContainer}>
              <Typography
                variant="medium"
                size="sm"
                color={theme.colors.neutral.$700}>
                <Trans>Account</Trans>
              </Typography>
            </View>
            {ACCOUNT_SETTINGS_MENU_LINKS.map(linkItem =>
              checkFlags(linkItem.sectionName) ? (
                <Item
                  title={linkItem.title}
                  iconName={linkItem.iconName}
                  link={linkItem.link}
                  sectionName={linkItem.sectionName}
                  active={
                    SettingsType[settingsSection] === linkItem.sectionName
                  }
                  key={linkItem.link}
                />
              ) : null,
            )}
          </View>
          {isAdminOrOwner && (
            <View style={styles.section}>
              <View style={styles.titleContainer}>
                <Typography
                  variant="medium"
                  size="sm"
                  color={theme.colors.neutral.$700}>
                  <Trans>Organization</Trans>
                </Typography>
              </View>
              {ORGANIZATION_SETTINGS_MENU_LINKS.map(linkItem =>
                checkFlags(linkItem.sectionName) ? (
                  <Item
                    title={linkItem.title}
                    iconName={linkItem.iconName}
                    link={linkItem.link}
                    sectionName={linkItem.sectionName}
                    active={
                      SettingsType[settingsSection] === linkItem.sectionName
                    }
                    key={linkItem.link}
                  />
                ) : null,
              )}
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 278,
    borderRadius: 6,
    backgroundColor: theme.colors.neutral.$0,
    marginTop: 2,
    marginRight: 2,
  },
  workspaces: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    paddingVertical: 10,
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 15,
    paddingLeft: 16,
    paddingRight: 10,
  },
  label: {
    flex: 1,
    height: 20,
    lineHeight: 20,
    fontSize: 13,
    fontWeight: '700',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$white,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$75,
  },
  title: {
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 20,
    color: theme.colors.neutral.$white,
    paddingVertical: '0.5rem',
    paddingLeft: 25,
  },
  menuButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 6,
    borderRadius: 3,
  },
  workspaceMenu: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: 10,
  },
  team: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 7,
    paddingLeft: 16,
    paddingRight: 10,
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    paddingVertical: 20,
    paddingHorizontal: 25,
    backgroundColor: theme.colors.neutral.$1,
  },
  footerTagline: {
    lineHeight: 18,
    fontSize: 11,
    fontWeight: '400',
    textAlign: 'center',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$6,
  },
  titleContainer: {
    height: 40,
    justifyContent: 'center',
  },
});
