import events from 'extensions/events';
import {
  Fragment,
  type ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {StyleSheet, type TextStyle, View, type ViewStyle} from 'react-native';

import theme from 'config/theme';
import {Button, type VARIANT} from 'interface/base/Button';
import Typography from 'interface/base/Typography';
import {CustomTextInput} from './CustomTextInput';
import Modal, {type ModalSize} from './Modal';

export interface PromptProps {
  title?: string;
  text?: string;
  maxWidth?: number;
  maxHeight?: number;
  rootStyle?: ViewStyle;
  children?: ReactNode;
  buttons?: PromptButton[];
  options?: PromptOptions;
  onClose: () => void;
  size?: ModalSize;
}

export interface PromptButton {
  variant?: keyof typeof VARIANT;
  text?: string;
  disabled?: boolean;
  textStyle?: TextStyle;
  buttonStyle?: ViewStyle;
  onPress?: (inputValue?: string) => void;
}

export interface PromptOptions {
  type?: 'default' | 'plain-text' | 'secure-text';
  keyboardType?: 'default' | 'email-address' | 'numeric' | 'number-pad';
  autoCompleteType?: 'email' | 'name' | 'password' | 'username' | 'off';
  selectInput?: 'none' | 'all' | 'filename';
  defaultValue?: string;
  placeholder?: string;
  primaryColor?: string;
  forceChoice?: boolean;
  isMinimal?: boolean;
}

export function Prompt(props: PromptProps) {
  const [input, setInput] = useState(props.options?.defaultValue ?? undefined);

  const pressButton = useCallback((button: PromptButton, value?: string) => {
    if (button.onPress) {
      button.onPress(value);
    }
  }, []);

  const renderButton = useCallback(
    (button: PromptButton, index: number) => (
      <Fragment key={index}>
        <Button
          variant={button.variant}
          disabled={button.disabled}
          onPress={e => {
            e.stopPropagation();
            pressButton(button, input);
          }}>
          {button.text}
        </Button>
      </Fragment>
    ),
    [pressButton, input],
  );

  useEffect(
    () =>
      events.addKeyListener('up', e => {
        if (e.key === 'Escape') props.onClose();
      }).unsubscribe,
    [props.onClose],
  );

  return (
    <Modal title={props.title} size={props.size}>
      {props.children}
      {!!props.text && !props.children && (
        <Typography
          variant="regular"
          size="md"
          color={theme.colors.neutral.$700}>
          {props.text}
        </Typography>
      )}
      {props.options?.type && props.options.type !== 'default' && (
        <CustomTextInput
          customProps={{
            autoFocus: true,
            onChangeText: setInput,
            defaultValue: props.options?.defaultValue,
            keyboardType: props.options?.keyboardType,
          }}
          placeholder={props.options?.placeholder}
          // @ts-ignore
          autoCompleteType={props.options?.autoCompleteType}
          secureTextEntry={props.options?.type === 'secure-text'}
          selectInput={props.options?.selectInput}
        />
      )}
      {props.buttons && props.buttons.length > 0 && (
        <View style={styles.buttons}>{props.buttons.map(renderButton)}</View>
      )}
    </Modal>
  );
}

const modalPadding = 20;

const styles = StyleSheet.create({
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: modalPadding,
    gap: 6,
  },
  close: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    marginRight: 6,
    padding: 8,
    height: 46,
    width: 46,
    right: 0,
  },
  spacer: {
    width: 6,
  },
});
