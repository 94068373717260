import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import {StyleSheet, View} from 'react-native';

interface Props {
  onNewChatPress: () => void;
}

export function AiChatListHeader({onNewChatPress}: Props) {
  return (
    <View style={styles.container}>
      <Typography size="md" variant="medium" color={theme.colors.neutral.$700}>
        AI Chats
      </Typography>
      <Button
        onPress={onNewChatPress}
        variant="secondary"
        startEnhancer={
          <Icon name="lucide:plus" color={theme.colors.neutral.$700} />
        }>
        <Typography size="xs" color={theme.colors.neutral.$700}>
          New Chat
        </Typography>
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    padding: 16,
    borderBottomColor: theme.colors.neutral.$75,
  },
});
