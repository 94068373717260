import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {selectors as files} from 'store/slices/files';
import {selectors as user} from 'store/slices/user';
import {getHashColor} from 'utils/common/color';

import type {MultiplayerConnection} from '../types';

export function useMultiplayerSync(
  connection: MultiplayerConnection,
  targetId: string,
  targetType: 'folder' | 'file',
) {
  const selection = useSelector(files.getSelection);
  const details = useSelector(user.getUserDetails);
  useEffect(() => {
    if (details && connection?.provider) {
      connection.setState({
        clientId: connection.provider.awareness.clientID,
        userId: details.id.toString(),
        userEmail: details.email_address,
        userColor: getHashColor(details.id.toString()),
        userNameFirst: details.first_name,
        userNameLast: details.last_name,
        currentItemId: targetId,
        currentItemType: targetType,
        selection,
      });
    }
  }, [connection, targetId, selection, details]);
}
