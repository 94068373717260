import type {VideoQualityOptions} from '@vidstack/react';
import theme from 'config/theme';
import React, {useMemo, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import useClickOutside from '../../hooks/useClickOutside';
import ActionButton from '../ActionButton';
import MediaPopup, {type OnChangeParams} from './MediaPopup';

type Props = {
  qualities: VideoQualityOptions;
  quality: string;
  qualityLabel: string;
};

const QualityButton = ({qualities, quality, qualityLabel}: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const {targetRef, sourceRef} = useClickOutside(showPopup, () =>
    setShowPopup(false),
  );

  const togglePopup = () => setShowPopup(prev => !prev);

  const handleChangeQuality = ({payload}: OnChangeParams) => {
    if (payload.select) {
      payload.select();
    }
    setShowPopup(false);
  };

  const options = useMemo(() => {
    if (!qualities || qualities.length === 0) {
      return [];
    }
    return qualities.map(option => ({
      label: option.label,
      value: option.value,
      payload: {select: option.select},
    }));
  }, [qualities]);

  return (
    <View style={styles.root}>
      <ActionButton
        ref={sourceRef}
        text={qualityLabel}
        onPress={togglePopup}
        textStyle={styles.text}
        tooltipText="Quality"
        hideTooltip={showPopup}
      />
      {showPopup && options && (
        <MediaPopup
          ref={targetRef}
          options={options}
          onChange={handleChangeQuality}
          currentValue={quality}
          headerText="Quality"
          isRight
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    position: 'relative',
  },
  text: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 24,
    color: theme.colors.neutral.$white,
  },
});

export default QualityButton;
