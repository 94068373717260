import theme from 'config/theme';
import type {WorkspaceListDetail} from 'fast-sdk/src/api/workspace/consts';
import {PanelGroup, PanelHandle} from 'interface/base/Panel';
import {StyleSheet} from 'react-native';
import {FolderTreeSection} from './sections/FolderTreeSection';
import {SharesSection} from './sections/SharesSection';

interface WorkspaceSideNavProps {
  workspace: WorkspaceListDetail;
  selectedFolderId?: string;
}

export function WorkspaceSideNav({
  workspace,
  selectedFolderId,
}: WorkspaceSideNavProps) {
  return (
    <PanelGroup direction="vertical" style={styles.panelGroup}>
      <FolderTreeSection
        workspace={workspace}
        selectedFolderId={selectedFolderId}
      />
      <PanelHandle style={styles.handle} />
      <SharesSection workspace={workspace} />
    </PanelGroup>
  );
}

const styles = StyleSheet.create({
  panelGroup: {
    flex: 1,
    height: '100%',
  },
  handle: {
    height: 1,
    backgroundColor: theme.colors.neutral.$100,
  },
});
