import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import type {Store} from './types';

const initialState: Store = {
  // Last error for showing to the user
  lastError: {
    message: '',
    title: '',
    errorCode: 0,
    retryFn: undefined,
    errorConfiguration: undefined,
    originalErrorText: '',
    availableRetries: 0,
    id: undefined,
  },
  noActionErrors: [],
  toastErrors: [],
  showError: false,
};

export default createSlice({name: 'errors', reducers, initialState});
export {selectors};
