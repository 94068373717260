import theme from 'config/theme';
import {FileList} from 'interface/stacks/workspace/storage/FileList';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import type {FilesItem} from 'store/slices/files/types';

type Props = {
  title: string;
  items: {[id: string]: FilesItem};
};

const RecentSection = ({title, items}: Props) => {
  if (Object.keys(items).length === 0) {
    return null;
  }

  return (
    <View style={styles.root}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.list}>
        <FileList
          view={Object.keys(items)}
          items={items}
          following={null}
          root={['root']}
          options={{
            disableDetails: true,
          }}
          recents
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  title: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$12,
    borderTopWidth: 1,
    borderTopColor: theme.colors.neutral.$12,
  },
  list: {
    flex: 1,
    margin: 0,
    flexDirection: 'row',
    paddingTop: 15,
  },
});

export default RecentSection;
