import type {DeviceBase, HapticFeedbackType} from './base';

class Device implements DeviceBase {
  getLocale(short?: boolean): string {
    const locale = navigator.language || navigator.userLanguage;
    return short ? locale.split('-').shift() : locale;
  }

  share(url: string, title: string) {
    navigator.share({url, title}).catch(() => {});
  }

  restartApp() {
    location.reload();
  }

  isDarkMode() {
    return matchMedia('(prefers-color-scheme: dark)').matches;
  }

  dismissKeyboard() {
    // Noop on web
    return;
  }

  triggerHapticFeedback(_type: HapticFeedbackType) {
    // Noop on web
    return;
  }
}

export default new Device();
