import {t} from '@lingui/macro';
import theme from 'config/theme';
import analytics from 'extensions/analytics';
import {useNavigate, useOutletContext} from 'extensions/navigation';
import {api} from 'fast-sdk';
import {Button} from 'interface/common/Button';
import {NavigateTo, useLogout} from 'interface/common/hooks/useLogout';
import {useSubDomain} from 'interface/common/hooks/useSubDomain';
import {SettingsType} from 'interface/stacks/settings/base/consts';
import {useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {SubscriptionBackButtonState} from '../layout/SubscriptionLayout';
import {CancelError} from './CancelError';
import {CancelLoading} from './CancelLoading';
import {CancelSuccess} from './CancelSuccess';

enum PageStatus {
  CancelPage = 'CancelPage',
  Loading = 'Loading',
  Success = 'Success',
  Error = 'Error',
}

export function CancelSubscription() {
  const [pageStatus, setPageStatus] = useState<PageStatus>(
    PageStatus.CancelPage,
  );

  const [status, setStatus] =
    useOutletContext<
      [
        SubscriptionBackButtonState,
        (status: SubscriptionBackButtonState) => void,
      ]
    >();

  const navigate = useNavigate();
  const {subdomain} = useSubDomain();
  const {logout} = useLogout();

  const onLogout = async () => {
    logout({navigateTo: NavigateTo.LOGOUT});
  };

  const cancelSubscription = () => {
    if (subdomain) {
      setPageStatus(PageStatus.Loading);
      api.billing
        .cancelSubscription(subdomain)
        .then(res => {
          if (res?.result) {
            setPageStatus(PageStatus.Success);
          } else {
            setPageStatus(PageStatus.Error);
          }
        })
        .catch(err => analytics.notify(err));
    }
  };

  const renderPage = () => {
    switch (pageStatus) {
      case PageStatus.CancelPage:
        setStatus(SubscriptionBackButtonState.Show);
        return (
          <View style={styles.root}>
            <Text selectable style={styles.headerText}>
              Cancel your subscription?
            </Text>
            <Text selectable style={styles.contentText}>
              All workspaces, files, and shares in your organization will not
              accessible if you cancel. Are you sure you want to proceed?
            </Text>
            <View style={styles.buttonsContainer}>
              <Button
                ariaLabel="Go Back"
                type="Secondary"
                label={t`No, take me back`}
                onPress={() => navigate(`/settings/${SettingsType.billing}`)}
                customRootStyle={styles.buttonContainer}
                customTextStyle={styles.buttonText}
                fontStyle="Normal, Large"
              />
              <Button
                type="Primary"
                label={t`Cancel subscription`}
                onPress={cancelSubscription}
                customRootStyle={styles.buttonContainer}
                customTextStyle={styles.buttonText}
                ariaLabel="Downgrade"
                fontStyle="Normal, Large"
              />
            </View>
          </View>
        );
      case PageStatus.Loading:
        setStatus(SubscriptionBackButtonState.Disabled);
        return (
          <CancelLoading callBack={() => setPageStatus(PageStatus.Success)} />
        );
      case PageStatus.Success:
        setStatus(SubscriptionBackButtonState.Remove);
        return <CancelSuccess done={onLogout} />;
      case PageStatus.Error:
        setStatus(SubscriptionBackButtonState.Remove);
        return <CancelError />;
    }
  };

  return renderPage();
}

const styles = StyleSheet.create({
  root: {
    width: 650,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 14,
    shadowColor: theme.colors.neutral.$black,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3.05,
    elevation: 4,
  },
  headerText: {
    fontSize: 32,
    fontWeight: '700',
    lineHeight: 48,
    color: theme.colors.neutral.$2Base,
    marginBottom: '1.5rem',
  },
  contentText: {
    fontSize: 16.5,
    fontWeight: '400',
    lineHeight: 26,
    color: theme.colors.neutral.$2Base,
    marginBottom: '3rem',
    textAlign: 'center',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 10,
  },
  buttonContainer: {
    paddingHorizontal: '1rem',
    paddingVertical: 12,
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
});
