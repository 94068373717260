import {ApiErrors} from 'fast-sdk/src/requests/errors';
import useCreateWorkspace from 'interface/stacks/workspace/hooks/useCreateWorkspace';
import {useEffect, useState} from 'react';

const DEFAULT_WORKSPACE_NAME = 'General';

const useCreateDefaultWorkspace = (
  isSubscribed: boolean,
  subdomain: string,
  orgId: string,
) => {
  const [isWorkspaceCreated, setIsWorkspaceCreated] = useState(false);

  const {createWorkspace} = useCreateWorkspace();

  useEffect(() => {
    if (isSubscribed && subdomain && orgId) {
      async function createDefaultWorkspace() {
        const response = await createWorkspace({
          subdomain,
          orgId,
          name: DEFAULT_WORKSPACE_NAME,
          intelligence: true,
        });

        if (
          response.result ||
          (response.error &&
            response.error.code === ApiErrors.WorkspaceConfigNameInUse)
        ) {
          setIsWorkspaceCreated(true);
        }
      }

      createDefaultWorkspace();
    }
  }, [isSubscribed, subdomain, orgId]);

  return {isWorkspaceCreated};
};

export default useCreateDefaultWorkspace;
