import {usePopup} from 'extensions/viewport/usePopup';
import {createRef} from 'react';
import {NewStorageNodeDropdown} from '../NewStorageNodeDropdown';

import type {StorageNamespace} from 'fast-sdk/src/api/storage/consts';
import type {UploaderState} from 'interface/stacks/uploads/hooks/useUploader';
import type {View} from 'react-native';

type Props = {
  folderKey: string;
  instanceId: string;
  instanceAlt: string;
  instanceNs: StorageNamespace;
  uploader: UploaderState;
};

export function useAddNewNode({
  folderKey,
  instanceId,
  instanceAlt,
  instanceNs,
  uploader,
}: Props) {
  const popup = usePopup();
  const addNewNodeButtonRef = createRef<View>();
  const openAddNewNodePopup = () => {
    popup.setRef(addNewNodeButtonRef);
    popup.setManualPosition(undefined);
    popup.setPlacement('bottom-start');
    setTimeout(() => {
      popup.open(
        <NewStorageNodeDropdown
          folderKey={folderKey}
          instanceId={instanceId}
          instanceAlt={instanceAlt}
          instanceNs={instanceNs}
          uploader={uploader}
          close={() => popup.close()}
        />,
        {
          closeOnClickOutside: true,
        },
      );
    }, 100);
  };
  return {addNewNodeButtonRef, openAddNewNodePopup};
}
