import {memo} from 'react';
import {Image, ImageBackground} from 'react-native';

export type ImageContentsProps = {
  url: string;
  thumb: string;
  width: number;
  height: number;
};

const ImageContents = memo(
  ({url, thumb, width, height}: ImageContentsProps) => (
    <ImageBackground source={{uri: thumb}} style={{width, height}}>
      <Image source={{uri: url}} style={{width, height}} />
    </ImageBackground>
  ),
);

export default ImageContents;
