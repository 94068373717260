import {api} from 'fast-sdk';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import * as onboarding from 'store/slices/onboarding';
import type {BillingPlan} from '../lib/types';

export default function useBillingPlan() {
  const [loading, setLoading] = useState<boolean>(false);
  const [tax, setTax] = useState<number>(0);
  const [plan, setPlan] = useState<BillingPlan | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchPlans() {
      setLoading(true);
      try {
        const response = await api.billing.getPlanList();

        if (!response || !response.result) {
          return;
        }

        const [first] = response.plans as Array<BillingPlan>;
        const tax = Math.ceil(first.pricing.price_base * 0.08);
        setPlan(first);
        setTax(tax);
        dispatch(onboarding.default.actions.setPlanName({name: first.name}));
      } finally {
        setLoading(false);
      }
    }

    fetchPlans();
  }, []);

  return {loading, tax, plan};
}
