import theme from 'config/theme';
import {StyleSheet, View} from 'react-native';
import {getVersion} from 'utils/common/version';
import AdvancedSettingsButton from '../AdvancedSettingsButton';
import StatusButton from '../StatusButton';
import useShowUpdateNotice from '../hooks/useShowUpdateNotice';
import AppNewVersionNotice from './AppNewVersionNotice';

interface StylingScheme {
  background?: string;
  text?: string;
  borderTopWidth?: number;
}

interface AppSideNavFooterProps {
  showAppStatus?: boolean;
  stylingScheme?: StylingScheme;
}

export function AppSideNavFooter({
  showAppStatus,
  stylingScheme = {
    background: theme.colors.neutral.$1,
    text: theme.colors.neutral.$6,
    borderTopWidth: 1,
  },
}: AppSideNavFooterProps) {
  const {showUpdateAlert} = useShowUpdateNotice();

  const textStyle = {
    color: stylingScheme.text,
  };

  return (
    <View style={[styles.footer, {backgroundColor: stylingScheme.background}]}>
      {showUpdateAlert && (
        <View style={styles.footerNewVersion}>
          <AppNewVersionNotice />
        </View>
      )}
      <View
        style={[
          styles.footerContent,
          {borderTopWidth: stylingScheme.borderTopWidth},
        ]}>
        <View style={styles.footerButtons}>
          <AdvancedSettingsButton
            label={'©2025 Fast.io'}
            textStyle={textStyle}
          />
          {showAppStatus && <StatusButton textStyle={textStyle} />}
        </View>

        <AdvancedSettingsButton
          label={`v${getVersion()}`}
          textStyle={textStyle}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    flexDirection: 'column',
    backgroundColor: theme.colors.neutral.$1,
  },
  footerNewVersion: {
    padding: 10,
  },
  footerContent: {
    flexDirection: 'column',
    paddingVertical: 10,
    paddingHorizontal: 25,
    borderTopWidth: 1,
    borderTopColor: theme.colors.neutral.$25,
  },
  footerTagline: {
    lineHeight: 18,
    fontSize: 11,
    fontWeight: '400',
    textAlign: 'left',
    textAlignVertical: 'top',
    color: theme.colors.neutral.$6,
  },
  footerButtons: {
    flexDirection: 'row',
    gap: 5,
  },
  clearCacheContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
    padding: 15,
    borderRadius: 7,
    backgroundColor: theme.colors.neutral.$white,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3.05,
    elevation: 4,
  },
});
