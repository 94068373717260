import {useCallback, useEffect, useRef} from 'react';

const useClickOutside = (isOpen: boolean, close: () => void) => {
  const targetRef = useRef(null);
  const sourceRef = useRef(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        !targetRef.current?.contains(event.target as Node) &&
        !sourceRef.current?.contains(event.target as Node)
      ) {
        close();
      }
    },
    [close],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  return {targetRef, sourceRef};
};

export default useClickOutside;
