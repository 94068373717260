import React from 'react';
import {StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';
import ShareTableHeaders from './ShareTableHeaders';
import ShareTableRows from './ShareTableRows';

interface SharesTableProps {
  shares: Share[];
  isSharesLoading: boolean;
  onSharePress: (share: Share) => void;
}

const SharesTable = ({
  shares,
  isSharesLoading,
  onSharePress,
}: SharesTableProps) => {
  return (
    <View style={styles.root}>
      <ShareTableHeaders />
      <ShareTableRows
        shares={shares}
        onSharePress={onSharePress}
        isSharesLoading={isSharesLoading}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    marginTop: 21,
  },
});

export default SharesTable;
