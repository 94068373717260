import theme from 'config/theme';
import {FlatList, StyleSheet, View} from 'react-native';

import {AddEmailItem} from './AddEmailItem';

export interface UserInfo {
  email: string;
  name: string;
  isInvite?: boolean;
}
interface AddEmailDropdownProps {
  searchTerm: string;
  items: UserInfo[];
  offsetVertical: number;
  onOptionSelected: (option: string) => void;
  focusedIndex: number;
}

export function AddEmailDropdown(props: AddEmailDropdownProps) {
  return (
    <View style={[styles.dropdown, {top: props.offsetVertical}]}>
      <FlatList
        data={props.items}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({item, index}) => (
          <AddEmailItem
            isFocused={index === props.focusedIndex}
            searchTerm={props.searchTerm}
            user={item}
            onOptionSelected={props.onOptionSelected}
          />
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  currentEmail: {
    fontWeight: 'bold',
  },
  dropdown: {
    backgroundColor: 'white',
    borderColor: theme.colors.neutral.$11,
    borderRadius: 5,
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    position: 'absolute',
    right: 0,
    zIndex: 5,
  },
});
