import {useNavigate, useParams} from 'extensions/navigation';
import SettingsContent, {
  PUBLIC_SETTINGS_SECTIONS,
} from 'interface/stacks/settings/SettingsContent';
import {SettingsType} from 'interface/stacks/settings/base/consts';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {slices} from 'store';
import * as app from 'store/slices/app';
import {DEFAULT_PAGE_INFO, PageType} from 'store/slices/app/types';

export function RouteSettings() {
  const {settingsSection: section} = useParams<'settingsSection'>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAdminOrOwner = useSelector(app.selectors.getUserIsAdminOrOwner);

  useEffect(() => {
    if (
      !isAdminOrOwner &&
      !PUBLIC_SETTINGS_SECTIONS.includes(section as SettingsType)
    ) {
      navigate(`/settings/${SettingsType.profile}`, {replace: true});
    }

    dispatch(
      slices.app.actions.setCurrentPage({
        ...DEFAULT_PAGE_INFO,
        type: PageType.Settings,
        settings: section,
      }),
    );
  }, [isAdminOrOwner, section]);

  return <SettingsContent type={SettingsType[section]} />;
}

export default RouteSettings;
