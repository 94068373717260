import {t} from '@lingui/macro';
import {format} from 'date-fns';
import {DefaultLayout} from 'fast-sdk/src/api/share/consts';
import DatetimePicker from 'interface/base/DatetimePicker';
import {CustomPicker, PickerSize} from 'interface/common/CustomPicker';
import {CustomSwitch} from 'interface/common/CustomSwitch';
import {StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';
import type {ShareState} from '../../../shared/dialogs/SharedCustomizeDialog/hooks/useShareActions';
import Section from './Section';
import SectionTitle from './SectionTitle';

interface Props {
  shareState: ShareState;
  updateShare: (updatedShare: Partial<Share>) => void;
}
export default function ShareOptions({shareState, updateShare}: Props) {
  const {
    allowComments,
    displayType,
    setAllowComments,
    setDisplayType,
    setExpirationDate,
    expirationDate,
  } = shareState;

  const handleSetExpirationDate = (newExpirationDate: Date | undefined) => {
    setExpirationDate(newExpirationDate);
    updateShare({
      expires: newExpirationDate
        ? format(newExpirationDate, 'yyyy-MM-dd hh:mm:ss')
        : undefined,
    });
  };

  const handleSetDisplayType = (newDisplayType: DefaultLayout) => {
    setDisplayType(newDisplayType);
    updateShare({
      display_type: newDisplayType,
    });
  };

  const handleSetAllowComments = (newAllowComments: boolean) => {
    setAllowComments(newAllowComments);
    updateShare({
      comments_enabled: newAllowComments,
    });
  };

  const renderDateText = (date: Date) => {
    return date ? t`Expires ${format(date, 'LLLL d, yyyy')}` : t`No expiration`;
  };

  return (
    <Section>
      <SectionTitle>Options</SectionTitle>
      <View style={styles.content}>
        <CustomSwitch
          label="Comments"
          onChange={handleSetAllowComments}
          checked={allowComments}
          icon="lucide:message-square-text"
        />
        <CustomPicker
          label={t`Default layout`}
          icon="lucide:layout-list"
          options={Object.values(DefaultLayout).map(value => ({
            title: value,
            value,
          }))}
          setSelected={handleSetDisplayType}
          selected={displayType}
          size={PickerSize.SMALL}
        />
        <DatetimePicker
          label="Expiration"
          date={expirationDate}
          setDate={handleSetExpirationDate}
          emptyDateText="No expiration"
          renderDateText={renderDateText}
        />
      </View>
    </Section>
  );
}

const styles = StyleSheet.create({
  content: {
    gap: 16,
  },
});
