import {Trans, t} from '@lingui/macro';
import DMImage from '_dummy/org/logo.png';
import theme from 'config/theme';
import {Button} from 'interface/common/Button';
import {
  Image,
  StyleSheet,
  Text,
  View,
  type ViewStyle,
  useWindowDimensions,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useNavigate} from 'react-router';

interface Props {
  children: React.ReactElement;
  name: string;
  subDomain: string;
  customSubTitle?: React.ReactElement;
  removeTitle?: boolean;
  removeBottomLink?: boolean;
  signUpPage?: boolean;
  customSubTitleStyle?: ViewStyle;
}

export function OrganizationLayout(props: Props) {
  const textColor = theme.colors.neutral.$2Base;
  const navigate = useNavigate();

  const dimension = useWindowDimensions();

  return (
    <View
      style={[
        styles.root,
        {
          width: dimension.width > 790 ? 420 : '100%',
        },
      ]}>
      <Image
        source={{uri: DMImage}}
        alt={`${props.name} Organization`}
        style={styles.orgLogo}
      />
      {props.removeTitle ? null : (
        <Text style={[styles.orgNameContainer, {color: textColor}]}>
          {props.signUpPage ? (
            <Trans>Create an account to join</Trans>
          ) : (
            <Trans>Sign in to</Trans>
          )}
          <Text style={styles.orgName}>{props.name}</Text>
        </Text>
      )}
      <View style={[{marginBottom: '3rem'}, props.customSubTitleStyle]}>
        {props.customSubTitle ? (
          props.customSubTitle
        ) : (
          <Text style={[styles.subDomain, {color: textColor}]}>
            {props.subDomain}
          </Text>
        )}
      </View>
      {props.children}
      {props.signUpPage ? (
        <Text style={styles.bottomSignupText}>
          Don’t have an @
          <Text style={{fontWeight: '700'}}>{props.subDomain}</Text> email
          address? Contact your organization administrator for an invite.
        </Text>
      ) : null}
      {props.removeBottomLink ? null : (
        <View
          style={[
            styles.bottomLinkContainer,
            {marginTop: props.signUpPage ? '1.5rem' : 0},
          ]}>
          <Text
            style={[
              styles.bottomText,
              {color: textColor},
            ]}>{`${props.signUpPage ? 'Already have an account?' : 'Need an account?'}`}</Text>
          <Button
            type="Text"
            label={props.signUpPage ? t`Sign In` : t`Sign Up`}
            onPress={() =>
              navigate(
                `/${props.signUpPage ? 'login' : 'signup'}/subdomain?name=${props.name}&subDomain=${props.subDomain}`,
              )
            }
            rIcon={
              <Icon
                name="arrow-forward"
                color={theme.colors.brand.$4Base}
                size={20}
                style={styles.rightArrowIcon}
                aria-label={'Sign up'}
              />
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 'auto',
  },
  orgLogo: {
    width: 68,
    height: 68,
    borderRadius: 50,
  },
  orgNameContainer: {
    width: '100%',
    textAlign: 'center',
    fontSize: 26,
    fontWeight: '700',
    lineHeight: 40,
    marginBottom: 7,
  },
  orgName: {
    fontSize: 26,
    fontWeight: '700',
    lineHeight: 40,
    color: theme.colors.brand.$3,
    marginLeft: '0.5rem',
  },
  subDomain: {
    width: '100%',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
  },
  bottomLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 7,
  },
  bottomText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
  },
  rightArrowIcon: {
    marginLeft: 10,
  },
  bottomSignupText: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.neutral.$5,
    textAlign: 'center',
    paddingBottom: '1.5rem',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.neutral.$11,
  },
});
