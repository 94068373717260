import {ROUTES} from 'constants/routes';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';
import {isSubdomainValid} from 'utils/common/platform';
import {useSubDomain} from './useSubDomain';

export const useCheckOrgSubscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {subdomain} = useSubDomain();

  const {redirect, subdomain: renewSubscriptionSubdomain} = useSelector(
    app.selectors.getRedirectToRenewSubscription,
  );

  const handleRenewSubscription = () => {
    try {
      if (
        isSubdomainValid(subdomain) &&
        subdomain === renewSubscriptionSubdomain
      ) {
        navigate(
          `/${ROUTES.LOGGED_IN_WITH_ORG.RENEW_SUBSCRIPTION}?orgDomain=${subdomain}`,
        );
      }
    } finally {
      dispatch(
        app.default.actions.setRedirectToRenewSubscription({
          redirect: false,
          subdomain: undefined,
        }),
      );
    }
  };

  useEffect(() => {
    if (redirect) {
      handleRenewSubscription();
    }
  }, [redirect]);

  return {handleRenewSubscription};
};
