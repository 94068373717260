import type {Actions, Store} from 'store/slices/app/types';
import {resetSlice} from 'store/utils';
import {initialState} from '.';

export function purge(state: Store) {
  return resetSlice(state, initialState);
}

export function activate(state: Store, action: Actions['Activate']) {
  const {active} = action.payload;
  state.active = active;
}

export function load(state: Store, action: Actions['Load']) {
  const {loaded} = action.payload;
  state.loaded = loaded;
}

export function setCurrentPage(
  state: Store,
  action: Actions['SetCurrentPage'],
) {
  state.currentPage = action.payload;
}

export function login(state: Store, action: Actions['Login']) {
  state.user = action.payload;
}

export function logout(state: Store) {
  state.user = null;
}

export function stopRedirect(state: Store) {
  state.stopRedirect = true;
}

export function startRedirect(state: Store) {
  state.stopRedirect = false;
}

export function setDomainOrigin(state: Store, action: Actions['DomainOrigin']) {
  const {origin} = action.payload;
  state.domainOrigin = origin;
}

export function setCurrentJoinInvitation(
  state: Store,
  action: Actions['SetCurrentJoinInvitation'],
) {
  const {joinInvitation} = action.payload;
  state.currentJoinInvitation = joinInvitation;
}

export function clearCurrentJoinInvitation(state: Store) {
  state.currentJoinInvitation = undefined;
}

export function setForceLogout(
  state: Store,
  action: Actions['SetForceLogout'],
) {
  state.forceLogout = action.payload;
}

export function setRedirectToRenewSubscription(
  state: Store,
  action: Actions['SetRedirectToRenewSubscription'],
) {
  const {redirect, subdomain} = action.payload;
  state.redirectToRenewSubscription = redirect;
  state.renewSubscriptionSubdomain = subdomain;
}

export function setUserPermission(
  state: Store,
  action: Actions['setUserPermission'],
) {
  state.userPermission = action.payload.permission;
}

export function setColumnInfo(state: Store, action: Actions['setColumnInfo']) {
  state.columnInfo = {...state.columnInfo, ...action.payload};
}

export function setColumnInfoOverride(
  state: Store,
  action: Actions['setColumnInfoOverride'],
) {
  state.columnInfoOverride = {...state.columnInfoOverride, ...action.payload};
}

export function setResendInvitationLimitEntry(
  state: Store,
  action: Actions['setResendInvitationLimitEntry'],
) {
  const {key, sent, times} = action.payload;
  const entry = state.resendInvitationLimit[key];

  if (entry) {
    if (sent && entry.times >= times) {
      entry.times = 1;
      entry.sent = sent;
    } else {
      entry.times++;
    }
  } else {
    state.resendInvitationLimit[key] = {times: 1, sent: new Date()};
  }
}
