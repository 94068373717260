import theme from 'config/theme';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import Typography from 'interface/base/Typography';
import React from 'react';
import {StyleSheet} from 'react-native';
import Card from './Card';

interface AddItemCardProps {
  onPress: () => void;
  label: string;
  ariaLabel: string;
}

const AddItemCard = ({onPress, label, ariaLabel}: AddItemCardProps) => {
  return (
    <Card style={styles.card} onPress={onPress}>
      <Button
        overrides={{
          Button: {
            style: {
              backgroundColor: theme.colors.neutral.$100,
              width: 40,
              height: 40,
              borderRadius: 8,
            },
          },
        }}
        aria-label={ariaLabel}
        variant="text"
        onPress={onPress}
        endEnhancer={
          <Icon
            name={'lucide:plus'}
            color={theme.colors.neutral.$2Base}
            size={18}
          />
        }
      />
      <Typography
        variant={'medium'}
        color={theme.colors.neutral.$2Base}
        overrides={{opacity: 0.7}}>
        {label}
      </Typography>
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    gap: 12,
  },
});

export default AddItemCard;
