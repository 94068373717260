import theme from 'config/theme';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import ActionButton from '../ActionButton';

export enum Tab {
  COMMENTS = 'comments',
  FILE_INFO = 'file-info',
}

type Props = {
  onClose: () => void;
  onChangeTab: (tab: Tab) => void;
  selected: Tab;
  showComments: boolean;
};

const Tabs = ({onClose, onChangeTab, selected, showComments}: Props) => {
  return (
    <View style={styles.root}>
      <View style={styles.tabs}>
        {showComments && (
          <ActionButton
            text="Comments"
            onPress={() => onChangeTab(Tab.COMMENTS)}
            textStyle={styles.text}
            isActive={selected === Tab.COMMENTS}
          />
        )}
        <ActionButton
          text="File Info"
          onPress={() => onChangeTab(Tab.FILE_INFO)}
          textStyle={styles.text}
          isActive={selected === Tab.FILE_INFO}
        />
      </View>
      <ActionButton
        icon={
          <Icon
            name="arrow-collapse-right"
            color={theme.colors.neutral.$white}
          />
        }
        onPress={onClose}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  text: {
    color: theme.colors.neutral.$white,
  },
});

export default Tabs;
