import {api} from 'fast-sdk';
import type {OrganizationDetails} from 'fast-sdk/src/api/organization/consts';
import {useEffect, useState} from 'react';
import type {Organization} from 'store/slices/user/types';

export const useOrganizationPublicDetails = (
  domain: string,
  orgDetails?: Organization | undefined,
) => {
  const [org, setOrg] = useState<OrganizationDetails | null>(orgDetails);
  const [loading, setLoading] = useState(true);

  async function fetchOrganizationDetails() {
    const {result, org} =
      await api.organization.getOrganizationPublicDetails(domain);
    if (result) {
      setOrg(org);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (domain && !org) {
      fetchOrganizationDetails();
    }
  }, [domain]);

  return {org, loading};
};
