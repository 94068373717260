import {VE_DEBUG_ROUTING_KEY} from './consts';
import {ApiErrors} from './errors';
import {
  type ApiError,
  type ApiResponse,
  type ErrorInterceptor,
  RequestError,
  type RequestOptions,
} from './types';

const fetch = globalThis.fetch;
const FormData = globalThis.FormData;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createFormData = (data: any, filename?: string) => {
  const fd = new FormData();
  for (const key in data) {
    if (
      Object.prototype.hasOwnProperty.call(data, key) &&
      data[key] !== undefined
    ) {
      if (key === 'chunk') {
        fd.append(key, data[key], filename);
      } else {
        fd.append(key, data[key]);
      }
    }
  }

  return fd;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createQueryString = (data: any) => {
  const queryString = [];
  for (const key in data) {
    if (
      Object.prototype.hasOwnProperty.call(data, key) &&
      data[key] !== undefined
    ) {
      const value =
        typeof data[key] === 'object' && data[key] !== null
          ? JSON.stringify(data[key])
          : data[key];
      queryString.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      );
    }
  }

  return queryString.join('&');
};

export const makeFetchRequest = async (
  options: RequestOptions,
  url: string,
): Promise<any> => {
  // instantiating error before await allows to see proper stacktrace
  const error = new RequestError();

  const debugKey = window.localStorage.getItem(VE_DEBUG_ROUTING_KEY);
  const fstBrKey = window.document.cookie;
  const response = await fetch(url, {
    ...options,
    credentials: 'include',
    headers: {
      ...options.headers,
      Cookie: `ve_br_key=${fstBrKey}`,
      'x-ve-debug-routing': debugKey!,
    },
  });

  const contentType = response.headers.get('Content-Type');
  const isJSON = contentType ? contentType.includes('application/json') : false;
  const isBlob = contentType
    ? /^(image|audio|video|application\/octet-stream|application\/x-mpegurl|application\/pdf)/i.test(
        contentType,
      )
    : false;
  let body;
  if (isJSON) {
    body = await response.json();
    if (body.data) body = body.data;
  } else if (isBlob) {
    body = await response.blob();
  } else {
    body = await response.text();
  }

  const isOk = 200 <= response.status && response.status < 300;
  if (!isOk) {
    const text = body?.error?.text;
    error.message = text || response.statusText;
    error.response = response;
    error.code = body?.error?.code;
    error.text = text;
    throw error;
  }

  return body;
};

export const makeXMLRequest = (
  options: RequestOptions,
  url: string,
  customXHR?: XMLHttpRequest,
): Promise<any> => {
  // instantiating error before await allows to see proper stacktrace
  const error = new RequestError();

  const xhr = customXHR ? customXHR : new XMLHttpRequest();

  return new Promise((resolve, reject) => {
    const method = options.method as string;

    xhr.upload.onprogress = e => {
      if (options.onProgress) {
        options.onProgress(e as ProgressEvent<XMLHttpRequestEventTarget>);
      }
    };
    xhr.open(method, url);

    for (const [key, value] of Object.entries(options.headers || {})) {
      xhr.setRequestHeader(key, value as string);
    }

    // The necessary Cookies for requests
    const fstBrKey = window.document.cookie;
    xhr.setRequestHeader('Cookie', `ve_br_key=${fstBrKey}`);

    xhr.onreadystatechange = () => {
      // if "The operation is complete."
      if (xhr.readyState === 4) {
        const isOk = 200 <= xhr.status && xhr.status < 300;

        let body;
        try {
          body = JSON.parse(xhr.responseText);
        } catch (e) {
          body = xhr.responseText;
        }

        if (isOk) {
          resolve(body);
        } else {
          const isCanceled = xhr.status === 0;
          const text = isCanceled
            ? 'Request canceled'
            : body?.error?.text || xhr.statusText;
          error.message = text;
          error.response = body;
          error.code = isCanceled
            ? ApiErrors.RequestAborted
            : body?.error?.code;
          error.text = text;

          reject(error);
        }
      }
    };

    if (options.onAbort) {
      options.onAbort.then(() => {
        xhr.abort();
      });
    }

    xhr.send(options.body as XMLHttpRequestBodyInit);
  });
};

export const callApi = async <TData, TParams extends any[]>(
  apiMethod: (...params: TParams) => Promise<ApiResponse<TData>>,
  params: TParams,
  errorInterceptor?: ErrorInterceptor,
  apiLogEnabled?: boolean,
): Promise<ApiResponse<TData>> => {
  const validParams = params.filter(Boolean);

  if (apiLogEnabled) {
    console.log('API Call:', ...validParams);
  }

  try {
    const response = await apiMethod(...params);
    if ('result' in response && response.result === false) {
      throw response.error;
    }

    if (apiLogEnabled) {
      console.log('API Response:', ...validParams, {response});
    }

    return response as ApiResponse<TData>;
  } catch (err) {
    const apiError = {...(err as ApiError), apiUrl: validParams[0]};
    const errorStack = new Error().stack ?? '';

    if (apiLogEnabled) {
      console.error('API Error:', ...validParams, apiError);
      console.error('API Error Trace:', errorStack);
    }

    if (errorInterceptor) {
      const retryFn = async () => {
        return await apiMethod(...params);
      };
      const callbackError = errorInterceptor(apiError, retryFn, errorStack);
      return {
        result: false,
        error: callbackError ?? apiError,
      } as ApiResponse<TData>;
    }

    return {
      result: false,
      error: apiError,
    } as ApiResponse<TData>;
  }
};

const LOCAL_API_URL = 'http://localhost:8810';

export const getBaseOrigin = () => {
  let baseUrl = location.origin;

  // Use proxy for local development
  if (baseUrl.includes('localhost')) {
    baseUrl = LOCAL_API_URL;
  }

  return baseUrl;
};

export const getBaseApiUrl = (path: string) => {
  //TODO move this to an env variable
  const apiVersion = 'current';

  return `/api/${apiVersion}${path}`;
};

export const getBaseUrl = (path: string, withHost?: boolean) => {
  const baseUrl = getBaseOrigin();

  // Build the URL
  return withHost ? `${path}` : `${baseUrl}${getBaseApiUrl(path)}`;
};
