import theme from 'config/theme';
import {ROUTES} from 'constants/routes';
import {Popup} from 'extensions/viewport/Popup';
import {Button} from 'interface/base/Button';
import {Icon} from 'interface/base/Icon';
import {Avatar} from 'interface/common/Avatar';
import {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import * as app from 'store/slices/app';
import * as settings from 'store/slices/settings';
import * as user from 'store/slices/user';
import {getHashColor} from 'utils/common/color';
import {createInitials} from 'utils/common/data';
import {SettingsType} from '../../settings/base/consts';
import useLegalNavigation from '../hooks/useLegalNavigation';
import {AccountDropdown} from '../layout/AccountDropdown';
import {HeaderOrgButton} from './HeaderOrgButton';

const commonOverrides = {
  Button: {
    style: {
      width: 32,
      paddingVertical: 0,
    },
  },
};

const getIcon = (name: string) => {
  return <Icon name={name} color={'#515662'} size={22} />;
};

export default function AppUserBar() {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const userDetails = useSelector(user.selectors.getUserDetails);
  const organization = useSelector(user.selectors.getSelectedOrganization);
  const orgLogo = useSelector(settings.selectors.getOrganizationLogo);
  const isAdminOrOwner = useSelector(app.selectors.getUserIsAdminOrOwner);

  const navigate = useNavigate();

  const {navigateToHelp} = useLegalNavigation();

  const openUserMenu = () => setIsUserMenuOpen(true);
  const closeUserMenu = () => setIsUserMenuOpen(false);

  const navigateToSettings = () => {
    const route = isAdminOrOwner
      ? SettingsType.organization
      : SettingsType['account-settings'];
    navigate(`/${ROUTES.LOGGED_IN_WITH_ORG.SETTINGS}/${route}`);
  };

  const userColor = userDetails?.color || getHashColor(userDetails?.id);
  const userInitials = createInitials(
    `${userDetails?.first_name} ${userDetails?.last_name}`,
  );
  const profilePic = useSelector(user.selectors.getUserProfilePic);

  return (
    <View style={styles.root}>
      <View style={styles.top}>
        {organization ? (
          <HeaderOrgButton organization={organization} orgLogo={orgLogo} />
        ) : (
          <Button
            overrides={{
              Button: {
                style: {
                  ...commonOverrides.Button.style,
                  backgroundColor: theme.colors.neutral.$175,
                },
              },
            }}
            aria-label="Home"
            variant="text"
            onPress={() => {}}
            endEnhancer={getIcon('lucide:house')}
          />
        )}

        <Button
          overrides={commonOverrides}
          aria-label="Search"
          variant="text"
          onPress={() => {}}
          endEnhancer={getIcon('lucide:search')}
        />
        <Button
          overrides={commonOverrides}
          aria-label="Notifications"
          variant="text"
          onPress={() => {}}
          endEnhancer={getIcon('lucide:bell')}
        />
      </View>
      <View style={styles.bottom}>
        <Button
          overrides={commonOverrides}
          aria-label="Help"
          variant="text"
          onPress={navigateToHelp}
          endEnhancer={getIcon('lucide:circle-help')}
        />
        <Button
          overrides={commonOverrides}
          aria-label="Settings"
          variant="text"
          onPress={navigateToSettings}
          endEnhancer={getIcon('lucide:settings')}
        />
        <Popup
          isOpen={isUserMenuOpen}
          close={closeUserMenu}
          placement="right-start"
          offset={16}
          triggerElement={
            <Button
              overrides={commonOverrides}
              aria-label="Account"
              variant="text"
              onPress={openUserMenu}
              endEnhancer={
                <Avatar
                  color={userColor}
                  initials={userInitials}
                  photo={profilePic}
                  hideBorder
                  size={1}
                  shapeKind="rounded-square"
                  imageStyle={styles.avatar}
                  innerStyle={styles.avatar}
                />
              }
            />
          }>
          <AccountDropdown close={closeUserMenu} />
        </Popup>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 64,
    height: '100%',
    paddingBottom: 16,
    paddingHorizontal: 16,
    paddingTop: 8,
    backgroundColor: theme.colors.neutral.$100,
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 6,
  },
  top: {
    gap: 24,
  },
  bottom: {
    gap: 24,
  },
});
