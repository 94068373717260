export function bytesize(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 B';
  const scale = 1000;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const dm = decimals <= 0 ? 0 : decimals;
  const i = Math.floor(Math.log(bytes) / Math.log(scale));
  if (!sizes[i]) return '0 B';
  return `${(bytes / scale ** i).toFixed(dm)} ${sizes[i]}`;
}

export function truncate(str: string, len: number): string {
  if (str.length <= len) return str;
  const half = Math.floor(len / 2);
  return `${str.slice(0, half)}…${str.slice(-half)}`;
}

export function getBit(value: number, bit: number) {
  return (value >> bit) % 2;
}

export function testBit(value: number, bit: number) {
  return (value >> bit) % 2 !== 0;
}

export function toArray(list: FileList | File[]): File[] {
  return Array.prototype.slice.call(list || [], 0);
}

export function uriToFile(uri: string, name: string): Promise<File> {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => res(new File(xhr.response, name));
    xhr.onerror = e => rej(e);
    xhr.open('GET', uri);
    xhr.send();
  });
}

export const createInitials = (name: string) => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/gu);
  const initials = [...name.matchAll(rgx)] || [];
  return (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();
};
