import AudioVolumeSlider from 'interface/stacks/content-viewer/components/control-bar/ExtVolumeSlider';
import PlayButton from 'interface/stacks/content-viewer/components/control-bar/PlayButton';
import PlaybackSpeedButton from 'interface/stacks/content-viewer/components/control-bar/PlaybackSpeedButton';
import VolumeButton from 'interface/stacks/content-viewer/components/control-bar/VolumeButton';
import useAudio from 'interface/stacks/content-viewer/hooks/useAudio';

const ControlsLeft = () => {
  const {audio, handler} = useAudio();

  const togglePause = () => handler.togglePaused();
  const onChangeRate = (speed: number) => handler.changePlaybackRate(speed);
  const toggleMuted = () => handler.toggleMuted();

  return (
    <>
      <PlayButton playing={audio.playing} togglePause={togglePause} />
      <PlaybackSpeedButton
        playbackRate={audio.playbackRate}
        onChangeRate={onChangeRate}
      />
      <VolumeButton
        volume={audio.volume}
        muted={audio.muted}
        toggleMuted={toggleMuted}>
        <AudioVolumeSlider />
      </VolumeButton>
    </>
  );
};

export default ControlsLeft;
