import type {AuthResponse} from '../api/auth/consts';
import type {ApiResponse} from '../requests';
import type WebSocketConnection from './WebSocketConnection';

export interface WebSocketConnectionOptions<T extends WebSocketResponseType> {
  webSocketCategory: WebSocketCategory;
  fetchAuth: (isExpired: boolean) => Promise<string>;
  onOpen?: (event: Event) => void;
  onMessage?: (data: WSResponse<T>) => void;
  onErrorMessage?: (error: Error) => void;
  onReady?: () => void;
  onError?: (event: Event) => void;
  onClose?: (event: CloseEvent, manualClose: boolean) => void;
  reconnectInterval?: number;
  maxReconnectAttempts?: number;
}

export type WSResponse<T extends WebSocketResponseType> = {
  result: boolean;
  time: string;
} & T;

export enum WebSocketCategory {
  Activity = 'activity',
  Multiplayer = 'multiplayer',
}

export type ActivityResponse = NewActivityResponse | ActivityCurrent;

export type MultiplayerResponse = {
  response: WebSocketResponseTypes.MULTIPLAYER;
  // TODO: Define the message types for the multiplayer websocket
};

export type ActivityType = ActivityTypes | string;

type NewActivityResponse = {
  response: WebSocketResponseTypes.ACTIVITY;
  activity: ActivityType[];
};

type ActivityCurrent = {
  response: WebSocketResponseTypes.ACTIVITY_CURRENT;
  activity: Record<ActivityType, string>;
};

type ReadyResponse = {
  response: WebSocketResponseTypes.READY;
};

type DeniedResponse = {
  response: WebSocketResponseTypes.DENIED;
};

export type WebSocketResponseType =
  | ActivityResponse
  | MultiplayerResponse
  | ReadyResponse
  | DeniedResponse;

export type WebSocketConnectorFn<T extends WebSocketResponseType> = (
  options: WebSocketConnectionOptions<T>,
) => WebSocketConnection<T>;

export interface WebSocketAuthResponse extends ApiResponse<AuthResponse> {
  timestamp: number;
}

export enum WebSocketResponseTypes {
  DENIED = 'denied',
  PONG = 'pong',
  OK = 'ok',
  READY = 'ready',
  ACTIVITY = 'activity',
  ACTIVITY_CURRENT = 'activity_current',
  MULTIPLAYER = 'multiplayer',
}

export enum ActivityTypes {
  ASSETS = 'assets',
  EVENTS = 'events',
  MEMBERSHIP = 'membership',
  APIKEY = 'apikey',
  UPLOADS = 'uploads',
  DETAILS = 'details',
  BILLING = 'billing',
  INVITATION = 'invitation',
  SHARES = 'shares',
  STORAGE = 'storage',
  PREVIEW = 'preview',
}

export enum WebSocketMessageTypes {
  PING = 'ping',
  ACTIVITY = 'activity',
  CLOSE = 'close',
}
