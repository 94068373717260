import {DEFAULT_RETRY_OPTIONS} from 'constants/errors';
import {ErrorFlowActions, type RetryOptions} from 'errors/types';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as errors from 'store/slices/errors';
import type {ErrorItem} from 'store/slices/errors/types';

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const calculateBackoffDelay = (
  attempt: number,
  initialInterval: number,
  maxInterval: number,
  backoffFactor: number,
): number => {
  const backoffDelay = initialInterval * backoffFactor ** attempt;
  return Math.min(backoffDelay, maxInterval);
};

const attemptRetry = async (
  retryFn: () => Promise<{result: boolean}>,
  retryOptions: RetryOptions,
): Promise<boolean> => {
  const {maxRetries, retryInterval, maxRetryInterval, backoffFactor} = {
    ...DEFAULT_RETRY_OPTIONS,
    ...retryOptions,
  };

  let attempt = 0;

  while (attempt < maxRetries) {
    try {
      const {result} = await retryFn();
      if (result) return true;
      throw new Error('Automatic Retry failed');
    } catch (error) {
      if (__DEV__) console.error(`Retry attempt ${attempt + 1} failed:`, error);
      attempt++;
      if (attempt < maxRetries) {
        const backoffDelay = calculateBackoffDelay(
          attempt,
          retryInterval,
          maxRetryInterval,
          backoffFactor,
        );
        if (__DEV__)
          console.log(`Waiting ${backoffDelay}ms before next retry attempt`);
        await delay(backoffDelay);
      }
    }
  }

  return false;
};

const handleError = async (error: ErrorItem) => {
  const {errorConfiguration, exception, originalErrorText, retryFn} = error;
  const {flowAction, retryOptions} = errorConfiguration;

  const canAutoRetry =
    flowAction === ErrorFlowActions.NoAction &&
    retryFn &&
    retryOptions?.automatic;

  if (canAutoRetry) {
    retryOptions?.waitTimeBeforeRetry &&
      (await delay(retryOptions.waitTimeBeforeRetry));

    const success = await attemptRetry(retryFn, retryOptions);

    if (!success) {
      if (__DEV__) console.error('All retry attempts failed');
    }
  } else {
    if (__DEV__)
      console.debug(
        'The following error occurred but no action was taken due to error configuration.',
        exception ?? originalErrorText,
      );
  }
};

export const useHandleNoActionErrors = () => {
  const noActionErrors = useSelector(errors.selectors.getNoActionErrors);

  useEffect(() => {
    if (noActionErrors.length > 0) {
      const latestError = noActionErrors[noActionErrors.length - 1];
      handleError(latestError);
    }
  }, [noActionErrors]);
};
