import {useNavigate, useOutletContext} from 'extensions/navigation';
import {PLANS_INFO} from 'interface/stacks/onboard/lib/consts';
import {useState} from 'react';

import {Downgrade} from './components/Downgrade';
import {UpgradeDowngradeLoading} from './components/Loading';
import {PaymentUpdate} from './components/PaymentUpdate';
import {SelectPlan} from './components/SelectPlan';
import {Success} from './components/Success';
import {UpgradeDowngradeState} from './layout/UpgradeDowngradeLayout';

import type {BilledDurationType, PlanType} from 'store/slices/settings/types';

export type SelectedPlan = {
  type: PlanType;
  duration: BilledDurationType;
};

export enum CallBackType {
  Upgrade = 'Upgrade',
  Downgrade = 'Downgrade',
}

export function UpgradeDowngrade() {
  const navigate = useNavigate();
  const [status, setStatus] =
    useOutletContext<
      [UpgradeDowngradeState, (status: UpgradeDowngradeState) => void]
    >();
  const [newPlan, setNewPlan] = useState<SelectedPlan>();
  const [callBackType, setCallBackType] = useState<CallBackType>();

  const gotoSelectPlan = () => setStatus(UpgradeDowngradeState.SelectPlan);
  const gotoSuccess = () => setStatus(UpgradeDowngradeState.Success);
  const gotoLoading = () => setStatus(UpgradeDowngradeState.Loading);
  const gotoAddCard = (plan: SelectedPlan) => {
    setNewPlan(plan);
    setCallBackType(CallBackType.Upgrade);
    setStatus(UpgradeDowngradeState.AddCard);
  };

  const gotoDowngrade = (plan: SelectedPlan) => {
    setNewPlan(plan);
    setCallBackType(CallBackType.Downgrade);
    setStatus(UpgradeDowngradeState.Downgrade);
  };

  switch (status) {
    case UpgradeDowngradeState.Loading:
      return <UpgradeDowngradeLoading callBack={gotoSuccess} />;
    case UpgradeDowngradeState.SelectPlan:
      return (
        <SelectPlan goToAddCard={gotoAddCard} goToDowngrade={gotoDowngrade} />
      );
    case UpgradeDowngradeState.AddCard:
      return (
        <PaymentUpdate
          plan={PLANS_INFO[newPlan.type]}
          duration={newPlan.duration}
          handleSubmit={gotoLoading}
        />
      );
    case UpgradeDowngradeState.Downgrade:
      return (
        <Downgrade
          plan={PLANS_INFO[newPlan.type]}
          duration={newPlan.duration}
          handleSubmit={gotoLoading}
          goBack={gotoSelectPlan}
        />
      );
    case UpgradeDowngradeState.Success:
      return (
        <Success
          plan={newPlan.type}
          duration={newPlan.duration}
          callBackType={callBackType}
          callBack={() => navigate(-1)}
        />
      );
  }
}
