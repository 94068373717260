import useUpdateShare from 'interface/stacks/workspace/shared/hooks/useUpdateShare';
import {StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';
import {GeneralAccess} from './GeneralAccess';
import ShareMembers from './ShareMembers';

interface Props {
  share: Share;
  toogleCurrentView: () => void;
  done: () => void;
}

export function ShareSettings({share, toogleCurrentView, done}: Props) {
  const isEditable = true;

  const {updateShare} = useUpdateShare({
    share,
  });

  return (
    <View style={styles.root}>
      <ShareMembers
        share={share}
        toogleCurrentView={toogleCurrentView}
        canAddRecipients={isEditable}
      />
      <GeneralAccess
        share={share}
        updateShare={updateShare}
        isEditable={isEditable}
        done={done}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    gap: 21,
  },
});
