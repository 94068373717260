import {Navigate, Outlet} from 'extensions/navigation';
import {useSearchParams} from 'extensions/navigation/hooks/useSearchParams';
import {api} from 'fast-sdk';
import useUserOrgPermission from 'interface/stacks/app/hooks/useUserOrgPermission';
import {useEffect, useState} from 'react';
import {View} from 'react-native';
import {Loading} from './Loading';

export function OrganizationAccessLayout() {
  const [searchParams] = useSearchParams();

  const [userHasAccess, setUserHasAccess] = useState<boolean>(false);
  const [organizationDomain, setOrganizationDomain] = useState<string>();
  const [userAccessLoading, setUserAccessLoading] = useState<boolean>(false);

  useUserOrgPermission();

  const getAccessStatus = async () => {
    setUserAccessLoading(true);
    if (searchParams) {
      const orgDomain = searchParams.get('organizationDomain');
      setOrganizationDomain(orgDomain);
      await getUserAccess(orgDomain);
    }
    setUserAccessLoading(false);
  };

  useEffect(() => {
    getAccessStatus();
  }, [location.pathname]);

  const getUserAccess = async (orgDomain?: string) => {
    if (!orgDomain) {
      setUserHasAccess(false);
      return;
    }
    try {
      const {result, org} =
        await api.organization.getOrganizationDetails(orgDomain);
      setUserHasAccess(!!(result && org.id));
    } catch (err) {
      setUserHasAccess(false);
    }
  };

  useEffect(() => {
    getUserAccess(organizationDomain);
  }, [organizationDomain]);

  if (userAccessLoading) {
    return (
      <View
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Loading size="large" />
      </View>
    );
  }

  if (userHasAccess || !organizationDomain) return <Outlet />;
  return <Navigate to="/request-access/organization" />;
}
