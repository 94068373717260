import {useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {isLoggedIn} from 'store/slices/app/selectors';
import EntityCommunicationManager from '../EntityActivityManager';
import WebSocketConnectionManager from '../WebSocketConnectionManager';

export default function useCleanUpConnections() {
  const isUserLoggedIn = useSelector(isLoggedIn);

  const cleanupConnections = useCallback(() => {
    EntityCommunicationManager.getInstance().endAllActivityHandlers();
    WebSocketConnectionManager.getInstance().closeAllConnections();
  }, []);

  useEffect(() => {
    if (!isUserLoggedIn) {
      cleanupConnections();
    }

    const handleBeforeUnload = () => {
      cleanupConnections();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUserLoggedIn, cleanupConnections]);

  return cleanupConnections;
}
