import type React from 'react';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import {BUTTON_CONSTANTS} from './constants';
import type {ActionIconProps} from './types';

const ActionIcon: React.FC<ActionIconProps> = ({
  name,
  size = BUTTON_CONSTANTS.ICON_SIZE,
  color = BUTTON_CONSTANTS.ICON_COLOR,
}) => <MCIcon name={name} size={size} color={color} />;

export default ActionIcon;
