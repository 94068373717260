import {ROUTES, WORKSPACE_ROOT} from 'constants/routes';
import {Navigate, useNavigate, useParams} from 'extensions/navigation';
import {InvitationTypes} from 'fast-sdk/src/api/share/consts';
import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as app from 'store/slices/app';
import * as onboarding from 'store/slices/onboarding';
import * as user from 'store/slices/user';
import AddProfilePicture from '../AddProfilePicture';
import CheckoutSuccess from '../CheckoutSuccess';
import InviteCoworkers from '../InviteCoworkers';
import OnboardError from '../OnboardError';
import OrganizationName from '../OrganizationName';
import Subdomain from '../Subdomain';
import useUpdateUser from '../hooks/useUpdateUser';
import LayoutOnboard from '../layout/LayoutOnboard';
import {
  OnboardSteps,
  getCurrentStepIndex,
  getStepFromIndex,
  totalSteps,
} from '../lib/consts';
import {Payment} from '../payment';

function RouteOnboard() {
  const params = useParams<'step'>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const step = params.step as OnboardSteps;

  const startOnboardingEmail = useSelector(
    onboarding.selectors.getStartOnboardingEmail,
  );
  const userDetails = useSelector(user.selectors.getUserDetails);
  const error = useSelector(onboarding.selectors.getError);
  const invitation = useSelector(app.selectors.getCurrentJoinInvitation);

  const {updateUser, loading} = useUpdateUser(true, {lazy: true});

  const next = () => {
    if (stepIndex !== totalSteps) {
      const nextStepIndex = Math.min(stepIndex + 1, totalSteps);
      navigate(
        `/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${getStepFromIndex(nextStepIndex)}`,
      );
    }
  };

  const done = async () => {
    await updateUser();

    if (!invitation) {
      navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`);
    }

    const {entity_type} = invitation;

    const isWorkspaceInvitation = entity_type === InvitationTypes.Workspace;

    const toWorkspaceRoute = `/${ROUTES.LOGGED_IN_WITH_ORG.WORKSPACE}/${WORKSPACE_ROOT}`;
    const toHomeRoute = `/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`;

    const newRoute = isWorkspaceInvitation ? toWorkspaceRoute : toHomeRoute;

    dispatch(app.default.actions.clearCurrentJoinInvitation());
    navigate(newRoute);
  };

  useEffect(() => {
    if (
      startOnboardingEmail &&
      startOnboardingEmail !== userDetails.email_address
    ) {
      dispatch(onboarding.default.actions.restartOnboarding());
    }
    dispatch(
      onboarding.default.actions.setStartOnboardingEmail({
        email: userDetails.email_address,
      }),
    );
  }, [startOnboardingEmail]);

  const stepIndex = useMemo(() => getCurrentStepIndex(step), [step]);

  return (
    <LayoutOnboard step={step} stepIndex={stepIndex} next={next}>
      {error ? (
        <OnboardError />
      ) : (
        <>
          {step === OnboardSteps.Profile && (
            <AddProfilePicture next={invitation ? done : next} />
          )}
          {step === OnboardSteps.Organization && (
            <OrganizationName next={next} />
          )}
          {step === OnboardSteps.Subdomain && <Subdomain next={next} />}
          {step === OnboardSteps.Payment && <Payment />}
          {step === OnboardSteps.Success && <CheckoutSuccess next={next} />}
          {step === OnboardSteps.Invites && (
            <InviteCoworkers next={done} loadingUpdateUser={loading} />
          )}
          {(stepIndex < 1 || stepIndex > totalSteps) && (
            <Navigate
              to={`/${ROUTES.LOGGED_IN_WITHOUT_ORG.ONBOARDING}/${OnboardSteps.Profile}`}
            />
          )}
        </>
      )}
    </LayoutOnboard>
  );
}

export default RouteOnboard;
