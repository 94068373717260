import VideoVolumeSlider from 'interface/stacks/content-viewer/components/control-bar/ExtVolumeSlider';
import PlayButton from 'interface/stacks/content-viewer/components/control-bar/PlayButton';
import PlaybackSpeedButton from 'interface/stacks/content-viewer/components/control-bar/PlaybackSpeedButton';
import VolumeButton from 'interface/stacks/content-viewer/components/control-bar/VolumeButton';
import useVideo from 'interface/stacks/content-viewer/hooks/useVideo';

type Props = {
  handleCheckPreviewReady: (recheck?: boolean) => void;
};

const ControlsLeft = ({handleCheckPreviewReady}: Props) => {
  const {video, handler} = useVideo();

  const togglePause = () => {
    if (!video.canPlay) {
      return handleCheckPreviewReady(true);
    }

    handler.togglePaused();
  };
  const onChangeRate = (speed: number) => handler.changePlaybackRate(speed);
  const toggleMuted = () => handler.toggleMuted();

  return (
    <>
      <PlayButton playing={video.playing} togglePause={togglePause} />
      <PlaybackSpeedButton
        playbackRate={video.playbackRate}
        onChangeRate={onChangeRate}
      />
      <VolumeButton
        volume={video.volume}
        muted={video.muted}
        toggleMuted={toggleMuted}>
        <VideoVolumeSlider />
      </VolumeButton>
    </>
  );
};

export default ControlsLeft;
