import {useModal} from 'extensions/viewport/useModal';
import {MemberAvatarGroup} from 'interface/stacks/auth/components/MemberAvatarGroup';
import {useCallback} from 'react';
import {Pressable, StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';
import * as aichat from 'store/slices/aichat';
import type {Member} from 'store/slices/settings/types';
import AiChatMembersModal from './AiChatMembersModal';

interface Props {
  chatId: string;
  workspaceMembers: Member[];
}

export function AiChatMembers({chatId, workspaceMembers}: Props) {
  const modal = useModal();
  const chat = useSelector(aichat.selectors.getChat(chatId));
  const chatMemberIds = chat?.members.map(member => member.id);

  let chatMembers =
    chatMemberIds
      ?.map(memberId =>
        workspaceMembers?.find(member => member.id === memberId),
      )
      ?.filter(member => !!member) ?? [];

  // TODO: Remove when API Done
  if (chatMembers.length === 0) {
    chatMembers = workspaceMembers
      ?.sort(() => Math.random() - 0.5)
      .slice(0, 1 + Math.random() * (workspaceMembers?.length - 1));
  }

  // TODO: get active members
  const activeMembers = [];

  const open = useCallback(() => {
    modal.open(
      <AiChatMembersModal
        members={chatMembers}
        activeMembers={activeMembers}
        onClose={modal.close}
        ownerId={chat?.creator.id}
      />,
    );
  }, []);

  return (
    <Pressable style={styles.container} onPress={open}>
      <MemberAvatarGroup
        members={chatMembers ?? []}
        loading={chatMembers?.length === 0}
        showCount={false}
        size={-1}
      />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {},
});
