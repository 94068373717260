import theme from 'config/theme';
import React from 'react';
import Icon from 'react-native-vector-icons/MaterialIcons';
import ActionButton from '../ActionButton';

type Props = {
  onPress: () => void;
};

const ZoomInButton = ({onPress}: Props) => {
  return (
    <ActionButton
      icon={<Icon name="add" size={20} color={theme.colors.neutral.$white} />}
      tooltipText="Zoom In"
      onPress={onPress}
    />
  );
};

export default ZoomInButton;
