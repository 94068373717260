import {Trans, t} from '@lingui/macro';
import CheckIcon from 'assets/svgs/icons/large_check.svg';
import theme from 'config/theme';
import {Button} from 'interface/common/Button';
import JSConfetti from 'js-confetti';
import {useEffect} from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';

interface Props {
  next: () => void;
}

const confetti = new JSConfetti();
export default function CheckoutSuccess(props: Props) {
  useEffect(() => {
    confetti.addConfetti({
      confettiNumber: 400,
      confettiColors: ['#0469FF', '#CB37FF', '#FF4C57'],
    });
  }, []);

  return (
    <View style={styles.root}>
      <View style={styles.wrapper}>
        <Image
          source={{uri: CheckIcon}}
          style={styles.icon}
          alt="Successful Payment"
          role="img"
        />
        <Text selectable style={styles.title}>
          <Trans>Congrats! Your trial begins today.</Trans>
        </Text>
        <Text selectable style={styles.content}>
          <Trans>Next, let’s invite some teammates join in the fun.</Trans>
        </Text>
        <View style={styles.buttonContainer}>
          <Button
            type="Primary"
            label={t`Continue to teammate invitations`}
            customTextStyle={styles.buttonText}
            customRootStyle={styles.button}
            onPress={props.next}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    gap: 14,
    maxWidth: 500,
  },
  title: {
    fontSize: 26,
    fontWeight: '700',
    lineHeight: 40,
    textAlign: 'center',
    color: theme.colors.neutral.$2Base,
  },
  content: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    textAlign: 'center',
  },
  icon: {
    width: 84,
    height: 84,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingVertical: 14,
  },
  button: {
    paddingVertical: 12,
    height: 48,
    borderRadius: 5,
    width: '100%',
  },
  buttonText: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 24,
  },
});
