import {createSlice} from '@reduxjs/toolkit';
import * as reducers from './reducers';
import * as selectors from './selectors';
import {ColumnManagerInfoKeys, PageType, type Store} from './types';

export const initialState: Store = {
  loaded: false,
  active: false,
  revision: 0,
  domainOrigin: 'fast.io',
  stopRedirect: false,
  currentJoinInvitation: undefined,
  forceLogout: false,
  redirectToRenewSubscription: false,
  renewSubscriptionSubdomain: undefined,
  userPermission: undefined,
  currentPage: {
    type: PageType.Unknown,
    workspace: '',
    shareName: '',
    shareId: '',
    folderId: '',
    fileId: '',
    settings: '',
    pageName: '',
  },
  columnInfo: {
    [ColumnManagerInfoKeys.WorkspaceBrowser]: true,
    [ColumnManagerInfoKeys.FoldersAndFiles]: true,
    [ColumnManagerInfoKeys.InfoPanel]: true,
    [ColumnManagerInfoKeys.ShareConfiguration]: true,
    [ColumnManagerInfoKeys.AIChat]: true,
  },
  columnInfoOverride: {
    [ColumnManagerInfoKeys.WorkspaceBrowser]: null,
    [ColumnManagerInfoKeys.FoldersAndFiles]: null,
    [ColumnManagerInfoKeys.InfoPanel]: null,
    [ColumnManagerInfoKeys.ShareConfiguration]: null,
    [ColumnManagerInfoKeys.AIChat]: null,
  },
  resendInvitationLimit: {},
};
const sliceName = 'app';

const resetColumnManagerInfoTransform = {
  in: state => state,
  out: (state, key) => {
    if (key === sliceName) {
      return {
        ...state,
        columnInfoOverride: initialState.columnInfoOverride,
      };
    }
    return state;
  },
};

export default createSlice({name: sliceName, reducers, initialState});
export {resetColumnManagerInfoTransform, selectors};
