import {Trans} from '@lingui/macro';
import theme from 'config/theme';
import {Link} from 'extensions/navigation';
import {
  Dimensions,
  StyleSheet,
  Text,
  View,
  type ViewStyle,
  useWindowDimensions,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import useLegalNavigation from '../app/hooks/useLegalNavigation';

const windowWidth = Dimensions.get('screen').width;
interface Props {
  title: string;
  subTitle: string | JSX.Element;
  linkDescription?: string;
  linkText?: string;
  linkTo?: string;
  children?: JSX.Element | Array<JSX.Element>;
  isSignUpPage?: boolean;
  buttonsMarginTop?: number;
  customTitleContainerStyle?: ViewStyle;
  customRootMaxWidth?: number;
}

export function Layout(props: Props) {
  const textColor = theme.colors.neutral.$2Base;
  const {navigateToTerms, navigateToPrivacy} = useLegalNavigation();

  const dimension = useWindowDimensions();

  return (
    <View
      style={[
        styles.root,
        {
          width:
            dimension.width > 790 ? props.customRootMaxWidth || 420 : '100%',
        },
      ]}>
      <View style={styles.container}>
        <View
          style={[styles.contentContainer, props.customTitleContainerStyle]}>
          <Text style={[styles.title, {color: textColor}]}>
            <Trans>{props.title}</Trans>
          </Text>
          {typeof props.subTitle === 'string' ? (
            <Text style={[styles.content, {color: textColor}]}>
              <Trans>{props.subTitle}</Trans>
            </Text>
          ) : (
            <Text style={{color: textColor}}>{props.subTitle}</Text>
          )}
        </View>
        {props.children}
        {props.isSignUpPage ? (
          <Text style={[styles.privacyContent, {color: textColor}]}>
            By continuing, you are setting up a Fast.io account and agree to the
            <Link
              onClick={navigateToPrivacy}
              style={styles.simpleLink}
              role="link">
              {' '}
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link
              onClick={navigateToTerms}
              style={styles.simpleLink}
              role="link">
              Terms of Service
            </Link>
            .
          </Text>
        ) : null}
        {props.isSignUpPage ? (
          <View
            style={[
              styles.hl,
              {
                borderBottomColor: theme.colors.neutral.$11,
              },
            ]}
          />
        ) : null}
        {props.linkDescription && props.linkText && props.linkTo ? (
          <View
            style={[
              styles.bottomButtonsContainer,
              {marginTop: props.buttonsMarginTop},
            ]}>
            <Text style={[styles.content, {color: textColor}]}>
              <Trans>{props.linkDescription}</Trans>
            </Text>
            <Link style={styles.link} to={props.linkTo}>
              <Text style={styles.linkText}>
                <Trans>{props.linkText}</Trans>
              </Text>
              <Icon
                name="arrow-forward"
                color={theme.colors.brand.$4Base}
                size={20}
                style={styles.rightArrowIcon}
                aria-label={props.isSignUpPage ? 'Sign up' : 'Sign in'}
              />
            </Link>
          </View>
        ) : null}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 'auto',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '3rem',
  },
  title: {
    marginBottom: 7,
    lineHeight: 40,
    fontSize: 26,
    fontWeight: '700',
  },
  content: {
    lineHeight: 22,
    fontSize: 14,
    fontWeight: '400',
    textAlign: 'center',
  },
  bottomButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 20,
  },
  linkText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.brand.$4Base,
  },
  rightArrowIcon: {
    marginLeft: 10,
  },
  simpleLink: {
    color: theme.colors.brand.$4Base,
  },
  privacyContent: {
    maxWidth: windowWidth > 790 ? 380 : windowWidth - 40,
    marginTop: '1.5rem',
    padding: 7,
    lineHeight: 21,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'center',
    color: '#71757F',
  },
  hl: {
    width: '90%',
    marginTop: '1.5rem',
    borderBottomWidth: 1,
  },
});
