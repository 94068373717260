import theme from 'config/theme';
import {
  Pressable,
  type StyleProp,
  StyleSheet,
  Text,
  type TextStyle,
  View,
  type ViewStyle,
} from 'react-native';
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons';

export interface TabItem<T extends string> {
  key: T;
  label: string;
  icon?: React.ReactNode;
}

export interface Props<T extends string> {
  tabs: Array<TabItem<T>>;
  activeTab: T;
  setActiveTab: (value: T) => void;
  tabStyle?: StyleProp<ViewStyle>;
  rootStyle?: StyleProp<ViewStyle>;
  activeTabStyle?: StyleProp<ViewStyle>;
  tabTextStyle?: StyleProp<TextStyle>;
}

export const createTabItem = <T extends string>(
  key: T,
  label: string,
  iconName: string,
  activeTab: T,
): TabItem<T> => ({
  key,
  label,
  icon: (
    <MCIcon
      name={iconName}
      size={20}
      color={
        key === activeTab
          ? theme.colors.neutral.$black
          : theme.colors.neutral.$5
      }
    />
  ),
});

const Tab = <T extends string>({
  tab,
  isActive,
  onPress,
  tabStyle,
  activeTabStyle,
  tabTextStyle,
}: {
  tab: TabItem<T>;
  isActive: boolean;
  onPress: () => void;
  tabStyle?: StyleProp<ViewStyle>;
  activeTabStyle?: StyleProp<ViewStyle>;
  tabTextStyle?: StyleProp<TextStyle>;
}) => (
  <Pressable
    key={tab.key}
    style={[
      styles.tab,
      tabStyle,
      isActive && styles.tabActive,
      isActive && activeTabStyle,
    ]}
    onPress={onPress}>
    {tab.icon && <View style={styles.tabIcon}>{tab.icon}</View>}
    <Text
      style={[
        styles.tabLabel,
        isActive && styles.activeTabLabel,
        tabTextStyle,
      ]}>
      {tab.label}
    </Text>
  </Pressable>
);

export function TabList<T extends string>({
  tabs,
  activeTab,
  setActiveTab,
  rootStyle,
  tabStyle,
  activeTabStyle,
  tabTextStyle,
}: Props<T>) {
  return (
    <View style={[styles.root, rootStyle]}>
      <View style={styles.tabs}>
        <View style={styles.tabMenu}>
          {tabs.map(tab => (
            <Tab
              key={tab.key}
              tab={tab}
              isActive={tab.key === activeTab}
              onPress={() => {
                setActiveTab(tab.key);
              }}
              tabStyle={tabStyle}
              activeTabStyle={activeTabStyle}
              tabTextStyle={tabTextStyle}
            />
          ))}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingHorizontal: 20,
    paddingBottom: 0,
  },
  tabs: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  tabMenu: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderBottomColor: theme.colors.neutral.$12,
    borderBottomWidth: 1,
  },
  tab: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    color: theme.colors.neutral.$5,
    backgroundColor: theme.colors.neutral.$white,
    paddingVertical: 20,
    paddingRight: 20,
    marginRight: 20,
  },
  tabActive: {
    borderBottomWidth: 2,
    borderBottomColor: theme.colors.neutral.$2Base,
    backgroundColor: theme.colors.neutral.$13,
  },
  tabLabel: {
    lineHeight: 24,
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'center',
    color: theme.colors.neutral.$5,
  },
  activeTabLabel: {
    color: theme.colors.neutral.$2Base,
  },
  tabIcon: {
    marginRight: 8,
  },
});
