import {Elements} from '@stripe/react-stripe-js';
import {type Stripe, loadStripe} from '@stripe/stripe-js';
import {api} from 'fast-sdk';
import {Loading} from 'interface/common/Loading';
import type {PlanMetaData} from 'interface/stacks/onboard/lib/types';
import {useEffect, useState} from 'react';
import type {BilledDurationType} from 'store/slices/settings/types';
import {AddCard} from './AddCard';

interface Props {
  plan: PlanMetaData;
  duration: BilledDurationType;
  handleSubmit: () => void;
}

export function PaymentUpdate(props: Props) {
  const [clientSecret, setClientSecret] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [stripe, setStripe] = useState<Stripe>(null);

  // Retrieve billing keys and load stripe
  useEffect(() => {
    (async () => {
      if (props.plan) {
        const planId = props.plan.ids[props.duration];
        const res = await api.billing.getKeys();
        if (res?.result) {
          setStripe(await loadStripe(res.payment_token_key));
          // TODO: get existing payment intent key from org
          // setClientSecret(payment_intent_key);
        }
        setLoading(false);
      }
    })();
  }, [props.plan]);

  return loading ? (
    <Loading />
  ) : (
    <Elements stripe={stripe} options={{clientSecret}}>
      <AddCard
        plan={props.plan}
        selectedUnit={props.duration}
        handleSubmit={props.handleSubmit}
      />
    </Elements>
  );
}
