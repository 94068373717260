import OrgLogoService from './OrgLogoService';
import UserProfilePhotoService from './UserProfilePhotoService';

export const orgLogoService = new OrgLogoService();

export const userProfilePhotoService = new UserProfilePhotoService();

export const clearImagesCache = () => {
  orgLogoService.clearAllCache();
  userProfilePhotoService.clearAllCache();
};
