import theme from 'config/theme';
import Calendar, {DEFAULT_WIDTH} from 'interface/common/Calendar';
import DatePicker from 'interface/common/DatePicker';
import React, {useEffect, useState} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {
  DATE_VALUES,
  type DateRange,
  DateRangeType,
  type OnChangeFilter,
  type OnRemoveFilter,
} from '../consts';
import useDateRange, {DateRangeBoundary} from '../hooks/useDateRange';
import DropdownPill from './dropdown-pill/DropdownPill';
import DropdownPillItem from './dropdown-pill/DropdownPillItem';

const label = {
  today: 'Today',
  last7Days: 'Last 7 Days',
  last30Days: 'Last 30 Days',
  custom: 'Custom',
};

const options = [
  {label: label.today, value: DATE_VALUES[DateRangeType.TODAY]},
  {label: label.last7Days, value: DATE_VALUES[DateRangeType.LAST_7_DAYS]},
  {label: label.last30Days, value: DATE_VALUES[DateRangeType.LAST_30_DAYS]},
  {label: label.custom, value: DATE_VALUES[DateRangeType.CUSTOM]},
];

type Props = {
  selected: DateRange;
  onChange: OnChangeFilter<DateRange>;
  onRemove: OnRemoveFilter;
};

const getLabel = (selected: DateRange | null) => {
  if (!selected) {
    return 'Date';
  }

  if (selected.type === DateRangeType.CUSTOM) {
    const formatter = new Intl.DateTimeFormat('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
    });
    const formattedStart = formatter.format(selected.start);
    const formattedEnd = formatter.format(selected.end);
    return `${formattedStart} – ${formattedEnd}`;
  }

  return label[selected.type];
};

const DateFilter = ({selected, onChange, onRemove}: Props) => {
  const [isCustom, setIsCustom] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const {
    start,
    end,
    boundary,
    handleChangeStart,
    handleChangeEnd,
    setBoundary,
    reset,
  } = useDateRange();

  useEffect(() => {
    if (selected) {
      setIsCustom(selected.type === DateRangeType.CUSTOM);
    }
  }, [selected]);

  useEffect(() => {
    if (isCustom && start && end) {
      onChange({type: DateRangeType.CUSTOM, start, end});
    }
  }, [isCustom, start, end]);

  const removeFilter = () => {
    setIsCalendarOpen(false);
    reset();
    onRemove();
  };

  const handlePressStartDate = () => {
    setIsCalendarOpen(true);
    setBoundary(DateRangeBoundary.START);
  };

  const handlePressEndDate = () => {
    setIsCalendarOpen(true);
    setBoundary(DateRangeBoundary.END);
  };

  return (
    <DropdownPill
      label={getLabel(selected)}
      active={Boolean(selected)}
      removeFilter={removeFilter}>
      {options.map(option => (
        <DropdownPillItem
          key={option.value.type}
          onPress={() => onChange(option.value)}>
          <View style={styles.iconContainer}>
            {selected?.type === option.value.type && (
              <Icon name="check" size={20} color={theme.colors.neutral.$5} />
            )}
          </View>
          <Text numberOfLines={1}>{option.label}</Text>
        </DropdownPillItem>
      ))}
      {isCustom && (
        <>
          <View style={styles.separator} />
          <View style={styles.customContainer}>
            <DatePicker
              date={start}
              onPress={handlePressStartDate}
              isActive={boundary && boundary === DateRangeBoundary.START}
            />
            <DatePicker
              date={end}
              onPress={handlePressEndDate}
              isActive={boundary && boundary === DateRangeBoundary.END}
            />
          </View>
          {isCalendarOpen && (
            <View style={styles.calendar}>
              <Calendar
                startDate={start}
                endDate={end}
                setStartDate={handleChangeStart}
                setEndDate={handleChangeEnd}
                isStartDate={boundary && boundary === DateRangeBoundary.START}
              />
            </View>
          )}
        </>
      )}
    </DropdownPill>
  );
};

const styles = StyleSheet.create({
  checkbox: {
    paddingHorizontal: 0,
  },
  iconContainer: {
    width: 20,
    height: 20,
  },
  customContainer: {
    paddingHorizontal: 20,
    paddingVertical: 13,
    gap: 10,
  },
  separator: {
    height: 1,
    backgroundColor: theme.colors.neutral.$11,
    width: '100%',
    marginVertical: 7,
  },
  calendar: {
    position: 'absolute',
    top: 0,
    right: -(DEFAULT_WIDTH + 10),
  },
});

export default DateFilter;
