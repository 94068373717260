import theme from 'config/theme';
import {useState} from 'react';
import {type StyleProp, StyleSheet, View, type ViewStyle} from 'react-native';
import {useHover} from './hooks/useHover';

export interface HoverWrapperProps {
  hoverStyle?: StyleProp<ViewStyle>;
  children: React.ReactNode;
}

export function HoverWrapper(props: HoverWrapperProps) {
  const {isHover, onHoverIn, onHoverOut} = useHover();
  return (
    <View
      //@ts-ignore
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      style={isHover && (props.hoverStyle ?? styles.defaultHover)}>
      {props.children}
    </View>
  );
}

const styles = StyleSheet.create({
  defaultHover: {
    backgroundColor: theme.colors.neutral.$13,
  },
});
