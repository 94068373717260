import {ROUTES} from 'constants/routes';
import {LOGIN_EVENT_KEY} from 'constants/sessionRoutes';
import {useNavigate} from 'extensions/navigation';
import {useEffect} from 'react';
import {Platform} from 'react-native';
import {useSelector} from 'react-redux';
import * as app from 'store/slices/app';

const REDIRECT_ROUTES = Object.values(ROUTES.NOT_LOGGED_IN);

export function useBroadcastLogin() {
  const navigate = useNavigate();
  const isLoggedIn = useSelector(app.selectors.isLoggedIn);

  useEffect(() => {
    const handleStorageChange = async (event: StorageEvent) => {
      if (event.key === LOGIN_EVENT_KEY) {
        window.location.reload();
      }
    };

    if (Platform.OS === 'web') {
      if (
        isLoggedIn &&
        REDIRECT_ROUTES.some(route => window.location.pathname.includes(route))
      ) {
        navigate(`/${ROUTES.LOGGED_IN_WITHOUT_ORG.HOME}`);
      }
      window.addEventListener('storage', handleStorageChange);
      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }
  }, []);
}
