import {t} from '@lingui/macro';
import theme from 'config/theme';
import {useParams} from 'extensions/navigation';
import {Button} from 'interface/common/Button';
import {Loading} from 'interface/common/Loading';
import {TextInput} from 'interface/common/TextInput';
import {useState} from 'react';
import {StyleSheet, Text} from 'react-native';
import {Layout, type Type} from './layout/Layout';

type Status = 'Request' | 'Sent' | 'Loading';

interface Props {
  type: Type;
}

export function Request(props: Props) {
  // This is the id of the file, or share, or workspace, or the organization
  // that the user wants to access
  const {id} = useParams<'id'>();
  const [status, setStatus] = useState<Status>('Request');
  const [message, setMessage] = useState<string>();
  const textColor = theme.colors.neutral.$2Base;

  const requestAccess = () => {
    // This id should be used in sending the access request
    console.log({id});
    setStatus('Loading');
    setInterval(() => {
      setStatus('Sent');
    }, 2000);
  };

  const Request = () => {
    return (
      <>
        <Text style={[styles.title, {color: textColor}]}>
          {`Request ${props.type.toLowerCase()} access`}
        </Text>
        <Text style={[styles.content, {color: textColor}]}>
          {`You don’t have access to this ${props.type.toLowerCase()}. Click the button below to
          request access, or sign in to an account with access.`}
        </Text>
        <TextInput
          placeHolder="Message (optional)"
          customProps={{
            numberOfLines: 3,
            multiline: true,
            textAlignVertical: 'top',
          }}
          setInputValue={setMessage}
          value={message}
          customStyle={styles.input}
          customRootStyle={styles.inputRoot}
        />
        <Button
          type="Primary"
          label={t`Request Access`}
          onPress={requestAccess}
        />
      </>
    );
  };

  const Sent = () => {
    return (
      <>
        <Text style={[styles.title, {color: textColor}]}>Request sent</Text>
        <Text style={[styles.content, {color: textColor}]}>
          {`An email will be sent to you shortly to let you know if access to this
          ${props.type.toLowerCase()} was approved.`}
        </Text>
      </>
    );
  };

  const renderResult = (status: Status) => {
    switch (status) {
      case 'Loading':
        return <Loading />;
      case 'Request':
        return Request();
      case 'Sent':
        return Sent();
    }
  };

  return <Layout type={props.type}>{renderResult(status)}</Layout>;
}

const styles = StyleSheet.create({
  title: {
    fontSize: 26,
    fontWeight: '700',
    lineHeight: 40,
    color: theme.colors.neutral.$2Base,
    textAlign: 'center',
    marginBottom: 7,
  },
  content: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
    color: theme.colors.neutral.$2Base,
    marginBottom: '2rem',
    textAlign: 'center',
  },
  inputRoot: {
    width: '100%',
    marginBottom: 35,
  },
  input: {
    height: 78,
    paddingHorizontal: 8,
    paddingVertical: 6,
    borderBottomWidth: 0,
    marginBottom: 0,
  },
});
