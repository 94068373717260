import theme from 'config/theme';
import type React from 'react';
import {
  Pressable,
  type StyleProp,
  StyleSheet,
  type ViewStyle,
} from 'react-native';

interface CardProps {
  children: React.ReactNode;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

const Card = ({children, onPress, style}: CardProps) => {
  return (
    <Pressable
      style={[styles.root, style]}
      onPress={() => onPress?.()}
      disabled={!onPress}>
      {children}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    minWidth: 390,
    maxWidth: '24%',
    backgroundColor: theme.colors.neutral.$50,
    borderRadius: 8,
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default Card;
