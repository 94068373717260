import theme from 'config/theme';
import {HoverWrapper} from 'interface/common/HoverWrapper';
import React from 'react';
import {Pressable, StyleSheet, Text} from 'react-native';

type Props = {
  onPress: () => void;
};

const ClearButton = ({onPress}: Props) => {
  return (
    <HoverWrapper hoverStyle={styles.hover}>
      <Pressable style={styles.root} onPress={onPress}>
        <Text style={styles.label}>Clear Filters</Text>
      </Pressable>
    </HoverWrapper>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 3,
    paddingVertical: 8,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: theme.colors.brand.$7,
    height: 36,
  },
  hover: {
    backgroundColor: theme.colors.neutral.$13,
    borderRadius: 50,
  },
  label: {
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.colors.brand.$4Base,
    paddingHorizontal: 10,
  },
});

export default ClearButton;
