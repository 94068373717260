import {NoResultsAvailable} from 'interface/common/NoResultsAvailable';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import type {Share} from 'store/slices/shared/types';
import ShareTableRowsSkeleton from '../../skeleton/ShareTableRowsSkeleton';
import ShareTableRow from './ShareTableRow';

interface ShareTableRowsProps {
  shares: Share[];
  onSharePress: (share: Share) => void;
  isSharesLoading: boolean;
}

const ShareTableRows = ({
  shares,
  onSharePress,
  isSharesLoading,
}: ShareTableRowsProps) => {
  const showTableSkeleton = isSharesLoading && shares.length === 0;
  const showNoResultsAvailable = shares.length === 0;
  return (
    <View style={(showTableSkeleton || !showNoResultsAvailable) && styles.root}>
      {showTableSkeleton ? (
        <ShareTableRowsSkeleton />
      ) : showNoResultsAvailable ? (
        <NoResultsAvailable message="No shares available" />
      ) : (
        shares.map((share, index) => (
          <ShareTableRow
            key={`org-shares-${index}`}
            share={share}
            onPress={() => onSharePress(share)}
          />
        ))
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    marginTop: 12,
  },
});

export default ShareTableRows;
