import theme from 'config/theme';
import {MonthDates} from 'interface/stacks/share/layout/calendar/MonthDates';
import {MonthSelect} from 'interface/stacks/share/layout/calendar/MonthSelect';
import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';

export const DEFAULT_WIDTH = 294;

type Props = {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  isStartDate: boolean;
};

const Calendar = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isStartDate,
}: Props) => {
  const [showDate, setShowDate] = useState<Date>(startDate);

  return (
    <View style={styles.root}>
      <MonthSelect showDate={showDate} setShowDate={setShowDate} />
      <MonthDates
        showDate={showDate}
        startDate={startDate}
        endDate={endDate}
        setDate={isStartDate ? setStartDate : setEndDate}
        isStartDate={isStartDate}
        isDateRange
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: DEFAULT_WIDTH,
    backgroundColor: theme.colors.neutral.$white,
    borderRadius: 7,
    boxShadow:
      '0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 6px 12px 0px rgba(0, 0, 0, 0.07);',
    paddingTop: 21,
    paddingHorizontal: 21,
  },
});

export default Calendar;
