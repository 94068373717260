import {Router} from 'extensions/navigation';
import {Navigator} from 'interface/Navigator';
import {history} from 'store';

export const NavigatorHistory = () => {
  return (
    <Router history={history}>
      <Navigator />
    </Router>
  );
};
