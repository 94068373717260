import {useDispatch} from 'react-redux';
import * as workspace from 'store/slices/workspace';
import {useFetchWorkspaces} from './useFetchWorkspaces';

interface UseLayoutWorkspacesOptions {
  updateLayoutLoader?: boolean;
}

export const useLayoutWorkspaces = (
  options: UseLayoutWorkspacesOptions = {updateLayoutLoader: true},
) => {
  const dispatch = useDispatch();

  const {fetchWorkspaces, isLoading: isFetchLoading} = useFetchWorkspaces();

  const fetchAndUpdateWorkspaces = async () => {
    const {updateLayoutLoader} = options;

    try {
      if (updateLayoutLoader)
        dispatch(workspace.default.actions.setWorkspacesLoading(true));

      const workspaces = await fetchWorkspaces();

      if (workspaces) {
        dispatch(
          workspace.default.actions.setWorkspaces({
            workspaces,
            preserveOrder: true,
          }),
        );
      }

      return workspaces;
    } finally {
      dispatch(workspace.default.actions.setWorkspacesLoaded(true));
      if (updateLayoutLoader)
        dispatch(workspace.default.actions.setWorkspacesLoading(false));
    }
  };

  return {fetchAndUpdateWorkspaces, isFetchLoading};
};
