import {api} from 'fast-sdk';
import type {BillingMeterUsage} from 'fast-sdk/src/api/billing/consts';
import {useEffect, useState} from 'react';

export default function useBillingMeters(domain: string, meter: string) {
  const [loading, setLoading] = useState(true);
  const [meters, setMeters] = useState<BillingMeterUsage | null>(null);

  async function getBillingMeters(domain: string) {
    return await api.billing.getMeters(domain, meter);
  }

  useEffect(() => {
    if (!domain) return;

    async function fetchMeters() {
      const meters = await getBillingMeters(domain);

      if (!meters.result) return setLoading(false);

      setMeters(meters.usage);
      setLoading(false);
    }

    fetchMeters();
  }, [domain]);

  return {meters, loading};
}
