import {useCallback, useRef} from 'react';
import {Platform} from 'react-native';
import {isTouch} from 'utils/common/platform';

import type {DragEvent, MouseEvent, ReactNode} from 'react';

interface MouseProps {
  children: ReactNode;
  onClick?(event: MouseEvent): void;
  onDoubleClick?(event: MouseEvent): void;
  onContextMenu?(event: MouseEvent): void;
  onDragStart?(event: DragEvent): void;
  onDragEnter?(event: DragEvent): void;
  onDragLeave?(event: DragEvent): void;
  onDragDrop?(event: DragEvent): void;
  onDragEnd?(event: DragEvent): void;
}

export function Mouse(props: MouseProps) {
  const doubleClick = useCallback(
    (e: MouseEvent) => {
      if (props.onDoubleClick) {
        e.stopPropagation();
        props.onDoubleClick(e);
      }
    },
    [props.onDoubleClick],
  );

  const contextMenu = useCallback(
    (e: MouseEvent) => {
      if (props.onContextMenu) {
        e.preventDefault();
        e.stopPropagation();
        props.onContextMenu(e);
      }
    },
    [props.onContextMenu],
  );

  const stateDrop = useRef(0);

  const dragOver = useCallback((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const dragEnter = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      stateDrop.current += 1;
      if (props.onDragEnter && stateDrop.current > 0) {
        props.onDragEnter(e);
      }
    },
    [props.onDragEnter],
  );

  const dragLeave = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      stateDrop.current -= 1;
      if (props.onDragLeave && stateDrop.current === 0) {
        props.onDragLeave(e);
      }
    },
    [props.onDragLeave],
  );

  const dragDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      stateDrop.current = 0;
      if (props.onDragDrop) {
        props.onDragDrop(e);
      }
    },
    [props.onDragDrop],
  );

  const dragStart = useCallback(
    (e: DragEvent) => {
      if (props.onDragStart) {
        props.onDragStart(e);
      }
    },
    [props.onDragStart],
  );

  const dragEnd = useCallback(
    (e: DragEvent) => {
      if (props.onDragEnd) {
        props.onDragEnd(e);
      }
    },
    [props.onDragEnd],
  );

  if (Platform.OS !== 'web') {
    return <>{props.children}</>;
  }

  return (
    <div
      draggable={!!props.onDragStart}
      onClick={props.onClick ?? undefined}
      onDragStart={dragStart}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDragOver={dragOver}
      onDragEnd={dragEnd}
      onDrop={dragDrop}
      onDoubleClick={doubleClick}
      onContextMenu={!isTouch() ? contextMenu : undefined}
      style={{display: 'flex', flex: 1, width: '100%', height: '100%'}}>
      {props.children}
    </div>
  );
}
