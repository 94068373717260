import {SocialLinkType} from 'store/slices/settings/types';

export function validateEmail(email: string) {
  return email
    .toString()
    .toLowerCase()
    .match(/^[\w-+\.]+@([\w-]+\.)+[a-zA-Z]{2,}$/g);
}

export function validatePassword(password: string): {[key: string]: boolean} {
  const result = {
    upperCase: false,
    lowerCase: false,
    digit: false,
    specialCharacter: false,
    '8OrMore': false,
  };
  const RegEx = {
    upperCase: /[A-Z]/,
    lowerCase: /[a-z]/,
    digit: /[0-9]/,
    specialCharacter:
      /[#$%&'"()*+,-./:;<=>?!¡™£¢∞§¶•ªº–≠«‘“…æ÷≥≤@\\[\]^_`{|}~]/,
  };

  if (password.length < 8) result['8OrMore'] = true;
  if (!RegEx.upperCase.test(password)) result.upperCase = true;
  if (!RegEx.lowerCase.test(password)) result.lowerCase = true;
  if (!RegEx.digit.test(password)) result.digit = true;
  if (!RegEx.specialCharacter.test(password)) result.specialCharacter = true;

  return result;
}

const isFacebookLink = (url: string) => {
  const facebookPattern = /^(https?:\/\/)?(www\.)?(facebook|fb)\.com/;
  return facebookPattern.test(url);
};

const isInstagramLink = (url: string) => {
  const instagramPattern = /^(https?:\/\/)?(www\.)?instagram\.com/;
  return instagramPattern.test(url);
};

const isTwitterLink = (url: string) => {
  const twitterPattern = /^(https?:\/\/)?(www\.)?(twitter|x)\.com/;
  return twitterPattern.test(url);
};

const isYoutubeLink = (url: string) => {
  const youtubePattern = /^(https?:\/\/)?(www\.)?youtube\.com/;
  return youtubePattern.test(url);
};

export const linkProvider = (link: string): SocialLinkType => {
  const lowerUrl = link.toLowerCase();

  const isFacebook = isFacebookLink(lowerUrl);
  const isInstagram = isInstagramLink(lowerUrl);
  const isTwitter = isTwitterLink(lowerUrl);
  const isYoutube = isYoutubeLink(lowerUrl);

  if (isFacebook) return SocialLinkType.Facebook;
  if (isInstagram) return SocialLinkType.Instagram;
  if (isTwitter) return SocialLinkType.Twitter;
  if (isYoutube) return SocialLinkType.Youtube;
  return SocialLinkType.Other;
};

export const providerMapper = (provider: SocialLinkType) => {
  switch (provider) {
    case SocialLinkType.Facebook:
      return 'facebook';
    case SocialLinkType.Instagram:
      return 'instagram';
    case SocialLinkType.Twitter:
      return 'twitter';
    case SocialLinkType.Youtube:
      return 'youtube';
    default:
      return 'homepage';
  }
};
