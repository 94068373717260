import {api} from 'fast-sdk';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as shared from 'store/slices/shared';
import * as workspace from 'store/slices/workspace';

export const useShares = () => {
  const workspaces = useSelector(workspace.selectors.getAllJoinedWorkspaces);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const fetchAndUpdateShares = async () => {
    const {result, shares} = await api.share.getAllShares();
    if (result) {
      dispatch(shared.default.actions.setShares({shares, workspaces}));
      dispatch(shared.default.actions.setSharesLoaded({loaded: true}));
    }
    setLoading(false);
  };

  return {fetchAndUpdateShares, loading};
};
