import {api} from 'fast-sdk';
import {useGetWorkspaceDetails} from 'interface/stacks/workspace/hooks/useGetWorkspaceDetails';
import {useDispatch} from 'react-redux';
import store from 'store';
import * as shared from 'store/slices/shared';

const useFetchShareDetails = () => {
  const dispatch = useDispatch();

  const {fetchWorkspaceDetails} = useGetWorkspaceDetails();

  const fetchShareDetails = async (
    workspaceFolderName: string,
    shareId: string,
  ) => {
    const {result, share: shareDetails} =
      await api.share.getShareDetails(shareId);
    if (result) {
      dispatch(
        shared.default.actions.updateShareDetails({
          workspaceFolderName,
          shareDetails,
        }),
      );
    }
  };

  const fetchShareDetailsByCustomName = async (sharedCustomName: string) => {
    const {result, share} = await api.share.getShareDetails(sharedCustomName);
    if (result) {
      const cachedWorkspace = store
        .getState()
        .workspace.workspaces.find(ws => ws.id === share.parent_workspace);
      const workspace =
        cachedWorkspace ??
        (await fetchWorkspaceDetails(share.parent_workspace));
      if (workspace) {
        dispatch(
          shared.default.actions.updateShareDetails({
            workspaceFolderName: workspace.folder_name,
            shareDetails: share,
          }),
        );
      }
    }
  };

  const fetchPublicShareDetails = async (shareName: string) => {
    const {result, ...rest} = await api.share.getPublicShareDetails(shareName);
    return result ? rest : null;
  };

  return {
    fetchShareDetails,
    fetchShareDetailsByCustomName,
    fetchPublicShareDetails,
  };
};

export default useFetchShareDetails;
